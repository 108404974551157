import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';

import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { getAllSpaces } from 'lib/api';
import { CoreSpace } from '@densityco/lib-api-types';

export const asyncFetchSpacesThunk = createAsyncThunk<
  // return value
  CoreSpace[],
  // parameters
  void,
  // types for thunkApi
  AppThunkApiConfig
>(
  'spaces/asyncFetchSpaces',
  async (_, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    return await getAllSpaces(auth.densityAPIClient);
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
