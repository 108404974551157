import { PlanDetail } from 'lib/api';
import { getPlansPatches } from 'lib/patches';
import { AppThunk } from 'redux/store';

import { patchPlansState, planDetailsAdapter } from '../plans/plans-slice';

export const updatePlanDetailInRedux =
  (planDetail: PlanDetail): AppThunk =>
  (dispatch, getState) => {
    const [patches] = getPlansPatches(getState().plans, (draft) => {
      planDetailsAdapter.setOne(draft.planDetails, planDetail);
    });

    dispatch(patchPlansState(patches));
  };
