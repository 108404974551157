import { Fragment, useMemo } from 'react';
import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand } from '@visx/scale';
import moment from 'moment-timezone';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { preprocessTimeUsedByDayChartValues } from 'lib/charts';
import { css } from '@emotion/react';

const X_AXIS_HEIGHT = 12;
const X_AXIS_SPACING = 6;
const Y_AXIS_WIDTH = 25;
const Y_AXIS_SPACING = 4;
const BAR_SPACING = 12;

export type BarsProps = {
  width: number;
  height: number;
  data: Array<{ time: string; value: number }>;
  timeZone: string;
  events?: boolean;
};

const Y_AXIS_QUARTILES = [0, 0.25, 0.5, 0.75, 1];

export default function ChartTimeUsedByDay({
  data,
  width,
  height,
  events = false,
  timeZone,
}: BarsProps) {
  const chartHeight = height - X_AXIS_HEIGHT - X_AXIS_SPACING;
  const chartWidth = width - Y_AXIS_WIDTH - Y_AXIS_SPACING;

  const xScale = useMemo(() => {
    return scaleBand<string>({
      domain: data.map((datum) => datum.time),
      range: [0, chartWidth],
      padding: 0.4,
    });
  }, [chartWidth, data]);

  const { yScale, maxIndices } = useMemo(
    () => preprocessTimeUsedByDayChartValues(data, chartHeight, 0.2),
    [data, chartHeight]
  );

  // Check whether we should show decimal places for hours
  const maxValue = data[maxIndices[0]].value;
  const decimalYValues = maxValue <= 1000 * 60 * 60 * 4 ? true : false;

  // Build a data structure for X-Axis labels
  // Months only appear under days the first time they appear in the data
  const dayAxisLabels: Array<{ dateLabel: string; monthLabel: string | null }> =
    [];
  let lastMonth: string | null = null;
  data.forEach((datum) => {
    const todayMonth = moment.tz(datum.time, timeZone).format('MMM');
    let monthLabel = null;

    if (lastMonth !== todayMonth) {
      monthLabel = todayMonth;
      lastMonth = todayMonth;
    }

    dayAxisLabels.push({
      dateLabel: moment.tz(datum.time, timeZone).format('D'),
      monthLabel,
    });
  });

  return (
    <svg
      width={width}
      height={height}
      css={css`
        overflow: visible;
      `}
    >
      <Group top={0} left={0}>
        {Y_AXIS_QUARTILES.map((proportion) => {
          // Always draw four Y-Axis lines, one per quartile
          const lineY = proportion * chartHeight;
          const lineHours = moment
            .duration(yScale.invert(proportion * chartHeight), 'milliseconds')
            .asHours()
            .toFixed(decimalYValues && proportion ? 1 : 0);
          const lineLabel = `${lineHours}${proportion ? ' hrs' : ''}`;

          return (
            <Group key={proportion} top={chartHeight - lineY}>
              <Group left={8 + Y_AXIS_WIDTH}>
                <text
                  fontSize="10px"
                  transform={`translate(0 3)`}
                  fill={dust.Gray400}
                  textAnchor="end"
                >
                  {lineLabel}
                </text>
              </Group>

              <line
                x1={Y_AXIS_WIDTH + Y_AXIS_SPACING}
                x2={chartWidth + BAR_SPACING * 2}
                y1={0}
                y2={0}
                stroke={dust.Gray100}
                strokeWidth={1}
              ></line>
            </Group>
          );
        })}

        <Group left={Y_AXIS_WIDTH + Y_AXIS_SPACING}>
          {data.map((d, i) => {
            const dayAxisLabel = dayAxisLabels[i];

            const barWidth = xScale.bandwidth();
            const barHeight = yScale(d.value) ?? 0;
            const barX = xScale(d.time) ?? 0;
            const barY = chartHeight - yScale(d.value) ?? 0;

            return (
              <Fragment key={`bar-${d.time}`}>
                <BarRounded
                  top
                  radius={2}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={dust.Blue400}
                  onClick={() => {
                    if (events) {
                      alert(`clicked: ${JSON.stringify(Object.values(d))}`);
                    }
                  }}
                />

                <Group
                  top={height - X_AXIS_SPACING}
                  left={barX + barWidth / 2}
                  textAnchor="middle"
                >
                  <text
                    fontSize="10px"
                    transform="translate(0 3)"
                    fill={dust.Gray400}
                  >
                    {dayAxisLabel.dateLabel}
                  </text>
                  {dayAxisLabel.monthLabel ? (
                    <text
                      fontSize="8px"
                      fontWeight="700"
                      transform="translate(0 16)"
                      fill={dust.Gray300}
                    >
                      {dayAxisLabel.monthLabel}
                    </text>
                  ) : null}
                </Group>
              </Fragment>
            );
          })}
        </Group>
      </Group>
    </svg>
  );
}
