import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { Action } from './actions';

import { Viewport } from 'lib/viewport';
import { ViewportCoordinates } from 'lib/geometry';
import { Floorplan } from 'lib/floorplan';

const ObjectPlacementTarget: React.FunctionComponent<{
  viewport: Viewport;
  floorplan: Floorplan;
  dispatch: React.Dispatch<Action>;
}> = ({ viewport, floorplan, dispatch }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  // This effect is used to register the mouse wheel event handling
  useEffect(() => {
    const elem = elementRef.current;
    if (!elem) throw new Error('Unable to get overlay element from ref');

    const onOverlayWheel = (evt: WheelEvent) => {
      evt.preventDefault();
      const bbox = elem.getBoundingClientRect();
      const dx = evt.deltaX;
      const dy = evt.deltaY;
      const { altKey, ctrlKey, metaKey, shiftKey } = evt;
      const position = ViewportCoordinates.create(
        evt.clientX - bbox.left,
        evt.clientY - bbox.top
      );
      dispatch({
        type: 'viewport.scrollwheel',
        position,
        dx,
        dy,
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
      });
    };

    // This is why we're using an effect rather than attaching a handler in the JSX,
    // we need to set { passive: false } on the listener
    elem.addEventListener('wheel', onOverlayWheel, { passive: false });

    return () => {
      elem.removeEventListener('wheel', onOverlayWheel);
    };
  }, [elementRef, dispatch]);

  // The position of the object placement target shouldn't change once it's mounted.
  const [elementBBox, setElementBBox] = useState<{
    top: number;
    left: number;
  } | null>(null);
  useEffect(() => {
    if (!elementRef.current) {
      return;
    }
    const bbox = elementRef.current.getBoundingClientRect();
    setElementBBox({ top: bbox.top, left: bbox.left });
  }, []);

  const onMouseDown = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      if (!elementBBox) {
        return;
      }
      const viewportCoordinates = ViewportCoordinates.create(
        evt.clientX - elementBBox.left,
        evt.clientY - elementBBox.top
      );
      const position = ViewportCoordinates.toFloorplanCoordinates(
        viewportCoordinates,
        viewport,
        floorplan
      );
      dispatch({ type: 'placement.click', position });
    },
    [floorplan, viewport, elementBBox, dispatch]
  );

  const onMouseMove = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      if (!elementBBox) {
        return;
      }
      const viewportCoords = ViewportCoordinates.create(
        evt.clientX - elementBBox.left,
        evt.clientY - elementBBox.top
      );

      const position = ViewportCoordinates.toFloorplanCoordinates(
        viewportCoords,
        viewport,
        floorplan
      );

      dispatch({
        type: 'placement.mousemove',
        position,
        viewport,
        floorplan,
        shiftKey: evt.shiftKey,
      });
    },
    [viewport, floorplan, elementBBox, dispatch]
  );

  const onMouseLeave = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      dispatch({
        type: 'placement.mousemove',
        position: null,
        viewport,
        floorplan,
        shiftKey: false,
      });
    },
    [floorplan, viewport, dispatch]
  );

  return (
    <div
      ref={elementRef}
      style={{
        position: 'absolute',
        width: viewport.width,
        height: viewport.height,
      }}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      data-cy="floorplan-object-placement-target"
    ></div>
  );
};

export default ObjectPlacementTarget;
