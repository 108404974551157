import { downloadFile } from '@densityco/lib-common-helpers';
import moment from 'moment-timezone';
import { CompatibleDateValue } from '@densityco/ui/dist/cjs/src/date-picker';
import { DayOfWeek } from '@densityco/lib-common-types';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApiConfig } from 'redux/store';
import { sanitizeCSVDocument } from 'lib/sanitize-csv';
import { computeHoursPerDay, TimeOfDay } from 'lib/date-time';
import { AnalysisDatapoint } from './analysis-slice';
import { defaultAppThunkOptions } from 'lib/redux';

function downloadAnalysisCSVLegacy(
  floorId: string,
  startTime: string,
  endTime: string,
  data: Array<{
    areaId: string;
    name: string;
    percentOccupied: number;
    totalHours: number;
    averageHoursPerDay: number;
  }>
) {
  const headerRow = `Area ID,Name,Total Hours,Hours Per Day,Percent Occupied`;
  const csvData = data.map(
    (item) =>
      `${item.areaId},${item.name},${item.totalHours.toFixed(
        2
      )},${item.averageHoursPerDay.toFixed(2)},${(
        item.percentOccupied * 100
      ).toFixed(1)}`
  );

  const sanitizedData = sanitizeCSVDocument(
    `${headerRow}\r\n${csvData.join('\r\n')}`
  );

  downloadFile(
    `density_summary_presence_${floorId}_${startTime}_${endTime}.csv`,
    sanitizedData,
    'text/csv;charset=utf8;'
  );
}

export const downloadAnalysisCSVLegacyThunk = createAsyncThunk<
  // return value
  void,
  // parameters
  {
    floorId: string;
    areas: { id: string; name: string }[];

    timeZone: string;
    filterStart: TimeOfDay;
    filterEnd: TimeOfDay;
    filterDays: DayOfWeek[];
    startDate: CompatibleDateValue;
    endDate: CompatibleDateValue;

    data: AnalysisDatapoint[];
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'analysis/downloadAnalysisCSVLegacy',
  async (args, thunkApi) => {
    const occupancyPercentByArea = Object.fromEntries(
      args.areas.map((area) => {
        const occupancy = args.data.find((item) => item.area_id === area.id);

        return [area.id, occupancy?.occupied_percent];
      })
    );

    const hoursPerDayByArea = Object.fromEntries(
      args.areas.map((area) => {
        const occupancy = args.data.find((item) => item.area_id === area.id);

        const hours = moment
          .duration(occupancy?.occupied_total_ms || 0, 'milliseconds')
          .asHours();

        const hoursPerDay = computeHoursPerDay(
          hours,
          [args.startDate, args.endDate],
          args.filterDays,
          args.timeZone
        );

        return [area.id, hoursPerDay];
      })
    );

    downloadAnalysisCSVLegacy(
      args.floorId,
      moment.tz(args.startDate, args.timeZone).format('YYYY-MM-DD'),
      moment.tz(args.endDate, args.timeZone).format('YYYY-MM-DD'),
      args.data.map((datum) => ({
        areaId: datum.area_id,
        name: args.areas.find((a) => a.id === datum.area_id)?.name || '',
        percentOccupied: occupancyPercentByArea[datum.area_id] || 0,
        totalHours: moment
          .duration(datum.occupied_total_ms, 'milliseconds')
          .asHours(),
        averageHoursPerDay: hoursPerDayByArea[datum.area_id],
      }))
    );
  },
  defaultAppThunkOptions
);
