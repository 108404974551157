import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import * as React from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { toast } from 'react-toastify';
import {
  GetViewportParameters,
  PDFDocumentProxy,
} from 'pdfjs-dist/types/display/api';
import {
  AppBar,
  AppBarContext,
  AppBarSection,
  Button,
  ButtonGroup,
} from '@densityco/ui';

import styles from './styles.module.scss';

import Modal from 'components/modal';

pdfjsLib.GlobalWorkerOptions.workerSrc =
  '//cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.js';

// this is currently set based on Figma's max image size
const MAX_IMAGE_WIDTH = 4096;

const ImageUploader: React.FunctionComponent<{
  onUploadImage: (
    image: HTMLImageElement,
    dataUrl: string,
    sourceFile: File,
    sourceFileDataUrl: string
  ) => void;
  children: (trigger: () => void) => React.ReactNode;
  allowPDF?: boolean;
}> = ({ onUploadImage, allowPDF = false, children }) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);
  const [pdfPageSelectModalVisible, setPdfPageSelectModalVisible] =
    useState<boolean>(false);
  const [pdfSelectedPageText, setPdfSelectedPageText] = useState<string>('');
  const sourceFileObject = useRef<File | null>(null);

  // Compute the list of content types accepted by the image uploader
  const acceptMime = useMemo(() => {
    const acceptMime = ['image/png', 'image/jpeg'];
    if (allowPDF) {
      acceptMime.push('application/pdf');
    }
    return acceptMime;
  }, [allowPDF]);

  const clearState = useCallback(() => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.value = '';
    }
    setPdf(null);
    setPdfPageSelectModalVisible(false);
  }, [setPdfPageSelectModalVisible, setPdf, hiddenFileInputRef]);

  const onUploadButtonClick = useCallback(() => {
    const input = hiddenFileInputRef.current;
    if (!input) throw new Error('Could not get input element from ref');
    input.click();
  }, [hiddenFileInputRef]);

  const convertDataUrlToImageForUpload = useCallback(
    (dataUrl: string) => {
      // Get the source file object stored in a ref so that it can be passed along to onUploadImage
      if (!sourceFileObject.current) {
        throw new Error('Could not get source file object!');
      }
      const sourceFile: File = sourceFileObject.current;

      const image = new Image();
      image.src = dataUrl;

      image.addEventListener('load', () => {
        // if the image is too large, scale it down
        // this creates a canvas, scales it, and then re-calls this function once the proper dimensions are set
        if (image.width > MAX_IMAGE_WIDTH) {
          const canvas = document.createElement('canvas');
          const scale = MAX_IMAGE_WIDTH / image.width;

          canvas.width = image.width * scale;
          canvas.height = image.height * scale;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            return;
          }

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          // break out (don't upload), and reprocess for upload
          return convertDataUrlToImageForUpload(canvas.toDataURL('image/png'));
        }
        clearState();
        onUploadImage(image, image.src, sourceFile, dataUrl);
      });
    },
    [onUploadImage, clearState]
  );

  const onFileInputChange = useCallback(() => {
    const input = hiddenFileInputRef.current;
    if (!input) throw new Error('Could not get input element from ref');

    const file = input.files?.item(0);
    if (!file) throw new Error('No file selected');

    // Store the file object for later so metadata can be extracted off of it.
    sourceFileObject.current = file;

    const reader = new FileReader();

    reader.addEventListener('load', (evt) => {
      const dataUrl = reader.result;
      if (typeof dataUrl !== 'string') {
        return;
      }

      // convert pdf file to png
      if (file.type === 'application/pdf') {
        pdfjsLib.getDocument(dataUrl).promise.then(setPdf);
      }
      // just a simple png/jpeg, render it to an image
      else if (file.type === 'image/png' || file.type === 'image/jpeg') {
        convertDataUrlToImageForUpload(dataUrl);
      }
    });
    reader.readAsDataURL(file);
  }, [convertDataUrlToImageForUpload]);

  const processPdfSelectedPage = useCallback(
    (selectedPage: number) => {
      if (!pdf) {
        throw new Error('PDF page selected, but no PDF found in state');
      }

      pdf.getPage(selectedPage).then((page) => {
        var viewportParams: GetViewportParameters = { scale: 5 };
        var viewport = page.getViewport(viewportParams);

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          return;
        }
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        page
          .render({ canvasContext: context, viewport: viewport })
          .promise.then(() => {
            const dataUrl = canvas.toDataURL('image/png');

            convertDataUrlToImageForUpload(dataUrl);
          });
      });
    },
    [convertDataUrlToImageForUpload, pdf]
  );

  useEffect(() => {
    if (!pdf) {
      return;
    }

    // more than one page - show the modal to select desired page
    if (pdf.numPages > 1) {
      setPdfPageSelectModalVisible(true);
    }
    // single page, move forward with upload
    else {
      processPdfSelectedPage(1);
    }
  }, [pdf, processPdfSelectedPage]);

  return (
    <div data-cy="cad-uploader">
      <input
        ref={hiddenFileInputRef}
        style={{ display: 'none' }}
        type="file"
        accept={acceptMime.join(',')}
        onChange={onFileInputChange}
      />
      {children(onUploadButtonClick)}
      {pdf ? (
        <Modal visible={pdfPageSelectModalVisible}>
          <div className={styles.pdfPageSelectModalBody}>
            <h5 className={styles.pdfPageSelectModalLabel}>
              Select the desired page (1 - {pdf.numPages})
            </h5>
            <input
              className={styles.inputTextSmall}
              type="number"
              min={1}
              max={pdf.numPages}
              step={1}
              onChange={(evt) => {
                setPdfSelectedPageText(evt.currentTarget.value);
              }}
            />
          </div>

          <AppBarContext.Provider value="BOTTOM_ACTIONS">
            <AppBar>
              <AppBarSection></AppBarSection>
              <AppBarSection>
                <ButtonGroup>
                  <Button variant="underline" onClick={() => clearState()}>
                    Cancel
                  </Button>
                  <Button
                    variant="filled"
                    type="primary"
                    disabled={
                      pdfSelectedPageText === '' ||
                      parseInt(pdfSelectedPageText) < 1 ||
                      parseInt(pdfSelectedPageText) > pdf.numPages
                    }
                    onClick={() => {
                      const selectedPage = parseInt(pdfSelectedPageText);
                      processPdfSelectedPage(selectedPage);
                    }}
                  >
                    Create Plan
                  </Button>
                </ButtonGroup>
              </AppBarSection>
            </AppBar>
          </AppBarContext.Provider>
        </Modal>
      ) : null}
    </div>
  );
};

const CAD_FILE_EXTENSIONS = ['.png', '.jpg', '.pdf', '.dxf'];

export const CADFileUploader: React.FunctionComponent<{
  onUploadImage: (
    image: HTMLImageElement,
    dataUrl: string,
    sourceFile: File,
    sourceFileDataUrl: string
  ) => void;
  onUploadCADFile: (file: File, rawBytesAsString: string) => void;
  children: (trigger: () => void) => React.ReactNode;
}> = ({ onUploadImage, onUploadCADFile, children }) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);
  const [pdfPageSelectModalVisible, setPdfPageSelectModalVisible] =
    useState<boolean>(false);
  const [pdfSelectedPageText, setPdfSelectedPageText] = useState<string>('');
  const sourceFileObject = useRef<File | null>(null);

  const clearState = useCallback(() => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.value = '';
    }
    setPdf(null);
    setPdfPageSelectModalVisible(false);
  }, [setPdfPageSelectModalVisible, setPdf, hiddenFileInputRef]);

  const onUploadButtonClick = useCallback(() => {
    const input = hiddenFileInputRef.current;
    if (!input) throw new Error('Could not get input element from ref');
    input.click();
  }, [hiddenFileInputRef]);

  const convertDataUrlToImageForUpload = useCallback(
    (dataUrl: string) => {
      // Get the source file object stored in a ref so that it can be passed along to onUploadImage
      if (!sourceFileObject.current) {
        throw new Error('Could not get source file object!');
      }
      const sourceFile: File = sourceFileObject.current;

      const image = new Image();
      image.src = dataUrl;

      image.addEventListener('load', () => {
        // if the image is too large, scale it down
        // this creates a canvas, scales it, and then re-calls this function once the proper dimensions are set
        if (image.width > MAX_IMAGE_WIDTH) {
          const canvas = document.createElement('canvas');
          const scale = MAX_IMAGE_WIDTH / image.width;

          canvas.width = image.width * scale;
          canvas.height = image.height * scale;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            return;
          }

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          // break out (don't upload), and reprocess for upload
          return convertDataUrlToImageForUpload(canvas.toDataURL('image/png'));
        }

        // Make sure the image is converted to a png
        if (!dataUrl.includes('image/png')) {
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            return;
          }

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          // break out (don't upload), and reprocess for upload
          return convertDataUrlToImageForUpload(canvas.toDataURL('image/png'));
        }

        clearState();
        onUploadImage(image, image.src, sourceFile, dataUrl);
      });
    },
    [onUploadImage, clearState]
  );

  const onFileInputChange = useCallback(() => {
    const input = hiddenFileInputRef.current;
    if (!input) throw new Error('Could not get input element from ref');

    const file = input.files?.item(0);
    if (!file) throw new Error('No file selected');

    // NOTE: ideally one would be able to filter by content type here, but that doesn't work because
    // chrome doesn't seem to be able to understand that *.dxf files have the application/dxf
    // content type.
    const endsWithExtension = CAD_FILE_EXTENSIONS.find((e) =>
      file.name.endsWith(e)
    );
    if (!endsWithExtension) {
      toast.error(
        `Please upload a file ending in one of these file extensions: ${CAD_FILE_EXTENSIONS.join(
          ', '
        )}`
      );
      return;
    }

    // Store the file object for later so metadata can be extracted off of it.
    sourceFileObject.current = file;

    const reader = new FileReader();

    reader.addEventListener('load', (evt) => {
      const rawBytesAsString = reader.result;
      if (typeof rawBytesAsString !== 'string') {
        return;
      }

      // convert pdf file to png
      if (file.type === 'application/pdf') {
        pdfjsLib.getDocument(rawBytesAsString).promise.then(setPdf);
      }
      // just a simple png/jpeg, render it to an image
      else if (file.type === 'image/png' || file.type === 'image/jpeg') {
        convertDataUrlToImageForUpload(rawBytesAsString);
      }
      // Parse DXF, and return the raw bytes
      else if (file.name.endsWith('.dxf')) {
        if (!sourceFileObject.current) {
          throw new Error('Could not get source file object!');
        }
        const sourceFile: File = sourceFileObject.current;
        clearState();
        onUploadCADFile(sourceFile, rawBytesAsString);
      }
    });
    reader.readAsDataURL(file);
  }, [convertDataUrlToImageForUpload, clearState, onUploadCADFile]);

  const processPdfSelectedPage = useCallback(
    (selectedPage: number) => {
      if (!pdf) {
        throw new Error('PDF page selected, but no PDF found in state');
      }

      pdf.getPage(selectedPage).then((page) => {
        var viewportParams: GetViewportParameters = { scale: 5 };
        var viewport = page.getViewport(viewportParams);

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          return;
        }
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        page
          .render({ canvasContext: context, viewport: viewport })
          .promise.then(() => {
            const dataUrl = canvas.toDataURL('image/png');

            convertDataUrlToImageForUpload(dataUrl);
          });
      });
    },
    [convertDataUrlToImageForUpload, pdf]
  );

  useEffect(() => {
    if (!pdf) {
      return;
    }

    // more than one page - show the modal to select desired page
    if (pdf.numPages > 1) {
      setPdfPageSelectModalVisible(true);
    }
    // single page, move forward with upload
    else {
      processPdfSelectedPage(1);
    }
  }, [pdf, processPdfSelectedPage]);

  return (
    <div data-cy="cad-file-uploader">
      <input
        ref={hiddenFileInputRef}
        style={{ display: 'none' }}
        type="file"
        // NOTE: ideally one would be able to filter by content type here, but that doesn't work because
        // chrome doesn't seem to be able to understand that *.dxf files have the application/dxf
        // content type, it thinks their content type is an empty string.
        accept=""
        onChange={onFileInputChange}
        data-cy="cad-file-uploader-file"
      />
      {children(onUploadButtonClick)}
      {pdf ? (
        <Modal visible={pdfPageSelectModalVisible}>
          <div className={styles.pdfPageSelectModalBody}>
            <h5 className={styles.pdfPageSelectModalLabel}>
              Select the desired page (1 - {pdf.numPages})
            </h5>
            <input
              className={styles.inputTextSmall}
              type="number"
              min={1}
              max={pdf.numPages}
              step={1}
              data-cy="pdf-page-number"
              onChange={(evt) => {
                setPdfSelectedPageText(evt.currentTarget.value);
              }}
            />
          </div>

          <AppBarContext.Provider value="BOTTOM_ACTIONS">
            <AppBar>
              <AppBarSection></AppBarSection>
              <AppBarSection>
                <ButtonGroup>
                  <Button variant="underline" onClick={() => clearState()}>
                    Cancel
                  </Button>
                  <Button
                    variant="filled"
                    type="primary"
                    disabled={
                      pdfSelectedPageText === '' ||
                      parseInt(pdfSelectedPageText) < 1 ||
                      parseInt(pdfSelectedPageText) > pdf.numPages
                    }
                    onClick={() => {
                      const selectedPage = parseInt(pdfSelectedPageText);
                      processPdfSelectedPage(selectedPage);
                    }}
                    data-cy="pdf-page-submit"
                  >
                    Submit
                  </Button>
                </ButtonGroup>
              </AppBarSection>
            </AppBar>
          </AppBarContext.Provider>
        </Modal>
      ) : null}
    </div>
  );
};

export default ImageUploader;
