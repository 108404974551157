import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Icons } from '@densityco/ui';

import styles from './styles.module.scss';

interface SaveButtonProps {
  saveHandler: () => void;
  savePending: boolean;
}

const SaveButton: React.FC<SaveButtonProps> = ({
  saveHandler,
  savePending,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const clickHandler = () => {
    setButtonDisabled(true);
    saveHandler();
  };

  useEffect(() => {
    let timer: any = null;
    if (buttonDisabled) {
      timer = setTimeout(() => setButtonDisabled(false), 1000);
    }
    return () => clearTimeout(timer);
  }, [buttonDisabled]);

  return (
    <button
      className={classNames([
        styles.buttonSmall,
        styles.success,
        styles.elevated,
      ])}
      onClick={clickHandler}
      disabled={savePending || buttonDisabled}
      data-cy="save-button"
    >
      <div className={styles.icon}>
        <Icons.Save width={18} height={18} color="currentColor" />
      </div>
      {savePending || buttonDisabled ? 'Saving...' : 'Save'}
    </button>
  );
};

export default SaveButton;
