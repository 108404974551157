import {
  protocol,
  decodeFramesFromBufferedPayload,
} from '@densityco/lib-oa-protocol';

export namespace PointCloud {
  export interface Point {
    x: number;
    y: number;
    z: number;
    velocity: number;
    snr: number;
    noise: number;
  }

  export function getPointsFromFrame(frame: protocol.Frame) {
    const points: Array<Point> = [];

    if (
      frame.pointCloud &&
      frame.sideInfo &&
      frame.pointCloud.length &&
      frame.sideInfo.length &&
      frame.pointCloud.length === frame.sideInfo.length
    ) {
      // we're good to continue on...
    } else {
      return points;
    }

    for (let i = 0; i < frame.pointCloud.length; i++) {
      const mmwavePoint = frame.pointCloud[i];
      const { x, y, z, velocity } = mmwavePoint;
      const mmwaveAuxiliary = frame.sideInfo[i];
      const { snr, noise } = mmwaveAuxiliary;
      if (typeof x !== 'number') throw new Error('Missing value for x');
      if (typeof y !== 'number') throw new Error('Missing value for y');
      if (typeof z !== 'number') throw new Error('Missing value for z');
      if (typeof velocity !== 'number')
        throw new Error('Missing value for velocity');
      if (typeof snr !== 'number') throw new Error('Missing value for snr');
      if (typeof noise !== 'number') throw new Error('Missing value for noise');
      points.push({
        x,
        y,
        z,
        velocity,
        snr,
        noise,
      });
    }
    return points;
  }

  export function getFramesFromBuffer(buffer: ArrayBuffer) {
    return decodeFramesFromBufferedPayload(buffer);
  }
}
