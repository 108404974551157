import { createSelector } from '@reduxjs/toolkit';

import { aggregateData } from 'lib/charts';
import { RootState } from 'redux/store';
import { selectTimeZone } from '../spaces/select-timezone';

export const selectAvgDwellForSpace = createSelector(
  [
    (state: RootState) => state.analysis.dwellAnalytics,

    // selectors can compose other selectors wow
    selectTimeZone,

    // parameters
    (_: RootState, params: { spaceId?: string }) => params.spaceId,
  ],
  (dwellAnalytics, timeZone, spaceId) => {
    if (!spaceId) {
      return;
    }

    const data = dwellAnalytics.entities[spaceId]?.data;
    if (!data?.length) {
      return;
    }

    const aggregation = aggregateData(data, timeZone, 'avg', 'day');

    const average =
      aggregation.reduce((acc, curr) => acc + curr.value, 0) /
      aggregation.length;

    return average;
  }
);
