import { v4 as uuidv4 } from 'uuid';
import * as d3 from 'd3';
import colors from '@densityco/ui/variables/colors.json';
import { arrayMove } from 'react-movable';

import { Action } from './actions';
import { PersistedData } from './persisted-data';

import { Floorplan } from 'lib/floorplan';
import { PlacementMode } from 'components/floorplan';
import { LengthUnit, Meters, Seconds } from 'lib/units';
import { Viewport } from 'lib/viewport';
import { isPhotoGroupId, isPhotoGroupPhotoId } from 'lib/api';
import { DEFAULT_PIXELS_PER_CAD_UNIT, ParseDXFOptions } from 'lib/dxf';
import {
  FloorplanCoordinates,
  CADCoordinates,
  ImageCoordinates,
  ViewportCoordinates,
  ViewportVector,
  snapToAngle,
  calculatePolygonCentroid,
} from 'lib/geometry';
import { lineSegmentIntersection2d } from 'lib/algorithm';
import { modulo, degreesToRadians } from 'lib/math';
import { PointCloud } from 'lib/pointcloud';
import { Measurement } from 'components/floorplan-measure/floorplan-measure';
import { FloorplanTargetInfo } from 'components/track-visualizer';
import { Tracks } from 'lib/tracks';
import {
  computeDefaultCADOrigin,
  computeNewFloorplanForCAD,
  CADImportOperationType,
} from 'lib/cad';
import { VERSION } from 'config';
import { OALiveSocketMessage } from 'lib/oa-live-socket';
import { GeoTiffTiepoint } from 'lib/geotiff';

type UndoStack = Array<UndoStack.Item>;
namespace UndoStack {
  export type Item = {
    undo: (state: State) => State;
    redo: (state: State) => State;
  };
}

type ManipulatedObject = {
  itemType: 'sensor' | 'space' | 'reference' | 'photogroup' | 'simulant';
  itemId: string;
  currentPosition: FloorplanCoordinates;
  initialPosition: {
    floorplan: FloorplanCoordinates;
    viewport: ViewportCoordinates;
    clientX: number;
    clientY: number;
  };
  locked?: boolean;
};

export type MappedPoint = {
  timestamp: number;
  sensorId: Sensor['id'];
  floorplanPosition: FloorplanCoordinates;
} & (
  | {
      isSimulated: false;
      sensorPoint?: PointCloud.Point;
    }
  | {
      isSimulated: true;
    }
);

export type AggregatedData = Array<MappedPoint>;
export namespace AggregatedData {
  const MAX_AGE = 10; // seconds

  export function releaseExpiredData(
    data: Array<MappedPoint>
  ): Array<MappedPoint> {
    const now = Seconds.fromMilliseconds(Date.now());
    return data.filter((point) => {
      return now - point.timestamp < MAX_AGE;
    });
  }
}

export type FloorplanCollection<T extends { id: string }> = {
  items: ReadonlyMap<T['id'], T>;
  renderOrder: ReadonlyArray<T['id']>;
};

export namespace FloorplanCollection {
  export function create<T extends { id: string }>(): FloorplanCollection<T> {
    return {
      items: new Map(),
      renderOrder: [],
    };
  }

  export function fromItems<T extends { id: string }>(
    items: Map<T['id'], T>
  ): FloorplanCollection<T> {
    return {
      items,
      renderOrder: Array.from(items.keys()),
    };
  }

  export function addItem<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    item: T
  ): FloorplanCollection<T> {
    const nextItems = new Map(collection.items);
    nextItems.set(item.id, item);
    const nextRenderOrder = [...collection.renderOrder, item.id];
    return {
      ...collection,
      items: nextItems,
      renderOrder: nextRenderOrder,
    };
  }

  export function removeItem<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    itemId: string
  ): FloorplanCollection<T> {
    const nextItems = new Map(collection.items);
    nextItems.delete(itemId);
    const nextRenderOrder = collection.renderOrder.filter(
      (id) => id !== itemId
    );
    return {
      ...collection,
      items: nextItems,
      renderOrder: nextRenderOrder,
    };
  }

  export function updateItem<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    itemId: string,
    update: Update<T>
  ): FloorplanCollection<T> {
    const prevItem = collection.items.get(itemId);
    if (!prevItem)
      throw new Error(`Cannot update item with id ${itemId}, not found`);
    const nextItems = new Map(collection.items);
    nextItems.set(itemId, {
      ...prevItem,
      ...update,
    });
    return {
      ...collection,
      items: nextItems,
    };
  }

  export function sendToFront<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    itemId: string
  ): FloorplanCollection<T> {
    const nextRenderOrder = [
      ...collection.renderOrder.filter((id) => id !== itemId),
      itemId,
    ];
    return {
      ...collection,
      renderOrder: nextRenderOrder,
    };
  }

  export function list<T extends { id: string }>(
    collection: FloorplanCollection<T>
  ): Array<T> {
    return Array.from(collection.items.values());
  }

  export function listInRenderOrder<T extends { id: string }>(
    collection: FloorplanCollection<T>
  ) {
    return collection.renderOrder.reduce<Array<T>>((result, id) => {
      const item = collection.items.get(id);
      if (item) {
        result.push(item);
      }
      return result;
    }, []);
  }

  export function isEmpty<T extends { id: string }>(
    collection: FloorplanCollection<T>
  ): boolean {
    return collection.items.size === 0;
  }

  // NOTE: The idea of changing the id of something gives me (Ryan) a bad feeling. But, until we
  // change how the saving functionality in the app, this is sort of a necessary evil. Because
  // otherwise, client generated uuid-based ids will not match what is on the server after saving.
  export function changeId<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    oldId: T['id'],
    newId: T['id']
  ) {
    const prevItem = collection.items.get(oldId);
    if (!prevItem) {
      if (!collection.items.get(newId)) {
        throw new Error(
          `Cannot change id of item with existing id ${oldId}, not found`
        );
      } else {
        // This likely means that state was already updated with the response of the patch
        return collection;
      }
    }
    const nextItems = new Map(collection.items);
    nextItems.delete(oldId);
    nextItems.set(newId, {
      ...prevItem,
      id: newId,
    });
    return {
      ...collection,
      items: nextItems,
      renderOrder: collection.renderOrder.map((i) => (i === oldId ? newId : i)),
    };
  }
}

export type HeightMap = {
  url: string;
  objectKey: string;
  position: FloorplanCoordinates;
  rotation: number;
  opacity: number;
  notes: string;
  limits:
    | { enabled: false }
    | { enabled: true; minMeters: number; maxMeters: number };
  geotiffTransformationData:
    | { enabled: false }
    | { enabled: true; tiePoint: GeoTiffTiepoint; scale: number };
};

export type SensorCoordinates = {
  type: 'sensor-coordinates';
  x: number;
  z: number;
};
export namespace SensorCoordinates {
  export function create(x: number, z: number): SensorCoordinates {
    return {
      type: 'sensor-coordinates',
      x,
      z,
    };
  }

  export function toFloorplanCoordinates(
    sensorCoordinates: SensorCoordinates,
    sensor: Sensor
  ) {
    const rotationRadians = degreesToRadians(sensor.rotation + 180);
    const x =
      -sensorCoordinates.x * Math.cos(rotationRadians) -
      sensorCoordinates.z * Math.sin(rotationRadians);
    const y =
      -sensorCoordinates.x * Math.sin(rotationRadians) +
      sensorCoordinates.z * Math.cos(rotationRadians);
    return FloorplanCoordinates.create(
      sensor.position.x + x + (sensor.dataNudgeX || 0),
      sensor.position.y + y + (sensor.dataNudgeY || 0)
    );
  }
}

export type SensorType = 'oa' | 'entry';
export namespace SensorType {
  export function generateDisplayName(sensorType: SensorType) {
    return sensorType === 'oa' ? 'OA' : 'Entry';
  }
}

export enum SensorStatus {
  UNCONFIGURED = 'unconfigured',
  PROVISIONING = 'provisioning',
  ERROR = 'error',
  ONLINE = 'online',
  LOW_POWER = 'low_power',
  ARCHIVED = 'archived',
}

export const getSensorStatusColor = (status?: string) => {
  switch (status) {
    case SensorStatus.ONLINE:
      return colors.green;
    case SensorStatus.LOW_POWER:
    case SensorStatus.ERROR:
    case SensorStatus.ARCHIVED:
      return colors.red;
    default:
      return colors.gray400;
  }
};

export type Sensor = {
  id: string;
  type: SensorType;
  name: string;
  position: FloorplanCoordinates;
  // NOTE: height is currently just used for sensors of 'oa' type.
  // 'entry' sensors are always rendered at 40" radius (double door width)
  // 'entry' sensors will have the default OA height applied.
  // a migration to assign actual heights to 'entry' sensors will need to occur
  // if the rendering shifts to using height for those sensors.
  // lib/sensor.ts contains the code that applies a constant coverage radius of 40"
  height: number;
  rotation: number;
  // NOTE: nudge is applied after rotation
  dataNudgeX?: number;
  dataNudgeY?: number;
  serialNumber: string | null;
  locked: boolean;
  last_heartbeat?: string | null;
  status?: SensorStatus;
  ipv4?: string | null;
  ipv6?: string | null;
  mac?: string | null;
  os?: string | null;
  notes: string;
  plan_sensor_index?: number | null;
  cadId: string;
  boundingBoxFilter: 'none' | 'cloud' | 'device';
};

export namespace Sensor {
  export function default_height(sensorType: SensorType) {
    if (sensorType === 'oa') {
      return Meters.fromFeet(10);
    } else {
      //TODO
      return Meters.fromInches(102);
    }
  }

  export function min_height(sensorType: SensorType) {
    if (sensorType === 'oa') {
      return Meters.fromFeet(7);
    } else {
      return Meters.fromInches(90);
    }
  }

  export function max_height(sensorType: SensorType) {
    if (sensorType === 'oa') {
      return Meters.fromFeet(12);
    } else {
      return Meters.fromInches(120);
    }
  }

  export function generateName(
    sensorType: SensorType,
    numExistingSensors: number
  ) {
    const sensorTypeText = SensorType.generateDisplayName(sensorType);
    return `${sensorTypeText} Sensor ${numExistingSensors + 1}`;
  }

  export function create(
    type: SensorType,
    position: FloorplanCoordinates,
    height: number,
    rotation: number,
    name: string,
    notes: string = '',
    cadId: string = ''
  ): Sensor {
    const id = uuidv4();
    return {
      id,
      type,
      name,
      position,
      height,
      rotation,
      dataNudgeX: 0,
      dataNudgeY: 0,
      serialNumber: null,
      locked: false,
      last_heartbeat: null,
      status: SensorStatus.UNCONFIGURED,
      ipv4: null,
      ipv6: null,
      mac: null,
      os: null,
      notes,
      plan_sensor_index: null,
      cadId,
      boundingBoxFilter: 'none',
    };
  }

  export function filterByType(
    type: SensorType,
    sensors: FloorplanCollection<Sensor>
  ) {
    return FloorplanCollection.list(sensors).filter((sensor) => {
      return sensor.type === type;
    });
  }
}

export type SensorStreamingStatus = 'connecting' | 'connected' | 'disconnected';
export type SensorConnection = {
  status: SensorStreamingStatus;
  serialNumber: string;
};

export type SensorCoverageIntersectionVectors = Array<[number, Array<number>]>;

type Update<T extends { id: string }> = Omit<Partial<T>, 'id'>;

enum CursorType {
  DEFAULT,
  GRABBABLE,
  GRABBING,
  PLACE_OBJECT,
  TOP_HANDLE,
  RIGHT_HANDLE,
  BOTTOM_HANDLE,
  LEFT_HANDLE,
  TOP_LEFT_HANDLE,
  TOP_RIGHT_HANDLE,
  BOTTOM_RIGHT_HANDLE,
  BOTTOM_LEFT_HANDLE,
  NOT_ALLOWED,
  POINTER,
  COPY,
  MOVE,
}

type Cursor =
  | 'default'
  | 'pointer'
  | 'grab'
  | 'grabbing'
  | 'crosshair'
  | 'ew-resize'
  | 'ns-resize'
  | 'nwse-resize'
  | 'nesw-resize'
  | 'not-allowed'
  | 'copy'
  | 'move'
  | 'pointer';

const cursors: Record<CursorType, Cursor> = {
  [CursorType.DEFAULT]: 'default',
  [CursorType.GRABBABLE]: 'grab',
  [CursorType.GRABBING]: 'grabbing',
  [CursorType.PLACE_OBJECT]: 'crosshair',
  [CursorType.TOP_HANDLE]: 'ns-resize',
  [CursorType.RIGHT_HANDLE]: 'ew-resize',
  [CursorType.BOTTOM_HANDLE]: 'ns-resize',
  [CursorType.LEFT_HANDLE]: 'ew-resize',
  [CursorType.TOP_LEFT_HANDLE]: 'nwse-resize',
  [CursorType.TOP_RIGHT_HANDLE]: 'nesw-resize',
  [CursorType.BOTTOM_RIGHT_HANDLE]: 'nwse-resize',
  [CursorType.BOTTOM_LEFT_HANDLE]: 'nesw-resize',
  [CursorType.NOT_ALLOWED]: 'not-allowed',
  [CursorType.COPY]: 'copy',
  [CursorType.MOVE]: 'move',
  [CursorType.POINTER]: 'pointer',
};

export type ResizeHandleType =
  | 'all'
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'topleft'
  | 'topright'
  | 'bottomright'
  | 'bottomleft';

function getCursorTypeForHandle(handle: ResizeHandleType): CursorType {
  switch (handle) {
    case 'all':
      return CursorType.MOVE;
    case 'top':
      return CursorType.TOP_HANDLE;
    case 'right':
      return CursorType.RIGHT_HANDLE;
    case 'bottom':
      return CursorType.BOTTOM_HANDLE;
    case 'left':
      return CursorType.LEFT_HANDLE;
    case 'topleft':
      return CursorType.TOP_LEFT_HANDLE;
    case 'topright':
      return CursorType.TOP_RIGHT_HANDLE;
    case 'bottomright':
      return CursorType.BOTTOM_RIGHT_HANDLE;
    case 'bottomleft':
      return CursorType.BOTTOM_LEFT_HANDLE;
  }
}

export function getCursorForHandle(handle: ResizeHandleType, locked: boolean) {
  if (locked) {
    return cursors[CursorType.NOT_ALLOWED];
  } else {
    return cursors[getCursorTypeForHandle(handle)];
  }
}

export function getCursorForObject(
  object: 'reference' | 'sensor' | 'space' | 'photogroup',
  manipulated: boolean,
  locked?: boolean
) {
  if (locked) {
    return cursors[CursorType.POINTER];
  }
  if (manipulated) {
    return cursors[CursorType.GRABBING];
  }
  switch (object) {
    default:
      return cursors[CursorType.GRABBABLE];
  }
}

export type SpaceShape =
  | {
      type: 'box';
      width: number;
      height: number;
    }
  | {
      type: 'circle';
      radius: number;
    }
  | {
      type: 'polygon';
      vertices: Array<FloorplanCoordinates>;
    };

export type Space = {
  id: string;
  name: string;
  position: FloorplanCoordinates;
  shape: SpaceShape;
  locked: boolean;
};

export namespace Space {
  export const MIN_RADIUS = Meters.fromFeet(1);
  export const MIN_WIDTH = Meters.fromFeet(2);
  export const MIN_HEIGHT = Meters.fromFeet(2);
  export const DEFAULT_BOX_SHAPE = {
    width: Meters.fromFeet(3),
    height: Meters.fromFeet(3),
  };
  export const DEFAULT_CIRCLE_SHAPE = {
    radius: Meters.fromFeet(1.5),
  };

  export function generateName(
    existingSpaces: FloorplanCollection<Space>
  ): string {
    // Looks for the highest space named "Space X" and returns Space X+1
    let maxNumFound = 0;
    for (let space of FloorplanCollection.list(existingSpaces)) {
      let result = space.name.match(/^Space (\d+)/);
      if (result && result.length === 2) {
        // the second result should be the capture group which is the number
        const spaceNum = parseInt(result[1], 10);
        if (spaceNum > maxNumFound) {
          maxNumFound = spaceNum;
        }
      }
    }
    return `Space ${maxNumFound + 1}`;
  }

  export function createBox(
    position: FloorplanCoordinates,
    name: string,
    width: number,
    height: number
  ): Space {
    const id = uuidv4();
    return {
      id,
      name,
      position,
      shape: {
        type: 'box',
        width,
        height,
      },
      locked: false,
    };
  }

  export function createCircle(
    position: FloorplanCoordinates,
    name: string,
    radius: number
  ): Space {
    const id = uuidv4();
    return {
      id,
      name,
      position,
      shape: {
        type: 'circle',
        radius,
      },
      locked: false,
    };
  }

  export function createPolygon(
    vertices: Array<FloorplanCoordinates>,
    name: string
  ): Space {
    const id = uuidv4();
    return {
      id,
      name,
      position: calculatePolygonCentroid(vertices),
      shape: {
        type: 'polygon',
        vertices,
      },
      locked: false,
    };
  }

  // Generate pairs of points that make up all edges of a polygonal space
  export function polygonEdges(
    vertices: Array<FloorplanCoordinates>
  ): Array<[FloorplanCoordinates, FloorplanCoordinates]> {
    if (vertices.length <= 1) {
      return [];
    } else if (vertices.length === 2) {
      return [[vertices[0], vertices[1]]];
    } else {
      const vertexPairs: Array<[FloorplanCoordinates, FloorplanCoordinates]> =
        [];
      for (let i = 0, j = 1; j < vertices.length; i++, j++) {
        vertexPairs.push([vertices[i], vertices[j]]);
      }
      vertexPairs.push([vertices[vertices.length - 1], vertices[0]]);
      return vertexPairs;
    }
  }
}

interface LastSensorIndices {
  lastOASensorIndex?: number;
  lastEntrySensorIndex?: number;
}

interface ReferenceBase {
  id: string;
  enabled: boolean;
}

export interface ReferencePoint extends ReferenceBase {
  type: 'point';
  position: FloorplanCoordinates;
}

export interface ReferenceRuler extends ReferenceBase {
  type: 'ruler';
  positionA: FloorplanCoordinates;
  positionB: FloorplanCoordinates;

  distanceLabelPosition: FloorplanCoordinates;

  currentCursorPosition?: FloorplanCoordinates;
  currentIsDistanceLockedToCenter?: boolean;
}

export type Reference = ReferencePoint | ReferenceRuler;

export const Reference = {
  createPoint(position: FloorplanCoordinates): Reference {
    const id = uuidv4();
    return {
      id,
      enabled: true,
      type: 'point',
      position,
    };
  },
  createRuler(
    positionA: FloorplanCoordinates,
    positionB: FloorplanCoordinates,
    distanceLabelPosition?: FloorplanCoordinates
  ): Reference {
    const id = uuidv4();
    return {
      id,
      type: 'ruler',
      positionA,
      positionB,
      enabled: true,

      // By default, position the dimension in between the endpoints
      distanceLabelPosition:
        distanceLabelPosition ||
        ReferenceRuler.calculateCenterPoint({ positionA, positionB }),
    };
  },
  toggle(reference: Reference): Reference {
    return {
      ...reference,
      enabled: !reference.enabled,
    };
  },
};

const REFRENCE_DISTANCE_LABEL_CENTER_SNAP_PX = 32;

export const ReferenceRuler = {
  calculateCenterPoint(
    referenceRuler: Pick<ReferenceRuler, 'positionA' | 'positionB'>
  ): FloorplanCoordinates {
    return FloorplanCoordinates.create(
      (referenceRuler.positionA.x + referenceRuler.positionB.x) / 2,
      (referenceRuler.positionA.y + referenceRuler.positionB.y) / 2
    );
  },

  // Returns a boolean indicating if the label showing the length of a reference line should snap to
  // the center of the reference line when moving it around the plan.
  isDistanceLockedToCenter(
    floorplan: Floorplan,
    viewport: Viewport,
    distanceLabelPosition: FloorplanCoordinates,
    centerPoint: FloorplanCoordinates
  ): boolean {
    const centerPointViewport = FloorplanCoordinates.toViewportCoordinates(
      centerPoint,
      floorplan,
      viewport
    );
    const distanceLabelPositionViewport =
      FloorplanCoordinates.toViewportCoordinates(
        distanceLabelPosition,
        floorplan,
        viewport
      );

    const distanceFromCenter = Math.hypot(
      Math.abs(centerPointViewport.y - distanceLabelPositionViewport.y),
      Math.abs(centerPointViewport.x - distanceLabelPositionViewport.x)
    );

    return distanceFromCenter < REFRENCE_DISTANCE_LABEL_CENTER_SNAP_PX;
  },
};

export type PhotoGroup = {
  id: string;
  name: string;
  notes: string;
  locked: boolean;
  position: FloorplanCoordinates;
  photos: Array<PhotoGroupPhoto>;

  // Used for internal bookkeeping
  operationToPerform: null | 'create' | 'update';
  photoIdsToDelete: Array<PhotoGroupPhoto['id']>;
};
export const PhotoGroup = {
  create(name: PhotoGroup['name'], position: FloorplanCoordinates): PhotoGroup {
    const id = uuidv4();
    return {
      id,
      position,
      name,
      locked: false,
      notes: '',
      photos: [],

      // Used for internal bookkeeping
      operationToPerform: 'create',
      photoIdsToDelete: [],
    };
  },
  generateName(existingPhotoGroups: FloorplanCollection<PhotoGroup>): string {
    // Looks for the highest photo group named "PGX" and returns PG(X+1)
    let maxNumFound = 0;
    for (let photoGroup of FloorplanCollection.list(existingPhotoGroups)) {
      let result = photoGroup.name.match(/^Group (\d+)$/);
      if (result && result.length === 2) {
        // the second result should be the capture group which is the number
        const spaceNum = parseInt(result[1], 10);
        if (spaceNum > maxNumFound) {
          maxNumFound = spaceNum;
        }
      }
    }
    return `Group ${maxNumFound + 1}`;
  },
  computeOperationAfterModification(
    existingOperation: PhotoGroup['operationToPerform']
  ): PhotoGroup['operationToPerform'] {
    if (existingOperation === 'create') {
      return 'create';
    } else {
      return 'update';
    }
  },
  resetOperationAfterSave(photoGroup: PhotoGroup): PhotoGroup {
    return {
      ...photoGroup,
      operationToPerform: null,
      photos: photoGroup.photos.map((photo) =>
        PhotoGroupPhoto.resetOperationAfterSave(photo)
      ),
    };
  },

  appendPhoto(photoGroup: PhotoGroup, photo: PhotoGroupPhoto): PhotoGroup {
    return {
      ...photoGroup,
      photos: [...photoGroup.photos, photo],
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
  removePhoto(
    photoGroup: PhotoGroup,
    photoId: PhotoGroupPhoto['id']
  ): PhotoGroup {
    let photoIdsToDelete = photoGroup.photoIdsToDelete;
    if (isPhotoGroupPhotoId(photoId)) {
      // Before marking the photo for deletion, make sure that the photo has indeed been uploaded to
      // the server first.
      const photo = photoGroup.photos.find((photo) => photo.id === photoId);
      if (!photo) {
        return photoGroup;
      }
      const photoLinkedToPhotoGroupOnServer = !photo.image.dirty;
      if (photoLinkedToPhotoGroupOnServer) {
        photoIdsToDelete = [...photoIdsToDelete, photoId];
      }
    }

    return {
      ...photoGroup,
      photoIdsToDelete,
      photos: photoGroup.photos.filter((photo) => photo.id !== photoId),
    };
  },
  getPhotoById(
    photoGroup: PhotoGroup,
    photoId: PhotoGroupPhoto['id']
  ): PhotoGroupPhoto | null {
    const photo = photoGroup.photos.find((photo) => photo.id === photoId);
    return photo || null;
  },

  lock(photoGroup: PhotoGroup): PhotoGroup {
    return {
      ...photoGroup,
      locked: true,
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
  unlock(photoGroup: PhotoGroup): PhotoGroup {
    return {
      ...photoGroup,
      locked: false,
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
  changeName(photoGroup: PhotoGroup, name: string): PhotoGroup {
    return {
      ...photoGroup,
      name,
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
  changeNotes(photoGroup: PhotoGroup, notes: string): PhotoGroup {
    return {
      ...photoGroup,
      notes,
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
};

export type PhotoGroupPhoto = {
  id: string;
  name: string;
  image: { dirty: true; dataUrl: string } | { dirty: false; url: string };
  // NOTE: photos are created initially when uploading the image to the server, and only ever need
  // to be "updated" when their name changes.
  operationToPerform: null | 'update';
};
export const PhotoGroupPhoto = {
  createFromUploadedPhotoId(
    id: string,
    name: string,
    photoDataUrl: string
  ): PhotoGroupPhoto {
    return {
      id,
      name,
      image: { dirty: true, dataUrl: photoDataUrl },
      operationToPerform: 'update',
    };
  },
  createFromImageUrl(id: string, name: string, url: string): PhotoGroupPhoto {
    return {
      id,
      name,
      image: { dirty: false, url },
      operationToPerform: null,
    };
  },
  updateName(photo: PhotoGroupPhoto, name: string): PhotoGroupPhoto {
    return { ...photo, name, operationToPerform: 'update' };
  },
  resetOperationAfterSave(photo: PhotoGroupPhoto): PhotoGroupPhoto {
    return { ...photo, operationToPerform: null };
  },
};

export type ProcessedCADSensorPlacement = Pick<
  Sensor,
  'serialNumber' | 'height' | 'rotation' | 'type' | 'cadId'
> & {
  position: CADCoordinates;
};

type PlanDXFSensorPlacementDataTag = {
  height_meters: number;
  position: { x: number; y: number };
  cad_id: string;
  serial_number: string | null;
};

type PlanDXFSensorPlacement = {
  data_tag: PlanDXFSensorPlacementDataTag;
  sensor_type: 'entry' | 'oa';
  position: { x: number; y: number };
  rotation: number;
};

export type PlanDXF = {
  id: string;
  status:
    | 'created'
    | 'downloading'
    | 'parsing_dxf'
    | 'processing_dxfs'
    | 'processing_images'
    | 'complete'
    | 'error';
  format: 'autocad' | 'vectorworks' | 'plain';
  options: ParseDXFOptions;
  default_openarea_sensor_layer: string;
  default_entry_sensor_layer: string;
  layer_names: Array<string>;
  frozen_layer_names: Array<string>;
  length_unit: LengthUnit;
  scale: number;
  extent_min_x: number;
  extent_min_y: number;
  extent_max_x: number;
  extent_max_y: number;
  dxf_version: string;
  dxf_header: { [key: string]: string };
  started_processing_at: string | null;
  completed_processing_at: string | null;
  created_at: string;
  updated_at: string;
  sensor_placements: Array<PlanDXFSensorPlacement>;
  assets: Array<PlanDXFAsset>;
};

export type PlanDXFAsset = {
  id: string;
  name: string;
  content_type: string;
  object_key: string;
  object_url: string;
  layer_name: string | null;
  pixels_per_unit: number | null;
};

export type PlanExport = {
  id: string;
  status:
    | 'created'
    | 'fetching'
    | 'processing'
    | 'uploading'
    | 'complete'
    | 'error';
  options: {
    include_coverage: boolean;
  };
  content_type: 'application/dxf';
  started_processing_at: string | null;
  completed_processing_at: string | null;
  created_at: string;
  updated_at: string;
  exported_object_key: string;
  exported_object_url: string;
};

export enum LayerId {
  HEIGHTMAP = 'heightmap',
}

export type SensorPoint = {
  frameNumber: number;
  x: number;
  y: number;
  z: number;
  velocity: number;
  snr: number;
  noise: number;
};

export type Simulant = {
  id: string;
  position: FloorplanCoordinates;
};

export namespace Simulant {
  export function create(position: FloorplanCoordinates): Simulant {
    const id = uuidv4();
    return {
      id,
      position,
    };
  }

  export function generatePoints(simulant: Simulant, numPoints: number) {
    const randomAngle = d3.randomUniform(0, Math.PI * 2);
    const randomRadius = d3.randomNormal(0, Meters.fromInches(6));
    return d3.range(numPoints).map(() => {
      const r = randomRadius();
      const a = randomAngle();
      return FloorplanCoordinates.create(
        r * Math.cos(a) + simulant.position.x,
        r * Math.sin(a) + simulant.position.y
      );
    });
  }
}

export type TimestampedFrameInfo = {
  timestamp: number;
  frame: {
    number: number;
    buffer: ArrayBuffer;
  };
};

export type AutoLayout = {
  boundingRegionPlacementEnabled: boolean;
  boundingRegionVertices: Array<FloorplanCoordinates>;
  minimumExclusiveArea: number;
  sensorHeight: number;
  originPosition: FloorplanCoordinates | null;
  cadIdPrefix: string;

  heightMapEnabled: boolean;
};

export type State = {
  unsavedModifications: boolean;
  floorplan: Floorplan;
  floorplanImage: HTMLImageElement | null;
  floorplanImageOpacity: number;
  floorplanCADOrigin: FloorplanCoordinates;
  heightMap: { enabled: false } | ({ enabled: true } & HeightMap);
  heightMapUpdated: boolean;
  viewport: Viewport;
  viewportVelocity: ViewportVector;
  gridSize: number;
  spaces: FloorplanCollection<Space>;
  sensors: FloorplanCollection<Sensor>;
  sensorConnections: Map<Sensor['id'], SensorConnection>;
  sensorCoverageIntersectionVectors: Map<
    Sensor['id'],
    'empty' | 'loading' | SensorCoverageIntersectionVectors
  >;
  lastSensorIndices?: {
    lastOASensorIndex?: number;
    lastEntrySensorIndex?: number;
  };
  references: FloorplanCollection<Reference>;
  photoGroups: FloorplanCollection<PhotoGroup>;
  photoGroupIdsToDelete: Array<PhotoGroup['id']>;
  duplicateSensorParams: { rotation: number; height: number } | null;
  duplicateSpaceBoxParams: { width: number; height: number } | null;
  duplicateSpaceCircleParams: { radius: number } | null;
  duplicateSpacePolygonParams: {
    vertices: Array<FloorplanCoordinates>;
    originalPosition: FloorplanCoordinates;
  } | null;
  objectListType: 'sensor' | 'space' | 'reference' | 'photogroup' | 'layer';
  saveMenu: null | {
    name: string;
  };
  placementMode: null | PlacementMode;
  highlightedObject: null | {
    type: 'sensor' | 'space' | 'photogroup' | 'reference';
    id: string;
  };
  focusedObject: null | {
    type: 'sensor' | 'space' | 'layer';
    id: string;
  };
  // The focused photo group is kept track of seperately because a photo group can be focused at the
  // same time as a sensor / space
  focusedPhotoGroupId: null | string;
  spaceOccupancy: ReadonlyMap<
    Space['id'],
    {
      occupied: boolean;
      confidence: number;
      dwellTime: number;
      lastUpdate: number;
    }
  >;
  // TODO: use manipulatedObject for cursor setting instead
  cursorOverride: null | Cursor;
  aggregatedPointsData: AggregatedData;
  aggregatedTracksData: Array<FloorplanTargetInfo>;
  simulation: {
    enabled: boolean;
    menuOpen: boolean;
    simBoundariesShown: boolean;
  };
  simulants: Array<Simulant>;
  streaming: {
    showTracks: boolean;
    showPoints: boolean;
  };
  planning: {
    showSensors: boolean;
    showOASensors: boolean;
    showSensorLabels: boolean;
    showSensorCoverage: boolean;
    showSensorCoverageExtents: boolean;
    showEntrySensors: boolean;
    showSpaces: boolean;
    showRulers: boolean;
    showScale: boolean;
    showPhotoGroups: boolean;
    showCeilingHeightMap: boolean;
  };
  undoStack: UndoStack;
  manipulatedObject: null | ManipulatedObject;
  measurement?: Measurement;
  visualization: {
    colorScaleDomain: [number, number];
    frameWindowSize: number;
    snrThreshold: number;
  };
  showInternalTool: boolean;
  modifierKeys: {
    alt: boolean;
    shift: boolean;
    ctrl: boolean;
    meta: boolean;
  };
  displayUnit: LengthUnit;
  // The below is duplicating `PlanState.status` but there doesn't seem to be a great way of
  // accessing that state in the Editor component
  savePending: boolean;
  boundingBoxFilter: 'none';

  scaleEdit:
    | { active: false }
    | {
        active: true;
        floorplanImage: HTMLImageElement;
        floorplan: Floorplan;
        objectKey?: string;
      };

  latestDXF:
    | { status: 'uploading'; fileUploadPercent?: number }
    | { status: 'upload_error' }
    | {
        status: PlanDXF['status'];
        id: PlanDXF['id'];
        createdAt: PlanDXF['created_at'];
        parseOptions: PlanDXF['options'];
      }
    | null;
  latestDXFEdit:
    | { active: false }
    | {
        active: true;
        planDXF: PlanDXF;
        cadFileUnit: LengthUnit;
        cadFileScale: number;
        pixelsPerCADUnit: number;
        floorplanCADOrigin: FloorplanCoordinates;
        operationType: CADImportOperationType;
        parseOptions: ParseDXFOptions;
        loading: boolean;
      };
  activeDXFId: PlanDXF['id'] | null;
  activeDXFFullRasterUrl: PlanDXFAsset['object_url'] | null;

  activeExport:
    | { status: 'requesting' }
    | { status: 'request-failed' }
    | PlanExport
    | { status: 'saving' }
    | { status: 'saving-complete' }
    | { status: 'saving-failed' }
    | null;

  heightMapImport:
    | { view: 'disabled' }
    | { view: 'uploading-image'; fileName: string; fileUploadPercent?: number }
    | ({ view: 'ready' } & HeightMap)
    | ({ view: 'enabled' } & HeightMap);

  autoLayout: { active: false } | (AutoLayout & { active: true });
  autoLayoutSensorPositions: Array<
    [FloorplanCoordinates, number, Array<[number, number]>]
  > | null;
  autoLayoutSensorPositionsDone: boolean;
};

export namespace State {
  export function getInitialState(
    floorplan: Floorplan,
    viewport: Viewport,
    floorplanImage: HTMLImageElement | null,
    measurement?: Measurement,
    lastSensorIndices?: LastSensorIndices
  ): State {
    return {
      unsavedModifications: false,
      floorplan,
      floorplanImage,
      floorplanImageOpacity: 0.5,
      floorplanCADOrigin: computeDefaultCADOrigin(floorplan),
      heightMap: { enabled: false },
      heightMapUpdated: false,
      viewport,
      viewportVelocity: new ViewportVector(0, 0),
      gridSize: Meters.fromInches(6),
      objectListType: 'sensor',
      duplicateSensorParams: null,
      duplicateSpaceBoxParams: null,
      duplicateSpaceCircleParams: null,
      duplicateSpacePolygonParams: null,
      sensors: FloorplanCollection.create(),
      sensorConnections: new Map(),
      sensorCoverageIntersectionVectors: new Map(),
      lastSensorIndices,
      spaces: FloorplanCollection.create(),
      references: FloorplanCollection.create(),
      photoGroups: FloorplanCollection.create(),
      photoGroupIdsToDelete: [],
      placementMode: null,
      highlightedObject: null,
      focusedObject: null,
      focusedPhotoGroupId: null,
      cursorOverride: null,
      saveMenu: null,
      spaceOccupancy: new Map(),
      aggregatedPointsData: [],
      aggregatedTracksData: [],
      simulation: {
        enabled: false,
        menuOpen: false,
        simBoundariesShown: false,
      },
      simulants: [],
      streaming: {
        showTracks: false,
        showPoints: true,
      },
      planning: {
        showSensors: true,
        showOASensors: true,
        showSensorLabels: true,
        showSensorCoverage: true,
        showSensorCoverageExtents: false,
        showEntrySensors: true,
        showSpaces: true,
        showRulers: true,
        showScale: true,
        showPhotoGroups: true,
        showCeilingHeightMap: false,
      },
      undoStack: [],
      manipulatedObject: null,
      measurement,
      visualization: {
        colorScaleDomain: [3, 8],
        frameWindowSize: 1,
        snrThreshold: 0,
      },
      showInternalTool: false,
      modifierKeys: {
        alt: false,
        shift: false,
        ctrl: false,
        meta: false,
      },
      displayUnit: 'feet_and_inches',
      savePending: false,
      boundingBoxFilter: 'none',
      scaleEdit: { active: false },
      latestDXF: null,
      latestDXFEdit: { active: false },
      activeDXFId: null,
      activeDXFFullRasterUrl: null,
      activeExport: null,
      heightMapImport: { view: 'disabled' },
      autoLayout: { active: false },
      autoLayoutSensorPositions: null,
      autoLayoutSensorPositionsDone: false,
    };
  }

  export function loadFromPersistedData(
    data: unknown,
    floorplanImage: HTMLImageElement,
    lastSensorIndices?: LastSensorIndices
  ): State {
    const defaultViewport: Viewport = {
      width: 0,
      height: 0,
      zoom: 1,
      top: 0,
      left: 0,
    };
    if (PersistedData.isV1(data)) {
      const floorplan = {
        width: data.imageSize.width,
        height: data.imageSize.height,
        origin: ImageCoordinates.create(0, 0),
        scale: data.imageScale,
      };
      const initialState = State.getInitialState(
        floorplan,
        defaultViewport,
        floorplanImage,
        data.measurement,
        lastSensorIndices
      );

      // the v1 data model has separate keys for points and rulers
      //     references = points, referenceRulers = rulers
      // this combines those arrays for creating References in state
      let referencesArray: (readonly [string, Reference])[] =
        data.references.map((r) => {
          const reference: Reference = {
            id: r.id,
            type: 'point',
            enabled: r.enabled,
            position: ImageCoordinates.toFloorplanCoordinates(
              ImageCoordinates.create(r.position.x, r.position.y),
              floorplan
            ),
          };
          return [reference.id, reference] as const;
        });

      if (data.referenceRulers) {
        referencesArray = [
          ...referencesArray,
          ...data.referenceRulers.map((r) => {
            const positionA = ImageCoordinates.toFloorplanCoordinates(
              ImageCoordinates.create(r.positionA.x, r.positionA.y),
              floorplan
            );
            const positionB = ImageCoordinates.toFloorplanCoordinates(
              ImageCoordinates.create(r.positionB.x, r.positionB.y),
              floorplan
            );

            const distanceLabelPosition = ReferenceRuler.calculateCenterPoint({
              positionA,
              positionB,
            });

            const reference: Reference = {
              id: r.id,
              type: 'ruler',
              enabled: r.enabled,
              positionA,
              positionB,

              // By default, position the dimension in between the endpoints
              distanceLabelPosition,
            };
            return [reference.id, reference] as const;
          }),
        ];
      }

      let references = FloorplanCollection.fromItems(new Map(referencesArray));

      return {
        ...initialState,
        unsavedModifications: false,
        floorplan,
        sensors: FloorplanCollection.fromItems(
          new Map(
            data.sensors.map((s) => {
              const isLinked = s.serialNumber !== null && s.serialNumber !== '';

              const sensor: Sensor = {
                id: s.id,
                type: s.type || 'oa',
                name: s.name,
                serialNumber: isLinked ? s.serialNumber : null,
                position: ImageCoordinates.toFloorplanCoordinates(
                  ImageCoordinates.create(s.position.x, s.position.y),
                  floorplan
                ),
                rotation: s.rotation,
                height: s.height,
                dataNudgeX: 0,
                dataNudgeY: 0,
                locked: s.locked || false,
                last_heartbeat: s.last_heartbeat,
                status: s.status,
                ipv4: s.ipv4,
                ipv6: s.ipv6,
                mac: s.mac,
                os: s.os,
                notes: s.notes || '',
                plan_sensor_index: s.plan_sensor_index,
                cadId: '',
                boundingBoxFilter: 'none',
              };
              return [sensor.id, sensor] as const;
            })
          )
        ),
        sensorConnections: new Map(
          data.sensors
            .filter((s) => s.serialNumber !== null && s.serialNumber !== '')
            .map((s) => {
              return [
                s.id,
                {
                  status: 'connecting',
                  serialNumber: s.serialNumber,
                },
              ];
            })
        ),
        lastSensorIndices: {
          lastOASensorIndex: data.last_oa_sensor_index,
          lastEntrySensorIndex: data.last_entry_sensor_index,
        },
        spaces: FloorplanCollection.fromItems(
          new Map(
            data.spaces.map((s) => {
              const space: Space = {
                id: s.id,
                name: s.name,
                position: ImageCoordinates.toFloorplanCoordinates(
                  ImageCoordinates.create(s.position.x, s.position.y),
                  floorplan
                ),
                shape: s.shape,
                locked: s.locked || false,
              };
              return [space.id, space] as const;
            })
          )
        ),
        references,
        spaceOccupancy: new Map(
          data.spaces.map((s) => {
            return [
              s.id,
              {
                occupied: false,
                confidence: 0,
                dwellTime: 0,
                lastUpdate: 0,
              },
            ] as const;
          })
        ),
      };
    } else if (PersistedData.isV0(data)) {
      const floorplan = data.floorplan;
      const initialState = State.getInitialState(
        floorplan,
        defaultViewport,
        floorplanImage
      );
      return {
        ...initialState,
        unsavedModifications: false,
        sensors: FloorplanCollection.fromItems(
          new Map(
            data.sensors.map(([id, s]) => {
              const {
                connection,
                dataNudgeX,
                dataNudgeY,
                frameWindowLength,
                ...savedSensor
              } = s;
              const sensor: Sensor = {
                ...savedSensor,
                type: s.type || 'oa',
                dataNudgeX: 0,
                dataNudgeY: 0,
                locked: false,
                notes: '',
                cadId: '',
                // last_heartbeat: s.last_heartbeat || null,
                boundingBoxFilter: 'none',
              };
              return [sensor.id, sensor] as const;
            })
          )
        ),
        sensorConnections: new Map(),
        spaces: FloorplanCollection.fromItems(
          new Map(
            data.spaces.map(([id, s]) => {
              const space: Space = {
                ...s,
                locked: false,
              };
              return [space.id, space] as const;
            })
          )
        ),
        references: FloorplanCollection.fromItems(
          new Map(
            data.references.map(([rId, r]) => {
              const reference: Reference = {
                id: rId,
                type: 'point',
                enabled: r.enabled,
                position: r.position,
              };
              return [reference.id, reference] as const;
            })
          )
        ),
        spaceOccupancy: new Map(
          data.spaces.map(([spaceId]) => [
            spaceId,
            {
              occupied: false,
              confidence: 0,
              dwellTime: 0,
              lastUpdate: 0,
            },
          ])
        ),
      };
    } else {
      throw new Error('Could not load data from saved file.');
    }
  }

  export function saveToPersistedData(state: State): PersistedData.v1 {
    const canvas = document.createElement('canvas');
    const floorplanImage = state.floorplanImage;
    if (!floorplanImage) {
      throw new Error('Floorplan Image is null.');
    }
    canvas.width = floorplanImage.width;
    canvas.height = floorplanImage.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get canvas 2D context');
    ctx.drawImage(floorplanImage, 0, 0);

    const imageData = canvas.toDataURL('image/png');

    return {
      version: '1',
      appVersion: VERSION || '',
      millisecondsSinceEpoch: Date.now(),
      title: 'Untitled',
      sensors: Array.from(state.sensors.items).map(([, s]) => {
        const imageCoords = FloorplanCoordinates.toImageCoordinates(
          s.position,
          state.floorplan
        );
        const sensor: PersistedData.v1.Sensor = {
          id: s.id,
          type: s.type,
          name: s.name,
          serialNumber: s.serialNumber || '',
          position: {
            type: 'image-coordinates',
            x: imageCoords.x,
            y: imageCoords.y,
          },
          rotation: s.rotation,
          height: s.height,
          locked: s.locked || false,
          last_heartbeat: s.last_heartbeat || null,
          status: s.status,
          ipv4: s.ipv4 || null,
          ipv6: s.ipv6 || null,
          mac: s.mac || null,
          os: s.os || null,
          plan_sensor_index: s.plan_sensor_index,
        };
        return sensor;
      }),

      // simulants are now ephemeral and not persisted.
      simulants: [],
      spaces: Array.from(state.spaces.items).map(([, s]) => {
        const imageCoords = FloorplanCoordinates.toImageCoordinates(
          s.position,
          state.floorplan
        );
        const space: PersistedData.v1.Space = {
          id: s.id,
          name: s.name,
          shape: s.shape,
          position: {
            type: 'image-coordinates',
            x: imageCoords.x,
            y: imageCoords.y,
          },
          locked: s.locked || false,
        };
        return space;
      }),
      references: FloorplanCollection.list(state.references)
        .filter((r): r is ReferencePoint => {
          return r.type === 'point';
        })
        .map((r) => {
          const imageCoords = FloorplanCoordinates.toImageCoordinates(
            r.position,
            state.floorplan
          );
          const reference: PersistedData.v1.ReferencePoint = {
            id: r.id,
            enabled: r.enabled,
            position: {
              type: 'image-coordinates',
              x: imageCoords.x,
              y: imageCoords.y,
            },
          };
          return reference;
        }),
      referenceRulers: FloorplanCollection.list(state.references)
        .filter((r): r is ReferenceRuler => {
          return r.type === 'ruler';
        })
        .map((r) => {
          const positionA = FloorplanCoordinates.toImageCoordinates(
            r.positionA,
            state.floorplan
          );
          const positionB = FloorplanCoordinates.toImageCoordinates(
            r.positionB,
            state.floorplan
          );
          const reference: PersistedData.v1.ReferenceRuler = {
            id: r.id,
            enabled: r.enabled,
            positionA,
            positionB,
          };
          return reference;
        }),
      imageData,
      imageScale: state.floorplan.scale,
      imageSize: {
        width: state.floorplan.width,
        height: state.floorplan.height,
      },
      measurement: state.measurement,
      last_oa_sensor_index: state.lastSensorIndices?.lastOASensorIndex,
      last_entry_sensor_index: state.lastSensorIndices?.lastEntrySensorIndex,
    };
  }

  export function setViewport(state: State, viewport: Viewport): State {
    return {
      ...state,
      viewport,
    };
  }

  export function updateViewport(
    state: State,
    update: Partial<Viewport>
  ): State {
    return {
      ...state,
      viewport: {
        ...state.viewport,
        ...update,
      },
    };
  }

  export function focusLayer(state: State, layerId: LayerId): State {
    return {
      ...state,
      objectListType: 'layer',
      focusedObject: {
        type: 'layer',
        id: layerId,
      },
    };
  }

  export function isLayerFocused(state: State, layerId: LayerId) {
    return Boolean(
      state.focusedObject &&
        state.focusedObject.type === 'layer' &&
        state.focusedObject.id === layerId
    );
  }

  export function addSpace(
    state: State,
    space: Space,
    addedWithUserInteraction = true
  ): State {
    const nextSpaceOccupancy = new Map(state.spaceOccupancy);
    nextSpaceOccupancy.set(space.id, {
      occupied: false,
      confidence: 0,
      dwellTime: 0,
      lastUpdate: Seconds.fromMilliseconds(Date.now()),
    });

    const undoItem: UndoStack.Item = {
      undo: (state) => {
        return State.removeSpace(state, space.id);
      },
      redo: (state) => {
        return State.addSpace(state, space);
      },
    };
    const nextUndoStack = [...state.undoStack, undoItem];

    return {
      ...state,
      spaces: FloorplanCollection.addItem(state.spaces, space),
      focusedObject: {
        type: 'space',
        id: space.id,
      },
      spaceOccupancy: nextSpaceOccupancy,
      undoStack: nextUndoStack,
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function lockSpace(
    state: State,
    id: Space['id'],
    locked: boolean
  ): State {
    const space = state.spaces.items.get(id);
    if (typeof space === 'undefined') {
      throw new Error(`Space with id ${id} not found`);
    }

    return {
      ...state,
      spaces: FloorplanCollection.updateItem(state.spaces, id, { locked }),
      unsavedModifications: true,
    };
  }

  export function updateSpace(
    state: State,
    id: Space['id'],
    update: Update<Space> | ((prev: Space) => Update<Space>)
  ): State {
    const space = state.spaces.items.get(id);
    if (typeof space === 'undefined') {
      throw new Error(`Space with id ${id} not found`);
    }

    // disallow state updates for locked spaces
    if (space.locked) {
      return state;
    }

    if (typeof update === 'function') {
      return updateSpace(state, id, update(space));
    }

    return {
      ...state,
      spaces: FloorplanCollection.updateItem(state.spaces, id, update),
      unsavedModifications: true,
    };
  }

  export function highlightItem(
    state: State,
    itemType: 'sensor' | 'space' | 'reference' | 'photogroup',
    id: string
  ) {
    return {
      ...state,
      highlightedObject: {
        type: itemType,
        id,
      },
    };
  }

  export function highlightSpace(state: State, id: Space['id']): State {
    return {
      ...state,
      highlightedObject: {
        type: 'space',
        id,
      },
    };
  }

  export function focusSpace(state: State, id: Space['id']): State {
    return {
      ...state,
      spaces: FloorplanCollection.sendToFront(state.spaces, id),
      objectListType: 'space',
      focusedObject: {
        type: 'space',
        id,
      },
    };
  }

  export function removeSpace(state: State, id: Space['id']): State {
    const space = state.spaces.items.get(id);
    if (typeof space === 'undefined') {
      throw new Error(`space with id ${id} not found`);
    }

    // disallow removal if locked
    if (space.locked) {
      return state;
    }

    const nextSpaceOccupancy = new Map(state.spaceOccupancy);
    nextSpaceOccupancy.delete(id);
    return {
      ...state,
      spaces: FloorplanCollection.removeItem(state.spaces, id),
      focusedObject: null,
      spaceOccupancy: nextSpaceOccupancy,
      unsavedModifications: true,
    };
  }

  export function updateSensorIndices(
    state: State,
    indices: LastSensorIndices
  ): State {
    return {
      ...state,
      lastSensorIndices: indices,
    };
  }

  export function addSensor(
    state: State,
    sensor: Sensor,
    addedWithUserInteraction = true
  ): State {
    const sensorIsOA = sensor.type === 'oa';

    const nextOASensorIndex = state.lastSensorIndices?.lastOASensorIndex
      ? state.lastSensorIndices?.lastOASensorIndex + 1
      : 1;
    const nextEntrySensorIndex = state.lastSensorIndices?.lastEntrySensorIndex
      ? state.lastSensorIndices?.lastEntrySensorIndex + 1
      : 1;
    const planSensorIndex = addedWithUserInteraction
      ? sensorIsOA
        ? nextOASensorIndex
        : nextEntrySensorIndex
      : sensor.plan_sensor_index;
    sensor = {
      ...sensor,
      plan_sensor_index: planSensorIndex,
    };

    // Put a placeholder value to tell downstream code that coverage vectors for this sensor need to
    // be computed
    const nextSensorCoverageIntersectionVectors = new Map(
      state.sensorCoverageIntersectionVectors
    );
    nextSensorCoverageIntersectionVectors.set(sensor.id, 'empty');

    return {
      ...state,
      sensorCoverageIntersectionVectors: nextSensorCoverageIntersectionVectors,
      lastSensorIndices: addedWithUserInteraction
        ? {
            lastOASensorIndex: sensorIsOA
              ? (planSensorIndex as number | undefined)
              : state.lastSensorIndices?.lastOASensorIndex,
            lastEntrySensorIndex: !sensorIsOA
              ? (planSensorIndex as number | undefined)
              : state.lastSensorIndices?.lastEntrySensorIndex,
          }
        : state.lastSensorIndices,
      sensors: FloorplanCollection.addItem(state.sensors, sensor),
      focusedObject: {
        type: 'sensor',
        id: sensor.id,
      },
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function removeSensor(
    state: State,
    id: Sensor['id'],
    ignoreLocked = false
  ): State {
    const sensor = state.sensors.items.get(id);
    if (typeof sensor === 'undefined') {
      throw new Error(`Sensor with id ${id} not found`);
    }

    // disallow removal if locked
    if (!ignoreLocked && sensor.locked) {
      return state;
    }

    const nextSensorConnections = state.sensorConnections;
    nextSensorConnections.delete(id);

    const nextSensorCoverageIntersectionVectors = new Map(
      state.sensorCoverageIntersectionVectors
    );
    nextSensorCoverageIntersectionVectors.delete(sensor.id);

    return {
      ...state,
      sensors: FloorplanCollection.removeItem(state.sensors, id),
      sensorConnections: nextSensorConnections,
      sensorCoverageIntersectionVectors: nextSensorCoverageIntersectionVectors,
      focusedObject: null,
      unsavedModifications: true,
    };
  }

  export function highlightSensor(state: State, id: Sensor['id']): State {
    return {
      ...state,
      highlightedObject: {
        type: 'sensor',
        id,
      },
    };
  }

  export function focusSensor(state: State, id: Sensor['id']): State {
    return {
      ...state,
      sensors: FloorplanCollection.sendToFront(state.sensors, id),
      objectListType: 'sensor',
      focusedObject: {
        type: 'sensor',
        id,
      },
    };
  }

  export function lockSensor(
    state: State,
    id: Sensor['id'],
    locked: boolean
  ): State {
    const sensor = state.sensors.items.get(id);
    if (typeof sensor === 'undefined') {
      throw new Error(`Sensor with id ${id} not found`);
    }

    return {
      ...state,
      sensors: FloorplanCollection.updateItem(state.sensors, id, { locked }),
      unsavedModifications: true,
    };
  }

  export function updateSensor(
    state: State,
    id: Sensor['id'],
    update: Update<Sensor> | ((prev: Sensor) => Update<Sensor>),
    ignoreLocked = false
  ): State {
    const sensor = state.sensors.items.get(id);
    if (typeof sensor === 'undefined') {
      throw new Error(`Sensor with id ${id} not found`);
    }

    // disallow state updates for locked sensors
    if (!ignoreLocked && sensor.locked) {
      return state;
    }

    if (typeof update === 'function') {
      return updateSensor(state, id, update(sensor), ignoreLocked);
    }

    // If any positional attributes about the sensor change, then recompute the sensor coverage
    // vectors
    let nextSensorCoverageIntersectionVectors =
      state.sensorCoverageIntersectionVectors;
    if (
      typeof update.height !== 'undefined' ||
      typeof update.rotation !== 'undefined' ||
      typeof update.position !== 'undefined'
    ) {
      nextSensorCoverageIntersectionVectors = new Map(
        state.sensorCoverageIntersectionVectors
      );
      nextSensorCoverageIntersectionVectors.set(sensor.id, 'empty');
    }

    return {
      ...state,
      sensors: FloorplanCollection.updateItem(state.sensors, id, update),
      sensorCoverageIntersectionVectors: nextSensorCoverageIntersectionVectors,
      unsavedModifications: true,
    };
  }

  export function updateOrCreateSensorConnection(
    state: State,
    id: Sensor['id'],
    update: SensorConnection
  ): State {
    const sensor = state.sensors.items.get(id);
    if (typeof sensor === 'undefined') {
      throw new Error(`Sensor with id ${id} not found`);
    }

    const nextSensorConnections = new Map(state.sensorConnections);
    nextSensorConnections.set(id, update);

    return {
      ...state,
      sensorConnections: nextSensorConnections,
      unsavedModifications: true,
    };
  }

  export function addReference(
    state: State,
    reference: Reference,
    addedWithUserInteraction = true
  ): State {
    return {
      ...state,
      references: FloorplanCollection.addItem(state.references, reference),
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function removeReference(state: State, id: Reference['id']): State {
    return {
      ...state,
      references: FloorplanCollection.removeItem(state.references, id),
      unsavedModifications: true,
    };
  }

  export function updateReference<T extends ReferencePoint | ReferenceRuler>(
    state: State,
    id: Reference['id'],
    update: Update<T> | ((prev: T) => Update<T>)
  ): State {
    if (typeof update === 'function') {
      const reference = state.references.items.get(id) as T;

      if (typeof reference === 'undefined') {
        throw new Error(`Reference with id ${id} not found`);
      }
      return {
        ...state,
        unsavedModifications: true,
        references: FloorplanCollection.updateItem(
          state.references,
          id,
          update(reference)
        ),
      };
    }
    return {
      ...state,
      references: FloorplanCollection.updateItem(state.references, id, update),
      unsavedModifications: true,
    };
  }

  export function addPhotoGroup(
    state: State,
    photoGroup: PhotoGroup,
    addedWithUserInteraction = true
  ): State {
    return {
      ...state,
      photoGroups: FloorplanCollection.addItem(state.photoGroups, photoGroup),
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function focusPhotoGroup(state: State, id: PhotoGroup['id']): State {
    return {
      ...state,
      photoGroups: FloorplanCollection.sendToFront(state.photoGroups, id),
      objectListType: 'photogroup',
      focusedPhotoGroupId: id,
    };
  }

  export function removePhotoGroup(state: State, id: PhotoGroup['id']): State {
    const photoGroup = state.photoGroups.items.get(id);
    if (typeof photoGroup === 'undefined') {
      throw new Error(`Photo Group with id ${id} not found`);
    }

    // disallow removal if locked
    if (photoGroup.locked) {
      return state;
    }

    let photoGroupIdsToDelete = state.photoGroupIdsToDelete;
    if (isPhotoGroupId(id)) {
      photoGroupIdsToDelete = [...photoGroupIdsToDelete, id];
    }

    return {
      ...state,
      photoGroups: FloorplanCollection.removeItem(state.photoGroups, id),
      photoGroupIdsToDelete,
      focusedPhotoGroupId: null,
      unsavedModifications: true,
    };
  }

  export function updatePhotoGroup(
    state: State,
    id: Reference['id'],
    update: Update<PhotoGroup> | ((prev: PhotoGroup) => Update<PhotoGroup>)
  ): State {
    if (typeof update === 'function') {
      const photoGroup = state.photoGroups.items.get(id) as PhotoGroup;

      if (typeof photoGroup === 'undefined') {
        throw new Error(`Photo Group with id ${id} not found`);
      }
      return {
        ...state,
        photoGroups: FloorplanCollection.updateItem(
          state.photoGroups,
          id,
          update(photoGroup)
        ),
        unsavedModifications: true,
      };
    }
    return {
      ...state,
      photoGroups: FloorplanCollection.updateItem(
        state.photoGroups,
        id,
        update
      ),
      unsavedModifications: true,
    };
  }

  export function lockPhotoGroup(state: State, id: PhotoGroup['id']): State {
    return State.updatePhotoGroup(state, id, (photoGroup) =>
      PhotoGroup.lock(photoGroup)
    );
  }

  export function unlockPhotoGroup(state: State, id: PhotoGroup['id']): State {
    return State.updatePhotoGroup(state, id, (photoGroup) =>
      PhotoGroup.unlock(photoGroup)
    );
  }

  export function appendPhotoToPhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    fileName: string,
    photoDataUrl: string,
    uploadedPhotoId: string
  ): State {
    return State.updatePhotoGroup(state, id, (photoGroup) => {
      const photo = PhotoGroupPhoto.createFromUploadedPhotoId(
        uploadedPhotoId,
        fileName,
        photoDataUrl
      );
      return PhotoGroup.appendPhoto(photoGroup, photo);
    });
  }
  export function removePhotoFromPhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    photoId: PhotoGroupPhoto['id']
  ): State {
    return State.updatePhotoGroup(state, id, (photoGroup) => {
      return PhotoGroup.removePhoto(photoGroup, photoId);
    });
  }
  export function changePhotoNameInPhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    photoId: PhotoGroupPhoto['id'],
    name: PhotoGroupPhoto['name']
  ): State {
    return State.updatePhotoGroup(state, id, (photoGroup) => {
      const existingPhoto = PhotoGroup.getPhotoById(photoGroup, photoId);
      if (!existingPhoto) {
        return photoGroup;
      }

      const updatedPhoto = PhotoGroupPhoto.updateName(existingPhoto, name);

      return {
        ...photoGroup,
        photos: photoGroup.photos.map((photo) =>
          photo.id === updatedPhoto.id ? updatedPhoto : photo
        ),
      };
    });
  }
  export function reorderPhotosInPhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    oldIndex: number,
    newIndex: number
  ): State {
    return State.updatePhotoGroup(state, id, (photoGroup) => {
      return {
        ...photoGroup,
        photos: arrayMove(photoGroup.photos, oldIndex, newIndex),
      };
    });
  }

  export function changePhotoGroupId(
    state: State,
    oldId: PhotoGroup['id'],
    newId: PhotoGroup['id']
  ): State {
    // When a photo group is saved to the server, migrate it's old uuid to a new server-generated
    // id.

    // 1. Change ids in the floorplan collection
    state = {
      ...state,
      photoGroups: FloorplanCollection.changeId(
        state.photoGroups,
        oldId,
        newId
      ),
      unsavedModifications: true,
    };

    // Update the focused / highlghted states
    if (State.isPhotoGroupHighlighted(state, oldId)) {
      state = State.highlightItem(state, 'photogroup', newId);
    }
    if (State.isPhotoGroupFocused(state, oldId)) {
      state = State.focusPhotoGroup(state, newId);
    }

    // Update manipualted object
    if (
      state.manipulatedObject &&
      state.manipulatedObject.itemType === 'photogroup' &&
      state.manipulatedObject.itemId === oldId
    ) {
      state.manipulatedObject.itemId = newId;
    }

    return state;
  }

  export function resetAllPhotoGroupOperationAfterSave(state: State): State {
    // Loop through every photo group and reset the operation
    const nextPhotoGroupsItems = new Map(state.photoGroups.items);
    Array.from(nextPhotoGroupsItems.entries()).forEach(([id, photoGroup]) => {
      nextPhotoGroupsItems.set(
        id,
        PhotoGroup.resetOperationAfterSave(photoGroup)
      );
    });

    return {
      ...state,
      photoGroups: {
        ...state.photoGroups,
        items: nextPhotoGroupsItems,
      },
    };
  }

  export function updatePhotoGroupPhotoImageUrl(
    state: State,
    photoGroupPhotoUrls: { [photoId: string]: string }
  ): State {
    // Ensure that photos within photo groups have the most up to date "image.url" property and
    // "image.dirty" is set to false once the plan is saved and the most up to date image urls are
    // available
    const newPhotoGroupItems = new Map(state.photoGroups.items);
    FloorplanCollection.list(state.photoGroups).forEach((photoGroup) => {
      photoGroup.photos.forEach((photo, photoIndex) => {
        const newPhotoUrl = photoGroupPhotoUrls[photo.id];
        if (newPhotoUrl) {
          // Update the url of the photo within the photo group without mutating the state
          const newPhotos = [...photoGroup.photos];
          newPhotos[photoIndex] = {
            ...photo,
            image: { dirty: false, url: newPhotoUrl },
          };
          const newPhotoGroup = {
            ...photoGroup,
            photos: newPhotos,
          };
          newPhotoGroupItems.set(photoGroup.id, newPhotoGroup);
        }
      });
    });

    return {
      ...state,
      photoGroups: {
        ...state.photoGroups,
        items: newPhotoGroupItems,
      },
    };
  }

  export function unhighlight(state: State): State {
    return {
      ...state,
      highlightedObject: null,
    };
  }

  export function blurFocusedObject(state: State): State {
    return {
      ...state,
      focusedObject: null,
    };
  }

  export function blurPhotoGroup(state: State): State {
    return {
      ...state,
      focusedPhotoGroupId: null,
    };
  }

  export function blurAll(state: State): State {
    return {
      ...state,
      focusedObject: null,
      focusedPhotoGroupId: null,
    };
  }

  export function isSpaceHighlighted(state: State, spaceId: Space['id']) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'space' &&
        state.highlightedObject.id === spaceId
    );
  }

  export function isSpaceFocused(state: State, spaceId: Space['id']) {
    return Boolean(
      state.focusedObject &&
        state.focusedObject.type === 'space' &&
        state.focusedObject.id === spaceId
    );
  }

  export function isSensorHighlighted(state: State, sensorId: Sensor['id']) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'sensor' &&
        state.highlightedObject.id === sensorId
    );
  }

  export function isSensorFocused(state: State, sensorId: Sensor['id']) {
    return Boolean(
      state.focusedObject &&
        state.focusedObject.type === 'sensor' &&
        state.focusedObject.id === sensorId
    );
  }

  export function isPhotoGroupHighlighted(
    state: State,
    photoGroupId: PhotoGroup['id']
  ) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'photogroup' &&
        state.highlightedObject.id === photoGroupId
    );
  }

  export function isPhotoGroupFocused(
    state: State,
    photoGroupId: PhotoGroup['id']
  ) {
    return state.focusedPhotoGroupId === photoGroupId;
  }

  export function getFocusedPhotoGroup(state: State) {
    if (!state.focusedPhotoGroupId) {
      return null;
    }
    return state.photoGroups.items.get(state.focusedPhotoGroupId) || null;
  }

  export function isReferenceEnabled(
    state: State,
    referenceId: ReferencePoint['id']
  ) {
    const reference = state.references.items.get(referenceId);
    return Boolean(reference && reference.enabled);
  }

  export function isReferenceHighlighted(
    state: State,
    referenceId: ReferencePoint['id']
  ) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'reference' &&
        state.highlightedObject.id === referenceId
    );
  }

  export function getFocusedSensor(state: State) {
    return (
      (state.focusedObject && state.focusedObject.type === 'sensor'
        ? state.sensors.items.get(state.focusedObject?.id)
        : null) || null
    );
  }

  export function getFocusedSpace(state: State) {
    return (
      (state.focusedObject && state.focusedObject.type === 'space'
        ? state.spaces.items.get(state.focusedObject?.id)
        : null) || null
    );
  }

  export function forceCursor(state: State, cursor: Cursor): State {
    return {
      ...state,
      cursorOverride: cursor,
    };
  }

  export function releaseCursor(state: State): State {
    return {
      ...state,
      cursorOverride: null,
    };
  }

  export function isCaptureAllowed(state: State): boolean {
    // make sure at least one of the sensors has a serial number
    return Array.from(state.sensors.items.values()).some(
      (sensor) => sensor.serialNumber
    );
  }

  export function addSimulant(state: State, simulant: Simulant): State {
    return {
      ...state,
      simulants: [...state.simulants, simulant],
      unsavedModifications: true,
    };
  }

  export function removeSimulant(state: State, id: Simulant['id']): State {
    return {
      ...state,
      simulants: state.simulants.filter((s) => s.id !== id),
      unsavedModifications: true,
    };
  }

  export function updateSimulant(
    state: State,
    id: Simulant['id'],
    update: Update<Simulant> | ((prev: Simulant) => Simulant)
  ) {
    return {
      ...state,
      simulants: state.simulants.map((simulant) => {
        if (simulant.id === id) {
          if (typeof update === 'function') {
            return update(simulant);
          }

          return {
            ...simulant,
            ...update,
          } as Simulant;
        }
        return simulant;
      }),
      unsavedModifications: true,
    };
  }

  export function undoStackPush(state: State, item: UndoStack.Item) {
    return {
      ...state,
      undoStack: [...state.undoStack, item],
    };
  }

  // export function undo(state: State): State {
  //   const
  // }
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'viewport.resize': {
      const { width, height } = action;
      return State.updateViewport(state, { width, height });
    }
    case 'viewport.dragmove': {
      const { dx, dy } = action;
      const { zoom, top, left } = state.viewport;

      return State.updateViewport(state, {
        top: top - dy / zoom,
        left: left - dx / zoom,
      });
    }
    case 'viewport.dragstart': {
      return State.forceCursor(state, cursors[CursorType.GRABBING]);
    }
    case 'viewport.dragend': {
      return State.releaseCursor(state);
    }
    case 'viewport.zoomToFit': {
      const { floorplan, viewport } = state;
      const nextViewport = Viewport.zoomToFit(viewport, floorplan);
      return State.setViewport(state, nextViewport);
    }
    case 'viewport.scrollwheel': {
      const { position, dx, dy, ctrlKey, metaKey } = action;
      const { viewport } = state;

      // ctrlKey hack for zoom
      if (ctrlKey || metaKey) {
        // limit scroll wheel sensitivity for mouse users
        const limit = 8;
        const scrollDelta = Math.max(-limit, Math.min(limit, dy));

        const nextZoomFactor =
          viewport.zoom + viewport.zoom * scrollDelta * -0.01;

        const targetX = viewport.left + position.x / viewport.zoom;
        const targetY = viewport.top + position.y / viewport.zoom;

        const top = targetY - position.y / nextZoomFactor;
        const left = targetX - position.x / nextZoomFactor;

        return State.updateViewport(state, {
          zoom: nextZoomFactor,
          top,
          left,
        });
      }

      // otherwise pan
      return State.updateViewport(state, {
        top: viewport.top + dy / viewport.zoom,
        left: viewport.left + dx / viewport.zoom,
      });
    }
    case 'viewport.mousedown': {
      if (state.focusedObject) {
        return State.blurFocusedObject(state);
      }
      return State.blurAll(state);
    }
    case 'placement.click': {
      if (state.placementMode) {
        const floorplanCoords = action.position;

        let nextState: State;
        switch (state.placementMode.type) {
          case 'sensor': {
            const sensorType = state.placementMode.sensorType;
            const filteredSensors = Sensor.filterByType(
              sensorType,
              state.sensors
            );
            const sensorName = Sensor.generateName(
              sensorType,
              filteredSensors.length
            );
            const sensor = Sensor.create(
              sensorType,
              floorplanCoords,
              state.duplicateSensorParams
                ? state.duplicateSensorParams.height
                : Sensor.default_height(sensorType),
              state.duplicateSensorParams
                ? state.duplicateSensorParams.rotation
                : 0,
              sensorName
            );
            nextState = State.addSensor(state, sensor);
            nextState = {
              ...nextState,
              duplicateSensorParams: null,
              // disable placement mode once the new object is placed
              placementMode: null,
              unsavedModifications: true,
            };
            return State.releaseCursor(nextState);
          }
          case 'space': {
            const spaceName = Space.generateName(state.spaces);
            switch (state.placementMode.shape) {
              case 'box': {
                const space = Space.createBox(
                  floorplanCoords,
                  spaceName,
                  state.duplicateSpaceBoxParams
                    ? state.duplicateSpaceBoxParams.width
                    : Space.DEFAULT_BOX_SHAPE.width,
                  state.duplicateSpaceBoxParams
                    ? state.duplicateSpaceBoxParams.height
                    : Space.DEFAULT_BOX_SHAPE.height
                );
                nextState = State.addSpace(state, space);
                nextState.duplicateSpaceBoxParams = null;
                break;
              }
              case 'circle': {
                const space = Space.createCircle(
                  floorplanCoords,
                  spaceName,
                  state.duplicateSpaceCircleParams
                    ? state.duplicateSpaceCircleParams.radius
                    : Space.DEFAULT_CIRCLE_SHAPE.radius
                );
                nextState = State.addSpace(state, space);
                nextState.duplicateSpaceCircleParams = null;
                break;
              }
              case 'polygon': {
                // If the next point cannot be placed due to a self intersection, then bail
                if (state.placementMode.nextPointSelfIntersection) {
                  return state;
                }

                // If the user clicks on the final point, then finish the polygon!
                if (state.placementMode.mouseOverFinalPoint) {
                  const space = Space.createPolygon(
                    state.placementMode.vertices,
                    spaceName
                  );
                  nextState = State.addSpace(state, space);
                  break;
                }

                // If not close to the first vertex, add a new vertex
                if (!state.placementMode.nextPointPosition) {
                  return state;
                }
                return {
                  ...state,
                  placementMode: {
                    ...state.placementMode,
                    vertices: [
                      ...state.placementMode.vertices,
                      state.placementMode.nextPointPosition,
                    ],
                  },
                };
              }
              case 'polygon-duplicate': {
                let vertices: Array<FloorplanCoordinates>;
                if (state.duplicateSpacePolygonParams) {
                  const originalPosition =
                    state.duplicateSpacePolygonParams.originalPosition;
                  // Given the vertices of the previous polygon, offset them all so that they are
                  // relative to the coordinate that the user clicked on
                  vertices = state.duplicateSpacePolygonParams.vertices.map(
                    (vertex) => {
                      const normalized = FloorplanCoordinates.create(
                        vertex.x - originalPosition.x,
                        vertex.y - originalPosition.y
                      );
                      return FloorplanCoordinates.create(
                        normalized.x + floorplanCoords.x,
                        normalized.y + floorplanCoords.y
                      );
                    }
                  );
                } else {
                  // A default polygon
                  vertices = [
                    FloorplanCoordinates.create(
                      floorplanCoords.x,
                      floorplanCoords.y - 2
                    ),
                    FloorplanCoordinates.create(
                      floorplanCoords.x + 2,
                      floorplanCoords.y + 2
                    ),
                    FloorplanCoordinates.create(
                      floorplanCoords.x - 2,
                      floorplanCoords.y + 2
                    ),
                  ];
                }

                const space = Space.createPolygon(vertices, spaceName);
                nextState = State.addSpace(state, space);
                nextState.duplicateSpacePolygonParams = null;
                nextState.placementMode = null;
                break;
              }
            }
            break;
          }
          case 'reference': {
            switch (state.placementMode.referenceType) {
              case 'point': {
                const referencePoint = Reference.createPoint(floorplanCoords);
                nextState = State.addReference(state, referencePoint);
                break;
              }
              case 'ruler': {
                const pointA = floorplanCoords;
                const pointB = {
                  ...floorplanCoords,
                  y: floorplanCoords.y + Meters.fromFeet(1),
                };
                const referenceRuler = Reference.createRuler(pointA, pointB);
                nextState = State.addReference(state, referenceRuler);
                break;
              }
            }
            break;
          }
          case 'photogroup': {
            const photoGroupName = PhotoGroup.generateName(state.photoGroups);
            const photoGroup = PhotoGroup.create(
              photoGroupName,
              floorplanCoords
            );
            nextState = State.addPhotoGroup(state, photoGroup);
            break;
          }
          case 'simulant': {
            const position = action.position;
            const simulant = Simulant.create(position);
            nextState = State.addSimulant(state, simulant);
            break;
          }
        }

        // disable placement mode once the new object is placed
        nextState = {
          ...nextState,
          placementMode: null,
          unsavedModifications: true,
        };
        return State.releaseCursor(nextState);
      } else {
        return state;
      }
    }
    case 'placement.mousemove': {
      if (!state.placementMode) {
        return state;
      }

      // FIXME: this is for the old floorplan component!
      let nextState = {
        ...state,
        placementMode: {
          ...state.placementMode,
          mousePosition: action.position,
        },
        // FIXME: for some reason using `forcecursor` results in a typescript error?
        // nextState = state.forceCursor(nextState, cursors[CursorType.copy]);
        cursorOverride: cursors[CursorType.COPY],
      };
      // FIXME end old floorplan component behavior

      // A few special behaviors when placing polygonal spaces:
      if (
        action.position &&
        state.placementMode.type === 'space' &&
        state.placementMode.shape === 'polygon'
      ) {
        let nextState = {
          ...state,
          placementMode: {
            type: 'space' as const,
            shape: 'polygon' as const,
            vertices: state.placementMode.vertices,
            mousePosition: action.position,
            nextPointPosition: action.position,
            mouseOverFinalPoint: false,
            nextPointSelfIntersection:
              state.placementMode.nextPointSelfIntersection,
          },
        };

        const positionViewport = FloorplanCoordinates.toViewportCoordinates(
          action.position,
          action.floorplan,
          action.viewport
        );

        // Apply any snapping, if enabled
        const snappingEnabled = action.shiftKey;
        if (snappingEnabled) {
          const finalVertexViewport =
            FloorplanCoordinates.toViewportCoordinates(
              nextState.placementMode.vertices[
                nextState.placementMode.vertices.length - 1
              ],
              action.floorplan,
              action.viewport
            );
          const snappedCoordinates = snapToAngle(
            finalVertexViewport,
            positionViewport
          );
          nextState.placementMode.nextPointPosition =
            ViewportCoordinates.toFloorplanCoordinates(
              ViewportCoordinates.create(
                snappedCoordinates.x,
                snappedCoordinates.y
              ),
              action.viewport,
              action.floorplan
            );
        }

        // If the mouse if over the final point, then set a flag
        if (nextState.placementMode.vertices.length > 2) {
          const firstVertex = nextState.placementMode.vertices[0];
          const firstVertexViewport =
            FloorplanCoordinates.toViewportCoordinates(
              firstVertex,
              action.floorplan,
              action.viewport
            );

          const distanceToFirstVertex = Math.hypot(
            Math.abs(positionViewport.y - firstVertexViewport.y),
            Math.abs(positionViewport.x - firstVertexViewport.x)
          );

          if (distanceToFirstVertex < 8) {
            nextState.placementMode.mouseOverFinalPoint = true;
            nextState.cursorOverride = cursors[CursorType.POINTER];
            // nextState = State.releaseCursor(nextState);
          }
        }

        // If the mouse intersects a previous line in the polygon, then set a flag
        if (
          nextState.placementMode.vertices.length > 2 &&
          nextState.placementMode.nextPointPosition
        ) {
          const lastVertex =
            nextState.placementMode.vertices[
              nextState.placementMode.vertices.length - 1
            ];

          const testLinePointA = FloorplanCoordinates.toViewportCoordinates(
            lastVertex,
            action.floorplan,
            action.viewport
          );
          const testLinePointB = FloorplanCoordinates.toViewportCoordinates(
            nextState.placementMode.nextPointPosition,
            action.floorplan,
            action.viewport
          );

          // Get all edges that make up the polygon being drawn
          const edges = Space.polygonEdges(nextState.placementMode.vertices)
            .slice(0, -1)
            .map(([pointA, pointB]) => [
              FloorplanCoordinates.toViewportCoordinates(
                pointA,
                action.floorplan,
                action.viewport
              ),
              FloorplanCoordinates.toViewportCoordinates(
                pointB,
                action.floorplan,
                action.viewport
              ),
            ]);

          // Check to see if the new line being drawn intersects with any existing polygon edge
          const intersectingPoints = edges.flatMap((edge, index) => {
            const point = lineSegmentIntersection2d(
              [],
              // The edge is one line segment
              [edge[0].x, edge[0].y],
              [edge[1].x, edge[1].y],
              // And the new edge currently being created is the other line segment
              [testLinePointA.x, testLinePointA.y],
              [testLinePointB.x, testLinePointB.y]
            );

            if (!point) {
              return [];
            }

            // An intersection may be returned for the point where the two edges join, but this is a
            // false positive
            if (
              (point[0] === testLinePointA.x &&
                point[1] === testLinePointA.y) ||
              (point[0] === testLinePointB.x && point[1] === testLinePointB.y)
            ) {
              return [];
            }
            if (
              (edges[edges.length - 1][1].x === point[0] &&
                edges[edges.length - 1][1].y === point[1]) ||
              (edges[edges.length - 1][1].x === point[0] &&
                edges[edges.length - 1][1].y === point[1])
            ) {
              return [];
            }

            return [ViewportCoordinates.create(point[0], point[1])];
          });

          nextState.placementMode.nextPointSelfIntersection =
            intersectingPoints.length > 0
              ? ViewportCoordinates.toFloorplanCoordinates(
                  intersectingPoints[0],
                  action.viewport,
                  action.floorplan
                )
              : null;
        }

        return nextState;
      }

      return nextState;
    }
    case 'placement.cancel': {
      const nextState = {
        ...state,
        placementMode: null,
      };
      return State.releaseCursor(nextState);
    }
    case 'sensor.remove': {
      return State.removeSensor(state, action.id);
    }
    case 'sensor.rotateRight90': {
      return State.updateSensor(state, action.id, (sensor: Sensor) => {
        return {
          rotation: Number(modulo(sensor.rotation + 90, 360).toFixed(1)),
        };
      });
    }
    case 'sensor.lock': {
      return State.lockSensor(state, action.id, true);
    }
    case 'sensor.unlock': {
      return State.lockSensor(state, action.id, false);
    }
    case 'sensor.saveNotes': {
      const id = action.id;
      const sensor = state.sensors.items.get(id);
      if (typeof sensor === 'undefined') {
        throw new Error(`Sensor with id ${id} not found`);
      }

      return {
        ...state,
        sensors: FloorplanCollection.updateItem(state.sensors, id, {
          notes: action.notes,
        }),
        unsavedModifications: true,
      };
    }
    case 'sensor.changeHeight': {
      const nextHeight = Math.min(
        Sensor.max_height(action.sensorType),
        Math.max(Sensor.min_height(action.sensorType), action.height)
      );
      return State.updateSensor(state, action.id, {
        height: nextHeight,
      });
    }
    case 'sensor.changeRotation': {
      const rotation = Number(modulo(action.rotation, 360).toFixed(1));
      return State.updateSensor(state, action.id, {
        rotation,
      });
    }
    case 'sensor.changeCadId': {
      return State.updateSensor(state, action.id, {
        cadId: action.cadId,
      });
    }
    case 'sensor.changeSerialNumber': {
      return State.updateSensor(state, action.id, {
        serialNumber: action.serialNumber,
      });
    }
    case 'sensor.boundingBoxFilter': {
      return State.updateSensor(state, action.id, {
        // I'm unsure what state we should manipulate here
        boundingBoxFilter: action.boundingBoxFilter,
      });
    }
    case 'sensor.setDiagnosticMetadata': {
      return State.updateSensor(state, action.id, {
        status: action.status,
        last_heartbeat: action.last_heartbeat,
        mac: action.mac,
        ipv4: action.ipv4,
        ipv6: action.ipv6,
        os: action.os,
      });
    }
    case 'sensor.dismiss': {
      return State.blurFocusedObject(state);
    }

    // case 'sensor.address.submit': {
    //   return State.updateSensor(state, action.id, {
    //     connection: {
    //       type: 'local',
    //       localIPAddress: action.address,
    //       status: 'connecting',
    //     },
    //   });
    // }

    case 'sensor.menu.unlink': {
      // TODO: implement
      return state;
    }

    case 'sensor.menu.link': {
      // TODO: implement
      return state;
    }

    case 'sensor.menu.connect': {
      return State.updateOrCreateSensorConnection(state, action.id, {
        serialNumber: action.serialNumber,
        status: 'connecting',
      });
    }

    case 'sensor.menu.disconnect': {
      const existingConnection = state.sensorConnections.get(action.id);
      if (!existingConnection) {
        console.warn(`Sensor with id ${action.id} does not have a connection`);
        return state;
      }

      const nextConnection = {
        ...existingConnection,
        status: 'disconnected',
      } as const;

      return State.updateOrCreateSensorConnection(
        state,
        action.id,
        nextConnection
      );
    }

    case 'sensorConnection.update': {
      return State.updateOrCreateSensorConnection(
        state,
        action.id,
        action.sensorConnection
      );
    }

    case 'sensor.socket.open': {
      const existingConnection = state.sensorConnections.get(action.id);
      if (!existingConnection) {
        console.warn(`Sensor with id ${action.id} does not have a connection`);
        return state;
      }

      return State.updateOrCreateSensorConnection(state, action.id, {
        serialNumber: existingConnection.serialNumber,
        status: 'connected',
      });
    }

    case 'sensor.socket.close':
    case 'sensor.socket.error': {
      const existingConnection = state.sensorConnections.get(action.id);

      // sensor has been removed prior to socket error / close
      // no need to update
      if (!existingConnection) {
        return state;
      }

      return State.updateOrCreateSensorConnection(state, action.id, {
        serialNumber: existingConnection.serialNumber,
        status: 'disconnected',
      });
    }

    case 'sensor.socket.frame': {
      const sensor = state.sensors.items.get(action.id);
      if (typeof sensor === 'undefined')
        throw new Error('Received frame for non-existent sensor');

      const sensorId = sensor.id;
      const timestamp = Seconds.fromMilliseconds(Date.now());

      const frames = PointCloud.getFramesFromBuffer(action.buffer);

      if (!frames || frames.length === 0) {
        return state;
      }

      // TODO: entrypoint for buffered payload mitigation can be here
      //       For now, just slamming all the points together (JMS 2021-05-04)

      const points: Array<PointCloud.Point> = [];
      const tracks: Array<Tracks.SensorTrack> = [];

      for (const frame of frames) {
        points.push(...PointCloud.getPointsFromFrame(frame));
        tracks.push(...Tracks.getTargetsFromFrame(frame));
      }

      const mappedPoints: Array<MappedPoint> = points.map((point) => {
        return {
          isSimulated: false,
          sensorPoint: point,
          sensorId,
          timestamp,
          floorplanPosition: SensorCoordinates.toFloorplanCoordinates(
            SensorCoordinates.create(point.x, point.z),
            sensor
          ),
        };
      });

      const mappedTracks: Array<FloorplanTargetInfo> = [];

      for (const track of tracks) {
        if (!sensor.serialNumber) continue;
        const { timestamp, position: sensorPosition } = track;
        const position = SensorCoordinates.toFloorplanCoordinates(
          SensorCoordinates.create(sensorPosition.x, sensorPosition.y),
          sensor
        );
        mappedTracks.push({
          timestamp,
          position,
          sensorSerial: sensor.serialNumber,
        });
      }

      return {
        ...state,
        aggregatedPointsData: state.aggregatedPointsData.concat(mappedPoints),
        aggregatedTracksData: state.aggregatedTracksData.concat(mappedTracks),
      };
    }

    case 'sensor.socket.message':
      const sensor = state.sensors.items.get(action.id);
      if (!sensor) {
        throw new Error(
          `Received message for non-existent sensor: ${action.id}`
        );
      }

      const sensorSerial = sensor.serialNumber;
      if (!sensorSerial) {
        throw new Error(`Missing serial number for sensor: ${sensor.id}`);
      }

      const sensorId = sensor.id;
      const timestamp = Seconds.fromMilliseconds(Date.now());

      const payload: OALiveSocketMessage = JSON.parse(action.message);

      const mappedPoints: Array<MappedPoint> = [];
      const mappedTracks: Array<FloorplanTargetInfo> = [];

      if (payload.message_type === 'targets') {
        payload.targets.forEach((target) => {
          mappedTracks.push({
            timestamp,
            position: FloorplanCoordinates.create(target.x, target.y),
            sensorSerial,
          });
        });
      } else if (payload.message_type === 'points') {
        payload.points.forEach((point) => {
          mappedPoints.push({
            isSimulated: false,
            sensorId,
            timestamp,
            floorplanPosition: FloorplanCoordinates.create(point.x, point.y),
          });
        });
      }

      return {
        ...state,
        aggregatedPointsData: state.aggregatedPointsData.concat(mappedPoints),
        aggregatedTracksData: state.aggregatedTracksData.concat(mappedTracks),
      };

    case 'space.dismiss': {
      return State.blurFocusedObject(state);
    }

    case 'space.resizeHandle.mouseenter': {
      // TODO: cursor
      return state;
    }

    case 'space.resizeHandle.mouseleave': {
      // TODO: cursor
      return state;
    }

    case 'space.resizeHandle.dragstart': {
      const cursorType = getCursorTypeForHandle(action.handle);
      return State.forceCursor(state, cursors[cursorType]);
    }

    case 'space.resizeHandle.dragend': {
      return State.releaseCursor(state);
    }

    case 'space.resize.box': {
      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'box') {
          return space;
        }

        return {
          ...space,
          position: action.position,
          shape: {
            ...space.shape,
            width: action.width,
            height: action.height,
          },
        };
      });
    }

    case 'space.resize.circle': {
      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'circle') {
          return space;
        }

        return {
          ...space,
          position: action.position,
          shape: {
            ...space.shape,
            radius: action.radius,
          },
        };
      });
    }

    case 'space.resize.polygon': {
      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'polygon') {
          return space;
        }

        return {
          ...space,
          position: action.position,
          shape: {
            ...space.shape,
            vertices: action.vertices,
          },
        };
      });
    }

    case 'space.resizeHandle.dragmove': {
      const { handle, id, dx, dy } = action;
      const delta = new ViewportVector(dx, dy)
        .toImageVector(state.viewport)
        .toFloorplanVector(state.floorplan);

      return State.updateSpace(state, id, (space) => {
        switch (space.shape.type) {
          case 'circle': {
            const radiusChange =
              handle === 'top'
                ? -delta.y
                : handle === 'right'
                ? delta.x
                : handle === 'bottom'
                ? delta.y
                : handle === 'left'
                ? -delta.x
                : 0;
            const nextRadius = Math.max(
              Space.MIN_RADIUS,
              space.shape.radius + radiusChange
            );
            return {
              shape: {
                ...space.shape,
                radius: nextRadius,
              },
            };
          }
          case 'box': {
            const widthChange =
              handle === 'topleft' ||
              handle === 'bottomleft' ||
              handle === 'left'
                ? -delta.x
                : handle === 'topright' ||
                  handle === 'bottomright' ||
                  handle === 'right'
                ? delta.x
                : 0;
            const heightChange =
              handle === 'topleft' || handle === 'topright' || handle === 'top'
                ? -delta.y
                : handle === 'bottomleft' ||
                  handle === 'bottomright' ||
                  handle === 'bottom'
                ? delta.y
                : 0;
            const nextWidth = Math.max(
              Space.MIN_WIDTH,
              space.shape.width + widthChange
            );
            const nextHeight = Math.max(
              Space.MIN_HEIGHT,
              space.shape.height + heightChange
            );
            const cxChange =
              handle === 'top' || handle === 'bottom'
                ? 0
                : nextWidth === Space.MIN_WIDTH
                ? 0
                : delta.x / 2;
            const cyChange =
              handle === 'left' || handle === 'right'
                ? 0
                : nextHeight === Space.MIN_HEIGHT
                ? 0
                : delta.y / 2;
            const nextPosition = FloorplanCoordinates.create(
              space.position.x + cxChange,
              space.position.y + cyChange
            );
            return {
              position: nextPosition,
              shape: {
                ...space.shape,
                width: nextWidth,
                height: nextHeight,
              },
            };
          }
          case 'polygon': {
            if (typeof action.polygonVertexIndex === 'undefined') {
              return space;
            }
            const verticesCopy = space.shape.vertices.slice();
            verticesCopy[action.polygonVertexIndex] =
              FloorplanCoordinates.create(
                verticesCopy[action.polygonVertexIndex].x + delta.x,
                verticesCopy[action.polygonVertexIndex].y + delta.y
              );
            return {
              ...space,
              position: calculatePolygonCentroid(verticesCopy),
              shape: {
                ...space.shape,
                vertices: verticesCopy,
              },
            };
          }
        }
      });
    }

    case 'space.remove': {
      return State.removeSpace(state, action.id);
    }

    case 'space.lock': {
      return State.lockSpace(state, action.id, true);
    }
    case 'space.unlock': {
      return State.lockSpace(state, action.id, false);
    }

    case 'space.changeName': {
      return State.updateSpace(state, action.id, {
        name: action.name,
      });
    }

    case 'space.polygon.addVertex': {
      const vertexPosition = ViewportCoordinates.toFloorplanCoordinates(
        action.position,
        state.viewport,
        state.floorplan
      );

      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'polygon') {
          return space;
        }

        const newVertices = [
          ...space.shape.vertices.slice(0, action.polygonVertexIndex),
          vertexPosition,
          ...space.shape.vertices.slice(action.polygonVertexIndex),
        ];

        return {
          ...space,
          position: calculatePolygonCentroid(newVertices),
          shape: { ...space.shape, vertices: newVertices },
        };
      });
    }

    case 'space.polygon.removeVertex': {
      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'polygon') {
          return space;
        }

        // Don't allow deleting vertices if the polygon is already a triangle
        // Polygons should always have an area and not be linear
        if (space.shape.vertices.length <= 3) {
          return space;
        }

        // Remove the vertex at the given index
        const verticesCopy = space.shape.vertices.slice();
        verticesCopy.splice(action.polygonVertexIndex, 1);

        return {
          ...space,
          position: calculatePolygonCentroid(verticesCopy),
          shape: {
            ...space.shape,
            vertices: verticesCopy,
          },
        };
      });
    }

    case 'reference.click': {
      return State.updateReference<ReferencePoint | ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          return {
            enabled: !reference.enabled,
          };
        }
      );
    }

    case 'reference.remove': {
      return State.removeReference(state, action.id);
    }

    case 'reference.rulerPosition.change': {
      return State.updateReference<ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          // Clear the cached cursor position
          let update: Update<ReferenceRuler> = {};

          update.positionA = action.positionA;
          update.positionB = action.positionB;
          update.distanceLabelPosition = action.distanceLabelPosition;

          // Move distance marker to keep it in the center if needed
          if (reference.currentIsDistanceLockedToCenter) {
            update.distanceLabelPosition =
              ReferenceRuler.calculateCenterPoint(reference);
          }

          return update;
        }
      );
    }

    case 'reference.rulerPosition.dragstart': {
      return State.updateReference<ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          // Set the initially cached cursor position to the endpoint that was clicked
          let update: Update<ReferenceRuler> = {};
          update.currentCursorPosition = reference[action.position];

          // Cache the "isDistanceLockedToCenter" value so that the label reference point stays
          // pinned to the center if needed during moves
          const centerPoint = ReferenceRuler.calculateCenterPoint(reference);
          const isDistanceLockedToCenter =
            ReferenceRuler.isDistanceLockedToCenter(
              state.floorplan,
              state.viewport,
              reference.distanceLabelPosition,
              centerPoint
            );
          update.currentIsDistanceLockedToCenter = isDistanceLockedToCenter;
          return update;
        }
      );
    }

    case 'reference.rulerPosition.dragmove': {
      return State.updateReference<ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          let update: Update<ReferenceRuler> = {};

          const staticPosition =
            reference[
              action.position === 'positionA' ? 'positionB' : 'positionA'
            ];

          const delta = new ViewportVector(action.dx, action.dy)
            .toImageVector(state.viewport)
            .toFloorplanVector(state.floorplan);

          if (!reference.currentCursorPosition) {
            // This should never happen, the dragstart action sets this value
            throw new Error(
              'Cursor position was not set on drag start: did the mousedown action fire when dragging the end of the reference line?'
            );
          }

          // Compute where the user's cursor currently is located
          // Note that this may not be in the same location as the end point if "snapping" is
          // enabled
          const currentCursorPosition: FloorplanCoordinates = {
            ...reference.currentCursorPosition,
            x: reference.currentCursorPosition.x + delta.x,
            y: reference.currentCursorPosition.y + delta.y,
          };
          update.currentCursorPosition = currentCursorPosition;

          // Apply any snapping, if enabled
          const snappingEnabled = action.shiftKey;
          if (snappingEnabled) {
            // Note that snapping is done based on the cursor position, and not the previous end
            // point position. This is important for the mechanic to work correctly.
            const snappedCoordinates = snapToAngle(
              staticPosition,
              currentCursorPosition
            );
            update[action.position] = {
              ...reference[action.position],
              x: snappedCoordinates.x,
              y: snappedCoordinates.y,
            };
          } else {
            // Snapping not enabled
            update[action.position] = {
              ...reference[action.position],
              x: currentCursorPosition.x,
              y: currentCursorPosition.y,
            };
          }

          // Move distance marker to keep it in the center if needed
          if (reference.currentIsDistanceLockedToCenter) {
            const centerPoint = ReferenceRuler.calculateCenterPoint(reference);
            update.distanceLabelPosition = {
              ...reference.distanceLabelPosition,
              x: centerPoint.x,
              y: centerPoint.y,
            };
          }

          return update;
        }
      );
    }

    case 'reference.rulerPosition.dragend': {
      return State.updateReference<ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          // Clear the cached cursor position
          let update: Update<ReferenceRuler> = {};
          update.currentCursorPosition = undefined;
          return update;
        }
      );
    }

    case 'reference.distanceLabelPosition.dragstart': {
      return State.updateReference<ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          // Set the initially cached cursor position to the endpoint that was clicked
          let update: Update<ReferenceRuler> = {};
          update.currentCursorPosition = reference.distanceLabelPosition;
          return update;
        }
      );
    }

    case 'reference.distanceLabelPosition.dragmove': {
      return State.updateReference<ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          let update: Update<ReferenceRuler> = {};

          const delta = new ViewportVector(action.dx, action.dy)
            .toImageVector(state.viewport)
            .toFloorplanVector(state.floorplan);

          if (!reference.currentCursorPosition) {
            // This should never happen, the dragstart action sets this value
            throw new Error(
              'Cursor position was not set on drag start: did the mousedown action fire when dragging the dimension of the reference line?'
            );
          }

          // Compute where the user's cursor currently is located
          // Note that this may not be in the same location as the end point if "snapping" is
          // enabled
          const currentCursorPosition: FloorplanCoordinates = {
            ...reference.currentCursorPosition,
            x: reference.currentCursorPosition.x + delta.x,
            y: reference.currentCursorPosition.y + delta.y,
          };
          update.currentCursorPosition = currentCursorPosition;

          const centerPoint = ReferenceRuler.calculateCenterPoint(reference);

          const isDistanceLockedToCenter =
            ReferenceRuler.isDistanceLockedToCenter(
              state.floorplan,
              state.viewport,
              update.currentCursorPosition,
              centerPoint
            );

          if (isDistanceLockedToCenter) {
            update.distanceLabelPosition = {
              ...reference.distanceLabelPosition,
              x: centerPoint.x,
              y: centerPoint.y,
            };
          } else {
            update.distanceLabelPosition = {
              ...reference.distanceLabelPosition,
              x: currentCursorPosition.x,
              y: currentCursorPosition.y,
            };
          }

          return update;
        }
      );
    }

    case 'reference.distanceLabelPosition.dragend': {
      return State.updateReference<ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          // Clear the cached cursor position
          let update: Update<ReferenceRuler> = {};
          update.currentCursorPosition = undefined;
          return update;
        }
      );
    }

    case 'referenceList.setDisplayUnit': {
      return {
        ...state,
        displayUnit: action.displayUnit,
        unsavedModifications: true,
      };
    }

    case 'photoGroup.remove': {
      return State.removePhotoGroup(state, action.id);
    }

    case 'photoGroup.dragmove': {
      return State.updatePhotoGroup(state, action.id, (photoGroup) => {
        return {
          ...photoGroup,
          position: action.itemPosition,
        };
      });
    }

    case 'photoGroup.lock': {
      return State.lockPhotoGroup(state, action.id);
    }
    case 'photoGroup.unlock': {
      return State.unlockPhotoGroup(state, action.id);
    }

    case 'photoGroup.changeName': {
      return State.updatePhotoGroup(state, action.id, (photoGroup) =>
        PhotoGroup.changeName(photoGroup, action.name)
      );
    }

    case 'photoGroup.saveNotes': {
      return State.updatePhotoGroup(state, action.id, (photoGroup) =>
        PhotoGroup.changeNotes(photoGroup, action.notes)
      );
    }

    case 'photoGroup.dismiss': {
      return State.blurPhotoGroup(state);
    }

    case 'photoGroup.changeId': {
      return State.changePhotoGroupId(state, action.oldId, action.newId);
    }

    case 'photoGroup.photos.append': {
      return State.appendPhotoToPhotoGroup(
        state,
        action.id,
        action.fileName,
        action.photoDataUrl,
        action.uploadedPhotoId
      );
    }
    case 'photoGroup.photos.remove': {
      return State.removePhotoFromPhotoGroup(state, action.id, action.photoId);
    }
    case 'photoGroup.photos.changeName': {
      return State.changePhotoNameInPhotoGroup(
        state,
        action.id,
        action.photoId,
        action.name
      );
    }
    case 'photoGroup.photos.reorder': {
      return State.reorderPhotosInPhotoGroup(
        state,
        action.id,
        action.oldIndex,
        action.newIndex
      );
    }

    case 'scaleEdit.begin': {
      const image = action.image || state.floorplanImage;
      if (!image) {
        return state;
      }

      return {
        ...state,
        scaleEdit: {
          active: true,
          floorplanImage: image,
          floorplan: {
            ...state.floorplan,
            width: image.width,
            height: image.height,
          },
          objectKey: action.objectKey,
        },
      };
    }

    case 'scaleEdit.cancel': {
      return {
        ...state,
        scaleEdit: {
          active: false,
        },
      };
    }

    case 'scaleEdit.submit': {
      if (!state.scaleEdit.active) {
        return state;
      }
      return {
        ...state,
        scaleEdit: {
          active: false,
        },
        floorplanImage: state.scaleEdit.floorplanImage,
        measurement: action.measurement,
        floorplan: {
          ...state.scaleEdit.floorplan,
          scale: action.measurement.computedScale,
        },
        unsavedModifications: true,
      };
    }

    case 'menu.showSensors': {
      return {
        ...state,
        objectListType: 'sensor',
      };
    }
    case 'menu.showSpaces': {
      return {
        ...state,
        objectListType: 'space',
      };
    }
    case 'menu.showReferences': {
      return {
        ...state,
        objectListType: 'reference',
      };
    }
    case 'menu.showPhotoGroups': {
      return {
        ...state,
        objectListType: 'photogroup',
      };
    }
    case 'menu.showLayers': {
      return {
        ...state,
        objectListType: 'layer',
      };
    }

    case 'menu.addSensor': {
      return {
        ...state,
        placementMode: {
          type: 'sensor',
          sensorType: action.sensorType,
          mousePosition: null,
        },
      };
    }
    case 'menu.addSpace': {
      let nextState: State;
      if (action.shape === 'polygon') {
        nextState = {
          ...state,
          placementMode: {
            type: 'space',
            shape: action.shape,
            vertices: [],
            mouseOverFinalPoint: false,
            nextPointSelfIntersection: null,
            mousePosition: null,
            nextPointPosition: null,
          },
        };
      } else {
        nextState = {
          ...state,
          placementMode: {
            type: 'space',
            shape: action.shape,
            mousePosition: null,
          },
        };
      }
      nextState = State.forceCursor(nextState, cursors[CursorType.COPY]);
      return nextState;
    }
    case 'menu.addReference': {
      return {
        ...state,
        placementMode: {
          type: 'reference',
          referenceType: action.referenceType,
          mousePosition: null,
        },
      };
    }
    case 'menu.addPhotoGroup': {
      return {
        ...state,
        placementMode: {
          type: 'photogroup',
          mousePosition: null,
        },
      };
    }
    case 'menu.removeFocusedObject': {
      if (state.focusedPhotoGroupId) {
        return State.removePhotoGroup(state, state.focusedPhotoGroupId);
      }

      if (!state.focusedObject) {
        return state;
      }

      switch (state.focusedObject.type) {
        case 'sensor':
          return State.removeSensor(state, state.focusedObject.id);
        case 'space':
          return State.removeSpace(state, state.focusedObject.id);
        default:
          return state;
      }
    }
    case 'menu.duplicateSensor': {
      const sensor = state.sensors.items.get(action.id);
      if (typeof sensor === 'undefined') {
        throw new Error(`Sensor with id ${action.id} not found`);
      }
      return {
        ...state,
        duplicateSensorParams: {
          height: sensor.height,
          rotation: sensor.rotation,
        },
        placementMode: {
          type: 'sensor',
          sensorType: sensor.type,
          mousePosition: null,
        },
      };
    }

    case 'menu.duplicateSpace': {
      const space = state.spaces.items.get(action.id);
      if (typeof space === 'undefined') {
        throw new Error(`Space with id ${action.id} not found`);
      }

      let update: Partial<
        Pick<
          State,
          | 'duplicateSpaceBoxParams'
          | 'duplicateSpaceCircleParams'
          | 'duplicateSpacePolygonParams'
        >
      > = {};

      if (space.shape.type === 'box') {
        update = {
          duplicateSpaceBoxParams: {
            width: space.shape.width,
            height: space.shape.height,
          },
        };
      }
      if (space.shape.type === 'circle') {
        update = {
          duplicateSpaceCircleParams: {
            radius: space.shape.radius,
          },
        };
      }
      if (space.shape.type === 'polygon') {
        update = {
          duplicateSpacePolygonParams: {
            vertices: space.shape.vertices,
            originalPosition: space.position,
          },
        };
      }
      return {
        ...state,
        placementMode: {
          type: 'space',
          shape:
            space.shape.type === 'polygon'
              ? 'polygon-duplicate'
              : space.shape.type,
          mousePosition: null,
        },
        ...update,
      };
    }

    case 'menu.streaming.toggleTracks': {
      return {
        ...state,
        streaming: {
          ...state.streaming,
          showTracks: !state.streaming.showTracks,
        },
      };
    }

    case 'menu.streaming.togglePoints': {
      return {
        ...state,
        streaming: {
          ...state.streaming,
          showPoints: !state.streaming.showPoints,
        },
      };
    }

    case 'menu.planning.toggleSensors': {
      // Deselect focused sensor if one is focused
      if (state.focusedObject && state.focusedObject.type === 'sensor') {
        state = State.blurFocusedObject(state);
      }

      const newShowSensors = !state.planning.showSensors;

      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: newShowSensors,
          showEntrySensors: newShowSensors,
          showOASensors: newShowSensors,
        },
      };
    }

    case 'menu.planning.toggleEntrySensors': {
      // Deselect focused sensor if one is focused
      if (state.focusedObject && state.focusedObject.type === 'sensor') {
        state = State.blurFocusedObject(state);
      }

      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showEntrySensors: !state.planning.showEntrySensors,
        },
      };
    }

    case 'menu.planning.toggleOASensors': {
      // Deselect focused sensor if one is focused
      if (state.focusedObject && state.focusedObject.type === 'sensor') {
        state = State.blurFocusedObject(state);
      }

      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: !state.planning.showOASensors,
        },
      };
    }

    case 'menu.planning.toggleSensorLabels': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: true,
          showSensorLabels: !state.planning.showSensorLabels,
        },
      };
    }

    case 'menu.planning.toggleSensorCoverage': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: true,
          showSensorCoverage: !state.planning.showSensorCoverage,
        },
      };
    }

    case 'menu.planning.toggleSensorCoverageExtents': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: true,
          showSensorCoverageExtents: !state.planning.showSensorCoverageExtents,
        },
      };
    }

    case 'menu.planning.toggleSpaces': {
      // Deselect focused space / area if one is focused
      if (state.focusedObject && state.focusedObject.type === 'space') {
        state = State.blurFocusedObject(state);
      }

      return {
        ...state,
        planning: {
          ...state.planning,
          showSpaces: !state.planning.showSpaces,
        },
      };
    }

    case 'menu.planning.togglePhotoGroups': {
      state = State.blurPhotoGroup(state);
      return {
        ...state,
        planning: {
          ...state.planning,
          showPhotoGroups: !state.planning.showPhotoGroups,
        },
      };
    }
    case 'menu.planning.toggleCeilingHeightMap': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showCeilingHeightMap: !state.planning.showCeilingHeightMap,
        },
      };
    }

    case 'menu.planning.toggleRulers': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showRulers: !state.planning.showRulers,
        },
      };
    }

    case 'menu.planning.toggleScale': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showScale: !state.planning.showScale,
        },
      };
    }

    case 'menu.visualization.colorScaleDomain.change': {
      const { colorScaleDomain } = action;
      return {
        ...state,
        visualization: {
          ...state.visualization,
          colorScaleDomain,
        },
      };
    }
    case 'menu.visualization.frameWindowSize.change': {
      const { frameWindowSize } = action;
      return {
        ...state,
        visualization: {
          ...state.visualization,
          frameWindowSize,
        },
      };
    }
    case 'menu.visualization.snrThreshold.change': {
      const { snrThreshold } = action;
      return {
        ...state,
        visualization: {
          ...state.visualization,
          snrThreshold,
        },
      };
    }

    case 'sensorList.item.mouseenter': {
      return State.highlightSensor(state, action.id);
    }
    case 'sensorList.item.mouseleave': {
      return State.unhighlight(state);
    }
    case 'spaceList.item.mouseenter': {
      return State.highlightSpace(state, action.id);
    }
    case 'spaceList.item.mouseleave': {
      return State.unhighlight(state);
    }
    case 'algorithm.releaseExpiredAggregateData': {
      return {
        ...state,
        aggregatedPointsData: AggregatedData.releaseExpiredData(
          state.aggregatedPointsData
        ),
      };
    }

    case 'algorithm.spaceOccupancyConfidenceChange': {
      const { confidence } = action;

      const RISING_THRESHOLD = 0.75;
      const FALLING_THRESHOLD = 0.25;

      const prevValue = state.spaceOccupancy.get(action.id);
      if (typeof prevValue === 'undefined') {
        throw new Error(`No spaceOccupancy entry for id ${action.id}`);
      }

      const nextValue = {
        ...prevValue,
        confidence,
        lastUpdate: action.timestamp,
      };

      const timeDelta = action.timestamp - prevValue.lastUpdate;

      if (prevValue.occupied) {
        if (confidence < FALLING_THRESHOLD) {
          nextValue.occupied = false;
        }
        nextValue.dwellTime += timeDelta;
      } else {
        if (confidence > RISING_THRESHOLD) {
          nextValue.occupied = true;
        }
      }

      const nextSpaceOccupancy = new Map(state.spaceOccupancy);
      nextSpaceOccupancy.set(action.id, nextValue);

      return {
        ...state,
        spaceOccupancy: nextSpaceOccupancy,
      };
    }

    case 'simulation.menu.enableSimulation': {
      return {
        ...state,
        simulation: {
          ...state.simulation,
          enabled: true,
        },
      };
    }

    case 'simulation.menu.disableSimulation': {
      return {
        ...state,
        simulation: {
          ...state.simulation,
          enabled: false,
        },
      };
    }

    case 'simulation.sensor.points': {
      const timestamp = Seconds.fromMilliseconds(Date.now());
      const newPoints: Array<MappedPoint> = action.points.map((position) => {
        return {
          isSimulated: true,
          timestamp,
          sensorId: action.id,
          floorplanPosition: position,
        };
      });
      return {
        ...state,
        aggregatedPointsData: [...state.aggregatedPointsData, ...newPoints],
      };
    }

    case 'simulation.menu.showSimBoundaries': {
      return {
        ...state,
        simulation: {
          ...state.simulation,
          simBoundariesShown: true,
        },
      };
    }

    case 'simulation.menu.hideSimBoundaries': {
      return {
        ...state,
        simulation: {
          ...state.simulation,
          simBoundariesShown: false,
        },
      };
    }
    // case 'simulation.simulant.dragmove': {
    //   const delta = new ViewportVector(action.dx, action.dy)
    //     .toImageVector(state.viewport)
    //     .toFloorplanVector(state.floorplan);

    //   return State.updateSimulant(state, action.id, (simulant) => {
    //     return {
    //       ...simulant,
    //       position: FloorplanCoordinates.create(
    //         simulant.position.x + delta.x,
    //         simulant.position.y + delta.y,
    //       ),
    //     };
    //   });
    // }

    case 'simulation.simulant.remove': {
      return State.removeSimulant(state, action.id);
    }

    case 'simulation.menu.addSimulant': {
      return {
        ...state,
        placementMode: {
          type: 'simulant',
          mousePosition: null,
        },
      };
    }

    case 'item.menu.mouseenter':
    case 'item.graphic.mouseenter': {
      const { itemType } = action;
      if (
        itemType === 'sensor' ||
        itemType === 'space' ||
        itemType === 'reference' ||
        itemType === 'photogroup'
      ) {
        return State.highlightItem(state, itemType, action.itemId);
      }
      return state;
    }

    case 'item.menu.mouseleave':
    case 'item.graphic.mouseleave': {
      const { itemType } = action;
      if (
        itemType === 'sensor' ||
        itemType === 'space' ||
        itemType === 'reference' ||
        itemType === 'photogroup'
      ) {
        return State.unhighlight(state);
      }
      return state;
    }

    case 'item.menu.mousedown': {
      const { itemType } = action;
      if (itemType === 'sensor') {
        return State.focusSensor(state, action.itemId);
      }
      if (itemType === 'space') {
        return State.focusSpace(state, action.itemId);
      }
      if (itemType === 'photogroup') {
        return State.focusPhotoGroup(state, action.itemId);
      }
      return state;
    }

    case 'item.graphic.mousedown': {
      let { itemType, itemId, itemPosition, clientX, clientY } = action;

      const position = ViewportCoordinates.toFloorplanCoordinates(
        itemPosition,
        state.viewport,
        state.floorplan
      );

      let nextState = state;

      if (itemType === 'sensor') {
        nextState = State.focusSensor(state, action.itemId);
      }
      if (itemType === 'space') {
        nextState = State.focusSpace(state, action.itemId);
      }
      if (itemType === 'photogroup') {
        nextState = State.focusPhotoGroup(state, action.itemId);
      }
      if (itemType === 'reference') {
        nextState = State.updateReference<ReferenceRuler>(
          state,
          action.itemId,
          (reference) => {
            // Cache the "isDistanceLockedToCenter" value so that the label reference point stays
            // pinned to the center if needed when the whole reference line moves
            const centerPoint = ReferenceRuler.calculateCenterPoint(reference);
            const isDistanceLockedToCenter =
              ReferenceRuler.isDistanceLockedToCenter(
                state.floorplan,
                state.viewport,
                reference.distanceLabelPosition,
                centerPoint
              );
            return {
              currentIsDistanceLockedToCenter: isDistanceLockedToCenter,
            };
          }
        );
      }

      if (itemType === 'sensor' && state.modifierKeys.alt) {
        const sensor = state.sensors.items.get(itemId);
        if (!sensor) {
          throw new Error(
            'Sensor duplicate dragged but original did not exist!'
          );
        }
        const filteredSensors = Sensor.filterByType(sensor.type, state.sensors);
        const sensorName = Sensor.generateName(
          sensor.type,
          filteredSensors.length
        );
        const newSensor = Sensor.create(
          sensor.type,
          position,
          sensor.height,
          sensor.rotation,
          sensorName
        );
        nextState = State.addSensor(state, newSensor);
        itemId = newSensor.id;
      }
      if (itemType === 'space' && state.modifierKeys.alt) {
        const space = state.spaces.items.get(itemId);
        if (!space) {
          throw new Error(
            'Sensor duplicate dragged but original did not exist!'
          );
        }
        const spaceName = Space.generateName(state.spaces);
        let newSpace: Space;
        if (space.shape.type === 'box') {
          newSpace = Space.createBox(
            position,
            spaceName,
            space.shape.width,
            space.shape.height
          );
        } else if (space.shape.type === 'circle') {
          newSpace = Space.createCircle(
            position,
            spaceName,
            space.shape.radius
          );
        } else if (space.shape.type === 'polygon') {
          newSpace = Space.createPolygon(space.shape.vertices, spaceName);
        } else {
          return state;
        }
        nextState = State.addSpace(state, newSpace);
        itemId = newSpace.id;
      }
      if (itemType === 'reference' && state.modifierKeys.alt) {
        const reference = state.references.items.get(itemId);
        if (!reference) {
          throw new Error(
            'Reference duplicate dragged but original did not exist!'
          );
        }

        let newReference: Reference;
        if (reference.type === 'point') {
          newReference = Reference.createPoint(position);
        } else if (reference.type === 'ruler') {
          newReference = Reference.createRuler(
            reference.positionA,
            reference.positionB
          );
        } else {
          return state;
        }
        nextState = State.addReference(state, newReference);
        itemId = newReference.id;
      }

      return {
        ...nextState,
        manipulatedObject: {
          itemType,
          itemId,
          initialPosition: {
            floorplan: position,
            viewport: itemPosition,
            clientX,
            clientY,
          },
          currentPosition: position,
        },
      };
    }

    case 'item.graphic.dragmove': {
      if (state.manipulatedObject === null) {
        console.warn(
          'item.graphic.dragmove called, but there is no manipulatedObject'
        );
        return state;
      }

      if (
        action.itemType !== state.manipulatedObject.itemType //||
        // action.itemId !== state.manipulatedObject.itemId
      ) {
        throw new Error(
          'item.graphic.dragmove called, but item type does not match'
        );
      }

      const position = action.itemPosition;

      const nextState: State = {
        ...state,
        manipulatedObject: {
          ...state.manipulatedObject,
          currentPosition: position,
        },
      };

      const { itemType } = action;
      if (itemType === 'sensor') {
        return State.updateSensor(nextState, state.manipulatedObject.itemId, {
          position,
        });
      }
      if (itemType === 'space') {
        return State.updateSpace(
          nextState,
          state.manipulatedObject.itemId,
          (space) => {
            if (space.shape.type !== 'polygon') {
              return { ...space, position };
            }

            // Polygonal spaces should have all their vertices translated the same amount as the
            // center
            const positionDeltaX = position.x - space.position.x;
            const positionDeltaY = position.y - space.position.y;
            return {
              ...space,
              position,
              shape: {
                ...space.shape,
                vertices: space.shape.vertices.map((v) =>
                  FloorplanCoordinates.create(
                    v.x + positionDeltaX,
                    v.y + positionDeltaY
                  )
                ),
              },
            };
          }
        );
      }
      if (itemType === 'reference') {
        const reference = state.references.items.get(
          state.manipulatedObject.itemId
        );
        if (!reference) {
          return state;
        }

        switch (reference.type) {
          case 'point': {
            return State.updateReference<ReferencePoint>(
              nextState,
              action.itemId,
              {
                position,
              }
            );
          }
          case 'ruler': {
            const previousPosition = state.manipulatedObject.currentPosition;
            const dx = position.x - previousPosition.x;
            const dy = position.y - previousPosition.y;

            const ruler = state.references.items.get(action.itemId) as
              | ReferenceRuler
              | undefined;

            if (!ruler) {
              return state;
            }
            const positionA = {
              ...ruler.positionA,
              x: ruler.positionA.x + dx,
              y: ruler.positionA.y + dy,
            };
            const positionB = {
              ...ruler.positionB,
              x: ruler.positionB.x + dx,
              y: ruler.positionB.y + dy,
            };

            // Move distance marker to keep it in the center if needed
            let distanceLabelPosition = ruler.distanceLabelPosition;
            if (reference.currentIsDistanceLockedToCenter) {
              const centerPoint = ReferenceRuler.calculateCenterPoint(ruler);
              distanceLabelPosition = {
                ...distanceLabelPosition,
                x: centerPoint.x,
                y: centerPoint.y,
              };
            }

            return State.updateReference<ReferenceRuler>(
              nextState,
              action.itemId,
              {
                positionA,
                positionB,
                distanceLabelPosition,
              }
            );
          }
        }
      }
      if (itemType === 'simulant') {
        return State.updateSimulant(nextState, state.manipulatedObject.itemId, {
          position,
        });
      }
      if (itemType === 'photogroup') {
        return State.updatePhotoGroup(
          nextState,
          state.manipulatedObject.itemId,
          (photoGroup) => {
            if (photoGroup.locked) {
              return photoGroup;
            }
            return {
              position,
              operationToPerform: PhotoGroup.computeOperationAfterModification(
                photoGroup.operationToPerform
              ),
            };
          }
        );
      }
      return nextState;
    }

    case 'item.graphic.dragend': {
      return {
        ...state,
        manipulatedObject: null,
      };
    }

    case 'item.graphic.dragcancel': {
      if (state.manipulatedObject === null) {
        console.warn('Tried to cancel drag, but no manipulatedObject exists');
        return state;
      }
      const { itemType, itemId } = state.manipulatedObject;
      const position = state.manipulatedObject.initialPosition.floorplan;
      const nextState: State = {
        ...state,
        manipulatedObject: null,
      };

      if (itemType === 'sensor') {
        return State.updateSensor(nextState, itemId, {
          position,
        });
      }
      if (itemType === 'space') {
        return State.updateSpace(nextState, itemId, {
          position,
        });
      }
      if (itemType === 'reference') {
        return State.updateReference<ReferencePoint>(nextState, itemId, {
          position,
        });
      }
      if (itemType === 'simulant') {
        return State.updateSimulant(nextState, itemId, {
          position,
        });
      }
      if (itemType === 'photogroup') {
        return State.updatePhotoGroup(nextState, itemId, (photoGroup) => ({
          position,
          operationToPerform: PhotoGroup.computeOperationAfterModification(
            photoGroup.operationToPerform
          ),
        }));
      }
      return nextState;
    }

    case 'viewport.effect.edgeScroll': {
      return State.updateViewport(state, {
        left: state.viewport.left + action.dx,
        top: state.viewport.top + action.dy,
      });
    }

    case 'save.pending': {
      return { ...state, savePending: true };
    }

    case 'save.error': {
      // FIXME: for now this has no effect
      return { ...state, savePending: false };
    }

    case 'save.success': {
      // After saving, reset the "operationToPerform" to null because the operation has been
      // performed!
      let newState = State.resetAllPhotoGroupOperationAfterSave(state);

      // Ensure that photos within photo groups have the most up to date "image.url" property and
      // "image.dirty" is set to false
      newState = State.updatePhotoGroupPhotoImageUrl(
        newState,
        action.photoGroupPhotoUrls
      );

      return {
        ...newState,

        // Ensure the save button is no longer disabled
        savePending: false,

        // Reset the "unsavedModifications" after a save is successfully performed
        unsavedModifications: false,

        // The ceiling raster has been updated, so reset the flag
        heightMapUpdated: false,
      };
    }

    case 'keyboard.modifier.change': {
      let newState = state;
      newState.modifierKeys[action.key] = action.down;
      return newState;
    }

    case 'latestDXF.uploadBegin': {
      let nextState = State.blurFocusedObject(state);
      nextState = State.blurPhotoGroup(nextState);
      return {
        ...nextState,
        latestDXF: {
          status: 'uploading',
        },
      };
    }
    case 'latestDXF.uploadProgress': {
      if (!state.latestDXF || state.latestDXF.status !== 'uploading') {
        return state;
      }
      return {
        ...state,
        latestDXF: {
          ...state.latestDXF,
          fileUploadPercent: action.fileUploadPercent,
        },
      };
    }
    case 'latestDXF.uploadComplete': {
      if (!state.latestDXF) {
        return state;
      }
      return {
        ...state,
        latestDXF: {
          status: 'created',
          id: action.planDXF.id,
          createdAt: action.planDXF.created_at,
          parseOptions: action.planDXF.options,
        },
      };
    }
    case 'latestDXF.uploadError': {
      return {
        ...state,
        latestDXF: {
          status: 'upload_error',
        },
      };
    }
    case 'latestDXF.update': {
      if (!state.latestDXF) {
        return state;
      }
      return {
        ...state,
        latestDXF: {
          status: action.planDXF.status,
          id: action.planDXF.id,
          createdAt: action.planDXF.created_at,
          parseOptions: action.planDXF.options,
        },
      };
    }
    case 'latestDXF.edit': {
      if (
        !state.latestDXF ||
        state.latestDXF.status === 'uploading' ||
        state.latestDXF.status === 'upload_error'
      ) {
        return state;
      }

      let nextState = State.blurFocusedObject(state);
      nextState = State.blurPhotoGroup(nextState);

      return {
        ...nextState,
        latestDXFEdit: {
          active: true,
          planDXF: action.planDXF,
          cadFileUnit: action.planDXF.length_unit,
          cadFileScale: action.planDXF.scale || 1,
          pixelsPerCADUnit:
            action.fullImageAsset.pixels_per_unit ||
            DEFAULT_PIXELS_PER_CAD_UNIT,
          floorplanCADOrigin: state.floorplanCADOrigin,
          operationType:
            action.planDXF.sensor_placements.length > 0 ? 'both' : 'floorplan',
          parseOptions: state.latestDXF.parseOptions,
          loading: false,
        },
      };
    }
    case 'latestDXFEdit.changeUnit': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          cadFileUnit: action.unit,
        },
      };
    }
    case 'latestDXFEdit.changeScale': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          cadFileScale: action.scale,
        },
      };
    }
    case 'latestDXFEdit.changeOASensorLayer': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          parseOptions: {
            ...state.latestDXFEdit.parseOptions,
            oa: {
              ...state.latestDXFEdit.parseOptions.oa,
              layer: action.layerName,
            },
          },
        },
      };
    }
    case 'latestDXFEdit.changeEntrySensorLayer': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          parseOptions: {
            ...state.latestDXFEdit.parseOptions,
            entry: {
              ...state.latestDXFEdit.parseOptions.entry,
              layer: action.layerName,
            },
          },
        },
      };
    }
    case 'latestDXFEdit.changeFloorplanCADOrigin': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          floorplanCADOrigin: action.coords,
        },
      };
    }
    case 'latestDXFEdit.changeOperationType': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          operationType: action.operationType,
        },
      };
    }
    case 'latestDXFEdit.beginAsyncOperation': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          loading: true,
        },
      };
    }
    case 'latestDXFEdit.cancel': {
      return {
        ...state,
        latestDXFEdit: {
          active: false,
        },
      };
    }
    case 'latestDXFEdit.applyFloorplanChanges': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      const cadFileUnitOrDefault = action.cadFileUnitOrDefault;
      const cadFileScaleOrDefault = action.cadFileScaleOrDefault;

      let newFloorplan: Floorplan;
      if (action.mode === 'create') {
        const initialFloorplan = {
          width: action.newBaseImage.width,
          height: action.newBaseImage.height,
          scale: 1,
          origin: ImageCoordinates.create(0, 0),
        };

        // Calcualte the initial scale for the floorplan
        const coordA = CADCoordinates.toFloorplanCoordinates(
          CADCoordinates.create(0, 0),
          initialFloorplan,
          FloorplanCoordinates.create(0, 0),
          cadFileUnitOrDefault,
          cadFileScaleOrDefault
        );
        const coordB = CADCoordinates.toFloorplanCoordinates(
          CADCoordinates.create(1, 0),
          initialFloorplan,
          FloorplanCoordinates.create(0, 0),
          cadFileUnitOrDefault,
          cadFileScaleOrDefault
        );
        const metersPerCADUnit = coordB.x - coordA.x;
        const ratio = metersPerCADUnit / state.latestDXFEdit.pixelsPerCADUnit;
        const cadImageScale = ratio * initialFloorplan.scale;

        newFloorplan = {
          ...initialFloorplan,
          scale: (1 / cadImageScale) * action.imageResizeScale,
        };
      } else {
        newFloorplan = computeNewFloorplanForCAD(
          state.floorplan,
          action.newBaseImage,
          state.floorplanCADOrigin,
          cadFileUnitOrDefault,
          cadFileScaleOrDefault,
          action.imageResizeScale
        );
      }

      const newFloorplanCADOrigin = computeDefaultCADOrigin(newFloorplan);

      let nextState = state;
      for (let change of action.floorplanChanges) {
        switch (change.type) {
          case 'addition':
            const filteredSensors = Sensor.filterByType(
              change.data.type,
              state.sensors
            );
            const sensorName = Sensor.generateName(
              change.data.type,
              filteredSensors.length
            );
            const sensorPosition = CADCoordinates.toFloorplanCoordinates(
              change.data.position,
              newFloorplan,
              newFloorplanCADOrigin,
              cadFileUnitOrDefault,
              cadFileScaleOrDefault
            );
            const sensor = Sensor.create(
              change.data.type,
              sensorPosition,
              change.data.height,
              change.data.rotation,
              sensorName,
              '',
              change.data.cadId
            );
            sensor.serialNumber = change.data.serialNumber;
            sensor.locked = true;
            nextState = State.addSensor(nextState, sensor, true);
            break;
          case 'deletion':
            nextState = State.removeSensor(nextState, change.data.id, true);
            break;
          case 'modification':
            const changeData = change.data;
            nextState = State.updateSensor(
              nextState,
              change.oldData.id,
              (sensor: Sensor) => {
                return {
                  ...sensor,
                  ...changeData,
                  position: CADCoordinates.toFloorplanCoordinates(
                    changeData.position,
                    newFloorplan,
                    newFloorplanCADOrigin,
                    cadFileUnitOrDefault,
                    cadFileScaleOrDefault
                  ),
                };
              },
              true
            );
            break;
          case 'no-change':
            break;
        }
      }

      const measurementDistanceInPixels = action.newBaseImage.width;
      const measurementDistanceInMeters =
        measurementDistanceInPixels / newFloorplan.scale;
      const [feet, inches] = Meters.toFeetAndInches(
        measurementDistanceInMeters
      );

      const newMeasurement = {
        pointA: ImageCoordinates.create(0, 0),
        pointB: ImageCoordinates.create(measurementDistanceInPixels, 0),
        userEnteredLength: {
          feetText: `${feet}`,
          inchesText: `${inches}`,
        },
        computedLength: measurementDistanceInMeters,
        computedScale: newFloorplan.scale,
      };

      return {
        ...nextState,
        floorplanImage: action.newBaseImage,
        floorplanCADOrigin: newFloorplanCADOrigin,
        floorplan: newFloorplan,
        measurement: newMeasurement,
        activeDXFId: action.activeDXFId,
        latestDXFEdit: {
          active: false,
        },
      };
    }

    case 'export.begin': {
      return { ...state, activeExport: { status: 'requesting' } };
    }
    case 'export.beginFailed': {
      return { ...state, activeExport: { status: 'request-failed' } };
    }
    case 'export.update': {
      return { ...state, activeExport: action.planExport };
    }
    case 'export.saving': {
      return { ...state, activeExport: { status: 'saving' } };
    }
    case 'export.savingComplete': {
      return { ...state, activeExport: { status: 'saving-complete' } };
    }
    case 'export.savingFailed': {
      return { ...state, activeExport: { status: 'saving-failed' } };
    }
    case 'export.reset': {
      return { ...state, activeExport: null };
    }

    case 'layers.heightMap.focus': {
      return State.focusLayer(state, LayerId.HEIGHTMAP);
    }

    case 'layers.dismiss': {
      return State.blurFocusedObject(state);
    }

    case 'heightMap.saveNotes': {
      if (!state.heightMap.enabled) {
        return state;
      }
      return {
        ...state,
        heightMap: {
          ...state.heightMap,
          notes: action.notes,
        },
      };
    }

    case 'heightMap.changeOpacity': {
      if (!state.heightMap.enabled) {
        return state;
      }
      return {
        ...state,
        heightMap: {
          ...state.heightMap,
          opacity: action.opacity,
        },
      };
    }

    case 'heightMapImport.startUpload': {
      return {
        ...state,
        heightMapImport: {
          view: 'uploading-image',
          fileName: action.fileName,
        },
      };
    }

    case 'heightMapImport.uploadProgress': {
      if (state.heightMapImport.view !== 'uploading-image') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          fileUploadPercent: action.percent,
        },
      };
    }

    case 'heightMapImport.finishUpload': {
      return {
        ...state,
        heightMapImport: {
          view: 'ready',
          objectKey: action.objectKey,
          url: action.url,
          position: FloorplanCoordinates.create(0, 0),
          rotation: 0,
          opacity: 100,
          notes: '',
          limits: { enabled: false },
          geotiffTransformationData: {
            enabled: false,
          },
        },
      };
    }

    case 'heightMapImport.edit': {
      if (state.heightMapImport.view !== 'ready') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          view: 'enabled',
        },
      };
    }

    case 'heightMapImport.beginRegistration': {
      if (!state.heightMap.enabled) {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          view: 'enabled',
          ...state.heightMap,
        },
      };
    }

    case 'heightMapImport.changeRegistration': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          position: action.position,
          rotation: action.rotation,
        },
      };
    }

    case 'heightMapImport.changePosition': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          position: action.position,
        },
      };
    }

    case 'heightMapImport.setGeoTiffOffsets': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          geotiffTransformationData: {
            enabled: true,
            tiePoint: action.tiePoint,
            scale: action.scale,
          },
        },
      };
    }

    case 'heightMapImport.changeRotation': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          rotation: action.rotation,
        },
      };
    }

    case 'heightMapImport.rotateRight90': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }

      let newRotation = state.heightMapImport.rotation + 90;
      if (newRotation >= 360) {
        newRotation -= 360;
      }

      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          rotation: newRotation,
        },
      };
    }

    case 'heightMapImport.clear': {
      return {
        ...state,
        heightMapImport: { view: 'disabled' },
        heightMapUpdated: true,
        heightMap: { enabled: false },
      };
    }

    case 'heightMapImport.changeBounds': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          limits: {
            enabled: true,
            minMeters: action.minMeters,
            maxMeters: action.maxMeters,
          },
        },
      };
    }

    case 'heightMapImport.changeOpacity': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          opacity: action.opacity,
        },
      };
    }

    case 'heightMapImport.save': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: { view: 'disabled' },

        // Show the height map after saving
        planning: {
          ...state.planning,
          showCeilingHeightMap: true,
        },

        heightMapUpdated: true,
        heightMap: {
          enabled: true,
          url: state.heightMapImport.url,
          objectKey: state.heightMapImport.objectKey,
          position: state.heightMapImport.position,
          rotation: state.heightMapImport.rotation,
          opacity: state.heightMapImport.opacity,
          notes: state.heightMapImport.notes,
          limits: state.heightMapImport.limits,
          geotiffTransformationData:
            state.heightMapImport.geotiffTransformationData,
        },
      };
    }
    case 'heightMapImport.cancel': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          view: state.heightMap ? 'disabled' : 'ready',
        },
      };
    }

    case 'sensorCoverageIntersectionVectors.beginCalculating': {
      const nextSensorCoverageIntersectionVectors = new Map(
        state.sensorCoverageIntersectionVectors
      );
      for (const id of action.sensorIds) {
        nextSensorCoverageIntersectionVectors.set(id, 'loading');
      }

      return {
        ...state,
        sensorCoverageIntersectionVectors:
          nextSensorCoverageIntersectionVectors,
      };
    }

    case 'sensorCoverageIntersectionVectors.setResult': {
      // If the update is not in progress, then reject the result
      // An example situation where this could happen: a user moves a sensor while it's already
      // calculating
      if (
        state.sensorCoverageIntersectionVectors.get(action.sensorId) !==
        'loading'
      ) {
        return state;
      }

      const nextSensorCoverageIntersectionVectors = new Map(
        state.sensorCoverageIntersectionVectors
      );
      nextSensorCoverageIntersectionVectors.set(action.sensorId, action.result);

      return {
        ...state,
        sensorCoverageIntersectionVectors:
          nextSensorCoverageIntersectionVectors,
      };
    }

    case 'autoLayout.begin': {
      if (state.autoLayout.active) {
        return state;
      }
      const nextState = State.blurPhotoGroup(state);
      return {
        ...nextState,
        autoLayout: {
          active: true,
          boundingRegionPlacementEnabled: true,
          boundingRegionVertices: [],
          minimumExclusiveArea: 1,
          sensorHeight: Meters.fromFeetAndInches(8, 0),
          originPosition: null,
          cadIdPrefix: '',
          heightMapEnabled: state.heightMap.enabled,
        },
        autoLayoutSensorPositions: null,
        autoLayoutSensorPositionsDone: false,
      };
    }

    case 'autoLayout.placeVertex': {
      if (!state.autoLayout.active) {
        return state;
      }
      if (!state.autoLayout.boundingRegionPlacementEnabled) {
        return state;
      }
      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          boundingRegionVertices: [
            ...state.autoLayout.boundingRegionVertices,
            action.vertex,
          ],
        },
      };
    }

    case 'autoLayout.changeVertexPosition': {
      if (!state.autoLayout.active) {
        return state;
      }

      const boundingRegionVerticesCopy =
        state.autoLayout.boundingRegionVertices.slice();
      boundingRegionVerticesCopy[action.index] = action.vertex;

      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          boundingRegionVertices: boundingRegionVerticesCopy,
        },
      };
    }

    case 'autoLayout.completeBoundingRegion': {
      if (!state.autoLayout.active) {
        return state;
      }
      if (!state.autoLayout.boundingRegionPlacementEnabled) {
        return state;
      }

      const averageX =
        state.autoLayout.boundingRegionVertices.reduce(
          (sum, v) => sum + v.x,
          0
        ) / state.autoLayout.boundingRegionVertices.length;
      const averageY =
        state.autoLayout.boundingRegionVertices.reduce(
          (sum, v) => sum + v.y,
          0
        ) / state.autoLayout.boundingRegionVertices.length;

      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          boundingRegionPlacementEnabled: false,
          originPosition: FloorplanCoordinates.create(averageX, averageY),
        },
      };
    }

    case 'autoLayout.resetBoundingRegion': {
      if (!state.autoLayout.active) {
        return state;
      }

      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          boundingRegionPlacementEnabled: true,
          boundingRegionVertices: [],
          originPosition: null,
        },
      };
    }

    case 'autoLayout.changeMinimumExclusiveArea': {
      if (!state.autoLayout.active) {
        return state;
      }

      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          minimumExclusiveArea: action.value,
        },
      };
    }

    case 'autoLayout.changeSensorHeight': {
      if (!state.autoLayout.active) {
        return state;
      }

      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          sensorHeight: action.height,
        },
      };
    }

    case 'autoLayout.changeHeightMapEnabled': {
      if (!state.autoLayout.active) {
        return state;
      }

      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          heightMapEnabled: action.enabled,
        },
      };
    }

    case 'autoLayout.changeCadIdPrefix': {
      if (!state.autoLayout.active) {
        return state;
      }

      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          cadIdPrefix: action.prefix,
        },
      };
    }

    case 'autoLayout.changeOriginPosition': {
      if (!state.autoLayout.active) {
        return state;
      }

      return {
        ...state,
        autoLayout: {
          ...state.autoLayout,
          originPosition: action.origin,
        },
      };
    }

    case 'autoLayout.clearSensorPositions': {
      if (!state.autoLayout.active) {
        return state;
      }

      return {
        ...state,
        autoLayoutSensorPositions: null,
        autoLayoutSensorPositionsDone: false,
      };
    }

    case 'autoLayout.setSensorPositions': {
      if (!state.autoLayout.active) {
        return state;
      }

      return {
        ...state,
        autoLayoutSensorPositions: action.sensorPositions,
        autoLayoutSensorPositionsDone: action.done,
      };
    }

    case 'autoLayout.submit': {
      if (
        !state.autoLayout.active ||
        !state.autoLayoutSensorPositions ||
        !state.autoLayoutSensorPositionsDone
      ) {
        return state;
      }

      // Find the largest CAD ID which starts with the specified prefix
      const cadIdPrefix = state.autoLayout.cadIdPrefix || '';
      const allOACadIdNumberSections = FloorplanCollection.list(state.sensors)
        .filter((sensor) => sensor.cadId.startsWith(cadIdPrefix))
        .map((sensor) => sensor.cadId.slice(cadIdPrefix.length))
        // Disregard entry cad ids
        .filter((unPrefixedCadId) => !unPrefixedCadId.startsWith('E'))
        .map((unPrefixedCadId) => {
          const match = /([0-9]+)$/.exec(unPrefixedCadId);
          return match ? match[1] : null;
        });
      const allOACadIdNumbers = allOACadIdNumberSections
        .filter((num) => num !== null)
        .map((num) => parseInt(num as string, 10))
        .filter((num) => !isNaN(num));

      let largestPrexistingCadIdNumber = Math.max(...allOACadIdNumbers);
      if (largestPrexistingCadIdNumber < 0) {
        largestPrexistingCadIdNumber = 0;
      }

      // Figure out how many numerical digits were used in cad ids on this plan
      // ie, OAS0001 would have 4 numerical digits
      const longestNumberOfDigitsInCadId = Math.max(
        ...allOACadIdNumberSections.map((n) => (n ? n.length : 0))
      );

      // When generating a cad id, the numeric part should have at least this many digits
      const maxNumberOfSensors =
        largestPrexistingCadIdNumber + state.autoLayoutSensorPositions.length;
      const defaultCadIdDigits = 3;
      const maxCadIdDigits = Math.max(
        `${maxNumberOfSensors}`.length,
        longestNumberOfDigitsInCadId,
        defaultCadIdDigits
      );

      let nextState = state;
      let cadIdNumber = largestPrexistingCadIdNumber;
      for (const [position, height] of state.autoLayoutSensorPositions) {
        const filteredSensors = Sensor.filterByType('oa', nextState.sensors);
        const sensorName = Sensor.generateName('oa', filteredSensors.length);
        const sensor = Sensor.create('oa', position, height, 0, sensorName);
        sensor.locked = true;

        cadIdNumber += 1;
        const paddedCadId = `${cadIdNumber}`.padStart(maxCadIdDigits, '0');
        sensor.cadId = state.autoLayout.cadIdPrefix
          ? `${state.autoLayout.cadIdPrefix}-OAS${paddedCadId}`
          : `OAS${paddedCadId}`;

        nextState = State.addSensor(nextState, sensor);
      }

      return {
        ...nextState,
        autoLayoutSensorPositions: null,
        autoLayoutSensorPositionsDone: false,
        autoLayout: { active: false },
      };
    }

    case 'autoLayout.cancel': {
      return {
        ...state,
        autoLayout: { active: false },
      };
    }

    default: {
      return state;
    }
  }
};
