import QuickLRU from 'quick-lru';

import { useEffect, FunctionComponent } from 'react';
import * as PIXI from 'pixi.js';

import { useFloorplanLayerContext } from './floorplan-layer-context';
import Layer from './layer';

// Store cached HTMLImageElement's for each image src to avoid having to refetch them
const bigImageCache = new QuickLRU<string, HTMLImageElement>({ maxSize: 16 });

// A custom pixi.js object for rendering large images, since webgl has a max texture size limit
// This will split the image up into tiles and render each tile seamlessly next to each other
export class BigImage extends PIXI.Container {
  imageSrc: string;
  tileSizeInPx: number;
  image: HTMLImageElement | null;
  applyCacheBustingHack: boolean;

  constructor(
    imageSrc: string,
    tileSizeInPx = 1024,
    applyCacheBustingHack = true
  ) {
    super();
    this.imageSrc = imageSrc;
    this.tileSizeInPx = tileSizeInPx;
    this.image = null;
    this.applyCacheBustingHack = applyCacheBustingHack;
    this.splitIntoTiles();
  }

  async splitIntoTiles() {
    // Remove all pre-existing tiles
    for (const child of this.children) {
      this.removeChild(child);
    }

    // Cache images so that if they have already been fetched they don't have to be refetched.
    let imageSrc = this.imageSrc;
    this.image = bigImageCache.get(imageSrc) || null;
    if (!this.image) {
      let cacheBustedImageSrc = imageSrc;
      if (this.applyCacheBustingHack && imageSrc.includes('s3.amazonaws.com')) {
        // In order to ensure chrome doesn't cache the base image, a cache-busting query parameter is
        // needed. See https://github.com/DensityCo/nashi-client/blob/master/src/utils/downloadImage.ts
        // for where I got this technique, or talk to wei-wei for more info.
        const result = new URL(imageSrc);
        result.searchParams.set('nonce', `${new Date().getTime()}`);
        cacheBustedImageSrc = result.toString();
      }

      // Load the image from the server
      this.image = new Image();
      this.image.crossOrigin = 'anonymous';
      const loadPromise = new Promise((resolve) => {
        if (!this.image) {
          throw new Error('Image is not defined!');
        }
        this.image.onload = resolve;
      });
      this.image.src = cacheBustedImageSrc;
      await loadPromise;

      // Store into cache
      bigImageCache.set(imageSrc, this.image);
    }

    const canvas = document.createElement('canvas');
    canvas.width = this.image.width;
    canvas.height = this.image.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error(
        'BigImage: Could not get 2d context for offscreen canvas!'
      );
    }
    ctx.drawImage(this.image, 0, 0);

    // Split image up into tiles and create a sprite for each one
    for (let x = 0; x < canvas.width; x += this.tileSizeInPx) {
      for (let y = 0; y < canvas.height; y += this.tileSizeInPx) {
        const imageData = ctx.getImageData(
          x,
          y,
          this.tileSizeInPx,
          this.tileSizeInPx
        );
        const texture = PIXI.Texture.fromBuffer(
          imageData.data as unknown as Uint8Array,
          this.tileSizeInPx,
          this.tileSizeInPx
        );
        const sprite = new PIXI.Sprite(texture);
        sprite.x = x;
        sprite.y = y;
        sprite.width = this.tileSizeInPx;
        sprite.height = this.tileSizeInPx;
        this.addChild(sprite);
      }
    }
  }
}

// The base image layer is the lowest level element on a floorplan. It renders the image at the
// back that illustrates the basic, underlying geometry of the floor.
const BaseImageLayer: FunctionComponent<{
  image: HTMLImageElement;
  grayscale: boolean;
  visible: boolean;
}> = ({ image, grayscale, visible }) => {
  const context = useFloorplanLayerContext();

  useEffect(() => {
    const baseImageLayer = new BigImage(image.src);
    baseImageLayer.name = 'base-image-layer';
    baseImageLayer.alpha = 0.5;

    // Apply blend mode and make floorplan grayscale
    // ref: https://github.com/pixijs/pixijs/issues/1598#issuecomment-284810464
    let colorMatrix = new PIXI.filters.ColorMatrixFilter();
    if (grayscale) {
      colorMatrix.desaturate();
    }
    colorMatrix.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    baseImageLayer.filters = [colorMatrix];

    context.app.stage.addChild(baseImageLayer);

    return () => {
      context.app.stage.removeChild(baseImageLayer);
    };
  }, [context.app, image, grayscale]);

  return (
    <Layer
      onAnimationFrame={() => {
        const viewport = context.viewport.current;
        if (!viewport) {
          return;
        }

        const baseImageLayer = context.app.stage.getChildByName(
          'base-image-layer'
        ) as BigImage;
        if (!baseImageLayer.image) {
          return;
        }
        baseImageLayer.renderable = visible;
        baseImageLayer.x = -1 * viewport.left * viewport.zoom;
        baseImageLayer.y = -1 * viewport.top * viewport.zoom;
        baseImageLayer.scale.set(
          (baseImageLayer.image.width / context.floorplan.width) * viewport.zoom
        );
      }}
    />
  );
};
export default BaseImageLayer;
