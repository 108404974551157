import { useCallback, useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { Icons } from '@densityco/ui';

import styles from './styles.module.scss';

import InternalTool from 'components/internal-tool';
import keyboardIcon from 'img/icon-keyboard.svg';
import sensorOAIcon from 'img/icon-sensor.svg';
import sensorEntryIcon from 'img/icon-sensor-entry.svg';
import zoomInIcon from 'img/icon-zoom-in.svg';
import panIcon from 'img/icon-hand.svg';
import rotateRightIcon from 'img/icon-rotate-right.svg';
import duplicateIcon from 'img/icon-duplicate.svg';
import trashIcon from 'img/icon-trash.svg';

type KeyboardShortcutEntry = {
  icon: string;
  title: string;
  shortcut: string;
  internal: boolean;
};

const READONLY_ENTRIES: Array<KeyboardShortcutEntry> = [
  {
    icon: panIcon,
    title: 'Pan Floor',
    shortcut: 'space + drag',
    internal: false,
  },
  {
    icon: zoomInIcon,
    title: 'Zoom In/Out',
    shortcut: 'cmd + scroll',
    internal: false,
  },
];

const ENTRIES: Array<KeyboardShortcutEntry> = [
  {
    icon: panIcon,
    title: 'Pan Floor',
    shortcut: 'space + drag',
    internal: false,
  },
  {
    icon: duplicateIcon,
    title: 'Duplicate',
    shortcut: 'alt + drag',
    internal: false,
  },
  {
    icon: zoomInIcon,
    title: 'Zoom In/Out',
    shortcut: 'cmd + scroll',
    internal: false,
  },
  {
    icon: sensorOAIcon,
    title: 'Add OA Sensor',
    shortcut: '1',
    internal: false,
  },
  {
    icon: sensorEntryIcon,
    title: 'Add Entry Sensor',
    shortcut: '2',
    internal: false,
  },
  {
    icon: rotateRightIcon,
    title: `Rotate object 90${String.fromCharCode(176)}`,
    shortcut: 'r r',
    internal: false,
  },
  {
    icon: trashIcon,
    title: 'Delete object',
    shortcut: 'backspace',
    internal: false,
  },
];

export type Props = {
  open: boolean;
  onMenuToggle: () => void;
  entries?: Array<KeyboardShortcutEntry>;
};

type ComponentWithController<P> = React.FunctionComponent<P> & {
  Controller: React.FunctionComponent<{
    render: (controlledProps: P) => JSX.Element;
  }>;
};

export function MiniShortcutsMenu() {
  return (
    <div
      className={styles.container}
      style={{ position: 'absolute', bottom: 16, right: 16 }}
    >
      <div
        className={classNames(styles.menu, styles.open)}
        style={{ marginBottom: -1 }}
      >
        {READONLY_ENTRIES.map((entry, index) => (
          <div key={index} className={styles.entry}>
            <img src={entry.icon} alt={entry.title} />
            <div className={styles.title}>{entry.title}</div>
            <div className={styles.shortcut}>{entry.shortcut}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

const KeyboardShortcutsMenu: ComponentWithController<Props> = ({
  open,
  onMenuToggle,
  entries = ENTRIES,
}) => {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.menu, { [styles.open]: open })}>
        {entries.map((entry, index) => {
          const node = (
            <div key={index} className={styles.entry}>
              <img src={entry.icon} alt={entry.title} />
              <div className={styles.title}>{entry.title}</div>
              <div className={styles.shortcut}>{entry.shortcut}</div>
            </div>
          );
          return entry.internal ? (
            <InternalTool key={index}>{node}</InternalTool>
          ) : (
            node
          );
        })}
      </div>
      <div className={styles.menuToggle} onClick={onMenuToggle}>
        <img src={keyboardIcon} alt="Keyboard" />
        <div className={styles.title}>Keyboard Shortcuts</div>
        <Icons.Collapse height={18} width={18} color="currentColor" />
      </div>
    </div>
  );
};

KeyboardShortcutsMenu.Controller = ({ render }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onMenuToggle = useCallback(() => {
    return setOpen(!open);
  }, [open]);
  return render({ open, onMenuToggle });
};

export default KeyboardShortcutsMenu;
