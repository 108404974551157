import { Icons } from '@densityco/ui';
import classNames from 'classnames';

import styles from './styles.module.scss';

export default function EmptyStateBuildingsIndex() {
  return (
    <div className={styles.EmptyStateBuildingsIndex}>
      <div className={styles.emptyBuildingStateAppBar}>
        <div className={styles.emptyStateBuildingBreadcrumbIcon}>
          <Icons.Building height={18} width={18} color="currentColor" />
        </div>
        <div className={styles.emptyStateBuildingBreadcrumbLabel}>---</div>
        {/* <Icons.ChevronDown height={18} width={18} color="currentColor"/>   */}
      </div>
      <div className={styles.emptyStateMessage}>
        You haven’t set up your Density virtual environment. Create your first
        building in{' '}
        <a href="https://dashboard.density.io/#/admin/locations">
          Location Management
        </a>{' '}
        before setting up your floors.
      </div>
      <a
        href="https://dashboard.density.io/#/admin/locations/create/building"
        className={classNames([
          styles.buttonSmall,
          styles.primary,
          styles.elevated,
        ])}
      >
        Create a Building
      </a>
    </div>
  );
}
