import { keyframes, css } from '@emotion/react';

const spin = keyframes`
 0% {
  transform: translate3d(-50%, -50%, 0) rotate(0deg);
 }
 100% {
  transform: translate3d(-50%, -50%, 0) rotate(360deg); 
 } 
`;

const CircleSpinner: React.FC<{
  height: number;
  width: number;
  color?: string;
}> = (props) => {
  return (
    <div
      css={css`
        height: ${props.height}px;
        width: ${props.width}px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          height: 12px;
          width: 12px;
          position: relative;

          &::after {
            animation: 1.5s linear infinite ${spin};
            animation-play-state: inherit;
            border: solid 2px ${props.color};
            border-bottom-color: rgba(0, 0, 0, 0);
            border-radius: 50%;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            will-change: transform;
          }
        `}
      ></div>
    </div>
  );
};

export default CircleSpinner;
