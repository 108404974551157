import { AppThunk } from 'redux/store';

export const sendResizeWindowEventThunk = (): AppThunk<() => void> => () => {
  const timeoutId = setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 250);

  return () => {
    clearTimeout(timeoutId);
  };
};
