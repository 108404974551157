import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import { RootState } from 'redux/store';
import { selectSpace } from '../spaces/select-space';
import { selectTimeZone } from '../spaces/select-timezone';

export const selectTimeUsedChartDataForSpace = createSelector(
  [
    (state: RootState) => state.analysis.timeUsedAnalytics,

    selectTimeZone,
    selectSpace,

    // parameters
    (_: RootState, params: { spaceId?: string }) => params.spaceId,
  ],
  (timeUsedAnalytics, timeZone, space, spaceId) => {
    if (!spaceId || !space) {
      return;
    }

    const capacity = space.capacity;
    if (!capacity) {
      return;
    }

    const data = timeUsedAnalytics.entities[spaceId]?.data;
    if (!data?.length) {
      return;
    }

    const aggregated: { time: string; value: number }[] = [];

    data.forEach((datum) => {
      const existing = aggregated.find(
        (a) => moment.tz(a.time, timeZone).diff(datum.date, 'days') === 0
      );

      if (existing) {
        existing.value += datum.raw;
      } else {
        aggregated.push({
          time: datum.date,
          value: datum.raw,
        });
      }
    });

    return aggregated;
  }
);
