import { useCallback, useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { Icons } from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';

import styles from './app-bar-select.module.scss';

const noop = () => {};

interface SelectBoxOption {
  id: string;
  name: string;
}

interface AppBarSelectBoxProps<T> {
  value: T | null;
  options: Array<T>;
  icon?: JSX.Element;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: T) => void;
}

const AppBarSelectBox = <T extends SelectBoxOption>({
  value,
  options,
  icon,
  placeholder = '---',
  disabled = false,
  onChange = noop,
}: AppBarSelectBoxProps<T>) => {
  const [opened, setOpened] = useState<boolean>(false);

  const onMenuToggle = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const onMenuBlur = useCallback(() => {
    setOpened(false);
  }, []);

  const onSelect = useCallback(
    (value: T) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <div
      className={classNames(styles.AppBarSelectBox, {
        [styles.opened]: opened,
      })}
    >
      <div
        className={classNames(styles.Value, {
          [styles.opened]: opened,
          [styles.disabled]: disabled,
        })}
        tabIndex={disabled ? -1 : 0}
        aria-expanded={opened}
        aria-autocomplete="list"
        onClick={onMenuToggle}
        onBlur={onMenuBlur}
      >
        <div className={styles.IconAndText}>
          {icon ? <div className={styles.Icon}>{icon}</div> : null}
          {value ? (
            <div className={styles.Text}>{value.name}</div>
          ) : (
            <div className={styles.Placeholder}>{placeholder}</div>
          )}
        </div>
        <div className={styles.Caret}>
          <Icons.ChevronDown color={colors.midnightOpaque80} />
        </div>
      </div>
      {/* .Value */}

      <div
        className={classNames(styles.Menu, {
          [styles.opened]: opened,
        })}
        role="listbox"
      >
        <ul>
          {options.map((option) => {
            return (
              <AppBarSelectBoxListItem<T>
                key={option.id}
                value={option}
                isSelected={value ? value.id === option.id : false}
                onSelect={onSelect}
                onFocus={onMenuToggle}
                onBlur={onMenuBlur}
                menuOpened={opened}
              >
                {option.name}
              </AppBarSelectBoxListItem>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AppBarSelectBox;

interface AppBarSelectBoxListItemProps<T> {
  value: T;
  isSelected: boolean;
  menuOpened: boolean;
  onSelect: (value: T) => void;
  onFocus: () => void;
  onBlur: () => void;
  children: React.ReactNode;
}

const AppBarSelectBoxListItem = <T extends SelectBoxOption>({
  value,
  isSelected,
  menuOpened,
  onSelect,
  onFocus,
  onBlur,
  children,
}: AppBarSelectBoxListItemProps<T>) => {
  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLLIElement>) => {
      if (evt.keyCode === 13 /* enter */) {
        /* Select this item in the menu */
        onSelect(value);
      } else if (evt.keyCode === 27 /* escape */) {
        /* Blur this item, which closes the dropdown */
        evt.currentTarget.blur();
      }
    },
    [onSelect, value]
  );

  const onClick = useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);

  return (
    <li
      role="option"
      aria-selected={isSelected}
      tabIndex={menuOpened ? 0 : -1}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    >
      {children}
    </li>
  );
};
