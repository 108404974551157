import {
  createSlice,
  createEntityAdapter,
  createAction,
} from '@reduxjs/toolkit';
import { applyPatches } from 'immer';

import { PlanDetail, PlanSummary } from 'lib/api';
import { PlansPatch } from 'lib/patches';
import {
  AsyncTaskState,
  getFulfilledState,
  getInitialAsyncTaskState,
  getPendingState,
  getRejectedState,
  hasValidPendingState,
  withPayloadType,
} from 'lib/redux';
import { RootState } from 'redux/store';
import { asyncFetchPlanDetailThunk } from './async-fetch-plan-detail-thunk';
import { asyncFetchPlanSummariesThunk } from './async-fetch-plan-summaries-thunk';

// mechanism for optimistic/pessimistic updates
export const patchPlansState = createAction(
  'plans/patchPlansState',
  withPayloadType<PlansPatch[]>()
);

const planSummariesAdapter = createEntityAdapter<PlanSummary>({
  selectId: (planSummary) => planSummary.id,
});

export const planDetailsAdapter = createEntityAdapter<PlanDetail>({
  selectId: (planDetail) => planDetail.id,
});

export const planSummariesSelectors =
  planSummariesAdapter.getSelectors<RootState>(
    (state) => state.plans.planSummaries
  );

export const planDetailsSelectors = planDetailsAdapter.getSelectors<RootState>(
  (state) => state.plans.planDetails
);

export const scopedPlanDetailsSelectors = planDetailsAdapter.getSelectors<
  RootState['plans']
>((state) => state.planDetails);

// TODO(wuweiweiwu): need to investigate how to best do loading states
// when managing entities with createEntityAdapter

const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    planSummaries: planSummariesAdapter.getInitialState(),
    planDetails: planDetailsAdapter.getInitialState(),

    // purely for tracking loading states since we're using entity adapters to manager the object
    planSumariesQuery: getInitialAsyncTaskState<void>(),

    // TODO(wuweiweiwu): need better by query state tracking
    // this is so we can track individual query loading states
    // since each call with a different parameter is unique and should be treated so
    // right now we're just using id as a query key
    planDetailQueries: {} as { [planId: string]: AsyncTaskState<void> },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncFetchPlanDetailThunk.pending, (state, action) => {
      state.planDetailQueries[action.meta.arg] =
        state.planDetailQueries[action.meta.arg] ||
        getInitialAsyncTaskState<void>();

      state.planDetailQueries[action.meta.arg] = getPendingState(
        state.planDetailQueries[action.meta.arg],
        action.meta.requestId,
        { force: true }
      );
    });

    builder.addCase(asyncFetchPlanDetailThunk.fulfilled, (state, action) => {
      if (
        hasValidPendingState(
          state.planDetailQueries[action.meta.arg],
          action.meta.requestId
        )
      ) {
        planDetailsAdapter.addOne(state.planDetails, action.payload);
      }

      state.planDetailQueries[action.meta.arg] = getFulfilledState(
        state.planDetailQueries[action.meta.arg],
        action.meta.requestId,
        undefined
      );
    });

    builder.addCase(asyncFetchPlanDetailThunk.rejected, (state, action) => {
      state.planDetailQueries[action.meta.arg] = getRejectedState(
        state.planDetailQueries[action.meta.arg],
        action.meta.requestId,
        action.error
      );
    });

    builder.addCase(asyncFetchPlanSummariesThunk.pending, (state, action) => {
      state.planSumariesQuery = getPendingState(
        state.planSumariesQuery,
        action.meta.requestId
      );
    });

    builder.addCase(asyncFetchPlanSummariesThunk.fulfilled, (state, action) => {
      if (
        hasValidPendingState(state.planSumariesQuery, action.meta.requestId)
      ) {
        planSummariesAdapter.setAll(state.planSummaries, action.payload);
      }

      state.planSumariesQuery = getFulfilledState(
        state.planSumariesQuery,
        action.meta.requestId,
        undefined
      );
    });

    builder.addCase(asyncFetchPlanSummariesThunk.rejected, (state, action) => {
      state.planSumariesQuery = getRejectedState(
        state.planSumariesQuery,
        action.meta.requestId,
        action.error
      );
    });

    builder.addCase(patchPlansState, (state, action) => {
      return applyPatches(state, action.payload);
    });
  },
});

export default plansSlice.reducer;
