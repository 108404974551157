import { createSelector } from '@reduxjs/toolkit';
import { truthy } from 'lib/truthy';
import { RootState } from 'redux/store';
import { spacesSelectors } from './spaces-slice';

export const selectSpaces = createSelector(
  [
    spacesSelectors.selectAll,
    (_: RootState, params: { spaceIds: string[] }) => params.spaceIds,
  ],
  (spaces, spaceIds) => {
    const selectedSpaces = spaceIds.map((spaceId) =>
      spaces.find((s) => s.id === spaceId)
    );

    return selectedSpaces.filter(truthy);
  }
);
