import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';
import { CompatibleDateValue } from '@densityco/ui/dist/cjs/src/date-picker';

import { AppThunkApiConfig } from 'redux/store';
import { DayOfWeek } from '@densityco/lib-common-types';
import { getTimeFilterString, TimeOfDay } from 'lib/date-time';
import { UtilizationAnalyticsResponse } from 'lib/utilization';
import { defaultAppThunkOptions } from 'lib/redux';

export const asyncFetchUtilizationAnalyticsByDayThunk = createAsyncThunk<
  // return value
  UtilizationAnalyticsResponse,
  // parameters
  {
    spaceId: string;

    timeZone: string;
    startDate: CompatibleDateValue;
    endDate: CompatibleDateValue;
    filterStart: TimeOfDay;
    filterEnd: TimeOfDay;
    filterDays: DayOfWeek[];
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'analysis/asyncFetchUtilizationAnalyticsByDay',
  async (args, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing appAPIClient');

    const source = axios.CancelToken.source();
    thunkApi.signal.addEventListener('abort', () => {
      source.cancel();
    });

    const timeFilterString = getTimeFilterString(
      args.filterStart,
      args.filterEnd,
      args.filterDays
    );

    const params = {
      start_date: args.startDate,
      end_date: args.endDate,
      time_filters: timeFilterString,
      space_ids: args.spaceId,
      time_zone: args.timeZone,
    };

    const response = await auth.densityAPIClient.get(
      `/app/analytics/utilization`,
      {
        params: { ...params, granularity: '1d' },
        cancelToken: source.token,
      }
    );

    return response.data;
  },
  {
    ...defaultAppThunkOptions,
    condition: (args, thunkApi) => {
      const { auth } = thunkApi.getState();
      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
