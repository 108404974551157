import { createSelector } from '@reduxjs/toolkit';

import { labelsSelectors } from './spaces-slice';

export const selectLabelOptions = createSelector(
  [labelsSelectors.selectAll],
  (labels) => {
    return labels.map((label) => ({
      value: label.id,
      label: label.name,
    }));
  }
);
