import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { useLayoutEffect, useMemo, useRef } from 'react';
import * as React from 'react';

import { devicePixelRatio } from 'lib/browser';
import { Viewport } from 'lib/viewport';

type Props = {
  image: HTMLImageElement;
  opacity?: number;
  viewport: Viewport;
};

const FloorplanImageView: React.FunctionComponent<Props> = ({
  image,
  opacity,
  viewport,
}) => {
  const canvasElementRef = useRef<HTMLCanvasElement>(null);

  const staticImageCanvas = useMemo(() => {
    const canvas = document.createElement('canvas');
    const { width, height } = image;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
      throw new Error('Could not get 2d context for offscreen canvas');
    }
    ctx.drawImage(image, 0, 0);
    return canvas;
  }, [image]);

  useLayoutEffect(() => {
    const canvas = canvasElementRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.save();
    ctx.scale(devicePixelRatio, devicePixelRatio);

    // Render a light-gray backdrop
    ctx.fillStyle = dust.Gray100;
    ctx.fillRect(0, 0, viewport.width, viewport.height);
    ctx.fillStyle = dust.Midnight;

    // Switch to multiply blending so floorplan images render with light-gray background
    ctx.globalCompositeOperation = 'multiply';

    // ctx.imageSmoothingEnabled = true;
    // ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      staticImageCanvas,
      0,
      0,
      image.width,
      image.height,
      viewport.zoom * -viewport.left,
      viewport.zoom * -viewport.top,
      viewport.zoom * image.width,
      viewport.zoom * image.height
    );

    // Switch back to default blending mode for drawing anything else on top of the floorplan
    ctx.globalCompositeOperation = 'source-over';

    ctx.restore();
  }, [image, staticImageCanvas, viewport, opacity]);

  const { width, height } = viewport;

  return (
    <canvas
      ref={canvasElementRef}
      style={{
        position: 'absolute',
        width,
        height,
        opacity,
        left: 0,
        top: 0,
        filter: 'grayscale(1)', // Ensure all floorplan images are shown in grayscale
      }}
      width={width * devicePixelRatio}
      height={height * devicePixelRatio}
    />
  );
};

export default React.memo(FloorplanImageView);
