import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import styled from '@emotion/styled';

// NOTE: these components are intended to be used with radix ui dropdown menu

export const DropdownMenuContent = styled(DropdownMenu.Content)`
  background-color: ${dust.White};
  border: 1px solid ${dust.Gray200};
  border-radius: 4px;
  box-shadow: ${dust.Elevation300};
  width: fit-content;
  padding: 4px;
`;

export const DropdownMenuItem = styled(DropdownMenu.Item)`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${dust.Gray100};
  }

  &[data-disabled] {
    background-color: ${dust.Gray000};
    color: ${dust.Gray300};
    cursor: not-allowed;
  }
`;
