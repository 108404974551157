import { fetchAllObjects } from '@densityco/lib-common-helpers';
import {
  stringToTimeFilter,
  getNextTimestampInTimeFilter,
  isTimestampInTimeFilter,
} from '@densityco/lib-time-helpers';
import moment from 'moment-timezone';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';
import { DayOfWeek } from '@densityco/lib-common-types';
import { CompatibleDateValue } from '@densityco/ui/dist/cjs/src/date-picker';

import { ProcessedHeatmapData } from './analysis-slice';

import { AppThunkApiConfig } from 'redux/store';
import { FixMe } from 'types/fixme';
import { getTimeFilterString, TimeOfDay } from 'lib/date-time';
import { getHeatmapWorkerApiAndCleanup } from 'lib/get-heatmap-worker-api-and-cleanup';
import { HeatmapFrame } from 'lib/process-heatmap-data';
import { defaultAppThunkOptions } from 'lib/redux';

export const asyncFetchHeatmapThunk = createAsyncThunk<
  // return value
  { data: HeatmapFrame[]; rawStepSize: number },
  // parameters
  {
    planId: string;

    startTime: CompatibleDateValue;
    endTime: CompatibleDateValue;
    filterStart: TimeOfDay;
    filterEnd: TimeOfDay;
    filterDays: DayOfWeek[];
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'analysis/asyncFetchHeatmap',
  async (args, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    const source = axios.CancelToken.source();
    thunkApi.signal.addEventListener('abort', () => {
      source.cancel();
    });

    const timeFilterString = getTimeFilterString(
      args.filterStart,
      args.filterEnd,
      args.filterDays
    );

    // TODO: Restore daily queries when OPEN-412 is resolved
    const duration = moment(args.endTime).diff(moment(args.startTime));
    const interval =
      duration > 604800000 ? '1h' : duration > 86400000 ? '1h' : '15m';
    const intervalSize =
      duration > 604800000 ? 3600000 : duration > 86400000 ? 3600000 : 900000;
    const pageSize = duration > 86400000 ? 2 : 10;

    const params = {
      start_time: args.startTime,
      end_time: args.endTime,
      time_filters: timeFilterString,
      consolidated: true,
      interval,
    };

    const pageTest = (data: FixMe) => {
      return data.results && data.results.length;
    };

    // TODO(wuweiweiwu): fetchAllObjects need to pass cancelToken down to the axios call
    const results = await fetchAllObjects(
      auth.densityAPIClient as FixMe,
      `/v2/floorplans/${args.planId}/heatmap`,
      {
        pageTest,
        params,
        pageSize: pageSize,
        cancelToken: source.token,
      }
    );

    return {
      data: results,
      rawStepSize: intervalSize,
    };
  },
  {
    ...defaultAppThunkOptions,
    condition: (args, thunkApi) => {
      const { auth } = thunkApi.getState();
      if (!auth.densityAPIClient) {
        return false;
      }

      if (moment(args.endTime).diff(args.endTime, 'days') > 14) {
        return false;
      }
    },
  }
);

export const asyncProcessHeatmapDataThunk = createAsyncThunk<
  // return value
  ProcessedHeatmapData,
  // parameters
  {
    planId: string;

    startTime: CompatibleDateValue;
    endTime: CompatibleDateValue;
    filterStart: TimeOfDay;
    filterEnd: TimeOfDay;
    filterDays: DayOfWeek[];
    timeZone: string;

    data: HeatmapFrame[];
    rawStepSize: number;
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'analysis/asyncProcessHeatmapData',
  async (args, thunkApi) => {
    const timeFilter = stringToTimeFilter(
      getTimeFilterString(args.filterStart, args.filterEnd, args.filterDays)
    );

    const filteredStartMoment = getNextTimestampInTimeFilter(
      moment.tz(args.startTime, args.timeZone),
      timeFilter
    );

    const filteredEndMoment = moment.tz(args.endTime, args.timeZone);
    while (!isTimestampInTimeFilter(filteredEndMoment, timeFilter)) {
      filteredEndMoment.subtract(1, 'minute');
    }

    const { workerApi, cleanup } = getHeatmapWorkerApiAndCleanup();

    thunkApi.signal.addEventListener('abort', () => {
      cleanup();
    });

    const processedData = await workerApi.processHeatmapData({
      rawData: args.data,
      dates: [filteredStartMoment.valueOf(), filteredEndMoment.valueOf()],
      windowSize:
        args.rawStepSize === 900000 // Half-hour buckets for 15m raw interval
          ? 1800000
          : args.rawStepSize === 3600000 // One-hour buckets for 1h raw interval
          ? 3600000
          : args.rawStepSize,
      stepSize: args.rawStepSize || 3600000,
      rawStepSize: args.rawStepSize || 3600000,
    });

    return processedData;
  },
  {
    ...defaultAppThunkOptions,
    condition: (args) => {
      if (!args.data.length) {
        return false;
      }
    },
  }
);

// TODO(wuweiweiwu): maybe we can split this up into 2 thunks that are dispatched from analysis
export const asyncFetchAndProcessHeatmapThunk = createAsyncThunk<
  // return value
  void,
  // parameters
  {
    planId: string;

    startTime: CompatibleDateValue;
    endTime: CompatibleDateValue;
    filterStart: TimeOfDay;
    filterEnd: TimeOfDay;
    filterDays: DayOfWeek[];
    timeZone: string;
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'analysis/asyncFetchAndProcessHeatmap',
  async (args, thunkApi) => {
    const heatmapPromise = thunkApi.dispatch(asyncFetchHeatmapThunk(args));

    thunkApi.signal.addEventListener('abort', () => {
      heatmapPromise.abort();
    });

    // NOTE: unwraping the thunk means that if the thunk rejects, it will throw and error here
    const { data, rawStepSize } = await heatmapPromise.unwrap();

    const processHeatmapPromise = thunkApi.dispatch(
      asyncProcessHeatmapDataThunk({ ...args, data, rawStepSize })
    );

    thunkApi.signal.addEventListener('abort', () => {
      processHeatmapPromise.abort();
    });

    await processHeatmapPromise.unwrap();
  },
  defaultAppThunkOptions
);
