import { useEffect, useRef } from 'react';
import * as React from 'react';
import * as d3 from 'd3';
import colors from '@densityco/ui/variables/colors.json';

import { Floorplan } from 'lib/floorplan';
import { FloorplanCoordinates } from 'lib/geometry';
import { Seconds } from 'lib/units';
import { Viewport } from 'lib/viewport';
import { PlanDetail } from 'lib/api';

// How long before the track visually fades out
const TRACK_VISUAL_FADE_TIME_SECONDS = 2;

export type FloorplanTargetInfo = {
  timestamp: number;
  sensorSerial: string;
  position: FloorplanCoordinates;
};

const TrackVisualizer: React.FunctionComponent<{
  plan: PlanDetail;
  floorplan: Floorplan;
  viewport: Viewport;
  targets: Array<FloorplanTargetInfo>;
}> = ({ plan, floorplan, viewport, targets }) => {
  const { width, height } = viewport;
  const canvasElementRef = useRef<HTMLCanvasElement>(null);

  const mutableTargets = useRef<Array<FloorplanTargetInfo>>([]);
  // When targets prop changes, update mutable targets collection
  useEffect(() => {
    mutableTargets.current = [...targets];
  }, [targets]);

  // Renders the targets
  useEffect(() => {
    const canvas = canvasElementRef.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx) return;
    const { width, height } = viewport;

    const opacityScale = d3
      .scaleLinear()
      .domain([0, TRACK_VISUAL_FADE_TIME_SECONDS])
      .range([1, 0])
      .clamp(true);
    let animationFrame: number;
    const update = () => {
      const now = Seconds.fromMilliseconds(Date.now());
      const targets = mutableTargets.current;
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = colors.blue;
      for (const target of targets) {
        const position = FloorplanCoordinates.toViewportCoordinates(
          target.position,
          floorplan,
          viewport
        );
        // const outerRadius =
        //   Meters.fromFeet(2) * plan.image_pixels_per_meter * viewport.zoom;
        // const color = colorBySensorSerial(target.sensorSerial);
        const color = colors.blue;
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.globalAlpha = opacityScale(now - target.timestamp);
        ctx.beginPath();
        ctx.arc(position.x, position.y, 5, 0, Math.PI * 2);
        ctx.fill();
        // ctx.beginPath();
        // ctx.arc(position.x, position.y, outerRadius, 0, Math.PI * 2);
        // ctx.stroke();

        // ctx.beginPath();
        // ctx.closePath();
      }
      animationFrame = window.requestAnimationFrame(update);
    };

    animationFrame = window.requestAnimationFrame(update);

    return () => {
      if (typeof animationFrame === 'number') {
        window.cancelAnimationFrame(animationFrame);
      }
    };
  }, [floorplan, plan, viewport, mutableTargets]); // <---- depends on mutableTargets

  return (
    <canvas
      ref={canvasElementRef}
      width={width}
      height={height}
      style={{ position: 'absolute', pointerEvents: 'none' }}
    />
  );
};

export default TrackVisualizer;
