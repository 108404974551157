import * as React from 'react';

type Theme = 'light' | 'dark';

const ThemeContext = React.createContext<Theme>('light');

const DarkTheme: React.FunctionComponent = ({ children }) => (
  <ThemeContext.Provider value="dark">{children}</ThemeContext.Provider>
);

export const useTheme: () => Theme = () => {
  const theme = React.useContext(ThemeContext);
  return theme;
};

export default DarkTheme;
