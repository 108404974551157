import * as React from 'react';
import { Icons } from '@densityco/ui';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { createSelector } from '@reduxjs/toolkit';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ValueLabel from 'components/value-label/value-label';
import { useAppSelector } from 'redux/store';
import GhostButton from 'components/ghost-button/ghost-button';
import { ReactComponent as ChevronToggleCollapse } from 'img/chevron-toggle-collapse.svg';
import { ReactComponent as ChevronToggleExpand } from 'img/chevron-toggle-expand.svg';
import { selectSubspaces } from 'redux/features/spaces/select-subspaces';
import { Floor } from 'lib/floors';

const Content: React.FC<{ isExpanded?: boolean }> = ({
  children,
  isExpanded,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  // some large initial value so when the content mounts, its already expanded
  const [height, setHeight] = React.useState(10000);

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let height = 0;

    Array.from(ref.current.children).forEach((child) => {
      height += (child as HTMLElement).offsetHeight;
    });

    setHeight(height);
  }, []);

  return (
    <div
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        transition: max-height 0.5s ease;
        overflow: ${isExpanded ? 'auto' : 'hidden'};
        // solution from https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css?rq=1
        // kinda hacky since we have to set an arbitrary max height. We don't know the height of the card (unless we do getBoundingClientRect) since
        // the height grows dependant of the children. it is alleviated by calculating the height from all of the child nodes offsetHeight
        // but if the content of the card is significantly higher, the transition time takes longer
        max-height: ${isExpanded ? height : 0}px;
      `}
    >
      {children}
    </div>
  );
};

const Section = styled.section`
  padding: ${dust.Space4};
  border-bottom: 1px solid ${dust.Gray200};
`;

const selectFloorSpacesCount = createSelector(
  [selectSubspaces],
  (subspaces) => {
    return subspaces.length;
  }
);

const selectFloorCapacity = createSelector([selectSubspaces], (subspaces) => {
  return subspaces.reduce((acc, curr) => acc + (curr.capacity || 0), 0);
});

const MetricsSection: React.FC<{ floor: Floor }> = (props) => {
  const count = useAppSelector((state) =>
    selectFloorSpacesCount(state, { spaceId: props.floor.id })
  );

  const capacity = useAppSelector((state) =>
    selectFloorCapacity(state, { spaceId: props.floor.id })
  );

  return (
    <Section>
      <div
        css={css`
          display: grid;
          row-gap: 12px;
          column-gap: 40px;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 12px;
        `}
      >
        <ValueLabel label="Capacity" value={capacity} />
        <ValueLabel label="Spaces" value={count} />
      </div>
    </Section>
  );
};

const FloorHeader: React.FC<{
  floor: Floor;
  isExpanded: boolean;
  toggleExpanded: VoidFunction;
}> = (props) => {
  const ToggleIcon = props.isExpanded
    ? ChevronToggleCollapse
    : ChevronToggleExpand;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 0 8px 0 12px;
        gap: ${dust.Space3};
        height: 2.5rem;
        border-bottom: 1px solid ${dust.Gray200};
        flex-shrink: 0;
      `}
    >
      <div
        css={css`
          margin-left: -${dust.Space2};
          color: ${dust.Gray400};
          transform: translateY(2px);
        `}
      >
        <Icons.Floor width={24} height={24} color="currentColor" />
      </div>

      <span
        css={css`
          width: 100%;
          font-size: ${dust.TextScale4};
          font-weight: ${dust.FontWeightBold};
          margin-top: 2px;
          // https://css-tricks.com/almanac/properties/l/line-clamp/
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `}
      >
        {props.floor.name}
      </span>

      <div
        css={css`
          display: flex;
          align-items: center;
          margin-left: auto;
          color: ${dust.Gray400};
          gap: ${dust.Space2};
          flex-shrink: 0;
        `}
      >
        <GhostButton onClick={props.toggleExpanded}>
          <ToggleIcon width={24} height={24} color="currentColor" />
        </GhostButton>
      </div>
    </div>
  );
};

type FloorCardProps = {
  floor: Floor;
};

const FloorCard: React.FC<FloorCardProps> = (props) => {
  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );

  const [isExpanded, setIsExpanded] = React.useState(true);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 17.5rem;
        min-width: 17.5rem;
        max-height: ${heatmapEnabled ? 'calc(100% - 80px)' : '100%'};
        padding: 0;
        background-color: ${dust.White};
        border: 1px solid ${dust.Gray200};
        border-radius: ${dust.Radius300};
        box-shadow: ${dust.Elevation300};
        pointer-events: all;
      `}
    >
      <FloorHeader
        floor={props.floor}
        isExpanded={isExpanded}
        toggleExpanded={() => setIsExpanded((prev) => !prev)}
      />

      <Content isExpanded={isExpanded}>
        <MetricsSection floor={props.floor} />
      </Content>
    </div>
  );
};

export default React.memo(FloorCard);
