export type DistributionAnalyticsData = {
  [time: string]: {
    band_start: number;
    band_end: number;
    percentage: number;
    raw: number;
  }[];
};

export type DistributionAnalyticsResponse = {
  results: {
    [spaceId: string]: DistributionAnalyticsData;
  };
};

export const aggregateDistribution = (data: DistributionAnalyticsData) => {
  const allData = Object.values(data);

  if (!allData.length) {
    return [];
  }

  // clone cuz the data from redux is frozen in dev
  const aggregated = allData[0].map((datum) => {
    return {
      band_start: datum.band_start,
      band_end: datum.band_end,
      raw: datum.raw,
    };
  });

  const rest = allData.slice(1);

  aggregated.forEach((aDatum, i) => {
    rest.forEach((rDatum) => {
      aDatum.raw += rDatum[i].raw;
    });
  });

  const total = aggregated.reduce((acc, curr) => {
    return acc + curr.raw;
  }, 0);

  return aggregated.map((datum) => {
    return { ...datum, percentage: datum.raw / total };
  });
};
