import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';

import {
  DropdownMenuContent,
  DropdownMenuItem,
} from 'components/dropdown/dropdown';
import GhostButton from 'components/ghost-button/ghost-button';
import { ReactComponent as SendPaperPlaneShareIcon } from 'img/icon-send-paper-plane-share.svg';
import { ReactComponent as ChevronDownIcon } from 'img/icon-chevron-down.svg';
import { ReactComponent as TableIcon } from 'img/icon-table.svg';
import CircleSpinner from 'components/circle-spinner/circle-spinner';
import { downloadAnalysisCSVLegacyThunk } from 'redux/features/analysis/download-analysis-csv-legacy-thunk';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { Floor } from 'lib/floors';
import { PlanDetail } from 'lib/api';
import { truthy } from 'lib/truthy';
import { useExportCSV } from 'hooks/use-export-csv';
import { useMemo } from 'react';

const ExportDropdownMenu: React.FC<{ plan: PlanDetail; floor: Floor }> = (
  props
) => {
  const dates = useAppSelector((state) => state.analysis.dates);
  const filterDays = useAppSelector((state) => state.analysis.filterDays);
  const filterStart = useAppSelector((state) => state.analysis.filterStart);
  const filterEnd = useAppSelector((state) => state.analysis.filterEnd);

  const analyticsQuery = useAppSelector(
    (state) => state.analysis.analyticsQuery
  );

  const dispatch = useAppDispatch();

  const timeZone = props.floor.time_zone;

  const isDownloadCSVOn = useTreatment(SPLITS.DOWNLOAD_CSV);

  const spaceIds = useMemo(() => {
    return props.plan.areas.map((a) => a.space_id).filter(truthy);
  }, [props.plan.areas]);

  const [triggerExportCSV, isExportingCSV] = useExportCSV({
    floorId: props.floor.id,
    spaceIds,
    timeZone,
    filterStart,
    filterEnd,
    filterDays,
    dates,
  });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <GhostButton
          aria-label="Export dropdown"
          css={css`
            width: 42px;
            height: 32px;
            color: ${dust.Gray700};
          `}
        >
          <SendPaperPlaneShareIcon width={20} height={20} />
          <ChevronDownIcon width={16} height={16} />
        </GhostButton>
      </DropdownMenu.Trigger>

      <DropdownMenuContent align="start">
        <DropdownMenuItem
          disabled={
            isDownloadCSVOn ? isExportingCSV : !analyticsQuery.data?.length
          }
          css={css`
            gap: 8px;
          `}
          onSelect={(e) => {
            // this prevents the menu from closing
            // documentation here https://www.radix-ui.com/docs/primitives/components/dropdown-menu#item
            e.preventDefault();

            if (!dates) {
              return;
            }

            if (isDownloadCSVOn) {
              triggerExportCSV();
            } else {
              dispatch(
                downloadAnalysisCSVLegacyThunk({
                  floorId: props.floor.id,
                  areas: props.plan.areas,
                  timeZone: timeZone,
                  filterStart: filterStart,
                  filterEnd: filterEnd,
                  filterDays: filterDays,
                  startDate: dates[0],
                  endDate: dates[1],
                  data: analyticsQuery.data || [],
                })
              );
            }
          }}
        >
          {isExportingCSV && isDownloadCSVOn ? (
            <>
              <CircleSpinner
                height={24}
                width={24}
                color={isExportingCSV ? dust.Gray300 : dust.Blue400}
              />

              <span
                css={css`
                  font-size: ${dust.TextScale4};
                  font-weight: ${dust.FontWeightMedium};
                `}
              >
                Downloading CSV
              </span>
            </>
          ) : (
            <>
              <TableIcon height={24} width={24} color={dust.Blue400} />

              <span
                css={css`
                  font-size: ${dust.TextScale4};
                  font-weight: ${dust.FontWeightMedium};
                `}
              >
                Export floor data CSV
              </span>
            </>
          )}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu.Root>
  );
};

export default ExportDropdownMenu;
