import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleOrdinal } from '@visx/scale';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';

const colors = [
  dust.Gray100,
  dust.Purple400,
  dust.Blue400,
  dust.Yellow400,
  dust.Teal400,
  dust.Red400,
];
const booleanColors = [dust.Gray100, dust.Blue400];

type Datum = {
  bandStart: number;
  bandEnd: number;
  percentage: number;
  raw: number;
};

export type PieProps = {
  data: Datum[];

  getValue: (d: Datum) => number;
  getLegendLabel?: (d: Datum) => string;

  isBoolean?: boolean;
};

export default function ChartOccupancyDistribution({
  data,
  getValue,
  getLegendLabel,
  isBoolean,
}: PieProps) {
  const ordinalScale = scaleOrdinal({
    domain: data.map((l) => l.bandStart),
    range: isBoolean ? booleanColors : colors,
  });

  const radius = 48;

  // svg size
  const height = radius * 2;
  const width = radius * 2;

  // group offset
  const top = radius;
  const left = radius;

  const thickness = 12;
  const legendGlyphDiameter = 8;

  const datumToBandLabel = (datum: Datum, isBoolean = false) => {
    if (datum.bandStart === 0) {
      return 'Available';
    } else if (isBoolean) {
      return 'Occupied';
    }
    if (datum.bandStart === datum.bandEnd) {
      return datum.bandStart.toString();
    } else {
      return `${datum.bandStart}-${datum.bandEnd}`;
    }
  };

  const pieSort = (a: Datum, b: Datum) =>
    a.bandStart === 0 ? 1 : b.bandStart === 0 ? -1 : a.bandStart - b.bandStart;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <svg width={width} height={height}>
        <Group top={top} left={left}>
          <Pie
            data={data}
            pieValue={getValue}
            pieSort={pieSort}
            outerRadius={radius}
            innerRadius={radius - thickness}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { bandStart } = arc.data;

                const arcPath = pie.path(arc);
                const arcFill = ordinalScale(bandStart);

                return (
                  <g key={`arc-${bandStart}-${index}`}>
                    <path d={arcPath || undefined} fill={arcFill} />
                  </g>
                );
              });
            }}
          </Pie>
        </Group>
      </svg>

      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 96px;
          gap: 4px;
        `}
      >
        <LegendOrdinal scale={ordinalScale}>
          {(labels) =>
            labels
              .sort((a, b) => (a.datum === 0 ? 1 : b.datum === 0 ? -1 : 0))
              .map((label, i) =>
                label.index === data.length - 1 &&
                data[label.index].raw === 0 ? null : (
                  <LegendItem
                    key={`legend-${i}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      width={legendGlyphDiameter}
                      height={legendGlyphDiameter}
                      transform="translate(0,0.5)"
                    >
                      <circle
                        fill={label.value}
                        r={legendGlyphDiameter / 2}
                        cx={legendGlyphDiameter / 2}
                        cy={legendGlyphDiameter / 2}
                      />
                    </svg>

                    <LegendLabel
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexGrow: 1,
                        height: 16,
                        paddingLeft: 4,
                      }}
                    >
                      <span
                        css={css`
                          font-size: ${dust.TextScale2};
                          font-weight: ${dust.FontWeightMedium};
                          color: ${dust.Gray400};
                          transform: translateY(1px);
                        `}
                      >
                        {datumToBandLabel(data[label.index], isBoolean)}
                      </span>
                      <span
                        css={css`
                          font-size: ${dust.TextScale3};
                          font-weight: ${dust.FontWeightMedium};
                          transform: translateY(0.5px);
                        `}
                      >
                        {getLegendLabel?.(data[label.index])}
                      </span>
                    </LegendLabel>
                  </LegendItem>
                )
              )
          }
        </LegendOrdinal>
      </div>
    </div>
  );
}
