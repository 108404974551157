import * as React from 'react';
import classNames from 'classnames';
import { Icons } from '@densityco/ui';

import styles from './floor-availability-sidebar.module.scss';

import { ascending } from 'lib/sort';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  hoverArea,
  unhoverArea,
} from 'redux/features/availability/availability-slice';
import { Floor } from 'lib/floors';
import { PlanDetail } from 'lib/api';

const FloorAvailabilitySidebar: React.FC<{ floor: Floor; plan: PlanDetail }> =
  ({ floor, plan }) => {
    const dispatch = useAppDispatch();

    const availabilityByAreaId = useAppSelector(
      (state) => state.availability.availabilityByAreaId
    );
    const highlightedArea = useAppSelector(
      (state) => state.availability.highlightedArea
    );

    const availableAreas = React.useMemo(() => {
      // if there isn't anything in the map, we assume its all available
      if (availabilityByAreaId.size === 0) {
        return plan.areas.map((a) => a.id);
      }

      let areaIds: string[] = [];

      availabilityByAreaId.forEach((isAvailable, areaId) => {
        if (isAvailable === true) {
          areaIds.push(areaId);
        } else {
          areaIds = areaIds.filter((id) => id !== areaId);
        }
      });

      return areaIds;
    }, [availabilityByAreaId, plan]);

    return (
      <div className={styles.FloorAvailabilitySidebar}>
        <div className={styles.floorPanel}>
          <div className={styles.floorPanelHeader}>
            <div className={styles.spaceIcon}>
              <Icons.Floor height={18} width={18} color="currentColor" />
            </div>
            <div className={styles.spaceName}>{floor.name}</div>
          </div>
          <div className={styles.floorPanelMeta}>
            <div
              className={classNames([
                styles.floorPanelMetaLabel,
                styles.selector,
              ])}
            >
              Target Capacity
            </div>
            <div className={styles.floorPanelMetaValue}>
              {floor.target_capacity ? floor.target_capacity : '---'}
            </div>
          </div>
        </div>
        {/* .floorPanel */}

        <div className={styles.spaceListPanel}>
          <div className={styles.spaceList}>
            {plan.areas
              .slice()
              .sort((a, b) => ascending(a.name, b.name))
              .map((area) => {
                const isAvailableRaw = availabilityByAreaId.get(area.id);
                // TODO: review; currently assuming it's available if there's no information
                const isAvailable =
                  typeof isAvailableRaw === 'undefined' ? true : isAvailableRaw;
                const isHighlighted = highlightedArea === area.id;

                // TODO(wuweiweiwu): focus is not currently supported
                const isFocused = false;

                return (
                  <div
                    key={area.id}
                    className={classNames(styles.spaceListItem, {
                      [styles.occupied]: !isAvailable,
                      [styles.highlighted]: isHighlighted,
                      [styles.focused]: isFocused,
                    })}
                    onMouseEnter={() => {
                      dispatch(hoverArea(area.id));
                    }}
                    onMouseLeave={() => {
                      dispatch(unhoverArea());
                    }}
                  >
                    <div className={styles.spaceIcon}>
                      <Icons.Space
                        height={18}
                        width={18}
                        color="currentColor"
                      />
                    </div>
                    <div className={styles.spaceName}>{area.name}</div>
                    <div className={styles.spaceStatus}>
                      <div className={styles.spaceStatusIndicator}></div>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* .spaceList */}
          <div
            className={classNames([styles.spaceListSummary], {
              [styles.occupied]: availableAreas.length === 0,
            })}
          >
            <div className={styles.spaceListSummaryTitle}>Availability:</div>
            <div className={styles.spaceStatus}>
              {/* // TODO: Count of available spaces over total spaces */}
              <div className={styles.spaceStatusValue}>
                {availableAreas.length}/{plan.areas.length}
              </div>
              {/* // TODO: If count is 0, make this gray */}
              <div className={styles.spaceStatusIndicator}></div>
            </div>
          </div>
        </div>
        {/* .spaceListPanel */}
      </div>
    );
  };

export default React.memo(FloorAvailabilitySidebar);
