import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { SensorType } from 'components/editor/state';
import { MetricLabel } from 'components/metric-label';
import { ViewportCoordinates } from 'lib/geometry';

const SensorGraphic: React.FunctionComponent<{
  x: number;
  y: number;
  sensorType: SensorType;
  radius: number;
  rotation: number;
  isFocused: boolean;
  isHighlighted: boolean;
  coverageRadius: string;
  centroidColor?: string;
  style?: React.SVGAttributes<SVGGElement>['style'];
  onMouseEnter?: (evt: React.MouseEvent<SVGGElement>) => void;
  onMouseLeave?: (evt: React.MouseEvent<SVGGElement>) => void;
  onMouseDown?: (evt: React.MouseEvent<SVGGElement>) => void;
  blink?: boolean;
  serialNumber?: string | null;
  showSerialNumber?: boolean;
  showSensorCoverage?: boolean;
}> = ({
  x,
  y,
  sensorType,
  radius,
  rotation,
  isFocused = false,
  isHighlighted = false,
  centroidColor,
  style,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onMouseDown = () => {},
  coverageRadius,
  blink = false,
  serialNumber = null,
  showSerialNumber = false,
  showSensorCoverage = true,
}) => {
  const labelAnchor = ViewportCoordinates.create(
    -radius / Math.SQRT2,
    -radius / Math.SQRT2
  );

  const serialNumberAnchor = ViewportCoordinates.create(
    0,
    -radius / Math.SQRT2
  );

  const isEntry = sensorType === 'entry';
  const fillOpacity = 0.12;
  const strokeWidth = isFocused ? 5 : isHighlighted ? 1 : 1;
  const strokeOpacity = isFocused ? 0.4 : isHighlighted ? 1 : 0.4;

  return (
    <g
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      transform={`translate(${x},${y})`}
      data-cy="sensor-graphic"
      data-cy-sensor-type={sensorType}
    >
      {isFocused && (
        <line
          x1={0}
          y1={-radius}
          x2={0}
          y2={radius}
          stroke={colors.blue}
          stroke-dasharray="1,1"
        ></line>
      )}
      {/* rotation group */}
      <g transform={`rotate(${rotation})`}>
        {/* Main sensor coverage area: OA, Entry */}
        {!isEntry && showSensorCoverage && (
          <circle
            r={radius}
            fill={colors.blue}
            fillOpacity={fillOpacity}
            stroke={isFocused || isHighlighted ? colors.blue500 : ''}
            strokeOpacity={strokeOpacity}
            strokeWidth={strokeWidth}
          />
        )}
        {isEntry && (
          <path
            d={`M ${radius} 0
                A ${radius} ${radius} 0 0 0 ${-radius} 0
                Z`}
            fill={colors.purple}
            fillOpacity={fillOpacity}
            stroke={colors.purple}
            strokeOpacity={strokeOpacity}
            strokeWidth={strokeWidth}
          />
        )}

        {/* arrow (oa) */}
        {isFocused && !isEntry ? (
          <g stroke={colors.blue}>
            <line
              x1={0}
              y1={-radius}
              x2={0}
              y2={radius}
              strokeDasharray={'4 2'}
            />
            <line x1={0} y1={-radius + 2} x2={-8} y2={-radius + 10} />
            <line x1={0} y1={-radius + 2} x2={8} y2={-radius + 10} />
          </g>
        ) : null}

        {/* sensor centroid marker */}
        <g filter="url(#filter0_d)" fill="none">
          {isEntry ? (
            <polygon
              points="0,-4 6,7 -6,7"
              fill={centroidColor}
              stroke="white"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          ) : (
            <rect
              x="-5"
              y="-5"
              width="10"
              height="10"
              rx="2"
              fill={centroidColor}
              stroke="white"
              strokeWidth="2"
              strokeLinejoin="round"
            >
              {blink && (
                <animate
                  attributeName="fill"
                  values={`${colors.green300};${colors.green}`}
                  begin="0s"
                  dur="1s"
                  calcMode="discrete"
                  repeatCount="indefinite"
                />
              )}
            </rect>
          )}
        </g>
        <defs>
          {/* filters */}
          <filter
            id="filter0_d"
            x="-5"
            y="-5"
            width="18"
            height="18"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.133333 0 0 0 0 0.164706 0 0 0 0 0.180392 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </g>
      {/* coverage area label (oa) */}
      {isFocused && !isEntry ? (
        <React.Fragment>
          <circle
            r={radius + strokeWidth / 2}
            fillOpacity={0}
            stroke={colors.blue700}
            strokeOpacity={1}
            strokeWidth={1}
          />
          <MetricLabel
            labelText={coverageRadius}
            centerPoint={labelAnchor}
            color={colors.blue}
          />
          {serialNumber && showSerialNumber && (
            <MetricLabel
              labelText={serialNumber}
              centerPoint={serialNumberAnchor}
              color={colors.blue}
            />
          )}
        </React.Fragment>
      ) : null}
    </g>
  );
};

export default SensorGraphic;
