import * as React from 'react';
import { Fragment } from 'react';
import classnames from 'classnames';
import {
  DateRangePicker,
  DateRangePickerContext,
  Icons,
  ListView,
  ListViewColumn,
  PercentageBar,
  PercentageBarContext,
  TimeFilterPicker,
  TimeFilterPickerContext,
} from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';
import moment from 'moment-timezone';
import { DayOfWeek } from '@densityco/lib-common-types';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';

import styles from './floor-analysis.module.scss';
import { HeatmapProvider } from './heatmap-context';
import HeatmapToolbarV2 from './heatmap-toolbar-v2';
import HeatmapToolbar from './heatmap-toolbar';
import HeatmapTimeline from './heatmap-timeline';
import FloorplanViewport from './floorplan-viewport';

import FloorSingleView from 'components/floor-single-view/floor-single-view';
import AppBarFloor from 'components/app-bar-floor/app-bar-floor';
import { Area, PlanDetail } from 'lib/api';
import { Floor } from 'lib/floors';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  clickArea,
  clickTableHeader,
  setDates,
  setFilter,
  toggleTableCollapsed,
  unhoverLabelFilter,
  hoverLabelFilter,
  removeLabelFilter,
  removeSpaceFunctionFilter,
  hoverSpaceFunctionFilter,
  unhoverSpaceFunctionFilter,
  addSpaceFunctionFilter,
  addLabelFilter,
  clearFilters,
  resetHeatmap,
} from 'redux/features/analysis/analysis-slice';
import SpaceCard from 'components/space-card/space-card';
import { Engagement } from 'lib/engagement';
import { asyncFetchAnalyticsThunk } from 'redux/features/analysis/async-fetch-analytics-thunk';
import { asyncFetchAndProcessHeatmapThunk } from 'redux/features/analysis/async-fetch-and-process-heatmap-thunk';
import { asyncFetchLabelsThunk } from 'redux/features/spaces/async-fetch-labels-thunk';
import { sendResizeWindowEventThunk } from 'redux/features/analysis/send-resize-window-event-thunk';
import { computeHoursPerDay, isDayInRange } from 'lib/date-time';
import { NO_VALUE_PLACEHOLDER } from 'lib/text';
import FloorCard from 'components/floor-card/floor-card';
import BulkEditCard from 'components/bulk-edit-card/bulk-edit-card';
import GhostButton from 'components/ghost-button/ghost-button';
import ExportDropdownMenu from './export-dropdown-menu';
import { useDeepMemo } from 'hooks/use-deep-memo';
import FloorplanViewDropdownMenu from './floorplan-view-dropdown-menu';
import SpaceFilterToolbar from 'components/space-filter-toolbar/space-filter-toolbar';
import SpaceFilterChipBar, {
  LabelInfo,
  SpaceFunctionInfo,
} from 'components/space-filter-chip-bar/space-filter-chip-bar';
import {
  NewSpaceFunction,
  spaceFunctionToNewSpaceFunction,
  SPACE_FUNCTION_DISPLAY_NAMES,
} from 'lib/space-functions';
import { truthy } from 'lib/truthy';
import { selectSubspaces } from 'redux/features/spaces/select-subspaces';
import FilteredCard from 'components/filtered-card/filtered-card';
import { labelsSelectors } from 'redux/features/spaces/spaces-slice';

const LABEL_FILTER_COLORS = [
  dust.Pink400,
  dust.Orange400,
  dust.Purple400,
  dust.Teal400,
  dust.Yellow400,

  dust.Pink600,
  dust.Orange600,
  dust.Purple600,
  dust.Teal600,
  dust.Yellow600,

  dust.Pink700,
  dust.Orange700,
  dust.Purple700,
  dust.Teal700,
  dust.Yellow700,
];

const SortIndicator = ({
  sortColumn,
  sortDirection,
  id,
}: {
  sortColumn: string | null;
  sortDirection: string;
  id: string;
}) => {
  if (sortColumn === id) {
    return (
      <div
        css={css`
          height: 16px;
          transform: translateY(-1px);
        `}
      >
        {sortDirection === 'asc' ? (
          <Icons.ChevronUp width={20} height={20} />
        ) : sortDirection === 'desc' ? (
          <Icons.ChevronDown width={20} height={20} />
        ) : (
          ''
        )}
      </div>
    );
  }
  return null;
};

const FloorAnalysis: React.FC<{
  planDetail: PlanDetail;
  floor: Floor;
  planImage: HTMLImageElement;
}> = ({ planDetail, floor, planImage }) => {
  const dispatch = useAppDispatch();

  const timeZone = floor.time_zone;

  const dates = useAppSelector((state) => state.analysis.dates);
  const filterDays = useAppSelector((state) => state.analysis.filterDays);
  const filterStart = useAppSelector((state) => state.analysis.filterStart);
  const filterEnd = useAppSelector((state) => state.analysis.filterEnd);
  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );
  const tableCollapsed = useAppSelector(
    (state) => state.analysis.tableCollapsed
  );
  const selectedAreas = useAppSelector((state) => state.analysis.selectedAreas);
  const sortDirection = useAppSelector((state) => state.analysis.sortDirection);
  const sortColumn = useAppSelector((state) => state.analysis.sortColumn);

  const analyticsQuery = useAppSelector(
    (state) => state.analysis.analyticsQuery
  );

  const labelFilters = useAppSelector((state) => state.analysis.labelFilters);
  const spaceFunctionFilters = useAppSelector(
    (state) => state.analysis.spaceFunctionFilters
  );

  const labels = useAppSelector(labelsSelectors.selectAll);

  const subspaces = useAppSelector((state) =>
    selectSubspaces(state, { spaceId: floor.id })
  );

  const planId = planDetail?.id;

  const areFiltersActive = Boolean(
    labelFilters.length + spaceFunctionFilters.length
  );

  // NOTE: we do deep memo here since we want to rerun the query when
  // the list of ids change. not if the name or capacity changes
  const areaIds = useDeepMemo(
    React.useMemo(
      () => planDetail?.areas.map((a) => a.id) || [],
      [planDetail?.areas]
    )
  );

  const isRelease1On = useTreatment(SPLITS.RELEASE_1);

  React.useEffect(
    function initializeDates() {
      // already set
      if (dates) {
        return;
      }

      // Initialize the datepicker to the "last complete day in filter"
      const today = moment.tz(timeZone).startOf('day');
      const startDate = today.clone().startOf('day').subtract(1, 'day');
      const endDate = today.clone().endOf('day').subtract(1, 'day');

      if (filterDays.length) {
        while (!filterDays.includes(startDate.format('dddd') as DayOfWeek)) {
          startDate.subtract(1, 'day');
          endDate.subtract(1, 'day');
        }
      }

      dispatch(
        setDates({
          timeZone,
          value: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        })
      );
    },
    [dates, filterDays, dispatch, timeZone]
  );

  React.useEffect(() => {
    if (!dates) {
      return;
    }

    if (!planId) {
      return;
    }

    // easy way to cancel running requests with createAsyncThunk
    // no longer have to wire up cancel tokens manually
    // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-while-running
    const promise = dispatch(
      asyncFetchAnalyticsThunk({
        planId,
        areaIds,
        timeZone: timeZone,
        startTime: dates[0],
        endTime: dates[1],
        filterStart: filterStart,
        filterEnd: filterEnd,
        filterDays: filterDays,
      })
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    dates,
    filterStart,
    filterEnd,
    filterDays,
    timeZone,
    planId,
    areaIds,
  ]);

  React.useEffect(() => {
    if (!heatmapEnabled) {
      return;
    }

    if (!dates) {
      return;
    }

    if (!planId) {
      return;
    }

    const promise = dispatch(
      asyncFetchAndProcessHeatmapThunk({
        planId,
        timeZone: timeZone,
        startTime: dates[0],
        endTime: dates[1],
        filterStart: filterStart,
        filterEnd: filterEnd,
        filterDays: filterDays,
      })
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    heatmapEnabled,
    dates,
    filterStart,
    filterEnd,
    filterDays,
    timeZone,
    planId,
  ]);

  React.useEffect(() => {
    if (!isRelease1On) {
      return;
    }

    const promise = dispatch(asyncFetchLabelsThunk());

    return () => {
      promise.abort();
    };
  }, [dispatch, isRelease1On]);

  React.useEffect(() => {
    const cleanup = dispatch(sendResizeWindowEventThunk());

    return () => {
      cleanup();
    };
  }, [dispatch, tableCollapsed, isRelease1On]);

  React.useEffect(() => {
    return () => {
      dispatch(resetHeatmap());
    };
  }, [dispatch]);

  const selectedOccupancy = React.useMemo(
    () =>
      analyticsQuery.data?.find((item) => item.area_id === selectedAreas[0]),
    [analyticsQuery.data, selectedAreas]
  );

  const today = React.useMemo(
    () => moment.tz(timeZone).startOf('day'),
    [timeZone]
  );

  const startTime = React.useMemo(
    () => today.clone().hour(filterStart.hour).minute(filterStart.minute),
    [filterStart.hour, filterStart.minute, today]
  );

  const endTime = React.useMemo(
    () => today.clone().hour(filterEnd.hour).minute(filterEnd.minute),
    [filterEnd.hour, filterEnd.minute, today]
  );

  const sortedAreas = React.useMemo(() => {
    if (!planDetail) {
      return [];
    }

    return planDetail.areas.slice().sort((a: Area, b: Area) => {
      const aOccupancy = analyticsQuery.data?.find(
        (i) => i.area_id === a.id
      ) || {
        area_id: a.id,
        occupied_percent: 0,
        occupied_total_ms: 0,
      };

      const bOccupancy = analyticsQuery.data?.find(
        (i) => i.area_id === b.id
      ) || {
        area_id: b.id,
        occupied_percent: 0,
        occupied_total_ms: 0,
      };

      const sortMultiplier = sortDirection === 'desc' ? -1 : 1;

      let result = 0;

      if (!sortColumn) {
        return result;
      }

      if (sortColumn !== 'name') {
        result =
          aOccupancy[sortColumn] > bOccupancy[sortColumn]
            ? 1
            : bOccupancy[sortColumn] > aOccupancy[sortColumn]
            ? -1
            : 0;
      } else {
        result = a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      }
      return result * sortMultiplier;
    });
  }, [analyticsQuery.data, planDetail, sortColumn, sortDirection]);

  const hoursPerDayByArea = React.useMemo(() => {
    if (!planDetail) {
      return {};
    }

    return Object.fromEntries(
      planDetail.areas.map((area) => {
        const occupancy = analyticsQuery.data?.find(
          (item) => item.area_id === area.id
        );

        const hours = moment
          .duration(occupancy?.occupied_total_ms || 0, 'milliseconds')
          .asHours();

        const hoursPerDay = computeHoursPerDay(
          hours,
          dates || [moment.tz(timeZone), moment.tz(timeZone)],
          filterDays,
          timeZone
        );

        return [area.id, hoursPerDay];
      })
    );
  }, [planDetail, analyticsQuery.data, dates, timeZone, filterDays]);

  const hoursPerDayTextByArea = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(hoursPerDayByArea).map((item) => [
          item[0],
          item[1] > 0 && item[1] < 0.5 ? '< 1' : Math.round(item[1]),
        ])
      ),
    [hoursPerDayByArea]
  );

  const occupancyPercentByArea = React.useMemo(() => {
    if (!planDetail) {
      return {};
    }

    return Object.fromEntries(
      planDetail.areas.map((area) => {
        const occupancy = analyticsQuery.data?.find(
          (item) => item.area_id === area.id
        );
        return [area.id, occupancy?.occupied_percent];
      })
    );
  }, [analyticsQuery.data, planDetail]);

  // TODO: Backend should return more sensible "null" results if the filter
  // excludes ALL days in the selected range. For now we calculate here
  const hasDayInRange = React.useMemo(
    () =>
      isDayInRange(
        dates || [moment.tz(timeZone), moment.tz(timeZone)],
        filterDays,
        timeZone
      ),
    [dates, filterDays, timeZone]
  );

  const showData = hasDayInRange && !!analyticsQuery.data?.length;

  const allLabelInfos = React.useMemo(() => {
    return (
      labels
        .map((label) => {
          const labelInfo: Omit<LabelInfo, 'hoverColor'> = {
            id: label.id,
            label: label.name,
            quantity: subspaces.filter((s) =>
              s.labels.some((l) => l.id === label.id)
            ).length,
          };

          return labelInfo;
        })
        .filter(truthy) || []
    );
  }, [labels, subspaces]);

  const allSpaceFunctionInfo = React.useMemo(() => {
    const allNewSpaceFunctions = Object.keys(
      SPACE_FUNCTION_DISPLAY_NAMES
    ) as NewSpaceFunction[];

    return allNewSpaceFunctions.map((newSpaceFunction) => {
      const spaceFunctionInfo: SpaceFunctionInfo = {
        id: newSpaceFunction,
        label: SPACE_FUNCTION_DISPLAY_NAMES[newSpaceFunction],
        quantity: subspaces.filter((s) =>
          s.function
            ? spaceFunctionToNewSpaceFunction(s.function) === newSpaceFunction
            : false
        ).length,
        hoverColor: dust.Blue400,
      };

      return spaceFunctionInfo;
    });
  }, [subspaces]);

  const filteredLabelInfos = React.useMemo(() => {
    return allLabelInfos
      .filter((info) => labelFilters.some((id) => id === info.id))
      .map((info, i) => {
        const labelInfo: LabelInfo = {
          ...info,
          hoverColor: LABEL_FILTER_COLORS[i % LABEL_FILTER_COLORS.length],
        };

        return labelInfo;
      });
  }, [allLabelInfos, labelFilters]);

  const filteredSpaceFunctionInfos = React.useMemo(() => {
    return allSpaceFunctionInfo.filter((info) =>
      spaceFunctionFilters.some((id) => id === info.id)
    );
  }, [allSpaceFunctionInfo, spaceFunctionFilters]);

  const filterToolbarLabelOptions = React.useMemo(() => {
    return allLabelInfos
      .filter((info) => !filteredLabelInfos.some((inf) => inf.id === info.id))
      .map((info) => ({
        label: info.label,
        id: info.id,
        quantity: info.quantity,
      }));
  }, [allLabelInfos, filteredLabelInfos]);

  const filterToolbarSpaceFunctionOptions = React.useMemo(() => {
    return allSpaceFunctionInfo
      .filter(
        (info) => !filteredSpaceFunctionInfos.some((inf) => inf.id === info.id)
      )
      .map((info) => ({
        spaceFunction: info.id,
        id: info.id,
        quantity: info.quantity,
      }));
  }, [allSpaceFunctionInfo, filteredSpaceFunctionInfos]);

  const appBar = <AppBarFloor />;

  if (!planDetail) {
    return appBar;
  }

  if (!floor) {
    return appBar;
  }

  return (
    <Fragment>
      {appBar}
      <FloorSingleView>
        <div className={styles.FloorAnalysisPage}>
          <div
            css={css`
              display: flex;
              align-items: center;
              z-index: 50;
              border-bottom: 1px solid ${dust.Gray200};
            `}
          >
            <div className={styles.FloorAnalysisTimeControls}>
              <DateRangePickerContext.Provider value="TIME_RANGE_CONTROL_BAR">
                <DateRangePicker
                  startDate={
                    dates
                      ? moment.tz(dates[0], timeZone).format('YYYY-MM-DD')
                      : moment.tz(timeZone)
                  }
                  endDate={
                    dates
                      ? moment.tz(dates[1], timeZone).format('YYYY-MM-DD')
                      : moment.tz(timeZone)
                  }
                  isOutsideRange={(date) =>
                    moment.tz(date, timeZone).diff(moment.tz(timeZone)) > 0
                  }
                  onChange={(value) => {
                    dispatch(
                      setDates({
                        timeZone,
                        value,
                      })
                    );
                  }}
                />
              </DateRangePickerContext.Provider>

              <div className={styles.FloorAnalysisTimeFilterPicker}>
                <TimeFilterPickerContext.Provider value="TIME_RANGE_CONTROL_BAR">
                  <TimeFilterPicker
                    startTime={startTime}
                    endTime={endTime}
                    daysOfWeek={filterDays}
                    setStartTime={(start) => {
                      dispatch(
                        setFilter({
                          filterStart: {
                            hour: start.hour(),
                            minute: start.minute(),
                            second: 0,
                            millisecond: 0,
                          },
                        })
                      );
                    }}
                    setEndTime={(end) => {
                      dispatch(
                        setFilter({
                          filterEnd: {
                            hour: end.hour(),
                            minute: end.minute(),
                            second: 0,
                            millisecond: 0,
                          },
                        })
                      );
                    }}
                    setDaysOfWeek={(days) => {
                      dispatch(
                        setFilter({
                          filterDays: days,
                        })
                      );
                    }}
                  />
                </TimeFilterPickerContext.Provider>
              </div>
            </div>

            {isRelease1On ? <HeatmapToolbarV2 /> : null}

            <div
              css={css`
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 16px;
                gap: 4px;
              `}
            >
              {isRelease1On ? (
                <SpaceFilterToolbar
                  isActive={Boolean(
                    labelFilters.length + spaceFunctionFilters.length
                  )}
                  labelOptions={filterToolbarLabelOptions}
                  functionOptions={filterToolbarSpaceFunctionOptions}
                  onSelectFunction={(spaceFunction) => {
                    dispatch(addSpaceFunctionFilter(spaceFunction));
                  }}
                  onSelectLabel={(id) => {
                    dispatch(addLabelFilter(id));
                  }}
                />
              ) : null}

              <ExportDropdownMenu plan={planDetail} floor={floor} />

              <FloorplanViewDropdownMenu />

              {/* TODO(wuweiweiwu): this should probably need to live in the app bar somewhere */}
              <GhostButton
                aria-label="Feedback"
                css={css`
                  width: 32px;
                  height: 32px;
                  color: ${dust.Gray700};
                `}
                onClick={() => Engagement.show()}
              >
                <Icons.Chat width={20} height={20} color="currentColor" />
              </GhostButton>
            </div>
          </div>

          <div
            css={css`
              position: relative;
              flex-grow: 1;
              overflow: hidden;
            `}
          >
            {/* container for the viewport */}
            <div
              css={css`
                position: absolute;
                top: 0px;
                left: 0px;
                height: 100%;
                width: 100%;
              `}
            >
              <FloorplanViewport plan={planDetail} planImage={planImage} />

              {!isRelease1On ? (
                <div
                  css={css`
                    position: absolute;
                    padding: 8px;
                    top: 0px;
                    left: 0px;
                  `}
                >
                  <HeatmapToolbar />
                </div>
              ) : null}

              {heatmapEnabled ? <HeatmapTimeline /> : null}
            </div>

            {/* container for the cards */}
            <div
              css={css`
                position: absolute;
                top: 0px;
                left: 0px;
                height: 100%;
                width: 100%;
                pointer-events: none;
              `}
            >
              {isRelease1On ? (
                <div
                  css={css`
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    // TODO(wuweiweiwu): kind of annoying that i have to set the height explicitly in the parent
                    height: calc(100% - 16px);
                    width: calc(100% - 16px);
                    z-index: 40;
                    display: flex;
                    align-items: flex-start;
                    gap: 8px;
                  `}
                >
                  {selectedAreas.length === 1 ? (
                    <SpaceCard
                      areaId={selectedAreas[0]}
                      floor={floor}
                      planDetail={planDetail}
                    />
                  ) : selectedAreas.length > 1 ? (
                    <BulkEditCard
                      areaIds={selectedAreas}
                      planDetail={planDetail}
                      floor={floor}
                    />
                  ) : areFiltersActive ? (
                    <FilteredCard floor={floor} />
                  ) : (
                    <FloorCard floor={floor} />
                  )}

                  <div
                    css={css`
                      pointer-events: all;
                    `}
                  >
                    <SpaceFilterChipBar
                      labels={filteredLabelInfos}
                      spaceFunctions={filteredSpaceFunctionInfos}
                      onHighlightSpaceFunction={(v, c) => {
                        dispatch(
                          hoverSpaceFunctionFilter({ value: v, color: c })
                        );
                      }}
                      onUnhighlightSpaceFunction={(v) => {
                        dispatch(unhoverSpaceFunctionFilter());
                      }}
                      onDeleteSpaceFunction={(v) => {
                        dispatch(removeSpaceFunctionFilter(v));
                      }}
                      onHighlightLabel={(v, c) => {
                        dispatch(hoverLabelFilter({ value: v, color: c }));
                      }}
                      onUnhighlightLabel={(v) => {
                        dispatch(unhoverLabelFilter());
                      }}
                      onDeleteLabel={(v) => {
                        dispatch(removeLabelFilter(v));
                      }}
                      onClear={() => {
                        dispatch(clearFilters());
                      }}
                    />
                  </div>
                </div>
              ) : null}

              {/* FloorAnalysisSelectedPanel */}
              {/* we only show this if there is 1 selected area */}
              {selectedAreas.length === 1 && !isRelease1On ? (
                <div
                  className={styles.FloorAnalysisSelectedPanel}
                  css={css`
                    transform: translateY(-48px);
                  `}
                >
                  <div className={styles.FloorAnalysisSelectedHeader}>
                    <div className={styles.FloorAnalysisSelectedHeaderIcon}>
                      <Icons.Space
                        height={18}
                        width={18}
                        color="currentColor"
                      />
                    </div>

                    {
                      planDetail.areas.find((x) => x.id === selectedAreas[0])
                        ?.name
                    }
                  </div>

                  {/* .FloorAnalysisSelectedAreaHeader */}

                  <div className={styles.FloorAnalysisSelectedMeta}>
                    <div className={styles.FloorAnalysisSelectedMetaLabel}>
                      % Time Occupied:
                    </div>

                    {showData ? (
                      <div className={styles.FloorAnalysisSelectedMetaBarChart}>
                        {selectedOccupancy && (
                          <PercentageBarContext.Provider value="LIST_VIEW">
                            <PercentageBar
                              percentage={selectedOccupancy.occupied_percent}
                            />
                          </PercentageBarContext.Provider>
                        )}
                      </div>
                    ) : (
                      NO_VALUE_PLACEHOLDER
                    )}
                  </div>

                  {/* .FloorAnalysisSelectedMeta */}

                  <div className={styles.FloorAnalysisSelectedMeta}>
                    <div className={styles.FloorAnalysisSelectedMetaLabel}>
                      Avg. Time Occupied:
                    </div>
                    <div className={styles.FloorAnalysisSelectedMetaValue}>
                      {showData
                        ? `${hoursPerDayTextByArea[selectedAreas[0]]} hr / day`
                        : NO_VALUE_PLACEHOLDER}
                    </div>
                  </div>

                  {/* .FloorAnalysisSelectedMeta */}
                </div>
              ) : null}
              {/* .FloorAnalysisSelectedPanel */}
            </div>
          </div>

          {isRelease1On ? null : (
            <div
              className={classnames(styles.FloorAnalysisTable, {
                [styles.FloorAnalysisTableCollapsed]: tableCollapsed,
              })}
            >
              <div
                className={styles.FloorAnalysisTableCollapser}
                onClick={() => dispatch(toggleTableCollapsed())}
              >
                {tableCollapsed ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
              </div>
              <div className={styles.FloorAnalysisTableHeaderRow}>
                <div
                  className={styles.FloorAnalysisTableHeader}
                  css={css`
                    flex-grow: 1;
                  `}
                  onClick={() => dispatch(clickTableHeader('name'))}
                >
                  {/* FIXME: layout should live in stylesheets */}
                  Space&nbsp;
                  <SortIndicator
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    id="name"
                  />
                </div>
                <div
                  className={styles.FloorAnalysisTableHeader}
                  css={css`
                    width: 240px;
                  `}
                  onClick={() => dispatch(clickTableHeader('occupied_percent'))}
                >
                  % Time Occupied&nbsp;
                  <SortIndicator
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    id="occupied_percent"
                  />
                </div>
                <div
                  className={styles.FloorAnalysisTableHeader}
                  css={css`
                    width: 240px;
                  `}
                  onClick={() =>
                    dispatch(clickTableHeader('occupied_total_ms'))
                  }
                >
                  Avg. Time Occupied&nbsp;
                  <SortIndicator
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    id="occupied_total_ms"
                  />
                </div>
              </div>

              <div className={styles.FloorAnalysisTableBody}>
                <ListView
                  data={sortedAreas}
                  showHeaders={false}
                  rowHeight={40}
                  padOuterColumns
                  onClickRow={(item) => {
                    dispatch(clickArea(item.id));
                  }}
                  sort={[
                    {
                      column: sortColumn,
                      direction: sortDirection,
                    },
                  ]}
                >
                  <ListViewColumn
                    id="name"
                    title="Space"
                    template={(area) => {
                      const selected = selectedAreas.some(
                        (id) => id === area.id
                      );

                      const name = area?.name;
                      return (
                        <div
                          className={styles.FloorAnalysisTableLabel}
                          css={css`
                            color: ${selected ? colors.blue : colors.gray800};
                          `}
                        >
                          <div className={styles.FloorAnalysisListTableIcon}>
                            <Icons.Space
                              height={18}
                              width={18}
                              color="currentColor"
                            />
                          </div>
                          {name}
                        </div>
                      );
                    }}
                  />
                  <ListViewColumn
                    id="occupied_percent"
                    title="% Time Occupied"
                    width={240}
                    template={(area) => {
                      if (!showData) {
                        return NO_VALUE_PLACEHOLDER;
                      }

                      const selected = selectedAreas.some(
                        (id) => id === area.id
                      );

                      const percentage = occupancyPercentByArea[area.id] || 0;

                      return (
                        <div
                          className={classnames(
                            styles.FloorAnalysisOccupancyBar,
                            {
                              [styles.selected]: selected,
                            }
                          )}
                          css={css`
                            width: 800px;
                            color: ${selected ? colors.blue : colors.gray800};
                          `}
                        >
                          <PercentageBarContext.Provider value="LIST_VIEW">
                            <div className={styles.FloorAnalysisTableLabel}>
                              <PercentageBar percentage={percentage} />
                            </div>
                          </PercentageBarContext.Provider>
                        </div>
                      );
                    }}
                  />
                  <ListViewColumn
                    id="occupied_total_ms"
                    title="Avg. Time Occupied"
                    width={240}
                    template={(area) => {
                      if (!showData) {
                        return NO_VALUE_PLACEHOLDER;
                      }

                      const selected = selectedAreas.some(
                        (id) => id === area.id
                      );

                      return (
                        <span
                          css={css`
                            font-size: 14px;
                            font-weight: 500;
                            color: ${selected ? colors.blue : colors.gray800};
                          `}
                        >
                          {`${hoursPerDayTextByArea[area?.id]} hr / day`}
                        </span>
                      );
                    }}
                  />
                </ListView>
              </div>
            </div>
          )}
        </div>
      </FloorSingleView>
    </Fragment>
  );
};

// we want to memo the first child of a context to optimize rendering
const MemoedFloorAnalysis = React.memo(FloorAnalysis);

const FloorAnalysisWithProviders: React.FC<{
  planDetail: PlanDetail;
  floor: Floor;
  planImage: HTMLImageElement;
}> = ({ planDetail, floor, planImage }) => {
  return (
    <HeatmapProvider plan={planDetail} floor={floor}>
      <MemoedFloorAnalysis
        planDetail={planDetail}
        floor={floor}
        planImage={planImage}
      />
    </HeatmapProvider>
  );
};

export default FloorAnalysisWithProviders;
