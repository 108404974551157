import * as React from 'react';
import { css } from '@emotion/react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import {
  AreaPolygonal,
  Area,
  AreaLabel,
} from './floorplan-viewport-components';
import { useHeatmap } from './heatmap-context';

import { useAppDispatch, useAppSelector } from 'redux/store';
import FloorplanImageView from 'components/floorplan-image-view/floorplan-image-view';
import { FloorplanCoordinates, ViewportCoordinates } from 'lib/geometry';
import {
  clickArea,
  clickBackground,
  hoverArea,
  mouseDown,
  mouseMove,
  mouseUp,
  selectAreas,
  setMultiSelect,
  unhoverArea,
} from 'redux/features/analysis/analysis-slice';
import { useAutoSize } from 'hooks/use-auto-size';
import {
  Keybindings,
  subscribeToKeybindings,
  unsubscribeFromKeybindings,
} from 'components/editor/keybindings';
import { areaToSpace, isAreaRectilinear } from 'lib/area';
import { doesRectangleOverlapSpace } from 'lib/algorithm';
import HeatmapContours from './heatmap-contours';
import { satisfyFilters } from 'lib/filter';
import { PlanDetail } from 'lib/api';
import { getFloorplanFromPlan } from 'lib/floorplan';
import { useSelector } from 'react-redux';
import { spacesSelectors } from 'redux/features/spaces/spaces-slice';
import { useViewportControls } from 'hooks/use-viewport-controls';

type FloorplanViewport = {
  plan: PlanDetail;
  planImage: HTMLImageElement;
};

const FloorplanViewport: React.FC<FloorplanViewport> = (props) => {
  const dispatch = useAppDispatch();

  const selectedAreas = useAppSelector((state) => state.analysis.selectedAreas);
  const hoveredAreas = useAppSelector((state) => state.analysis.hoveredAreas);
  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );
  const showSpaceName = useAppSelector((state) => state.analysis.showSpaceName);

  const spaceFunctionFilters = useAppSelector(
    (state) => state.analysis.spaceFunctionFilters
  );
  const labelFilters = useAppSelector((state) => state.analysis.labelFilters);

  const hoveredSpaceFunctionFilter = useAppSelector(
    (state) => state.analysis.hoveredSpaceFunctionFilter
  );
  const hoveredLabelFilter = useAppSelector(
    (state) => state.analysis.hoveredLabelFilter
  );

  const spacesDict = useSelector(spacesSelectors.selectEntities);

  const areFiltersActive = Boolean(
    spaceFunctionFilters.length + labelFilters.length
  );

  const isAnyFilterHovered = Boolean(
    hoveredSpaceFunctionFilter || hoveredLabelFilter
  );

  const dragStart = useAppSelector((state) => state.analysis.dragStart);
  const dragCurrent = useAppSelector((state) => state.analysis.dragCurrent);
  const mouseState = useAppSelector((state) => state.analysis.mouseState);

  const { onViewportWidthChange } = useHeatmap();

  const { resize, zoomToFit, viewport, viewportElementRef } =
    useViewportControls<HTMLDivElement>();
  const viewportSize = useAutoSize(viewportElementRef);

  const dragSelectionRectangle = React.useMemo(() => {
    if (!dragStart || !dragCurrent) {
      return;
    }

    const startX = dragStart.x - viewportSize.left;
    const currentX = dragCurrent.x - viewportSize.left;

    const startY = dragStart.y - viewportSize.top;
    const currentY = dragCurrent.y - viewportSize.top;

    return {
      topLeft: ViewportCoordinates.create(
        Math.min(startX, currentX),
        Math.min(startY, currentY)
      ),
      bottomRight: ViewportCoordinates.create(
        Math.max(startX, currentX),
        Math.max(startY, currentY)
      ),
    };
  }, [dragCurrent, dragStart, viewportSize.left, viewportSize.top]);

  const viewportAdjusted = React.useMemo(() => {
    return {
      // In a world, where code assumes everything uses content-box sizing...
      // This Christmas, one programmer will subtract 2, from the viewport
      ...viewport,
      top: viewport.top || 0,
      left: viewport.left || 0,
      zoom: viewport.zoom || 1,
      height: viewport.height - 2,
      width: viewport.width - 2,
    };
  }, [viewport]);

  const areasAsSpaces = React.useMemo(() => {
    return props.plan.areas
      .filter((area) => {
        const spaceId = area.space_id;
        if (!spaceId) {
          return false;
        }

        const space = spacesDict[spaceId];
        if (!space) {
          return false;
        }

        // make sure to filter out spaces that don't satisfy the filter
        if (
          areFiltersActive &&
          !satisfyFilters(spaceFunctionFilters, labelFilters, space)
        ) {
          return false;
        }

        return true;
      })
      .map(areaToSpace);
  }, [
    areFiltersActive,
    labelFilters,
    props.plan.areas,
    spaceFunctionFilters,
    spacesDict,
  ]);

  const floorplan = React.useMemo(() => {
    return getFloorplanFromPlan(props.plan);
  }, [props.plan]);

  React.useEffect(() => {
    onViewportWidthChange(viewport.width);
  }, [onViewportWidthChange, viewport.width]);

  React.useEffect(() => {
    if (!dragSelectionRectangle) {
      return;
    }

    const floorplan = getFloorplanFromPlan(props.plan);

    const rectangle = {
      topLeft: ViewportCoordinates.toFloorplanCoordinates(
        dragSelectionRectangle.topLeft,
        viewportAdjusted,
        floorplan
      ),
      bottomRight: ViewportCoordinates.toFloorplanCoordinates(
        dragSelectionRectangle.bottomRight,
        viewportAdjusted,
        floorplan
      ),
    };

    const areaAsSpaceOverlapped = areasAsSpaces.filter((areaAsSpace) => {
      return doesRectangleOverlapSpace(rectangle, areaAsSpace);
    });

    dispatch(selectAreas(areaAsSpaceOverlapped.map((a) => a.id)));
  }, [
    areasAsSpaces,
    dispatch,
    dragSelectionRectangle,
    mouseState,
    props.plan,
    viewportAdjusted,
  ]);

  const handleAreaClick = React.useCallback(
    (id: string, event: React.MouseEvent | null) => {
      event?.stopPropagation();

      dispatch(clickArea(id));
    },
    [dispatch]
  );

  React.useEffect(() => {
    const { width, height } = viewportSize;

    resize(width, height);

    zoomToFit(props.planImage);
  }, [props.planImage, resize, viewportSize, zoomToFit]);

  React.useEffect(() => {
    const keybindings: Keybindings = [
      {
        sequence: 'shift',
        keydown: () => {
          dispatch(setMultiSelect(true));
        },
        keyup: () => {
          dispatch(setMultiSelect(false));
        },
      },
      {
        sequence: 'esc',
        keypress: () => {
          dispatch(clickBackground());
        },
      },
    ];

    subscribeToKeybindings(keybindings);

    return () => {
      unsubscribeFromKeybindings(keybindings);
    };
  }, [dispatch]);

  const renderableAreas = props.plan.areas.flatMap((area) => {
    const isSelected = selectedAreas.some((id) => id === area.id);
    const isHovered = hoveredAreas.some((id) => id === area.id);

    if (!area.space_id) {
      return [];
    }

    const space = spacesDict[area.space_id];
    if (!space) {
      return [];
    }

    const doesSpaceSatisfyFilters = satisfyFilters(
      spaceFunctionFilters,
      labelFilters,
      space
    );

    // if doesn't satisfy filter, don't render the area
    if (areFiltersActive && !doesSpaceSatisfyFilters) {
      return [];
    }

    const satisfyCurrentHoveredFilter = Boolean(
      (hoveredSpaceFunctionFilter &&
        satisfyFilters([hoveredSpaceFunctionFilter.value], [], space)) ||
        (hoveredLabelFilter &&
          satisfyFilters([], [hoveredLabelFilter.value], space))
    );

    const currentHoveredFilterColor =
      hoveredSpaceFunctionFilter?.color || hoveredLabelFilter?.color;

    const isActive = isSelected || isHovered;

    const isMuted = heatmapEnabled;

    const backgroundColor = satisfyCurrentHoveredFilter
      ? currentHoveredFilterColor
      : isActive
      ? dust.Blue400
      : isMuted
      ? dust.Gray400
      : dust.Blue400;

    const commonProps = {
      id: area.id,
      name: area.name,
      backgroundColor,
      isMuted,
      isSelected,
      isHovered,
      isNameVisible: showSpaceName,
      isAnyFilterHovered,
      satisfyCurrentHoveredFilter,
      currentHoveredFilterColor,
      onOver: () => {
        dispatch(hoverArea(area.id));
      },
      onOut: () => {
        dispatch(unhoverArea(area.id));
      },
      onSelect: (e: React.MouseEvent<SVGPathElement | SVGRectElement>) => {
        handleAreaClick(area.id, e);
      },
    };

    return [{ space, area, commonProps }];
  });

  return (
    <div
      ref={viewportElementRef}
      css={css`
        position: relative;
        height: 100%;
        width: 100%;
      `}
      // TODO(wuweiweiwu): pull into useMouseDrag hook or abstraction
      onMouseDown={(e) => {
        dispatch(mouseDown({ x: e.clientX, y: e.clientY }));
      }}
      onMouseUp={(e) => {
        // TODO(wuweiweiwu): this is a super hack way to get drag events
        // to work nicely with click events to support clicking the background
        // this schedules the mouseup event to fire in the next event loop
        // after the onClick handler so that mouseState isn't updated
        setTimeout(() => {
          dispatch(mouseUp());
        });
      }}
      onMouseMove={(e) => {
        dispatch(mouseMove({ x: e.clientX, y: e.clientY }));
      }}
      onClick={(e) => {
        // we only want to fire the click background event when the user clicks the background
        // not when ending a drag
        if (mouseState !== 'dragging') {
          dispatch(clickBackground());
        }
      }}
    >
      <FloorplanImageView
        image={props.planImage}
        opacity={0.5}
        viewport={viewport}
      />

      <svg
        style={{
          position: 'absolute',
          width: viewport.width,
          height: viewport.height,
        }}
        width={viewport.width}
        height={viewport.height}
        viewBox={`0 0 ${viewport.width} ${viewport.height}`}
      >
        {/* drag selection */}
        {dragSelectionRectangle ? (
          <rect
            strokeWidth={2}
            stroke={dust.Blue400}
            strokeDasharray={'10 10'}
            x={dragSelectionRectangle.topLeft.x}
            y={dragSelectionRectangle.topLeft.y}
            width={
              dragSelectionRectangle.bottomRight.x -
              dragSelectionRectangle.topLeft.x
            }
            height={
              dragSelectionRectangle.bottomRight.y -
              dragSelectionRectangle.topLeft.y
            }
            rx={4}
            fill={dust.Gray400}
            fillOpacity={0.2}
          />
        ) : null}

        {/* areas shapes */}
        {renderableAreas.map(({ space, area, commonProps }) => {
          if (area.shape === 'circle') {
            const planCoords = FloorplanCoordinates.create(
              area.circle_centroid_x_meters,
              area.circle_centroid_y_meters
            );

            const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
              planCoords,
              floorplan,
              viewportAdjusted
            );

            const viewportRadius =
              area.circle_radius_meters *
              props.plan.image_pixels_per_meter *
              viewportAdjusted.zoom;

            return (
              <Area
                key={area.id}
                top={viewportCoords.y - viewportRadius}
                left={viewportCoords.x - viewportRadius}
                width={viewportRadius * 2}
                height={viewportRadius * 2}
                isCircular
                {...commonProps}
              />
            );
          } else if (area.polygon_verticies && isAreaRectilinear(area)) {
            const topLeft = FloorplanCoordinates.toViewportCoordinates(
              FloorplanCoordinates.create(
                area.polygon_verticies[0].x_from_origin_meters,
                area.polygon_verticies[0].y_from_origin_meters
              ),
              floorplan,
              viewportAdjusted
            );

            const bottomRight = FloorplanCoordinates.toViewportCoordinates(
              FloorplanCoordinates.create(
                area.polygon_verticies[2].x_from_origin_meters,
                area.polygon_verticies[2].y_from_origin_meters
              ),
              floorplan,
              viewportAdjusted
            );

            return (
              <Area
                key={area.id}
                top={topLeft.y}
                left={topLeft.x}
                width={bottomRight.x - topLeft.x}
                height={bottomRight.y - topLeft.y}
                {...commonProps}
              />
            );
          } else if (area.polygon_verticies) {
            const vertices = area.polygon_verticies.map((v) =>
              FloorplanCoordinates.create(
                v.x_from_origin_meters,
                v.y_from_origin_meters
              )
            );
            const verticesViewport = vertices.map((v) =>
              FloorplanCoordinates.toViewportCoordinates(
                v,
                floorplan,
                viewportAdjusted
              )
            );

            return (
              <AreaPolygonal
                key={area.id}
                vertices={verticesViewport}
                {...commonProps}
              />
            );
          } else {
            return null;
          }
        })}
      </svg>

      {/* areas labels */}
      {showSpaceName
        ? renderableAreas.map(({ space, area, commonProps }) => {
            if (area.shape === 'circle') {
              const planCoords = FloorplanCoordinates.create(
                area.circle_centroid_x_meters,
                area.circle_centroid_y_meters
              );

              const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
                planCoords,
                floorplan,
                viewportAdjusted
              );

              const viewportRadius =
                area.circle_radius_meters *
                props.plan.image_pixels_per_meter *
                viewportAdjusted.zoom;

              const labelViewportCoordinates = ViewportCoordinates.create(
                viewportCoords.x,
                viewportCoords.y - viewportRadius
              );

              return (
                <AreaLabel
                  position={labelViewportCoordinates}
                  anchor="center"
                  backgroundColor={commonProps.backgroundColor}
                >
                  {area.name}
                </AreaLabel>
              );
            } else if (area.polygon_verticies && isAreaRectilinear(area)) {
              const topLeft = FloorplanCoordinates.toViewportCoordinates(
                FloorplanCoordinates.create(
                  area.polygon_verticies[0].x_from_origin_meters,
                  area.polygon_verticies[0].y_from_origin_meters
                ),
                floorplan,
                viewportAdjusted
              );

              const labelViewportCoordinates = ViewportCoordinates.create(
                topLeft.x + 2,
                topLeft.y + 2
              );

              return (
                <AreaLabel
                  position={labelViewportCoordinates}
                  backgroundColor={commonProps.backgroundColor}
                >
                  {area.name}
                </AreaLabel>
              );
            } else if (
              area.polygon_verticies &&
              area.polygon_verticies.length > 0
            ) {
              // Render the label at the vertex with the smallest y value
              const vertexHeights = area.polygon_verticies.map(
                (v) => v.y_from_origin_meters
              );
              const highestVertex =
                area.polygon_verticies[
                  vertexHeights.indexOf(Math.min(...vertexHeights))
                ];

              const highestVertexCoords =
                FloorplanCoordinates.toViewportCoordinates(
                  FloorplanCoordinates.create(
                    highestVertex.x_from_origin_meters,
                    highestVertex.y_from_origin_meters
                  ),
                  floorplan,
                  viewportAdjusted
                );

              const labelViewportCoordinates = ViewportCoordinates.create(
                highestVertexCoords.x,
                highestVertexCoords.y
              );

              return (
                <AreaLabel
                  position={labelViewportCoordinates}
                  anchor="center"
                  backgroundColor={commonProps.backgroundColor}
                >
                  {area.name}
                </AreaLabel>
              );
            } else {
              return null;
            }
          })
        : null}

      <HeatmapContours plan={props.plan} viewport={viewport} />
    </div>
  );
};

export default React.memo(FloorplanViewport);
