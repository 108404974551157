import * as React from 'react';

import Editor, { EditorTreatments } from 'components/editor/editor';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { PlanDetail } from 'lib/api';
import { updatePlanDetailInRedux } from 'redux/features/planning/update-plan-detail-in-redux-thunk';
import { asyncFetchSpacesThunk } from 'redux/features/spaces/async-fetch-spaces-thunk';
import { SPLITS } from 'lib/treatments';
import { useTreatments } from 'contexts/treatments';

const FloorPlanning: React.FC<{
  planDetail: PlanDetail;
  planImage: HTMLImageElement | null;
}> = ({ planDetail, planImage }) => {
  const dispatch = useAppDispatch();

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const tokenCheckResponse = useAppSelector(
    (state) => state.auth.tokenCheckResponse
  );

  const handlePlanSaved = React.useCallback(
    (planDetail: PlanDetail) => {
      // update plan detail in redux
      dispatch(updatePlanDetailInRedux(planDetail));

      // refetch spaces query since some spaces may have been deleted or created
      dispatch(asyncFetchSpacesThunk());
    },
    [dispatch]
  );

  const treatments = useTreatments([
    SPLITS.IMPORT_DXF,
    SPLITS.EXPORT_DXF,
    SPLITS.PLANNING_FLOORPLAN_COMPONENT,
    SPLITS.HEIGHT_MAP,
    SPLITS.AUTOLAYOUT,
    SPLITS.SENSOR_COVERAGE,
  ]) as EditorTreatments;

  if (!densityAPIClient) {
    return null;
  }

  return (
    <Editor
      client={densityAPIClient}
      plan={planDetail}
      planImage={planImage}
      tokenCheckResponse={tokenCheckResponse || undefined}
      onPlanSaved={handlePlanSaved}
      treatments={treatments}
    />
  );
};

export default FloorPlanning;
