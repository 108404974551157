import { Fragment, useMemo } from 'react';
import * as React from 'react';
import { verifyUserForInternalDensityTool } from '@densityco/lib-common-auth';
import { useAppSelector } from 'redux/store';

const InternalTool: React.FunctionComponent = ({ children }) => {
  const tokenCheckResponse = useAppSelector(
    (state) => state.auth.tokenCheckResponse
  );

  const userMayViewInternalTool = useMemo(() => {
    if (!tokenCheckResponse) {
      return;
    }

    return verifyUserForInternalDensityTool(tokenCheckResponse);
  }, [tokenCheckResponse]);

  return userMayViewInternalTool ? <Fragment>{children}</Fragment> : null;
};

export default InternalTool;
