import {
  createSlice,
  createAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { CoreSpace, CoreSpaceLabel } from '@densityco/lib-api-types';
import { applyPatches } from 'immer';

import { asyncFetchLabelsThunk } from './async-fetch-labels-thunk';

import {
  getInitialAsyncTaskState,
  withPayloadType,
  getPendingState,
  getFulfilledState,
  getRejectedState,
  hasValidPendingState,
  AsyncTaskState,
} from 'lib/redux';
import { asyncFetchSpacesThunk } from './async-fetch-spaces-thunk';
import { asyncFetchSpaceThunk } from './async-fetch-space-thunk';
import { RootState } from 'redux/store';
import { SpacesPatch } from 'lib/patches';

// mechanism for optimistic/pessimistic updates
export const patchSpacesState = createAction(
  'spaces/patchSpacesState',
  withPayloadType<SpacesPatch[]>()
);

export const spacesAdapter = createEntityAdapter<CoreSpace>({
  selectId: (space) => space.id,
});

export const labelsAdapter = createEntityAdapter<CoreSpaceLabel>({
  selectId: (label) => label.id,
});

export const spacesSelectors = spacesAdapter.getSelectors<RootState>(
  (state) => state.spaces.spaces
);

export const scopedSpacesSelectors = spacesAdapter.getSelectors<
  RootState['spaces']
>((state) => state.spaces);

export const labelsSelectors = labelsAdapter.getSelectors<RootState>(
  (state) => state.spaces.labels
);

export const scopedLabelsSelectors = labelsAdapter.getSelectors<
  RootState['spaces']
>((state) => state.labels);

const spacesSlice = createSlice({
  name: 'spaces',
  initialState: {
    spaces: spacesAdapter.getInitialState(),
    labels: labelsAdapter.getInitialState(),

    labelsQuery: getInitialAsyncTaskState<void>(),
    spacesQuery: getInitialAsyncTaskState<void>(),

    // TODO(wuweiweiwu): need better by query state tracking
    // this is so we can track individual query loading states
    // since each call with a different parameter is unique and should be treated so
    // right now we're just using id as a query key
    spaceQueries: {} as { [spaceId: string]: AsyncTaskState<void> },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncFetchLabelsThunk.pending, (state, action) => {
      state.labelsQuery = getPendingState(
        state.labelsQuery,
        action.meta.requestId
      );
    });

    builder.addCase(asyncFetchLabelsThunk.fulfilled, (state, action) => {
      if (hasValidPendingState(state.labelsQuery, action.meta.requestId)) {
        labelsAdapter.setAll(state.labels, action.payload);
      }

      state.labelsQuery = getFulfilledState(
        state.labelsQuery,
        action.meta.requestId,
        undefined
      );
    });

    builder.addCase(asyncFetchLabelsThunk.rejected, (state, action) => {
      state.labelsQuery = getRejectedState(
        state.labelsQuery,
        action.meta.requestId,
        action.error
      );
    });

    builder.addCase(asyncFetchSpacesThunk.pending, (state, action) => {
      state.spacesQuery = getPendingState(
        state.spacesQuery,
        action.meta.requestId,
        {
          hasData: state.spaces.ids.length > 0,
        }
      );
    });

    builder.addCase(asyncFetchSpacesThunk.fulfilled, (state, action) => {
      if (hasValidPendingState(state.spacesQuery, action.meta.requestId)) {
        spacesAdapter.setAll(state.spaces, action.payload);
      }

      state.spacesQuery = getFulfilledState(
        state.spacesQuery,
        action.meta.requestId,
        undefined
      );
    });

    builder.addCase(asyncFetchSpacesThunk.rejected, (state, action) => {
      state.spacesQuery = getRejectedState(
        state.spacesQuery,
        action.meta.requestId,
        action.error
      );
    });

    builder.addCase(asyncFetchSpaceThunk.pending, (state, action) => {
      state.spaceQueries[action.meta.arg] =
        state.spaceQueries[action.meta.arg] || getInitialAsyncTaskState<void>();

      state.spaceQueries[action.meta.arg] = getPendingState(
        state.spaceQueries[action.meta.arg],
        action.meta.requestId
      );
    });

    builder.addCase(asyncFetchSpaceThunk.fulfilled, (state, action) => {
      if (
        hasValidPendingState(
          state.spaceQueries[action.meta.arg],
          action.meta.requestId
        )
      ) {
        spacesAdapter.upsertOne(state.spaces, action.payload);
      }

      state.spaceQueries[action.meta.arg] = getFulfilledState(
        state.spaceQueries[action.meta.arg],
        action.meta.requestId,
        undefined
      );
    });

    builder.addCase(asyncFetchSpaceThunk.rejected, (state, action) => {
      state.spaceQueries[action.meta.arg] = getRejectedState(
        state.spaceQueries[action.meta.arg],
        action.meta.requestId,
        action.error
      );
    });

    builder.addCase(patchSpacesState, (state, action) => {
      return applyPatches(state, action.payload);
    });
  },
});

export default spacesSlice.reducer;
