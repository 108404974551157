import { Fragment, useRef, useEffect, useState, useCallback } from 'react';
import * as React from 'react';
import * as PIXI from 'pixi.js';
import styles from './styles.module.scss';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { Icons } from '@densityco/ui';
import AppBarFloorplan from 'components/app-bar-floorplan';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import Floorplan, {
  Layer,
  useFloorplanLayerContext,
} from 'components/floorplan';
import FormLabel from 'components/form-label';
import FloorplanCoordinatesField from 'components/floorplan-coordinates-field';
import HorizontalForm from 'components/horizontal-form';
import Panel, { PanelTitle, PanelBody } from 'components/panel';
import TextField from 'components/text-field';
import RotationField from 'components/rotation-field';
import OpacityField from 'components/opacity-field';

import { FloorplanCoordinates, ViewportCoordinates } from 'lib/geometry';
import parseGeoTiff, {
  GEOTIFF_NO_DATA,
  ParsedGeoTiff,
  GeoTiffTiepoint,
} from 'lib/geotiff';
import { round } from 'lib/math';

import { State, HeightMap } from './state';
import { Action } from './actions';
import HeightMapRegistrationLayer from './floorplan-layers/height-map-registration-layer';

const HeightExtentsPointerSvg: React.FunctionComponent = () => (
  <svg width="24" height="20" fill="none" viewBox="0 0 16 19">
    <g filter="url(#filter0_dd_6742_53766)">
      <path
        fill="#fff"
        d="M4 7.623V12a2 2 0 002 2h4a2 2 0 002-2V7.623a2 2 0 00-.63-1.458l-2-1.879a2 2 0 00-2.74 0l-2 1.88A2 2 0 004 7.622z"
      ></path>
      <path
        stroke="#313840"
        strokeWidth="2"
        d="M4 7.623V12a2 2 0 002 2h4a2 2 0 002-2V7.623a2 2 0 00-.63-1.458l-2-1.879a2 2 0 00-2.74 0l-2 1.88A2 2 0 004 7.622z"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_dd_6742_53766"
        width="16"
        height="18.256"
        x="0"
        y="0.744"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.174902 0 0 0 0 0.2 0 0 0 0.06 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          mode="multiply"
          result="effect1_dropShadow_6742_53766"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.174902 0 0 0 0 0.2 0 0 0 0.1 0"></feColorMatrix>
        <feBlend
          in2="effect1_dropShadow_6742_53766"
          mode="multiply"
          result="effect2_dropShadow_6742_53766"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_6742_53766"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

const HeightMapExtentsSlider: React.FunctionComponent<{
  smallestHeightMeters: number;
  largestHeightMeters: number;
  minMeters: number;
  maxMeters: number;
  onChange: (minMeters: number, maxMeters: number) => void;
}> = ({
  smallestHeightMeters,
  largestHeightMeters,
  minMeters,
  maxMeters,
  onChange,
}) => {
  const bar = useRef<HTMLDivElement | null>(null);

  const [barDragging, setBarDragging] = useState(false);

  const [workingMinMeters, setWorkingMinMeters] = useState(minMeters);
  useEffect(() => setWorkingMinMeters(minMeters), [minMeters]);

  const [workingMaxMeters, setWorkingMaxMeters] = useState(maxMeters);
  useEffect(() => setWorkingMaxMeters(maxMeters), [maxMeters]);

  const range = largestHeightMeters - smallestHeightMeters;
  const left = `${((workingMinMeters - smallestHeightMeters) / range) * 100}%`;
  const right = `${((largestHeightMeters - workingMaxMeters) / range) * 100}%`;

  return (
    <div
      className={styles.heightMapExtentsSlider}
      data-cy="height-map-extents-slider"
    >
      <div
        className={styles.heightMapExtentsSliderBar}
        ref={bar}
        data-cy="height-map-extents-slider-bar"
      >
        <div
          className={styles.heightMapExtentsSliderGradient}
          style={{ left, right, cursor: barDragging ? 'grabbing' : 'grab' }}
          onMouseDown={(event) => {
            if (!bar.current) {
              return;
            }
            event.preventDefault();
            setBarDragging(true);

            const barBBox = bar.current.getBoundingClientRect();

            const initialX = event.clientX - barBBox.left;
            const initialWorkingMinMeters = workingMinMeters;
            const initialWorkingMaxMeters = workingMaxMeters;

            let newWorkingMinMeters = workingMinMeters;
            let newWorkingMaxMeters = workingMaxMeters;

            const onMouseMove = (event: MouseEvent) => {
              if (!event.buttons) {
                return;
              }

              const x = event.clientX - barBBox.left;
              const percentageChange = (x - initialX) / barBBox.width;

              newWorkingMinMeters =
                initialWorkingMinMeters + range * percentageChange;
              newWorkingMaxMeters =
                initialWorkingMaxMeters + range * percentageChange;

              // Ensure that either move wouldn't put the bar out of bounds of the track
              if (newWorkingMinMeters < smallestHeightMeters) {
                newWorkingMinMeters = smallestHeightMeters;
                newWorkingMaxMeters =
                  smallestHeightMeters +
                  (initialWorkingMaxMeters - initialWorkingMinMeters);
              }
              if (newWorkingMaxMeters > largestHeightMeters) {
                newWorkingMinMeters =
                  largestHeightMeters -
                  (initialWorkingMaxMeters - initialWorkingMinMeters);
                newWorkingMaxMeters = largestHeightMeters;
              }

              setWorkingMinMeters(newWorkingMinMeters);
              setWorkingMaxMeters(newWorkingMaxMeters);
              onChange(newWorkingMinMeters, newWorkingMaxMeters);
            };
            window.addEventListener('mousemove', onMouseMove);

            const onMouseUp = () => {
              window.removeEventListener('mousemove', onMouseMove);
              window.removeEventListener('mouseup', onMouseUp);
              setBarDragging(false);
              onChange(newWorkingMinMeters, newWorkingMaxMeters);
            };
            window.addEventListener('mouseup', onMouseUp);
          }}
        />
      </div>

      <div
        className={styles.heightMapExtentsSliderMarker}
        style={{ left, transform: 'translateX(-12px)' }}
        onMouseDown={(event) => {
          if (!bar.current) {
            return;
          }
          event.preventDefault();

          const barBBox = bar.current.getBoundingClientRect();

          let newWorkingMinMeters = workingMinMeters;

          const onMouseMove = (event: MouseEvent) => {
            if (!event.buttons) {
              return;
            }

            let percentage = (event.clientX - barBBox.left) / barBBox.width;
            if (percentage < 0) {
              percentage = 0;
            }
            if (percentage > 1) {
              percentage = 1;
            }

            newWorkingMinMeters = smallestHeightMeters + percentage * range;
            setWorkingMinMeters(newWorkingMinMeters);
            onChange(newWorkingMinMeters, workingMaxMeters);
          };
          window.addEventListener('mousemove', onMouseMove);

          const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            onChange(newWorkingMinMeters, workingMaxMeters);
          };
          window.addEventListener('mouseup', onMouseUp);
        }}
        data-cy="height-map-extents-slider-marker-min"
      >
        <HeightExtentsPointerSvg />
      </div>

      <div
        className={styles.heightMapExtentsSliderMarker}
        style={{ right, transform: 'translateX(12px)' }}
        onMouseDown={(event) => {
          if (!bar.current) {
            return;
          }
          event.preventDefault();

          const barBBox = bar.current.getBoundingClientRect();

          let newWorkingMaxMeters = workingMaxMeters;

          const onMouseMove = (event: MouseEvent) => {
            if (!event.buttons) {
              return;
            }

            let percentage = (event.clientX - barBBox.left) / barBBox.width;
            if (percentage < 0) {
              percentage = 0;
            }
            if (percentage > 1) {
              percentage = 1;
            }

            newWorkingMaxMeters = smallestHeightMeters + percentage * range;
            setWorkingMaxMeters(newWorkingMaxMeters);
            onChange(workingMinMeters, newWorkingMaxMeters);
          };
          window.addEventListener('mousemove', onMouseMove);

          const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            onChange(workingMinMeters, newWorkingMaxMeters);
          };
          window.addEventListener('mouseup', onMouseUp);
        }}
        data-cy="height-map-extents-slider-marker-max"
      >
        <HeightExtentsPointerSvg />
      </div>
    </div>
  );
};

const HeightMapExtents: React.FunctionComponent<{
  smallestHeightMeters: number;
  largestHeightMeters: number;
  minMeters: number;
  maxMeters: number;
  onChange: (minMeters: number, maxMeters: number) => void;

  activeExtent: 'max' | 'min' | null;
  onPickExtent: (extent: 'max' | 'min' | null) => void;
}> = ({
  smallestHeightMeters,
  largestHeightMeters,
  minMeters,
  maxMeters,
  onChange,
  activeExtent,
  onPickExtent,
}) => {
  const [minMetersText, setMinMetersText] = useState('');
  useEffect(() => {
    setMinMetersText(`${round(minMeters, 2)}`);
  }, [minMeters]);
  const [minMetersInvalid, setMinMetersInvalid] = useState(false);

  const [maxMetersText, setMaxMetersText] = useState('');
  useEffect(() => {
    setMaxMetersText(`${round(maxMeters, 2)}`);
  }, [maxMeters]);
  const [maxMetersInvalid, setMaxMetersInvalid] = useState(false);

  return (
    <Fragment>
      <HorizontalForm size="medium">
        <TextField
          value={minMetersText}
          error={minMetersInvalid}
          onChange={(e) => setMinMetersText(e.currentTarget.value)}
          min={smallestHeightMeters}
          max={maxMeters}
          width={85}
          size="medium"
          onBlur={() => {
            const newMinMeters = parseFloat(minMetersText);
            if (isNaN(newMinMeters)) {
              setMinMetersInvalid(true);
              return;
            }
            if (minMeters === newMinMeters) {
              return;
            }
            setMinMetersInvalid(false);
            onChange(newMinMeters, maxMeters);
          }}
          trailingSuffix="m"
          data-cy="height-map-minimum-extent"
        />
        <Tooltip
          contents="Pick Lowest Obstruction"
          enterDelay={0}
          target={
            <Button
              onClick={() =>
                onPickExtent(activeExtent !== 'min' ? 'min' : null)
              }
              type={activeExtent === 'min' ? 'filled' : 'outlined'}
              trailingIcon={
                <Icons.EyeDropper width={32} height={32} color="currentColor" />
              }
              size="medium"
              data-cy="height-map-minimum-extent-eyedropper"
            />
          }
        />

        <TextField
          value={maxMetersText}
          error={maxMetersInvalid}
          onChange={(e) => setMaxMetersText(e.currentTarget.value)}
          min={minMeters}
          max={largestHeightMeters}
          width={85}
          size="medium"
          onBlur={() => {
            const newMaxMeters = parseFloat(maxMetersText);
            if (isNaN(newMaxMeters)) {
              setMaxMetersInvalid(true);
              return;
            }
            if (newMaxMeters === maxMeters) {
              return;
            }
            setMaxMetersInvalid(false);
            onChange(minMeters, newMaxMeters);
          }}
          trailingSuffix="m"
          data-cy="height-map-maximum-extent"
        />
        <Tooltip
          contents="Pick Ceiling"
          enterDelay={0}
          target={
            <Button
              onClick={() =>
                onPickExtent(activeExtent !== 'max' ? 'max' : null)
              }
              type={activeExtent === 'max' ? 'filled' : 'outlined'}
              trailingIcon={
                <Icons.EyeDropper width={32} height={32} color="currentColor" />
              }
              size="medium"
              data-cy="height-map-maximum-extent-eyedropper"
            />
          }
        />
      </HorizontalForm>

      <HeightMapExtentsSlider
        smallestHeightMeters={smallestHeightMeters}
        largestHeightMeters={largestHeightMeters}
        minMeters={minMeters}
        maxMeters={maxMeters}
        onChange={onChange}
      />
    </Fragment>
  );
};

const HeightMapHeightRangeHeightPickerLayer: React.FunctionComponent<{
  heightMap: HeightMap;
  onPickHeight: (heightMeters: number) => void;
}> = ({ heightMap, onPickHeight }) => {
  const context = useFloorplanLayerContext();
  const geotiffParseResults = useParseGeoTiff(heightMap.url);

  useEffect(() => {
    const backdropSprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdropSprite.name = 'height-picker-backdrop';
    backdropSprite.alpha = 0;
    backdropSprite.interactive = true;
    backdropSprite.cursor = 'crosshair';
    backdropSprite.on('mousedown', (event) => {
      if (!context.viewport.current) {
        return;
      }
      if (!geotiffParseResults) {
        return;
      }

      const mousePosition = ViewportCoordinates.create(
        event.data.global.x,
        event.data.global.y
      );

      const heightMapPosition = ViewportCoordinates.toHeightMapCoordinates(
        mousePosition,
        context.viewport.current,
        context.floorplan,
        heightMap,
        geotiffParseResults.scale
      );

      geotiffParseResults
        .getHeightAtPoint(
          Math.round(heightMapPosition.x),
          Math.round(heightMapPosition.y)
        )
        .then((heightMeters) => {
          // If the user clicks outside the geotiff, disregard that point
          if (heightMeters === GEOTIFF_NO_DATA) {
            return;
          }

          // If the request was aborted for some reason, disregard
          if (heightMeters === null) {
            return;
          }

          onPickHeight(heightMeters);
        });
    });

    context.app.stage.addChild(backdropSprite);
    return () => {
      context.app.stage.removeChild(backdropSprite);
    };
  }, [context, heightMap, geotiffParseResults, onPickHeight]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }

        const backdropSprite = context.app.stage.getChildByName(
          'height-picker-backdrop'
        ) as PIXI.Sprite | null;

        if (!backdropSprite) {
          return;
        }

        backdropSprite.width = context.viewport.current.width;
        backdropSprite.height = context.viewport.current.height;
      }}
    />
  );
};

export const useParseGeoTiff = (heightMapUrl: HeightMap['url'] | null) => {
  const [geotiffParseResults, setGeotiffParseResults] =
    useState<ParsedGeoTiff | null>(null);

  // Parse the raw geotiff to get a representation that can be used for rendering
  useEffect(() => {
    let active = false;

    if (!heightMapUrl) {
      setGeotiffParseResults(null);
      return;
    }

    active = true;

    parseGeoTiff(heightMapUrl).then((results) => {
      if (active) {
        setGeotiffParseResults(results);
      }
    });

    return () => {
      active = false;
    };
  }, [heightMapUrl]);

  return geotiffParseResults;
};

const HeightMapImport: React.FunctionComponent<{
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ state, dispatch }) => {
  const geotiffParseResults = useParseGeoTiff(
    state.heightMapImport.view === 'enabled' ? state.heightMapImport.url : null
  );

  const [heightPickerExtent, setHeightPickerExtent] = useState<
    'min' | 'max' | null
  >(null);

  const onGeoTiffLoaded = useCallback(
    (tiePoint: GeoTiffTiepoint, scale: number) => {
      dispatch({
        type: 'heightMapImport.setGeoTiffOffsets',
        tiePoint,
        scale,
      });
    },
    [dispatch]
  );

  if (!state.floorplanImage) {
    return null;
  }
  if (state.heightMapImport.view !== 'enabled') {
    return null;
  }

  return (
    <Fragment>
      <AppBarFloorplan
        actions={
          <HorizontalForm size="medium">
            <Button
              size="medium"
              data-cy="height-map-import-cancel"
              type="cleared"
              onClick={() => dispatch({ type: 'heightMapImport.cancel' })}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              data-cy="height-map-import-submit"
              onClick={() => dispatch({ type: 'heightMapImport.save' })}
            >
              Save Height Map
            </Button>
          </HorizontalForm>
        }
      />

      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Floorplan
            image={state.floorplanImage}
            floorplan={state.floorplan}
            width="100%"
            height="100%"
            lengthUnit={state.displayUnit}
          >
            <HeightMapRegistrationLayer
              url={state.heightMapImport.url}
              position={state.heightMapImport.position}
              rotationInDegrees={state.heightMapImport.rotation}
              onGeoTiffLoaded={onGeoTiffLoaded}
              limits={state.heightMapImport.limits}
              opacity={state.heightMapImport.opacity}
              onDragMove={(position, scale, rotation) => {
                dispatch({
                  type: 'heightMapImport.changeRegistration',
                  position,
                  rotation,
                });
              }}
            />
            {heightPickerExtent !== null ? (
              <HeightMapHeightRangeHeightPickerLayer
                heightMap={state.heightMapImport}
                onPickHeight={(heightMeters) => {
                  if (!geotiffParseResults) {
                    return;
                  }
                  if (state.heightMapImport.view !== 'enabled') {
                    return;
                  }

                  let minMeters = state.heightMapImport.limits.enabled
                    ? state.heightMapImport.limits.minMeters
                    : geotiffParseResults.smallestValue;
                  let maxMeters = state.heightMapImport.limits.enabled
                    ? state.heightMapImport.limits.maxMeters
                    : geotiffParseResults.largestValue;

                  switch (heightPickerExtent) {
                    case 'min':
                      minMeters = heightMeters;
                      break;
                    case 'max':
                      maxMeters = heightMeters;
                      break;
                  }

                  dispatch({
                    type: 'heightMapImport.changeBounds',
                    minMeters,
                    maxMeters,
                  });

                  setHeightPickerExtent(null);
                }}
              />
            ) : null}
          </Floorplan>
        </div>

        <Panel position="top-left" width={300}>
          <PanelTitle
            icon={<Icons.Cog width={18} height={18} color="currentColor" />}
          >
            Adjustments
          </PanelTitle>
          <PanelBody>
            <FormLabel
              label="Origin"
              input={
                <FloorplanCoordinatesField
                  value={state.heightMapImport.position}
                  floorplan={state.floorplan}
                  computeDefaultValue={() => FloorplanCoordinates.create(0, 0)}
                  data-cy="height-map-position"
                  onChange={(position) => {
                    dispatch({
                      type: 'heightMapImport.changePosition',
                      position,
                    });
                  }}
                />
              }
              htmlFor="height-map-origin"
            />

            <FormLabel
              label="Rotation"
              input={
                <RotationField
                  value={state.heightMapImport.rotation}
                  onChange={(rotation) => {
                    dispatch({
                      type: 'heightMapImport.changeRotation',
                      rotation,
                    });
                  }}
                  onRotateRight90={() =>
                    dispatch({ type: 'heightMapImport.rotateRight90' })
                  }
                  rotationSVGGlyph={
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="none"
                      strokeWidth="1"
                    >
                      <g
                        stroke={dust.Blue400}
                        strokeWidth="2"
                        transform="translate(4,5)"
                      >
                        <g transform="translate(4.648,6)">
                          <path d="M1.0042334 5.68434189e-14L1.0042334 20 21.3521024 20 21.3521024 5.34801195 17.8868814 5.34801195"></path>
                          <path d="M4.308 9.661L1.004 9.661"></path>
                          <path d="M10.9131311 5.34801195L10.9131311 9.54738553 7.67833682 9.54738553"></path>
                          <path d="M0 3.81149139e-14L10.9131311 0 10.9131311 5.34801195 14.1551468 5.36419725"></path>
                        </g>
                      </g>
                      <path
                        d="M15.6665 4.50022L19.9998 1.61133L24.3332 4.50022"
                        stroke={dust.Blue400}
                        strokeWidth="2"
                        fill="transparent"
                        transform="translate(0,2)"
                      />
                    </g>
                  }
                  data-cy="height-map-rotation"
                />
              }
              htmlFor="height-map-rotation"
            />

            <FormLabel
              label="Opacity"
              input={
                <OpacityField
                  value={state.heightMapImport.opacity}
                  onChange={(opacity: number) =>
                    dispatch({ type: 'heightMapImport.changeOpacity', opacity })
                  }
                  data-cy="height-map-opacity"
                />
              }
              htmlFor="height-map-rotation"
            />

            {geotiffParseResults ? (
              <FormLabel
                label="Bounds"
                input={
                  <HeightMapExtents
                    smallestHeightMeters={geotiffParseResults.smallestValue}
                    largestHeightMeters={geotiffParseResults.largestValue}
                    minMeters={
                      state.heightMapImport.limits.enabled
                        ? state.heightMapImport.limits.minMeters
                        : geotiffParseResults.smallestValue
                    }
                    maxMeters={
                      state.heightMapImport.limits.enabled
                        ? state.heightMapImport.limits.maxMeters
                        : geotiffParseResults.largestValue
                    }
                    onChange={(minMeters, maxMeters) =>
                      dispatch({
                        type: 'heightMapImport.changeBounds',
                        minMeters,
                        maxMeters,
                      })
                    }
                    activeExtent={heightPickerExtent}
                    onPickExtent={setHeightPickerExtent}
                  />
                }
                htmlFor="height-map-rotation"
              />
            ) : null}

            <br />
            <HorizontalForm size="small">
              <Button
                onClick={() =>
                  geotiffParseResults &&
                  dispatch({
                    type: 'heightMapImport.changeBounds',
                    minMeters: geotiffParseResults.smallestValue,
                    maxMeters: geotiffParseResults.largestValue,
                  })
                }
                type="outlined"
                size="small"
              >
                Reset
              </Button>
            </HorizontalForm>
          </PanelBody>
        </Panel>
      </div>
    </Fragment>
  );
};

export default HeightMapImport;
