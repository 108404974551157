import * as React from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css, SerializedStyles } from '@emotion/react';

type InlineInputProps = {
  // following react-select's method of customizing but instead with the return value of css
  inputStyles?: SerializedStyles;
  labelStyles?: SerializedStyles;

  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const InlineInput: React.FC<InlineInputProps> = (props) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
    }
  }, [isFocused]);

  if (isFocused) {
    return (
      <input
        ref={inputRef}
        css={[
          css`
            font-size: ${dust.TextScale3};
            font-weight: ${dust.FontWeightMedium};
            border: 1px solid ${dust.Blue400};
            border-radius: ${dust.Radius100};
            width: 30px;
            height: 24px;
            outline: none;
          `,
          props.inputStyles,
        ]}
        value={props.value}
        onBlur={(e) => {
          props.onBlur?.(e);

          setIsFocused(false);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            inputRef.current?.blur();
          }
        }}
        onChange={props.onChange}
      />
    );
  }

  return (
    <span
      css={[
        css`
          font-size: ${dust.TextScale3};
          font-weight: ${dust.FontWeightMedium};
          cursor: pointer;
        `,
        props.labelStyles,
      ]}
      onClick={() => {
        setIsFocused(true);
      }}
    >
      {props.value || props.placeholder}
    </span>
  );
};

export default InlineInput;
