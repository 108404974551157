// NOTE: copied from figma. this is the unicode character U+2013 not to be confused with U+002d or "-"
export const NO_VALUE_PLACEHOLDER = '––';

export const toHumanReadablePercent = (n: number) => {
  const percent = n * 100;

  return toHumanReadableNumber(percent) + '%';
};

export const toHumanReadableTime = (milliseconds: number) => {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hour = minutes / 60;

  if (seconds < 60) {
    return toHumanReadableNumber(seconds) + ' sec';
  }

  if (minutes < 60) {
    return toHumanReadableNumber(minutes) + ' min';
  }

  return toHumanReadableNumber(hour) + ' hr';
};

export const toHumanReadableNumber = (n: number) => {
  return n === 0 ? '0' : n < 1 ? '< 1' : n.toFixed(0);
};

export const pluralize = (str: string, val: number) =>
  `${str}${val !== 1 ? 's' : ''}`;
