import Mousetrap from 'mousetrap';

export type Keybindings = Array<{
  sequence: Array<string> | string;
  keypress?: (evt: KeyboardEvent) => void;
  keyup?: (evt: KeyboardEvent) => void;
  keydown?: (evt: KeyboardEvent) => void;
}>;
export type ModifierKey = 'alt' | 'shift' | 'ctrl' | 'meta';

export const MODIFIER_KEYS: Array<ModifierKey> = [
  'alt',
  'shift',
  'ctrl',
  'meta',
];

export function modifierKeyFromEvt(evt: KeyboardEvent): ModifierKey | null {
  switch (evt.key.toLowerCase()) {
    case 'alt': {
      return 'alt';
    }
    case 'shift': {
      return 'shift';
    }
    case 'ctrl': {
      return 'ctrl';
    }
    case 'meta': {
      return 'meta';
    }
    default: {
      return null;
    }
  }
}

export function subscribeToKeybindings(keybindings: Keybindings) {
  keybindings.forEach((keybinding) => {
    if (keybinding.keydown) {
      Mousetrap.bind(keybinding.sequence, keybinding.keydown, 'keydown');
    }
    if (keybinding.keyup) {
      Mousetrap.bind(keybinding.sequence, keybinding.keyup, 'keyup');
    }
    if (keybinding.keypress) {
      Mousetrap.bind(keybinding.sequence, keybinding.keypress);
    }
  });
}

export function unsubscribeFromKeybindings(keybindings: Keybindings) {
  keybindings.forEach((keybinding) => {
    Mousetrap.unbind(keybinding.sequence);
  });
}
