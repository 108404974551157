import * as React from 'react';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  Icons,
  InputBox,
  InputBoxContext,
  Switch,
} from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';

import styles from './app-bar-floor.module.scss';

import AppBarSelectBox from 'components/app-bar-select-box/app-bar-select';
import DropdownInfo from 'components/dropdown-info';
import { findFloorsInBuilding } from 'lib/floors';
import {
  PlanViewDescriptor,
  PlanViewId,
  planViewListOrder,
  PLAN_VIEW_ANALYSIS,
  PLAN_VIEW_AVAILABILITY,
  PLAN_VIEW_PLANNING,
} from 'lib/plan-views';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import TreatmentsOverrides from 'components/treatments-overrides/treatments-overrides';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectFloor } from 'redux/features/spaces/select-floor';
import { spacesSelectors } from 'redux/features/spaces/spaces-slice';
import { selectBuilding } from 'redux/features/spaces/select-building';
import { asyncUpdateSpaceThunk } from 'redux/features/spaces/async-update-space-thunk';
import { selectPlanDetailByFloor } from 'redux/features/plans/select-plan-detail-by-floor';

const AppBarFloor: React.FunctionComponent = () => {
  const history = useHistory();
  const params = useParams<{
    buildingId: string;
    floorId: string;
    planViewId: PlanViewId;
  }>();

  const { planViewId, floorId, buildingId } = params;

  const dispatch = useAppDispatch();

  const floor = useAppSelector((state) => selectFloor(state, { floorId }));

  const planDetail = useAppSelector((state) =>
    selectPlanDetailByFloor(state, { floorId })
  );

  const building = useAppSelector((state) =>
    selectBuilding(state, { buildingId })
  );
  const spaces = useAppSelector(spacesSelectors.selectAll);

  const isFFOverridesOn = useTreatment(SPLITS.FF_OVERRIDES);
  const [isFFOverridesModalOpen, setIsFFOverridesModalOpen] =
    React.useState(false);

  const planViewDescriptor = React.useMemo(() => {
    switch (planViewId) {
      case 'planning': {
        return PLAN_VIEW_PLANNING;
      }
      case 'availability': {
        return PLAN_VIEW_AVAILABILITY;
      }
      case 'analysis': {
        return PLAN_VIEW_ANALYSIS;
      }
      default: {
        return PLAN_VIEW_PLANNING;
      }
    }
  }, [planViewId]);

  const setFloorStatus = React.useCallback(
    (status: 'live' | 'planning') => {
      if (!floor || !planDetail) {
        return;
      }

      dispatch(
        asyncUpdateSpaceThunk({
          spaceId: floorId,
          planId: planDetail.id,

          body: {
            status,
          },
        })
      );
    },
    [dispatch, floor, floorId, planDetail]
  );

  const onBuildingClick = React.useCallback(() => {
    history.push(`/buildings/${buildingId}`);
  }, [buildingId, history]);

  const floors = React.useMemo(() => {
    return findFloorsInBuilding(spaces, buildingId);
  }, [spaces, buildingId]);

  const statusIsLive = React.useMemo(() => {
    return Boolean(floor && floor.status === 'live');
  }, [floor]);

  if (!building || !floor) {
    return null;
  }

  return (
    <div className={styles.AppBarFloor}>
      <section className={styles.AppBarFloorLeft}>
        <div className={styles.Breadcrumb}>
          <div className={styles.Building} onClick={onBuildingClick}>
            <div className={styles.Icon}>
              <Icons.Building width={20} height={20} color="currentColor" />
            </div>
            <div className={styles.Name}>{building.name}</div>
          </div>
          <div>
            <Icons.ChevronRight
              width={24}
              height={24}
              color={colors.midnightOpaque40}
            />
          </div>
          <AppBarSelectBox
            value={floor}
            options={floors}
            icon={
              <div style={{ marginLeft: 4 }}>
                <Icons.Floor width={20} height={20} />
              </div>
            }
            onChange={(floor) => {
              history.push(`/buildings/${building.id}/floors/${floor.id}`);
            }}
          />
        </div>
      </section>
      <section>
        <div className={styles.viewSelectBox}>
          <InputBoxContext.Provider value="OPEN_AREA_APP_BAR">
            <InputBox
              type="select"
              width={190}
              anchor="ANCHOR_RIGHT"
              value={planViewDescriptor.id}
              choices={planViewListOrder}
              onChange={(view: PlanViewDescriptor) => {
                history.push(
                  `/buildings/${building.id}/floors/${floor.id}/${view.path}`
                );
              }}
            />
          </InputBoxContext.Provider>
        </div>
      </section>
      <section className={styles.AppBarFloorRight}>
        <div>
          <DropdownInfo
            label={
              <div className={styles.status}>
                <div
                  className={classNames(styles.statusDot, {
                    [styles.statusDotPlanning]: !statusIsLive,
                  })}
                >
                  &#8226;&nbsp;
                </div>
                <span className={styles.statusText}>
                  {statusIsLive ? 'Live' : 'Planning'}{' '}
                </span>
                &nbsp;
                <Icons.ChevronDown color={colors.midnightOpaque80} />
              </div>
            }
          >
            <section className={styles.statusInfo}>
              <section className={styles.statusInfoHeader}>
                Status for <strong>{floor.name}</strong>
              </section>
              <section className={styles.statusInfoBody}>
                {statusIsLive
                  ? 'This floor is in “Live” mode. You won’t be able to make adjustments to your plan unless you move it back to “Planning”.'
                  : 'This floor is in “Planning” mode. No reporting data based on this plan will be recorded until you explicitly make it “Live”.'}
              </section>
              <section className={styles.statusInfoFooter}>
                <div className={styles.statusInfoSwitchInputGroup}>
                  {statusIsLive ? 'Back to "Planning"' : 'Make it "Live"'}
                  <div className={styles.statusSwitch}>
                    <Switch
                      value={statusIsLive}
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        setFloorStatus(statusIsLive ? 'planning' : 'live');
                      }}
                    />
                  </div>
                </div>
              </section>
            </section>
          </DropdownInfo>
        </div>

        {isFFOverridesOn ? (
          <div>
            <Button
              style={{
                marginLeft: 8,
                height: 30,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => setIsFFOverridesModalOpen(true)}
            >
              FF Overrides
            </Button>
          </div>
        ) : null}
      </section>

      {isFFOverridesOn ? (
        <TreatmentsOverrides
          isOpen={isFFOverridesModalOpen}
          onClose={() => setIsFFOverridesModalOpen(false)}
        />
      ) : null}
    </div>
  );
};
export default AppBarFloor;
