import * as React from 'react';
import _isEqual from 'lodash.isequal';

export function useDeepMemo<TKey>(key: TKey) {
  const ref = React.useRef<TKey>(key);

  if (!_isEqual(key, ref.current)) {
    ref.current = key;
  }

  return ref.current;
}
