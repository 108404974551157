import { createSelector } from '@reduxjs/toolkit';
import { isBuilding } from 'lib/buildings';
import { RootState } from 'redux/store';
import { spacesSelectors } from './spaces-slice';

export const selectBuilding = createSelector(
  [
    (state: RootState, params: { buildingId: string }) =>
      spacesSelectors.selectById(state, params.buildingId),
  ],
  (space) => {
    if (!space) {
      return;
    }

    if (isBuilding(space)) {
      return space;
    }
  }
);
