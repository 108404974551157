import { useCallback } from 'react';
import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { Simulant } from './state';
import { Action } from './actions';

import { Floorplan } from 'lib/floorplan';
import { FloorplanCoordinates } from 'lib/geometry';
import { Viewport } from 'lib/viewport';

const SimulantGraphic: React.FunctionComponent<{
  simulant: Simulant;
  floorplan: Floorplan;
  viewport: Viewport;
  boundariesShown: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({ simulant, floorplan, viewport, boundariesShown, dispatch }) => {
  const position = FloorplanCoordinates.toViewportCoordinates(
    simulant.position,
    floorplan,
    viewport
  );
  const onMouseDown = useCallback(
    (evt: React.MouseEvent<SVGCircleElement>) => {
      evt.preventDefault();
      evt.stopPropagation();

      const { clientX, clientY } = evt;
      dispatch({
        type: 'item.graphic.mousedown',
        itemType: 'simulant',
        itemId: simulant.id,
        itemPosition: position,
        clientX,
        clientY,
      });
    },
    [simulant.id, position, dispatch]
  );
  const onContextMenu = useCallback(
    (evt: React.MouseEvent<SVGCircleElement>) => {
      evt.preventDefault();
      dispatch({ type: 'simulation.simulant.remove', id: simulant.id });
    },
    [simulant.id, dispatch]
  );
  return (
    <circle
      cx={position.x}
      cy={position.y}
      r={8}
      fillOpacity={0}
      stroke={colors.green}
      strokeWidth={boundariesShown ? 2 : 0}
      style={{ cursor: 'grab' }}
      onMouseDown={onMouseDown}
      onContextMenu={onContextMenu}
    />
  );
};

export default SimulantGraphic;
