import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';
import { ChartAvailabilityByDayColumn } from 'lib/charts';
import moment from 'moment-timezone';

const ChartAvailabilityByDayKey: React.FC<{
  text: string;
  color: string;
}> = (props) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 4px;
      `}
    >
      <div
        css={css`
          background: ${props.color};
          width: 8px;
          height: 8px;
          border-radius: 50%;
        `}
      ></div>
      <div
        css={css`
          font-size: 10px;
          color: ${dust.Gray400};
        `}
      >
        {props.text}
      </div>
    </div>
  );
};

const ChartAvailabilityByDay: React.FC<{
  columns: Array<ChartAvailabilityByDayColumn>;
  timeZone: string;
}> = (props) => {
  // Build a data structure for X-Axis labels
  // Months only appear under days the first time they appear in the data
  const dayAxisLabels: Array<{ dateLabel: string; monthLabel: string | null }> =
    [];
  let lastMonth: string | null = null;
  props.columns.forEach((column) => {
    const todayMonth = moment.tz(column.date, props.timeZone).format('MMM');
    let monthLabel = null;
    if (lastMonth !== todayMonth) {
      monthLabel = todayMonth;
      lastMonth = todayMonth;
    }
    dayAxisLabels.push({
      dateLabel: moment.tz(column.date, props.timeZone).format('D'),
      monthLabel,
    });
  });

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-around;
        `}
      >
        <ChartAvailabilityByDayKey text="Available" color={dust.Gray100} />
        <ChartAvailabilityByDayKey text="Occupied" color={dust.Blue400} />
      </div>
      <div
        css={css`
          display: flex;
          gap: 4px;
        `}
      >
        {/* Y axis */}
        <div>
          {props.columns[0].rows
            .slice(0, -1)
            .filter((_, i) => i % 2 === 0)
            .map((item) => (
              <div
                css={css`
                  font-size: 10px;
                  color: ${dust.Gray400};
                  height: 20.5px;
                  transform: translateY(-1px);
                `}
              >
                {moment
                  .tz(item.hour, 'HH:mm', props.timeZone)
                  .format('ha')
                  .slice(0, -1)}
              </div>
            ))}
        </div>

        {/* Chart and X axis */}
        <div
          css={css`
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          {/* Chart */}
          <div
            css={css`
              display: flex;
              gap: 2px;
            `}
          >
            {props.columns.map((column, columnIndex) => (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  gap: 2px;
                `}
              >
                {column.rows.map((item) => (
                  <div
                    css={css`
                      width: 100%;
                      height: 8.25px;
                      border-radius: 1px;
                      background: ${item.available
                        ? dust.Gray100
                        : dust.Blue400};
                    `}
                  ></div>
                ))}
                {/* X axis label */}
                <div
                  css={css`
                    font-size: 10px;
                    color: ${dust.Gray400};
                  `}
                >
                  {dayAxisLabels[columnIndex].dateLabel}
                </div>
                {dayAxisLabels[columnIndex].monthLabel ? (
                  <div
                    css={css`
                      font-size: 8px;
                      font-weight: 700;
                      color: ${dust.Gray300};
                    `}
                  >
                    {dayAxisLabels[columnIndex].monthLabel}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartAvailabilityByDay;
