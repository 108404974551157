import * as React from 'react';
import * as d3 from 'd3';
import { css } from '@emotion/react';

import { HEATMAP_COLORS } from './heatmap-constants';
import { useHeatmap } from './heatmap-context';

import { FloorplanCoordinates } from 'lib/geometry';
import { useAppSelector } from 'redux/store';
import { PlanDetail } from 'lib/api';
import { getFloorplanFromPlan } from 'lib/floorplan';
import { Viewport } from 'lib/viewport';

type HeatmapContoursProps = {
  plan: PlanDetail;
  viewport: Viewport;
};

const HeatmapContours: React.FC<HeatmapContoursProps> = ({
  plan,
  viewport,
}) => {
  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );

  const { frameContours, gridSize, maxX, maxY, frame } = useHeatmap();

  const floorplan = getFloorplanFromPlan(plan);

  const height = viewport.height;
  const width = viewport.width;
  const scale = floorplan.scale * viewport.zoom;

  const canvasElementRef = React.useRef<HTMLCanvasElement>(null);

  // Update canvas on first render and when params change
  React.useLayoutEffect(() => {
    const canvas = canvasElementRef.current;
    if (!canvas) throw new Error('Could not get canvas element from ref');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get canvas 2d context');

    ctx.save();

    // IMPORTANT: scale the context by the device pixel ratio
    ctx.scale(devicePixelRatio, devicePixelRatio);

    // IMPORTANT: clear the canvas before drawing again
    ctx.clearRect(0, 0, width, height);

    const heatmapTopLeft = FloorplanCoordinates.toViewportCoordinates(
      FloorplanCoordinates.create(0, 0),
      floorplan,
      viewport
    );
    const heatmapBottomRight = FloorplanCoordinates.toViewportCoordinates(
      FloorplanCoordinates.create(maxX * gridSize, maxY * gridSize),
      floorplan,
      viewport
    );
    const projection = d3
      .geoIdentity()
      .scale((heatmapBottomRight.x - heatmapTopLeft.x) / maxX)
      .translate([heatmapTopLeft.x, heatmapTopLeft.y]);

    // const pathGenerator = d3.geoPath(projection, ctx);

    // heatmap display
    // ctx.globalAlpha = floorplanImageOpacity;

    function geoCurvePath(
      curve: d3.CurveCatmullRomFactory,
      projection: d3.GeoIdentityTransform,
      context: CanvasRenderingContext2D
    ) {
      return (object: d3.ContourMultiPolygon) => {
        const pathContext = context === undefined ? d3.path() : context;
        d3.geoPath(projection, curveContext(curve(pathContext)))(object);
        return context === undefined ? pathContext + '' : undefined;
      };
    }

    function curveContext(curve: d3.CurveGenerator): d3.GeoContext {
      let initialPoint: [number, number] = [0, 0];
      return {
        moveTo(x: number, y: number) {
          initialPoint = [x, y];
          curve.lineStart();
          curve.point(x, y);
        },
        lineTo(x: number, y: number) {
          curve.point(x, y);
        },
        closePath() {
          curve.point(initialPoint[0], initialPoint[1]);
          curve.lineEnd();
        },
        arc() {
          throw new Error('Curved context does not include this feature.');
        },
        beginPath() {
          throw new Error('Curved context does not include this feature.');
        },
      };
    }

    frameContours.forEach((contour, index) => {
      ctx.fillStyle = HEATMAP_COLORS[index];
      ctx.beginPath();
      geoCurvePath(d3.curveCatmullRom, projection, ctx)(contour);
      ctx.closePath();
      ctx.fill();
    });

    ctx.restore();
  }, [
    canvasElementRef,
    frameContours,
    width,
    height,
    scale,
    viewport,
    frame,
    maxX,
    maxY,
    gridSize,
    floorplan,
  ]);

  return (
    <canvas
      ref={canvasElementRef}
      css={css`
        position: absolute;
        width: ${width}px;
        height: ${height}px;
        pointer-events: none;
        opacity: ${heatmapEnabled ? 0.7 : 0};
      `}
      width={width * devicePixelRatio}
      height={height * devicePixelRatio}
    />
  );
};

export default React.memo(HeatmapContours);
