import { AxiosInstance, AxiosResponse } from 'axios';
import { CoreSpace } from '@densityco/lib-api-types';
import { CoreUser } from '@densityco/lib-api-types';
import { Matrix } from 'transformation-matrix';

import {
  SensorStatus,
  PhotoGroup,
  PhotoGroupPhoto,
  PlanDXF,
  PlanDXFAsset,
  PlanExport,
} from '../components/editor/state';

// TODO: move these types to lib-api-types

type Paginated<T> = {
  next: string | null;
  previous: string | null;
  total: number;
  results: Array<T>;
};

export type PlanSummary = Pick<
  PlanDetail,
  | 'id'
  | 'floor'
  | 'image_url'
  | 'source_pdf_url'
  | 'source_pdf_page'
  | 'created_at'
  | 'updated_at'
>;

// This is the format that a photo group comes back from the server in.
type SerializedPhotoGroup = Omit<PhotoGroup, 'photos' | 'position'> & {
  photos: Array<{
    id: string;
    name: string;
    url: string;
  }>;
  origin_x_pixels: number;
  origin_y_pixels: number;
};

interface Plan {
  id: string;
  // DB Timestamps - eg. "2021-03-11T20:27:09.724000"
  created_at: string;
  updated_at: string | null;

  // Floor - Core Space with {type: 'floor'} that is 1-to-1 with this Plan
  floor_id: string; // eg. "spc_906277793787019303"
  floor: {
    id: string;
    name: string;
    parent_id: string;
    status: 'planning' | 'live';
    time_zone: string | null;
  };

  // Floorplan Base Image
  image_url: string | null;

  image_key: string;
  source_pdf_key: string;

  source_pdf_url?: string | null;
  source_pdf_page?: number | null;

  // Image Dimensions
  image_width_pixels: number;
  image_height_pixels: number;

  // Scale - Computed from measurement
  image_pixels_per_meter: number;

  // Measurement - Result of user interaction to measure the floorplan scale
  measurement_computed_length_meters: number;
  measurement_point_a_x_pixels: number;
  measurement_point_a_y_pixels: number;
  measurement_point_b_x_pixels: number;
  measurement_point_b_y_pixels: number;
  measurement_user_entered_length_feet: string;
  measurement_user_entered_length_inches: string;

  // Floorplan Origin - Image location treated as (0, 0) in Floorplan Coordinates
  origin_x_pixels: number;
  origin_y_pixels: number;

  // Floorplan Objects
  areas: Array<Area>;
  plan_sensors: Array<PlanSensor>;
  reference_points: Array<ReferencePoint>;
  reference_rulers: Array<ReferenceRuler>;

  // Keep track of last sensor index to display a new index when adding sensors
  last_oa_sensor_index?: number;
  last_entry_sensor_index?: number;

  photo_groups: Array<SerializedPhotoGroup>;

  // Floorplan CAD origin
  floorplan_cad_origin_x: number;
  floorplan_cad_origin_y: number;

  latest_dxf: Pick<PlanDXF, 'id' | 'status' | 'created_at' | 'options'> | null;
  active_dxf_id: PlanDXF['id'] | null;
  active_dxf_full_raster_url: PlanDXFAsset['object_url'] | null;

  // Ceiling Raster data
  ceiling_raster_key: string | null;
  ceiling_raster_url: string | null;
  ceiling_raster_floorplan_origin_x: number;
  ceiling_raster_floorplan_origin_y: number;
  ceiling_raster_floorplan_angle_degrees: number;
  ceiling_raster_opacity_percent: number;
  ceiling_raster_notes: string;
  ceiling_raster_min_height_limit_meters: number;
  ceiling_raster_max_height_limit_meters: number;
  point_cloud_transformation_matrix: Matrix | null;
  point_cloud_transformation_steps: string | null;
}

export type PlanDetail = Omit<Plan, PlanWriteOnlyFields>;

interface AreaBase {
  id: string;
  name: string;
  locked: boolean;

  // core space id
  // this is nullable because if it isn't it will break a lot of existing typings
  space_id?: string;
}

interface AreaPolygon extends AreaBase {
  shape: 'polygon';
  polygon_verticies: Array<{
    x_from_origin_meters: number;
    y_from_origin_meters: number;
  }>;
  circle_centroid_x_meters: null;
  circle_centroid_y_meters: null;
  circle_radius_meters: null;
}

interface AreaCircle extends AreaBase {
  shape: 'circle';
  circle_centroid_x_meters: number;
  circle_centroid_y_meters: number;
  circle_radius_meters: number;
  polygon_verticies: null;
}

export type Area = AreaPolygon | AreaCircle;

export interface PlanSensor {
  id: string;
  sensor_serial_number?: string | null;
  sensor_type: 'oa' | 'entry';
  locked: boolean;
  rotation: number; // degrees
  centroid_from_origin_x_meters: number;
  centroid_from_origin_y_meters: number;
  height_meters: number;
  last_heartbeat?: string | null;
  status?: SensorStatus;
  diagnostic_info?: {
    ipv4?: string | null;
    ipv6?: string | null;
    os?: string | null;
    mac?: string | null;
  };
  notes?: string;
  plan_sensor_index?: number | null;
  cad_id: string;
  bounding_box_filter: 'none' | 'cloud' | 'device';
}

// NOTE: trying new ts features, don't worry about it
type CoreObjectId<P extends string> = `${P}_${number}`;

export type AreaId = CoreObjectId<'are'>;
export type PlanSensorId = CoreObjectId<'psr'>;
export type ReferencePointId = CoreObjectId<'rep'>;
export type ReferenceRulerId = CoreObjectId<'rer'>;
export type PhotoGroupId = CoreObjectId<'ppg'>;
export type PhotoGroupPhotoId = CoreObjectId<'pph'>;

export function isAreaId(id: string): id is AreaId {
  return id.startsWith('are_');
}
export function isPlanSensorId(id: string): id is PlanSensorId {
  return id.startsWith('psr_');
}
export function isReferencePointId(id: string): id is ReferencePointId {
  return id.startsWith('rep_');
}
export function isReferenceRulerId(id: string): id is ReferenceRulerId {
  return id.startsWith('rer_');
}
export function isPhotoGroupId(id: string): boolean {
  return id.startsWith('ppg_');
}
export function isPhotoGroupPhotoId(id: string): boolean {
  return id.startsWith('pph_');
}

// This represents when a Plan is being updated and the object in question
//   does not yet have an ID from the database. This happens when you update a Plan
//   and have new objects that haven't been saved yet.
export type Unsaved<
  T extends
    | Area
    | PlanSensor
    | ReferencePoint
    | ReferenceRuler
    | SerializedPhotoGroup
> = Omit<T, 'id'>;

interface ReferencePoint {
  id: string;
  position_x_meters: number;
  position_y_meters: number;
  enabled: boolean;
}

interface ReferenceRuler {
  id: string;
  position_a_x_meters: number;
  position_a_y_meters: number;
  position_b_x_meters: number;
  position_b_y_meters: number;
  position_label_x_meters: number;
  position_label_y_meters: number;
  enabled: boolean;
}

// These are fields that are returned in responses, but NOT writable
type PlanReadOnlyFields =
  | 'id'
  | 'image_url'
  | 'source_pdf_url'
  | 'floor'
  | 'floor_id'
  | 'created_at'
  | 'updated_at';

// These are fields that are not returned in responses but are writable
type PlanWriteOnlyFields = 'image_key' | 'source_pdf_key';

// PlanUpdate allows any field except the read-only fields
// Also, it overrides the floorplan object lists to allow their as-yet-unsaved states
export type PlanUpdate = Partial<
  Omit<
    Plan,
    | PlanReadOnlyFields
    | 'areas'
    | 'plan_sensors'
    | 'reference_points'
    | 'reference_rulers'
  >
> & {
  areas: Array<Area | Unsaved<Area>>;
  plan_sensors: Array<PlanSensor | Unsaved<PlanSensor>>;
  reference_points: Array<ReferencePoint | Unsaved<ReferencePoint>>;
  reference_rulers: Array<ReferenceRuler | Unsaved<ReferenceRuler>>;
};

// Fields required to create a Plan
interface PlanCreateBase {
  // ID of Core Space with {type: 'floor'} eg. "spc_906277793787019303"
  floor_id: string;
  // S3 Key of uploaded floorplan image
  image_key: string;

  // Image dimensions and scale
  image_width_pixels: number;
  image_height_pixels: number;
  image_pixels_per_meter: number;

  // Floorplan origin
  origin_x_pixels: number;
  origin_y_pixels: number;

  // Measurement data
  measurement_point_a_x_pixels: number;
  measurement_point_a_y_pixels: number;
  measurement_point_b_x_pixels: number;
  measurement_point_b_y_pixels: number;
  measurement_computed_length_meters: number;
  measurement_user_entered_length_feet: string;
  measurement_user_entered_length_inches: string;
}

// When creating a plan with a PDF, there are additional fields
interface PlanCreateWithPDF extends PlanCreateBase {
  // S3 Key of uploaded source PDF document
  source_pdf_key: string;
  // Page number in the PDF that was used as floorplan image
  source_pdf_page: number;
}

export type PlanCreate = PlanCreateBase | PlanCreateWithPDF;

export type PlanImageSignedURLCreate = {
  // ID of Core Space with {type: 'floor'} eg. "spc_906277793787019303"
  floor_id: string;
  // File extension, eg. "png"
  ext: string;
  // MIME type of image
  content_type: string;
};

type PlanImageSignedURLResponse = {
  // Signed S3 URL to upload the image to
  signed_url: string;
  // This signed url can be used to GET the data uploaded via PUT to `signed_url`
  get_signed_url: string;
  key: string;
  content_type: string;
};

export function getUser(client: AxiosInstance) {
  return client.get<CoreUser>('v2/users/me');
}

function getPlans(client: AxiosInstance) {
  return client.get<Paginated<PlanSummary>>('v1/plans');
}

export async function getAllPlans(client: AxiosInstance) {
  const initialResponse = await getPlans(client);
  const plans = await rollupPagination(client, initialResponse);
  return plans;
}

export function getPlan(client: AxiosInstance, planId: string) {
  return client.get<PlanDetail>(`v1/plans/${planId}`);
}

export function postPlan(client: AxiosInstance, data: PlanCreate) {
  return client.post<PlanDetail>('v1/plans', data);
}

export function deletePlan(client: AxiosInstance, planId: string) {
  return client.delete<PlanDetail>(`v1/plans/${planId}`);
}

export function patchPlan(
  client: AxiosInstance,
  planId: string,
  data: Partial<PlanUpdate>
) {
  return client.patch<PlanDetail>(`v1/plans/${planId}`, data);
}

export function postPlanImageSignedURL(
  client: AxiosInstance,
  data: PlanImageSignedURLCreate
) {
  return client.post<PlanImageSignedURLResponse>('v1/plans/image-upload', data);
}

export function getSpace(client: AxiosInstance, spaceId: string) {
  return client.get<CoreSpace>(`v2/spaces/${spaceId}`);
}

function getSpaces(client: AxiosInstance) {
  return client.get<Paginated<CoreSpace>>('v2/spaces', {
    params: { page_size: 1000 },
  });
}

export async function getAllSpaces(client: AxiosInstance) {
  const initialResponse = await getSpaces(client);
  const spaces = await rollupPagination(client, initialResponse);
  return spaces;
}

async function rollupPagination<T>(
  client: AxiosInstance,
  initialResponse: AxiosResponse<Paginated<T>>
) {
  let response = initialResponse;

  const results: Array<T> = [];
  results.push(...response.data.results);

  while (response.data.next) {
    response = await client.get<Paginated<T>>(response.data.next);
    results.push(...response.data.results);
  }

  return results;
}

export function locateSensor(
  client: AxiosInstance,
  sensorSerialNumber: string
) {
  return client.post(`v2/sensors/${sensorSerialNumber}/locate?immediate=true`);
}

type SensorDiagnostics = {
  network_addresses: Array<{
    if: string;
    family: 'ipv4' | 'ipv6';
    address: string;
    mac: string;
  }>;
  last_heartbeat: string;
  current_status: SensorStatus;
  os: { VERSION_ID: string };
};

export function getSensorDiagnostics(
  client: AxiosInstance,
  sensorSerialNumber: string
): Promise<AxiosResponse<SensorDiagnostics>> {
  return client.get(`v2/sensors/${sensorSerialNumber}/diagnostics`);
}

type ImageUploadBody = {
  photo_id: string;
  upload_url: string;
};

export function getUploadImageUrl(
  client: AxiosInstance,
  planId: string,
  fileName: string,
  contentType: string
): Promise<AxiosResponse<ImageUploadBody>> {
  return client.post(`v1/plans/${planId}/photo-groups/upload`, {
    file_name: fileName,
    content_type: contentType,
  });
}

export function createPhotoGroup(
  client: AxiosInstance,
  planId: Plan['id'],
  photoGroup: PhotoGroup
): Promise<AxiosResponse> {
  return client.post(`v1/plans/${planId}/photo-groups`, {
    name: photoGroup.name,
    notes: photoGroup.notes,
    photo_ids: photoGroup.photos.map((photo) => photo.id),
    locked: photoGroup.locked,
    origin_x_pixels: photoGroup.position.x,
    origin_y_pixels: photoGroup.position.y,
  });
}

export function updatePhotoGroup(
  client: AxiosInstance,
  planId: Plan['id'],
  photoGroupId: PhotoGroup['id'],
  update: Partial<PhotoGroup>
): Promise<AxiosResponse> {
  return client.put(`v1/plans/${planId}/photo-groups/${photoGroupId}`, {
    name: update?.name,
    notes: update?.notes,
    photo_ids: update.photos
      ? update.photos.map((photo) => photo.id)
      : undefined,
    locked: update?.locked,
    origin_x_pixels: update?.position?.x,
    origin_y_pixels: update?.position?.y,
  });
}

export function deletePhotoGroup(
  client: AxiosInstance,
  planId: Plan['id'],
  photoGroupId: PhotoGroup['id']
): Promise<AxiosResponse> {
  return client.delete(`v1/plans/${planId}/photo-groups/${photoGroupId}`);
}

export function updatePhoto(
  client: AxiosInstance,
  planId: Plan['id'],
  photoGroupId: PhotoGroup['id'],
  photoId: PhotoGroupPhoto['id'],
  update: Partial<PhotoGroupPhoto>
): Promise<AxiosResponse> {
  return client.put(
    `v1/plans/${planId}/photo-groups/${photoGroupId}/photos/${photoId}`,
    {
      name: update?.name,
    }
  );
}

export function deletePhoto(
  client: AxiosInstance,
  planId: Plan['id'],
  photoGroupId: PhotoGroup['id'],
  photoId: PhotoGroupPhoto['id']
): Promise<AxiosResponse> {
  return client.delete(
    `v1/plans/${planId}/photo-groups/${photoGroupId}/photos/${photoId}`
  );
}

export const FloorplanAPI = {
  createFloorplan(client: AxiosInstance, data: PlanCreate) {
    return client.post<PlanDetail>('v2/floorplans', data);
  },
  imageUpload(client: AxiosInstance, data: PlanImageSignedURLCreate) {
    return client.post<PlanImageSignedURLResponse>(
      'v2/floorplans/image-upload',
      data
    );
  },
  createAndProcessDXF(
    client: AxiosInstance,
    planId: PlanDetail['id'],
    uploadedDXFKey: string
  ): Promise<AxiosResponse<PlanDXF>> {
    return client.post(`v2/floorplans/${planId}/dxfs/process`, {
      uploaded_dxf_key: uploadedDXFKey,
    });
  },
  getDXF(
    client: AxiosInstance,
    planId: PlanDetail['id'],
    dxfId: PlanDXF['id']
  ): Promise<AxiosResponse<PlanDXF>> {
    return client.get(`v2/floorplans/${planId}/dxfs/${dxfId}`);
  },
  updateDXF(
    client: AxiosInstance,
    planId: PlanDetail['id'],
    dxfId: PlanDXF['id'],
    dxf: Partial<PlanDXF>
  ): Promise<AxiosResponse> {
    return client.patch(`v2/floorplans/${planId}/dxfs/${dxfId}`, dxf);
  },
  updateAndReprocessDXF(
    client: AxiosInstance,
    planId: PlanDetail['id'],
    dxfId: PlanDXF['id'],
    options: PlanDXF['options']
  ): Promise<AxiosResponse> {
    return client.put(`v2/floorplans/${planId}/dxfs/${dxfId}/reprocess`, {
      options,
    });
  },
  updatePlanActiveDXFId(
    client: AxiosInstance,
    planId: PlanDetail['id'],
    activeDXFId: PlanDXF['id'] | null
  ): Promise<AxiosResponse> {
    return client.patch(`v2/floorplans/${planId}`, {
      active_dxf_id: activeDXFId,
    });
  },
  createExport(
    client: AxiosInstance,
    planId: PlanDetail['id'],
    exportParams: Partial<PlanExport> = {}
  ): Promise<AxiosResponse<PlanExport>> {
    return client.post(`v2/floorplans/${planId}/exports`, exportParams);
  },

  updateCeilingRaster(
    client: AxiosInstance,
    planId: Plan['id'],
    key: string | null,
    positionX?: number,
    positionY?: number,
    angleDegrees?: number,
    notes?: string,
    opacityPercent?: number,
    minHeightLimitMeters?: number,
    maxHeightLimitMeters?: number,
    pointCloudTransformationMatrix?: Matrix,
    pointCloudTransformationSteps?: string
  ): Promise<AxiosResponse> {
    return client.put(`v2/floorplans/${planId}/ceiling-raster`, {
      ceiling_raster_key: key,
      ceiling_raster_floorplan_origin_x: positionX,
      ceiling_raster_floorplan_origin_y: positionY,
      ceiling_raster_floorplan_angle_degrees: angleDegrees,
      ceiling_raster_notes: notes,
      ceiling_raster_opacity_percent: opacityPercent,
      ceiling_raster_min_height_limit_meters: minHeightLimitMeters,
      ceiling_raster_max_height_limit_meters: maxHeightLimitMeters,
      point_cloud_transformation_matrix: pointCloudTransformationMatrix,
      point_cloud_transformation_steps: pointCloudTransformationSteps,
    });
  },
};
