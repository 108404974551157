import React, { MouseEventHandler } from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { ViewportCoordinates } from 'lib/geometry';
import { css } from '@emotion/react';

type AreaProps = {
  top: number;
  left: number;
  height: number;
  width: number;
  backgroundColor?: string;
  isCircular?: boolean;

  isMuted: boolean;
  isSelected: boolean;
  isHovered: boolean;

  // if any filter is hovered, not necessarily one that applies to this space
  isAnyFilterHovered: boolean;

  onOver: () => void;
  onOut: () => void;
  onSelect: MouseEventHandler<SVGRectElement>;
};

export const Area: React.FC<AreaProps> = ({
  top,
  left,
  height,
  width,
  backgroundColor,
  isCircular = false,
  isSelected,
  isHovered,
  isMuted,
  isAnyFilterHovered,
  onOver,
  onOut,
  onSelect,
}) => {
  const isActive = isSelected || isHovered;

  const opacity = isAnyFilterHovered
    ? 0.4
    : isActive
    ? 0.5
    : isMuted
    ? 0.2
    : 0.3;

  return (
    <rect
      x={left}
      y={top}
      width={width}
      height={height}
      rx={isCircular ? width / 2 : 4}
      stroke={isActive ? dust.Blue400 : 'rgba(0, 0, 0, 0)'}
      strokeWidth={2}
      fill={backgroundColor}
      fillOpacity={opacity}
      style={{ cursor: 'pointer' }}
      onMouseOver={onOver}
      onMouseOut={onOut}
      onClick={onSelect}
    />
  );
};

type AreaPolygonalProps = {
  vertices: Array<ViewportCoordinates>;
  backgroundColor?: string;

  isMuted: boolean;
  isSelected: boolean;
  isHovered: boolean;

  // if any filter is hovered, not necessarily one that applies to this space
  isAnyFilterHovered: boolean;

  onOver: () => void;
  onOut: () => void;
  onSelect: MouseEventHandler<SVGPathElement>;
};

export const AreaPolygonal: React.FC<AreaPolygonalProps> = ({
  vertices,
  backgroundColor,
  isSelected,
  isHovered,
  isMuted,
  isAnyFilterHovered,
  onOver,
  onOut,
  onSelect,
}) => {
  const isActive = isSelected || isHovered;

  const opacity = isAnyFilterHovered
    ? 0.4
    : isActive
    ? 0.5
    : isMuted
    ? 0.2
    : 0.3;

  return (
    <path
      d={`
        M${vertices[0].x},${vertices[0].y}
        ${vertices
          .slice(1)
          .map((v) => `L${v.x},${v.y}`)
          .join(' ')}
        L${vertices[0].x},${vertices[0].y}
      `}
      stroke={isActive ? dust.Blue400 : 'rgba(0, 0, 0, 0)'}
      strokeWidth={2}
      fill={backgroundColor}
      fillOpacity={opacity}
      style={{ cursor: 'pointer' }}
      onMouseOver={onOver}
      onMouseOut={onOut}
      onClick={onSelect}
    />
  );
};

type AreaLabelProps = {
  position: ViewportCoordinates;
  anchor?: 'center' | 'topLeft';
  backgroundColor?: string;
};

export const AreaLabel: React.FC<AreaLabelProps> = ({
  position,
  anchor = 'topLeft',
  backgroundColor,
  children,
}) => (
  <div
    css={css`
      position: absolute;
      top: ${position.y}px;
      left: ${position.x}px;
      transform: ${anchor === 'center' ? 'translate(-50%, -50%)' : 'none'};
      border-radius: 4px;
      padding: 4px;
      // account for left and right spacing
      max-width: calc(100% - 4px);
      width: max-content;
      background-color: ${backgroundColor};
      pointer-events: none;
    `}
  >
    <span
      css={css`
        // https://css-tricks.com/almanac/properties/l/line-clamp/
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: ${dust.TextScale1};
        font-weight: ${dust.FontWeightBold};
        color: ${dust.White};
        user-select: none;
      `}
    >
      {children}
    </span>
  </div>
);
