import { CoreSpace } from '@densityco/lib-api-types';

import {
  NewSpaceFunction,
  spaceFunctionToNewSpaceFunction,
} from './space-functions';

// match any of function AND any of labels
export const satisfyFilters = (
  spaceFunctions: NewSpaceFunction[],
  labelIds: string[],
  space: CoreSpace
) => {
  let satisfyFunctionFilter = false;
  if (!spaceFunctions.length) {
    satisfyFunctionFilter = true;
  } else {
    satisfyFunctionFilter = spaceFunctions.some((spaceFunction) =>
      space.function
        ? spaceFunctionToNewSpaceFunction(space.function) === spaceFunction
        : false
    );
  }

  let satisfyLabelFilter = false;
  if (!labelIds.length) {
    satisfyLabelFilter = true;
  } else {
    satisfyLabelFilter = labelIds.some((labelId) =>
      space.labels.some((label) => label.id === labelId)
    );
  }

  return satisfyFunctionFilter && satisfyLabelFilter;
};
