import { Fragment, useCallback } from 'react';
import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { Action } from './actions';
import { ReferencePoint, ReferenceRuler, State } from './state';
import { FloorplanRulerGraphic } from './floorplan-ruler-graphic';

import {
  FloorplanCoordinates,
  ViewportCoordinates,
  ViewportVector,
} from 'lib/geometry';
import { Floorplan } from 'lib/floorplan';
import { LengthUnit, displayLength } from 'lib/units';
import { Viewport } from 'lib/viewport';
import { addDragListener } from 'lib/drag';
import EndpointGraphic from 'components/endpoint-graphic';
import { MetricLabel } from 'components/metric-label';

const ENDPOINT_GRAPHIC_RADIUS = 8;
const REFERENCE_DISABLED_OPACITY = 0.5;

const ReferenceLines: React.FunctionComponent<{
  referencePosition: ViewportCoordinates;
  sensorPosition: ViewportCoordinates;
  displayUnit: LengthUnit;
  floorplan: Floorplan;
  viewport: Viewport;
}> = ({
  referencePosition,
  sensorPosition,
  displayUnit,
  floorplan,
  viewport,
}) => {
  const floorplanVector = new ViewportVector(
    sensorPosition.x - referencePosition.x,
    sensorPosition.y - referencePosition.y
  )
    .toImageVector(viewport)
    .toFloorplanVector(floorplan);

  const xLabel = displayLength(Math.abs(floorplanVector.x), displayUnit);
  const yLabel = displayLength(Math.abs(floorplanVector.y), displayUnit);

  return (
    <Fragment>
      <line
        x1={referencePosition.x}
        y1={referencePosition.y}
        x2={sensorPosition.x}
        y2={referencePosition.y}
        stroke={colors.yellow}
        strokeWidth={1}
        strokeDasharray={'2, 2'}
      />
      <line
        x1={sensorPosition.x}
        y1={referencePosition.y}
        x2={sensorPosition.x}
        y2={sensorPosition.y}
        stroke={colors.yellow}
        strokeWidth={2}
      />
      <line
        x1={referencePosition.x}
        y1={referencePosition.y}
        x2={referencePosition.x}
        y2={sensorPosition.y}
        stroke={colors.yellow}
        strokeWidth={1}
        strokeDasharray={'2, 2'}
      />
      <line
        x1={referencePosition.x}
        y1={sensorPosition.y}
        x2={sensorPosition.x}
        y2={sensorPosition.y}
        stroke={colors.yellow}
        strokeWidth={2}
      />
      <MetricLabel
        labelText={xLabel}
        centerPoint={ViewportCoordinates.create(
          sensorPosition.x - (sensorPosition.x - referencePosition.x) / 2,
          sensorPosition.y
        )}
        color={colors.yellow}
      />
      <MetricLabel
        labelText={yLabel}
        centerPoint={ViewportCoordinates.create(
          sensorPosition.x,
          sensorPosition.y - (sensorPosition.y - referencePosition.y) / 2
        )}
        color={colors.yellow}
      />
    </Fragment>
  );
};

export const ReferencePointGraphic: React.FunctionComponent<{
  reference: ReferencePoint;
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ reference, state, dispatch }) => {
  const position = FloorplanCoordinates.toViewportCoordinates(
    reference.position,
    state.floorplan,
    state.viewport
  );
  const focusedSensor = State.getFocusedSensor(state);
  const isHighlighted = State.isReferenceHighlighted(state, reference.id);
  const color = isHighlighted ? colors.yellowDark : colors.yellow;

  const onMouseEnter = useCallback(() => {
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'reference',
      itemId: reference.id,
    });
  }, [reference.id, dispatch]);

  const onMouseLeave = useCallback(() => {
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'reference',
      itemId: reference.id,
    });
  }, [reference.id, dispatch]);

  const onMouseDown = useCallback(
    (evt: React.MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      const { clientX, clientY } = evt;

      dispatch({
        type: 'item.graphic.mousedown',
        itemType: 'reference',
        itemId: reference.id,
        itemPosition: position,
        clientX,
        clientY,
      });
    },
    [reference.id, position, dispatch]
  );

  return (
    <Fragment>
      {reference.enabled && focusedSensor ? (
        <ReferenceLines
          referencePosition={position}
          sensorPosition={FloorplanCoordinates.toViewportCoordinates(
            focusedSensor.position,
            state.floorplan,
            state.viewport
          )}
          displayUnit={state.displayUnit}
          floorplan={state.floorplan}
          viewport={state.viewport}
        />
      ) : null}
      <g
        opacity={reference.enabled ? 1 : REFERENCE_DISABLED_OPACITY}
        onMouseDown={onMouseDown}
      >
        <EndpointGraphic
          position={position}
          radius={8}
          color={color}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseDown={onMouseDown}
        />
      </g>
    </Fragment>
  );
};

export const ReferenceRulerGraphic: React.FunctionComponent<{
  reference: ReferenceRuler;
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ reference, state, dispatch }) => {
  const positionA = FloorplanCoordinates.toViewportCoordinates(
    reference.positionA,
    state.floorplan,
    state.viewport
  );
  const positionB = FloorplanCoordinates.toViewportCoordinates(
    reference.positionB,
    state.floorplan,
    state.viewport
  );

  const distanceLabelPosition = FloorplanCoordinates.toViewportCoordinates(
    reference.distanceLabelPosition,
    state.floorplan,
    state.viewport
  );

  const isHighlighted = State.isReferenceHighlighted(state, reference.id);
  const color = isHighlighted ? colors.yellowDark : colors.yellow;

  const floorplanX = reference.positionB.x - reference.positionA.x;
  const floorplanY = reference.positionB.y - reference.positionA.y;
  const distanceMeters = Math.hypot(floorplanX, floorplanY);
  const distanceText = displayLength(distanceMeters, state.displayUnit);

  const onMouseDownLine = useCallback(
    (evt: React.MouseEvent<SVGLineElement>) => {
      evt.preventDefault();
      evt.stopPropagation();

      const { clientX, clientY } = evt;

      const centerPoint = {
        ...positionA,
        x: (positionA.x + positionB.x) / 2,
        y: (positionA.y + positionB.y) / 2,
      };

      dispatch({
        type: 'item.graphic.mousedown',
        itemType: 'reference',
        itemId: reference.id,
        itemPosition: centerPoint,
        clientX,
        clientY,
      });
    },
    [reference.id, positionA, positionB, dispatch]
  );

  const onMouseDownEndPoint = useCallback(
    (position: 'positionA' | 'positionB', evt: React.MouseEvent) => {
      evt.stopPropagation();

      dispatch({
        type: 'reference.rulerPosition.dragstart',
        id: reference.id,
        position,
      });

      const unregister = addDragListener(
        evt.clientX,
        evt.clientY,
        (dx, dy, evt) => {
          dispatch({
            type: 'reference.rulerPosition.dragmove',
            id: reference.id,
            position,
            dx,
            dy,
            shiftKey: evt.shiftKey,
          });
        },
        () => {
          dispatch({
            type: 'reference.rulerPosition.dragend',
            id: reference.id,
          });
        }
      );

      return () => {
        // NOTE: This is to guard against one of the dependencies changing + the resulting rerender
        // causing the dragging process to get into a broken state. It's probably unlikely that this
        // will happen though.
        unregister();
      };
    },
    [reference.id, dispatch]
  );

  const onMouseDownDimension = useCallback(
    (evt: React.MouseEvent<SVGLineElement>) => {
      evt.preventDefault();
      evt.stopPropagation();

      dispatch({
        type: 'reference.distanceLabelPosition.dragstart',
        id: reference.id,
      });

      const unregister = addDragListener(
        evt.clientX,
        evt.clientY,
        (dx, dy, evt) => {
          dispatch({
            type: 'reference.distanceLabelPosition.dragmove',
            id: reference.id,
            dx,
            dy,
            shiftKey: evt.shiftKey,
          });
        },
        () => {
          dispatch({
            type: 'reference.rulerPosition.dragend',
            id: reference.id,
          });
        }
      );

      return () => {
        // NOTE: This is to guard against one of the dependencies changing + the resulting rerender
        // causing the dragging process to get into a broken state. It's probably unlikely that this
        // will happen though.
        unregister();
      };
    },
    [reference.id, dispatch]
  );

  const centerPoint = ReferenceRuler.calculateCenterPoint(reference);

  const isDistanceLockedToCenter = ReferenceRuler.isDistanceLockedToCenter(
    state.floorplan,
    state.viewport,
    centerPoint,
    reference.distanceLabelPosition
  );

  return (
    <g data-cy="reference-ruler-graphic">
      <FloorplanRulerGraphic
        positionA={positionA}
        positionB={positionB}
        color={color}
        opacity={reference.enabled ? 1 : REFERENCE_DISABLED_OPACITY}
        endpointRadius={ENDPOINT_GRAPHIC_RADIUS}
        distanceText={distanceText}
        distanceLabelPosition={distanceLabelPosition}
        showDistanceLabel={reference.enabled}
        onMouseDownLine={onMouseDownLine}
        onMouseDownEndPoint={onMouseDownEndPoint}
        onMouseDownDimension={onMouseDownDimension}
        showDistanceLeaderLine={!isDistanceLockedToCenter}
      />
    </g>
  );
};
