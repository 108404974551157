import * as React from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import moment from 'moment-timezone';
import { css } from '@emotion/react';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { toggleHeatmapEnabled } from 'redux/features/analysis/analysis-slice';
import { HEATMAP_COLORS } from './heatmap-constants';
import { useHeatmap } from './heatmap-context';
import Switch from 'components/switch/switch';

const HeatmapToolbarV2: React.FC = () => {
  const dispatch = useAppDispatch();

  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );

  const { minDisplayed, maxDisplayed, frameContours } = useHeatmap();

  const showKey = frameContours.length > 0;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        height: 40px;
        padding: 0 8px;
        border-radius: ${dust.Radius100};
        border: 1px solid ${dust.Gray200};
      `}
    >
      <span
        css={css`
          font-size: ${dust.TextScale4};
          font-weight: ${dust.FontWeightBold};
        `}
      >
        Heatmap
      </span>

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Switch
          isChecked={heatmapEnabled}
          onChange={() => {
            dispatch(toggleHeatmapEnabled());
          }}
        />
      </div>

      {showKey ? (
        <>
          <div
            css={css`
              width: 0px;
              border: 1px solid ${dust.Gray200};
              height: 16px;
            `}
          ></div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;
            `}
          >
            <p
              css={css`
                font-size: ${dust.TextScale3};
                font-weight: ${dust.FontWeightMedium};
              `}
            >
              {moment
                .duration(minDisplayed)
                .humanize()
                .replace('a few seconds', '< 1s')}
            </p>

            <div
              css={css`
                display: flex;
                flex-direction: row;
              `}
            >
              {HEATMAP_COLORS.map((x, i) =>
                2 < i && i < HEATMAP_COLORS.length ? (
                  <div
                    key={i}
                    style={{
                      backgroundColor: HEATMAP_COLORS[i],
                      height: 8,
                      width: 8,
                    }}
                  />
                ) : null
              )}
            </div>

            <p
              css={css`
                font-size: ${dust.TextScale3};
                font-weight: ${dust.FontWeightMedium};
              `}
            >
              {'~ '}
              {moment
                .duration(maxDisplayed)
                .humanize()
                .replace('a few seconds', '1s')
                .replace('a minute', '1m')
                .replace('an hour', '1h')
                .replace(/ seconds?/, 's')
                .replace(/ minutes?/, 'm')
                .replace(/ hours?/, 'h')
                .replace(/ days?/, 'd')}
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default HeatmapToolbarV2;
