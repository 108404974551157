import { DayOfWeek } from '@densityco/lib-common-types';
import { CompatibleDateValue } from '@densityco/ui/dist/cjs/src/date-picker';
import { timeFilterToString } from '@densityco/lib-time-helpers';
import moment from 'moment';

export type TimeOfDay = {
  hour: number;
  minute: number;
  second: number;
  millisecond: number;
};

// Eventually these types and utilities could be standardized in something like https://github.com/DensityCo/lib-common-types
// and in https://github.com/DensityCo/lib-common-helpers
export type CompatibleDateRange = [CompatibleDateValue, CompatibleDateValue];

function extractMomentFromDateValue(
  value: CompatibleDateValue,
  timeZone: string
) {
  if (moment.isMoment(value)) {
    return value.clone();
  }
  return moment.tz(value, timeZone);
}

export function isDayInRange(
  dates: CompatibleDateRange,
  days: Array<DayOfWeek>,
  timeZone: string
) {
  const [startDateValue, endDateValue] = dates;

  const startMoment = extractMomentFromDateValue(startDateValue, timeZone);
  const endMoment = extractMomentFromDateValue(endDateValue, timeZone);

  // Determine if ANY days in this range are included in the filter
  let nextMoment = startMoment.clone();
  while (nextMoment < endMoment) {
    if (days.includes(nextMoment.format('dddd') as DayOfWeek)) {
      return true;
    }
    nextMoment.add(1, 'day');
  }
  return false;
}

export function computeHoursPerDay(
  hours: number,
  dates: CompatibleDateRange,
  days: Array<DayOfWeek>,
  timeZone: string
) {
  const [startDateValue, endDateValue] = dates;

  const startMoment = extractMomentFromDateValue(startDateValue, timeZone);
  const endMoment = extractMomentFromDateValue(endDateValue, timeZone);

  // Determine how many days in this range are included in the filter
  // So we know what to divide for for "hours per day"
  let totalDays = 0;
  let nextMoment = startMoment.clone();
  while (nextMoment < endMoment) {
    if (days.includes(nextMoment.format('dddd') as DayOfWeek)) {
      totalDays++;
    }
    nextMoment.add(1, 'day');
  }

  return hours ? hours / (totalDays || 1) : 0;
}

export const getTimeFilterString = (
  filterStart: TimeOfDay,
  filterEnd: TimeOfDay,
  filterDays: DayOfWeek[]
) => {
  return timeFilterToString({
    start: moment.duration(
      filterStart.hour * 60 + filterStart.minute,
      'minutes'
    ),
    end: moment.duration(filterEnd.hour * 60 + filterEnd.minute, 'minutes'),
    days: filterDays,
  });
};
