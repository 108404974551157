import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';

import {
  labelsAdapter,
  labelsSelectors,
  patchSpacesState,
  scopedSpacesSelectors,
  spacesAdapter,
} from './spaces-slice';
import { asyncFetchLabelsThunk } from './async-fetch-labels-thunk';

import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { getSpacesPatches } from 'lib/patches';
import { CoreSpaceLabel } from '@densityco/lib-api-types';

export const asyncSaveSpaceLabelsThunk = createAsyncThunk<
  // return value
  void,
  // parameters
  {
    spaceId: string;
    addedLabelIds: string[];
    removedLabelIds: string[];
    createdLabelNames: string[];
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'spaces/asyncSaveSpaceLabels',
  async (args, thunkApi) => {
    const { auth } = thunkApi.getState();
    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    if (args.removedLabelIds.length) {
      await auth.densityAPIClient.delete(`/app/spaces/${args.spaceId}/labels`, {
        params: {
          label_ids: args.removedLabelIds.join(','),
        },
      });

      const [patches] = getSpacesPatches(
        thunkApi.getState().spaces,
        (draft) => {
          const space = scopedSpacesSelectors.selectById(draft, args.spaceId);
          if (!space) {
            return;
          }

          spacesAdapter.updateOne(draft.spaces, {
            id: args.spaceId,
            changes: {
              labels: space.labels.filter(
                (l: CoreSpaceLabel) => !args.removedLabelIds.includes(l.id)
              ),
            },
          });
        }
      );

      thunkApi.dispatch(patchSpacesState(patches));
    }

    if (args.addedLabelIds.length || args.createdLabelNames.length) {
      const response = await auth.densityAPIClient.post(
        `/app/spaces/${args.spaceId}/labels`,
        [
          ...args.addedLabelIds.map((id) => ({ id })),
          ...args.createdLabelNames.map((name) => ({ name })),
        ]
      );

      const addedOrCreatedLabels: CoreSpaceLabel[] = response.data;

      const existingLabels = labelsSelectors.selectEntities(
        thunkApi.getState()
      );

      const addedLabels = addedOrCreatedLabels.filter(
        (l) => existingLabels[l.id]
      );
      const createdLabels = addedOrCreatedLabels.filter(
        (l) => !existingLabels[l.id]
      );

      const [patches] = getSpacesPatches(
        thunkApi.getState().spaces,
        (draft) => {
          // upsert to update existing ones and also add new ones
          labelsAdapter.upsertMany(
            draft.labels,
            addedLabels.concat(createdLabels)
          );

          const space = scopedSpacesSelectors.selectById(draft, args.spaceId);
          if (!space) {
            return;
          }

          // add addedLabels, createdLabels to space.labels
          spacesAdapter.updateOne(draft.spaces, {
            id: args.spaceId,
            changes: {
              labels: space.labels.concat(addedLabels).concat(createdLabels),
            },
          });
        }
      );

      thunkApi.dispatch(patchSpacesState(patches));
    }

    // TODO(wuweiweiwu): also need to get data back from backend to see if the label is orphaned
    // so we can update the state without a full refetch
    // Refresh labels from server, in case any have been removed
    thunkApi.dispatch(asyncFetchLabelsThunk());
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
