import * as React from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';

import { NO_VALUE_PLACEHOLDER } from 'lib/text';

type ValueLabelProps = {
  label: string;
  value?: string | number;
};

const ValueLabel: React.FC<ValueLabelProps> = ({ label, value }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <span
        css={css`
          font-size: ${dust.TextScale2};
          font-weight: ${dust.FontWeightBold};
          color: ${dust.Gray400};
        `}
      >
        {label}
      </span>
      <span
        css={css`
          font-size: ${dust.TextScale5};
          font-weight: ${dust.FontWeightMedium};
        `}
      >
        {value ? value : NO_VALUE_PLACEHOLDER}
      </span>
    </div>
  );
};

export default ValueLabel;
