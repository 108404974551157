import { ImageVector, ViewportCoordinates } from './geometry';

export interface Viewport {
  width: number;
  height: number;
  zoom: number;
  top: number;
  left: number;
}

interface BoxSize {
  width: number;
  height: number;
}

export namespace Viewport {
  export interface Specimen extends BoxSize {}

  export function getSVGTransformString(viewport: Readonly<Viewport>) {
    const scale = `scale(${viewport.zoom}, ${viewport.zoom})`;
    const translate = `translate(${-viewport.left}, ${-viewport.top})`;
    return `${scale} ${translate}`;
  }

  export function getMinZoom<V extends BoxSize, S extends Specimen>(
    viewport: Readonly<V>,
    itemToFit: Readonly<S>
  ) {
    const widthFactorNeeded = viewport.width / itemToFit.width;
    const heightFactorNeeded = viewport.height / itemToFit.height;
    return Math.min(widthFactorNeeded, heightFactorNeeded);
  }

  export function zoomToFit<V extends BoxSize, S extends Specimen>(
    viewport: Readonly<V>,
    itemToFit: Readonly<S>
  ): Viewport {
    const zoom = getMinZoom(viewport, itemToFit);
    const widthDifference = viewport.width - zoom * itemToFit.width;
    const heightDifference = viewport.height - zoom * itemToFit.height;
    const top = -(heightDifference / zoom) / 2;
    const left = -(widthDifference / zoom) / 2;
    return {
      ...viewport,
      zoom,
      top,
      left,
    };
  }

  export function zoomToFitWithSidebar<V extends BoxSize, S extends Specimen>(
    viewport: Readonly<V>,
    itemToFit: Readonly<S>,
    sidebarWidth: number
  ) {
    // Calculate zoom based on a viewport that subtracts the sidebar width
    const adjustedViewport: BoxSize = {
      width: viewport.width - sidebarWidth,
      height: viewport.height,
    };
    const zoom = getMinZoom(adjustedViewport, itemToFit);

    const widthDifference = adjustedViewport.width - zoom * itemToFit.width;
    const heightDifference = adjustedViewport.height - zoom * itemToFit.height;
    const top = -(heightDifference / zoom) / 2;
    const left = -(widthDifference / zoom) / 2;
    const adjustedLeft = left - sidebarWidth / zoom;
    return {
      ...viewport,
      zoom,
      top,
      left: adjustedLeft,
    };
  }

  export function zoomWithMouseWheel(
    viewport: Readonly<Viewport>,
    position: ViewportCoordinates,
    mouseDeltaY: number
  ): Viewport {
    // limit scroll wheel sensitivity for mouse users
    const limit = 8;
    const scrollDelta = Math.max(-limit, Math.min(limit, mouseDeltaY));

    const nextZoomFactor = viewport.zoom + viewport.zoom * scrollDelta * -0.01;

    const targetX = viewport.left + position.x / viewport.zoom;
    const targetY = viewport.top + position.y / viewport.zoom;

    const top = targetY - position.y / nextZoomFactor;
    const left = targetX - position.x / nextZoomFactor;

    return {
      ...viewport,
      zoom: nextZoomFactor,
      top,
      left,
    };
  }

  export function dragWithMouse(
    viewport: Readonly<Viewport>,
    mouseDeltaX: number,
    mouseDeltaY: number
  ): Viewport {
    const { zoom, top, left } = viewport;
    return {
      ...viewport,
      top: top - mouseDeltaY / zoom,
      left: left - mouseDeltaX / zoom,
    };
  }

  export function pan(viewport: Readonly<Viewport>, delta: ImageVector) {
    return {
      ...viewport,
      top: viewport.top + delta.y,
      left: viewport.left + delta.x,
    };
  }
}
