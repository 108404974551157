import { useState, useRef, useEffect } from 'react';
import * as React from 'react';
import { AxiosInstance } from 'axios';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { Icons, Checkbox } from '@densityco/ui';
import { Blue400 } from '@density/dust/dist/tokens/dust.tokens';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';

import { FixMe } from 'types/fixme';
import Button from 'components/button';
import Tooltip from 'components/tooltip';
import HorizontalForm from 'components/horizontal-form';
import LoadingProgressCircle from 'components/loading-progress-circle';

import { PlanDetail, FloorplanAPI } from 'lib/api';

const EXPORT_STEP_WORKFLOW_ORDER = [
  'fetching',
  'processing',
  'uploading',
  'complete',
  'saving',
  'saving-complete',
];

const ExportPanel: React.FunctionComponent<{
  state: State;
  floorName: string;
  client: AxiosInstance;
  plan: PlanDetail;
  dispatch: React.Dispatch<Action>;
}> = ({ state, client, floorName, plan, dispatch }) => {
  const [panelOpen, setPanelOpen] = useState<boolean>(false);
  const [includeCoverage, setIncludeCoverage] = useState<boolean>(false);

  const fileName = floorName.toLowerCase().replace(/\W/g, '-');
  const fileNameWithExtension = `${fileName}.dxf`;

  const fileHandle = useRef<FixMe | null>(null);

  // When the dxf has completed processing, save it to the local filesystem
  useEffect(() => {
    if (!state.activeExport || state.activeExport.status !== 'complete') {
      return;
    }

    const dxfExportUrl = state.activeExport.exported_object_url;
    if (!dxfExportUrl) {
      return;
    }

    dispatch({ type: 'export.saving' });

    if (fileHandle.current) {
      // More info: https://web.dev/file-system-access
      fileHandle.current
        .createWritable()
        .then(async (writable: FixMe) => {
          let response;
          try {
            response = await fetch(dxfExportUrl);
          } catch (err) {
            toast.error(
              'Cannot export plan: failed to make request to server!'
            );
            return;
          }
          if (!response.ok) {
            toast.error(
              'Cannot export plan: failed to fetch dxf data from server!'
            );
            return;
          }
          if (!response.body) {
            toast.error(
              'Cannot export plan: dxf data from server was missing!'
            );
            return;
          }

          // More about streaming fetch responses:
          // https://developer.chrome.com/articles/fetch-streaming-requests/
          const reader = response.body.getReader();
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            await writable.write(value);
          }
          await writable.close();

          dispatch({ type: 'export.savingComplete' });
        })
        .catch((err: Error) => {
          console.error('Error downloading file:', err);
          dispatch({ type: 'export.savingFailed' });
        });
    } else {
      // If the file system access api isn't available, then download the file like
      // normal
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = dxfExportUrl;
        link.download = fileNameWithExtension;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(dxfExportUrl);
      }

      dispatch({ type: 'export.savingComplete' });
    }
  }, [state.activeExport, fileNameWithExtension, dispatch]);

  // Once the export is complete, close the panel
  useEffect(() => {
    if (state.activeExport && state.activeExport.status === 'saving-complete') {
      setPanelOpen(false);
      toast.success('Plan exported!');
    }
  }, [state.activeExport, dispatch]);

  // If the export fails, show a toast
  useEffect(() => {
    if (state.activeExport && state.activeExport.status === 'error') {
      toast.error('Plan export failed!');
    }
  }, [state.activeExport, dispatch]);

  // When the panel is closed, reset the active export
  useEffect(() => {
    if (!panelOpen) {
      setIncludeCoverage(false);
      dispatch({ type: 'export.reset' });
    }
  }, [panelOpen, dispatch]);

  const disabled = plan.active_dxf_id === null;

  return (
    <div className={styles.exportPanel}>
      <Tooltip
        contents={
          disabled
            ? 'Please import a DXF to enable exporting as DXF'
            : 'Export DXF'
        }
        placement="bottom"
        target={
          <Button
            onClick={() => setPanelOpen(!panelOpen)}
            trailingIcon={
              <Icons.Download height={18} width={18} color="currentColor" />
            }
            disabled={disabled}
            size="medium"
            type="outlined"
            data-cy="export-panel-button"
          />
        }
      />
      <div
        className={classnames(styles.exportMenu, {
          [styles.open]: panelOpen,
        })}
      >
        <div className={styles.exportPreview}>
          <div
            className={styles.exportPreviewImage}
            style={
              state.floorplanImage
                ? { backgroundImage: `url(${state.floorplanImage.src})` }
                : {}
            }
          />
          <div className={styles.exportPreviewDetails}>
            <div
              className={styles.exportPreviewTitle}
              data-cy="export-panel-status"
              data-cy-status={
                state.activeExport ? state.activeExport.status : null
              }
            >
              {state.activeExport
                ? {
                    requesting: 'Loading...',
                    created: 'Queueing...',
                    fetching: 'Downloading...',
                    processing: 'Processing...',
                    uploading: 'Uploading...',
                    complete: 'Generated',
                    error: 'Failed',
                    'request-failed': 'Failed',
                    saving: 'Saving...',
                    'saving-complete': 'Complete',
                    'saving-failed': 'Failed',
                  }[state.activeExport.status] || 'Floorplan'
                : 'Floorplan'}
              {state.activeExport &&
              EXPORT_STEP_WORKFLOW_ORDER.includes(state.activeExport.status) ? (
                <LoadingProgressCircle
                  numerator={
                    EXPORT_STEP_WORKFLOW_ORDER.indexOf(
                      state.activeExport.status
                    ) + 1
                  }
                  denominator={EXPORT_STEP_WORKFLOW_ORDER.length}
                />
              ) : null}
              {!state.activeExport ? (
                <div className={styles.exportPreviewBadge}>DXF</div>
              ) : null}
            </div>
            <div className={styles.exportPreviewName}>
              <div className={styles.prefix}>{fileName.slice(0, -3)}</div>
              <div className={styles.suffix}>{fileName.slice(-3)}.dxf</div>
            </div>
          </div>
        </div>

        <div
          className={styles.exportControls}
          data-cy="export-panel-include-coverage"
        >
          <Checkbox
            label="Include Coverage Ellipses"
            checked={includeCoverage}
            onChange={(event) =>
              setIncludeCoverage(event.currentTarget.checked)
            }
            disabled={state.activeExport !== null}
            color={Blue400}
          />
        </div>

        <div className={styles.exportPanelActionBar}>
          <HorizontalForm size="small">
            {state.activeExport ? (
              <Button disabled size="medium" data-cy="export-panel-submit">
                {state.activeExport.status === 'request-failed' ||
                state.activeExport.status === 'error'
                  ? 'Export'
                  : 'Loading...'}
              </Button>
            ) : (
              <Button
                size="medium"
                onClick={async () => {
                  fileHandle.current = null;

                  // If it's available, use the new FileSystemAccess API
                  //
                  // This is only available in chrome, but allows one to make a "save dialog" and
                  // write to a local file on disk.
                  if ((window as FixMe).showSaveFilePicker) {
                    // More info: https://web.dev/file-system-access
                    fileHandle.current = await (
                      window as FixMe
                    ).showSaveFilePicker({
                      id: 'densityDXFExport',
                      startIn: 'downloads',
                      suggestedName: fileNameWithExtension,
                    });
                  }

                  // When the popup is opened, start the export process
                  dispatch({ type: 'export.begin' });

                  let response;
                  try {
                    response = await FloorplanAPI.createExport(
                      client,
                      plan.id,
                      {
                        options: {
                          include_coverage: includeCoverage,
                        },
                      }
                    );
                  } catch (err) {
                    console.error(err);
                    toast.error('Error generating export!');
                    dispatch({ type: 'export.beginFailed' });
                    return;
                  }

                  dispatch({
                    type: 'export.update',
                    planExport: response.data,
                  });
                }}
                data-cy="export-panel-submit"
              >
                Export
              </Button>
            )}
          </HorizontalForm>
        </div>
      </div>
    </div>
  );
};

export default ExportPanel;
