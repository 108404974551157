import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Icons } from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';
import { useRouteMatch } from 'react-router-dom';

import styles from './app-bar-floorplan.module.scss';

import AppBarSelectBox from 'components/app-bar-select-box/app-bar-select';
import { findFloorsInBuilding } from 'lib/floors';
import { selectBuilding } from 'redux/features/spaces/select-building';
import { useAppSelector } from 'redux/store';
import { spacesSelectors } from 'redux/features/spaces/spaces-slice';
import { selectFloor } from 'redux/features/spaces/select-floor';

const AppBarFloorplan: React.FunctionComponent<{ actions?: React.ReactNode }> =
  ({ actions }) => {
    const history = useHistory();
    const { params } = useRouteMatch<{ buildingId: string; floorId: string }>();

    const building = useAppSelector((state) =>
      selectBuilding(state, { buildingId: params.buildingId })
    );

    const floor = useAppSelector((state) =>
      selectFloor(state, { floorId: params.floorId })
    );

    const spaces = useAppSelector(spacesSelectors.selectAll);

    const onBuildingClick = useCallback(() => {
      if (!building) return;
      history.push(`/buildings/${building.id}`);
    }, [building, history]);

    const floors = useMemo(() => {
      if (!building) return [];
      return findFloorsInBuilding(spaces, building.id);
    }, [spaces, building]);

    // TODO: this is annoying, fix this typing
    if (!building) return null;
    if (!floor) return null;

    return (
      <div className={styles.AppBarPlanCreate}>
        <section className={styles.AppBarPlanCreateLeft}>
          <div className={styles.Breadcrumb}>
            <div className={styles.Building} onClick={onBuildingClick}>
              <div className={styles.Icon}>
                <Icons.Building width={20} height={20} color="currentColor" />
              </div>
              <div className={styles.Name}>{building.name}</div>
            </div>
            <div>
              <Icons.ChevronRight
                width={24}
                height={24}
                color={colors.midnightOpaque40}
              />
            </div>
            <AppBarSelectBox
              value={floor}
              options={floors}
              icon={
                <div style={{ marginLeft: 4 }}>
                  <Icons.Floor width={20} height={20} />
                </div>
              }
              onChange={(floor) => {
                history.push(`/buildings/${building.id}/floors/${floor.id}`);
              }}
            />
          </div>
        </section>
        {actions ? (
          <section className={styles.AppBarPlanCreateRight}>{actions}</section>
        ) : null}
      </div>
    );
  };
export default AppBarFloorplan;
