import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';

import {
  DropdownMenuContent,
  DropdownMenuItem,
} from 'components/dropdown/dropdown';
import GhostButton from 'components/ghost-button/ghost-button';
import { ReactComponent as ToggleControlsIcon } from 'img/icon-toggle-controls.svg';
import { ReactComponent as TextFormatIcon } from 'img/icon-text-format.svg';
import { ReactComponent as ChevronDownIcon } from 'img/icon-chevron-down.svg';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { toggleSpaceName } from 'redux/features/analysis/analysis-slice';
import Switch from 'components/switch/switch';

const FloorplanViewDropdownMenu: React.FC = (props) => {
  const showSpaceName = useAppSelector((state) => state.analysis.showSpaceName);

  const dispatch = useAppDispatch();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <GhostButton
          aria-label="Floorplan view dropdown"
          css={css`
            width: 42px;
            height: 32px;
            color: ${dust.Gray700};
          `}
        >
          <ToggleControlsIcon width={20} height={20} />
          <ChevronDownIcon width={16} height={16} />
        </GhostButton>
      </DropdownMenu.Trigger>

      <DropdownMenuContent align="start">
        <DropdownMenuItem
          css={css`
            gap: 8px;
          `}
          onSelect={(e) => {
            // this prevents the menu from closing
            // documentation here https://www.radix-ui.com/docs/primitives/components/dropdown-menu#item
            e.preventDefault();

            dispatch(toggleSpaceName());
          }}
        >
          <TextFormatIcon height={24} width={24} color={dust.Blue400} />

          <span
            css={css`
              font-size: ${dust.TextScale4};
              font-weight: ${dust.FontWeightMedium};
            `}
          >
            Space names
          </span>

          <Switch isChecked={showSpaceName} />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu.Root>
  );
};

export default FloorplanViewDropdownMenu;
