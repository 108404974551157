import * as React from 'react';

import styles from './styles.module.scss';

import { State } from 'components/editor/state';
import { Action } from 'components/editor/actions';
import { RangeSlider, ValueSlider } from 'components/slider';

const InternalToolingPanel: React.FunctionComponent<{
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ state, dispatch }) => {
  return (
    <div className={styles.controlPanel}>
      <p>
        <i>Note: this is temporary and internal-use only</i>
      </p>
      <div className={styles.controlPanelDivider}></div>
      <header className={styles.controlPanelHeader}>
        Visualization Controls
      </header>
      <div className={styles.controlPanelField}>
        <div className={styles.fieldTitle}>SNR Threshold</div>
        <div className={styles.valueLabel}>
          {state.visualization.snrThreshold}
        </div>
        <ValueSlider
          value={state.visualization.snrThreshold}
          min={1}
          max={36}
          step={0.1}
          onChange={(snrThreshold) => {
            dispatch({
              type: 'menu.visualization.snrThreshold.change',
              snrThreshold,
            });
          }}
        />
      </div>
      <div className={styles.controlPanelField}>
        <div className={styles.fieldTitle}>Color Scale Domain</div>
        <div className={styles.valueLabel}>
          {JSON.stringify(state.visualization.colorScaleDomain)}
        </div>
        <RangeSlider
          value={state.visualization.colorScaleDomain}
          min={0}
          max={96}
          step={1}
          onChange={(colorScaleDomain) => {
            dispatch({
              type: 'menu.visualization.colorScaleDomain.change',
              colorScaleDomain,
            });
          }}
        />
      </div>
    </div>
  );
};

export default InternalToolingPanel;
