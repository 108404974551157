import { useLayoutEffect, useMemo, useRef } from 'react';
import * as React from 'react';
import * as d3 from 'd3';
import colors from '@densityco/ui/variables/colors.json';

import { drawRoundedRectangle, FloorplanCoordinates } from 'lib/geometry';
import { State } from 'components/editor/state';
import { Seconds } from 'lib/units';

// ALERT: This is NOT the "heatmap" as advertised on density.io, check in the
// "floor analysis" folder for that component. This renders "points" in the
// planner interface.
const HeatmapVisualizer: React.FunctionComponent<{
  state: State;
}> = ({ state }) => {
  const canvasElementRef = useRef<HTMLCanvasElement>(null);

  const { floorplan, viewport, floorplanImageOpacity, gridSize } = state;

  const width = viewport.width;
  const height = viewport.height;
  const scale = floorplan.scale * viewport.zoom;

  const heatmapColorScale = useMemo(() => {
    return d3
      .scaleSequential(d3.interpolateRgb(colors.midnightOpaque05, colors.blue))
      .clamp(true)
      .domain(state.visualization.colorScaleDomain);
  }, [state.visualization.colorScaleDomain]);

  const heatmap = useMemo(() => {
    const heatmap = new Map<number, Map<number, number>>();
    const now = Seconds.fromMilliseconds(Date.now());
    const data = state.aggregatedPointsData.filter((point) => {
      const isRecent = point.timestamp > now - 0.333;
      const isValid = point.isSimulated
        ? true
        : (point.sensorPoint?.snr || 0) >= state.visualization.snrThreshold;
      return isRecent && isValid;
    });
    for (const point of data) {
      const gridCoords = FloorplanCoordinates.toGridCoordinates(
        point.floorplanPosition,
        gridSize
      );
      let row = heatmap.get(gridCoords.y);
      if (typeof row === 'undefined') {
        row = new Map();
        heatmap.set(gridCoords.y, row);
      }
      const count = row.get(gridCoords.x);
      if (typeof count === 'undefined') {
        row.set(gridCoords.x, 1);
      } else {
        row.set(gridCoords.x, count + 1);
      }
    }
    return heatmap;
  }, [state.aggregatedPointsData, state.visualization, gridSize]);

  // Update canvas on first render and when params change
  useLayoutEffect(() => {
    const canvas = canvasElementRef.current;
    if (!canvas) throw new Error('Could not get canvas element from ref');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get canvas 2d context');

    const gridStepPixels = scale * gridSize;

    ctx.save();

    // IMPORTANT: scale the context by the device pixel ratio
    ctx.scale(devicePixelRatio, devicePixelRatio);

    // IMPORTANT: clear the canvas before drawing again
    ctx.clearRect(0, 0, width, height);

    // NOTE: Change these to affect square spacing and corner radius
    // --------------------------------------------------------------
    // This is the space between squares as a percentage of grid step
    const squareSpacing = 0.25;
    // This is corner radius as a percentage of resulting square size
    const squareCornerRadius = 1 / 3;

    // --------------------------------------------------------------
    // Don't try to tweak these
    const squareComputedCornerOffset = (gridStepPixels * squareSpacing) / 2;
    const squareComputedSize = gridStepPixels - 2 * squareComputedCornerOffset;
    const squareComputedRadius = squareComputedSize * squareCornerRadius;

    // heatmap display
    ctx.globalAlpha = 1;
    heatmap.forEach((row, y) => {
      row.forEach((count, x) => {
        const pos = FloorplanCoordinates.toViewportCoordinates(
          FloorplanCoordinates.create(x * gridSize, y * gridSize),
          floorplan,
          viewport
        );
        if (count < state.visualization.colorScaleDomain[0]) {
          return;
        }
        ctx.fillStyle = heatmapColorScale(count) || colors.midnightOpaque05;
        ctx.beginPath();
        drawRoundedRectangle(
          ctx,
          pos.x + squareComputedCornerOffset,
          pos.y + squareComputedCornerOffset,
          squareComputedSize,
          squareComputedSize,
          squareComputedRadius
        );
        ctx.closePath();
        ctx.fill();
      });
    });

    ctx.restore();
  }, [
    canvasElementRef,
    state.visualization,
    floorplanImageOpacity,
    floorplan,
    width,
    height,
    scale,
    viewport,
    gridSize,
    heatmap,
    heatmapColorScale,
  ]);

  return (
    <canvas
      ref={canvasElementRef}
      style={{
        position: 'absolute',
        width,
        height,
        pointerEvents: 'none',
      }}
      width={width * devicePixelRatio}
      height={height * devicePixelRatio}
    />
  );
};

export default HeatmapVisualizer;
