import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ErrorReporting } from 'lib/error-reporting';
import { AppThunkApiConfig } from 'redux/store';
import { FixMe } from 'types/fixme';
import invariant from 'invariant';
import { defaultAppThunkOptions } from 'lib/redux';

export const asyncFetchStatusThunk = createAsyncThunk<
  // return value
  { areaId: string; isAvailable: boolean }[],
  // parameters
  { planId: string },
  // types for thunkAPI
  AppThunkApiConfig
>(
  'availability/asyncFetchStatusThunk',
  async (args, thunkAPI) => {
    const { auth } = thunkAPI.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener('abort', () => {
      source.cancel();
    });

    try {
      const response = await auth.densityAPIClient.get(
        `/v2/floorplans/${args.planId}/areas/status`,
        {
          cancelToken: source.token,
        }
      );

      return (response.data.areas || []).map((item: FixMe) => ({
        areaId: item.area_id,
        isAvailable: !item.presence,
      }));
    } catch (err) {
      // use instanceof to narrow the type to Error
      if (!axios.isCancel(err) && err instanceof Error) {
        ErrorReporting.report(err, 'error');
      }

      throw err;
    }
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkAPI) => {
      const { auth } = thunkAPI.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
