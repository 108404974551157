import { useRef } from 'react';
import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import { FloorplanCoordinates } from 'lib/geometry';
import { PhotoGroup } from 'components/editor/state';
import photoGroupsImageUrl from './photo-groups-image.png';

import {
  ObjectLayer,
  MetricLabel,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  toRawHex,
} from 'components/floorplan';

import {
  Yellow400,
  Gray400,
  Gray900,
  Red400,
  White,
} from '@density/dust/dist/tokens/dust.tokens';

const DIAMETER_PX = 28;
const BADGE_OFFSET_X_PX = (DIAMETER_PX / 2) * Math.cos(-Math.PI / 4);
const BADGE_OFFSET_Y_PX = (DIAMETER_PX / 2) * Math.sin(-Math.PI / 4);

const FOCUSED_DIAMETER_PX = 32;
const FOCUSED_BADGE_OFFSET_X_PX =
  (FOCUSED_DIAMETER_PX / 2) * Math.cos(-Math.PI / 4);
const FOCUSED_BADGE_OFFSET_Y_PX = (DIAMETER_PX / 2) * Math.sin(-Math.PI / 4);

const FOCUSED_ICON_SCALE = 1.3;

const FOCUSED_SHADOW_WIDTH_PX = 5;

// The photo groups layer renders an icon for each photo group at the proper x and y coordinates.
// When clicked, it opens a panel showing photo group info on the right side of the screen.
const PhotoGroupsLayer: React.FunctionComponent<{
  photoGroups: Array<PhotoGroup>;
  focusedPhotoGroupId: string | null;
  highlightedObject: {
    type: 'sensor' | 'space' | 'photogroup' | 'reference';
    id: string;
  } | null;
  onMouseEnter: (photoGroup: PhotoGroup, event: FederatedPointerEvent) => void;
  onMouseLeave: (photoGroup: PhotoGroup, event: FederatedPointerEvent) => void;
  onMouseDown: (photoGroup: PhotoGroup, event: FederatedPointerEvent) => void;
  onDragMove: (
    photoGroup: PhotoGroup,
    newCoordinates: FloorplanCoordinates
  ) => void;
}> = ({
  photoGroups,
  focusedPhotoGroupId,
  highlightedObject,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onDragMove,
}) => {
  const context = useFloorplanLayerContext();

  const focusedPhotoGroupPosition = useRef<FloorplanCoordinates | null>(null);

  return (
    <ObjectLayer
      objects={photoGroups}
      extractId={(photoGroup: PhotoGroup) => photoGroup.id}
      onCreate={(getPhotoGroup: () => PhotoGroup) => {
        if (!context.viewport.current) {
          return null;
        }

        const photoGroupGraphic = new PIXI.Container();

        const shape = new PIXI.Graphics();
        shape.name = 'shape';
        shape.interactive = true;
        shape.cursor = 'grab';
        photoGroupGraphic.addChild(shape);

        shape.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }
          onMouseDown(getPhotoGroup(), evt);

          // Clicking and dragging moves the photo group.
          addDragHandler(
            context,
            getPhotoGroup().position,
            evt,
            (newPosition) => {
              focusedPhotoGroupPosition.current = newPosition;
            },
            () => {
              // On release, call onDragMove
              if (!focusedPhotoGroupPosition.current) {
                return;
              }
              onDragMove(getPhotoGroup(), focusedPhotoGroupPosition.current);
              focusedPhotoGroupPosition.current = null;
            }
          );
        });
        shape.on('mouseover', (evt) => onMouseEnter(getPhotoGroup(), evt));
        shape.on('mouseout', (evt) => onMouseLeave(getPhotoGroup(), evt));

        const image = PIXI.Sprite.from(photoGroupsImageUrl);
        image.name = 'image';
        photoGroupGraphic.addChild(image);

        const badge = new MetricLabel('', {
          backgroundColor: toRawHex(Gray900),
        });
        badge.name = 'badge';
        photoGroupGraphic.addChild(badge);

        return photoGroupGraphic;
      }}
      onUpdate={(
        photoGroup: PhotoGroup,
        photoGroupContainer: PIXI.Container
      ) => {
        if (!context.viewport.current) {
          return;
        }

        const isFocused = focusedPhotoGroupId === photoGroup.id;
        const isAnyPhotoGroupFocused = focusedPhotoGroupId !== null;

        const isHighlighted =
          highlightedObject &&
          highlightedObject.type === 'photogroup' &&
          highlightedObject.id === photoGroup.id;

        const photoGroupPosition =
          isFocused &&
          focusedPhotoGroupPosition &&
          focusedPhotoGroupPosition.current
            ? focusedPhotoGroupPosition.current
            : photoGroup.position;

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          photoGroupPosition,
          context.floorplan,
          context.viewport.current
        );

        photoGroupContainer.renderable = isWithinViewport(
          context,
          viewportCoords,
          -1 * DIAMETER_PX
        );
        if (!photoGroupContainer.renderable) {
          return;
        }

        photoGroupContainer.x = viewportCoords.x;
        photoGroupContainer.y = viewportCoords.y;

        const photoGroupShape = photoGroupContainer.getChildByName(
          'shape'
        ) as PIXI.Graphics;
        photoGroupShape.clear();

        const baseDiameter = isFocused ? FOCUSED_DIAMETER_PX : DIAMETER_PX;

        // Secondary "shadow" circle
        if (isFocused || isHighlighted) {
          photoGroupShape.beginFill(toRawHex(Yellow400), 0.4);
          photoGroupShape.drawCircle(
            0,
            0,
            baseDiameter / 2 + FOCUSED_SHADOW_WIDTH_PX
          );
          photoGroupShape.endFill();
        }

        // Main circle
        photoGroupShape.lineStyle({
          width: 2,
          color: toRawHex(White),
          join: PIXI.LINE_JOIN.ROUND,
        });
        if (isAnyPhotoGroupFocused && !isFocused) {
          photoGroupShape.beginFill(toRawHex(Gray400));
        } else {
          photoGroupShape.beginFill(toRawHex(Yellow400));
        }
        photoGroupShape.drawCircle(0, 0, baseDiameter / 2);
        photoGroupShape.endFill();

        // Make the image larger when the photo group is selected
        const image = photoGroupContainer.getChildByName(
          'image'
        ) as MetricLabel;
        image.x = -6.5 * (isFocused ? FOCUSED_ICON_SCALE : 1);
        image.y = -7 * (isFocused ? FOCUSED_ICON_SCALE : 1);
        image.width = 13.33 * (isFocused ? FOCUSED_ICON_SCALE : 1);
        image.height = 12 * (isFocused ? FOCUSED_ICON_SCALE : 1);

        // A badge is shown in the corner that indicates if the photo group has photos
        const badge = photoGroupContainer.getChildByName(
          'badge'
        ) as MetricLabel;

        // Hide the badge when super zoomed out to increase performance when zoomed out
        if (context.viewport.current.zoom > 0.25) {
          badge.visible = true;
          badge.x = isFocused ? FOCUSED_BADGE_OFFSET_X_PX : BADGE_OFFSET_X_PX;
          badge.y = isFocused ? FOCUSED_BADGE_OFFSET_Y_PX : BADGE_OFFSET_Y_PX;

          badge.setText(`${photoGroup.photos.length}`);

          // Make the badge red if no photos are in the photo group
          const newColor = toRawHex(
            photoGroup.photos.length > 0 ? Gray900 : Red400
          );
          if (newColor !== badge.options.backgroundColor) {
            badge.options.backgroundColor = newColor;
            badge.redrawTextBounds();
          }
        } else {
          badge.visible = false;
        }
      }}
      onRemove={(photoGroup: PhotoGroup, photoGroupContainer) => {
        photoGroupContainer.destroy(true);
      }}
    />
  );
};

export default PhotoGroupsLayer;
