import { CoreSpace } from '@densityco/lib-api-types';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';
import { spacesSelectors } from './spaces-slice';

export const selectSubspaces = createSelector(
  [
    spacesSelectors.selectAll,
    (_: RootState, params: { spaceId: string }) => params.spaceId,
  ],
  (spaces, spaceId) => {
    const subspaces: CoreSpace[] = [];

    spaces.forEach((space) => {
      if (space.ancestry.some((ancestor) => ancestor.id === spaceId)) {
        subspaces.push(space);
      }
    });

    return subspaces;
  }
);
