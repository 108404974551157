import { Area } from './api';
import { FloorplanCoordinates } from './geometry';

import { Space } from 'components/editor/state';

export function isAreaRectilinear(area: Area): boolean {
  if (!area.polygon_verticies) {
    return false;
  }
  if (area.polygon_verticies.length !== 4) {
    return false;
  }
  const topLeft = area.polygon_verticies[0];
  const bottomLeft = area.polygon_verticies[1];
  const bottomRight = area.polygon_verticies[2];
  const topRight = area.polygon_verticies[3];

  const isRectilinear =
    topLeft.y_from_origin_meters === bottomLeft.y_from_origin_meters &&
    bottomLeft.x_from_origin_meters === bottomRight.x_from_origin_meters &&
    bottomRight.y_from_origin_meters === topRight.y_from_origin_meters &&
    topRight.x_from_origin_meters === topLeft.x_from_origin_meters;

  return isRectilinear;
}

// converts an Area from the api into a Space (in Editor terminology)
export function areaToSpace(area: Area): Space {
  switch (area.shape) {
    case 'circle': {
      const position = FloorplanCoordinates.create(
        area.circle_centroid_x_meters,
        area.circle_centroid_y_meters
      );
      const space = Space.createCircle(
        position,
        area.name,
        area.circle_radius_meters
      );
      // FIXME: pass through id in createCircle() instead
      space.id = area.id;
      return space;
    }
    case 'polygon': {
      // Figure out if the "polygon" stored on the server is a box or a polygon
      // NOTE: I think ideally it would be nice to have this descriminated on the backend rather
      // than using a hueristic here.
      if (isAreaRectilinear(area)) {
        const topLeft = area.polygon_verticies[0];
        const bottomRight = area.polygon_verticies[2];

        // The space is a rectangle, so create a box space
        const left = topLeft.x_from_origin_meters;
        const top = topLeft.y_from_origin_meters;
        const right = bottomRight.x_from_origin_meters;
        const bottom = bottomRight.y_from_origin_meters;
        const width = right - left;
        const height = bottom - top;
        const cx = left + width / 2;
        const cy = top + height / 2;
        const position = FloorplanCoordinates.create(cx, cy);
        const space = Space.createBox(position, area.name, width, height);
        // FIXME: pass through id in createBox() instead
        space.id = area.id;
        return space;
      }

      // The space is not a rectangle, so create a polygon space
      const vertices = area.polygon_verticies.map((v) =>
        FloorplanCoordinates.create(
          v.x_from_origin_meters,
          v.y_from_origin_meters
        )
      );
      const space = Space.createPolygon(vertices, area.name);
      // FIXME: pass through id in createPolygon() instead
      space.id = area.id;
      return space;
    }
  }
}
