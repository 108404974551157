import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';
import { CoreSpace } from '@densityco/lib-api-types';

import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { getSpace } from 'lib/api';
import { spacesSelectors } from './spaces-slice';

export const asyncFetchSpaceThunk = createAsyncThunk<
  // return value
  CoreSpace,
  // parameters
  string,
  // types for thunkApi
  AppThunkApiConfig
>(
  'plans/asyncFetchSpace',
  async (arg, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    const response = await getSpace(auth.densityAPIClient, arg);

    return response.data;
  },
  {
    ...defaultAppThunkOptions,
    condition: (arg, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }

      // don't start a fetch if a space is in cache
      const space = spacesSelectors.selectById(thunkApi.getState(), arg);
      if (space) {
        return false;
      }
    },
  }
);
