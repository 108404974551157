import * as React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

import { Building } from 'lib/buildings';

const EmptyStateBuildingSingle: React.FunctionComponent<{
  building: Building;
}> = ({ building }) => {
  return (
    <div className={styles.EmptyStateBuildingSingle}>
      <div className={styles.emptyStateMessage}>
        You haven’t added any floors to this building yet.
      </div>
      <a
        href={`https://dashboard.density.io/#/admin/locations/${building.id}/create/floor`}
        className={classNames([
          styles.buttonSmall,
          styles.primary,
          styles.elevated,
        ])}
      >
        Add a Floor
      </a>
    </div>
  );
};

export default EmptyStateBuildingSingle;
