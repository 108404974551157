import * as React from 'react';
import { css } from '@emotion/react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

type BadgeProps = {
  intent?: 'success' | 'failure';
};

const Badge: React.FC<BadgeProps> = (props) => {
  return (
    <span
      css={css`
        background-color: ${props.intent === 'success'
          ? dust.Green400
          : dust.Red400};
        color: ${dust.White};
        font-size: ${dust.TextScale2};
        font-weight: ${dust.FontWeightBold};
        border-radius: 100px;
        padding: 4px 6px;
      `}
    >
      {props.children}
    </span>
  );
};

export default Badge;
