import * as React from 'react';
import { Fragment } from 'react';
import {
  Subject,
  Subscription,
  interval,
  fromEvent,
  animationFrameScheduler,
  combineLatest,
  merge,
  BehaviorSubject,
} from 'rxjs';
import {
  bufferTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { wrap, releaseProxy, proxy, Remote } from 'comlink';
import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import { downloadFile } from '@densityco/lib-common-helpers';
import { fromAction } from '@densityco/lib-state-management';
import { Icons, Dialogger } from '@densityco/ui';

import FloorplanMeasure from '../floorplan-measure/floorplan-measure';

import FloorplanObjectsList, {
  LatestDXFStatus,
} from './floorplan-objects-list';
import FloorplanViewport from './floorplan-viewport';
import FocusedSensorPanel from './focused-sensor-panel';
import FocusedSpacePanel from './focused-space-panel';
import FocusedPhotoGroupPanel from './focused-photo-group-panel';
import FocusedHeightmapLayerPanel from './focused-heightmap-layer-panel';
import AutoLayoutPanel, { AutoLayoutLayer } from './auto-layout-panel';
import SensorDataEmitter from './sensor-data-emitter';
import InternalToolingPanel from './internal-tooling-panel';
import SensorSimulationObserver from './sensor-simulation-observer';
import SpaceObserver from './space-observer';
import { FixMe } from 'types/fixme';
import {
  State,
  reducer,
  FloorplanCollection,
  Sensor,
  ReferenceRuler,
  PlanDXF,
  LayerId,
  SensorCoverageIntersectionVectors,
} from './state';
import {
  MODIFIER_KEYS,
  Keybindings,
  modifierKeyFromEvt,
  subscribeToKeybindings,
  unsubscribeFromKeybindings,
} from './keybindings';
import { Action } from './actions';
import styles from './styles.module.scss';
import SimulationPanel from './simulation-panel';
import { generateExportCanvas, storeCanvasToDisk } from './export';
import SaveButton from './save-button';
import SettingsPanel from './settings-panel';
import ExportPanel from './export-panel';
import CADImport from './cad-import';
import HeightMapImport from './height-map';
import { initializeStateFromPlan, preparePlanUpdate } from './plan-io';

import KeyboardShortcutsMenu from 'components/keyboard-shortcuts-menu';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import Button from 'components/button';
import Tooltip from 'components/tooltip';
import HorizontalForm from 'components/horizontal-form';
import FloorSingleView from 'components/floor-single-view/floor-single-view';
import AppBarFloor from 'components/app-bar-floor/app-bar-floor';
import AppBarFloorplan from 'components/app-bar-floorplan';
import Floorplan from 'components/floorplan';
import SensorsLayer from './floorplan-layers/sensors-layer';
import SpacesLayer from './floorplan-layers/spaces-layer';
import ReferenceRulersLayer from './floorplan-layers/reference-rulers-layer';
import PhotoGroupsLayer from './floorplan-layers/photo-groups-layer';
import FloorplanScaleLayer from './floorplan-layers/floorplan-scale-layer';
import AggregatedPointsLayer from './floorplan-layers/aggregated-points-layer';
import ObjectPlacementTargetLayer from './floorplan-layers/object-placement-target-layer';
import { HeightMapLayer } from './floorplan-layers/height-map-registration-layer';
import ObjectMeasureLayer from './floorplan-layers/object-measure-layer';
import PolygonalSpaceCreationLayer from './floorplan-layers/polygonal-space-creation-layer';
import { resizeImageToMaxSize } from 'lib/image';
import { Seconds } from 'lib/units';
import { SPLITS } from 'lib/treatments';
import {
  FloorplanCoordinates,
  ViewportCoordinates,
  ViewportVector,
} from 'lib/geometry';
import {
  patchPlan,
  createPhotoGroup,
  updatePhotoGroup,
  deletePhotoGroup,
  updatePhoto,
  deletePhoto,
  PlanDetail,
  FloorplanAPI,
} from 'lib/api';
import FloorplanEventStream, {
  PlanEventMessageDXF,
  PlanEventMessageExport,
} from 'lib/floorplan-event-stream';
import InternalTool from 'components/internal-tool';
import { TokenCheckResponse } from '@densityco/lib-common-auth';
import {
  scale,
  translate,
  compose,
  rotateDEG,
  decomposeTSR,
} from 'transformation-matrix';

// enables the capturing and download-ability of action stream metrics
const _PROFILE_MODE = false;

const EDGE_SCROLL_TRIGGER_WIDTH = 60; // pixels (from edge of viewport)
const EDGE_SCROLL_VELOCITY = 1000; // pixels-per-second

// function displayDwellTime(seconds: number) {
//   return new Date(seconds * 1000).toISOString().substr(11, 8);
// }

export type EditorTreatments = {
  web_oa_planning_floorplan_component: 'on' | 'off';
  web_oa_import_dxf: 'on' | 'off';
  web_oa_export_dxf: 'on' | 'off';
  web_oa_live_connection: 'on' | 'off';
  web_oa_height_maps: 'on' | 'off';
  web_oa_autolayout: 'on' | 'off';
  web_oa_sensor_coverage_simulation: 'on' | 'off';
};

type Props = {
  plan: PlanDetail;
  planImage: HTMLImageElement | null;
  client: AxiosInstance;
  tokenCheckResponse?: TokenCheckResponse;

  onPlanSaved?: (planDetail: PlanDetail) => void;
  // onDuplicatePlanComplete: (plan: PersistedData.v1) => void;

  treatments: EditorTreatments;
};

enum DragEndCondition {
  CANCEL = 'cancel',
  END = 'end',
}

function cacheBustImageUrl(imageSrc: string) {
  if (imageSrc.includes('s3.amazonaws.com')) {
    // In order to ensure chrome doesn't cache the base image, a cache-busting query parameter is
    // needed. See https://github.com/DensityCo/nashi-client/blob/master/src/utils/downloadImage.ts
    // for where I got this technique, or talk to wei-wei for more info.
    const result = new URL(imageSrc);
    result.searchParams.set('nonce', `${new Date().getTime()}`);
    return result.toString();
  } else {
    return imageSrc;
  }
}

class Editor extends React.Component<Props, State> {
  private viewportElementRef: React.RefObject<HTMLDivElement>;
  private floorplanRef: React.RefObject<FixMe>;
  private actionStream: Subject<Action>;
  private stateChangeStream: Subject<State>;
  private subscriptions: Set<Subscription>;
  private stateSubject: BehaviorSubject<State>;
  private keybindings: Keybindings;
  private _profiledActions: Array<{ elapsed: number; action: Action }>;
  private floorplanEventStream: FloorplanEventStream | null;
  private disableComponentDidUpdateEffect: boolean;

  private coverageIntersectionProcessingWorker: Worker | null;
  private coverageIntersectionProcessingWorkerWrapped: Remote<
    import('lib/coverage-intersection-worker').CoverageIntersectionWorker
  > | null;

  constructor(props: Props) {
    super(props);

    this.viewportElementRef = React.createRef();
    this.floorplanRef = React.createRef();

    this.actionStream = new Subject<Action>();
    this.stateChangeStream = new Subject<State>();
    this.subscriptions = new Set();
    this._profiledActions = [];
    this.disableComponentDidUpdateEffect = false;

    if (_PROFILE_MODE) {
      this.actionStream.subscribe((action) => {
        this._profiledActions.push({
          elapsed: Seconds.fromMilliseconds(performance.now()),
          action,
        });
      });
    }

    this.state = initializeStateFromPlan(this.props.plan, this.props.planImage);

    this.stateSubject = new BehaviorSubject<State>(this.state);

    this.keybindings = [
      {
        sequence: '` ` `',
        keypress: () => {
          this.setState({ showInternalTool: !this.state.showInternalTool });
        },
      },
      {
        sequence: '1',
        keypress: () => {
          this.dispatch({ type: 'menu.addSensor', sensorType: 'oa' });
        },
      },
      {
        sequence: '2',
        keypress: () => {
          this.dispatch({ type: 'menu.addSensor', sensorType: 'entry' });
        },
      },
      {
        sequence: ['del', 'backspace'],
        keypress: () => {
          this.dispatch({ type: 'menu.removeFocusedObject' });
        },
      },
      {
        sequence: 'r r',
        keypress: () => {
          if (
            !this.state.focusedObject ||
            this.state.focusedObject.type !== 'sensor'
          ) {
            return;
          }
          this.dispatch({
            type: 'sensor.rotateRight90',
            id: this.state.focusedObject.id,
          });
        },
      },
      {
        sequence: 's s',
        keypress: () => {
          this.dispatch({
            type: this.state.simulation.enabled
              ? 'simulation.menu.disableSimulation'
              : 'simulation.menu.enableSimulation',
          });
        },
      },
      {
        sequence: 'e e',
        keypress: () => {
          this.onExportClick();
        },
      },
      {
        sequence: 's o',
        keypress: () => {
          this.dispatch({
            type: this.state.simulation.simBoundariesShown
              ? 'simulation.menu.hideSimBoundaries'
              : 'simulation.menu.showSimBoundaries',
          });
        },
      },
      {
        sequence: 'n s',
        keypress: () => {
          this.dispatch({ type: 'simulation.menu.addSimulant' });
        },
      },
      {
        sequence: MODIFIER_KEYS,
        keydown: (evt: KeyboardEvent) => {
          const key = modifierKeyFromEvt(evt);
          if (!key) {
            return;
          }
          this.dispatch({ type: 'keyboard.modifier.change', key, down: true });
        },
        keyup: (evt: KeyboardEvent) => {
          const key = modifierKeyFromEvt(evt);
          if (!key) {
            return;
          }
          this.dispatch({ type: 'keyboard.modifier.change', key, down: false });
        },
      },
    ];

    this.floorplanEventStream = null;

    this.coverageIntersectionProcessingWorker = null;
    this.coverageIntersectionProcessingWorkerWrapped = null;
  }

  dispatch = (action: Action) => {
    this.actionStream.next(action);
  };

  dispatchImmediately = (action: Action) => {
    this.setState((prevState) => {
      return reducer(prevState, action);
    });
  };

  updateViewportSize = () => {
    const viewportElement = this.viewportElementRef.current;
    if (!viewportElement) {
      return;
    }
    const bbox = viewportElement.getBoundingClientRect();
    const { width, height } = bbox;

    if (this.props.treatments[SPLITS.PLANNING_FLOORPLAN_COMPONENT] === 'on') {
      const viewport = this.floorplanRef.current.getViewport();
      this.floorplanRef.current.changeViewport({ ...viewport, width, height });
    } else {
      this.dispatch({ type: 'viewport.resize', width, height });
    }
  };

  onZoomToFitClick = () => {
    if (this.props.treatments[SPLITS.PLANNING_FLOORPLAN_COMPONENT] === 'on') {
      if (this.floorplanRef.current) {
        this.floorplanRef.current.zoomToFit();
      }
    } else {
      this.dispatch({ type: 'viewport.zoomToFit' });
    }
  };

  // NOTE: there is an important difference between `dispatch` and `this.dispatch`
  // TODO: lift up these actions so there can be one dispatch function
  onSaveClick = async () => {
    if (this.state.savePending) return;
    this.dispatch({ type: 'save.pending' });
    const data = preparePlanUpdate(this.state);

    // Update the plan "document", which updates sensors/spaces/references
    const patchPlanResponse = patchPlan(
      this.props.client,
      this.props.plan.id,
      data
    );
    let geoTiffPointCloudMatrix = undefined;
    if (
      this.state.heightMap.enabled &&
      this.state.heightMap.geotiffTransformationData.enabled
    ) {
      geoTiffPointCloudMatrix = compose(
        rotateDEG(-this.state.heightMap.rotation),
        scale(
          1 / this.state.heightMap.geotiffTransformationData.scale,
          1 / this.state.heightMap.geotiffTransformationData.scale
        ),
        translate(
          -this.state.heightMap.position.x,
          -this.state.heightMap.position.y
        ),
        translate(
          -this.state.heightMap.geotiffTransformationData.tiePoint[0].x,
          this.state.heightMap.geotiffTransformationData.tiePoint[0].y
        )
      );
    }

    // Make a request to the v2 floorplans api to update the height map if it needs to be
    // updated
    let heightMapUpdateRequest: Promise<void> = Promise.resolve();
    if (
      this.props.treatments[SPLITS.HEIGHT_MAP] === 'on' &&
      this.state.heightMapUpdated
    ) {
      heightMapUpdateRequest = FloorplanAPI.updateCeilingRaster(
        this.props.client,
        this.props.plan.id,
        this.state.heightMap.enabled ? this.state.heightMap.objectKey : null,
        this.state.heightMap.enabled ? this.state.heightMap.position.x : 0,
        this.state.heightMap.enabled ? this.state.heightMap.position.y : 0,
        this.state.heightMap.enabled ? this.state.heightMap.rotation : 0,
        this.state.heightMap.enabled ? this.state.heightMap.notes : '',
        this.state.heightMap.enabled ? this.state.heightMap.opacity : 100,
        this.state.heightMap.enabled && this.state.heightMap.limits.enabled
          ? this.state.heightMap.limits.minMeters
          : 0,
        this.state.heightMap.enabled && this.state.heightMap.limits.enabled
          ? this.state.heightMap.limits.maxMeters
          : 0,
        this.state.heightMap.enabled
          ? JSON.parse(JSON.stringify(geoTiffPointCloudMatrix))
          : undefined,
        this.state.heightMap.enabled && geoTiffPointCloudMatrix
          ? JSON.stringify(decomposeTSR(geoTiffPointCloudMatrix))
          : undefined
      ).then(() => undefined);
    }

    // Photo groups are different right now. They use their own REST endpoints because they have a
    // more complicated lifecycle than can be expressed with just patching the plan.
    //
    // NOTE: This has a lot of problems and is going to be brittle over the long term. For example -
    // what happens if a request fails in the middle of the process? The save operation could end up
    // being half completed.
    const photoGroupCreatesAndUpdates = FloorplanCollection.list(
      this.state.photoGroups
    ).map(async (photoGroup) => {
      if (photoGroup.operationToPerform === 'create') {
        const response = await createPhotoGroup(
          this.props.client,
          this.props.plan.id,
          photoGroup
        );
        // After creating the photo group on the server, dispatch an action to update the id of the
        // photo group in the store. If this "id upgrade" is not done, then the store will continue
        // to contain the old uuid which will screw up future http operations.
        this.dispatch({
          type: 'photoGroup.changeId',
          oldId: photoGroup.id,
          newId: response.data.id,
        });
        // Update the photo group in memory to have the id that came back from the server
        photoGroup.id = response.data.id;
      } else if (photoGroup.operationToPerform === 'update') {
        await updatePhotoGroup(
          this.props.client,
          this.props.plan.id,
          photoGroup.id,
          photoGroup
        );
      }

      await Promise.all(
        photoGroup.photos.map(async (photo) => {
          if (photo.operationToPerform === 'update') {
            await updatePhoto(
              this.props.client,
              this.props.plan.id,
              photoGroup.id,
              photo.id,
              photo
            );
          }
        })
      );

      await Promise.all(
        photoGroup.photoIdsToDelete.map(async (photoId) => {
          await deletePhoto(
            this.props.client,
            this.props.plan.id,
            photoGroup.id,
            photoId
          );
        })
      );
    });

    // Perform any deletes as a secondary step
    const photoGroupRequests = Promise.all(photoGroupCreatesAndUpdates).then(
      () => {
        return Promise.all(
          this.state.photoGroupIdsToDelete.map(async (photoGroupId) => {
            return deletePhotoGroup(
              this.props.client,
              this.props.plan.id,
              photoGroupId
            );
          })
        );
      }
    );

    Promise.all([patchPlanResponse, photoGroupRequests, heightMapUpdateRequest])
      .then(([response]) => {
        const photoGroupPhotoUrls = Object.fromEntries(
          response.data.photo_groups.flatMap((photoGroup) =>
            photoGroup.photos.map((photo) => [photo.id, photo.url])
          )
        );

        this.dispatch({
          type: 'save.success',
          planId: response.data.id,
          photoGroupPhotoUrls,
        });

        return response.data;
      })
      .then((plan) => {
        this.props.onPlanSaved?.(plan);
      })
      .catch((error) => {
        this.dispatch({ type: 'save.error', error });
      });
  };

  onExportClick = () => {
    if (!this.state.floorplanImage) {
      toast.error('Cannot export plan: no floorplan image!');
      return;
    }
    generateExportCanvas(
      this.state.floorplanImage,
      this.state.sensors,
      this.state.references,
      this.state.floorplan,
      this.state.viewport,
      this.state.measurement,
      this.state.displayUnit
    ).then((canvas) => {
      storeCanvasToDisk(canvas, this.getTitle(), 'png');
    });
  };

  onShowRawFloorplanClick = async () => {
    if (!this.state.floorplanImage) {
      toast.error('Cannot export plan: no floorplan image!');
      return;
    }
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = this.state.floorplanImage.currentSrc;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getTitle = () => {
    return this.props.plan.floor.name;
  };

  onDownloadProfileClick = () => {
    downloadFile(
      'profile.json',
      JSON.stringify(this._profiledActions),
      'application/json'
    );
  };

  componentDidMount() {
    // main subscription, transitions state
    this.subscriptions.add(
      this.actionStream
        // buffer actions for each animation frame
        .pipe(bufferTime(0, animationFrameScheduler))
        .subscribe((actions) => {
          // avoid setState if there are no actions to process
          if (actions.length === 0) return;
          // call setState once, rolling up all state transitions during this interval
          this.setState((prevState) => {
            let state = prevState;
            for (const action of actions) {
              state = reducer(state, action);
            }
            this.stateChangeStream.next(state);
            return state;
          });
        })
    );

    // SIDE EFFECT:
    // When the "unsavedModifications" boolean in the state changes, then enable or disable the
    // "window.onbeforeunload" handler which shows a message when a user navigates away from the
    // page.
    this.subscriptions.add(
      this.stateChangeStream
        .pipe(
          map((state) => state.unsavedModifications),
          distinctUntilChanged()
        )
        .subscribe((unsavedModifications) => {
          if (unsavedModifications) {
            // NOTE: the way this functionality is implemented varies between browsers, only some
            // browsers show the text returned from this function (most / all modern ones do not)
            window.onbeforeunload = function () {
              return `You might have unsaved changes - you'll loose them if you navigate away from the page. Are you sure?`;
            };
          } else {
            window.onbeforeunload = null;
          }
        })
    );

    // SIDE EFFECT: When the sensor position changes, recompute the coverage extents
    this.subscriptions.add(
      this.stateSubject.subscribe((state) => {
        if (this.props.treatments[SPLITS.SENSOR_COVERAGE] !== 'on') {
          return;
        }

        if (state.planning.showSensorCoverageExtents) {
          // Start up the sensor coverage intersection processing worker
          if (!this.coverageIntersectionProcessingWorker) {
            this.coverageIntersectionProcessingWorker = new Worker(
              new URL('lib/coverage-intersection-worker', import.meta.url),
              {
                name: 'coverage-intersection-worker',
              }
            );
          }
          if (!this.coverageIntersectionProcessingWorkerWrapped) {
            this.coverageIntersectionProcessingWorkerWrapped = wrap(
              this.coverageIntersectionProcessingWorker
            );
          }
        } else {
          // Ensure worker is terminated if coverage extents are disabled
          if (this.coverageIntersectionProcessingWorkerWrapped) {
            this.coverageIntersectionProcessingWorkerWrapped[releaseProxy]();
            this.coverageIntersectionProcessingWorkerWrapped = null;
          }
          if (this.coverageIntersectionProcessingWorker) {
            this.coverageIntersectionProcessingWorker.terminate();
            this.coverageIntersectionProcessingWorker = null;
          }
          return;
        }

        if (!this.coverageIntersectionProcessingWorkerWrapped) {
          return;
        }
        const workerWrapped = this.coverageIntersectionProcessingWorkerWrapped;
        if (!state.heightMap.enabled) {
          return;
        }
        const heightMap = state.heightMap;

        const sensorIdsWithoutCoverageVectors = Array.from(
          state.sensorCoverageIntersectionVectors
        )
          .filter(([sensorId, value]) => value === 'empty')
          .map(([sensorId, _v]) => sensorId);

        if (!sensorIdsWithoutCoverageVectors.length) {
          return;
        }

        this.dispatchImmediately({
          type: 'sensorCoverageIntersectionVectors.beginCalculating',
          sensorIds: sensorIdsWithoutCoverageVectors,
        });

        const sensorsWithoutCoverageVectors = sensorIdsWithoutCoverageVectors
          .map((sensorId) => state.sensors.items.get(sensorId))
          .filter((sensor) => sensor) as Array<Sensor>;

        (async () => {
          for (const {
            id,
            position,
            height,
            rotation,
          } of sensorsWithoutCoverageVectors) {
            const result: SensorCoverageIntersectionVectors = await new Promise(
              (resolve) => {
                workerWrapped.oaCoverageIntersectionWorker(
                  position,
                  height,
                  rotation,
                  heightMap,
                  proxy(resolve)
                );
              }
            );
            this.dispatchImmediately({
              type: 'sensorCoverageIntersectionVectors.setResult',
              sensorId: id,
              result,
            });
          }
        })();
      })
    );

    // update viewport size on first render
    this.updateViewportSize();
    this.onZoomToFitClick();

    // window resize handling
    this.subscriptions.add(
      fromEvent(window, 'resize').subscribe(() => {
        this.updateViewportSize();
      })
    );

    this.subscriptions.add(
      fromAction(this.actionStream, 'save.success').subscribe(() => {
        toast.success('Plan successfully saved!');
      })
    );

    this.subscriptions.add(
      fromAction(this.actionStream, 'save.error').subscribe(({ error }) => {
        if (error?.response?.data?.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Oh shoot, error saving floorplan.');
        }
      })
    );

    // release expired data
    this.subscriptions.add(
      interval(Seconds.toMilliseconds(10)).subscribe(() => {
        this.dispatch({ type: 'algorithm.releaseExpiredAggregateData' });
      })
    );

    // shared window escape key stream
    const escapeKey = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
      filter((evt) => evt.key === 'Escape' || evt.key === 'Esc'),
      share()
    );

    if (this.props.treatments[SPLITS.PLANNING_FLOORPLAN_COMPONENT] !== 'on') {
      const clock = interval(0, animationFrameScheduler).pipe(
        startWith(-1),
        map(() => performance.now()),
        pairwise(),
        map(([t0, t1]) => {
          return Seconds.fromMilliseconds(t1 - t0);
        })
      );

      // drag gesture

      // stream of current mouse position in ViewportCoordinates
      const mouseMovesRelativeToViewport = fromEvent<MouseEvent>(
        window,
        'mousemove'
      ).pipe(
        map((evt) => {
          const bbox = this.viewportElementRef.current?.getBoundingClientRect();
          if (!bbox)
            throw new Error('Could not get bounding box for viewport element');

          const { clientX, clientY, altKey, ctrlKey, metaKey, shiftKey } = evt;

          const x = clientX - bbox.left;
          const y = clientY - bbox.top;

          return {
            position: ViewportCoordinates.create(x, y),
            modifiers: {
              altKey,
              ctrlKey,
              metaKey,
              shiftKey,
            },
          };
        })
      );

      // shared window mouseup stream
      const windowMouseUp = fromEvent<MouseEvent>(window, 'mouseup').pipe(
        share()
      );

      // shared window escape key stream
      const escapeKey = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
        filter((evt) => evt.key === 'Escape' || evt.key === 'Esc'),
        share()
      );
      // when state.maniuplatedObject goes from null -> ManipulatedObject
      const itemDragStart = this.stateSubject.pipe(
        map((state) => state.manipulatedObject),
        distinctUntilChanged(),
        filter((manipulatedObject) => manipulatedObject !== null),
        map((manipulatedObject) => {
          const bbox = this.viewportElementRef.current?.getBoundingClientRect();
          if (!bbox)
            throw new Error('Could not get bounding box for viewport element');

          if (!manipulatedObject) throw new Error('No manipulated object');

          const { itemType, itemId, initialPosition } = manipulatedObject;

          const mousePosition = ViewportCoordinates.create(
            initialPosition.clientX - bbox.left,
            initialPosition.clientY - bbox.top
          );
          const mouseOffset = new ViewportVector(
            mousePosition.x - initialPosition.viewport.x,
            mousePosition.y - initialPosition.viewport.y
          );
          return {
            itemType,
            itemId,
            mousePosition,
            mouseOffset,
          };
        })
      );

      this.subscriptions.add(
        escapeKey.subscribe(() => this.dispatch({ type: 'placement.cancel' }))
      );

      this.subscriptions.add(
        itemDragStart
          .pipe(
            switchMap(({ itemType, itemId, mouseOffset }) =>
              combineLatest([
                mouseMovesRelativeToViewport.pipe(
                  map((move) => {
                    const { position, modifiers } = move;

                    const itemPosition = ViewportCoordinates.create(
                      position.x - mouseOffset.x,
                      position.y - mouseOffset.y
                    );
                    return {
                      itemType,
                      itemId,
                      itemPosition,
                      modifiers,
                    };
                  })
                ),
                clock.pipe(),
              ]).pipe(
                map(([{ itemType, itemId, itemPosition, modifiers }]) => {
                  return {
                    itemType,
                    itemId,
                    itemPosition,
                    modifiers,
                  };
                }),
                takeUntil(windowMouseUp.pipe())
              )
            )
          )
          .subscribe(({ itemType, itemId, itemPosition }) => {
            this.dispatch({
              type: 'item.graphic.dragmove',
              itemType,
              itemId,
              itemPosition: ViewportCoordinates.toFloorplanCoordinates(
                itemPosition,
                this.state.viewport,
                this.state.floorplan
              ),
            });
          })
      );

      // edge scrolling
      const edgeScrollVelocity = itemDragStart.pipe(
        switchMap(() =>
          mouseMovesRelativeToViewport.pipe(
            map((move) => {
              const { position } = move;
              const x =
                position.x < EDGE_SCROLL_TRIGGER_WIDTH
                  ? -EDGE_SCROLL_VELOCITY
                  : position.x >
                    this.state.viewport.width - EDGE_SCROLL_TRIGGER_WIDTH
                  ? EDGE_SCROLL_VELOCITY
                  : 0;
              const y =
                position.y < EDGE_SCROLL_TRIGGER_WIDTH
                  ? -EDGE_SCROLL_VELOCITY
                  : position.y >
                    this.state.viewport.height - EDGE_SCROLL_TRIGGER_WIDTH
                  ? EDGE_SCROLL_VELOCITY
                  : 0;

              return new ViewportVector(x, y);
            }),
            distinctUntilChanged(),
            takeUntil(windowMouseUp.pipe())
          )
        )
      );

      this.subscriptions.add(
        edgeScrollVelocity
          .pipe(
            switchMap((velocity) =>
              clock.pipe(
                map((delta) => [velocity, delta] as const),
                takeUntil(windowMouseUp.pipe())
              )
            )
          )
          .subscribe(([velocity, delta]) => {
            this.dispatch({
              type: 'viewport.effect.edgeScroll',
              dx: velocity.x * delta,
              dy: velocity.y * delta,
            });
          })
      );

      this.subscriptions.add(
        itemDragStart
          .pipe(
            switchMap(() =>
              merge(
                windowMouseUp.pipe(map(() => DragEndCondition.END)),
                escapeKey.pipe(map(() => DragEndCondition.CANCEL))
              ).pipe(take(1))
            )
          )
          .subscribe((endCondition) => {
            const { END, CANCEL } = DragEndCondition;
            switch (endCondition) {
              case END: {
                this.dispatch({ type: 'item.graphic.dragend' });
                break;
              }
              case CANCEL: {
                this.dispatch({ type: 'item.graphic.dragcancel' });
                break;
              }
            }
          })
      );
    }

    this.subscriptions.add(
      escapeKey.subscribe(() => this.dispatch({ type: 'placement.cancel' }))
    );

    subscribeToKeybindings(this.keybindings);

    // Connect to websocket event stream
    this.floorplanEventStream = new FloorplanEventStream(
      this.props.client,
      this.props.plan.id,
      { reconnectAutomatically: true }
    );
    this.floorplanEventStream.connect();

    // If the latest dxf changes, reflect those updates in the interface
    const updateLatestDXF = (message: PlanEventMessageDXF) => {
      if (
        message.event !== 'plan.dxf.deleted' &&
        this.state.latestDXF &&
        this.state.latestDXF.status !== 'uploading' &&
        this.state.latestDXF.status !== 'upload_error' &&
        this.state.latestDXF.id === message.plan_dxf_id
      ) {
        this.dispatchImmediately({
          type: 'latestDXF.update',
          planDXF: message.plan_dxf,
        });
      }
    };
    this.floorplanEventStream.on(
      'plan.dxf.created',
      (message: PlanEventMessageDXF) => {
        updateLatestDXF(message);
      }
    );
    this.floorplanEventStream.on(
      'plan.dxf.updated',
      (message: PlanEventMessageDXF) => {
        updateLatestDXF(message);
      }
    );
    this.floorplanEventStream.on(
      'plan.export.updated',
      (message: PlanEventMessageExport) => {
        if (
          message.event !== 'plan.export.deleted' &&
          this.state.activeExport &&
          this.state.activeExport.status !== 'requesting' &&
          this.state.activeExport.status !== 'request-failed' &&
          this.state.activeExport.status !== 'saving' &&
          this.state.activeExport.status !== 'saving-complete' &&
          this.state.activeExport.status !== 'saving-failed' &&
          this.state.activeExport.id === message.plan_export_id
        ) {
          this.dispatchImmediately({
            type: 'export.update',
            planExport: message.plan_export,
          });
        }
      }
    );
  }
  componentWillUnmount() {
    for (const subscription of Array.from(this.subscriptions)) {
      subscription.unsubscribe();
    }
    unsubscribeFromKeybindings(this.keybindings);

    if (this.floorplanEventStream) {
      this.floorplanEventStream.disconnect();
      this.floorplanEventStream = null;
    }

    if (this.coverageIntersectionProcessingWorkerWrapped) {
      this.coverageIntersectionProcessingWorkerWrapped[releaseProxy]();
      this.coverageIntersectionProcessingWorkerWrapped = null;
    }
    if (this.coverageIntersectionProcessingWorker) {
      this.coverageIntersectionProcessingWorker.terminate();
      this.coverageIntersectionProcessingWorker = null;
    }
  }

  componentDidUpdate({ plan: prevPlan }: Props) {
    const { plan, planImage } = this.props;
    if (plan !== prevPlan && !this.disableComponentDidUpdateEffect) {
      // This handles when the plan gets updated via the API.
      // The plan sent to the API is a prop so we have to rerun initializeStateFromPlan
      // in order to update the component with the new plan from the API so that for
      // example, the space and sensor IDs get updated to reflect what's in the DB.
      // Sending in the viewport from component state since that shouldn't get reset.
      // TODO: this should probably perform a more granular update rather than updating
      // the whole plan state. But this problem should go away when the new API is
      // integrated.
      this.setState(
        initializeStateFromPlan(plan, planImage, this.state.viewport)
      );
      this.disableComponentDidUpdateEffect = false;
    }
  }

  render() {
    const { state, dispatch, dispatchImmediately } = this;
    this.stateSubject.next(state);

    // Globally add a way that cypress can interrogate the editor's state
    // This is used by the planning tab "page object" in the cypress tests
    if (
      process.env.REACT_APP_ENABLE_EDITOR_GET_STATE &&
      process.env.REACT_APP_ENABLE_EDITOR_GET_STATE.toLowerCase() === 'true'
    ) {
      (window as any).editorGetState = () => this.state;
      (window as any).editorGetFloorplanRef = () => this.floorplanRef;
    }

    const focusedSensor = State.getFocusedSensor(state);
    const focusedSpace = State.getFocusedSpace(state);
    const focusedPhotoGroup = State.getFocusedPhotoGroup(state);

    if (state.scaleEdit.active) {
      return (
        <FloorSingleView>
          <FloorplanMeasure
            image={state.scaleEdit.floorplanImage}
            measurement={state.measurement}
            renderAsModal={true}
            onSubmit={async (measurement) => {
              if (!state.scaleEdit.active) {
                return;
              }

              // create a plan update with the image_key from the image we just uploaded to S3
              const data = preparePlanUpdate({
                ...state,
                measurement,
                floorplan: {
                  ...state.scaleEdit.floorplan,
                  scale: measurement.computedScale,
                },
              });
              data.image_key = state.scaleEdit.objectKey;
              try {
                await patchPlan(this.props.client, this.props.plan.id, data);
              } catch (err) {
                toast.error('Error updating plan.');
                return;
              }

              dispatch({ type: 'scaleEdit.submit', measurement });
            }}
            onCancel={() =>
              dispatch({
                type: 'scaleEdit.cancel',
              })
            }
          />
        </FloorSingleView>
      );
    }

    if (this.state.latestDXFEdit.active) {
      if (this.state.latestDXFEdit.loading) {
        return <LoadingOverlay text="Loading..." />;
      }

      // When called, make a request to the floorplan api to update the options associated with a
      // plandxf and reparse the plandxf
      const onChangeDXFOptions = async (options: PlanDXF['options']) => {
        if (!this.state.latestDXFEdit.active) {
          return;
        }

        const confirmed = await Dialogger.confirm({
          title: 'Recompute',
          prompt:
            'Adjusting sensor layers requires the file to be reprocessed. Are you sure?',
          confirmText: 'Reprocess',
        });
        if (!confirmed) {
          return;
        }

        dispatch({ type: 'latestDXFEdit.beginAsyncOperation' });

        try {
          await FloorplanAPI.updateAndReprocessDXF(
            this.props.client,
            this.props.plan.id,
            this.state.latestDXFEdit.planDXF.id,
            options
          );
        } catch (err) {
          toast.error('Error reprocessing dxf!');
          return;
        }

        dispatch({ type: 'latestDXFEdit.cancel' });
      };

      const onChangeLengthUnit = async (
        newLengthUnit: PlanDXF['length_unit']
      ) => {
        if (!this.state.latestDXFEdit.active) {
          return;
        }
        const currentLengthUnit = this.state.latestDXFEdit.cadFileUnit;

        dispatch({ type: 'latestDXFEdit.changeUnit', unit: newLengthUnit });
        try {
          await FloorplanAPI.updateDXF(
            this.props.client,
            this.props.plan.id,
            this.state.latestDXFEdit.planDXF.id,
            { length_unit: newLengthUnit }
          );
        } catch (err) {
          toast.error('Error saving scale!');
          dispatch({
            type: 'latestDXFEdit.changeUnit',
            unit: currentLengthUnit,
          });
        }
      };

      const onChangeScale = async (newScale: PlanDXF['scale']) => {
        if (!this.state.latestDXFEdit.active) {
          return;
        }
        const currentScale = this.state.latestDXFEdit.cadFileScale;

        dispatch({ type: 'latestDXFEdit.changeScale', scale: newScale });
        try {
          await FloorplanAPI.updateDXF(
            this.props.client,
            this.props.plan.id,
            this.state.latestDXFEdit.planDXF.id,
            { scale: newScale }
          );
        } catch (err) {
          toast.error('Error saving scale!');
          dispatch({ type: 'latestDXFEdit.changeScale', scale: currentScale });
        }
      };

      if (this.state.floorplanImage) {
        return (
          <CADImport
            mode="update"
            image={this.state.floorplanImage}
            sensors={FloorplanCollection.list(this.state.sensors)}
            initialFloorplan={state.floorplan}
            floorplanCADOrigin={this.state.latestDXFEdit.floorplanCADOrigin}
            planDXF={this.state.latestDXFEdit.planDXF}
            cadFileUnit={this.state.latestDXFEdit.cadFileUnit}
            cadFileScale={this.state.latestDXFEdit.cadFileScale}
            pixelsPerCADUnit={this.state.latestDXFEdit.pixelsPerCADUnit}
            dxfParseOptions={this.state.latestDXFEdit.parseOptions}
            operationType={this.state.latestDXFEdit.operationType}
            displayUnit={this.state.displayUnit}
            onDragMoveFloorplanCADOrigin={(coords) => {
              dispatch({
                type: 'latestDXFEdit.changeFloorplanCADOrigin',
                coords,
              });
            }}
            onChangeCADFileUnit={onChangeLengthUnit}
            onChangeCADFileScale={onChangeScale}
            onChangeOASensorLayer={(layerName) => {
              if (!this.state.latestDXFEdit.active) {
                return;
              }
              onChangeDXFOptions({
                ...this.state.latestDXFEdit.planDXF.options,
                oa: {
                  ...this.state.latestDXFEdit.planDXF.options.oa,
                  layer: layerName,
                },
              });
            }}
            onChangeEntrySensorLayer={(layerName) => {
              if (!this.state.latestDXFEdit.active) {
                return;
              }
              onChangeDXFOptions({
                ...this.state.latestDXFEdit.planDXF.options,
                entry: {
                  ...this.state.latestDXFEdit.planDXF.options.entry,
                  layer: layerName,
                },
              });
            }}
            onChangeOperationType={(operationType) =>
              dispatch({
                type: 'latestDXFEdit.changeOperationType',
                operationType,
              })
            }
            onSubmit={async (
              floorplanChanges,
              _floorplan,
              _floorplanCADOrigin,
              cadFileUnitOrDefault,
              cadFileScaleOrDefault,
              pixelsPerCADUnit
            ) => {
              if (!this.state.latestDXFEdit.active) {
                return;
              }

              if (!this.state.floorplanImage) {
                throw new Error('Floorplan Image is unset.');
              }

              let imageResizeScale = 1;
              let newBaseImage = this.state.floorplanImage;

              // Update the floorplan image, if need be
              if (this.state.latestDXFEdit.operationType !== 'sensors') {
                dispatch({ type: 'latestDXFEdit.beginAsyncOperation' });

                // Get the full image asset url
                const fullImageAsset =
                  this.state.latestDXFEdit.planDXF.assets.find((asset) => {
                    return (
                      asset.name === 'full_image' &&
                      asset.content_type === 'image/png'
                    );
                  });
                if (!fullImageAsset) {
                  throw new Error(
                    `Cannot find full_image asset with content type of image/png for ${this.state.latestDXFEdit.planDXF.id}!`
                  );
                }

                // Wait for full image asset to load
                const fullImageAssetImage = new Image();
                fullImageAssetImage.crossOrigin = 'anonymous';
                fullImageAssetImage.src = cacheBustImageUrl(
                  fullImageAsset.object_url
                );
                await new Promise((resolve) =>
                  fullImageAssetImage.addEventListener('load', resolve)
                );

                // Resize the image to be 4096 x 4096 at max
                const result = resizeImageToMaxSize(fullImageAssetImage);
                const resizedImage = result[0];
                imageResizeScale = result[1] * pixelsPerCADUnit;

                // Wait for image to load
                await new Promise((resolve) =>
                  resizedImage.addEventListener('load', resolve)
                );

                // Upload floorplan image
                let signedUrlResponse;
                try {
                  signedUrlResponse = await FloorplanAPI.imageUpload(
                    this.props.client,
                    {
                      floor_id: this.props.plan.floor.id,
                      ext: 'png',
                      content_type: 'image/png',
                    }
                  );
                } catch (err) {
                  toast.error('Error getting plan image url!');
                  return;
                }

                const {
                  key: objectKey,
                  signed_url: signedUrl,
                  get_signed_url: getSignedUrl,
                } = signedUrlResponse.data;

                const [, imageData] = resizedImage.src.split(',');
                const imageBytesAsString = atob(imageData);
                const byteArray = new Uint8Array(imageBytesAsString.length);
                for (let i = 0; i < imageBytesAsString.length; i++) {
                  byteArray[i] = imageBytesAsString.charCodeAt(i);
                }

                const putImageResponse = await axios.put(
                  signedUrl,
                  byteArray.buffer,
                  {
                    headers: {
                      'Content-Type': 'image/png',
                    },
                  }
                );

                if (putImageResponse.status !== 200) {
                  throw new Error(
                    `Error uploading image, status code was ${putImageResponse.status}`
                  );
                }

                // After uploading the image, use the get signed url to construct the new base image
                newBaseImage = new Image();
                newBaseImage.src = getSignedUrl;
                await new Promise((resolve) =>
                  newBaseImage.addEventListener('load', resolve)
                );

                // create a plan update with the image_key from the image we just uploaded to S3
                const data = preparePlanUpdate(this.state);
                data.image_key = objectKey;
                const patchPlanResponse = await patchPlan(
                  this.props.client,
                  this.props.plan.id,
                  data
                );

                if (patchPlanResponse.status !== 200) {
                  throw new Error(
                    `Error saving uploaded image, status code was ${putImageResponse.status}`
                  );
                }
              }

              const activeDXFId = this.state.latestDXFEdit.planDXF.id;

              dispatch({ type: 'latestDXFEdit.beginAsyncOperation' });

              try {
                await FloorplanAPI.updatePlanActiveDXFId(
                  this.props.client,
                  this.props.plan.id,
                  activeDXFId
                );
              } catch (err) {
                toast.error('Error attaching DXF to floorplan!');
                return;
              }

              dispatchImmediately({
                type: 'latestDXFEdit.applyFloorplanChanges',
                mode: 'update',
                floorplanChanges,
                newBaseImage,
                imageResizeScale,
                activeDXFId,
                cadFileUnitOrDefault,
                cadFileScaleOrDefault,
              });

              this.disableComponentDidUpdateEffect = true;
              this.onSaveClick();
            }}
            onCancel={() => dispatch({ type: 'latestDXFEdit.cancel' })}
          />
        );
      } else {
        return (
          <CADImport
            mode="create"
            cadFileUnit={this.state.latestDXFEdit.cadFileUnit}
            planDXF={this.state.latestDXFEdit.planDXF}
            cadFileScale={this.state.latestDXFEdit.cadFileScale}
            pixelsPerCADUnit={this.state.latestDXFEdit.pixelsPerCADUnit}
            dxfParseOptions={this.state.latestDXFEdit.parseOptions}
            displayUnit={this.state.displayUnit}
            onChangeCADFileUnit={onChangeLengthUnit}
            onChangeCADFileScale={onChangeScale}
            onChangeOASensorLayer={(layerName) => {
              if (!this.state.latestDXFEdit.active) {
                return;
              }
              onChangeDXFOptions({
                ...this.state.latestDXFEdit.planDXF.options,
                oa: {
                  ...this.state.latestDXFEdit.planDXF.options,
                  layer: layerName,
                },
              });
            }}
            onChangeEntrySensorLayer={(layerName) => {
              if (!this.state.latestDXFEdit.active) {
                return;
              }
              onChangeDXFOptions({
                ...this.state.latestDXFEdit.planDXF.options,
                oa: {
                  ...this.state.latestDXFEdit.planDXF.options,
                  layer: layerName,
                },
              });
            }}
            onSubmit={async (
              floorplanChanges,
              _floorplan,
              _floorplanCADOrigin,
              cadFileUnitOrDefault,
              cadFileScaleOrDefault
            ) => {
              if (!this.state.latestDXFEdit.active) {
                return;
              }

              dispatch({ type: 'latestDXFEdit.beginAsyncOperation' });

              // Get the full image asset url
              const fullImageAsset =
                this.state.latestDXFEdit.planDXF.assets.find((asset) => {
                  return (
                    asset.name === 'full_image' &&
                    asset.content_type === 'image/png'
                  );
                });
              if (!fullImageAsset) {
                throw new Error(
                  `Cannot find full_image asset with content type of image/png for ${this.state.latestDXFEdit.planDXF.id}!`
                );
              }

              // Wait for full image asset to load
              const fullImageAssetImage = new Image();
              fullImageAssetImage.crossOrigin = 'anonymous';
              fullImageAssetImage.src = cacheBustImageUrl(
                fullImageAsset.object_url
              );
              await new Promise((resolve) =>
                fullImageAssetImage.addEventListener('load', resolve)
              );

              // Resize the image to be 4096 x 4096 at max
              const [resizedImage, imageResizeScale] =
                resizeImageToMaxSize(fullImageAssetImage);

              // Wait for resized image to load (this _should_ be fast, as the resized image has a
              // base64 src)
              await new Promise((resolve) =>
                resizedImage.addEventListener('load', resolve)
              );

              // Upload floorplan image
              let signedUrlResponse;
              try {
                signedUrlResponse = await FloorplanAPI.imageUpload(
                  this.props.client,
                  {
                    floor_id: this.props.plan.floor.id,
                    ext: 'png',
                    content_type: 'image/png',
                  }
                );
              } catch (err) {
                toast.error('Error uploading image to floorplan api!');
                return;
              }

              const {
                key: objectKey,
                signed_url: signedUrl,
                get_signed_url: getSignedUrl,
              } = signedUrlResponse.data;

              const [, imageData] = resizedImage.src.split(',');
              const imageBytesAsString = atob(imageData);
              const byteArray = new Uint8Array(imageBytesAsString.length);
              for (let i = 0; i < imageBytesAsString.length; i++) {
                byteArray[i] = imageBytesAsString.charCodeAt(i);
              }

              const putImageResponse = await axios.put(
                signedUrl,
                byteArray.buffer,
                {
                  headers: {
                    'Content-Type': 'image/png',
                  },
                }
              );

              if (putImageResponse.status !== 200) {
                throw new Error(
                  `Error uploading image, status code was ${putImageResponse.status}`
                );
              }

              // After uploading the image, use the get signed url to construct the new base image
              const newBaseImage = new Image();
              newBaseImage.src = getSignedUrl;
              await new Promise((resolve) =>
                newBaseImage.addEventListener('load', resolve)
              );

              // create a plan update with the image_key from the image we just uploaded to S3
              const data = preparePlanUpdate(this.state);
              data.image_key = objectKey;
              const patchPlanResponse = await patchPlan(
                this.props.client,
                this.props.plan.id,
                data
              );

              if (patchPlanResponse.status !== 200) {
                throw new Error(
                  `Error saving uploaded image, status code was ${putImageResponse.status}`
                );
              }

              const activeDXFId = this.state.latestDXFEdit.planDXF.id;
              try {
                await FloorplanAPI.updatePlanActiveDXFId(
                  this.props.client,
                  this.props.plan.id,
                  activeDXFId
                );
              } catch (err) {
                toast.error('Error attaching DXF to floorplan!');
                return;
              }

              dispatchImmediately({
                type: 'latestDXFEdit.applyFloorplanChanges',
                mode: 'create',
                floorplanChanges,
                newBaseImage,
                imageResizeScale,
                activeDXFId,
                cadFileUnitOrDefault,
                cadFileScaleOrDefault,
              });

              this.disableComponentDidUpdateEffect = true;
              this.onSaveClick();
            }}
            onCancel={() => dispatch({ type: 'latestDXFEdit.cancel' })}
          />
        );
      }
    }

    if (
      !state.floorplanImage ||
      (state.measurement && state.measurement.computedLength === 0)
    ) {
      return (
        <Fragment>
          <AppBarFloorplan />
          <FloorSingleView>
            {state.latestDXF ? (
              <div className={styles.noFloorplanImageMessage}>
                <div className={styles.noFloorplanImageMessageHeader}>
                  DXF Processing:
                </div>
                <div className={styles.noFloorplanImageMessageBody}>
                  <LatestDXFStatus
                    state={{ ...state, activeDXFId: null }}
                    client={this.props.client}
                    plan={this.props.plan}
                    dispatch={this.dispatchImmediately}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.noFloorplanImageMessage}>
                <div className={styles.noFloorplanImageMessageHeader}>
                  No floorplan image
                </div>
                <div className={styles.noFloorplanImageMessageBody}>
                  The floorplan image is missing.
                </div>
              </div>
            )}
          </FloorSingleView>
        </Fragment>
      );
    }

    if (state.heightMapImport.view === 'enabled') {
      return <HeightMapImport state={state} dispatch={dispatch} />;
    }

    return (
      <Fragment>
        <AppBarFloor />
        <FloorSingleView>
          <div className={styles.editor}>
            {/* MAIN VIEW */}
            <div
              ref={this.viewportElementRef}
              className={styles.editorViewport}
            >
              {this.props.treatments[SPLITS.PLANNING_FLOORPLAN_COMPONENT] ===
              'on' ? (
                // New floorplan component!
                <Floorplan
                  ref={this.floorplanRef}
                  image={state.floorplanImage}
                  floorplan={state.floorplan}
                  width="100%"
                  height="100%"
                  lengthUnit={state.displayUnit}
                  onClickBackground={(evt) => {
                    const position = ViewportCoordinates.create(
                      evt.data.global.x,
                      evt.data.global.y
                    );
                    dispatchImmediately({
                      type: 'viewport.mousedown',
                      position,
                    });
                  }}
                >
                  {this.props.treatments[SPLITS.AUTOLAYOUT] === 'on' &&
                  state.autoLayout.active ? (
                    <AutoLayoutLayer
                      autoLayout={state.autoLayout}
                      autoLayoutSensorPositions={
                        state.autoLayoutSensorPositions
                      }
                      heightMap={state.heightMap}
                      onPlaceVertex={(vertex) =>
                        this.dispatch({
                          type: 'autoLayout.placeVertex',
                          vertex,
                        })
                      }
                      onChangeVertexPosition={(index, vertex) =>
                        this.dispatch({
                          type: 'autoLayout.changeVertexPosition',
                          index,
                          vertex,
                        })
                      }
                      onCompleteBoundingRegion={() =>
                        this.dispatch({
                          type: 'autoLayout.completeBoundingRegion',
                        })
                      }
                      onChangeOriginPosition={(origin) => {
                        this.dispatch({
                          type: 'autoLayout.changeOriginPosition',
                          origin,
                        });
                      }}
                    />
                  ) : null}
                  {this.props.treatments[SPLITS.HEIGHT_MAP] === 'on' &&
                  state.heightMap.enabled &&
                  state.planning.showCeilingHeightMap ? (
                    <HeightMapLayer heightMap={state.heightMap} />
                  ) : null}
                  <ObjectPlacementTargetLayer
                    placementMode={state.placementMode}
                    onMouseDown={(position) =>
                      dispatchImmediately({ type: 'placement.click', position })
                    }
                    onMouseMove={(position, evt, viewport, floorplan) => {
                      dispatch({
                        type: 'placement.mousemove',
                        position,
                        viewport,
                        floorplan,
                        shiftKey: evt.shiftKey,
                      });
                    }}
                  />
                  <PolygonalSpaceCreationLayer
                    placementMode={state.placementMode}
                  />
                  <SensorsLayer
                    sensors={FloorplanCollection.listInRenderOrder(
                      this.state.sensors
                    ).filter((sensor) => {
                      // Don't render entry / oa sensors if they should be hidden
                      if (
                        sensor.type === 'oa' &&
                        !state.planning.showOASensors
                      ) {
                        return false;
                      }
                      if (
                        sensor.type === 'entry' &&
                        !state.planning.showEntrySensors
                      ) {
                        return false;
                      }
                      return true;
                    })}
                    highlightedObject={state.highlightedObject}
                    focusedObject={state.focusedObject}
                    heightMap={state.heightMap}
                    coverageIntersectionEnabled={
                      this.props.treatments[SPLITS.SENSOR_COVERAGE] === 'on' &&
                      state.planning.showSensorCoverageExtents
                    }
                    coverageIntersectionVectors={
                      state.sensorCoverageIntersectionVectors
                    }
                    hideOpenAreaCoverage={!state.planning.showSensorCoverage}
                    hideSensorLabels={!state.planning.showSensorLabels}
                    onMouseEnter={(sensor) => {
                      dispatchImmediately({
                        type: 'item.graphic.mouseenter',
                        itemType: 'sensor',
                        itemId: sensor.id,
                      });
                    }}
                    onMouseLeave={(sensor) => {
                      dispatchImmediately({
                        type: 'item.graphic.mouseleave',
                        itemType: 'sensor',
                        itemId: sensor.id,
                      });
                    }}
                    onMouseDown={(sensor, evt) => {
                      const viewportPosition =
                        FloorplanCoordinates.toViewportCoordinates(
                          sensor.position,
                          state.floorplan,
                          state.viewport
                        );
                      dispatchImmediately({
                        type: 'item.graphic.mousedown',
                        itemType: 'sensor',
                        itemId: sensor.id,
                        itemPosition: viewportPosition,
                        clientX: evt.data.global.x,
                        clientY: evt.data.global.y,
                      });
                    }}
                    onDragMove={(sensor, itemPosition) => {
                      dispatchImmediately({
                        type: 'item.graphic.dragmove',
                        itemType: 'sensor',
                        itemId: sensor.id,
                        itemPosition,
                      });
                    }}
                    onDragEnd={(sensor) => {
                      dispatchImmediately({ type: 'item.graphic.dragend' });
                    }}
                  />
                  {state.sensorConnections.size > 0 ? (
                    <AggregatedPointsLayer
                      gridSize={state.gridSize}
                      colorScaleDomain={state.visualization.colorScaleDomain}
                      snrThreshold={state.visualization.snrThreshold}
                      planId={this.props.plan.id}
                      sensors={state.sensors}
                      sensorConnections={state.sensorConnections}
                      onChangeSensorConnection={(id, sensorConnection) => {
                        dispatchImmediately({
                          type: 'sensorConnection.update',
                          id,
                          sensorConnection,
                        });
                      }}
                    />
                  ) : null}
                  <ObjectMeasureLayer
                    focusedObject={state.focusedObject}
                    sensors={state.sensors}
                  />
                  {state.planning.showSpaces ? (
                    <SpacesLayer
                      spaces={FloorplanCollection.listInRenderOrder(
                        this.state.spaces
                      )}
                      highlightedObject={state.highlightedObject}
                      focusedObject={state.focusedObject}
                      spaceOccupancy={state.spaceOccupancy}
                      onMouseEnter={(space) => {
                        dispatchImmediately({
                          type: 'item.graphic.mouseenter',
                          itemType: 'space',
                          itemId: space.id,
                        });
                      }}
                      onMouseLeave={(space) => {
                        dispatchImmediately({
                          type: 'item.graphic.mouseleave',
                          itemType: 'space',
                          itemId: space.id,
                        });
                      }}
                      onMouseDown={(space, evt) => {
                        const viewportPosition =
                          FloorplanCoordinates.toViewportCoordinates(
                            space.position,
                            state.floorplan,
                            state.viewport
                          );
                        dispatchImmediately({
                          type: 'item.graphic.mousedown',
                          itemType: 'space',
                          itemId: space.id,
                          itemPosition: viewportPosition,
                          clientX: evt.data.global.x,
                          clientY: evt.data.global.y,
                        });
                      }}
                      onDragMove={(space, itemPosition) => {
                        dispatchImmediately({
                          type: 'item.graphic.dragmove',
                          itemType: 'space',
                          itemId: space.id,
                          itemPosition,
                        });
                      }}
                      onResizeBoxSpace={(
                        space,
                        newPosition,
                        newWidth,
                        newHeight
                      ) => {
                        dispatchImmediately({
                          type: 'space.resize.box',
                          id: space.id,
                          position: newPosition,
                          width: newWidth,
                          height: newHeight,
                        });
                      }}
                      onResizeCircleSpace={(space, newPosition, newRadius) => {
                        dispatchImmediately({
                          type: 'space.resize.circle',
                          id: space.id,
                          position: newPosition,
                          radius: newRadius,
                        });
                      }}
                      onResizePolygonSpace={(
                        space,
                        newPosition,
                        newVertices
                      ) => {
                        dispatchImmediately({
                          type: 'space.resize.polygon',
                          id: space.id,
                          position: newPosition,
                          vertices: newVertices,
                        });
                      }}
                    />
                  ) : null}
                  {state.planning.showRulers ? (
                    <ReferenceRulersLayer
                      referenceRulers={
                        FloorplanCollection.listInRenderOrder(
                          this.state.references
                        ).filter(
                          (r) => r.type !== 'point'
                        ) as Array<ReferenceRuler>
                      }
                      highlightedObject={state.highlightedObject}
                      onEndpointsMoved={(
                        reference,
                        positionA,
                        positionB,
                        distanceLabelPosition
                      ) => {
                        dispatchImmediately({
                          type: 'reference.rulerPosition.change',
                          id: reference.id,
                          positionA,
                          positionB,
                          distanceLabelPosition,
                        });
                      }}
                    />
                  ) : null}
                  {state.planning.showPhotoGroups ? (
                    <PhotoGroupsLayer
                      photoGroups={FloorplanCollection.listInRenderOrder(
                        this.state.photoGroups
                      )}
                      focusedPhotoGroupId={state.focusedPhotoGroupId}
                      highlightedObject={state.highlightedObject}
                      onMouseEnter={(photoGroup) => {
                        dispatch({
                          type: 'item.graphic.mouseenter',
                          itemType: 'photogroup',
                          itemId: photoGroup.id,
                        });
                      }}
                      onMouseLeave={(photoGroup) => {
                        dispatch({
                          type: 'item.graphic.mouseleave',
                          itemType: 'photogroup',
                          itemId: photoGroup.id,
                        });
                      }}
                      onMouseDown={(photoGroup, evt) => {
                        const viewportPosition =
                          FloorplanCoordinates.toViewportCoordinates(
                            photoGroup.position,
                            state.floorplan,
                            state.viewport
                          );
                        dispatch({
                          type: 'item.graphic.mousedown',
                          itemType: 'photogroup',
                          itemId: photoGroup.id,
                          itemPosition: viewportPosition,
                          clientX: evt.data.global.x,
                          clientY: evt.data.global.y,
                        });
                      }}
                      onDragMove={(photoGroup, itemPosition) => {
                        dispatchImmediately({
                          type: 'photoGroup.dragmove',
                          id: photoGroup.id,
                          itemPosition,
                        });
                      }}
                    />
                  ) : null}
                  {state.planning.showScale ? (
                    <FloorplanScaleLayer measurement={state.measurement} />
                  ) : null}
                </Floorplan>
              ) : (
                // Old floorplan component!
                <FloorplanViewport
                  planImage={state.floorplanImage}
                  plan={this.props.plan}
                  state={state}
                  dispatch={dispatch}
                />
              )}
              {/* FOCUSED SENSOR PANEL */}
              {focusedSensor ? (
                <FocusedSensorPanel
                  key={focusedSensor.id}
                  state={state}
                  sensor={focusedSensor}
                  dispatch={dispatch}
                  client={this.props.client}
                />
              ) : null}
              {/* FOCUSED SPACE PANEL */}
              {focusedSpace ? (
                <FocusedSpacePanel
                  key={focusedSpace.id}
                  space={focusedSpace}
                  displayUnit={state.displayUnit}
                  dispatch={dispatch}
                />
              ) : null}
              {/* FOCUSED PHOTO GROUP PANEL */}
              {focusedPhotoGroup ? (
                <FocusedPhotoGroupPanel
                  key={focusedPhotoGroup.id}
                  photoGroup={focusedPhotoGroup}
                  planId={this.props.plan.id}
                  dispatch={dispatch}
                  client={this.props.client}
                />
              ) : null}
              {/* FOCUSED HEIGHTMAP LAYER PANEL */}
              {State.isLayerFocused(state, LayerId.HEIGHTMAP) ? (
                <FocusedHeightmapLayerPanel
                  state={state}
                  dispatch={dispatch}
                  plan={this.props.plan}
                  client={this.props.client}
                />
              ) : null}

              {state.autoLayout.active ? (
                <AutoLayoutPanel state={state} dispatch={dispatch} />
              ) : null}

              <div className={styles.floorplanControls}>
                <HorizontalForm size="medium">
                  {this.props.treatments[SPLITS.AUTOLAYOUT] === 'on' ? (
                    <Tooltip
                      contents="Autolayout"
                      placement="bottom"
                      enterDelay={0}
                      target={
                        <Button
                          onClick={() => dispatch({ type: 'autoLayout.begin' })}
                          trailingIcon={
                            <Icons.Integrations2
                              width={18}
                              height={18}
                              color="currentColor"
                            />
                          }
                          size="medium"
                          type="outlined"
                        />
                      }
                    />
                  ) : null}
                  {state.showInternalTool &&
                  this.props.treatments[SPLITS.OA_LIVE_CONNECTION] === 'off' ? (
                    <InternalToolingPanel state={state} dispatch={dispatch} />
                  ) : null}
                  {/* Simulants are being deprecated with the new floorplan component */}
                  {this.props.treatments[
                    SPLITS.PLANNING_FLOORPLAN_COMPONENT
                  ] !== 'on' ? (
                    <InternalTool>
                      <SimulationPanel
                        simulation={state.simulation}
                        dispatch={dispatch}
                      />
                    </InternalTool>
                  ) : null}
                  {this.props.treatments[SPLITS.EXPORT_DXF] === 'on' ? (
                    <ExportPanel
                      state={state}
                      dispatch={dispatch}
                      client={this.props.client}
                      plan={this.props.plan}
                      floorName={this.getTitle()}
                    />
                  ) : null}
                  <Tooltip
                    contents="Export Planner Image"
                    placement="bottom"
                    enterDelay={0}
                    target={
                      <Button
                        onClick={this.onExportClick}
                        trailingIcon={
                          <Icons.Camera
                            width={18}
                            height={18}
                            color="currentColor"
                          />
                        }
                        size="medium"
                        type="outlined"
                      />
                    }
                  />
                  <Tooltip
                    contents="Show Raw Floorplan"
                    placement="bottom"
                    enterDelay={0}
                    target={
                      <Button
                        onClick={this.onShowRawFloorplanClick}
                        trailingIcon={
                          <Icons.ImageAlt
                            width={18}
                            height={18}
                            color="currentColor"
                          />
                        }
                        size="medium"
                        type="outlined"
                      />
                    }
                  />
                  {_PROFILE_MODE ? (
                    <Tooltip
                      contents="Download profiling data"
                      placement="bottom"
                      enterDelay={0}
                      target={
                        <Button
                          onClick={this.onDownloadProfileClick}
                          trailingIcon={
                            <Icons.Ruler
                              width={18}
                              height={18}
                              color="currentColor"
                            />
                          }
                          size="medium"
                          type="outlined"
                        />
                      }
                    />
                  ) : null}
                  <SettingsPanel
                    state={state}
                    dispatch={dispatch}
                    tokenCheckResponse={this.props.tokenCheckResponse}
                  />
                  <SaveButton
                    saveHandler={this.onSaveClick}
                    savePending={state.savePending}
                  />
                </HorizontalForm>
              </div>

              <div className={styles.mapControls}>
                <div className={styles.mapControlsInner}>
                  <KeyboardShortcutsMenu.Controller
                    render={(props) => {
                      return <KeyboardShortcutsMenu {...props} />;
                    }}
                  />
                  <Tooltip
                    contents="Zoom to fit"
                    placement="bottom"
                    enterDelay={0}
                    target={
                      <Button
                        size="medium"
                        type="outlined"
                        onClick={this.onZoomToFitClick}
                        data-cy="zoom-to-fit"
                        trailingIcon={
                          <Icons.ZoomToFit
                            width={18}
                            height={18}
                            color="currentColor"
                          />
                        }
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.sidebar}>
              {/* OBJECTS PANEL */}
              <FloorplanObjectsList
                state={state}
                client={this.props.client}
                plan={this.props.plan}
                dispatch={dispatch}
              />
            </div>

            {/* KEYBOARD SHORTCUTS MENU */}
            {Array.from(state.spaces.items.values()).map((space) => {
              return (
                <SpaceObserver
                  key={space.id}
                  space={space}
                  data={state.aggregatedPointsData}
                  dispatch={dispatch}
                />
              );
            })}
            {this.props.treatments[SPLITS.PLANNING_FLOORPLAN_COMPONENT] !== 'on'
              ? Array.from(state.sensors.items.values()).map((sensor) => {
                  const sensorConnection =
                    state.sensorConnections.get(sensor.id) || null;
                  return (
                    <React.Fragment key={sensor.id}>
                      <SensorDataEmitter
                        planId={this.props.plan.id}
                        sensor={sensor}
                        sensorConnection={sensorConnection}
                        dispatch={dispatch}
                      />
                      {state.simulation.enabled && sensor.type === 'oa' ? (
                        <SensorSimulationObserver
                          sensor={sensor}
                          simulants={state.simulants}
                          dispatch={dispatch}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })
              : null}

            {/* TODO: uncomment when fully implemented */}
            {/* <InternalTool>
              <CapturePanel
                capture={state.capture}
                sensors={state.sensors.items}
                dispatch={dispatch}
                client={this.props.client}
              />
            </InternalTool> */}
          </div>
        </FloorSingleView>
      </Fragment>
    );
  }
}

export default Editor;
