import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';
import ClearButton from 'components/clear-button/clear-button';
import { NewSpaceFunction, SPACE_FUNCTION_ICONS } from 'lib/space-functions';

type SpaceFunctionChipInfo = {
  id: NewSpaceFunction;
  label: string;
  quantity: number;
  // These are practically going to be Blue400, but no easy way to strictly type
  hoverColor: string;
  isFunction: true;
};
type SpaceLabelChipInfo = {
  id: string;
  label: string;
  quantity: number;
  hoverColor: string;
  isFunction: false;
};
type SpaceFilterChipInfo = SpaceFunctionChipInfo | SpaceLabelChipInfo;

function SpaceFilterChip({
  id,
  label,
  quantity,
  hoverColor,
  isFunction,
  onMouseOver,
  onMouseOut,
  onDelete,
}: {
  onMouseOver: () => void;
  onMouseOut: () => void;
  onDelete: () => void;
} & SpaceFilterChipInfo) {
  // Not sure why this doesn't understand `id` based on the discriminated union
  const Icon = isFunction ? SPACE_FUNCTION_ICONS[id as NewSpaceFunction] : null;

  return (
    <div
      key={id}
      css={css`
        width: fit-content;
        cursor: default;
        display: flex;
        align-items: center;
        background: ${dust.White};
        color: ${dust.Gray700};
        box-shadow: ${dust.Elevation300};
        padding: ${dust.Space2} ${dust.Space3};
        border-radius: ${dust.Radius400};
        font-size: ${dust.TextScale3};
        line-height: ${dust.TextLineHeight3};
        font-weight: ${dust.TextWeight3};

        &:hover {
          background: ${hoverColor};
          color: ${dust.White};
        }

        &:hover > .labelIcon {
          color: ${dust.White};
        }

        &:hover > .labelDelete {
          color: ${dust.White};
        }
      `}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {isFunction && Icon ? (
        <div
          css={css`
            color: ${dust.Blue400};
            transform: translateY(1px);
            margin: -4px 0;
          `}
          className="labelIcon"
        >
          <Icon height={16} color="currentColor" />
        </div>
      ) : null}

      <span
        css={css`
          white-space: nowrap;
          user-select: none;
        `}
        className="labelText"
      >
        {label}
      </span>
      <div
        css={css`
          background: ${dust.Gray000};
          color: ${dust.Gray600};
          padding: 0 ${dust.Space2};
          margin: 0 ${dust.Space2};
          border-radius: ${dust.Radius300};

          font-size: ${dust.TextScale2};
          line-height: ${dust.TextLineHeight2};
          font-weight: ${dust.TextWeight2};
        `}
      >
        {quantity}
      </div>
      <div
        css={css`
          cursor: pointer;
          transform: translateY(-1px);
          font-size: ${dust.TextScale4};
          color: ${dust.Gray300};
        `}
        className="labelDelete"
        onClick={onDelete}
      >
        ×
      </div>
    </div>
  );
}

export type SpaceFunctionInfo = Omit<SpaceFunctionChipInfo, 'isFunction'>;
export type LabelInfo = Omit<SpaceLabelChipInfo, 'isFunction'>;

export default function SpaceFilterChipBar({
  spaceFunctions,
  labels,

  onHighlightSpaceFunction,
  onUnhighlightSpaceFunction,
  onDeleteSpaceFunction,
  onHighlightLabel,
  onUnhighlightLabel,
  onDeleteLabel,
  onClear,
}: {
  spaceFunctions: Array<SpaceFunctionInfo>;
  labels: Array<LabelInfo>;
  onHighlightSpaceFunction: (id: NewSpaceFunction, color: string) => void;
  onUnhighlightSpaceFunction: (id: NewSpaceFunction) => void;
  onDeleteSpaceFunction: (id: NewSpaceFunction) => void;
  onHighlightLabel: (id: string, color: string) => void;
  onUnhighlightLabel: (id: string) => void;
  onDeleteLabel: (id: string) => void;
  onClear: () => void;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${dust.Space3};
        flex-wrap: wrap;
      `}
    >
      {spaceFunctions.map((chip) => (
        <SpaceFilterChip
          key={chip.id}
          id={chip.id}
          label={chip.label}
          quantity={chip.quantity}
          hoverColor={chip.hoverColor}
          isFunction={true}
          onMouseOver={() => onHighlightSpaceFunction(chip.id, chip.hoverColor)}
          onMouseOut={() => onUnhighlightSpaceFunction(chip.id)}
          onDelete={() => onDeleteSpaceFunction(chip.id)}
        />
      ))}

      {!!spaceFunctions.length && !!labels.length ? (
        <span
          css={css`
            color: ${dust.Gray400};
          `}
        >
          |
        </span>
      ) : null}

      {labels.map((chip) => (
        <SpaceFilterChip
          key={chip.id}
          id={chip.id}
          label={chip.label}
          quantity={chip.quantity}
          hoverColor={chip.hoverColor}
          isFunction={false}
          onMouseOver={() => onHighlightLabel(chip.id, chip.hoverColor)}
          onMouseOut={() => onUnhighlightLabel(chip.id)}
          onDelete={() => onDeleteLabel(chip.id)}
        />
      ))}

      {!!spaceFunctions.length || !!labels.length ? (
        <ClearButton
          onClick={(e) => {
            e.preventDefault();

            onClear();
          }}
        >
          <span>Clear all</span>
        </ClearButton>
      ) : null}
    </div>
  );
}
