import { useMemo } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { Icons } from '@densityco/ui';

import styles from './app-bar-building.module.scss';

import { Building, isBuilding } from 'lib/buildings';
import { ascending } from 'lib/sort';
import AppBarSelectBox from 'components/app-bar-select-box/app-bar-select';
import { useAppSelector } from 'redux/store';
import { spacesSelectors } from 'redux/features/spaces/spaces-slice';

const AppBarBuilding: React.FunctionComponent<{
  building: Building;
}> = ({ building }) => {
  const history = useHistory();

  const spaces = useAppSelector(spacesSelectors.selectAll);

  const buildings = useMemo(() => {
    return spaces.filter(isBuilding).sort((a, b) => ascending(a.name, b.name));
  }, [spaces]);

  return (
    <div className={styles.AppBarBuilding}>
      <AppBarSelectBox
        value={building}
        options={buildings}
        icon={
          <div style={{ marginLeft: 4 }}>
            <Icons.Building width={20} height={20} />
          </div>
        }
        onChange={(building) => {
          history.push(`/buildings/${building.id}`);
        }}
      />

      <a
        href={`https://dashboard.density.io/#/admin/locations/${building.id}/create/floor`}
        className={classNames([
          styles.buttonSmall,
          styles.primary,
          styles.elevated,
        ])}
      >
        Add a Floor
      </a>
    </div>
  );
};
export default AppBarBuilding;
