import { Draft, Patch, produceWithPatches } from 'immer';

import { RootState } from 'redux/store';
import { Brand } from 'types/brand';

type MaybeDrafted<T> = T | Draft<T>;
type Recipe<T> = (data: MaybeDrafted<T>) => void | MaybeDrafted<T>;

// this way we don't mistakenly pass invalid patches to other slices of state
export type SpacesPatch = Brand<Patch, 'spaces'>;

export const getSpacesPatches = (
  base: RootState['spaces'],
  recipe: Recipe<RootState['spaces']>
) => {
  const [, patches, inversePatches] = produceWithPatches(base, recipe);

  return [patches as SpacesPatch[], inversePatches as SpacesPatch[]] as const;
};

export type PlansPatch = Brand<Patch, 'plans'>;

export const getPlansPatches = (
  base: RootState['plans'],
  recipe: Recipe<RootState['plans']>
) => {
  const [, patches, inversePatches] = produceWithPatches(base, recipe);

  return [patches as PlansPatch[], inversePatches as PlansPatch[]] as const;
};
