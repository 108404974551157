import { useCallback, useState } from 'react';
import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { ViewportCoordinates } from 'lib/geometry';

const LEADER_LINE_LENGTH_PX = 8;

const LeaderLine: React.FunctionComponent<{
  metricLabelCenter: ViewportCoordinates;
  metricLabelBackgroundWidth: number;
  pointAt: ViewportCoordinates;
  color: string;
}> = ({ metricLabelCenter, metricLabelBackgroundWidth, pointAt, color }) => {
  let path = '';

  if (pointAt.x > metricLabelCenter.x) {
    // Draw the leader line to the right
    const leaderLineStartX =
      metricLabelCenter.x + metricLabelBackgroundWidth / 2;
    path += `
      M${leaderLineStartX},${metricLabelCenter.y}
      L${leaderLineStartX + LEADER_LINE_LENGTH_PX},${metricLabelCenter.y}
    `;
  } else {
    // Draw the leader line to the left
    const leaderLineStartX =
      metricLabelCenter.x - metricLabelBackgroundWidth / 2;
    path += `
      M${leaderLineStartX},${metricLabelCenter.y}
      L${leaderLineStartX - LEADER_LINE_LENGTH_PX},${metricLabelCenter.y}
    `;
  }

  return (
    <path
      d={path + `L${pointAt.x},${pointAt.y}`}
      stroke={color}
      fill="transparent"
      strokeWidth={1.5}
      markerEnd="url(#leader-line-arrow)"
    />
  );
};

export const MetricLabel: React.FunctionComponent<{
  labelText: string;
  centerPoint: ViewportCoordinates;
  pointAt?: ViewportCoordinates;
  color?: string;
  opacity?: number;
  showDistanceLeaderLine?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseDown?: (evt: any) => void;
}> = ({
  labelText,
  centerPoint,
  pointAt,
  color = colors.blue,
  opacity = 1,
  showDistanceLeaderLine = false,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
}) => {
  const [labelBackgroundWidth, setLabelBackgroundWidth] = useState<number>(44);

  const labelRef = useCallback(
    (node: SVGTextElement) => {
      if (node !== null) {
        setLabelBackgroundWidth(node.getBBox().width + 16);
      }
    },
    // labelBackgroundWidth updates when the label text changes
    // it's needed as a dependency, but not used explicitly
    //eslint-disable-next-line
    [labelText]
  );

  return (
    <g>
      <g
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        opacity={opacity}
        style={{ cursor: onMouseDown ? 'move' : undefined }}
      >
        <rect
          x={centerPoint.x - labelBackgroundWidth / 2}
          y={centerPoint.y - 8}
          width={labelBackgroundWidth}
          height={16}
          ry={8}
          fill={color}
        />
        <text
          x={centerPoint.x}
          y={centerPoint.y + 4}
          ref={labelRef}
          textAnchor={'middle'}
          fontSize={12}
          fontWeight={'bold'}
          fill={colors.white}
          style={{ userSelect: 'none' }}
        >
          {labelText}
        </text>
      </g>

      {/* Render a leader line if the pointing location is different than the center location of the label */}
      {pointAt && showDistanceLeaderLine ? (
        <LeaderLine
          metricLabelCenter={centerPoint}
          metricLabelBackgroundWidth={labelBackgroundWidth}
          pointAt={pointAt}
          color={color}
        />
      ) : null}
    </g>
  );
};
