import { useCallback, useEffect, useRef, useMemo } from 'react';
import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import {
  FloorplanCollection,
  SensorType,
  State,
  getCursorForObject,
  getSensorStatusColor,
} from './state';
import { Action } from './actions';
import SpaceGraphic, { SpacePolygonCreationGraphic } from './space-graphic';
import {
  ReferencePointGraphic,
  ReferenceRulerGraphic,
} from './reference-graphic';
import PhotoGroupGraphic from './photo-group-graphic';
import SimulantGraphic from './simulant-graphic';
import ObjectPlacementTarget from './object-placement-target';
import styles from './styles.module.scss';
import { FloorplanRulerGraphic } from './floorplan-ruler-graphic';

import { useKeyState } from 'lib/keyboard';
import {
  computeCoverageRadiusOA,
  computeCoverageRadiusEntry,
} from 'lib/sensor';
import { displayLength } from 'lib/units';
import SensorGraphic from 'components/sensor-graphic';
import FloorplanImageView from 'components/floorplan-image-view/floorplan-image-view';
import { addDragListener } from 'lib/drag';
import {
  FloorplanCoordinates,
  ImageCoordinates,
  ViewportCoordinates,
} from 'lib/geometry';
import TrackVisualizer from 'components/track-visualizer';
import HeatmapVisualizer from 'components/heatmap-visualizer';
import { PlanDetail } from 'lib/api';

const PLACEMENT_TOOLTIP_OFFSET_X_PX = 16;

const FloorplanViewport: React.FunctionComponent<{
  plan: PlanDetail;
  planImage: HTMLImageElement;
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ plan, state, dispatch, planImage }) => {
  const containerElementRef = useRef<HTMLDivElement>(null);
  const svgElementRef = useRef<SVGSVGElement>(null);

  const { floorplan, viewport } = state;
  const width = viewport.width;
  const height = viewport.height;

  const spaceKeyPressed = useKeyState(' ');

  // This effect is used to register the mouse wheel event handling
  useEffect(() => {
    const elem = svgElementRef.current;
    if (!elem) throw new Error('Unable to get overlay element from ref');

    const onOverlayWheel = (evt: WheelEvent) => {
      evt.preventDefault();
      const bbox = elem.getBoundingClientRect();
      const dx = evt.deltaX;
      const dy = evt.deltaY;
      const { altKey, ctrlKey, metaKey, shiftKey } = evt;
      const position = ViewportCoordinates.create(
        evt.clientX - bbox.left,
        evt.clientY - bbox.top
      );
      dispatch({
        type: 'viewport.scrollwheel',
        position,
        dx,
        dy,
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
      });
    };

    // This is why we're using an effect rather than attaching a handler in the JSX,
    // we need to set { passive: false } on the listener
    elem.addEventListener('wheel', onOverlayWheel, { passive: false });

    return () => {
      elem.removeEventListener('wheel', onOverlayWheel);
    };
  }, [svgElementRef, viewport, dispatch]);

  const onViewportMouseDown = useCallback(
    (evt: React.MouseEvent<Element>) => {
      // prevents text highlight when there is a double-click
      evt.preventDefault();

      // When clicking on the viewport, deselect any other focused elements
      if (document.activeElement) {
        (document.activeElement as any).blur();
      }

      const elem = evt.currentTarget;
      const bbox = elem.getBoundingClientRect();
      const position = ViewportCoordinates.create(
        evt.clientX - bbox.left,
        evt.clientY - bbox.top
      );
      dispatch({ type: 'viewport.mousedown', position });
      if (spaceKeyPressed) {
        dispatch({ type: 'viewport.dragstart' });

        const unregister = addDragListener(
          evt.clientX,
          evt.clientY,
          (dx, dy) => {
            dispatch({ type: 'viewport.dragmove', dx, dy });
          },
          () => {
            dispatch({ type: 'viewport.dragend' });
          }
        );

        return () => {
          unregister();
        };
      }
    },
    [spaceKeyPressed, dispatch]
  );

  const cursorParams = state.cursorOverride
    ? {
        cursor: state.cursorOverride,
      }
    : {};

  let addPlacementText = null;

  if (state.placementMode) {
    switch (state.placementMode.type) {
      case 'sensor':
        const sensorTypeText = SensorType.generateDisplayName(
          state.placementMode.sensorType
        );
        addPlacementText = `Click to add an ${sensorTypeText} ${state.placementMode.type}`;
        break;
      case 'space':
        if (state.placementMode.shape === 'polygon') {
          if (state.placementMode.vertices.length === 0) {
            addPlacementText = 'Click to add your first point';
          } else if (state.placementMode.nextPointSelfIntersection) {
            addPlacementText = 'Cannot place a point here';
          } else if (state.placementMode.mouseOverFinalPoint) {
            addPlacementText = 'Click to complete the shape';
          } else {
            addPlacementText = 'Click to add your next point';
          }
        } else if (state.placementMode.shape === 'polygon-duplicate') {
          addPlacementText = `Click to add a polygon space`;
        } else {
          addPlacementText = `Click to add a ${state.placementMode.shape} ${state.placementMode.type}`;
        }
        break;
      case 'reference':
        addPlacementText = `Click to add a ${state.placementMode.type} point`;
        break;
      case 'photogroup':
        addPlacementText = 'Click to add a photo group';
        break;
      default:
        addPlacementText = `Click to add a ${state.placementMode.type}`;
        break;
    }
  }

  const placementTooltipStyles = useMemo(() => {
    if (!state.placementMode || !state.placementMode.mousePosition) {
      return {};
    }

    const pos = FloorplanCoordinates.toViewportCoordinates(
      state.placementMode.mousePosition,
      state.floorplan,
      state.viewport
    );
    return {
      position: 'absolute' as const,
      left: pos.x + PLACEMENT_TOOLTIP_OFFSET_X_PX,
      top: pos.y,
    };
  }, [state.placementMode, state.floorplan, state.viewport]);

  return (
    <div
      ref={containerElementRef}
      style={{
        position: 'relative',
        width,
        height,
        backgroundColor: colors.white,
        ...cursorParams,
      }}
    >
      <FloorplanImageView
        image={planImage}
        opacity={state.floorplanImageOpacity}
        viewport={viewport}
      />

      {/* HEATMAP OVERLAY */}
      {state.streaming.showPoints ? <HeatmapVisualizer state={state} /> : null}

      {/* TRACKS OVERLAY */}
      {state.streaming.showTracks ? (
        <TrackVisualizer
          plan={plan}
          floorplan={state.floorplan}
          viewport={state.viewport}
          targets={state.aggregatedTracksData}
        />
      ) : null}

      <svg
        ref={svgElementRef}
        style={{
          position: 'absolute',
          width,
          height,
        }}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        onMouseDown={onViewportMouseDown}
        data-cy="floorplan-svg"
      >
        <defs>
          {/* FILTER USED FOR SENSOR GRAPHIC */}
          <filter
            id="filter0_d"
            x="-5"
            y="-5"
            width="18"
            height="18"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.133333 0 0 0 0 0.164706 0 0 0 0 0.180392 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>

          {/* ARROWHEAD USED FOR LEADER LINES */}
          {/* modified from https://vanseodesign.com/web-design/svg-markers/ */}
          <marker
            id="leader-line-arrow"
            markerWidth="10"
            markerHeight="10"
            refX="10"
            refY="3"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,6 L9,3 z" fill={colors.yellow} />
          </marker>
        </defs>
        {FloorplanCollection.listInRenderOrder(state.sensors).map((sensor) => {
          // Don't render entry / oa sensors if they should be hidden
          if (sensor.type === 'oa' && !state.planning.showOASensors) {
            return null;
          }
          if (sensor.type === 'entry' && !state.planning.showEntrySensors) {
            return null;
          }

          const viewportPosition = FloorplanCoordinates.toViewportCoordinates(
            sensor.position,
            floorplan,
            viewport
          );
          const radiusMeters =
            sensor.type === 'oa'
              ? computeCoverageRadiusOA(sensor.height)
              : computeCoverageRadiusEntry(sensor.height);
          const radiusPixels = radiusMeters * floorplan.scale * viewport.zoom;
          const isHighlighted = State.isSensorHighlighted(state, sensor.id);
          const isFocused = State.isSensorFocused(state, sensor.id);
          const coverageRadius = displayLength(radiusMeters, state.displayUnit);
          const sensorConnection = state.sensorConnections.get(sensor.id);
          const sensorStreamingStatus = sensorConnection
            ? sensorConnection.status
            : null;

          const cursor =
            state.cursorOverride ||
            getCursorForObject(
              'sensor',
              Boolean(state.manipulatedObject),
              sensor.locked
            );
          const styleOverrides = { cursor };

          let connectionColor: string = getSensorStatusColor(sensor.status);

          let blink: boolean;
          switch (sensorStreamingStatus) {
            case 'connected':
            case 'connecting':
              blink = true;
              break;
            default:
              blink = false;
              break;
          }

          return (
            <SensorGraphic
              key={sensor.id}
              sensorType={sensor.type}
              x={viewportPosition.x}
              y={viewportPosition.y}
              radius={radiusPixels}
              rotation={sensor.rotation}
              isHighlighted={isHighlighted}
              isFocused={isFocused}
              coverageRadius={coverageRadius}
              centroidColor={
                sensor.type === 'entry' ? colors.green : connectionColor
              }
              style={styleOverrides}
              onMouseEnter={(evt) => {
                dispatch({
                  type: 'item.graphic.mouseenter',
                  itemType: 'sensor',
                  itemId: sensor.id,
                });
              }}
              onMouseLeave={(evt) => {
                dispatch({
                  type: 'item.graphic.mouseleave',
                  itemType: 'sensor',
                  itemId: sensor.id,
                });
              }}
              onMouseDown={(evt) => {
                evt.preventDefault();

                // IMPORTANT: prevent mouse event from propagating to viewport element
                evt.stopPropagation();

                const { clientX, clientY } = evt;
                dispatch({
                  type: 'item.graphic.mousedown',
                  itemType: 'sensor',
                  itemId: sensor.id,
                  itemPosition: viewportPosition,
                  clientX,
                  clientY,
                });
              }}
              blink={blink}
              serialNumber={sensor.serialNumber}
              showSerialNumber={state.planning.showSensorLabels}
              showSensorCoverage={state.planning.showSensorCoverage}
            />
          );
        })}
        {state.planning.showSpaces
          ? FloorplanCollection.listInRenderOrder(state.spaces).map((space) => {
              const cursor =
                state.cursorOverride ||
                getCursorForObject(
                  'space',
                  Boolean(state.manipulatedObject),
                  space.locked
                );
              const styleOverrides = { cursor };

              return (
                <SpaceGraphic
                  key={space.id}
                  space={space}
                  state={state}
                  style={styleOverrides}
                  dispatch={dispatch}
                />
              );
            })
          : null}
        {state.placementMode &&
        state.placementMode.type === 'space' &&
        state.placementMode.shape === 'polygon' &&
        state.placementMode.vertices.length > 0 ? (
          <SpacePolygonCreationGraphic state={state} />
        ) : null}
        {state.planning.showRulers
          ? FloorplanCollection.listInRenderOrder(state.references).map(
              (reference) => {
                switch (reference.type) {
                  case 'point': {
                    return (
                      <ReferencePointGraphic
                        key={reference.id}
                        reference={reference}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }
                  default: {
                    return (
                      <ReferenceRulerGraphic
                        key={reference.id}
                        reference={reference}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }
                }
              }
            )
          : null}
        {state.planning.showPhotoGroups
          ? FloorplanCollection.listInRenderOrder(state.photoGroups).map(
              (photoGroup) => {
                const cursor =
                  state.cursorOverride ||
                  getCursorForObject(
                    'photogroup',
                    Boolean(state.manipulatedObject),
                    photoGroup.locked
                  );
                return (
                  <PhotoGroupGraphic
                    key={photoGroup.id}
                    photoGroup={photoGroup}
                    state={state}
                    cursor={cursor}
                    dispatch={dispatch}
                  />
                );
              }
            )
          : null}
        {state.planning.showScale && state.measurement ? (
          <g data-cy="floorplan-scale-graphic">
            <FloorplanRulerGraphic
              positionA={ImageCoordinates.toViewportCoordinates(
                state.measurement.pointA,
                viewport
              )}
              positionB={ImageCoordinates.toViewportCoordinates(
                state.measurement.pointB,
                viewport
              )}
              distanceText={displayLength(
                state.measurement.computedLength,
                state.displayUnit
              )}
              showDistanceLabel={true}
              showDistanceLeaderLine={false}
            />
          </g>
        ) : null}
        {state.simulation.enabled
          ? state.simulants.map((simulant) => {
              return (
                <SimulantGraphic
                  key={simulant.id}
                  simulant={simulant}
                  floorplan={floorplan}
                  viewport={viewport}
                  boundariesShown={state.simulation.simBoundariesShown}
                  dispatch={dispatch}
                />
              );
            })
          : null}
      </svg>

      {/* PLACEMENT TOOLTIP */}
      {state.placementMode && state.placementMode.mousePosition ? (
        <div style={placementTooltipStyles}>
          <div
            className={styles.placementTooltip}
            data-cy="add-placement-tooltip"
          >
            {addPlacementText}
          </div>
        </div>
      ) : null}
      {/* PLACEMENT TARGET */}
      {state.placementMode ? (
        <ObjectPlacementTarget
          viewport={viewport}
          floorplan={floorplan}
          dispatch={dispatch}
        />
      ) : null}
      {/* SPACEBAR PANNER */}
      {spaceKeyPressed ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width,
            height,
            cursor: state.cursorOverride || 'grab',
          }}
          onMouseDown={onViewportMouseDown}
        />
      ) : null}
    </div>
  );
};

export default FloorplanViewport;
