import { Fragment, useCallback, useState } from 'react';
import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { Action } from './actions';
import {
  getCursorForHandle,
  getCursorForObject,
  ResizeHandleType,
  Space,
  State,
} from './state';

import { FloorplanCoordinates, ViewportCoordinates } from 'lib/geometry';
import { addDragListener } from 'lib/drag';

const SPACE_COLOR = colors.purple;

const ResizeHandle: React.FunctionComponent<{
  spaceId: Space['id'];
  locked: boolean;
  handle: ResizeHandleType;
  polygonVertexIndex?: number;
  position: ViewportCoordinates;
  size?: number;
  dispatch: React.Dispatch<Action>;
}> = ({
  spaceId,
  locked,
  handle,
  polygonVertexIndex,
  position,
  size = 6,
  dispatch,
}) => {
  const cursor = getCursorForHandle(handle, locked);

  const topLeft = {
    x: position.x - size / 2,
    y: position.y - size / 2,
  };

  const onMouseDown = useCallback(
    (evt: React.MouseEvent<SVGRectElement>) => {
      evt.stopPropagation();

      // Right clicking on a point will delete it
      if (typeof polygonVertexIndex !== 'undefined' && evt.buttons === 2) {
        dispatch({
          type: 'space.polygon.removeVertex',
          id: spaceId,
          polygonVertexIndex,
        });
        return;
      }

      dispatch({
        type: 'space.resizeHandle.dragstart',
        handle,
        polygonVertexIndex,
      });
      const unregister = addDragListener(
        evt.clientX,
        evt.clientY,
        (dx, dy) => {
          dispatch({
            type: 'space.resizeHandle.dragmove',
            id: spaceId,
            handle,
            polygonVertexIndex,
            dx,
            dy,
          });
        },
        () => {
          dispatch({ type: 'space.resizeHandle.dragend' });
        }
      );

      return () => {
        unregister();
      };
    },
    [spaceId, handle, polygonVertexIndex, dispatch]
  );
  const onMouseEnter = useCallback(() => {
    dispatch({
      type: 'space.resizeHandle.mouseenter',
      handle,
      polygonVertexIndex,
    });
  }, [handle, polygonVertexIndex, dispatch]);
  const onMouseLeave = useCallback(() => {
    dispatch({ type: 'space.resizeHandle.mouseleave' });
  }, [dispatch]);

  return (
    <rect
      key={handle}
      x={topLeft.x}
      y={topLeft.y}
      width={size}
      height={size}
      fill={'white'}
      stroke={SPACE_COLOR}
      style={{ cursor }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      // Disable the context menu action so that the right click to delete a point action will work.
      // If this is not here, right clicking opens the browser context menu instead
      onContextMenu={(e) => e.preventDefault()}
    />
  );
};

const BoxSideResizeHandle: React.FunctionComponent<{
  spaceId: Space['id'];
  locked: boolean;
  handle: ResizeHandleType;
  start: ViewportCoordinates;
  end: ViewportCoordinates;
  thickness?: number;
  dispatch: React.Dispatch<Action>;
}> = ({ spaceId, locked, handle, start, end, thickness = 3, dispatch }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const cursor = getCursorForHandle(handle, locked);

  const onMouseDown = useCallback(
    (evt: React.MouseEvent<SVGLineElement>) => {
      evt.stopPropagation();
      dispatch({ type: 'space.resizeHandle.dragstart', handle });
      setIsDragging(true);
      const unregister = addDragListener(
        evt.clientX,
        evt.clientY,
        (dx, dy) => {
          dispatch({
            type: 'space.resizeHandle.dragmove',
            id: spaceId,
            handle,
            dx,
            dy,
          });
        },
        () => {
          dispatch({ type: 'space.resizeHandle.dragend' });
          setIsDragging(false);
        }
      );

      return () => {
        unregister();
      };
    },
    [spaceId, handle, dispatch]
  );
  const onMouseEnter = useCallback(() => {
    dispatch({ type: 'space.resizeHandle.mouseenter', handle });
    setIsHovered(true);
  }, [handle, dispatch]);
  const onMouseLeave = useCallback(() => {
    dispatch({ type: 'space.resizeHandle.mouseleave' });
    setIsHovered(false);
  }, [dispatch]);

  return (
    <line
      x1={start.x}
      y1={start.y}
      x2={end.x}
      y2={end.y}
      stroke={isHovered || isDragging ? SPACE_COLOR : 'transparent'}
      strokeWidth={thickness}
      style={{ cursor }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
    />
  );
};

const SpaceCircleGraphic: React.FunctionComponent<{
  space: Space;
  position: ViewportCoordinates;
  radiusPixels: number;
  fillOpacity: number;
  isHighlighted: boolean;
  isFocused: boolean;
  isBeingManipulated: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  space,
  position,
  radiusPixels,
  fillOpacity,
  isHighlighted,
  isFocused,
  isBeingManipulated,
  dispatch,
}) => {
  const spaceId = space.id;
  const leftX = position.x - radiusPixels;
  const rightX = position.x + radiusPixels;
  const topY = position.y - radiusPixels;
  const bottomY = position.y + radiusPixels;

  // side middles
  const top = ViewportCoordinates.create(position.x, topY);
  const right = ViewportCoordinates.create(rightX, position.y);
  const bottom = ViewportCoordinates.create(position.x, bottomY);
  const left = ViewportCoordinates.create(leftX, position.y);

  const onSpaceMouseEnter = useCallback(() => {
    dispatch({
      type: 'item.graphic.mouseenter',
      itemType: 'space',
      itemId: spaceId,
    });
  }, [spaceId, dispatch]);
  const onSpaceMouseLeave = useCallback(() => {
    dispatch({
      type: 'item.graphic.mouseleave',
      itemType: 'space',
      itemId: spaceId,
    });
  }, [spaceId, dispatch]);
  const onSpaceMouseDown = useCallback(
    (evt: React.MouseEvent<SVGCircleElement>) => {
      evt.stopPropagation();

      const { clientX, clientY } = evt;

      dispatch({
        type: 'item.graphic.mousedown',
        itemType: 'space',
        itemId: spaceId,
        itemPosition: position,
        clientX,
        clientY,
      });
    },
    [spaceId, position, dispatch]
  );

  return (
    <Fragment>
      {isFocused ? (
        <circle
          cx={position.x}
          cy={position.y}
          r={radiusPixels + 2}
          fill={'transparent'}
          stroke={SPACE_COLOR}
          strokeOpacity={0.2}
          strokeWidth={4}
        />
      ) : null}
      <circle
        cx={position.x}
        cy={position.y}
        r={radiusPixels}
        fill={SPACE_COLOR}
        fillOpacity={fillOpacity}
        stroke={SPACE_COLOR}
        strokeWidth={isHighlighted ? 2 : 1}
        onMouseDown={onSpaceMouseDown}
        onMouseEnter={onSpaceMouseEnter}
        onMouseLeave={onSpaceMouseLeave}
      />
      {isFocused ? (
        <Fragment>
          {/* TOP */}
          <ResizeHandle
            spaceId={spaceId}
            locked={space.locked}
            handle={'top'}
            position={top}
            dispatch={dispatch}
          />
          {/* RIGHT */}
          <ResizeHandle
            spaceId={spaceId}
            locked={space.locked}
            handle={'right'}
            position={right}
            dispatch={dispatch}
          />
          {/* BOTTOM */}
          <ResizeHandle
            spaceId={spaceId}
            locked={space.locked}
            handle={'bottom'}
            position={bottom}
            dispatch={dispatch}
          />
          {/* LEFT */}
          <ResizeHandle
            spaceId={spaceId}
            locked={space.locked}
            handle={'left'}
            position={left}
            dispatch={dispatch}
          />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const SpaceBoxGraphic: React.FunctionComponent<{
  space: Space;
  position: ViewportCoordinates;
  widthPixels: number;
  heightPixels: number;
  fillOpacity: number;
  isHighlighted: boolean;
  isFocused: boolean;
  isBeingManipulated: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  space,
  position,
  widthPixels,
  heightPixels,
  fillOpacity,
  isHighlighted,
  isFocused,
  isBeingManipulated,
  dispatch,
}) => {
  const spaceId = space.id;
  const leftX = position.x - widthPixels / 2;
  const rightX = position.x + widthPixels / 2;
  const topY = position.y - heightPixels / 2;
  const bottomY = position.y + heightPixels / 2;

  // corners
  const topLeft = ViewportCoordinates.create(leftX, topY);
  const topRight = ViewportCoordinates.create(rightX, topY);
  const bottomRight = ViewportCoordinates.create(rightX, bottomY);
  const bottomLeft = ViewportCoordinates.create(leftX, bottomY);

  const cursor = getCursorForObject('space', isBeingManipulated, space.locked);

  const onSpaceMouseEnter = useCallback(() => {
    dispatch({
      type: 'item.graphic.mouseenter',
      itemType: 'space',
      itemId: spaceId,
    });
  }, [spaceId, dispatch]);
  const onSpaceMouseLeave = useCallback(() => {
    dispatch({
      type: 'item.graphic.mouseleave',
      itemType: 'space',
      itemId: spaceId,
    });
  }, [spaceId, dispatch]);
  const onSpaceMouseDown = useCallback(
    (evt: React.MouseEvent<SVGRectElement>) => {
      evt.stopPropagation();

      const { clientX, clientY } = evt;

      dispatch({
        type: 'item.graphic.mousedown',
        itemType: 'space',
        itemId: spaceId,
        itemPosition: position,
        clientX,
        clientY,
      });
      // dispatch({
      //   type: 'item.graphic.dragstart',
      //   itemType: 'space',
      //   itemId: spaceId,
      // });
    },
    [spaceId, position, dispatch]
  );

  return (
    <Fragment>
      {isFocused ? (
        <rect
          x={topLeft.x - 2}
          y={topLeft.y - 2}
          width={widthPixels + 4}
          height={heightPixels + 4}
          rx={2}
          fill={'transparent'}
          stroke={SPACE_COLOR}
          strokeOpacity={0.2}
          strokeWidth={4}
        />
      ) : null}
      <rect
        x={topLeft.x}
        y={topLeft.y}
        width={widthPixels}
        height={heightPixels}
        rx={2}
        fill={SPACE_COLOR}
        fillOpacity={fillOpacity}
        stroke={SPACE_COLOR}
        strokeWidth={isHighlighted ? 2 : 1}
        style={{ cursor }}
        onMouseEnter={onSpaceMouseEnter}
        onMouseLeave={onSpaceMouseLeave}
        onMouseDown={onSpaceMouseDown}
      />
      {isFocused ? (
        <Fragment>
          {/* First, the side line handles */}
          <g>
            <BoxSideResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'top'}
              start={topLeft}
              end={topRight}
              dispatch={dispatch}
            />
            <BoxSideResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'right'}
              start={topRight}
              end={bottomRight}
              dispatch={dispatch}
            />
            <BoxSideResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'bottom'}
              start={bottomLeft}
              end={bottomRight}
              dispatch={dispatch}
            />
            <BoxSideResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'left'}
              start={topLeft}
              end={bottomLeft}
              dispatch={dispatch}
            />
          </g>
          {/* Second, the corner square handles (so they are on top of the line handles) */}
          <g>
            <ResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'topleft'}
              position={topLeft}
              dispatch={dispatch}
            />
            <ResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'topright'}
              position={topRight}
              dispatch={dispatch}
            />
            <ResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'bottomright'}
              position={bottomRight}
              dispatch={dispatch}
            />
            <ResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'bottomleft'}
              position={bottomLeft}
              dispatch={dispatch}
            />
          </g>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const SpacePolygonGraphic: React.FunctionComponent<{
  space: Space;
  position: ViewportCoordinates;
  vertices: Array<ViewportCoordinates>;
  fillOpacity: number;
  isHighlighted: boolean;
  isFocused: boolean;
  isBeingManipulated: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  space,
  position,
  vertices,
  fillOpacity,
  isHighlighted,
  isFocused,
  isBeingManipulated,
  dispatch,
}) => {
  const spaceId = space.id;
  const cursor = getCursorForObject('space', isBeingManipulated, space.locked);

  const onSpaceMouseEnter = useCallback(() => {
    dispatch({
      type: 'item.graphic.mouseenter',
      itemType: 'space',
      itemId: spaceId,
    });
  }, [spaceId, dispatch]);
  const onSpaceMouseLeave = useCallback(() => {
    dispatch({
      type: 'item.graphic.mouseleave',
      itemType: 'space',
      itemId: spaceId,
    });
  }, [spaceId, dispatch]);
  const onSpaceMouseDown = useCallback(
    (evt: React.MouseEvent<SVGRectElement>) => {
      evt.stopPropagation();

      const { clientX, clientY } = evt;

      dispatch({
        type: 'item.graphic.mousedown',
        itemType: 'space',
        itemId: spaceId,
        itemPosition: position,
        clientX,
        clientY,
      });
      // dispatch({
      //   type: 'item.graphic.dragstart',
      //   itemType: 'space',
      //   itemId: spaceId,
      // });
    },
    [spaceId, position, dispatch]
  );

  const onPerimeterMouseDown = useCallback(
    (
      v1: ViewportCoordinates,
      v2: ViewportCoordinates,
      evt: React.MouseEvent<SVGLineElement>
    ) => {
      evt.stopPropagation();

      // NOTE: traverse up the DOM tree to find the svg that the element clicked on is within
      // This seems like a bad idea.
      let wrappingSvg: Element | null = evt.currentTarget;
      while (wrappingSvg && wrappingSvg.tagName !== 'svg') {
        wrappingSvg = wrappingSvg.parentElement;
      }
      if (!wrappingSvg) {
        return;
      }

      const bbox = wrappingSvg.getBoundingClientRect();
      const position = ViewportCoordinates.create(
        evt.clientX - bbox.left,
        evt.clientY - bbox.top
      );

      dispatch({
        type: 'space.polygon.addVertex',
        id: spaceId,
        polygonVertexIndex: vertices.indexOf(v2),
        position,
      });
    },
    [spaceId, vertices, dispatch]
  );

  const polygonPath = `
    M${vertices[0].x},${vertices[0].y}
    ${vertices.slice(1).map((v) => `L${v.x},${v.y}`)}
    L${vertices[0].x},${vertices[0].y}
  `;

  return (
    <Fragment>
      {isFocused ? (
        <path
          d={polygonPath}
          fill={'transparent'}
          stroke={SPACE_COLOR}
          strokeOpacity={0.2}
          strokeWidth={4}
        />
      ) : null}

      <path
        d={polygonPath}
        fill={SPACE_COLOR}
        fillOpacity={fillOpacity}
        stroke={SPACE_COLOR}
        strokeWidth={isHighlighted ? 2 : 1}
        style={{ cursor }}
        onMouseEnter={onSpaceMouseEnter}
        onMouseLeave={onSpaceMouseLeave}
        onMouseDown={onSpaceMouseDown}
      />

      {isFocused
        ? vertices.map((v1, i) => {
            const v2 = i < vertices.length - 1 ? vertices[i + 1] : vertices[0];
            return (
              <line
                key={i}
                x1={v1.x}
                y1={v1.y}
                x2={v2.x}
                y2={v2.y}
                strokeWidth={4}
                stroke="transparent"
                style={{ cursor: 'copy' }}
                onMouseDown={(evt) => onPerimeterMouseDown(v1, v2, evt)}
              />
            );
          })
        : null}

      {isFocused ? (
        <g>
          {vertices.map((v, i) => (
            <ResizeHandle
              spaceId={spaceId}
              locked={space.locked}
              handle={'all'}
              polygonVertexIndex={i}
              position={v}
              dispatch={dispatch}
            />
          ))}
        </g>
      ) : null}

      {/* Render small plus sign at the centroid */}
      <path
        transform={`translate(${position.x},${position.y})`}
        d="M-4,0 L4,0 M0,-4 L0,4"
        stroke={SPACE_COLOR}
      />
    </Fragment>
  );
};

// This graphic is shown when creating a new polygon.
export const SpacePolygonCreationGraphic: React.FunctionComponent<{
  state: State;
}> = ({ state }) => {
  if (!state.placementMode) {
    return null;
  }
  if (state.placementMode.type !== 'space') {
    return null;
  }
  if (state.placementMode.shape !== 'polygon') {
    return null;
  }
  if (state.placementMode.vertices.length === 0) {
    return null;
  }

  const viewportVertices = state.placementMode.vertices.map((v) =>
    FloorplanCoordinates.toViewportCoordinates(
      v,
      state.floorplan,
      state.viewport
    )
  );

  // Add the next point position indicated by the cursor to the geometry
  if (state.placementMode.nextPointPosition) {
    if (state.placementMode.mouseOverFinalPoint) {
      viewportVertices.push(viewportVertices[0]);
    } else {
      const nextPointPositionViewport =
        FloorplanCoordinates.toViewportCoordinates(
          state.placementMode.nextPointPosition,
          state.floorplan,
          state.viewport
        );
      viewportVertices.push(nextPointPositionViewport);
    }
  }

  return (
    <g>
      <path
        d={`
      M${viewportVertices[0].x},${viewportVertices[0].y}
      ${viewportVertices.slice(1).map((v) => `L${v.x},${v.y}`)}
    `}
        fill={colors.purple}
        stroke={colors.purple}
        fillOpacity={0.05}
      />
      {viewportVertices.map((v, i) => (
        <rect
          key={i}
          x={v.x - 3}
          y={v.y - 3}
          stroke={colors.purple}
          fill="white"
          width={6}
          height={6}
        />
      ))}
      {state.placementMode.nextPointSelfIntersection
        ? (() => {
            const nextPointSelfIntersectionViewport =
              FloorplanCoordinates.toViewportCoordinates(
                state.placementMode.nextPointSelfIntersection,
                state.floorplan,
                state.viewport
              );

            return (
              <g
                transform={`translate(${nextPointSelfIntersectionViewport.x},${nextPointSelfIntersectionViewport.y})`}
              >
                <circle cx={0} cy={0} r={8} fill="white" stroke={colors.red} />
                <path
                  d={'M-4,-4 L4,4 M-4,4 L4,-4'}
                  stroke={colors.red}
                  strokeWidth={2}
                />
              </g>
            );
          })()
        : null}
    </g>
  );
};

const SpaceGraphic: React.FunctionComponent<{
  space: Space;
  state: State;
  style?: React.SVGAttributes<SVGGElement>['style'];
  dispatch: React.Dispatch<Action>;
}> = ({ space, state, style, dispatch }) => {
  const positionViewportCoords = FloorplanCoordinates.toViewportCoordinates(
    space.position,
    state.floorplan,
    state.viewport
  );
  const isHighlighted = State.isSpaceHighlighted(state, space.id);
  const isFocused = State.isSpaceFocused(state, space.id);

  const occupancy = state.spaceOccupancy.get(space.id);
  if (!occupancy)
    throw new Error(`Cannot display occupancy for space with id ${space.id}`);

  const fillOpacity = occupancy.occupied ? 0.5 : 0.05;

  return (
    <g key={space.id} style={style} data-cy={`space-graphic-${space.id}`}>
      {space.shape.type === 'circle' ? (
        <SpaceCircleGraphic
          space={space}
          position={positionViewportCoords}
          radiusPixels={
            space.shape.radius * state.floorplan.scale * state.viewport.zoom
          }
          isHighlighted={isHighlighted}
          isFocused={isFocused}
          fillOpacity={fillOpacity}
          isBeingManipulated={Boolean(state.manipulatedObject)}
          dispatch={dispatch}
        />
      ) : null}
      {space.shape.type === 'box' ? (
        <SpaceBoxGraphic
          space={space}
          position={positionViewportCoords}
          widthPixels={
            space.shape.width * state.floorplan.scale * state.viewport.zoom
          }
          heightPixels={
            space.shape.height * state.floorplan.scale * state.viewport.zoom
          }
          isHighlighted={isHighlighted}
          isFocused={isFocused}
          fillOpacity={fillOpacity}
          isBeingManipulated={Boolean(state.manipulatedObject)}
          dispatch={dispatch}
        />
      ) : null}
      {space.shape.type === 'polygon' ? (
        <SpacePolygonGraphic
          space={space}
          position={positionViewportCoords}
          vertices={space.shape.vertices.map((v) =>
            FloorplanCoordinates.toViewportCoordinates(
              v,
              state.floorplan,
              state.viewport
            )
          )}
          isHighlighted={isHighlighted}
          isFocused={isFocused}
          fillOpacity={fillOpacity}
          isBeingManipulated={Boolean(state.manipulatedObject)}
          dispatch={dispatch}
        />
      ) : null}
    </g>
  );
};

export default SpaceGraphic;
