import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';

import { AppThunkApiConfig } from 'redux/store';
import { DayOfWeek } from '@densityco/lib-common-types';
import { CompatibleDateValue } from '@densityco/ui/dist/cjs/src/date-picker';
import { getTimeFilterString, TimeOfDay } from 'lib/date-time';
import { defaultAppThunkOptions } from 'lib/redux';
import { DwellAnalyticsResponse } from 'lib/dwell';

export const asyncFetchDwellAnalyticsThunk = createAsyncThunk<
  // return value
  DwellAnalyticsResponse,
  // parameters
  {
    spaceId: string;

    timeZone: string;
    startDate: CompatibleDateValue;
    endDate: CompatibleDateValue;
    filterStart: TimeOfDay;
    filterEnd: TimeOfDay;
    filterDays: DayOfWeek[];
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'analysis/asyncFetchDwellAnalytics',
  async (args, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    // cancelling requests while they're running https://redux-toolkit.js.org/api/createAsyncThunk#canceling-while-running
    const source = axios.CancelToken.source();
    thunkApi.signal.addEventListener('abort', () => {
      source.cancel();
    });

    const timeFilterString = getTimeFilterString(
      args.filterStart,
      args.filterEnd,
      args.filterDays
    );

    const params = {
      start_date: args.startDate,
      end_date: args.endDate,
      time_filters: timeFilterString,
      space_ids: args.spaceId,
      time_zone: args.timeZone,
    };

    const response = await auth.densityAPIClient.get(`/app/analytics/dwell`, {
      params: { ...params, granularity: '1d' },
      cancelToken: source.token,
    });

    return response.data;
  },
  {
    ...defaultAppThunkOptions,
    condition: (args, thunkApi) => {
      const { auth } = thunkApi.getState();
      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
