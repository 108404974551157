import { Middleware, isAnyOf } from '@reduxjs/toolkit';
import { Analytics } from 'lib/analytics';
import { Area } from 'lib/api';
import { getTimeFilterString } from 'lib/date-time';
import { clickArea, selectAreas } from 'redux/features/analysis/analysis-slice';
import { asyncFetchAnalyticsThunk } from 'redux/features/analysis/async-fetch-analytics-thunk';
import { asyncFetchHeatmapThunk } from 'redux/features/analysis/async-fetch-and-process-heatmap-thunk';
import { asyncFetchDistributionAnalyticsThunk } from 'redux/features/analysis/async-fetch-distribution-analytics-thunk';
import { asyncFetchDwellAnalyticsThunk } from 'redux/features/analysis/async-fetch-dwell-analytics-thunk';
import { asyncFetchUtilizationAnalyticsByDayThunk } from 'redux/features/analysis/async-fetch-utilization-analytics-by-day-thunk';
import { asyncFetchUtilizationAnalyticsByHourThunk } from 'redux/features/analysis/async-fetch-utilization-analytics-by-hour-thunk';
import { downloadAnalysisCSVLegacyThunk } from 'redux/features/analysis/download-analysis-csv-legacy-thunk';
import { planDetailsSelectors } from 'redux/features/plans/plans-slice';
import { asyncBulkSaveSpaceLabelsThunk } from 'redux/features/spaces/async-bulk-save-space-labels-thunk';
import { asyncBulkUpdateSpaceThunk } from 'redux/features/spaces/async-bulk-update-space-thunk';
import { asyncSaveSpaceLabelsThunk } from 'redux/features/spaces/async-save-space-labels-thunk';
import { asyncUpdateSpaceThunk } from 'redux/features/spaces/async-update-space-thunk';

// things that we're tracking
// https://www.notion.so/densityinc/OA-Product-Metrics-dd90e5f0f25c436da93fc39cfddaab8e
export const analyticsMiddleware: Middleware =
  (store) => (next) => (action) => {
    if (
      isAnyOf(
        asyncFetchAnalyticsThunk.pending,
        asyncFetchHeatmapThunk.pending
      )(action)
    ) {
      Analytics.track('Run Query', {
        planId: action.meta.arg.planId,
        startTime: action.meta.arg.startTime,
        endTime: action.meta.arg.endTime,
        timeFilter: getTimeFilterString(
          action.meta.arg.filterStart,
          action.meta.arg.filterEnd,
          action.meta.arg.filterDays
        ),
      });
    } else if (
      isAnyOf(
        asyncFetchDistributionAnalyticsThunk.pending,
        asyncFetchUtilizationAnalyticsByDayThunk.pending,
        asyncFetchUtilizationAnalyticsByHourThunk.pending,
        asyncFetchDwellAnalyticsThunk.pending
      )(action)
    ) {
      Analytics.track('Run Query', {
        spaceId: action.meta.arg.spaceId,
        startTime: action.meta.arg.startDate,
        endTime: action.meta.arg.endDate,
        timeFilter: getTimeFilterString(
          action.meta.arg.filterStart,
          action.meta.arg.filterEnd,
          action.meta.arg.filterDays
        ),
      });
    } else if (isAnyOf(downloadAnalysisCSVLegacyThunk.pending)(action)) {
      Analytics.track('Legacy Export CSV', {
        floorId: action.meta.arg.floorId,
        startTime: action.meta.arg.startDate,
        endTime: action.meta.arg.endDate,
        timeFilter: getTimeFilterString(
          action.meta.arg.filterStart,
          action.meta.arg.filterEnd,
          action.meta.arg.filterDays
        ),
      });
    } else if (isAnyOf(asyncSaveSpaceLabelsThunk.pending)(action)) {
      Analytics.track('Save Space Labels', {
        spaceId: action.meta.arg.spaceId,
        addedLabelIds: action.meta.arg.addedLabelIds,
        createdLabelNames: action.meta.arg.createdLabelNames,
        removedLabelIds: action.meta.arg.removedLabelIds,
      });
    } else if (isAnyOf(asyncBulkSaveSpaceLabelsThunk.pending)(action)) {
      Analytics.track('Bulk Save Space Labels', {
        spaceIds: action.meta.arg.spaceIds,
        addedLabelIds: action.meta.arg.addedLabelIds,
        createdLabelNames: action.meta.arg.createdLabelNames,
        addMap: action.meta.arg.addMap,
        removeMap: action.meta.arg.removeMap,
      });
    } else if (isAnyOf(asyncUpdateSpaceThunk.pending)(action)) {
      if (action.meta.arg.body.capacity) {
        Analytics.track('Save Space Capacity', {
          spaceId: action.meta.arg.spaceId,
          capacity: action.meta.arg.body.capacity,
        });
      }

      if (action.meta.arg.body.function) {
        Analytics.track('Save Space Function', {
          spaceId: action.meta.arg.spaceId,
          function: action.meta.arg.body.function,
        });
      }

      if (action.meta.arg.body.name) {
        Analytics.track('Save Space Name', {
          spaceId: action.meta.arg.spaceId,
          name: action.meta.arg.body.name,
        });
      }
    } else if (isAnyOf(asyncBulkUpdateSpaceThunk.pending)(action)) {
      if (action.meta.arg.body.capacity) {
        Analytics.track('Bulk Save Space Capacity', {
          spaceIds: action.meta.arg.spaceIds,
          capacity: action.meta.arg.body.capacity,
        });
      }

      if (action.meta.arg.body.function) {
        Analytics.track('Bulk Save Space Function', {
          spaceIds: action.meta.arg.spaceIds,
          function: action.meta.arg.body.function,
        });
      }
    } else if (isAnyOf(clickArea)(action)) {
      const planDetails = planDetailsSelectors.selectAll(store.getState());

      let area: Area | undefined = undefined;

      // TODO(wuweiweiwu): probably not the most performant thing in the world.
      // in the future can pass space ids into the event
      planDetails.forEach((pd) => {
        pd.areas.forEach((a) => {
          if (a.id === action.payload) {
            area = a;
          }
        });
      });

      // @ts-ignore
      const spaceId = area?.space_id;

      Analytics.track('Select Space', {
        spaceId,
        areaId: action.payload,
      });
    } else if (isAnyOf(selectAreas)(action)) {
      const planDetails = planDetailsSelectors.selectAll(store.getState());

      let areas: Area[] = [];

      planDetails.forEach((pd) => {
        pd.areas.forEach((a) => {
          if (action.payload.some((aId) => aId === a.id)) {
            areas.push(a);
          }
        });
      });

      // @ts-ignore
      const spaceIds = areas.map((a) => a.space_id);

      Analytics.track('Select Spaces', {
        spaceIds,
        areaIds: action.payload,
      });
    }

    return next(action);
  };
