// split.io split names
export const SPLITS = {
  FF_OVERRIDES: 'web_oa_ff_overrides',
  DOWNLOAD_CSV: 'web_oa_download_csv',
  RELEASE_1: 'web_oa_release_1',
  SHOW_TRACKS: 'web_oa_show_tracks',
  DWELL: 'web_oa_dwell',
  IMPORT_DXF: 'web_oa_import_dxf',
  EXPORT_DXF: 'web_oa_export_dxf',
  PLANNING_FLOORPLAN_COMPONENT: 'web_oa_planning_floorplan_component',
  OA_LIVE_CONNECTION: 'web_oa_live_connection',
  ELLIPTICAL_OA_COVERAGE: 'web_oa_elliptical_oa_coverage',
  HEIGHT_MAP: 'web_oa_height_maps',
  AUTOLAYOUT: 'web_oa_autolayout',
  SENSOR_COVERAGE: 'web_oa_sensor_coverage_simulation',
} as const;

// features to override in split.io localhost mode
export const LOCAL_FEATURES = {
  [SPLITS.FF_OVERRIDES]: 'on',
  [SPLITS.IMPORT_DXF]: 'on',
  [SPLITS.EXPORT_DXF]: 'on',
  [SPLITS.PLANNING_FLOORPLAN_COMPONENT]: 'on',
  [SPLITS.ELLIPTICAL_OA_COVERAGE]: 'on',
  [SPLITS.HEIGHT_MAP]: 'on',
  [SPLITS.AUTOLAYOUT]: 'on',
  [SPLITS.SENSOR_COVERAGE]: 'on',
};
