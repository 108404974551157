import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import classNames from 'classnames';
import { Icons } from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';
import { getLiveCountProvider } from '@densityco/lib-space-helpers';
import {
  LiveCountChangeHandler,
  LiveCountData,
} from '@densityco/lib-space-helpers/dist/live-count';

import styles from './building-single.module.scss';

import AppBarBuilding from 'components/app-bar-building/app-bar-building';
import EmptyStateBuildingSingle from 'components/empty-state-building-single';
import { findFloorsInBuilding, Floor } from 'lib/floors';
import { useAppSelector } from 'redux/store';
import { planSummariesSelectors } from 'redux/features/plans/plans-slice';
import { spacesSelectors } from 'redux/features/spaces/spaces-slice';
import { PlanSummary } from 'lib/api';
import { CoreSpace } from '@densityco/lib-api-types';
import { Building } from 'lib/buildings';
import { FixMe } from 'types/fixme';

/**
 * Path /buildings/:buildingId/floors
 */

const BuildingSingle: React.FunctionComponent<{ building: Building }> = ({
  building,
}) => {
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );
  const planSummaries = useAppSelector(planSummariesSelectors.selectAll);
  const spaces = useAppSelector(spacesSelectors.selectAll);

  const floors = React.useMemo(() => {
    return findFloorsInBuilding(spaces, building.id);
  }, [building.id, spaces]);

  const plansByFloorId = useMemo(() => {
    const mapping = new Map<string, PlanSummary>();
    for (const plan of planSummaries) {
      mapping.set(plan.floor.id, plan);
    }
    return mapping;
  }, [planSummaries]);

  const [liveData, setLiveData] = useState<Map<string, LiveCountData>>(
    new Map()
  );

  // Whoops, gotta do a bit of surgery on the base url, and
  // pass an assertion in the .connect() method
  const client = useMemo(() => {
    if (!densityAPIClient) {
      return;
    }

    const client = axios.create({
      baseURL: densityAPIClient.defaults.baseURL + '/v2',
      headers: densityAPIClient.defaults.headers,
    });
    client.defaults.headers.common['Authorization'] =
      client.defaults.headers['Authorization'];
    client.defaults.headers.common['X-Impersonate-User'] =
      client.defaults.headers['X-Impersonate-User'];
    return client;
  }, [densityAPIClient]);

  /**
   * NOTE:
   *
   * The rather odd-looking code below is used to avoid leaking continued
   * state updates (eg. setLiveData) once the component has been unmounted.
   *
   * This can happen when async work is being done and then in the meantime
   * the user switches to another view. If that async code eventually results
   * in a setState of some kind, then it will be called on an unmounted
   * component, causing potentially undefined behavior.
   *
   * In our case, it seems that the LiveCountProvider .disconnect() method
   * does not fully clean up the async work that is happening (or at least
   * does not complete cleanup by the time the component unmounts).
   *
   * Here, a mutable ref is being used to hold the state of whether the
   * component is mounted or not. That way, the onCountChange callback can
   * first check if the component is mounted before applying a state update.
   */
  const volatileComponentIsMounted = useRef(true);

  // This handles setting the flag when the component unmounts
  useEffect(() => {
    // Do nothing in the effect itself

    // But use the cleanup function to set the flag
    return () => {
      volatileComponentIsMounted.current = false;
    };
  }, []);

  const onCountChange = useCallback<LiveCountChangeHandler>((status, data) => {
    // Check if component is mounted before applying state change
    if (volatileComponentIsMounted.current) {
      setLiveData(data);
    }
  }, []);

  useEffect(() => {
    if (!client) {
      return;
    }

    const provider = getLiveCountProvider();
    const spaceIds = floors.map((floor) => floor.id);

    provider.connect(
      onCountChange,
      client as FixMe,
      spaceIds,
      undefined,
      120000,
      120000
    );
    return () => {
      provider.disconnect();
    };
  }, [client, floors, onCountChange]);

  return (
    <div className={styles.FloorsIndex}>
      <AppBarBuilding building={building} />

      {floors.length ? (
        <FloorsList
          floors={floors}
          plansByFloorId={plansByFloorId}
          liveDataByFloorId={liveData}
        />
      ) : (
        <EmptyStateBuildingSingle building={building} />
      )}
    </div>
  );
};

export default BuildingSingle;

// -------------
// TODO: maybe we should break these out into separate components at some point

const FloorsList: React.FunctionComponent<{
  floors: Array<Floor>;
  plansByFloorId: Map<string, PlanSummary>;
  liveDataByFloorId: Map<string, LiveCountData>;
}> = ({ floors, plansByFloorId, liveDataByFloorId }) => {
  return (
    <ul className={styles.FloorsList}>
      {floors.map((floor) => {
        const planSummary = plansByFloorId.get(floor.id);
        return (
          <FloorsListItem
            key={floor.id}
            floor={floor}
            planSummary={planSummary}
            liveData={liveDataByFloorId.get(floor.id)}
          />
        );
      })}
    </ul>
  );
};

const FloorsListItem: React.FunctionComponent<{
  floor: CoreSpace;
  planSummary?: PlanSummary;
  liveData?: LiveCountData;
}> = ({ floor, planSummary, liveData }) => {
  const history = useHistory();

  const status = floor.status;

  const onSelect = useCallback(() => {
    history.push(`/buildings/${floor.parent_id}/floors/${floor.id}`);
  }, [floor, history]);

  return (
    <li className={styles.FloorsListItem} onClick={onSelect}>
      <div className={styles.Header}>
        <span className={styles.HeaderText}>{floor.name}</span>

        <div className={styles.HeaderStatus}>
          <div className={styles.HeaderCountLabel}>
            <div style={{ transform: 'translateY(3px)' }}>
              <Icons.Person width={16} height={16} color={colors.gray400} />
            </div>
            &nbsp;
            {liveData?.currentCount}
          </div>

          <div
            className={classNames(styles.HeaderStatusIndicator, {
              [styles.live]: status === 'live',
              [styles.planning]: status === 'planning',
            })}
          ></div>

          <div className={styles.HeaderStatusLabel}>
            {status ? status : '---'}
          </div>
        </div>
      </div>
      <div className={styles.Body}>
        {planSummary ? (
          <Fragment>
            {planSummary.image_url ? (
              <div className={styles.PlanImageWrapper}>
                <img
                  className={styles.PlanImage}
                  src={planSummary.image_url}
                  alt=""
                />
              </div>
            ) : (
              <div className={styles.PlanAddButton}>
                <Icons.Apps width={20} height={20} color={colors.gray400} />{' '}
                <div className={styles.PlanAddButtonLabel}>Processing...</div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className={styles.PlanAddButton}>
            <Icons.Plus width={20} height={20} color={colors.gray400} />{' '}
            <div className={styles.PlanAddButtonLabel}>Create a plan</div>
          </div>
        )}
      </div>
    </li>
  );
};
