import { useState } from 'react';
import * as React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const DropdownInfo: React.FunctionComponent<{
  label: React.ReactNode;
}> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <section
      className={styles.dropdownInfoWrapper}
      tabIndex={0}
      onBlur={() => setOpen(false)}
    >
      <section
        className={styles.dropdownInfoLabel}
        onClick={() => setOpen(!open)}
      >
        {props.label}
      </section>
      <section
        className={classnames(styles.dropdownBox, {
          [styles.open]: open,
        })}
      >
        {props.children}
      </section>
    </section>
  );
};

export default DropdownInfo;
