import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';

import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { getAllPlans, PlanSummary } from 'lib/api';

export const asyncFetchPlanSummariesThunk = createAsyncThunk<
  // return value
  PlanSummary[],
  // parameters
  void,
  // types for thunkApi
  AppThunkApiConfig
>(
  'plans/asyncFetchPlanSummaries',
  async (_, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    return await getAllPlans(auth.densityAPIClient);
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
