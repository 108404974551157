import { DayOfWeek } from '@densityco/lib-common-types';
import {
  CompatibleDateRange,
  getTimeFilterString,
  TimeOfDay,
} from 'lib/date-time';
import { ErrorReporting } from 'lib/error-reporting';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/store';
import moment from 'moment-timezone';
import { downloadFile } from '@densityco/lib-common-helpers';
import { sanitizeCSVDocument } from 'lib/sanitize-csv';
import { useUpdate } from './use-update';
import { Analytics } from 'lib/analytics';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import { StorageKeys } from 'lib/storage';

type ExportCSVArgs = {
  floorId: string;
  spaceIds: string[];

  timeZone: string;
  dates: CompatibleDateRange | null;
  filterStart: TimeOfDay;
  filterEnd: TimeOfDay;
  filterDays: DayOfWeek[];
};

enum CSVStatuses {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  STARTED = 'STARTED',
}

const lsSetValues = (taskId: string, s3Url: string, csvKey: string) => {
  localStorage.setItem(StorageKeys.CSV_S3_URL, s3Url);
  localStorage.setItem(StorageKeys.CSV_TASK_ID, taskId);
  localStorage.setItem(StorageKeys.CSV_KEY, csvKey);
};

const generateCsvKey = (args: ExportCSVArgs) => {
  const timeFilterString = getTimeFilterString(
    args.filterStart,
    args.filterEnd,
    args.filterDays
  );
  return JSON.stringify({
    floorId: args.floorId,
    dates: args.dates,
    timeFilterString,
  });
};

export const useExportCSV = (args: ExportCSVArgs) => {
  const s3UrlRef = useRef<string>();
  const taskIdRef = useRef<string>();

  const [isLoading, setIsLoading] = useState(false);

  const [key, update] = useUpdate();

  const client = useAppSelector((state) => state.auth.densityAPIClient);

  const isDwellOn = useTreatment(SPLITS.DWELL);
  const csvKey = generateCsvKey(args);

  // cancel the polling whenever any args changes
  useEffect(() => {
    setIsLoading(false);

    s3UrlRef.current = undefined;
    taskIdRef.current = undefined;
  }, [
    args.dates,
    args.filterDays,
    args.filterEnd,
    args.filterStart,
    args.floorId,
    args.spaceIds,
    args.timeZone,
    isDwellOn,
  ]);

  const fetchCSV = useCallback(
    async (url: string) => {
      const res = await fetch(url);

      if (!res.ok) {
        // Expired URL
        if (taskIdRef.current || s3UrlRef.current) {
          taskIdRef.current = undefined;
          s3UrlRef.current = undefined;
        }
        return false;
      }

      const csvString = await res.text();

      // sanitize
      const sanitizedCsvString = sanitizeCSVDocument(csvString);

      const granularity = '15m';

      downloadFile(
        `density_occupancy_${granularity}_${args.floorId}_${moment
          .tz(args.dates?.[0], args.timeZone)
          .format('YYYY-MM-DD')}_${moment
          .tz(args.dates?.[1], args.timeZone)
          .format('YYYY-MM-DD')}.csv`,
        sanitizedCsvString,
        'text/csv;charset=utf8;'
      );

      return true;
    },
    [args.dates, args.floorId, args.timeZone]
  );

  const checkCSVStatus = useCallback(
    async (taskId) => {
      if (!client) {
        return false;
      }

      try {
        const res = await client.get<{
          status: CSVStatuses;
        }>(`/app/reports/csv?task_id=${taskId}`);

        return res.data.status;
      } catch (e) {
        return CSVStatuses.FAILURE;
      }
    },
    [client]
  );

  // poll s3 for csv
  useEffect(() => {
    if (!s3UrlRef.current || !taskIdRef.current) {
      return;
    }

    if (!client) {
      return;
    }

    const start = async () => {
      while (true) {
        if (!s3UrlRef.current) {
          break;
        }

        const status = await checkCSVStatus(taskIdRef.current);

        if (status === CSVStatuses.SUCCESS) {
          await fetchCSV(s3UrlRef.current);
        }

        if (status === CSVStatuses.SUCCESS || status === CSVStatuses.FAILURE) {
          if (status === CSVStatuses.FAILURE) {
            ErrorReporting.report(
              `Failed to generate CSV for taskId ${taskIdRef.current}`
            );
          }
          break;
        }

        // 5 seconds
        const timeout = 1000 * 5;

        await new Promise((resolve) => setTimeout(resolve, timeout));
      }
      setIsLoading(false);
      lsSetValues('', '', '');
    };

    start();
  }, [
    args.dates,
    args.floorId,
    args.timeZone,
    checkCSVStatus,
    client,
    fetchCSV,
    key,
  ]);

  const startCsvJob = useCallback(async () => {
    if (!client) {
      return;
    }

    if (!args.dates) {
      return;
    }

    const timeFilterString = getTimeFilterString(
      args.filterStart,
      args.filterEnd,
      args.filterDays
    );

    Analytics.track('Export CSV', {
      floorId: args.floorId,
      startTime: args.dates[0],
      endTime: args.dates[1],
      timeFilter: timeFilterString,
    });

    try {
      const res = await client.post<{
        task_id: string;
        url: string;
      }>(`/app/reports/csv`, {
        start_date: args.dates[0],
        end_date: args.dates[1],
        floor_id: args.floorId,
        space_ids: args.spaceIds,
        time_zone: args.timeZone,
        time_filters: timeFilterString,
        include_dwell: isDwellOn,
      });

      s3UrlRef.current = res.data.url;
      taskIdRef.current = res.data.task_id;
      lsSetValues(taskIdRef.current, s3UrlRef.current, csvKey);

      console.log('url ref set', s3UrlRef.current);
    } catch (err) {
      ErrorReporting.report(err);
      setIsLoading(false);
    }
  }, [
    args.dates,
    args.filterDays,
    args.filterEnd,
    args.filterStart,
    args.floorId,
    args.spaceIds,
    args.timeZone,
    client,
    csvKey,
    isDwellOn,
  ]);

  // trigger csv export
  const triggerCSV = useCallback(async () => {
    if (!client) {
      return;
    }

    if (!args.dates) {
      return;
    }

    const lsS3Url = localStorage.getItem(StorageKeys.CSV_S3_URL) ?? undefined;
    const lsTaskId = localStorage.getItem(StorageKeys.CSV_TASK_ID) ?? undefined;
    const lsCsvKey = localStorage.getItem(StorageKeys.CSV_KEY) ?? undefined;

    setIsLoading(true);
    // Handle case where user has loaded from storage
    if (lsS3Url && lsTaskId && lsCsvKey === csvKey) {
      const status = await checkCSVStatus(lsTaskId);
      if (status === CSVStatuses.SUCCESS) {
        const downloadSuccess = await fetchCSV(lsS3Url);
        if (downloadSuccess) {
          setIsLoading(false);
          lsSetValues('', '', '');
          return;
        }
      } else if (
        status === CSVStatuses.PENDING ||
        status === CSVStatuses.STARTED
      ) {
        // resume the polling
        s3UrlRef.current = lsS3Url;
        taskIdRef.current = lsTaskId;
        update();
        return;
      }
    }

    s3UrlRef.current = undefined;
    taskIdRef.current = undefined;

    try {
      await startCsvJob();
      // start the polling
      update();
    } catch (err) {
      ErrorReporting.report(err);
      setIsLoading(false);
      lsSetValues('', '', '');
    }
  }, [
    args.dates,
    checkCSVStatus,
    client,
    csvKey,
    fetchCSV,
    startCsvJob,
    update,
  ]);

  return [triggerCSV, isLoading] as const;
};
