import { useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { Icons } from '@densityco/ui';

import styles from './floor-availability.module.scss';

import Switch from 'components/switch/switch';
import BetaLabel from 'components/beta-label/beta-label';

const AvailabilitySettingsPanel: React.FunctionComponent<{
  showTracks: boolean;
  toggleShowTracks: () => void;
}> = ({ showTracks, toggleShowTracks }) => {
  const [panelOpen, setPanelOpen] = useState<boolean>(false);

  return (
    <div className={styles.floorplanControls} style={{ zIndex: 1 }}>
      <div className={styles.settingsPanel}>
        <div
          className={classNames([
            styles.buttonSmall,
            styles.buttonIcon,
            styles.secondary,
            styles.elevated,
          ])}
          onMouseDown={() => setPanelOpen(!panelOpen)}
        >
          <Icons.Cog height={18} width={18} color="currentColor" />
          <span className={styles.tooltip}>Settings</span>
        </div>
        <div
          style={{ right: 0, transform: 'none' }}
          className={classNames(styles.settingsMenu, {
            [styles.open]: panelOpen,
          })}
        >
          <div className={styles.settingsMenuSection}>
            <div
              className={classNames(
                styles.settingsMenuRow,
                styles.settingsMenuRowInteractive
              )}
              // NOTE: the entire row is a clickable toggle target
              onClick={toggleShowTracks}
            >
              <div className={styles.settingsMenuRowLabel}>
                <div className={styles.settingsMenuRowLabelIcon}>
                  <Icons.Person height={16} width={16} color="currentColor" />
                </div>
                <div>
                  Show Activity
                  <BetaLabel />
                </div>
              </div>
              <div className={styles.settingsMenuRowAction}>
                <Switch isChecked={showTracks} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailabilitySettingsPanel;
