import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';

import {
  labelsSelectors,
  patchSpacesState,
  scopedSpacesSelectors,
  labelsAdapter,
  spacesAdapter,
} from './spaces-slice';
import { asyncFetchLabelsThunk } from './async-fetch-labels-thunk';

import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { getSpacesPatches } from 'lib/patches';
import { FixMe } from 'types/fixme';
import { throwIfAggregateError } from 'lib/throw-if-aggregate-error';
import { CoreSpaceLabel } from '@densityco/lib-api-types';

export const asyncBulkSaveSpaceLabelsThunk = createAsyncThunk<
  // return value
  void,
  // parameters
  {
    spaceIds: string[];
    addedLabelIds: string[];
    createdLabelNames: string[];
    addMap: { [spaceId: string]: Set<string> };
    removeMap: { [spaceId: string]: Set<string> };
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'spaces/asyncBulkSaveSpaceLabels',
  async (args, thunkApi) => {
    const { auth } = thunkApi.getState();

    const client = auth.densityAPIClient;
    invariant(client, 'missing densityAPIClient');

    const removeLabels = async (spaceId: string) => {
      if (!args.removeMap[spaceId]?.size) {
        return;
      }

      await client.delete(`/app/spaces/${spaceId}/labels`, {
        params: {
          label_ids: Array.from(args.removeMap[spaceId]).join(','),
        },
      });

      const [patches] = getSpacesPatches(
        thunkApi.getState().spaces,
        (draft) => {
          const space = scopedSpacesSelectors.selectById(draft, spaceId);
          if (!space) {
            return;
          }

          spacesAdapter.updateOne(draft.spaces, {
            id: spaceId,
            changes: {
              labels: space.labels.filter(
                (l) => !Array.from(args.removeMap[spaceId]).includes(l.id)
              ),
            } as FixMe,
          });
        }
      );

      thunkApi.dispatch(patchSpacesState(patches));
    };

    const createAndAddLabels = async (spaceId: string) => {
      if (
        !args.addedLabelIds.length &&
        !args.createdLabelNames.length &&
        !args.addMap[spaceId]?.size
      ) {
        return;
      }

      const response = await client.post(`/app/spaces/${spaceId}/labels`, [
        ...Array.from(args.addMap[spaceId] || []).map((id) => ({ id })),
        ...args.addedLabelIds.map((id) => ({ id })),
        ...args.createdLabelNames.map((name) => ({ name })),
      ]);

      const addedOrCreatedLabels: CoreSpaceLabel[] = response.data || [];

      const existingLabels = labelsSelectors.selectEntities(
        thunkApi.getState()
      );

      const addedLabels = addedOrCreatedLabels.filter(
        (l) => existingLabels[l.id]
      );
      const createdLabels = addedOrCreatedLabels.filter(
        (l) => !existingLabels[l.id]
      );

      const [patches] = getSpacesPatches(
        thunkApi.getState().spaces,
        (draft) => {
          // upsert to update existing and add new labesl
          labelsAdapter.upsertMany(
            draft.labels,
            addedLabels.concat(createdLabels)
          );

          const space = scopedSpacesSelectors.selectById(draft, spaceId);
          if (!space) {
            return;
          }

          // add addedLabels, createdLabels to space
          spacesAdapter.updateOne(draft.spaces, {
            id: spaceId,
            changes: {
              labels: space.labels.concat(addedLabels).concat(createdLabels),
            },
          });
        }
      );

      thunkApi.dispatch(patchSpacesState(patches));
    };

    const results = await Promise.allSettled([
      ...args.spaceIds.map(removeLabels),
      ...args.spaceIds.map(createAndAddLabels),
    ]);

    throwIfAggregateError('Failed to bulk save space labels', results);

    // TODO(wuweiweiwu): also need to get data back from backend to see if the label is orphaned
    // so we can update the state without a full refetch
    // Refresh labels from server, in case any have been removed
    thunkApi.dispatch(asyncFetchLabelsThunk());
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
