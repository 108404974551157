import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  WEBSOCKET_OPEN,
  WEBSOCKET_CLOSED,
  WEBSOCKET_MESSAGE,
} from '@giantmachines/redux-websocket';
import moment from 'moment';

import { OA_LIVE_SOCKET_PREFIX, withPayloadType } from 'lib/redux';
import { FloorplanTargetInfo } from 'components/track-visualizer';
import { Seconds } from 'lib/units';
import { AVAILABILITY_SOCKET_PREFIX } from 'lib/redux';
import { FloorplanCoordinates } from 'lib/geometry';
import { OALiveSocketMessage } from 'lib/oa-live-socket';

// CONFIGURABLE STUFF
// How often to update availability
export const AVAILABILITY_UPDATE_CADENCE = 250;
// How many targets must be inside the space during the time window
export const MIN_MATCHING_TARGETS_PER_TIME_WINDOW = 5;
// How long of a sliding window is looked at for availability status
export const ANALYSIS_TIME_WINDOW_SECONDS = 3;
// How long to wait before releasing old data
export const TRACK_DATA_RELEASE_TIMEOUT_SECONDS = 15;
// END CONFIGURABLE STUFF

export const hoverArea = createAction(
  'availability/hoverArea',
  withPayloadType<string>()
);

export const unhoverArea = createAction(
  'availability/unhoverArea',
  withPayloadType<void>()
);

export const observerChange = createAction(
  'availability/observerChange',
  withPayloadType<Array<{ areaId: string; isAvailable: boolean }>>()
);

export const toggleAreaLabels = createAction(
  'availability/toggleAreaLabels',
  withPayloadType<void>()
);

export const toggleShowTracks = createAction(
  'availability/toggleShowTracks',
  withPayloadType<void>()
);

export const clearOldTargets = createAction(
  'availability/clearOldTargets',
  withPayloadType<void>()
);

const socketOpen = createAction(
  `${AVAILABILITY_SOCKET_PREFIX}::${WEBSOCKET_OPEN}`
);

const socketClosed = createAction(
  `${AVAILABILITY_SOCKET_PREFIX}::${WEBSOCKET_CLOSED}`
);

// message action shape https://github.com/giantmachines/redux-websocket#%EF%B8%8F-redux_websocketmessage
const socketMesssage = createAction(
  `${AVAILABILITY_SOCKET_PREFIX}::${WEBSOCKET_MESSAGE}`,
  withPayloadType<{
    message: string;
    origin: string;
  }>()
);

const OALiveSocketOpen = createAction(
  `${OA_LIVE_SOCKET_PREFIX}::${WEBSOCKET_OPEN}`
);

const OALiveSocketClosed = createAction(
  `${OA_LIVE_SOCKET_PREFIX}::${WEBSOCKET_CLOSED}`
);

const OALiveSocketMesssage = createAction(
  `${OA_LIVE_SOCKET_PREFIX}::${WEBSOCKET_MESSAGE}`,
  withPayloadType<{
    message: string;
    origin: string;
  }>()
);

type AvailabilityState = {
  availabilityByAreaId: ReadonlyMap<string, boolean>;
  highlightedArea: string | null;
  showAllAreaLabels: boolean;
  showTracks: boolean;
  isWebsocketOpen: boolean;
  targets: Array<FloorplanTargetInfo>;
};

const initialState: AvailabilityState = {
  availabilityByAreaId: new Map(),
  highlightedArea: null,
  showAllAreaLabels: true,
  showTracks: true,
  isWebsocketOpen: false,
  // previously global TARGETS
  targets: [],
};

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hoverArea, (state, action) => {
      state.highlightedArea = action.payload;
    });

    builder.addCase(unhoverArea, (state, action) => {
      state.highlightedArea = null;
    });

    builder.addCase(observerChange, (state, action) => {
      action.payload.forEach((datum) => {
        state.availabilityByAreaId.set(datum.areaId, datum.isAvailable);
      });
    });

    builder.addCase(toggleAreaLabels, (state, action) => {
      state.showAllAreaLabels = !state.showAllAreaLabels;
    });

    builder.addCase(toggleShowTracks, (state, action) => {
      state.showTracks = !state.showTracks;
    });

    builder.addCase(clearOldTargets, (state, action) => {
      const now = Seconds.fromMilliseconds(Date.now());

      state.targets = state.targets.filter((target) => {
        const then = target.timestamp;
        return then >= now - TRACK_DATA_RELEASE_TIMEOUT_SECONDS;
      });
    });

    builder.addCase(socketOpen, (state, action) => {
      state.isWebsocketOpen = true;
    });

    builder.addCase(socketClosed, (state, action) => {
      state.isWebsocketOpen = false;
    });

    builder.addCase(socketMesssage, (state, action) => {
      const message = action.payload.message;

      const payload = JSON.parse(message) as
        | {
            type: 'area_use';
            timestamp: string;
            area_id: string;
            presence: boolean;
          }
        | {
            type: 'floorplan_targets';
            timestamp: string;
            sensor: string;
            targets: Array<{
              id: number;
              x: number;
              y: number;
              tag: number;
            }>;
          };

      if (payload.type === 'floorplan_targets') {
        const targets = (payload.targets || []).map((target) => ({
          timestamp: Seconds.fromMilliseconds(
            moment(payload.timestamp).valueOf()
          ),
          sensorSerial: payload.sensor,
          position: FloorplanCoordinates.create(target.x, target.y),
        }));

        state.targets.push(...targets);
      }
    });

    builder.addCase(OALiveSocketOpen, (state, action) => {
      state.isWebsocketOpen = true;
    });

    builder.addCase(OALiveSocketClosed, (state, action) => {
      state.isWebsocketOpen = false;
    });

    builder.addCase(OALiveSocketMesssage, (state, action) => {
      const message = action.payload.message;
      const payload: OALiveSocketMessage = JSON.parse(message);

      if (payload.message_type === 'targets') {
        const targets = (payload.targets || []).map((target) => ({
          timestamp: Seconds.fromMilliseconds(
            moment(payload.event_at).valueOf()
          ),
          sensorSerial: payload.entity_id,
          position: FloorplanCoordinates.create(target.x, target.y),
        }));

        state.targets.push(...targets);
      }
    });
  },
});

// export reducer as default expoort
export default availabilitySlice.reducer;
