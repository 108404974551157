import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { ViewportCoordinates } from 'lib/geometry';

const EndpointGraphic: React.FunctionComponent<{
  position: ViewportCoordinates;
  radius: number;
  color?: string;
  onMouseDown?: (evt: React.MouseEvent) => void;
  onMouseEnter?: (evt: React.MouseEvent) => void;
  onMouseLeave?: (evt: React.MouseEvent) => void;
}> = ({
  position,
  radius,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  color = colors.yellow,
}) => {
  return (
    <g
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <line
        x1={position.x - radius}
        y1={position.y}
        x2={position.x + radius}
        y2={position.y}
        stroke={color}
        strokeWidth={1}
      />
      <line
        x1={position.x}
        y1={position.y - radius}
        x2={position.x}
        y2={position.y + radius}
        stroke={color}
        strokeWidth={1}
      />
      <circle
        cx={position.x}
        cy={position.y}
        r={radius}
        stroke={color}
        strokeWidth={2}
        fill={'transparent'}
      />
    </g>
  );
};

export default EndpointGraphic;
