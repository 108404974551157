import { useEffect, useRef, useMemo } from 'react';
import * as PIXI from 'pixi.js';
import { ViewportCoordinates, FloorplanCoordinates } from 'lib/geometry';
import { Floorplan as FloorplanType } from 'lib/floorplan';
import { Viewport } from 'lib/viewport';

import { PlacementMode } from 'components/floorplan/placement-mode';
import {
  Layer,
  MetricLabel,
  useFloorplanLayerContext,
  toRawHex,
} from 'components/floorplan';

import { Gray400 } from '@density/dust/dist/tokens/dust.tokens';

const PLACEMENT_TOOLTIP_OFFSET_X_PX = 16;

// The object placement layer renders information about objects about to be placed, and facilutates
// rendering a backdrop over top of all other elements so clicks anywhere on the floorplan are
// trapped.
const ObjectPlacementTargetLayer: React.FunctionComponent<{
  placementMode: PlacementMode | null;
  onMouseDown: (coords: FloorplanCoordinates, evt: MouseEvent) => void;
  onMouseMove: (
    coords: FloorplanCoordinates,
    evt: MouseEvent,
    viewport: Viewport,
    floorplan: FloorplanType
  ) => void;
}> = ({ placementMode, onMouseDown, onMouseMove }) => {
  const context = useFloorplanLayerContext();

  const mousePositionRef = useRef<ViewportCoordinates | null>(null);

  const addPlacementText: string | null = useMemo(() => {
    return PlacementMode.computeText(placementMode);
  }, [placementMode]);

  // When the user goes into placement mode, render a label next to the cursor
  useEffect(() => {
    if (!placementMode) {
      return;
    }
    if (!context.viewport.current) {
      return;
    }

    const canvasElement = context.app.view;
    const canvasBBox = canvasElement.getBoundingClientRect();

    // Register mouse handlers
    const onMouseDownCanvas = (evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }

      const viewportCoords = ViewportCoordinates.create(
        evt.clientX - canvasBBox.x,
        evt.clientY - canvasBBox.y
      );
      const floorplanCoords = ViewportCoordinates.toFloorplanCoordinates(
        viewportCoords,
        context.viewport.current,
        context.floorplan
      );
      onMouseDown(floorplanCoords, evt);
    };
    const onMouseMoveCanvas = (evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }

      mousePositionRef.current = ViewportCoordinates.create(
        evt.clientX - canvasBBox.x,
        evt.clientY - canvasBBox.y
      );

      if (onMouseMove) {
        const floorplanCoords = ViewportCoordinates.toFloorplanCoordinates(
          mousePositionRef.current,
          context.viewport.current,
          context.floorplan
        );
        onMouseMove(
          floorplanCoords,
          evt,
          context.viewport.current,
          context.floorplan
        );
      }
    };
    const onMouseOutCanvas = () => {
      mousePositionRef.current = null;
    };
    canvasElement.addEventListener('mousedown', onMouseDownCanvas);
    canvasElement.addEventListener('mousemove', onMouseMoveCanvas);
    canvasElement.addEventListener('mouseout', onMouseOutCanvas);

    const objectPlacementLabel = new MetricLabel(addPlacementText || '', {
      backgroundColor: toRawHex(Gray400),
      pinHorizontal: 'start',
      textStyle: new PIXI.TextStyle({
        // FIXME: load correct font here
        fontFamily: 'Arial',
        fontSize: 14,
        fontWeight: 'bold',
        fill: '#ffffff',
      }),
      horizontalPaddingPixels: 16,
      verticalPaddingPixels: 9,
      radiusPixels: 32,
    });
    objectPlacementLabel.name = 'object-placement-label';
    context.app.stage.addChild(objectPlacementLabel);

    // A transparent backgrop is rendered overtop of the whole stage
    // to ensure that we have control over the mouse position
    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.name = 'object-placement-backdrop';
    backdrop.width = context.viewport.current.width;
    backdrop.height = context.viewport.current.height;
    backdrop.alpha = 0;
    backdrop.interactive = true;
    backdrop.cursor = 'copy';
    context.app.stage.addChild(backdrop);

    return () => {
      context.app.stage.removeChild(backdrop);
      context.app.stage.removeChild(objectPlacementLabel);

      mousePositionRef.current = null;

      canvasElement.removeEventListener('mousedown', onMouseDownCanvas);
      canvasElement.removeEventListener('mousemove', onMouseMoveCanvas);
      canvasElement.removeEventListener('mouseout', onMouseOutCanvas);
    };
    // Only rerun the hook when placementMode is set or unset. It gets changed often and completely
    // setting up / tearing down all the sprites / mouse events is way too slow
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placementMode === null, addPlacementText]);

  return (
    <Layer
      onAnimationFrame={() => {
        const objectPlacementLabel = context.app.stage.getChildByName(
          'object-placement-label'
        ) as MetricLabel | undefined;
        if (!objectPlacementLabel) {
          return;
        }

        // If the mouse is no longer on the canvas, then hide the label
        if (!mousePositionRef.current) {
          objectPlacementLabel.visible = false;
          return;
        }

        objectPlacementLabel.visible = true;
        objectPlacementLabel.x =
          mousePositionRef.current.x + PLACEMENT_TOOLTIP_OFFSET_X_PX;
        objectPlacementLabel.y = mousePositionRef.current.y;
        if (addPlacementText) {
          objectPlacementLabel.setText(addPlacementText);
        }
      }}
    />
  );
};

export default ObjectPlacementTargetLayer;
