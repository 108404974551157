import { useEffect } from 'react';
import * as React from 'react';
import * as d3 from 'd3';

import { Action } from './actions';
import { Sensor, Simulant } from './state';

import { FloorplanCoordinates } from 'lib/geometry';
import { computeCoverageRadiusOA } from 'lib/sensor';

const SensorSimulationObserver: React.FunctionComponent<{
  sensor: Sensor;
  simulants: Array<Simulant>;
  dispatch: React.Dispatch<Action>;
}> = ({ sensor, simulants, dispatch }) => {
  useEffect(() => {
    const interval = window.setInterval(() => {
      const radius = computeCoverageRadiusOA(sensor.height);
      const points = simulants.reduce<Array<FloorplanCoordinates>>(
        (points, simulant) => {
          const distance = Math.hypot(
            sensor.position.x - simulant.position.x,
            sensor.position.y - simulant.position.y
          );
          // filter out points outside FoV
          if (distance > radius) return points;

          const numPointsToGenerate = d3.randomPoisson(
            Math.floor(Math.random() * 44 * (1 / (0.8 * distance)))
          )();
          const newPoints = Simulant.generatePoints(
            simulant,
            numPointsToGenerate
          );
          points.push(...newPoints);
          return points;
        },
        []
      );
      dispatch({ type: 'simulation.sensor.points', id: sensor.id, points });
    }, 1000 / 6.6666);

    return () => {
      window.clearInterval(interval);
    };
  }, [sensor, simulants, dispatch]);
  return null;
};

export default SensorSimulationObserver;
