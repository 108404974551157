import { Meters } from './units';
import { degreesToRadians } from './math';

export const computeCoverageRadiusOA = (installHeight: number) => {
  // assumptions for now
  const fovDegrees = 105;
  const requiredSubjectCoverageHeight = 0; // for now...

  // the computed height accounting for the requirement of subject coverage
  const H = installHeight - requiredSubjectCoverageHeight;

  const fovRadians = degreesToRadians(fovDegrees);
  return Math.min(H * Math.tan(fovRadians / 2), Meters.fromFeet(12));
};

export const computeCoverageMajorMinorAxisOA = (
  installHeightMeters: number
): [number, number] => {
  if (installHeightMeters < Meters.fromFeet(8)) {
    installHeightMeters = Meters.fromFeet(8);
  }
  if (installHeightMeters > Meters.fromFeet(12)) {
    installHeightMeters = Meters.fromFeet(12);
  }

  // See this document for where these multipliers were found:
  // https://www.notion.so/densityinc/OA1-Technical-Specifications-Frequently-Asked-Questions-FAQ-DRAFT-Data-Sheet-1ef5fb5bd81d443f93fade8c33234a0f#a66c61a854144024bed791cfd8eb6394
  const majorMeters = Math.min(
    installHeightMeters * 1.3,
    Meters.fromFeet(14.3)
  );
  const minorMeters = Math.min(installHeightMeters * 0.92, Meters.fromFeet(9));
  return [majorMeters, minorMeters];
};

export const computeCoverageRadiusEntry = (installHeight: number) => {
  // this uses the linear scale from our installation documentation:
  // install height     maximum install width
  // 90"                40"
  // 93"                45"
  // 96"                50"
  // 99"                55"
  // 102"               60"
  // 105"               65"
  // 108"               70"
  // 111"               75"
  // 114"               80"
  // 117"               85"
  // 120"               90"
  const heightInches = Meters.toInches(installHeight);
  const installWidth = 40 + (5 * (heightInches - 90)) / 3;
  return Meters.fromInches(installWidth / 2);
};

export const computeCoverageArea = (coverageRadius: number) => {
  return Math.PI * Math.pow(coverageRadius, 2);
};

// Given a sensor, return the os build number part os the os version
export function extractOSBuildNumber(osVersion: string): number | null {
  if (!osVersion) {
    return null;
  }

  const buildNumber = window.parseInt(osVersion.split('-')[0], 10);
  if (isNaN(buildNumber)) {
    return null;
  }

  return buildNumber;
}

const FIRST_BUILD_WITH_SENSOR_LOCATE_AND_IMMEDIATE_COMMANDS = 10094;

export function supportsLocate(osVersion: string): boolean {
  const buildNumber = extractOSBuildNumber(osVersion);

  if (!buildNumber) {
    return false;
  }

  return buildNumber >= FIRST_BUILD_WITH_SENSOR_LOCATE_AND_IMMEDIATE_COMMANDS;
}
