import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';

import App from 'components/app/app';
import { APP_ENV, VERSION } from 'config';

import 'normalize.css/normalize.css';
import '@densityco/ui/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import './styles/index.scss';

console.info('Density Open Area', APP_ENV, VERSION);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
