import { createSelector } from '@reduxjs/toolkit';

import { aggregateData } from 'lib/charts';
import { RootState } from 'redux/store';
import { selectSpace } from '../spaces/select-space';
import { selectTimeZone } from '../spaces/select-timezone';

export const selectAvgUtilizationForSpace = createSelector(
  [
    (state: RootState) => state.analysis.utilizationAnalyticsByHour,

    // selectors can compose other selectors wow
    selectSpace,
    selectTimeZone,

    // parameters
    (_: RootState, params: { spaceId?: string }) => params.spaceId,
  ],
  (utilizationAnalyticsByHour, space, timeZone, spaceId) => {
    if (!spaceId || !space) {
      return;
    }

    if (!space.capacity) {
      return;
    }

    const data = utilizationAnalyticsByHour.entities[spaceId]?.data;
    if (!data?.length) {
      return;
    }

    const aggregation = aggregateData(data, timeZone, 'avg', 'hour');

    const average =
      aggregation.reduce((acc, curr) => acc + curr.value, 0) /
      aggregation.length;

    return average / space.capacity;
  }
);
