import { useState, useEffect } from 'react';

import TextField from 'components/text-field';

import { round } from 'lib/math';

const OpacityField: React.FunctionComponent<{
  value: number;
  onChange: (value: number) => void;
  'data-cy'?: string;
}> = ({ value, onChange, 'data-cy': dataCy }) => {
  const [opacityText, setOpacityText] = useState('');
  useEffect(() => {
    setOpacityText(`${round(value, 2)}`);
  }, [value]);
  const [opacityInvalid, setOpacityInvalid] = useState(false);

  return (
    <TextField
      type="number"
      value={opacityText}
      error={opacityInvalid}
      onChange={(e) => {
        setOpacityText(e.currentTarget.value);

        let opacity = parseFloat(e.currentTarget.value);
        if (isNaN(opacity)) {
          setOpacityInvalid(true);
          return;
        }
        setOpacityInvalid(false);
        onChange(opacity);
      }}
      trailingSuffix="%"
      width={85}
      size="medium"
      onBlur={() => {
        let opacity = parseFloat(opacityText);
        if (isNaN(opacity)) {
          setOpacityInvalid(true);
          return;
        }
        if (opacity > 100) {
          opacity = 100;
        }
        if (opacity < 0) {
          opacity = 0;
        }
        setOpacityInvalid(false);
        onChange(opacity);
      }}
      data-cy={dataCy}
    />
  );
};

export default OpacityField;
