import { useEffect, useState } from 'react';

// when the image is fully loaded, return the image
export const useImageLoader = (imageSrc?: string) => {
  const [image, setImage] = useState<HTMLImageElement>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!imageSrc) {
      return;
    }

    // don't clear existing image
    setIsLoading(true);
    setError(undefined);

    const img = new Image();

    // this will fire when the image is fully loaded
    img.addEventListener('load', () => {
      setImage(img);
      setIsLoading(false);
      setError(undefined);
    });

    img.addEventListener('error', (evt) => {
      setIsLoading(false);
      setError(new Error(`Failed to load image. url: ${imageSrc}`));
    });

    // setting the `src` attribute starts loading the image
    img.src = imageSrc;
  }, [imageSrc]);

  return [image, isLoading, error] as const;
};
