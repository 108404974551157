import { useEffect, useState } from 'react';
import * as React from 'react';

import { StorageKeys } from 'lib/storage';

const LogoutFinish: React.FunctionComponent = () => {
  const [finished, setFinished] = useState<boolean>(false);
  useEffect(() => {
    const token = localStorage.getItem(StorageKeys.SESSION_TOKEN);
    if (!token) {
      setFinished(true);
      return;
    }
    fetch('https://api.density.io/v2/logout', {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(() => {
      // remove token from storage
      localStorage.removeItem(StorageKeys.SESSION_TOKEN);
      setFinished(true);
    });
  }, []);
  return finished ? (
    <div>
      You have successfully logged out! Click <a href={'/'}>here</a> to login
      again.
    </div>
  ) : (
    <div>Logging out...</div>
  );
};

export default LogoutFinish;
