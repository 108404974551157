import colors from '@densityco/ui/variables/colors.json';

import {
  FloorplanCollection,
  ReferenceRuler,
  Sensor,
  Reference,
} from './state';

import { FloorplanCoordinates, ImageCoordinates } from 'lib/geometry';
import { Floorplan } from 'lib/floorplan';
import { Viewport } from 'lib/viewport';
import { LengthUnit, displayLength } from 'lib/units';
import { degreesToRadians } from 'lib/math';
import { Measurement } from 'components/floorplan-measure/floorplan-measure';

const LEADER_LINE_STEM_LENGTH_PX = 32;
const END_POINT_RADIUS_PX = 4;

// A utility function to render a "tag" containing text centered on a point
function drawMetricLabel(
  ctx: CanvasRenderingContext2D,
  scaleRatio: number,
  center: ImageCoordinates,
  color: string,
  text: string
): number {
  const textHeight = 14;
  const textMargin = 2;

  ctx.font = `${textHeight / scaleRatio}px Helvetica`;
  const textWidth = ctx.measureText(text).width;

  const textBackgroundWidth = textWidth + textMargin * 2;
  const textBackgroundHeight = textHeight + textMargin * 2;

  ctx.beginPath();
  ctx.rect(
    center.x - textWidth / 2 - textMargin,
    center.y - textHeight / 2 - textMargin,
    textBackgroundWidth,
    textBackgroundHeight
  );
  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();

  ctx.fillStyle = colors.white;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  ctx.fillText(text, center.x, center.y);

  return textBackgroundWidth;
}

// Given an arrow tip location and a point somewhere else along the line segment, render an
// arrowhead
function drawArrowHead(
  ctx: CanvasRenderingContext2D,
  scaleRatio: number,
  color: string,
  arrowTip: ImageCoordinates,
  lineTail: ImageCoordinates
) {
  const angle = Math.atan2(lineTail.y - arrowTip.y, lineTail.x - arrowTip.x);

  const arrowSideLength = 20;
  const arrowDegrees = 20;

  const pointA = ImageCoordinates.create(
    arrowTip.x +
      arrowSideLength * Math.cos(angle + degreesToRadians(arrowDegrees)),
    arrowTip.y +
      arrowSideLength * Math.sin(angle + degreesToRadians(arrowDegrees))
  );

  const pointB = ImageCoordinates.create(
    arrowTip.x +
      arrowSideLength * Math.cos(angle - degreesToRadians(arrowDegrees)),
    arrowTip.y +
      arrowSideLength * Math.sin(angle - degreesToRadians(arrowDegrees))
  );

  ctx.beginPath();
  ctx.moveTo(arrowTip.x, arrowTip.y);
  ctx.lineTo(pointA.x, pointA.y);
  ctx.lineTo(pointB.x, pointB.y);
  ctx.closePath();
  ctx.fillStyle = colors.yellow;
  ctx.fill();
}

// A utility function to draw an dimension line on a canvas
function drawDimension(
  ctx: CanvasRenderingContext2D,
  scaleRatio: number,
  sensorCountBarHeight: number,
  floorplan: Floorplan,
  viewport: Viewport,
  displayUnit: LengthUnit,
  color: string,
  positionA: FloorplanCoordinates,
  positionB: FloorplanCoordinates,
  distanceLabelPosition?: FloorplanCoordinates
) {
  let positionAImageCoordinates = FloorplanCoordinates.toImageCoordinates(
    positionA,
    floorplan
  );
  let positionBImageCoordinates = FloorplanCoordinates.toImageCoordinates(
    positionB,
    floorplan
  );

  let distanceLabelPositionImageCoordinates = distanceLabelPosition
    ? FloorplanCoordinates.toImageCoordinates(distanceLabelPosition, floorplan)
    : null;

  // shift everything down by the top bar height
  positionAImageCoordinates.y =
    positionAImageCoordinates.y + sensorCountBarHeight / scaleRatio;
  positionBImageCoordinates.y =
    positionBImageCoordinates.y + sensorCountBarHeight / scaleRatio;
  if (distanceLabelPositionImageCoordinates) {
    distanceLabelPositionImageCoordinates.y =
      distanceLabelPositionImageCoordinates.y +
      sensorCountBarHeight / scaleRatio;
  }

  // Render reference line
  ctx.beginPath();
  ctx.moveTo(positionAImageCoordinates.x, positionAImageCoordinates.y);
  ctx.lineTo(positionBImageCoordinates.x, positionBImageCoordinates.y);
  ctx.closePath();

  ctx.strokeStyle = color;
  ctx.lineWidth = 3;
  ctx.stroke();

  // Make endpoints
  ctx.beginPath();
  ctx.ellipse(
    positionAImageCoordinates.x,
    positionAImageCoordinates.y,
    END_POINT_RADIUS_PX,
    END_POINT_RADIUS_PX,
    0,
    0,
    degreesToRadians(360)
  );
  ctx.ellipse(
    positionBImageCoordinates.x,
    positionBImageCoordinates.y,
    END_POINT_RADIUS_PX,
    END_POINT_RADIUS_PX,
    0,
    0,
    degreesToRadians(360)
  );
  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();

  const floorplanX = positionB.x - positionA.x;
  const floorplanY = positionB.y - positionA.y;
  const distanceMeters = Math.hypot(floorplanX, floorplanY);
  const distanceText = displayLength(distanceMeters, displayUnit);

  // Draw metric label
  let metricLabelPosition = distanceLabelPositionImageCoordinates;
  if (!metricLabelPosition) {
    metricLabelPosition = FloorplanCoordinates.toImageCoordinates(
      ReferenceRuler.calculateCenterPoint({ positionA, positionB }),
      floorplan
    );
  }
  const distanceTextBackgroundWidth = drawMetricLabel(
    ctx,
    scaleRatio,
    metricLabelPosition,
    color,
    distanceText
  );

  // Draw leader line if:
  // 1. There's an explicit distance label position
  // 2. That distance label position isn't in the center of the reference line
  if (!distanceLabelPosition || !distanceLabelPositionImageCoordinates) {
    return;
  }

  const isDistanceLockedToCenter = ReferenceRuler.isDistanceLockedToCenter(
    floorplan,
    viewport,
    distanceLabelPosition,
    ReferenceRuler.calculateCenterPoint({ positionA, positionB })
  );

  if (isDistanceLockedToCenter) {
    return;
  }

  ctx.lineWidth = 1;

  ctx.beginPath();
  const centerPointX =
    (positionAImageCoordinates.x + positionBImageCoordinates.x) / 2;
  const centerPointY =
    (positionAImageCoordinates.y + positionBImageCoordinates.y) / 2;

  let leaderLineStartX: number;
  if (centerPointX > distanceLabelPositionImageCoordinates.x) {
    // Draw the leader line to the right of the metric label
    leaderLineStartX =
      distanceLabelPositionImageCoordinates.x + distanceTextBackgroundWidth / 2;
    ctx.moveTo(leaderLineStartX, distanceLabelPositionImageCoordinates.y);
    ctx.lineTo(
      leaderLineStartX + LEADER_LINE_STEM_LENGTH_PX,
      distanceLabelPositionImageCoordinates.y
    );
  } else {
    // Draw the leader line to the left of the metric label
    leaderLineStartX =
      distanceLabelPositionImageCoordinates.x - distanceTextBackgroundWidth / 2;
    ctx.moveTo(leaderLineStartX, distanceLabelPositionImageCoordinates.y);
    ctx.lineTo(
      leaderLineStartX - LEADER_LINE_STEM_LENGTH_PX,
      distanceLabelPositionImageCoordinates.y
    );
  }
  ctx.lineTo(centerPointX, centerPointY);
  ctx.strokeStyle = color;
  ctx.stroke();

  // Make midpoint
  drawArrowHead(
    ctx,
    scaleRatio,
    colors.yellow,
    ImageCoordinates.create(centerPointX, centerPointY),
    ImageCoordinates.create(
      leaderLineStartX,
      distanceLabelPositionImageCoordinates.y
    )
  );
}

export async function generateExportCanvas(
  floorplanImage: HTMLImageElement,
  sensors: FloorplanCollection<Sensor>,
  references: FloorplanCollection<Reference>,
  floorplan: Floorplan,
  viewport: Viewport,
  measurement: Measurement | undefined,
  displayUnit: LengthUnit
): Promise<HTMLCanvasElement> {
  // ====================================
  // Sensor Count bar (fixed 30px height)
  // ------------------------------------
  //
  // Floorplan (scaled to 2000px width)
  //
  // ====================================
  const canvas = document.createElement('canvas');
  const sensorCountBarHeight = 30;
  const desiredWidth = 5000;
  const scaleRatio = desiredWidth / floorplanImage.width;
  canvas.width = floorplanImage.width * scaleRatio;
  canvas.height = floorplanImage.height * scaleRatio + sensorCountBarHeight;

  const ctx = canvas.getContext('2d');
  if (!ctx) throw new Error('Could not get canvas 2D context');

  ctx.scale(scaleRatio, scaleRatio);

  // white background (behind lowered opacity floorplan)
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(
    0,
    sensorCountBarHeight / scaleRatio,
    floorplanImage.width,
    floorplanImage.height
  );

  // draw floorplan image @ lowered opacity
  // This should be easy... but because of CORS related reasons, it is not
  // So a) hack around a CORS cache issue in chrome, and b) get this image with fetch because I
  // can't figure out how to configure CORS to work right with a `new Image()`
  ctx.globalAlpha = 0.6;
  let imageSrc = floorplanImage.src;
  if (imageSrc.includes('s3.amazonaws.com')) {
    // In order to ensure chrome doesn't cache the base image, a cache-busting query parameter is
    // needed. See https://github.com/DensityCo/nashi-client/blob/master/src/utils/downloadImage.ts
    // for where I got this technique, or talk to wei-wei for more info.
    const result = new URL(imageSrc);
    result.searchParams.set('nonce', `${new Date().getTime()}`);
    imageSrc = result.toString();
  }
  const response = await fetch(imageSrc);
  if (!response.ok) {
    throw new Error('Failed to fetch floorplan image!');
  }
  const imageBlob = await response.blob();
  const imageURL = URL.createObjectURL(imageBlob);

  // Wrap bytes into a new Image element
  const newImage = new Image();
  newImage.src = imageURL;
  await new Promise((resolve) => newImage.addEventListener('load', resolve));

  ctx.drawImage(newImage, 0, sensorCountBarHeight / scaleRatio);
  ctx.globalAlpha = 1.0;

  // sensor count bar
  ctx.fillStyle = colors.midnight;
  ctx.fillRect(0, 0, floorplanImage.width, sensorCountBarHeight / scaleRatio);

  // sensor count text
  const countOA = Sensor.filterByType('oa', sensors).length;
  const countEntry = Sensor.filterByType('entry', sensors).length;

  ctx.font = `${16 / scaleRatio}px Helvetica`;
  ctx.fillStyle = colors.white;

  ctx.fillText(`OA: ${countOA}`, 50 / scaleRatio, 21 / scaleRatio);
  ctx.fillText(`Entry: ${countEntry}`, 158 / scaleRatio, 21 / scaleRatio);

  // sensor count OA legend
  const sensorLegendWidth = 15 / scaleRatio;
  const oaLegendCenter = {
    x: 30 / scaleRatio,
    y: sensorCountBarHeight / scaleRatio / 2,
  };
  ctx.beginPath();
  ctx.rect(
    oaLegendCenter.x - sensorLegendWidth / 2,
    oaLegendCenter.y - sensorLegendWidth / 2,
    sensorLegendWidth,
    sensorLegendWidth
  );
  ctx.closePath();
  ctx.lineWidth = 2 / scaleRatio;
  ctx.lineJoin = 'round';
  ctx.strokeStyle = colors.white;
  ctx.stroke();

  // sensor count entry legend
  const entryLegendCenter = {
    x: 140 / scaleRatio,
    y: sensorCountBarHeight / scaleRatio / 2,
  };
  ctx.beginPath();
  ctx.moveTo(
    entryLegendCenter.x - sensorLegendWidth / 1.8,
    entryLegendCenter.y + sensorLegendWidth / 2
  );
  ctx.lineTo(entryLegendCenter.x, entryLegendCenter.y - sensorLegendWidth / 2);
  ctx.lineTo(
    entryLegendCenter.x + sensorLegendWidth / 1.8,
    entryLegendCenter.y + sensorLegendWidth / 2
  );
  ctx.closePath();
  ctx.lineWidth = 2 / scaleRatio;
  ctx.lineJoin = 'round';
  ctx.strokeStyle = colors.white;
  ctx.stroke();

  // sensor markers
  const sensorMarkerWidth = 24;
  Array.from(sensors.items).forEach(([, s]) => {
    let { x, y } = FloorplanCoordinates.toImageCoordinates(
      s.position,
      floorplan
    );

    // shift everything down by the top bar height
    y = y + sensorCountBarHeight / scaleRatio;

    ctx.globalAlpha = 0.5;
    if (s.type === 'oa') {
      ctx.beginPath();
      ctx.rect(
        x - sensorMarkerWidth / 2,
        y - sensorMarkerWidth / 2,
        sensorMarkerWidth,
        sensorMarkerWidth
      );
      ctx.closePath();
      ctx.fillStyle = colors.purple;
    } else {
      ctx.beginPath();
      ctx.moveTo(x - sensorMarkerWidth / 1.8, y + sensorMarkerWidth / 2);
      ctx.lineTo(x, y - sensorMarkerWidth / 2);
      ctx.lineTo(x + sensorMarkerWidth / 1.8, y + sensorMarkerWidth / 2);
      ctx.closePath();
      ctx.fillStyle = colors.blue;
    }

    ctx.fill();
    ctx.globalAlpha = 1;
    ctx.shadowColor = colors.midnightOpaque20;
    ctx.shadowBlur = 10;

    ctx.lineWidth = 3;
    ctx.lineJoin = 'round';
    ctx.strokeStyle = colors.white;
    ctx.stroke();
  });

  ctx.shadowColor = 'transparent';
  ctx.shadowBlur = 0;

  // Reference lines
  Array.from(references.items).forEach(([, reference]) => {
    if (reference.type !== 'ruler') {
      return;
    }
    if (!reference.enabled) {
      return;
    }

    drawDimension(
      ctx,
      scaleRatio,
      sensorCountBarHeight,
      floorplan,
      viewport,
      displayUnit,
      colors.yellow,
      reference.positionA,
      reference.positionB,
      reference.distanceLabelPosition
    );
  });

  // sensor marker serial number labels
  Array.from(sensors.items).forEach(([, s]) => {
    if (!s.serialNumber) {
      return;
    }

    let { x, y } = FloorplanCoordinates.toImageCoordinates(
      s.position,
      floorplan
    );

    // shift everything down by the top bar height
    y = y + sensorCountBarHeight / scaleRatio;

    const color = s.type === 'oa' ? colors.purple : colors.blue;

    drawMetricLabel(
      ctx,
      scaleRatio,
      ImageCoordinates.create(x, y - sensorMarkerWidth - 2),
      color,
      s.serialNumber
    );
  });

  // Floorplan scale
  if (measurement) {
    drawDimension(
      ctx,
      scaleRatio,
      sensorCountBarHeight,
      floorplan,
      viewport,
      displayUnit,
      colors.blue,
      ImageCoordinates.toFloorplanCoordinates(measurement.pointA, floorplan),
      ImageCoordinates.toFloorplanCoordinates(measurement.pointB, floorplan)
    );
  }

  return canvas;
}

export function storeCanvasToDisk(
  canvas: HTMLCanvasElement,
  fileName: string = 'export',
  imageType: 'png' | 'jpg' = 'png'
) {
  const link = document.createElement('a');
  const outputFile = `${fileName}.${imageType}`;
  const uri = canvas.toDataURL(`image/${imageType}`, 1.0);

  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = outputFile;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
}
