import { useRef, useEffect } from 'react';

import * as PIXI from 'pixi.js';

import { useFloorplanLayerContext } from './floorplan-layer-context';
import Layer from './layer';

type ObjectId = string;
type ObjectLayerProps<T extends object, V extends PIXI.Container> = {
  objects: Array<T>;
  extractId: (obj: T) => ObjectId;
  onCreate: (objGetter: () => T) => V | null;
  onUpdate: (obj: T, geometry: V, animationFrame: boolean) => void;
  onRemove: (obj: T, geometry: V) => void;
};

const ObjectLayer = <T extends object, V extends PIXI.Container>({
  objects,
  extractId,
  onCreate,
  onUpdate,
  onRemove,
}: ObjectLayerProps<T, V>) => {
  const context = useFloorplanLayerContext();

  const objectGeometries = useRef<ReadonlyMap<ObjectId, V>>(new Map());

  const objectReferences = useRef<ReadonlyMap<ObjectId, T>>(new Map());

  const getObjectById = (
    objectId: ObjectId,
    o: ReadonlyMap<ObjectId, T> = objectReferences.current
  ): T => {
    const objOrUndefined = o.get(objectId);
    if (!objOrUndefined) {
      throw new Error(
        'An object should never be in objectGeometries that is not in objectReferences!'
      );
    }
    return objOrUndefined;
  };

  // Initially create each object / cleanup each object when component unmounts
  useEffect(() => {
    objectReferences.current = new Map(
      objects.map((object) => [extractId(object), object])
    );

    objectGeometries.current = new Map(
      objects.flatMap((object) => {
        const geometry = onCreate(() => getObjectById(extractId(object)));
        if (!geometry) {
          return [];
        }
        context.app.stage.addChild(geometry);
        return [[extractId(object), geometry]];
      })
    );

    return () => {
      for (const [objectId, geometry] of Array.from(
        objectGeometries.current.entries()
      )) {
        context.app.stage.removeChild(geometry);
        onRemove(getObjectById(objectId), geometry);
      }
      objectGeometries.current = new Map();
      objectReferences.current = new Map();
    };
    // Don't include all dependencies because this hook should _only_ run on component mount / unmount
    // Keeping these values up to date is the responsibility of the next hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.app]);

  useEffect(() => {
    // Cache old object references for "delete" action below
    const oldObjectReferences = objectReferences.current;
    objectReferences.current = new Map(
      objects.map((object) => [extractId(object), object])
    );

    const objectIdsUpdated: Array<string> = [];

    const updatedObjectGeometries: Array<[ObjectId, V]> = Array.from(
      objectGeometries.current.entries()
    ).flatMap(([objectId, geometry]) => {
      const matchingObjectFromProps = objects.find(
        (obj) => extractId(obj) === objectId
      );
      // console.log('UPDATING', objectId, 'WITH', matchingObjectFromProps)
      if (matchingObjectFromProps) {
        // Matching object found, so update
        onUpdate(matchingObjectFromProps, geometry, false);
        objectIdsUpdated.push(objectId);
        return [[extractId(matchingObjectFromProps), geometry]];
      } else {
        // No matching object was passed in, so delete
        context.app.stage.removeChild(geometry);
        onRemove(getObjectById(objectId, oldObjectReferences), geometry);
        return [];
      }
    });

    const newObjectGeometries: Array<[ObjectId, V]> = objects
      .filter((obj) => !objectIdsUpdated.includes(extractId(obj)))
      .flatMap((newObject) => {
        const geometry = onCreate(() => getObjectById(extractId(newObject)));
        if (!geometry) {
          return [];
        }
        context.app.stage.addChild(geometry);
        return [[extractId(newObject), geometry]];
      });

    objectGeometries.current = new Map([
      ...updatedObjectGeometries,
      ...newObjectGeometries,
    ]);
    // Updating when onCreate / onRemove / onUpdate changes would cause the diffing logic to get
    // more complex, so assume they are constant throughout the lifecycle of the component
    // FIXME: rewrite this logic to be able to handle these functions changing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects]);

  return (
    <Layer
      onAnimationFrame={(context, _timedelta, index) => {
        if (!context.viewport.current) {
          return;
        }

        for (const object of objects) {
          const geometry = objectGeometries.current.get(extractId(object));
          if (!geometry) {
            return;
          }

          onUpdate(object, geometry, true);
        }
      }}
    />
  );
};

export default ObjectLayer;
