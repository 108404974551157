import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';
import { CoreSpaceLabel } from '@densityco/lib-api-types';

import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';

export const asyncFetchLabelsThunk = createAsyncThunk<
  // return value
  CoreSpaceLabel[],
  // parameters
  void,
  // types for thunkApi
  AppThunkApiConfig
>(
  'spaces/asyncFetchLabels',
  async (_, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    const results = await auth.densityAPIClient.get<CoreSpaceLabel[]>(
      `/app/spaces/labels`
    );

    return results.data;
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
