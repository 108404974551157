import { useState, useCallback } from 'react';

import { useIsMounted } from './use-is-mounted';

export const useUpdate = () => {
  const [updateKey, setUpdateKey] = useState(0);

  const isMounted = useIsMounted();

  const update = useCallback(() => {
    // NOTE: this check is needed here because components can unmount while async actions are in flight
    if (isMounted()) {
      setUpdateKey((p) => p + 1);
    }
  }, [isMounted]);

  return [updateKey, update] as const;
};
