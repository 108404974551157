/**
 * WARNING: There are rules here
 *
 * Please do NOT import anything here.
 *
 * Any and all types used in a PersistedData structure should be defined here,
 * even if it seems duplicative.
 *
 * Types used in the rest of the application might change; these types must not.
 */

import { SensorStatus } from './state';

export namespace PersistedData {
  // IMPORTANT: Please add a type guard for each new version
  export function isV0(data: unknown): data is v0 {
    if (!(data && typeof data === 'object')) return false;
    return typeof (data as any).version === 'undefined';
  }
  export function isV1(data: unknown): data is v1 {
    if (!(data && typeof data === 'object')) return false;
    return (data as any).version === '1';
  }

  export type v1 = {
    // version of the json structure
    version: '1';
    // version of planner
    appVersion: string;
    // timestamp of save (eg. for sorting)
    millisecondsSinceEpoch: number;
    // title, ie. the name you would give to the "file"
    title: string;
    // the id of the duplicated 'ancestor'
    duplicated_from?: string;

    // floorplan data fields
    sensors: Array<v1.Sensor>;
    spaces: Array<v1.Space>;

    // original references = reference points
    references: Array<v1.ReferencePoint>;
    // new references = reference rulers
    referenceRulers?: Array<v1.ReferenceRuler>;
    simulants?: Array<v1.Simulant>;
    imageSize: {
      width: number;
      height: number;
    };
    imageScale: number;
    imageData: string;

    /**
     * NOTE: this is only optional because the info will not exist when converting
     * from a v0 file to a v1 file. All new sessions will provide this info.
     */
    // initial measurement info (optional)
    measurement?: v1.Measurement;

    // track sensor indices
    last_oa_sensor_index?: number;
    last_entry_sensor_index?: number;
  };

  export namespace v1 {
    export type Position = {
      type: 'image-coordinates';
      x: number;
      y: number;
    };

    export type Measurement = {
      pointA: Position;
      pointB: Position;
      userEnteredLength: {
        feetText: string;
        inchesText: string;
      };
      // meters
      computedLength: number;
      // pixels-per-meter
      computedScale: number;
    };

    export type Sensor = {
      id: string;
      type?: 'oa' | 'entry';
      name: string;
      position: Position;
      height: number;
      rotation: number;
      serialNumber: string;
      locked?: boolean;
      last_heartbeat?: string | null;
      status?: SensorStatus;
      ipv4?: string | null;
      ipv6?: string | null;
      mac?: string | null;
      os?: string | null;
      notes?: string | null;
      plan_sensor_index?: number | null;
    };

    export type Space = {
      id: string;
      name: string;
      position: Position;
      shape: v0.SpaceShape;
      locked?: boolean;
    };

    export type ReferencePoint = {
      id: string;
      position: Position;
      enabled: boolean;
    };

    export type ReferenceRuler = {
      id: string;
      positionA: Position;
      positionB: Position;
      enabled: boolean;
    };

    export type Simulant = {
      id: string;
      position: Position;
    };
  }

  // NOTE: v0 is identified by not having a `version` field
  export type v0 = {
    floorplan: v0.Floorplan;
    sensors: v0.Repository<v0.Sensor>;
    spaces: v0.Repository<v0.Space>;
    references: v0.Repository<v0.ReferencePoint>;
    simulants: Array<v0.Simulant>;
    imageData: string;
  };
  export namespace v0 {
    export type Repository<T extends { id: string }> = ReadonlyArray<
      [T['id'], T]
    >;
    export type Floorplan = {
      width: number;
      height: number;
      origin: ImageCoordinates;
      scale: number;
    };
    type ImageCoordinates = {
      type: 'image-coordinates';
      x: number;
      y: number;
    };
    type FloorplanCoordinates = {
      type: 'floorplan-coordinates';
      x: number;
      y: number;
    };
    export type Sensor = {
      id: string;
      type?: 'oa' | 'entry';
      name: string;
      position: FloorplanCoordinates;
      height: number;
      rotation: number;
      connection:
        | null
        | {
            type: 'local';
            localIPAddress: string;
            status: 'connecting' | 'connected' | 'disconnected';
          }
        | {
            type: 'cloud';
            serialNumber: string;
            status: 'connecting' | 'connected' | 'disconnected';
          };
      // note, nudge is applied after rotation
      dataNudgeX: number;
      dataNudgeY: number;
      frameWindowLength: number;
      serialNumber: string | null;
      last_heartbeat?: string | null;
      status?: SensorStatus;
      ipv4?: string | null;
      ipv6?: string | null;
      mac?: string | null;
      os?: string | null;
      plan_sensor_index?: number | null;
    };
    export type SpaceShape =
      | {
          type: 'box';
          width: number;
          height: number;
        }
      | {
          type: 'circle';
          radius: number;
        }
      | {
          type: 'polygon';
          vertices: Array<FloorplanCoordinates>;
        };

    export type Space = {
      id: string;
      name: string;
      position: FloorplanCoordinates;
      shape: SpaceShape;
      color: string;
    };
    export type ReferencePoint = {
      id: string;
      position: FloorplanCoordinates;
      enabled: boolean;
    };
    export type Simulant = {
      id: string;
      position: FloorplanCoordinates;
    };
  }

  export function loadImage(data: unknown): Promise<HTMLImageElement> {
    if (PersistedData.isV0(data) || PersistedData.isV1(data)) {
      return new Promise((resolve) => {
        const image = new Image();
        image.addEventListener('load', () => {
          resolve(image);
        });
        image.src = data.imageData;
      });
    }

    throw new Error(
      'Could not load image from persisted data, could not identify file version'
    );
  }

  export function serializeImage(image: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get canvas 2D context');
    ctx.drawImage(image, 0, 0);

    const imageData = canvas.toDataURL('image/png');
    return imageData;
  }
}
