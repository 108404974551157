import * as dust from '@density/dust/dist/tokens/dust.tokens';
import styled from '@emotion/styled';

const GhostButton = styled.button<{ isActive?: boolean }>`
  all: unset;
  font-family: inherit;
  border-radius: ${dust.Radius100};
  height: 24px;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.isActive ? dust.Blue400 : dust.Gray400)};
  background-color: ${(props) => (props.isActive ? dust.Blue100 : dust.White)};
  cursor: pointer;

  &:hover {
    color: ${dust.Blue400};
    background-color: ${dust.Blue100};
  }

  &[data-state='open'] {
    color: ${dust.Blue400};
    background-color: ${dust.Blue100};
  }
`;

export default GhostButton;
