import {
  ANALYSIS_TIME_WINDOW_SECONDS,
  AVAILABILITY_UPDATE_CADENCE,
  clearOldTargets,
  MIN_MATCHING_TARGETS_PER_TIME_WINDOW,
  observerChange,
} from './availability-slice';

import { AppThunk } from 'redux/store';
import { areaToSpace } from 'lib/area';
import { isPositionInsideSpace } from 'lib/algorithm';
import { FloorplanTargetInfo } from 'components/track-visualizer';
import { Seconds } from 'lib/units';
import { planDetailsSelectors } from '../plans/plans-slice';

function getAnalysisTimeWindow() {
  const end = Seconds.fromMilliseconds(Date.now());
  const start = end - ANALYSIS_TIME_WINDOW_SECONDS;
  return { start, end };
}

export const updateAvailabilityOnIntervalThunk =
  (args: { planId: string }): AppThunk<VoidFunction> =>
  (dispatch, getState) => {
    const interval = setInterval(() => {
      dispatch(clearOldTargets());

      const {
        availability: { targets },
      } = getState();

      // Filter targets to the time window for analysis
      const timeWindow = getAnalysisTimeWindow();
      const analysisTargets = targets.filter(
        (target) => target.timestamp > timeWindow.start
      );

      const plan = planDetailsSelectors.selectById(getState(), args.planId);
      if (!plan) {
        return;
      }

      // Then, look at each Area or "Space" and see how many targets are inside
      const spaces = plan.areas.map(areaToSpace);
      for (const space of spaces) {
        const matches: FloorplanTargetInfo[] = [];

        for (const target of analysisTargets) {
          const isInside = isPositionInsideSpace(target.position, space);
          if (isInside) {
            matches.push(target);
          }
        }

        if (matches.length > MIN_MATCHING_TARGETS_PER_TIME_WINDOW) {
          // There is a target in the space, available = FALSE
          dispatch(observerChange([{ areaId: space.id, isAvailable: false }]));
        } else {
          // There is not a target in the space, available = TRUE
          dispatch(observerChange([{ areaId: space.id, isAvailable: true }]));
        }
      }
    }, AVAILABILITY_UPDATE_CADENCE);

    return () => {
      clearInterval(interval);
    };
  };
