import { CoreSpaceFunction } from '@densityco/lib-api-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';

import { NewSpaceFunction } from 'lib/space-functions';
import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { getPlansPatches, getSpacesPatches } from 'lib/patches';
import { patchSpacesState, spacesAdapter } from './spaces-slice';
import {
  patchPlansState,
  planDetailsAdapter,
  scopedPlanDetailsSelectors,
} from '../plans/plans-slice';
import { FixMe } from 'types/fixme';

export const asyncUpdateSpaceThunk = createAsyncThunk<
  // return value
  void,
  // parameters
  {
    planId: string;
    spaceId: string;

    body: {
      function?: CoreSpaceFunction | NewSpaceFunction;
      capacity?: number;
      name?: string;
      status?: 'live' | 'planning';
    };
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'spaces/asyncUpdateSpace',
  async (args, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    await auth.densityAPIClient.patch(`/app/spaces/${args.spaceId}`, args.body);

    const [patches] = getSpacesPatches(thunkApi.getState().spaces, (draft) => {
      spacesAdapter.updateOne(draft.spaces, {
        id: args.spaceId,

        // FIXME: space functions need to be updated
        changes: args.body as FixMe,
      });
    });

    thunkApi.dispatch(patchSpacesState(patches));

    // also need to do area name updates
    const name = args.body.name;
    if (name) {
      const [patches] = getPlansPatches(thunkApi.getState().plans, (draft) => {
        const planDetail = scopedPlanDetailsSelectors.selectById(
          draft,
          args.planId
        );
        if (!planDetail) {
          return;
        }

        planDetailsAdapter.updateOne(draft.planDetails, {
          id: args.planId,
          changes: {
            areas: planDetail.areas.map((a) => {
              if (a.space_id === args.spaceId) {
                return { ...a, name };
              }

              return a;
            }),
          },
        });
      });

      thunkApi.dispatch(patchPlansState(patches));
    }
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
