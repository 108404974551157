import {
  Fragment,
  useImperativeHandle,
  useEffect,
  useRef,
  useState,
  ForwardedRef,
  forwardRef,
} from 'react';
import * as PIXI from 'pixi.js';
import { devicePixelRatio } from 'lib/browser';
import { ViewportCoordinates } from 'lib/geometry';
import { Viewport } from 'lib/viewport';
import { LengthUnit } from 'lib/units';

import { FloorplanBaseImage, FloorplanLayerContextData } from './types';
import { FloorplanLayerContextProvider } from './floorplan-layer-context';
import { toRawHex } from './utils';
import BaseImageLayer from './base-image-layer';

import { Gray000 } from '@density/dust/dist/tokens/dust.tokens';

// Pixi.js shows a console.log message when it starts. Disable this.
PIXI.utils.skipHello();

const DEFAULT_VIEWPORT: Viewport = {
  width: 0,
  height: 0,
  zoom: 1,
  top: 0,
  left: 0,
};

type FloorplanImperativeInterface = {
  getViewport: () => Viewport;
  changeViewport: (newViewport: Viewport) => void;
  zoomToFit: () => void;
  zoomToFitWithSidebar: (sidebarWidthInPixels: number) => void;
  getFloorplanLayerContext: () => FloorplanLayerContextData | null;
  setFloorplanLayerContext: (
    floorplanLayerContext: FloorplanLayerContextData
  ) => void;
};

type FloorplanProps = {
  image: HTMLImageElement;
  floorplan: FloorplanBaseImage;
  grayscaleFloorplanImage?: boolean;
  width: React.ReactText;
  height: React.ReactText;
  onClickBackground?: (evt: any) => void;
  lengthUnit: LengthUnit;
  backgroundColor?: string;
  showBaseFloorplan?: boolean;
  children: React.ReactNode;
};

const Floorplan = forwardRef(
  (props: FloorplanProps, ref: ForwardedRef<FloorplanImperativeInterface>) => {
    const {
      image,
      width,
      height,
      floorplan,
      grayscaleFloorplanImage = true,
      lengthUnit,
      onClickBackground,
      backgroundColor = Gray000,
      showBaseFloorplan = true,
      children,
    } = props;
    const pixiContainerRef = useRef<HTMLDivElement | null>(null);

    const viewportRef = useRef<Viewport>(DEFAULT_VIEWPORT);
    // (window as any).viewportRef = viewportRef

    const [floorplanLayerContext, setFloorplanLayerContext] =
      useState<FloorplanLayerContextData | null>(null);

    // Add imperative ways to effect viewport and general state that this component owns
    useImperativeHandle(
      ref,
      () => ({
        getViewport(): Viewport {
          return viewportRef.current;
        },
        changeViewport(newViewport: Viewport) {
          viewportRef.current = newViewport;
        },

        zoomToFit() {
          viewportRef.current = Viewport.zoomToFit(
            viewportRef.current,
            floorplan
          );
        },
        zoomToFitWithSidebar(sidebarWidthInPixels: number) {
          viewportRef.current = Viewport.zoomToFitWithSidebar(
            viewportRef.current,
            floorplan,
            sidebarWidthInPixels
          );
        },

        // fitViewportToPoints(
        //   points: Array<FloorplanCoordinates>,
        //   edgeSpacingPx: number = 32,
        //   minimumZoom: number = 0.25,
        // ) {
        //   const minExtent = FloorplanCoordinates.create(
        //     Math.min(points.map(p => p.x)),
        //     Math.min(points.map(p => p.y)),
        //   );
        //   const maxExtent = FloorplanCoordinates.create(
        //     Math.max(points.map(p => p.x)),
        //     Math.max(points.map(p => p.y)),
        //   );
        //   const widthInMeters = maxExtent.x - minExtent.x;
        //   const heightInMeters = maxExtent.y - minExtent.y;
        //
        //   const newZoom = widthInMeters / (floorplan.width / zoom);
        //
        //   widthInMeters * zoom = widthInPixels
        //
        //   viewportRef.current = Viewport.zoomToFit(
        //     viewportRef.current,
        //     floorplan,
        //   );
        // },

        getFloorplanLayerContext() {
          return floorplanLayerContext;
        },

        setFloorplanLayerContext(
          newLayerContextData: FloorplanLayerContextData
        ) {
          setFloorplanLayerContext(newLayerContextData);
        },
      }),
      [floorplan, floorplanLayerContext]
    );

    // When the component mounts, set up pixi
    const webglContextCounter =
      floorplanLayerContext && floorplanLayerContext.webglContextCounter;
    useEffect(() => {
      const pixiContainer = pixiContainerRef.current;
      if (!pixiContainer) {
        throw new Error('Could not get pixi container element from ref');
      }

      const containerBBox = pixiContainer.getBoundingClientRect();

      const app = new PIXI.Application({
        width: containerBBox.width,
        height: containerBBox.height,
        backgroundColor: toRawHex(backgroundColor),
        resolution: devicePixelRatio,
        antialias: true,
      });

      app.view.style.width = `${containerBBox.width}px`;
      app.view.style.height = `${containerBBox.height}px`;

      // Once we know the viewport width + height, then set an initial sane viewport
      const isDefaultViewport =
        viewportRef.current.width === DEFAULT_VIEWPORT.width &&
        viewportRef.current.height === DEFAULT_VIEWPORT.height;
      viewportRef.current.width = containerBBox.width;
      viewportRef.current.height = containerBBox.height;
      if (isDefaultViewport) {
        viewportRef.current = Viewport.zoomToFit(
          viewportRef.current,
          floorplan
        );
      }

      app.view.setAttribute('data-cy', 'floorplan-canvas');

      pixiContainer.appendChild(app.view);

      // If the web gl context is lost, restart everything to regenerate the webgl context
      const onWebglContextLost = () => {
        setFloorplanLayerContext((floorplanLayerContext) => {
          if (floorplanLayerContext) {
            return {
              ...floorplanLayerContext,
              webglContextCounter:
                floorplanLayerContext.webglContextCounter + 1,
            };
          } else {
            return floorplanLayerContext;
          }
        });
      };
      app.view.addEventListener('webglcontextlost', onWebglContextLost);

      // If width or height are non numeric, then they are a percent / etc
      // And if that's the case, listen for viewport resize changes and ensure that the viewport
      // reflects its proper size
      const onResize = () => {
        const containerBBox = pixiContainer.getBoundingClientRect();

        app.renderer.resize(containerBBox.width, containerBBox.height);
        app.view.style.width = `${containerBBox.width}px`;
        app.view.style.height = `${containerBBox.height}px`;
        viewportRef.current.width = containerBBox.width;
        viewportRef.current.height = containerBBox.height;
      };
      const listenForResize =
        typeof width !== 'number' || typeof height !== 'number';
      if (listenForResize) {
        window.addEventListener('resize', onResize);
      }

      // When the user scrolls their mosue wheel, change the position of the canvas, or zoom in and
      // out if the ctrl / cmd key is held.
      const onWheel = (event: WheelEvent) => {
        event.preventDefault();

        const dx = event.deltaX;
        const dy = event.deltaY;
        const { ctrlKey, metaKey } = event;

        // ctrlKey hack for zoom
        if (ctrlKey || metaKey) {
          const bbox = app.view.getBoundingClientRect();
          const position = ViewportCoordinates.create(
            event.clientX - bbox.left,
            event.clientY - bbox.top
          );

          // limit scroll wheel sensitivity for mouse users
          const limit = 8;
          const scrollDelta = Math.max(-limit, Math.min(limit, dy));

          const nextZoomFactor =
            viewportRef.current.zoom +
            viewportRef.current.zoom * scrollDelta * -0.01;

          const targetX =
            viewportRef.current.left + position.x / viewportRef.current.zoom;
          const targetY =
            viewportRef.current.top + position.y / viewportRef.current.zoom;

          const top = targetY - position.y / nextZoomFactor;
          const left = targetX - position.x / nextZoomFactor;

          viewportRef.current = {
            ...viewportRef.current,
            zoom: nextZoomFactor,
            top,
            left,
          };
        } else {
          // otherwise pan
          viewportRef.current = {
            ...viewportRef.current,
            top: viewportRef.current.top + dy / viewportRef.current.zoom,
            left: viewportRef.current.left + dx / viewportRef.current.zoom,
          };
        }
      };
      app.view.addEventListener('wheel', onWheel);

      // When the user presses space, go into a mode where clicking and dragging the mouse pans the
      // canvas.
      let previousMousePosition: ViewportCoordinates | null = null;
      let viewBoundingBox: DOMRect | null;
      let backdropSprite: PIXI.Sprite | null = null;

      // When space is pressed, enable this mode
      const onKeyDown = (event: KeyboardEvent) => {
        if (event.key !== ' ') {
          return;
        }
        if (event.repeat) {
          return;
        }

        // Don't go into this space-to-drag mode if the user has something else focused
        if (document.activeElement !== document.body) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();

        // A transparent backdrop is rendered overtop of the whole stage
        // to ensure that we have control over all pixi canvas mouse events
        if (backdropSprite) {
          app.stage.removeChild(backdropSprite);
        }
        backdropSprite = new PIXI.Sprite(PIXI.Texture.WHITE);
        backdropSprite.name = 'space-and-drag-backdrop';
        backdropSprite.width = viewportRef.current.width;
        backdropSprite.height = viewportRef.current.height;
        backdropSprite.alpha = 0;
        backdropSprite.interactive = true;
        backdropSprite.cursor = 'grab';
        backdropSprite.on('mousedown', (event) => {
          if (!backdropSprite) {
            return;
          }
          backdropSprite.cursor = 'grabbing';

          viewBoundingBox = app.view.getBoundingClientRect();

          const originalEvent = event.data.originalEvent;
          previousMousePosition = ViewportCoordinates.create(
            originalEvent.clientX - viewBoundingBox.left,
            originalEvent.clientY - viewBoundingBox.top
          );
        });
        backdropSprite.on('mousemove', (event) => {
          const originalEvent = event.data.originalEvent;
          if (originalEvent.buttons !== 1) {
            return;
          }

          if (!viewBoundingBox) {
            return;
          }
          const currentMousePosition = ViewportCoordinates.create(
            originalEvent.clientX - viewBoundingBox.left,
            originalEvent.clientY - viewBoundingBox.top
          );

          if (!previousMousePosition) {
            return;
          }
          const dy = previousMousePosition.y - currentMousePosition.y;
          const dx = previousMousePosition.x - currentMousePosition.x;

          viewportRef.current = {
            ...viewportRef.current,
            top: viewportRef.current.top + dy / viewportRef.current.zoom,
            left: viewportRef.current.left + dx / viewportRef.current.zoom,
          };

          previousMousePosition = currentMousePosition;
        });
        backdropSprite.on('mouseup', (event) => {
          if (!backdropSprite) {
            return;
          }
          backdropSprite.cursor = 'grab';
          viewBoundingBox = null;
          previousMousePosition = null;
        });
        app.stage.addChild(backdropSprite);
      };

      // When space is released, disable this mode
      const onKeyUp = (event: KeyboardEvent) => {
        if (event.key !== ' ') {
          return;
        }
        if (!backdropSprite) {
          return;
        }
        app.stage.removeChild(backdropSprite);
      };

      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('keyup', onKeyUp);

      setFloorplanLayerContext({
        app,
        animationFrameHandlers: [],
        viewport: viewportRef,
        floorplan,
        lengthUnit,
        webglContextCounter: 1,
      });

      return () => {
        if (listenForResize) {
          window.removeEventListener('resize', onResize);
        }
        app.view.removeEventListener('wheel', onWheel);
        app.view.removeEventListener('webglcontextlost', onWebglContextLost);

        window.removeEventListener('keydown', onKeyDown);
        window.removeEventListener('keyup', onKeyUp);

        // Delete all children from inside the pixi container before react unmounts the parent div
        // Otherwise react throws an error
        while (pixiContainer.lastChild) {
          pixiContainer.removeChild(pixiContainer.lastChild);
        }

        // Delay rully cleaning up the app so that all child layers can finish cleaning themselves
        // up
        // FIXME: there's gotta be a more elegant way to do this
        setTimeout(() => {
          app.destroy(false, true);
        }, 1000);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundColor, webglContextCounter]);

    // Add clickable background handler
    useEffect(() => {
      if (!floorplanLayerContext) {
        return;
      }

      if (onClickBackground) {
        if (!pixiContainerRef.current) {
          return;
        }
        const containerBBox = pixiContainerRef.current.getBoundingClientRect();

        // ref: https://stackoverflow.com/questions/62835213/pixi-js-need-to-make-clickable-background
        const bg = new PIXI.Sprite(PIXI.Texture.WHITE);
        bg.alpha = 0;
        bg.width = containerBBox.width;
        bg.height = containerBBox.height;
        bg.interactive = true;
        bg.on('mousedown', onClickBackground);
        floorplanLayerContext.app.stage.addChildAt(bg, 0);
        return () => {
          floorplanLayerContext.app.stage.removeChild(bg);
        };
      }
    }, [floorplanLayerContext, onClickBackground]);

    // Propegate width and height changes to the underlying pixi.Application
    useEffect(() => {
      if (!floorplanLayerContext) {
        return;
      }
      if (!pixiContainerRef.current) {
        return;
      }

      const containerBBox = pixiContainerRef.current.getBoundingClientRect();

      floorplanLayerContext.app.renderer.resize(
        containerBBox.width,
        containerBBox.height
      );
      floorplanLayerContext.app.view.style.width = `${containerBBox.width}px`;
      floorplanLayerContext.app.view.style.height = `${containerBBox.height}px`;

      viewportRef.current.width = containerBBox.width;
      viewportRef.current.height = containerBBox.height;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height]);

    // Run animation frame for all layers
    useEffect(() => {
      if (!floorplanLayerContext) {
        return;
      }

      function frame(timeDelta: number) {
        if (!floorplanLayerContext) {
          return null;
        }

        floorplanLayerContext.animationFrameHandlers.forEach(
          (animationFrameHandler, index) => {
            animationFrameHandler(floorplanLayerContext, timeDelta, index);
          }
        );
      }

      floorplanLayerContext.app.ticker.add(frame);

      return () => {
        if (!floorplanLayerContext) {
          return;
        }
        floorplanLayerContext.app.ticker.remove(frame);
      };
    }, [floorplanLayerContext]);

    // Keep context.floorplan in sync with floorplan prop
    useEffect(() => {
      setFloorplanLayerContext((floorplanLayerContext) => {
        if (
          floorplanLayerContext &&
          floorplanLayerContext.floorplan !== floorplan
        ) {
          return { ...floorplanLayerContext, floorplan };
        } else {
          return floorplanLayerContext;
        }
      });
    }, [floorplan]);

    // Keep context.lengthUnit in sync with lengthUnit prop
    useEffect(() => {
      setFloorplanLayerContext((floorplanLayerContext) => {
        if (
          floorplanLayerContext &&
          floorplanLayerContext.lengthUnit !== lengthUnit
        ) {
          return { ...floorplanLayerContext, lengthUnit };
        } else {
          return floorplanLayerContext;
        }
      });
    }, [lengthUnit]);

    return (
      <FloorplanLayerContextProvider value={floorplanLayerContext}>
        {/* Render the base canvas */}
        <div
          ref={pixiContainerRef}
          style={{ width, height, overflow: 'hidden' }}
          // Disable context menu so that pixi.js can tap into right click events
          onContextMenu={(e) => e.preventDefault()}
        />

        {floorplanLayerContext ? (
          <Fragment>
            {/* Underlying floorplan image layer */}
            <BaseImageLayer
              image={image}
              grayscale={grayscaleFloorplanImage}
              visible={showBaseFloorplan}
            />

            {/* All other upper layers */}
            {children}
          </Fragment>
        ) : null}
      </FloorplanLayerContextProvider>
    );
  }
);

export default Floorplan;
