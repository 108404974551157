import { createSelector } from '@reduxjs/toolkit';
import { isFloor } from 'lib/floors';
import { RootState } from 'redux/store';
import { spacesSelectors } from './spaces-slice';

export const selectFloor = createSelector(
  [
    (state: RootState, params: { floorId: string }) =>
      spacesSelectors.selectById(state, params.floorId),
  ],
  (space) => {
    if (!space) {
      return;
    }

    if (isFloor(space)) {
      return space;
    }
  }
);
