import { CoreSpace, CoreSpaceType } from '@densityco/lib-api-types';
import { descending } from 'd3-array';

export interface Building extends CoreSpace {
  space_type: CoreSpaceType.BUILDING;
}

export function isBuilding(space: CoreSpace): space is Building {
  return space.space_type === CoreSpaceType.BUILDING;
}

export function autoSelectBuilding(buildings: ReadonlyArray<Building>) {
  if (buildings.length === 0) return null;
  const sorted = buildings
    .slice()
    .sort((a, b) => descending(a.updated_at, b.updated_at));
  return sorted[0];
}
