import { Icons } from '@densityco/ui';
import { CoreSpaceFunction } from '@densityco/lib-api-types';

// New space functions
export enum NewSpaceFunction {
  BREAK_ROOM = 'break_room',
  CAFE = 'cafe',
  DESK = 'desk',
  DESK_NEIGHBORHOOD = 'desk_neighborhood',
  ENCLOSED_WORKSPACE = 'enclosed_workspace',
  EVENT_SPACE = 'event_space',
  GAMES_ROOM = 'games_room',
  GYM = 'gym',
  KITCHEN = 'kitchen',
  LAB = 'lab',
  LIBRARY = 'library',
  LOUNGE = 'lounge',
  MEETING_ROOM = 'meeting_room',
  OPEN_COLLABORATION_SPACE = 'open_collaboration_space',
  OPEN_WORKSPACE = 'open_workspace',
  OTHER = 'other',
  OUTDOOR = 'outdoor',
  PHONE_BOOTH = 'phone_booth',
  RECEPTION = 'reception',
  RESTROOM = 'restroom',
  RETAIL = 'retail',
  SERVERY = 'servery',
  THEATER = 'theater',
  UTILITY_ROOM = 'utility_room',
  WELLNESS_ROOM = 'wellness_room',
}

export const SPACE_FUNCTION_DISPLAY_NAMES: Record<NewSpaceFunction, string> = {
  break_room: 'Break Room',
  cafe: 'Cafe',
  desk: 'Desk',
  desk_neighborhood: 'Desk Neighborhood',
  enclosed_workspace: 'Enclosed Workspace',
  event_space: 'Event Space',
  games_room: 'Games Room',
  gym: 'Gym',
  kitchen: 'Kitchen',
  lab: 'Lab',
  library: 'Library',
  lounge: 'Lounge',
  meeting_room: 'Meeting Room',
  open_collaboration_space: 'Open Collaboration Space',
  open_workspace: 'Open Workspace',
  other: 'Other',
  outdoor: 'Outdoor',
  phone_booth: 'Phone Booth',
  reception: 'Reception',
  restroom: 'Restroom',
  retail: 'Retail',
  servery: 'Servery',
  theater: 'Theater',
  utility_room: 'Utility Room',
  wellness_room: 'Wellness Room',
};

export const SPACE_FUNCTION_ICONS: Record<
  NewSpaceFunction,
  typeof Icons.BreakRoom
> = {
  break_room: Icons.BreakRoom,
  cafe: Icons.Cafe,
  desk: Icons.Desk,
  desk_neighborhood: Icons.Desk, // Temporary icon, change this once there's an official desk_neighborhood icon
  enclosed_workspace: Icons.EnclosedWorkspace,
  event_space: Icons.EventSpace,
  games_room: Icons.GamesRoom,
  gym: Icons.Gym,
  kitchen: Icons.Kitchen,
  lab: Icons.Lab,
  library: Icons.Library,
  lounge: Icons.Lounge,
  meeting_room: Icons.MeetingRoom,
  open_collaboration_space: Icons.OpenCollaborationSpace,
  open_workspace: Icons.OpenWorkspace,
  other: Icons.Other,
  outdoor: Icons.Outdoor,
  phone_booth: Icons.PhoneBooth,
  reception: Icons.Reception,
  restroom: Icons.Restroom,
  retail: Icons.Retail,
  servery: Icons.Servery,
  theater: Icons.Theater,
  utility_room: Icons.UtilityRoom,
  wellness_room: Icons.WellnessRoom,
};

// TODO(guscost): remove conversion once space function migration is complete
export function spaceFunctionToNewSpaceFunction(
  spaceFunction: CoreSpaceFunction | NewSpaceFunction
): NewSpaceFunction {
  switch (spaceFunction) {
    case CoreSpaceFunction.BREAK_ROOM:
      return NewSpaceFunction.BREAK_ROOM;
    case CoreSpaceFunction.CAFE:
      return NewSpaceFunction.CAFE;
    case CoreSpaceFunction.COLLABORATION:
      return NewSpaceFunction.MEETING_ROOM;
    case CoreSpaceFunction.CONFERENCE_ROOM:
      return NewSpaceFunction.MEETING_ROOM;
    case CoreSpaceFunction.DINING_AREA:
      return NewSpaceFunction.CAFE;
    case CoreSpaceFunction.EVENT_SPACE:
      return NewSpaceFunction.EVENT_SPACE;
    case CoreSpaceFunction.FOCUS_QUIET:
      return NewSpaceFunction.ENCLOSED_WORKSPACE;
    case CoreSpaceFunction.GYM:
      return NewSpaceFunction.GYM;
    case CoreSpaceFunction.KITCHEN:
      return NewSpaceFunction.KITCHEN;
    case CoreSpaceFunction.LAB:
      return NewSpaceFunction.LAB;
    case CoreSpaceFunction.LIBRARY:
      return NewSpaceFunction.LIBRARY;
    case CoreSpaceFunction.LOUNGE:
      return NewSpaceFunction.LOUNGE;
    case CoreSpaceFunction.MEETING_ROOM:
      return NewSpaceFunction.MEETING_ROOM;
    case CoreSpaceFunction.OFFICE:
      return NewSpaceFunction.ENCLOSED_WORKSPACE;
    case CoreSpaceFunction.PHONE_BOOTH:
      return NewSpaceFunction.PHONE_BOOTH;
    case CoreSpaceFunction.PLACE_OF_WORSHIP:
      return NewSpaceFunction.OTHER;
    case CoreSpaceFunction.RECEPTION:
      return NewSpaceFunction.RECEPTION;
    case CoreSpaceFunction.RESTROOM:
      return NewSpaceFunction.RESTROOM;
    case CoreSpaceFunction.RETAIL:
      return NewSpaceFunction.RETAIL;
    case CoreSpaceFunction.THEATER:
      return NewSpaceFunction.THEATER;
    case CoreSpaceFunction.WELLNESS_ROOM:
      return NewSpaceFunction.WELLNESS_ROOM;
    case CoreSpaceFunction.WORKSTATIONS:
      return NewSpaceFunction.DESK_NEIGHBORHOOD;
    default:
      return spaceFunction;
  }
}
