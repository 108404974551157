import { createSelector } from '@reduxjs/toolkit';

import { aggregateData } from 'lib/charts';
import { RootState } from 'redux/store';
import { selectSpace } from '../spaces/select-space';
import { selectTimeZone } from '../spaces/select-timezone';

export const selectUtilizationChartDataByHourForSpace = createSelector(
  [
    (state: RootState) => state.analysis.utilizationAnalyticsByHour,

    selectTimeZone,
    selectSpace,

    // parameters
    (_: RootState, params: { spaceId?: string }) => params.spaceId,
  ],
  (utilizationAnalyticsByHour, timeZone, space, spaceId) => {
    if (!spaceId || !space) {
      return;
    }

    const capacity = space.capacity;
    if (!capacity) {
      return;
    }

    const data = utilizationAnalyticsByHour.entities[spaceId]?.data;
    if (!data?.length) {
      return;
    }

    const aggregation = aggregateData(data, timeZone, 'max', 'hour');

    return aggregation.map((datum) => ({
      ...datum,
      value: datum.value / capacity,
    }));
  }
);
