import * as dust from '@density/dust/dist/tokens/dust.tokens';
import React from 'react';

import { TIMELINE_OFFSET, useHeatmap } from './heatmap-context';

const HeatmapTimeline: React.FC = () => {
  const {
    timelinePoints,
    frameStep,
    frameStartX,
    windowSize,
    stepSize,
    startMoment,
    onTimelineHover,
    onTimelineClick,
  } = useHeatmap();

  const startTime = startMoment.format('h:mm');
  const endTime = startMoment.clone().add(windowSize).format('h:mm A');

  return (
    <div
      style={{
        position: 'absolute',
        bottom: TIMELINE_OFFSET,
        left: TIMELINE_OFFSET,
        right: TIMELINE_OFFSET,
        width: 'calc(100% - 8px)',
        height: 80,
      }}
    >
      <svg
        style={{
          position: 'absolute',
          width: '100%',
          height: 32,
          top: 48,
        }}
      >
        {timelinePoints.map((point, index) => {
          const x = index * frameStep;

          // TODO(wuweiweiwu): need to revisit the window logic
          // for when there is a lot of data points, there is a noticeable offset
          // between the expoanded tile and where the mouse is
          const isInWindow =
            x >= frameStartX &&
            x < Math.floor(frameStartX + (frameStep * windowSize) / stepSize);

          return (
            <rect
              key={index}
              x={x}
              y={isInWindow ? 0 : 8}
              height={isInWindow ? 32 : 24}
              width={frameStep - 2}
              fill={dust.Blue700}
              fillOpacity={point}
              rx={2}
            />
          );
        })}
      </svg>
      <div
        onMouseMove={(e) => {
          onTimelineHover(e.clientX);
        }}
        onMouseLeave={(e) => {
          onTimelineHover(null);
        }}
        onClick={(e) => {
          e.stopPropagation();

          onTimelineClick(e.clientX);
        }}
        style={{
          cursor: 'pointer',
          position: 'relative',
          width: '100%',
          height: 80,
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: isNaN(frameStartX) ? 0 : frameStartX,
            top: 16,
            width: 300,
            fontSize: 12,
            userSelect: 'none',
          }}
        >
          {startMoment.format('MMM D, YYYY')}

          <br />

          <strong>
            {startTime} - {endTime}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HeatmapTimeline);
