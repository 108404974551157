import { useEffect } from 'react';
import * as React from 'react';

import { useAppSelector } from 'redux/store';

const SensorDataStream: React.FunctionComponent<{
  sensorSerial: string;
  onOpen: () => void;
  onClose: () => void;
  onError: () => void;
  onFrame: (buffer: ArrayBuffer) => void;
}> = ({ sensorSerial, onOpen, onClose, onError, onFrame }) => {
  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  useEffect(() => {
    if (!densityAPIClient) {
      return;
    }

    let socket: WebSocket | null = null;

    // request socket server auth code, use to create a connection
    densityAPIClient
      .post(`/v2/oa/auth/${sensorSerial}`)
      .then(
        (response) => {
          socket = new WebSocket(response.data['url']);
          socket.binaryType = 'arraybuffer';

          socket.addEventListener('open', () => {
            onOpen();
          });

          socket.addEventListener('message', (evt) => {
            const payload: unknown = evt.data;

            if (!(payload instanceof ArrayBuffer)) {
              throw new Error(
                'Socket message data must be of type ArrayBuffer'
              );
            }

            onFrame(payload);
          });

          socket.addEventListener('close', () => {
            onClose();
          });

          socket.addEventListener('error', (evt) => {
            onError();
          });
        },
        () => {
          onError();
        }
      )
      .catch((err) => {
        console.error(err);
        onError();
      });

    // Make sure the socket gets closed before unloading this component
    return () => {
      socket && socket.close();
    };
  }, [densityAPIClient, onClose, onError, onFrame, onOpen, sensorSerial]);

  return null;
};

export default SensorDataStream;
