import * as React from 'react';
import { Icons } from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';

import { Action } from './actions';
import { Space } from './state';
import styles from './styles.module.scss';

import {
  displayLength,
  LengthUnit,
  displayCircularArea,
  displayBoxArea,
  displayPolygonalArea,
} from 'lib/units';
import Button from 'components/button';
import TextField from 'components/text-field';
import HorizontalForm from 'components/horizontal-form';
import Panel, { PanelHeader, PanelBody, PanelActions } from 'components/panel';

const FocusedSpacePanel: React.FunctionComponent<{
  space: Space;
  displayUnit: LengthUnit;
  dispatch: React.Dispatch<Action>;
}> = ({ space, displayUnit, dispatch }) => {
  let circleDiameter: string | null = null;
  let boxWidth: string | null = null;
  let boxHeight: string | null = null;
  let coverageArea: string | null = null;

  if (space.shape.type === 'circle') {
    circleDiameter = displayLength(space.shape.radius * 2, displayUnit);
    coverageArea = displayCircularArea(space.shape.radius, displayUnit);
  }

  if (space.shape.type === 'box') {
    boxWidth = displayLength(space.shape.width, displayUnit);
    boxHeight = displayLength(space.shape.height, displayUnit);
    coverageArea = displayBoxArea(
      space.shape.width,
      space.shape.height,
      displayUnit
    );
  }

  if (space.shape.type === 'polygon') {
    coverageArea = displayPolygonalArea(space.shape.vertices, displayUnit);
  }

  return (
    <div data-cy="focused-space-panel">
      <Panel position="top-left">
        <PanelHeader>
          <TextField
            type="text"
            size="medium"
            value={space.name}
            placeholder={space.name}
            disabled={space.locked}
            onChange={(evt) => {
              const name = evt.currentTarget.value;
              dispatch({ type: 'space.changeName', id: space.id, name });
            }}
            leadingIcon={
              <Icons.Space height={18} width={18} color={colors.blue} />
            }
            width="100%"
          />
        </PanelHeader>

        <PanelBody>
          <div className={styles.spaceConfigMeta}>
            {space.shape.type === 'circle' ? (
              <div className={styles.sensorConfigMetaRow}>
                <span>Diameter:&nbsp;</span>
                <span className={styles.muted}>{circleDiameter}</span>
              </div>
            ) : null}
            {space.shape.type === 'box' ? (
              <div className={styles.sensorConfigMetaRow}>
                <span>Width x Height:&nbsp;</span>
                <span className={styles.muted}>
                  {boxWidth} x {boxHeight}
                </span>
              </div>
            ) : null}
            {space.shape.type === 'polygon' ? (
              <div className={styles.sensorConfigMetaRow}>
                <span>Sides:&nbsp;</span>
                <span className={styles.muted}>
                  {space.shape.vertices.length}
                </span>
              </div>
            ) : null}
            <div className={styles.sensorConfigMetaRow}>
              <span>Area:&nbsp;</span>
              <span className={styles.muted}>{coverageArea}</span>
            </div>
          </div>
          {/* .sensorConfigMeta */}
        </PanelBody>

        <PanelActions
          left={
            <HorizontalForm size="medium">
              <Button
                type="cleared"
                size="medium"
                trailingIcon={
                  <Icons.Trash width={18} height={18} color="currentColor" />
                }
                danger
                disabled={space.locked}
                onClick={() => dispatch({ type: 'space.remove', id: space.id })}
                data-cy="space-delete-button"
              />
              <Button
                type="cleared"
                size="medium"
                data-cy={
                  space.locked ? 'space-unlock-button' : 'space-lock-button'
                }
                trailingIcon={
                  space.locked ? (
                    <Icons.LockClosed
                      width={18}
                      height={18}
                      color={colors.yellow}
                    />
                  ) : (
                    <Icons.LockOpen
                      width={18}
                      height={18}
                      color="currentColor"
                    />
                  )
                }
                onClick={() => {
                  const lockToggleAction = space.locked
                    ? 'space.unlock'
                    : 'space.lock';
                  dispatch({ type: lockToggleAction, id: space.id });
                }}
              />
              <Button
                type="cleared"
                size="medium"
                data-cy="space-duplicate-button"
                trailingIcon={
                  <Icons.CopyAlt width={18} height={18} color="currentColor" />
                }
                onClick={() =>
                  dispatch({ type: 'menu.duplicateSpace', id: space.id })
                }
              />
            </HorizontalForm>
          }
          right={
            <Button
              type="cleared"
              size="medium"
              onClick={() => dispatch({ type: 'space.dismiss', id: space.id })}
              data-cy="space-dismiss"
            >
              Done
            </Button>
          }
        />
      </Panel>
    </div>
  );
};

export default FocusedSpacePanel;
