import { createSelector } from '@reduxjs/toolkit';

import { aggregateData } from 'lib/charts';
import { RootState } from 'redux/store';
import { selectTimeZone } from '../spaces/select-timezone';

export const selectMaxDwellForSpace = createSelector(
  [
    (state: RootState) => state.analysis.dwellAnalytics,

    selectTimeZone,

    // parameters
    (_: RootState, params: { spaceId?: string }) => params.spaceId,
  ],
  (dwellAnalytics, timeZone, spaceId) => {
    if (!spaceId) {
      return;
    }

    const data = dwellAnalytics.entities[spaceId]?.data;
    if (!data?.length) {
      return;
    }

    const aggregation = aggregateData(data, timeZone, 'max', 'day');

    const max = Math.max(...aggregation.map((d) => d.value));

    return max;
  }
);
