import { useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { Checkbox } from '@densityco/ui';
import { Icons } from '@densityco/ui';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';

import Button from 'components/button';
import Tooltip from 'components/tooltip';

const SimulationPanel: React.FunctionComponent<{
  simulation: State['simulation'];
  dispatch: React.Dispatch<Action>;
}> = ({ simulation, dispatch }) => {
  const [panelOpen, setPanelOpen] = useState<boolean>(false);

  return (
    <div className={classNames([styles.simulationPanel])}>
      <Tooltip
        contents="Add simulants"
        placement="bottom"
        target={
          <Button
            onClick={() => setPanelOpen(!panelOpen)}
            trailingIcon={
              <Icons.PersonPlus width={18} height={18} color="currentColor" />
            }
            size="medium"
            type="outlined"
          />
        }
      />
      <div
        className={classNames(styles.simulationMenu, {
          [styles.open]: panelOpen,
        })}
      >
        <div className={styles.simulationControlsList}>
          <div
            className={classNames([
              styles.simulationMenuItem,
              styles.simulationControlsItem,
            ])}
          >
            <Checkbox
              label={'Show Outlines'}
              checked={simulation.simBoundariesShown}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                if (evt.target.checked) {
                  dispatch({ type: 'simulation.menu.showSimBoundaries' });
                } else {
                  dispatch({ type: 'simulation.menu.hideSimBoundaries' });
                }
              }}
            />
            <button
              className={classNames([
                styles.buttonAddSims,
                styles.buttonSmall,
                styles.primary,
              ])}
              onClick={() => {
                dispatch({ type: 'simulation.menu.addSimulant' });
              }}
            >
              Add
            </button>
          </div>
          <div>
            <Checkbox
              label={'Enable Simulants'}
              checked={simulation.enabled}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                if (evt.target.checked) {
                  dispatch({ type: 'simulation.menu.enableSimulation' });
                } else {
                  dispatch({ type: 'simulation.menu.disableSimulation' });
                }
              }}
            />
          </div>
        </div>
        {/* .simulationControlsList */}

        <div className={styles.simulationMenuItem}>
          <div className={styles.title}>Toggle Simulation</div>
          <div className={styles.shortcut}>s s</div>
        </div>
        <div className={styles.simulationMenuItem}>
          <div className={styles.title}>Toggle Simulant Outlines</div>
          <div className={styles.shortcut}>s o</div>
        </div>
        <div className={styles.simulationMenuItem}>
          <div className={styles.title}>Add Simulant</div>
          <div className={styles.shortcut}>n s</div>
        </div>
        <div className={styles.simulationMenuItem}>
          <div className={styles.title}>Remove Simulant</div>
          <div className={styles.shortcut}>right click</div>
        </div>
        <div className={styles.simulationMenuItem}>
          <div className={styles.title}>Move Simulant</div>
          <div className={styles.shortcut}>drag</div>
        </div>
      </div>
    </div>
    // .simulationPanel
  );
};

export default SimulationPanel;
