import { useCallback } from 'react';
import * as React from 'react';

import { Action } from './actions';
import { Sensor, SensorConnection } from './state';

import SensorDataStream from 'components/sensor-data-stream';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import SensorDataStreamOALive from 'components/sensor-data-stream-oa-live';

const SensorDataEmitter: React.FunctionComponent<{
  planId: string;
  sensor: Sensor;
  sensorConnection: SensorConnection | null;
  dispatch: React.Dispatch<Action>;
}> = ({ planId, sensor, sensorConnection, dispatch }) => {
  const isOALiveConnectionOn = useTreatment(SPLITS.OA_LIVE_CONNECTION);

  const onConnectionOpen = useCallback(() => {
    dispatch({ type: 'sensor.socket.open', id: sensor.id });
  }, [sensor.id, dispatch]);
  const onConnectionClose = useCallback(() => {
    dispatch({ type: 'sensor.socket.close', id: sensor.id });
  }, [sensor.id, dispatch]);
  const onConnectionError = useCallback(() => {
    dispatch({ type: 'sensor.socket.error', id: sensor.id });
  }, [sensor.id, dispatch]);
  const onFrame = useCallback(
    (buffer: ArrayBuffer) => {
      dispatch({ type: 'sensor.socket.frame', id: sensor.id, buffer });
    },
    [sensor.id, dispatch]
  );
  const onMessage = useCallback(
    (message: string) => {
      dispatch({ type: 'sensor.socket.message', id: sensor.id, message });
    },
    [sensor.id, dispatch]
  );

  if (!sensorConnection) return null;
  if (sensorConnection.status === 'disconnected') return null;

  if (isOALiveConnectionOn) {
    return (
      <SensorDataStreamOALive
        key={sensor.id}
        planId={planId}
        sensorSerial={sensorConnection.serialNumber}
        onOpen={onConnectionOpen}
        onClose={onConnectionClose}
        onError={onConnectionError}
        onMessage={onMessage}
      />
    );
  }

  // SensorDataStream is currently only configured to work with cloud connections
  // if in the future local connections are needed, SensorDataStream's interface will need
  // to accommodate direct IP address parameter pass-through
  return (
    <SensorDataStream
      key={sensor.id}
      sensorSerial={sensorConnection.serialNumber}
      onOpen={onConnectionOpen}
      onClose={onConnectionClose}
      onError={onConnectionError}
      onFrame={onFrame}
    />
  );
};

export default SensorDataEmitter;
