import { DayOfWeek } from '@densityco/lib-common-types';
import { CompatibleDateValue } from '@densityco/ui/dist/cjs/src/date-picker';
import {
  createAction,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { stringToTimeFilter } from '@densityco/lib-time-helpers';

import { asyncFetchDistributionAnalyticsThunk } from './async-fetch-distribution-analytics-thunk';
import { asyncFetchUtilizationAnalyticsByDayThunk } from './async-fetch-utilization-analytics-by-day-thunk';
import { asyncFetchAnalyticsThunk } from './async-fetch-analytics-thunk';
import {
  asyncFetchHeatmapThunk,
  asyncProcessHeatmapDataThunk,
} from './async-fetch-and-process-heatmap-thunk';
import { asyncFetchUtilizationAnalyticsByHourThunk } from './async-fetch-utilization-analytics-by-hour-thunk';

import { CompatibleDateRange, TimeOfDay } from 'lib/date-time';
import {
  HeatmapFrame,
  HeatmapPreprocessedFrame,
} from 'lib/process-heatmap-data';
import {
  getInitialAsyncTaskState,
  AsyncTaskState,
  withPayloadType,
  getPendingState,
  getFulfilledState,
  getRejectedState,
  hasValidPendingState,
} from 'lib/redux';
import { UtilizationAnalyticsData } from 'lib/utilization';
import { NewSpaceFunction } from 'lib/space-functions';
import { DistributionAnalyticsData } from 'lib/distribution';
import { RootState } from 'redux/store';
import { DwellAnalyticsData } from 'lib/dwell';
import { asyncFetchDwellAnalyticsThunk } from './async-fetch-dwell-analytics-thunk';
import { TimeUsedAnalyticsData } from 'lib/time-used';
import { asyncFetchTimeUsedAnalyticsThunk } from './async-fetch-time-used-analytics-thunk';

export const distributionAnalyticsAdapter = createEntityAdapter<{
  spaceId: string;
  data: DistributionAnalyticsData;
}>({
  selectId: (data) => data.spaceId,
});

export const timeUsedAnalyticsAdapter = createEntityAdapter<{
  spaceId: string;
  data: TimeUsedAnalyticsData;
}>({
  selectId: (data) => data.spaceId,
});

export const utilizationAnalyticsByHourAdapter = createEntityAdapter<{
  spaceId: string;
  data: UtilizationAnalyticsData;
}>({
  selectId: (data) => data.spaceId,
});

export const utilizationAnalyticsByHourSelectors =
  utilizationAnalyticsByHourAdapter.getSelectors<RootState>(
    (state) => state.analysis.utilizationAnalyticsByHour
  );

export const utilizationAnalyticsByDayAdapter = createEntityAdapter<{
  spaceId: string;
  data: UtilizationAnalyticsData;
}>({
  selectId: (data) => data.spaceId,
});

export const dwellAnalyticsAdapter = createEntityAdapter<{
  spaceId: string;
  data: DwellAnalyticsData;
}>({
  selectId: (data) => data.spaceId,
});

// use createAction + extraReducers for optimal type safety

export const toggleHeatmapEnabled = createAction(
  'analysis/toggleHeatmapEnabled',
  withPayloadType<void>()
);

export const clickTableHeader = createAction(
  'analysis/clickTableHeader',
  withPayloadType<SortColumn>()
);

export const clickBackground = createAction(
  'analysis/clickBackground',
  withPayloadType<void>()
);

export const clickArea = createAction(
  'analysis/clickArea',
  withPayloadType<string>()
);

export const selectAreas = createAction(
  'analysis/selectAreas',
  withPayloadType<string[]>()
);

export const clickTimeline = createAction(
  'analysis/clickTimeline',
  withPayloadType<number | null>()
);

export const hoverTimeline = createAction(
  'analysis/hoverTimeline',
  withPayloadType<number | null>()
);

export const setDates = createAction(
  'analysis/setDates',
  withPayloadType<{
    value: { startDate: CompatibleDateValue; endDate: CompatibleDateValue };
    timeZone: string;
  }>()
);

export const setFilter = createAction(
  'analysis/setFilter',
  withPayloadType<{
    filterStart?: TimeOfDay;
    filterEnd?: TimeOfDay;
    filterDays?: Array<DayOfWeek>;
  }>()
);

export const hoverArea = createAction(
  'analysis/hoverArea',
  withPayloadType<string>()
);

export const unhoverArea = createAction(
  'analysis/unhoverArea',
  withPayloadType<string>()
);

export const toggleTableCollapsed = createAction(
  'analysis/toggleTableCollapsed',
  withPayloadType<void>()
);

export const setHeatmapLegend = createAction(
  'analysis/setHeatmapLegend',
  withPayloadType<{ min: number; max: number }>()
);

export const mouseDown = createAction(
  'analysis/mouseDown',
  withPayloadType<{ x: number; y: number }>()
);

export const mouseMove = createAction(
  'analysis/mouseMove',
  withPayloadType<{ x: number; y: number }>()
);

export const mouseUp = createAction(
  'analysis/mouseUp',
  withPayloadType<void>()
);

export const setMultiSelect = createAction(
  'analysis/setMultiSelect',
  withPayloadType<boolean>()
);

export const toggleSpaceName = createAction(
  'analysis/toggleSpaceName',
  withPayloadType<void>()
);

export const addSpaceFunctionFilter = createAction(
  'analysis/addSpaceFunctionFilter',
  withPayloadType<NewSpaceFunction>()
);

export const hoverSpaceFunctionFilter = createAction(
  'analysis/hoverSpaceFunctionFilter',
  withPayloadType<{ value: NewSpaceFunction; color: string }>()
);

export const unhoverSpaceFunctionFilter = createAction(
  'analysis/unhoverSpaceFunctionFilter',
  withPayloadType<void>()
);

export const removeSpaceFunctionFilter = createAction(
  'analysis/removeSpaceFunctionFilter',
  withPayloadType<NewSpaceFunction>()
);

export const addLabelFilter = createAction(
  'analysis/addLabelFilter',
  withPayloadType<string>()
);

export const hoverLabelFilter = createAction(
  'analysis/hoverLabelFilter',
  withPayloadType<{ value: string; color: string }>()
);

export const unhoverLabelFilter = createAction(
  'analysis/unhoverLabelFilter',
  withPayloadType<void>()
);

export const removeLabelFilter = createAction(
  'analysis/removeLabelFilter',
  withPayloadType<string>()
);

export const clearFilters = createAction(
  'analysis/clearFilters',
  withPayloadType<void>()
);

export const resetHeatmap = createAction(
  'analysis/resetHeatmap',
  withPayloadType<void>()
);

export type SortColumn = 'name' | 'occupied_percent' | 'occupied_total_ms';

export type AnalysisDatapoint = {
  occupied_percent: number;
  occupied_total_ms: number;
  area_id: string;
};

export type ProcessedHeatmapData = {
  hydratedData: Array<HeatmapPreprocessedFrame>;
  heatmapFrameStarts: Array<number>;
  nestedHeatmapFrames: Array<Map<number, Map<number, number>>>;
  unrolledHeatmapFrames: Array<Array<number>>;
  individualMaxValue: number;
  cumulativeMaxValue: number;
  maxX: number;
  maxY: number;
  windowSize: number;
  stepSize: number;
  rawStepSize: number;
  gridSize: number;
};

type FloorAnalysisState = {
  heatmapEnabled: boolean;
  showSpaceName: boolean;
  heatmapLegend: { min: number; max: number } | null;
  hoveredAreas: Array<string>;
  selectedAreas: Array<string>;

  clickedTimelineX: number | null;
  hoveredTimelineX: number | null;

  spaceFunctionFilters: NewSpaceFunction[];
  labelFilters: string[];

  hoveredSpaceFunctionFilter: { value: NewSpaceFunction; color: string } | null;
  hoveredLabelFilter: { value: string; color: string } | null;

  dates: CompatibleDateRange | null;
  filterStart: TimeOfDay;
  filterEnd: TimeOfDay;
  filterDays: Array<DayOfWeek>;
  sortColumn: SortColumn | null;
  sortDirection: 'asc' | 'desc' | 'none';
  tableCollapsed: boolean;

  // floorplan interactions state
  mouseState: 'mouseup' | 'mousedown' | 'dragging';
  dragStart: { x: number; y: number } | null;
  dragCurrent: { x: number; y: number } | null;
  multiSelect: boolean;

  // async network data state
  rawStepSize: number | null;

  distributionAnalyticsQueries: { [spaceId: string]: AsyncTaskState<void> };
  timeUsedAnalyticsQueries: { [spaceId: string]: AsyncTaskState<void> };
  utilizationAnalyticsByHourQueries: {
    [spaceId: string]: AsyncTaskState<void>;
  };
  utilizationAnalyticsByDayQueries: { [spaceId: string]: AsyncTaskState<void> };
  dwellAnalyticsQueries: { [spaceId: string]: AsyncTaskState<void> };

  distributionAnalytics: EntityState<{
    spaceId: string;
    data: DistributionAnalyticsData;
  }>;
  utilizationAnalyticsByHour: EntityState<{
    spaceId: string;
    data: UtilizationAnalyticsData;
  }>;
  utilizationAnalyticsByDay: EntityState<{
    spaceId: string;
    data: UtilizationAnalyticsData;
  }>;
  dwellAnalytics: EntityState<{
    spaceId: string;
    data: DwellAnalyticsData;
  }>;
  timeUsedAnalytics: EntityState<{
    spaceId: string;
    data: TimeUsedAnalyticsData;
  }>;

  analyticsQuery: AsyncTaskState<AnalysisDatapoint[]>;
  heatmapQuery: AsyncTaskState<HeatmapFrame[]>;
  processHeatmap: AsyncTaskState<ProcessedHeatmapData>;
  downloadCSV: AsyncTaskState<void>;
};

function getInitialState(): FloorAnalysisState {
  const hours = stringToTimeFilter('mon+tue+wed+thu+fri:0800-1700');

  return {
    heatmapEnabled: true,
    showSpaceName: false,
    heatmapLegend: null,

    spaceFunctionFilters: [],
    labelFilters: [],

    hoveredSpaceFunctionFilter: null,
    hoveredLabelFilter: null,

    hoveredAreas: [],
    selectedAreas: [],
    clickedTimelineX: null,
    hoveredTimelineX: null,
    dates: null,
    filterStart: {
      hour: Math.floor(hours.start.asHours()),
      minute: hours.start.asMinutes() % 60,
      second: 0,
      millisecond: 0,
    },
    filterEnd: {
      hour: Math.floor(hours.end.asHours()),
      minute: hours.end.asMinutes() % 60,
      second: 0,
      millisecond: 0,
    },
    filterDays: hours.days,
    sortColumn: 'occupied_percent',
    sortDirection: 'desc',
    tableCollapsed: true,

    mouseState: 'mouseup',
    dragStart: null,
    dragCurrent: null,
    multiSelect: false,

    rawStepSize: null,

    distributionAnalyticsQueries: {},
    utilizationAnalyticsByDayQueries: {},
    utilizationAnalyticsByHourQueries: {},
    dwellAnalyticsQueries: {},
    timeUsedAnalyticsQueries: {},

    distributionAnalytics: distributionAnalyticsAdapter.getInitialState(),
    utilizationAnalyticsByHour:
      utilizationAnalyticsByHourAdapter.getInitialState(),
    utilizationAnalyticsByDay:
      utilizationAnalyticsByDayAdapter.getInitialState(),
    dwellAnalytics: utilizationAnalyticsByDayAdapter.getInitialState(),
    timeUsedAnalytics: timeUsedAnalyticsAdapter.getInitialState(),

    analyticsQuery: getInitialAsyncTaskState(),
    heatmapQuery: getInitialAsyncTaskState(),
    processHeatmap: getInitialAsyncTaskState(),
    downloadCSV: getInitialAsyncTaskState(),
  };
}

const initialState = getInitialState();

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {},
  // https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers
  extraReducers: (builder) => {
    builder.addCase(
      asyncFetchDistributionAnalyticsThunk.pending,
      (state, action) => {
        state.distributionAnalyticsQueries[action.meta.arg.spaceId] =
          state.distributionAnalyticsQueries[action.meta.arg.spaceId] ||
          getInitialAsyncTaskState<void>();

        state.distributionAnalyticsQueries[action.meta.arg.spaceId] =
          getPendingState(
            state.distributionAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId
          );
      }
    );

    builder.addCase(
      asyncFetchDistributionAnalyticsThunk.fulfilled,
      (state, action) => {
        if (
          hasValidPendingState(
            state.distributionAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId
          )
        ) {
          distributionAnalyticsAdapter.upsertOne(state.distributionAnalytics, {
            spaceId: action.meta.arg.spaceId,
            data: action.payload.results[action.meta.arg.spaceId] || [],
          });
        }

        state.distributionAnalyticsQueries[action.meta.arg.spaceId] =
          getFulfilledState(
            state.distributionAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            undefined
          );
      }
    );

    builder.addCase(
      asyncFetchDistributionAnalyticsThunk.rejected,
      (state, action) => {
        state.distributionAnalyticsQueries[action.meta.arg.spaceId] =
          getRejectedState(
            state.distributionAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            action.error
          );
      }
    );

    builder.addCase(asyncFetchAnalyticsThunk.pending, (state, action) => {
      state.analyticsQuery = getPendingState(
        state.analyticsQuery,
        action.meta.requestId
      );
    });

    builder.addCase(asyncFetchAnalyticsThunk.fulfilled, (state, action) => {
      state.analyticsQuery = getFulfilledState(
        state.analyticsQuery,
        action.meta.requestId,
        action.payload
      );
    });

    builder.addCase(asyncFetchAnalyticsThunk.rejected, (state, action) => {
      state.analyticsQuery = getRejectedState(
        state.analyticsQuery,
        action.meta.requestId,
        action.error
      );
    });

    builder.addCase(asyncFetchHeatmapThunk.pending, (state, action) => {
      state.heatmapQuery = getPendingState(
        state.heatmapQuery,
        action.meta.requestId
      );

      state.rawStepSize = null;
    });

    builder.addCase(asyncFetchHeatmapThunk.fulfilled, (state, action) => {
      state.heatmapQuery = getFulfilledState(
        state.heatmapQuery,
        action.meta.requestId,
        action.payload.data
      );

      state.rawStepSize = action.payload.rawStepSize;
    });

    builder.addCase(asyncFetchHeatmapThunk.rejected, (state, action) => {
      state.heatmapQuery = getRejectedState(
        state.heatmapQuery,
        action.meta.requestId,
        action.error
      );

      state.rawStepSize = null;
    });

    builder.addCase(
      asyncFetchUtilizationAnalyticsByDayThunk.pending,
      (state, action) => {
        state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId] =
          state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId] ||
          getInitialAsyncTaskState<void>();

        state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId] =
          getPendingState(
            state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId],
            action.meta.requestId
          );
      }
    );

    builder.addCase(
      asyncFetchUtilizationAnalyticsByDayThunk.fulfilled,
      (state, action) => {
        if (
          hasValidPendingState(
            state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId],
            action.meta.requestId
          )
        ) {
          utilizationAnalyticsByDayAdapter.upsertOne(
            state.utilizationAnalyticsByDay,
            {
              spaceId: action.meta.arg.spaceId,
              data: action.payload.results[action.meta.arg.spaceId] || [],
            }
          );
        }

        state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId] =
          getFulfilledState(
            state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            undefined
          );
      }
    );

    builder.addCase(
      asyncFetchUtilizationAnalyticsByDayThunk.rejected,
      (state, action) => {
        state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId] =
          getRejectedState(
            state.utilizationAnalyticsByDayQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            action.error
          );
      }
    );

    builder.addCase(
      asyncFetchUtilizationAnalyticsByHourThunk.pending,
      (state, action) => {
        state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId] =
          state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId] ||
          getInitialAsyncTaskState<void>();

        state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId] =
          getPendingState(
            state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId],
            action.meta.requestId
          );
      }
    );

    builder.addCase(
      asyncFetchUtilizationAnalyticsByHourThunk.fulfilled,
      (state, action) => {
        if (
          hasValidPendingState(
            state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId],
            action.meta.requestId
          )
        ) {
          utilizationAnalyticsByHourAdapter.upsertOne(
            state.utilizationAnalyticsByHour,
            {
              spaceId: action.meta.arg.spaceId,
              data: action.payload.results[action.meta.arg.spaceId] || [],
            }
          );
        }

        state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId] =
          getFulfilledState(
            state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            undefined
          );
      }
    );

    builder.addCase(
      asyncFetchUtilizationAnalyticsByHourThunk.rejected,
      (state, action) => {
        state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId] =
          getRejectedState(
            state.utilizationAnalyticsByHourQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            action.error
          );
      }
    );

    builder.addCase(asyncFetchDwellAnalyticsThunk.pending, (state, action) => {
      state.dwellAnalyticsQueries[action.meta.arg.spaceId] =
        state.dwellAnalyticsQueries[action.meta.arg.spaceId] ||
        getInitialAsyncTaskState<void>();

      state.dwellAnalyticsQueries[action.meta.arg.spaceId] = getPendingState(
        state.dwellAnalyticsQueries[action.meta.arg.spaceId],
        action.meta.requestId
      );
    });

    builder.addCase(
      asyncFetchDwellAnalyticsThunk.fulfilled,
      (state, action) => {
        if (
          hasValidPendingState(
            state.dwellAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId
          )
        ) {
          utilizationAnalyticsByHourAdapter.upsertOne(state.dwellAnalytics, {
            spaceId: action.meta.arg.spaceId,
            data: action.payload.results[action.meta.arg.spaceId] || [],
          });
        }

        state.dwellAnalyticsQueries[action.meta.arg.spaceId] =
          getFulfilledState(
            state.dwellAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            undefined
          );
      }
    );

    builder.addCase(asyncFetchDwellAnalyticsThunk.rejected, (state, action) => {
      state.dwellAnalyticsQueries[action.meta.arg.spaceId] = getRejectedState(
        state.dwellAnalyticsQueries[action.meta.arg.spaceId],
        action.meta.requestId,
        action.error
      );
    });

    builder.addCase(
      asyncFetchTimeUsedAnalyticsThunk.pending,
      (state, action) => {
        state.timeUsedAnalyticsQueries[action.meta.arg.spaceId] =
          state.timeUsedAnalyticsQueries[action.meta.arg.spaceId] ||
          getInitialAsyncTaskState<void>();

        state.timeUsedAnalyticsQueries[action.meta.arg.spaceId] =
          getPendingState(
            state.timeUsedAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId
          );
      }
    );

    builder.addCase(
      asyncFetchTimeUsedAnalyticsThunk.fulfilled,
      (state, action) => {
        if (
          hasValidPendingState(
            state.timeUsedAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId
          )
        ) {
          timeUsedAnalyticsAdapter.upsertOne(state.timeUsedAnalytics, {
            spaceId: action.meta.arg.spaceId,
            data: action.payload.results[action.meta.arg.spaceId] || [],
          });
        }

        state.timeUsedAnalyticsQueries[action.meta.arg.spaceId] =
          getFulfilledState(
            state.timeUsedAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            undefined
          );
      }
    );

    builder.addCase(
      asyncFetchTimeUsedAnalyticsThunk.rejected,
      (state, action) => {
        state.timeUsedAnalyticsQueries[action.meta.arg.spaceId] =
          getRejectedState(
            state.timeUsedAnalyticsQueries[action.meta.arg.spaceId],
            action.meta.requestId,
            action.error
          );
      }
    );

    builder.addCase(asyncProcessHeatmapDataThunk.pending, (state, action) => {
      state.processHeatmap = getPendingState(
        state.processHeatmap,
        action.meta.requestId
      );
    });

    builder.addCase(asyncProcessHeatmapDataThunk.fulfilled, (state, action) => {
      state.processHeatmap = getFulfilledState(
        state.processHeatmap,
        action.meta.requestId,
        action.payload
      );
    });

    builder.addCase(asyncProcessHeatmapDataThunk.rejected, (state, action) => {
      state.processHeatmap = getRejectedState(
        state.processHeatmap,
        action.meta.requestId,
        action.error
      );
    });

    builder.addCase(toggleHeatmapEnabled, (state, action) => {
      const prevHeatmapEnabled = state.heatmapEnabled;

      state.heatmapEnabled = !prevHeatmapEnabled;
      state.tableCollapsed = !prevHeatmapEnabled;
    });

    builder.addCase(setDates, (state, action) => {
      state.dates = [
        moment
          .tz(action.payload.value.startDate, action.payload.timeZone)
          .startOf('day')
          .toISOString(),
        moment
          .tz(action.payload.value.endDate, action.payload.timeZone)
          .endOf('day')
          .toISOString(),
      ];
    });

    builder.addCase(setFilter, (state, action) => {
      if (action.payload.filterStart) {
        state.filterStart = action.payload.filterStart;
      }

      if (action.payload.filterEnd) {
        state.filterEnd = action.payload.filterEnd;
      }

      if (action.payload.filterDays) {
        state.filterDays = action.payload.filterDays;
      }
    });

    builder.addCase(hoverArea, (state, action) => {
      state.hoveredAreas = state.hoveredAreas.concat(action.payload);
    });

    builder.addCase(unhoverArea, (state, action) => {
      state.hoveredAreas = state.hoveredAreas.filter(
        (areaId) => areaId !== action.payload
      );
    });

    builder.addCase(clickTableHeader, (state, action) => {
      state.sortColumn = action.payload;
      state.sortDirection =
        action.payload !== state.sortColumn
          ? 'asc'
          : state.sortDirection === 'desc'
          ? 'none'
          : state.sortDirection === 'asc'
          ? 'desc'
          : 'asc';
    });

    builder.addCase(clickArea, (state, action) => {
      if (state.multiSelect) {
        if (state.selectedAreas.includes(action.payload)) {
          state.selectedAreas = state.selectedAreas.filter(
            (id) => id !== action.payload
          );
        } else {
          state.selectedAreas.push(action.payload);
        }
      } else {
        state.selectedAreas = [action.payload];
      }

      state.clickedTimelineX = null;
    });

    builder.addCase(clickBackground, (state, action) => {
      if (state.multiSelect) {
        return;
      }

      state.selectedAreas = [];
      state.clickedTimelineX = null;
    });

    builder.addCase(selectAreas, (state, action) => {
      state.selectedAreas = action.payload;
      state.clickedTimelineX = null;
    });

    builder.addCase(clickTimeline, (state, action) => {
      state.clickedTimelineX = action.payload;
    });

    builder.addCase(hoverTimeline, (state, action) => {
      state.hoveredTimelineX = action.payload;
    });

    builder.addCase(toggleTableCollapsed, (state, action) => {
      state.tableCollapsed = !state.tableCollapsed;
    });

    builder.addCase(setHeatmapLegend, (state, action) => {
      state.heatmapLegend = action.payload;
    });

    builder.addCase(mouseDown, (state, action) => {
      state.mouseState = 'mousedown';
      state.dragStart = action.payload;
    });

    builder.addCase(mouseMove, (state, action) => {
      if (
        state.mouseState === 'mousedown' &&
        state.dragStart &&
        // only start drag after the user has moved 10 pixels away
        Math.max(
          Math.abs(state.dragStart.x - action.payload.x),
          Math.abs(state.dragStart.y - action.payload.y)
        ) > 10
      ) {
        state.mouseState = 'dragging';
      }

      if (state.mouseState === 'dragging') {
        state.dragCurrent = action.payload;
      }
    });

    builder.addCase(mouseUp, (state, action) => {
      state.mouseState = 'mouseup';
      state.dragStart = null;
      state.dragCurrent = null;
    });

    builder.addCase(setMultiSelect, (state, action) => {
      state.multiSelect = action.payload;
    });

    builder.addCase(toggleSpaceName, (state, action) => {
      state.showSpaceName = !state.showSpaceName;
    });

    builder.addCase(addSpaceFunctionFilter, (state, action) => {
      state.spaceFunctionFilters.push(action.payload);
      state.selectedAreas = [];
    });

    builder.addCase(removeSpaceFunctionFilter, (state, action) => {
      state.spaceFunctionFilters = state.spaceFunctionFilters.filter(
        (f) => f !== action.payload
      );
      state.hoveredSpaceFunctionFilter = null;
    });

    builder.addCase(hoverSpaceFunctionFilter, (state, action) => {
      state.hoveredSpaceFunctionFilter = action.payload;
    });

    builder.addCase(unhoverSpaceFunctionFilter, (state, action) => {
      state.hoveredSpaceFunctionFilter = null;
    });

    builder.addCase(addLabelFilter, (state, action) => {
      state.labelFilters.push(action.payload);
      state.selectedAreas = [];
    });

    builder.addCase(removeLabelFilter, (state, action) => {
      state.labelFilters = state.labelFilters.filter(
        (f) => f !== action.payload
      );
      state.hoveredLabelFilter = null;
    });

    builder.addCase(hoverLabelFilter, (state, action) => {
      state.hoveredLabelFilter = action.payload;
    });

    builder.addCase(unhoverLabelFilter, (state, action) => {
      state.hoveredLabelFilter = null;
    });

    builder.addCase(clearFilters, (state, action) => {
      state.hoveredLabelFilter = null;
      state.labelFilters = [];
      state.hoveredSpaceFunctionFilter = null;
      state.spaceFunctionFilters = [];
    });

    builder.addCase(resetHeatmap, (state) => {
      state.heatmapQuery = getInitialAsyncTaskState();
      state.processHeatmap = getInitialAsyncTaskState();
    });
  },
});

// export reducer as default expoort
export default analysisSlice.reducer;
