import { createSelector } from '@reduxjs/toolkit';
import { aggregateDistribution } from 'lib/distribution';

import { RootState } from 'redux/store';

export const selectDistributionChartDataForSpace = createSelector(
  [
    (state: RootState) => state.analysis.distributionAnalytics,

    // parameters
    (_: RootState, params: { spaceId?: string }) => params.spaceId,
  ],
  (distributionAnalytics, spaceId) => {
    if (!spaceId) {
      return;
    }

    const data = distributionAnalytics.entities[spaceId]?.data;
    if (!data) {
      return;
    }

    return aggregateDistribution(data)
      .map((datum) => ({
        bandStart: datum.band_start,
        bandEnd: datum.band_end,
        percentage: datum.percentage,
        raw: datum.raw,
      }))
      .sort((d1, d2) => d1.bandStart - d2.bandStart);
  }
);
