import { useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import { ViewportCoordinates, FloorplanCoordinates } from 'lib/geometry';
import { distanceToLineSegment } from 'lib/algorithm';
import { Space } from 'components/editor/state';

import {
  ObjectLayer,
  ResizeHandle,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  drawPolygon,
  toRawHex,
} from 'components/floorplan';

import { Purple400, Purple700 } from '@density/dust/dist/tokens/dust.tokens';

const FOCUSED_OUTLINE_WIDTH_PX = 4;

// The spaces layer renders a number of box, circle, and polygonal spaces to the floorplan
const SpacesLayer: React.FunctionComponent<{
  spaces: Array<Space>;
  highlightedObject: {
    type: 'sensor' | 'space' | 'photogroup' | 'reference';
    id: string;
  } | null;
  focusedObject: null | {
    type: 'sensor' | 'space' | 'layer';
    id: string;
  };
  spaceOccupancy: ReadonlyMap<Space['id'], { occupied: boolean }>;
  onMouseEnter: (space: Space, event: FederatedPointerEvent) => void;
  onMouseLeave: (space: Space, event: FederatedPointerEvent) => void;
  onMouseDown: (space: Space, event: FederatedPointerEvent) => void;
  onDragMove: (space: Space, newCoordinates: FloorplanCoordinates) => void;
  onResizeBoxSpace: (
    space: Space,
    newPosition: FloorplanCoordinates,
    newWidth: number,
    newHeight: number
  ) => void;
  onResizeCircleSpace: (
    space: Space,
    newPosition: FloorplanCoordinates,
    newRadius: number
  ) => void;
  onResizePolygonSpace: (
    space: Space,
    newPosition: FloorplanCoordinates,
    newVertices: Array<FloorplanCoordinates>
  ) => void;
}> = ({
  spaces,
  highlightedObject,
  focusedObject,
  spaceOccupancy,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onDragMove,
  onResizeBoxSpace,
  onResizeCircleSpace,
  onResizePolygonSpace,
}) => {
  const context = useFloorplanLayerContext();

  const selectedSpace = useRef<Space | null>(null);

  // Set this data when a user's cursor is hovering over the border of a polygonal space. This is
  // used to facilutate clicking to create new vertices.
  const polygonSpaceVertexPosition = useRef<{
    vertexAIndex: number;
    vertexBIndex: number;
    position: FloorplanCoordinates;
  } | null>(null);

  const focusedSpace =
    focusedObject && focusedObject.type === 'space'
      ? spaces.find((space) => space.id === focusedObject.id)
      : null;
  useEffect(() => {
    if (!focusedSpace) {
      selectedSpace.current = null;
      return;
    }
    const space = spaces.find((space) => space.id === focusedSpace.id);
    if (!space) {
      return;
    }
    selectedSpace.current = space;
  }, [spaces, focusedSpace]);

  function shouldRenderSpace(
    space: Space,
    spaceViewportCoords: ViewportCoordinates
  ): boolean {
    if (!context.viewport.current) {
      return false;
    }

    let keyDimension: number;
    if (space.shape.type === 'box') {
      keyDimension = Math.max(space.shape.width, space.shape.height);
    } else if (space.shape.type === 'polygon') {
      // Find the vertex furthest from the center of the polygon
      const vertexDistanceApproximations = space.shape.vertices.map((v) =>
        Math.max(
          Math.abs(v.x - space.position.x),
          Math.abs(v.y - space.position.y)
        )
      );
      keyDimension = Math.max(...vertexDistanceApproximations);
    } else {
      keyDimension = space.shape.radius;
    }

    const keyDimensionInPx =
      keyDimension * context.floorplan.scale * context.viewport.current.zoom;

    return isWithinViewport(
      context,
      spaceViewportCoords,
      -1 * keyDimensionInPx
    );
  }

  function createResizeHandlesForPolygonalSpace(
    space: Space
  ): PIXI.Container | null {
    if (space.shape.type !== 'polygon') {
      return null;
    }

    const onResizeHandleReleased = () => {
      if (!selectedSpace.current) {
        return;
      }
      if (selectedSpace.current.shape.type !== 'polygon') {
        return;
      }
      onResizePolygonSpace(
        selectedSpace.current,
        selectedSpace.current.position,
        selectedSpace.current.shape.vertices
      );
    };

    const onResizeHandleDeleted = (index: number) => {
      if (!selectedSpace.current) {
        return;
      }
      if (selectedSpace.current.shape.type !== 'polygon') {
        return;
      }
      if (selectedSpace.current.shape.vertices.length <= 3) {
        // Don't allow deleting a vertex if the resulting shape won't have three points
        return;
      }

      // Remove the given resize handle
      selectedSpace.current.shape.vertices.splice(index, 1);

      onResizePolygonSpace(
        selectedSpace.current,
        selectedSpace.current.position,
        selectedSpace.current.shape.vertices
      );
    };

    const resizeHandles = new PIXI.Container();
    resizeHandles.name = 'resize-handles';
    for (let index = 0; index < space.shape.vertices.length; index += 1) {
      const resizeHandle = new ResizeHandle(
        context,
        (newPosition) => {
          if (
            !selectedSpace.current ||
            selectedSpace.current.shape.type !== 'polygon'
          ) {
            return;
          }
          selectedSpace.current.shape.vertices[index] = newPosition;
        },
        {
          onRelease: onResizeHandleReleased,
          onDelete: () => onResizeHandleDeleted(index),
        }
      );
      resizeHandle.cursor = 'move';
      resizeHandles.addChild(resizeHandle);
    }
    return resizeHandles;
  }

  return (
    <ObjectLayer
      objects={spaces}
      extractId={(space) => {
        switch (space.shape.type) {
          case 'box':
            return `${space.id},box`;
          case 'circle':
            return `${space.id},circle`;
          case 'polygon':
            return `${space.id},polygon,${space.shape.vertices.length}`;
        }
      }}
      onCreate={(getSpace) => {
        if (!context.viewport.current) {
          return null;
        }

        const spaceGraphic = new PIXI.Container();

        const shape = new PIXI.Graphics();
        shape.name = 'shape';
        shape.interactive = true;
        shape.cursor = 'grab';
        spaceGraphic.addChild(shape);

        shape.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }
          onMouseDown(getSpace(), evt);

          if (getSpace().locked) {
            return;
          }

          // If a user clicks on a polygon space when near the edge, create a new vertex
          if (
            selectedSpace.current &&
            getSpace().id === selectedSpace.current.id &&
            selectedSpace.current.shape.type === 'polygon' &&
            polygonSpaceVertexPosition.current
          ) {
            selectedSpace.current.shape.vertices.splice(
              polygonSpaceVertexPosition.current.vertexBIndex,
              0,
              polygonSpaceVertexPosition.current.position
            );
            onResizePolygonSpace(
              selectedSpace.current,
              selectedSpace.current.position,
              selectedSpace.current.shape.vertices
            );
            return;
          }

          // Otherwise, the user's trying to move the space
          addDragHandler(
            context,
            getSpace().position,
            evt,
            (newPosition) => {
              if (!selectedSpace.current) {
                return;
              }
              const oldPosition = selectedSpace.current.position;
              selectedSpace.current.position = newPosition;

              // Translate all vertices when moving polygonal spaces
              if (selectedSpace.current.shape.type === 'polygon') {
                const positionDeltaX = newPosition.x - oldPosition.x;
                const positionDeltaY = newPosition.y - oldPosition.y;
                for (
                  let index = 0;
                  index < selectedSpace.current.shape.vertices.length;
                  index += 1
                ) {
                  selectedSpace.current.shape.vertices[index] =
                    FloorplanCoordinates.create(
                      selectedSpace.current.shape.vertices[index].x +
                        positionDeltaX,
                      selectedSpace.current.shape.vertices[index].y +
                        positionDeltaY
                    );
                }
              }
            },
            () => {
              if (!selectedSpace.current) {
                return;
              }
              onDragMove(getSpace(), selectedSpace.current.position);
            }
          );
        });
        shape.on('mouseover', (evt) => onMouseEnter(getSpace(), evt));
        shape.on('mouseout', (evt) => onMouseLeave(getSpace(), evt));
        shape.on('mousemove', (event) => {
          if (!context.viewport.current) {
            return;
          }
          if (space.shape.type !== 'polygon') {
            return;
          }

          const isFocused =
            selectedSpace.current && selectedSpace.current.id === getSpace().id;
          if (!isFocused) {
            return;
          }

          const positionViewport = ViewportCoordinates.create(
            event.data.global.x,
            event.data.global.y
          );

          // Figure out the vertices that the mouse position is in between
          let vertexAIndex = -1;
          let vertexBIndex = -1;
          let minDistance = Infinity;
          for (const [a, b] of Space.polygonEdges(space.shape.vertices)) {
            const aViewport = FloorplanCoordinates.toViewportCoordinates(
              a,
              context.floorplan,
              context.viewport.current
            );
            const bViewport = FloorplanCoordinates.toViewportCoordinates(
              b,
              context.floorplan,
              context.viewport.current
            );

            const result = distanceToLineSegment(
              positionViewport,
              aViewport,
              bViewport
            );
            if (result < minDistance) {
              vertexAIndex = space.shape.vertices.indexOf(a);
              vertexBIndex = space.shape.vertices.indexOf(b);
              minDistance = result;
            }
          }

          // Only place vertices if the mouse is near an edge of the polygon
          if (minDistance > FOCUSED_OUTLINE_WIDTH_PX) {
            polygonSpaceVertexPosition.current = null;
            return;
          }

          const position = ViewportCoordinates.toFloorplanCoordinates(
            positionViewport,
            context.viewport.current,
            context.floorplan
          );

          polygonSpaceVertexPosition.current = {
            vertexAIndex,
            vertexBIndex,
            position,
          };
        });

        const space = getSpace();
        if (space.shape.type === 'box') {
          let initialX: number,
            initialY: number,
            initialWidth: number,
            initialHeight: number;
          const onResizeHandlePressed = () => {
            if (!selectedSpace.current) {
              return;
            }
            if (selectedSpace.current.shape.type !== 'box') {
              return;
            }
            initialX = selectedSpace.current.position.x;
            initialY = selectedSpace.current.position.y;
            initialWidth = selectedSpace.current.shape.width;
            initialHeight = selectedSpace.current.shape.height;
          };

          const onResizeHandleReleased = () => {
            if (!selectedSpace.current) {
              return;
            }
            if (selectedSpace.current.shape.type !== 'box') {
              return;
            }
            onResizeBoxSpace(
              getSpace(),
              selectedSpace.current.position,
              selectedSpace.current.shape.width,
              selectedSpace.current.shape.height
            );
          };

          const topLeftResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              const leftSideOfWidth = initialX - newPosition.x;
              const rightSideOfWidth = initialWidth / 2;
              const newWidth = leftSideOfWidth + rightSideOfWidth;
              const newX =
                initialX +
                initialWidth / 2 -
                (rightSideOfWidth + leftSideOfWidth) / 2;

              const topSideOfHeight = initialY - newPosition.y;
              const bottomSideOfHeight = initialHeight / 2;
              const newHeight = topSideOfHeight + bottomSideOfHeight;
              const newY =
                initialY +
                initialHeight / 2 -
                (bottomSideOfHeight + topSideOfHeight) / 2;

              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'box'
              ) {
                return;
              }

              // Make sure space isn't too small
              if (newWidth >= Space.MIN_WIDTH) {
                selectedSpace.current.position.x = newX;
                selectedSpace.current.shape.width = newWidth;
              }
              if (newHeight >= Space.MIN_HEIGHT) {
                selectedSpace.current.position.y = newY;
                selectedSpace.current.shape.height = newHeight;
              }
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          topLeftResizeHandle.name = 'resize-top-left';
          topLeftResizeHandle.cursor = 'nwse-resize';
          spaceGraphic.addChild(topLeftResizeHandle);

          const bottomLeftResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              const leftSideOfWidth = initialX - newPosition.x;
              const rightSideOfWidth = initialWidth / 2;
              const newWidth = leftSideOfWidth + rightSideOfWidth;
              const newX =
                initialX +
                initialWidth / 2 -
                (rightSideOfWidth + leftSideOfWidth) / 2;

              const bottomSideOfHeight = newPosition.y - initialY;
              const topSideOfHeight = initialHeight / 2;
              const newHeight = bottomSideOfHeight + topSideOfHeight;
              const newY =
                initialY -
                initialHeight / 2 +
                (topSideOfHeight + bottomSideOfHeight) / 2;

              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'box'
              ) {
                return;
              }

              // Make sure space isn't too small
              if (newWidth >= Space.MIN_WIDTH) {
                selectedSpace.current.position.x = newX;
                selectedSpace.current.shape.width = newWidth;
              }
              if (newHeight >= Space.MIN_HEIGHT) {
                selectedSpace.current.position.y = newY;
                selectedSpace.current.shape.height = newHeight;
              }
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          bottomLeftResizeHandle.name = 'resize-bottom-left';
          bottomLeftResizeHandle.cursor = 'nesw-resize';
          spaceGraphic.addChild(bottomLeftResizeHandle);

          const topRightResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              const rightSideOfWidth = newPosition.x - initialX;
              const leftSideOfWidth = initialWidth / 2;
              const newWidth = leftSideOfWidth + rightSideOfWidth;
              const newX =
                initialX -
                initialWidth / 2 +
                (rightSideOfWidth + leftSideOfWidth) / 2;

              const topSideOfHeight = initialY - newPosition.y;
              const bottomSideOfHeight = initialHeight / 2;
              const newHeight = topSideOfHeight + bottomSideOfHeight;
              const newY =
                initialY +
                initialHeight / 2 -
                (bottomSideOfHeight + topSideOfHeight) / 2;

              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'box'
              ) {
                return;
              }

              // Make sure space isn't too small
              if (newWidth >= Space.MIN_WIDTH) {
                selectedSpace.current.position.x = newX;
                selectedSpace.current.shape.width = newWidth;
              }
              if (newHeight >= Space.MIN_HEIGHT) {
                selectedSpace.current.position.y = newY;
                selectedSpace.current.shape.height = newHeight;
              }
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          topRightResizeHandle.name = 'resize-top-right';
          topRightResizeHandle.cursor = 'nesw-resize';
          spaceGraphic.addChild(topRightResizeHandle);

          const bottomRightResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              const rightSideOfWidth = newPosition.x - initialX;
              const leftSideOfWidth = initialWidth / 2;
              const newWidth = leftSideOfWidth + rightSideOfWidth;
              const newX =
                initialX -
                initialWidth / 2 +
                (rightSideOfWidth + leftSideOfWidth) / 2;

              const bottomSideOfHeight = newPosition.y - initialY;
              const topSideOfHeight = initialHeight / 2;
              const newHeight = bottomSideOfHeight + topSideOfHeight;
              const newY =
                initialY -
                initialHeight / 2 +
                (topSideOfHeight + bottomSideOfHeight) / 2;

              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'box'
              ) {
                return;
              }

              // Make sure space isn't too small
              if (newWidth >= Space.MIN_WIDTH) {
                selectedSpace.current.position.x = newX;
                selectedSpace.current.shape.width = newWidth;
              }
              if (newHeight >= Space.MIN_HEIGHT) {
                selectedSpace.current.position.y = newY;
                selectedSpace.current.shape.height = newHeight;
              }
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          bottomRightResizeHandle.name = 'resize-bottom-right';
          bottomRightResizeHandle.cursor = 'nwse-resize';
          spaceGraphic.addChild(bottomRightResizeHandle);
        } else if (space.shape.type === 'polygon') {
          const resizeHandles = createResizeHandlesForPolygonalSpace(space);
          if (resizeHandles) {
            spaceGraphic.addChild(resizeHandles);
          }
        } else {
          let initialX: number, initialY: number;
          const onResizeHandlePressed = () => {
            if (!selectedSpace.current) {
              return;
            }
            initialX = selectedSpace.current.position.x;
            initialY = selectedSpace.current.position.y;
          };

          const onResizeHandleReleased = () => {
            if (!selectedSpace.current) {
              return;
            }
            if (selectedSpace.current.shape.type !== 'circle') {
              return;
            }
            onResizeCircleSpace(
              getSpace(),
              selectedSpace.current.position,
              selectedSpace.current.shape.radius
            );
          };

          const topResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'circle'
              ) {
                return;
              }
              const newRadius = initialY - newPosition.y;

              // Make sure space isn't too small
              if (newRadius < Space.MIN_RADIUS) {
                return;
              }

              selectedSpace.current.shape.radius = newRadius;
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          topResizeHandle.name = 'resize-top';
          topResizeHandle.cursor = 'ns-resize';
          spaceGraphic.addChild(topResizeHandle);

          const bottomResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'circle'
              ) {
                return;
              }

              const newRadius = newPosition.y - initialY;

              // Make sure space isn't too small
              if (newRadius < Space.MIN_RADIUS) {
                return;
              }

              selectedSpace.current.shape.radius = newRadius;
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          bottomResizeHandle.name = 'resize-bottom';
          bottomResizeHandle.cursor = 'ns-resize';
          spaceGraphic.addChild(bottomResizeHandle);

          const leftResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'circle'
              ) {
                return;
              }
              const newRadius = initialX - newPosition.x;

              // Make sure space isn't too small
              if (newRadius < Space.MIN_RADIUS) {
                return;
              }

              selectedSpace.current.shape.radius = newRadius;
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          leftResizeHandle.name = 'resize-left';
          leftResizeHandle.cursor = 'ew-resize';
          spaceGraphic.addChild(leftResizeHandle);

          const rightResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'circle'
              ) {
                return;
              }
              const newRadius = newPosition.x - initialX;

              // Make sure space isn't too small
              if (newRadius < Space.MIN_RADIUS) {
                return;
              }

              selectedSpace.current.shape.radius = newRadius;
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          rightResizeHandle.name = 'resize-right';
          rightResizeHandle.cursor = 'ew-resize';
          spaceGraphic.addChild(rightResizeHandle);
        }

        return spaceGraphic;
      }}
      onUpdate={(s: Space, spaceContainer: PIXI.Container) => {
        if (!context.viewport.current) {
          return;
        }

        const isFocused =
          focusedObject &&
          focusedObject.type === 'space' &&
          focusedObject.id === s.id;
        const isHighlighted =
          highlightedObject &&
          highlightedObject.type === 'space' &&
          highlightedObject.id === s.id;

        const space: Space =
          isFocused && selectedSpace.current ? selectedSpace.current : s;

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          space.position,
          context.floorplan,
          context.viewport.current
        );

        spaceContainer.renderable = shouldRenderSpace(space, viewportCoords);
        if (!spaceContainer.renderable) {
          return;
        }

        spaceContainer.x = viewportCoords.x;
        spaceContainer.y = viewportCoords.y;

        // Figure out if the space has accregated points in it
        const occupancy = spaceOccupancy.get(space.id);
        const spaceOccupied = occupancy && occupancy.occupied;

        const spaceShape = spaceContainer.getChildByName(
          'shape'
        ) as PIXI.Graphics;
        if (isFocused && polygonSpaceVertexPosition.current) {
          // When a new point can be added, show a special cursor
          spaceShape.cursor = 'copy';
        } else {
          spaceShape.cursor = space.locked ? 'pointer' : 'grab';
        }
        spaceShape.clear();
        spaceShape.beginFill(toRawHex(Purple400), spaceOccupied ? 0.5 : 0.12);

        if (isHighlighted || isFocused) {
          spaceShape.lineStyle({
            width: 1,
            color: toRawHex(Purple400),
            join: PIXI.LINE_JOIN.ROUND,
          });
        } else if (spaceOccupied) {
          spaceShape.lineStyle({
            width: 1,
            color: toRawHex(Purple700),
            join: PIXI.LINE_JOIN.ROUND,
          });
        }

        switch (space.shape.type) {
          case 'box': {
            const widthPixels =
              space.shape.width *
              context.floorplan.scale *
              context.viewport.current.zoom;
            const heightPixels =
              space.shape.height *
              context.floorplan.scale *
              context.viewport.current.zoom;

            const upperLeftX = (-1 * widthPixels) / 2;
            const upperLeftY = (-1 * heightPixels) / 2;

            // Render filled box shape for space
            spaceShape.drawRect(
              upperLeftX,
              upperLeftY,
              widthPixels,
              heightPixels
            );

            const topLeftResizeHandle =
              spaceContainer.getChildByName('resize-top-left');
            const bottomLeftResizeHandle =
              spaceContainer.getChildByName('resize-bottom-left');
            const topRightResizeHandle =
              spaceContainer.getChildByName('resize-top-right');
            const bottomRightResizeHandle = spaceContainer.getChildByName(
              'resize-bottom-right'
            );
            if (
              !topLeftResizeHandle ||
              !topRightResizeHandle ||
              !topRightResizeHandle ||
              !bottomRightResizeHandle
            ) {
              break;
            }

            // Disable resize handles if the space is locked
            topLeftResizeHandle.interactive = !space.locked;
            bottomLeftResizeHandle.interactive = !space.locked;
            topRightResizeHandle.interactive = !space.locked;
            bottomRightResizeHandle.interactive = !space.locked;

            if (isFocused) {
              // Add outline
              spaceShape.lineStyle({
                width: FOCUSED_OUTLINE_WIDTH_PX,
                color: toRawHex(Purple400),
                alpha: 0.2,
                alignment: 0,
                join: PIXI.LINE_JOIN.ROUND,
              });
              spaceShape.drawRect(
                upperLeftX,
                upperLeftY,
                widthPixels,
                heightPixels
              );

              // Reposition resize handles
              topLeftResizeHandle.visible = true;
              topLeftResizeHandle.x = upperLeftX;
              topLeftResizeHandle.y = upperLeftY;

              bottomLeftResizeHandle.visible = true;
              bottomLeftResizeHandle.x = upperLeftX;
              bottomLeftResizeHandle.y = upperLeftY + heightPixels;

              topRightResizeHandle.x = upperLeftX + widthPixels;
              topRightResizeHandle.y = upperLeftY;
              topRightResizeHandle.visible = true;

              bottomRightResizeHandle.x = upperLeftX + widthPixels;
              bottomRightResizeHandle.y = upperLeftY + heightPixels;
              bottomRightResizeHandle.visible = true;
            } else {
              topLeftResizeHandle.visible = false;
              bottomLeftResizeHandle.visible = false;
              topRightResizeHandle.visible = false;
              bottomRightResizeHandle.visible = false;
            }
            break;
          }
          case 'circle': {
            const radiusPixels =
              space.shape.radius *
              context.floorplan.scale *
              context.viewport.current.zoom;

            const upperLeftX = -1 * radiusPixels;
            const upperLeftY = -1 * radiusPixels;

            // Render filled circle shape for space
            spaceShape.drawCircle(0, 0, radiusPixels);
            spaceShape.endFill();

            const topResizeHandle = spaceContainer.getChildByName('resize-top');
            const bottomResizeHandle =
              spaceContainer.getChildByName('resize-bottom');
            const leftResizeHandle =
              spaceContainer.getChildByName('resize-left');
            const rightResizeHandle =
              spaceContainer.getChildByName('resize-right');
            if (
              !topResizeHandle ||
              !bottomResizeHandle ||
              !leftResizeHandle ||
              !rightResizeHandle
            ) {
              break;
            }

            // Disable resize handles if the space is locked
            topResizeHandle.interactive = !space.locked;
            bottomResizeHandle.interactive = !space.locked;
            leftResizeHandle.interactive = !space.locked;
            rightResizeHandle.interactive = !space.locked;

            if (isFocused) {
              // Add outline
              spaceShape.lineStyle({
                width: FOCUSED_OUTLINE_WIDTH_PX,
                color: toRawHex(Purple400),
                alpha: 0.2,
                alignment: 0,
                join: PIXI.LINE_JOIN.ROUND,
              });
              spaceShape.drawCircle(0, 0, radiusPixels);

              // Reposition resize handles
              topResizeHandle.visible = true;
              topResizeHandle.x = 0;
              topResizeHandle.y = upperLeftY;

              bottomResizeHandle.visible = true;
              bottomResizeHandle.x = 0;
              bottomResizeHandle.y = -1 * upperLeftY;

              leftResizeHandle.visible = true;
              leftResizeHandle.x = upperLeftX;
              leftResizeHandle.y = 0;

              rightResizeHandle.visible = true;
              rightResizeHandle.x = -1 * upperLeftX;
              rightResizeHandle.y = 0;
            } else {
              topResizeHandle.visible = false;
              bottomResizeHandle.visible = false;
              leftResizeHandle.visible = false;
              rightResizeHandle.visible = false;
            }
            break;
          }
          case 'polygon': {
            // Render polygon
            const verticesViewport = space.shape.vertices.map((v) => {
              if (!context.viewport.current) {
                throw new Error('This is impossible');
              }

              return ViewportCoordinates.create(
                (v.x - space.position.x) *
                  context.floorplan.scale *
                  context.viewport.current.zoom,
                (v.y - space.position.y) *
                  context.floorplan.scale *
                  context.viewport.current.zoom
              );
            });
            drawPolygon(spaceShape, verticesViewport);

            const resizeHandles = spaceContainer.getChildByName(
              'resize-handles'
            ) as PIXI.Container | undefined;
            if (!resizeHandles) {
              return;
            }

            // Disable resize handles if the space is locked
            resizeHandles.interactive = !space.locked;

            if (isFocused) {
              // Add outline
              spaceShape.lineStyle({
                width: FOCUSED_OUTLINE_WIDTH_PX,
                color: toRawHex(Purple400),
                alpha: 0.2,
                alignment: 1,
                join: PIXI.LINE_JOIN.ROUND,
              });
              drawPolygon(spaceShape, verticesViewport);

              // Ensure resize handles are positioned in the right spots
              resizeHandles.visible = true;
              for (
                let vertexIndex = 0;
                vertexIndex < verticesViewport.length;
                vertexIndex += 1
              ) {
                const handle = resizeHandles.children[vertexIndex];
                if (!handle) {
                  continue;
                }

                handle.x = verticesViewport[vertexIndex].x;
                handle.y = verticesViewport[vertexIndex].y;
              }
            } else {
              resizeHandles.visible = false;
            }
          }
        }
        spaceShape.endFill();
      }}
      onRemove={(space: Space, spaceContainer) => {
        spaceContainer.destroy(true);
      }}
    />
  );
};

export default SpacesLayer;
