import { CoreSpaceFunction } from '@densityco/lib-api-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';

import { NewSpaceFunction } from 'lib/space-functions';
import { throwIfAggregateError } from 'lib/throw-if-aggregate-error';
import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { patchSpacesState, spacesAdapter } from './spaces-slice';
import { getSpacesPatches } from 'lib/patches';
import { FixMe } from 'types/fixme';

export const asyncBulkUpdateSpaceThunk = createAsyncThunk<
  // return value
  void,
  // parameters
  {
    spaceIds: string[];

    body: {
      function?: CoreSpaceFunction | NewSpaceFunction;
      capacity?: number;
    };
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'spaces/asyncBulkUpdateSpace',
  async (args, thunkApi) => {
    const { auth } = thunkApi.getState();

    const client = auth.densityAPIClient;
    invariant(client, 'missing densityAPIClient');

    const updateSpace = async (spaceId: string) => {
      await client.patch(`/app/spaces/${spaceId}`, args.body);

      const [patches] = getSpacesPatches(
        thunkApi.getState().spaces,
        (draft) => {
          spacesAdapter.updateOne(draft.spaces, {
            id: spaceId,

            // FIXME: space functions need to be updated
            changes: args.body as FixMe,
          });
        }
      );

      thunkApi.dispatch(patchSpacesState(patches));
    };

    const results = await Promise.allSettled(args.spaceIds.map(updateSpace));

    throwIfAggregateError('Failed to bulk update space', results);
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
