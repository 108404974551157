// aggregate results from Promise.allSettled and throw if there is at least one error
export const throwIfAggregateError = <T>(
  msg: string,
  results: PromiseSettledResult<T>[]
) => {
  const rejectedResults = results.filter(
    (result): result is PromiseRejectedResult => result.status === 'rejected'
  );

  if (rejectedResults.length <= 0) {
    return;
  }

  throw new AggregateError(
    rejectedResults.map((result) => result.reason),
    msg
  );
};
