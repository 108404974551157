import pointInPolygon from 'point-in-polygon';
import { Space } from '../components/editor/state';

import { FloorplanCoordinates } from 'lib/geometry';
import { distance } from 'lib/math';

export function isPositionInsideSpace(
  position: FloorplanCoordinates,
  space: Space
): boolean {
  switch (space.shape.type) {
    case 'box': {
      const rx = space.shape.width / 2;
      const ry = space.shape.height / 2;
      const minX = space.position.x - rx;
      const maxX = space.position.x + rx;
      const minY = space.position.y - ry;
      const maxY = space.position.y + ry;
      return (
        position.x >= minX &&
        position.x <= maxX &&
        position.y >= minY &&
        position.y <= maxY
      );
    }
    case 'circle': {
      return (
        Math.hypot(
          position.x - space.position.x,
          position.y - space.position.y
        ) <= space.shape.radius
      );
    }
    case 'polygon': {
      return pointInPolygon(
        [position.x, position.y],
        space.shape.vertices.map((v) => [v.x, v.y])
      );
    }
  }
}

// From https://github.com/substack/line-segment-intersect-2d/blob/main/index.js
type LineSegmentIntersection2dPoint = [number, number];
export function lineSegmentIntersection2d(
  out: Array<number>,
  a0: LineSegmentIntersection2dPoint,
  a1: LineSegmentIntersection2dPoint,
  b0: LineSegmentIntersection2dPoint,
  b1: LineSegmentIntersection2dPoint
) {
  var ax = a1[0] - a0[0];
  var ay = a1[1] - a0[1];
  var bx = b1[0] - b0[0];
  var by = b1[1] - b0[1];
  var d = ax * by - bx * ay;
  if (d === 0) return null;
  var dpos = d > 0;
  var cx = a0[0] - b0[0];
  var cy = a0[1] - b0[1];
  var sn = ax * cy - ay * cx;
  const snlessthanzero = sn < 0;
  if (snlessthanzero === dpos) return null;
  var tn = bx * cy - by * cx;
  const tnlessthanzero = tn < 0;
  if (tnlessthanzero === dpos) return null;
  const sngreaterthand = sn > d;
  const tngreaterthand = tn > d;
  if (sngreaterthand === dpos || tngreaterthand === dpos) return null;
  var t = tn / d;
  out[0] = a0[0] + t * ax;
  out[1] = a0[1] + t * ay;
  return out;
}

export function doesRectangleOverlapSpace(
  rectangle: {
    topLeft: FloorplanCoordinates;
    bottomRight: FloorplanCoordinates;
  },
  space: Space
): boolean {
  switch (space.shape.type) {
    case 'box': {
      const rx = space.shape.width / 2;
      const ry = space.shape.height / 2;
      const minX = space.position.x - rx;
      const maxX = space.position.x + rx;
      const minY = space.position.y - ry;
      const maxY = space.position.y + ry;

      if (rectangle.bottomRight.y < minY || rectangle.topLeft.y > maxY) {
        return false;
      }

      if (rectangle.bottomRight.x < minX || rectangle.topLeft.x > maxX) {
        return false;
      }

      return true;
    }

    case 'circle': {
      // nearest point on the rectangle to the center of the circle
      const x = Math.max(
        Math.min(rectangle.bottomRight.x, space.position.x),
        rectangle.topLeft.x
      );
      const y = Math.max(
        Math.min(rectangle.bottomRight.y, space.position.y),
        rectangle.topLeft.y
      );

      // difference between nearest point to the center of the circle
      const dx = x - space.position.x;
      const dy = y - space.position.y;

      // check the distance between the two points is less than the space's radius
      return (
        Math.pow(dx, 2) + Math.pow(dy, 2) <= Math.pow(space.shape.radius, 2)
      );
    }

    case 'polygon': {
      // If the polygon is in the rectangle, either:
      // a) the polygon is fully inside the rectangle
      // b) the polygon is partially inside the rectangle, and one of the rectangle's sides
      // itnersects with the polygon's side

      // Start by testing a)
      const polygonFullyInsideRectangle =
        space.position.x >= rectangle.topLeft.x &&
        space.position.y >= rectangle.topLeft.y &&
        space.position.x <= rectangle.bottomRight.x &&
        space.position.y <= rectangle.bottomRight.y;
      if (polygonFullyInsideRectangle) {
        return true;
      }

      // And if that fails, test b)

      // Generate all rectangle sides
      const bottomLeft = FloorplanCoordinates.create(
        rectangle.topLeft.x,
        rectangle.bottomRight.y
      );
      const topRight = FloorplanCoordinates.create(
        rectangle.bottomRight.x,
        rectangle.topLeft.y
      );
      const rectangleSides = [
        [rectangle.topLeft, bottomLeft],
        [bottomLeft, rectangle.bottomRight],
        [rectangle.bottomRight, topRight],
        [topRight, rectangle.topLeft],
      ];

      // Generate pairs of vertexes, ie, each "line segment" making up the polygon
      const vertexPairs = Space.polygonEdges(space.shape.vertices);

      // Check each rectangle side against each line segment that makes up the polygon
      for (const [sidePointA, sidePointB] of rectangleSides) {
        for (const [vertexPointA, vertexPointB] of vertexPairs) {
          const intersects = lineSegmentIntersection2d(
            [],
            [sidePointA.x, sidePointA.y],
            [sidePointB.x, sidePointB.y],
            [vertexPointA.x, vertexPointA.y],
            [vertexPointB.x, vertexPointB.y]
          );
          if (intersects) {
            return true;
          }
        }
      }

      return false;
    }
  }
}

// A simplified version of: https://stackoverflow.com/q/31494662/4115328
export function distanceToLineSegment<T extends string>(
  point: { type: T; x: number; y: number },
  lineStartPoint: { type: T; x: number; y: number },
  lineEndPoint: { type: T; x: number; y: number }
) {
  const dx = lineEndPoint.x - lineStartPoint.x;
  const dy = lineEndPoint.y - lineStartPoint.y;
  const l2 = dx * dx + dy * dy;

  if (l2 === 0) {
    return distance(point, lineEndPoint);
  }

  let t =
    ((point.x - lineStartPoint.x) * dx + (point.y - lineStartPoint.y) * dy) /
    l2;
  t = Math.max(0, Math.min(1, t));

  return distance(point, {
    type: point.type,
    x: lineStartPoint.x + t * dx,
    y: lineStartPoint.y + t * dy,
  });
}
