import * as React from 'react';
import { Fragment } from 'react';
import classNames from 'classnames';
import colors from '@densityco/ui/variables/colors.json';

import SpaceGraphic from './space-graphic';
import styles from './floor-availability.module.scss';
import AvailabilitySettingsPanel from './availability-settings-panel';
import FloorAvailabilitySidebar from './floor-availability-sidebar';

import FloorplanImageView from 'components/floorplan-image-view/floorplan-image-view';
import { MiniShortcutsMenu } from 'components/keyboard-shortcuts-menu';
import AppBarFloor from 'components/app-bar-floor/app-bar-floor';
import { useAutoSize } from 'hooks/use-auto-size';
import { useViewportControls } from 'hooks/use-viewport-controls';
import { Area, PlanDetail } from 'lib/api';
import { FloorplanCoordinates, ViewportCoordinates } from 'lib/geometry';
import { areaToSpace } from 'lib/area';
import { Viewport } from 'lib/viewport';
import { Floorplan } from 'lib/floorplan';
import {
  computeCoverageRadiusOA,
  computeCoverageMajorMinorAxisOA,
} from 'lib/sensor';
import TrackVisualizer, {
  FloorplanTargetInfo,
} from 'components/track-visualizer';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  toggleAreaLabels,
  toggleShowTracks,
} from 'redux/features/availability/availability-slice';
import { asyncFetchStatusThunk } from 'redux/features/availability/async-fetch-status-thunk';
import { asyncSetUpStatusWebSocketThunk } from 'redux/features/availability/async-set-up-status-web-socket-thunk';
import { updateAvailabilityOnIntervalThunk } from 'redux/features/availability/update-availability-on-interval-thunk';
import { Floor } from 'lib/floors';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import { asyncSetUpOALiveWebSocketThunk } from 'redux/features/availability/async-set-up-oa-live-web-socket-thunk';

const FloorAvailability: React.FC<{
  planDetail: PlanDetail;
  floor: Floor;
  planImage: HTMLImageElement;
}> = ({ planDetail, floor, planImage }) => {
  const dispatch = useAppDispatch();
  const availabilityState = useAppSelector((state) => state.availability);

  const isShowTracksOn = useTreatment(SPLITS.SHOW_TRACKS);
  const isOALiveConnectionOn = useTreatment(SPLITS.OA_LIVE_CONNECTION);

  const {
    viewport,
    resize,
    zoomToFit,
    onViewportMouseDown,
    viewportElementRef,
  } = useViewportControls<HTMLDivElement>();
  const viewportSize = useAutoSize(viewportElementRef);

  React.useEffect(() => {
    const { width, height } = viewportSize;
    resize(width, height);

    if (planImage) {
      zoomToFit(planImage);
    }
  }, [viewportSize, resize, zoomToFit, planImage]);

  React.useEffect(() => {
    const onKeyUp = (evt: KeyboardEvent) => {
      if (evt.key === 'i') {
        dispatch(toggleAreaLabels());
      }
    };
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (!planDetail) {
      return;
    }

    const promise = dispatch(asyncFetchStatusThunk({ planId: planDetail.id }));

    return () => {
      promise.abort();
    };
  }, [dispatch, planDetail]);

  React.useEffect(() => {
    if (!planDetail) {
      return;
    }

    const promise = isOALiveConnectionOn
      ? dispatch(
          asyncSetUpOALiveWebSocketThunk({
            planId: planDetail.id,
          })
        )
      : dispatch(
          asyncSetUpStatusWebSocketThunk({
            planId: planDetail.id,
          })
        );

    return () => {
      promise.abort();
    };
  }, [dispatch, isOALiveConnectionOn, planDetail]);

  React.useEffect(() => {
    if (!planDetail) {
      return;
    }

    const cleanup = dispatch(
      updateAvailabilityOnIntervalThunk({ planId: planDetail.id })
    );

    return () => {
      cleanup();
    };
  }, [dispatch, planDetail]);

  const appBar = <AppBarFloor />;

  if (!planDetail) {
    return appBar;
  }

  if (!floor) {
    return appBar;
  }

  return (
    <Fragment>
      {appBar}
      <div className={styles.FloorAvailability}>
        {/* This is the layout pane for the Sidebar */}
        <div className={styles.Sidebar}>
          {/* This is the actual sidebar you see */}
          <FloorAvailabilitySidebar plan={planDetail} floor={floor} />
        </div>
        <div
          className={styles.Floorplan}
          ref={viewportElementRef}
          onMouseDown={onViewportMouseDown}
        >
          {isShowTracksOn ? (
            <AvailabilitySettingsPanel
              showTracks={availabilityState.showTracks}
              toggleShowTracks={() => dispatch(toggleShowTracks())}
            />
          ) : null}

          {planImage ? (
            <FloorplanImageView
              image={planImage}
              opacity={0.25}
              viewport={viewport}
            />
          ) : null}

          <MiniShortcutsMenu />

          <AvailabilityOverlay
            viewport={viewport}
            plan={planDetail}
            availability={availabilityState.availabilityByAreaId}
            highlightedArea={availabilityState.highlightedArea}
            targets={availabilityState.targets}
            showAllAreaLabels={availabilityState.showAllAreaLabels}
            showTracks={isShowTracksOn && availabilityState.showTracks}
          />
        </div>
      </div>
    </Fragment>
  );
};

const AreaLabel: React.FunctionComponent<{
  floorplan: Floorplan;
  viewport: Viewport;
  area: Area;
  isAvailable: boolean;
}> = ({ floorplan, viewport, area, isAvailable }) => {
  const labelOrigin = React.useMemo(() => {
    const space = areaToSpace(area);
    const spaceCentroid = FloorplanCoordinates.toViewportCoordinates(
      space.position,
      floorplan,
      viewport
    );

    const [offsetXMeters, offsetYMeters] = (() => {
      switch (space.shape.type) {
        case 'box':
          return [space.shape.width / 2, space.shape.height / 2];
        case 'circle':
          return [space.shape.radius, space.shape.radius];
        case 'polygon':
          const posX = Math.max(
            ...space.shape.vertices.map((v) => space.position.x - v.x)
          );
          const posY = Math.max(
            ...space.shape.vertices.map((v) => space.position.y - v.y)
          );
          return [posX, posY];
      }
    })();
    const offsetX = offsetXMeters * floorplan.scale * viewport.zoom;
    const offsetY = offsetYMeters * floorplan.scale * viewport.zoom;

    return ViewportCoordinates.create(
      spaceCentroid.x - offsetX,
      spaceCentroid.y - offsetY
    );
  }, [floorplan, viewport, area]);

  return (
    <div
      style={{
        position: 'absolute',
        top: labelOrigin.y,
        left: labelOrigin.x,
      }}
    >
      <div
        className={classNames(styles.AreaLabel, {
          [styles.available]: isAvailable,
        })}
        style={{ position: 'absolute', bottom: 0, left: 0 }}
      >
        {area.name}
      </div>
    </div>
  );
};

const AvailabilityOverlay: React.FunctionComponent<{
  plan: PlanDetail;
  availability: ReadonlyMap<string, boolean>;
  highlightedArea: string | null;
  targets: Array<FloorplanTargetInfo>;
  showAllAreaLabels: boolean;
  showTracks: boolean;
  viewport: Viewport;
}> = ({
  plan,
  availability,
  highlightedArea,
  targets,
  showAllAreaLabels,
  showTracks,
  viewport,
}) => {
  // TODO: maybe for later...
  // const colorBySensorSerial = useMemo(() => {
  //   return d3.scaleOrdinal(d3.schemeCategory10);
  // }, []);

  const ellipticalOACoverageEnabled = useTreatment(
    SPLITS.ELLIPTICAL_OA_COVERAGE
  );

  const floorplan: Floorplan = React.useMemo(() => {
    return {
      width: plan.image_width_pixels,
      height: plan.image_height_pixels,
      scale: plan.image_pixels_per_meter,
      origin: {
        type: 'image-coordinates',
        x: plan.origin_x_pixels,
        y: plan.origin_y_pixels,
      },
    };
  }, [plan]);

  const sensorGraphics = React.useMemo(() => {
    // TODO: why is this possible?
    if (Number.isNaN(viewport.top)) {
      return;
    }

    return plan.plan_sensors
      .filter((sensor) => sensor.sensor_type === 'oa')
      .map((sensor) => {
        const floorplanPosition = FloorplanCoordinates.create(
          sensor.centroid_from_origin_x_meters,
          sensor.centroid_from_origin_y_meters
        );
        const position = FloorplanCoordinates.toViewportCoordinates(
          floorplanPosition,
          floorplan,
          viewport
        );

        if (ellipticalOACoverageEnabled) {
          const [majorMeters, minorMeters] = computeCoverageMajorMinorAxisOA(
            sensor.height_meters
          );
          const majorPixels =
            majorMeters * plan.image_pixels_per_meter * viewport.zoom;
          const minorPixels =
            minorMeters * plan.image_pixels_per_meter * viewport.zoom;
          return (
            <ellipse
              key={sensor.id}
              cx={position.x}
              cy={position.y}
              rx={minorPixels}
              ry={majorPixels}
              fill="transparent"
              stroke={colors.midnightOpaque40}
              strokeDasharray="4 4"
              transform={`rotate(${sensor.rotation}deg)`}
            />
          );
        } else {
          const radiusMeters = computeCoverageRadiusOA(sensor.height_meters);
          const radiusPixels =
            radiusMeters * plan.image_pixels_per_meter * viewport.zoom;
          return (
            <circle
              key={sensor.id}
              cx={position.x}
              cy={position.y}
              r={radiusPixels}
              fill="transparent"
              stroke={colors.midnightOpaque40}
              strokeDasharray="4 4"
            />
          );
        }
      });
  }, [plan, floorplan, viewport, ellipticalOACoverageEnabled]);

  const spaceGraphics = React.useMemo(() => {
    return plan.areas.map((area) => {
      const space = areaToSpace(area);

      const isAvailableRaw = availability.get(area.id);
      const isAvailable =
        typeof isAvailableRaw === 'undefined' ? true : isAvailableRaw;

      const isHighlighted = Boolean(
        highlightedArea && area.id === highlightedArea
      );

      return (
        <SpaceGraphic
          key={area.id}
          area={area}
          space={space}
          floorplan={floorplan}
          viewport={viewport}
          isAvailable={isAvailable}
          isHighlighted={isHighlighted}
        />
      );
    });
  }, [plan, floorplan, viewport, availability, highlightedArea]);

  const areaLabels = React.useMemo(() => {
    if (!showAllAreaLabels && !highlightedArea) return null;

    return plan.areas.map((area) => {
      if (!showAllAreaLabels && highlightedArea !== area.id) return null;

      const isAvailableRaw = availability.get(area.id);
      const isAvailable =
        typeof isAvailableRaw === 'undefined' ? true : isAvailableRaw;
      return (
        <AreaLabel
          key={area.id}
          floorplan={floorplan}
          viewport={viewport}
          area={area}
          isAvailable={isAvailable}
        />
      );
    });
  }, [
    floorplan,
    viewport,
    plan,
    availability,
    showAllAreaLabels,
    highlightedArea,
  ]);

  return (
    <React.Fragment>
      <svg
        width={viewport.width}
        height={viewport.height}
        viewBox={`0 0 ${viewport.width} ${viewport.height}`}
        style={{ position: 'absolute' }}
      >
        <g>{sensorGraphics}</g>
        <g>{spaceGraphics}</g>
      </svg>

      {areaLabels}

      {showTracks ? (
        <TrackVisualizer
          plan={plan}
          floorplan={floorplan}
          viewport={viewport}
          targets={targets}
        />
      ) : null}
    </React.Fragment>
  );
};

export default FloorAvailability;
