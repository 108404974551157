import * as React from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  NewSpaceFunction,
  spaceFunctionToNewSpaceFunction,
  SPACE_FUNCTION_ICONS,
} from 'lib/space-functions';
import { truthy } from 'lib/truthy';
import { ReactComponent as CopyIcon } from 'img/icon-copy.svg';
import { ReactComponent as DangerWarningIcon } from 'img/icon-danger-warning.svg';
import { useUpdate } from 'hooks/use-update';
import FunctionDropdown, {
  valueToOption,
} from 'components/function-dropdown/function-dropdown';
import InlineInput from 'components/inline-input/inline-input';
import { pluralize } from 'lib/text';
import { useRifm } from 'rifm';
import { selectLabelOptions } from 'redux/features/spaces/select-label-options';
import LabelEditor, {
  diffLabels,
  ReadonlyLabels,
  SelectOptionType,
} from 'components/label-editor/label-editor';
import { asyncBulkUpdateSpaceThunk } from 'redux/features/spaces/async-bulk-update-space-thunk';
import { asyncBulkSaveSpaceLabelsThunk } from 'redux/features/spaces/async-bulk-save-space-labels-thunk';
import { PlanDetail } from 'lib/api';
import { selectSpaces } from 'redux/features/spaces/select-spaces';
import { CoreSpace } from '@densityco/lib-api-types';
import { Floor } from 'lib/floors';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const Section = styled.section`
  padding: ${dust.Space4};
  border-bottom: 1px solid ${dust.Gray200};
`;

const Header: React.FC<{ spaces: CoreSpace[] }> = (props) => {
  const allSameFunction = React.useMemo(() => {
    const functionSet = new Set(
      props.spaces.map((space) =>
        space.function ? spaceFunctionToNewSpaceFunction(space.function) : null
      )
    );

    return functionSet.size === 1;
  }, [props.spaces]);

  const Icon =
    allSameFunction && props.spaces[0].function
      ? SPACE_FUNCTION_ICONS[
          spaceFunctionToNewSpaceFunction(props.spaces[0].function)
        ]
      : CopyIcon;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 8px 8px 8px 12px;
        gap: ${dust.Space3};
        height: 2.5rem;
        border-bottom: 1px solid ${dust.Gray200};
        flex-shrink: 0px;
      `}
    >
      <div
        css={css`
          margin-left: -${dust.Space2};
          color: ${dust.Blue400};
          transform: translateY(2px);
        `}
      >
        <Icon width={24} height={24} color="currentColor" />
      </div>

      <span
        css={css`
          width: 100%;
          font-size: ${dust.TextScale4};
          font-weight: ${dust.FontWeightBold};
          margin-top: 2px;
          // https://css-tricks.com/almanac/properties/l/line-clamp/
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `}
      >
        {props.spaces.length} {pluralize('space', props.spaces.length)}
      </span>
    </div>
  );
};

const FunctionAndCapacityEditSection: React.FC<{
  spaces: CoreSpace[];
  floor: Floor;
  planDetail: PlanDetail;
}> = ({ spaces, floor, planDetail }) => {
  const [key, update] = useUpdate();

  const dispatch = useAppDispatch();

  const spacesMissingFunctions = React.useMemo(() => {
    return spaces.filter((space) => !space.function);
  }, [spaces]);

  const spacesMissingCapacity = React.useMemo(() => {
    return spaces.filter((space) => !space.capacity);
  }, [spaces]);

  const allSameFunction = React.useMemo(() => {
    const functionSet = new Set(
      spaces.map((space) =>
        space.function ? spaceFunctionToNewSpaceFunction(space.function) : null
      )
    );

    return functionSet.size === 1 && !functionSet.has(null);
  }, [spaces]);

  const allSameCapacity = React.useMemo(() => {
    const functionSet = new Set(spaces.map((space) => space.capacity));

    return functionSet.size === 1;
  }, [spaces]);

  const functionPlaceholder = React.useMemo(() => {
    if (spacesMissingFunctions.length) {
      return {
        text: 'Missing functions',
        color: dust.Yellow400,
        background: dust.Yellow100,
      };
    }

    if (!allSameFunction) {
      return {
        text: 'Multiple functions',
        color: dust.Blue400,
        background: dust.Blue100,
      };
    }
  }, [allSameFunction, spacesMissingFunctions.length]);

  const functionValue = React.useMemo(() => {
    if (spacesMissingFunctions.length || !allSameFunction) {
      return;
    }

    return spaces[0].function
      ? spaceFunctionToNewSpaceFunction(spaces[0].function)
      : undefined;
  }, [allSameFunction, spacesMissingFunctions.length, spaces]);

  const capacityPlaceholder = React.useMemo(() => {
    if (spacesMissingCapacity.length) {
      return {
        text: 'Missing',
        color: dust.Yellow400,
      };
    }

    if (!allSameCapacity) {
      return {
        text: 'Mixed',
        color: dust.Blue400,
      };
    }
  }, [allSameCapacity, spacesMissingCapacity.length]);

  const [capacity, setCapacity] = React.useState('');

  const rifm = useRifm({
    value: capacity,
    mask: true,
    onChange: setCapacity,
    format: (str: string) => {
      const r = parseInt(str.replace(/[^\d]+/gi, ''), 10);

      return r ? r.toString() : '';
    },
  });

  React.useEffect(() => {
    if (spacesMissingCapacity.length || !allSameCapacity) {
      setCapacity('');
      return;
    }

    setCapacity(`${spaces[0].capacity}`);
  }, [allSameCapacity, key, spacesMissingCapacity.length, spaces]);

  if (!planDetail) {
    return null;
  }

  return (
    <section
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid ${dust.Gray200};
        height: 32px;
        padding: 0 ${dust.Space4};
        flex-shrink: 0;
      `}
    >
      <FunctionDropdown
        key={`${key}-${functionValue}`}
        value={
          functionValue
            ? valueToOption(spaceFunctionToNewSpaceFunction(functionValue))
            : undefined
        }
        options={Object.values(NewSpaceFunction).map((f) =>
          valueToOption(f as NewSpaceFunction)
        )}
        onChange={(value) => {
          if (!value) {
            return;
          }

          dispatch(
            asyncBulkUpdateSpaceThunk({
              spaceIds: spaces.map((s) => s.id),
              body: {
                function: value.value,
              },
            })
          ).then(() => {
            update();
          });
        }}
        placeholder={functionPlaceholder ? functionPlaceholder.text : undefined}
        placeholderStyles={(provided, state) => {
          return {
            color: functionPlaceholder?.color || dust.Gray400,
          };
        }}
        controlStyles={(provided, state) => {
          return {
            background: state.menuIsOpen
              ? functionPlaceholder?.background || dust.Blue100
              : 'none',
            color: state.hasValue
              ? dust.Blue400
              : functionPlaceholder?.color || dust.Gray400,
          };
        }}
      />

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <span
          css={css`
            font-size: ${dust.TextScale2};
            color: ${dust.Gray400};
            font-weight: ${dust.FontWeightBold};
            margin-right: ${dust.Space3};
            transform: translateY(1px);
          `}
        >
          Capacity
        </span>

        <InlineInput
          inputStyles={css`
            height: 20px;
            width: 28px;
            font-size: ${dust.TextScale3};
            font-weight: ${dust.FontWeightMedium};
            margin-right: -10px;
            width: 43px;
          `}
          labelStyles={css`
            font-size: ${dust.TextScale3};
            font-weight: ${dust.FontWeightMedium};
            margin-top: 1px;
            color: ${capacityPlaceholder?.color};
          `}
          placeholder={capacityPlaceholder?.text}
          value={rifm.value}
          onChange={rifm.onChange}
          onBlur={() => {
            dispatch(
              asyncBulkUpdateSpaceThunk({
                spaceIds: spaces.map((s) => s.id),
                body: {
                  capacity: parseInt(capacity),
                },
              })
            ).then(() => {
              update();
            });
          }}
        />
      </div>
    </section>
  );
};

const MissingValuesCallToActionSection: React.FC<{ spaces: CoreSpace[] }> = ({
  spaces,
}) => {
  const spacesMissingFunctions = React.useMemo(() => {
    return spaces.filter((space) => !space.function);
  }, [spaces]);

  const spacesMissingCapacity = React.useMemo(() => {
    return spaces.filter((space) => !space.capacity);
  }, [spaces]);

  const incompleteSpacesCount = React.useMemo(() => {
    const set = new Set([
      ...spacesMissingFunctions.map((s) => s.id),
      ...spacesMissingCapacity.map((s) => s.id),
    ]);

    return set.size;
  }, [spacesMissingCapacity, spacesMissingFunctions]);

  if (!incompleteSpacesCount) {
    return null;
  }

  return (
    <Section
      css={css`
        background-color: ${dust.Gray000};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <div
          css={css`
            border-radius: 50%;
            background-color: ${dust.Yellow400};
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
          `}
        >
          <DangerWarningIcon height={40} width={40} color={dust.Yellow700} />
        </div>

        <span
          css={css`
            font-weight: ${dust.FontWeightMedium};
            font-size: ${dust.TextScale3};
            margin-left: 16px;
          `}
        >
          <span
            css={css`
              color: ${dust.Blue400};
            `}
          >
            {incompleteSpacesCount}
          </span>

          <span>{` ${pluralize(
            'space',
            incompleteSpacesCount
          )} in your selection need${
            incompleteSpacesCount === 1 ? 's' : ''
          } `}</span>

          {!!spacesMissingCapacity.length ? (
            <span
              css={css`
                color: ${dust.Blue400};
              `}
            >
              capacity
            </span>
          ) : null}

          {!!spacesMissingCapacity.length && !!spacesMissingFunctions.length ? (
            <span> or </span>
          ) : null}

          {!!spacesMissingFunctions.length ? (
            <span
              css={css`
                color: ${dust.Blue400};
              `}
            >
              function
            </span>
          ) : null}

          <span> info.</span>
        </span>
      </div>
    </Section>
  );
};

const LabelSection: React.FC<{ spaces: CoreSpace[] }> = ({ spaces }) => {
  const [key, update] = useUpdate();

  const dispatch = useAppDispatch();

  const labelOptions = useAppSelector(selectLabelOptions);

  const [labelValues, setLabelValues] = React.useState<ReadonlyLabels>([]);
  const [initialLabelValues, setInitialLabelValues] =
    React.useState<ReadonlyLabels>([]);

  React.useEffect(() => {
    const union = new Set<string>();

    spaces.forEach((space) => {
      space.labels.forEach((label) => {
        union.add(label.id);
      });
    });

    let intersection = new Set<string>(Array.from(union));

    spaces.forEach((space) => {
      const curr = new Set<string>(space.labels.map((label) => label.id));

      intersection = new Set(
        Array.from(intersection).filter((id) => curr.has(id))
      );
    });

    const labelValues: ReadonlyLabels = Array.from(union)
      .map((id) => {
        const option = labelOptions.find((option) => option.value === id);
        if (!option) {
          return null;
        }

        if (!intersection.has(id)) {
          const value: SelectOptionType = { ...option, clickToAdd: true };
          return value;
        }

        return option;
      })
      .filter(truthy);

    setLabelValues(labelValues);
    setInitialLabelValues(labelValues);
  }, [key, labelOptions, spaces]);

  return (
    <Section>
      <span
        css={css`
          font-size: ${dust.TextScale4};
          color: ${dust.Gray400};
        `}
      >
        Labels
      </span>

      <div
        css={css`
          margin-top: ${dust.Space3};
        `}
      >
        <LabelEditor
          value={labelValues}
          options={labelOptions}
          onChange={(value) => {
            setLabelValues(value);
          }}
          onBlur={() => {
            const diff = diffLabels(initialLabelValues, labelValues);

            // add to all spaces
            const addedLabelIds = diff.creates
              .filter((l) => !l.__isNew__)
              .map((l) => l.value);

            // create and add to all spaces
            const createdLabelNames = diff.creates
              .filter((l) => l.__isNew__)
              .map((l) => l.label);

            // the labels that need to be added to or removed from specific spaces
            const removeMap: { [spaceId: string]: Set<string> } = {};
            const addMap: { [spaceId: string]: Set<string> } = {};

            initialLabelValues.forEach((initialValue) => {
              const newValue = labelValues.find(
                (v) => v.value === initialValue.value
              );

              // label was removed from some spaces
              if (!newValue) {
                spaces.forEach((space) => {
                  removeMap[space.id] = removeMap[space.id] || new Set();

                  if (space.labels.some((l) => l.id === initialValue.value)) {
                    removeMap[space.id].add(initialValue.value);
                  }
                });
                return;
              }

              // label was added to some spaces
              if (initialValue.clickToAdd && !newValue.clickToAdd) {
                spaces.forEach((space) => {
                  addMap[space.id] = addMap[space.id] || new Set();

                  if (space.labels.every((l) => l.id !== newValue.value)) {
                    addMap[space.id].add(newValue.value);
                  }
                });
              }
            });

            dispatch(
              asyncBulkSaveSpaceLabelsThunk({
                spaceIds: spaces.map((s) => s.id),

                addedLabelIds,
                createdLabelNames,

                addMap,
                removeMap,
              })
            ).then(() => {
              update();
            });
          }}
        />
      </div>
    </Section>
  );
};

type BulkEditCardProps = {
  planDetail: PlanDetail;
  floor: Floor;
  areaIds: string[];
};

const BulkEditCard: React.FC<BulkEditCardProps> = (props) => {
  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );

  const areas = React.useMemo(() => {
    return props.planDetail.areas.filter((area) =>
      props.areaIds.some((aId) => aId === area.id)
    );
  }, [props.areaIds, props.planDetail.areas]);

  const spaceIds = React.useMemo(() => {
    const ids = areas.map((a) => a.space_id).filter(truthy);

    return ids;
  }, [areas]);

  const spaces = useAppSelector((state) => selectSpaces(state, { spaceIds }));

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 17.5rem;
        min-width: 17.5rem;
        max-height: ${heatmapEnabled ? 'calc(100% - 80px)' : '100%'};
        padding: 0;
        background-color: ${dust.White};
        border: 1px solid ${dust.Gray200};
        border-radius: ${dust.Radius300};
        box-shadow: ${dust.Elevation300};
        pointer-events: all;
      `}
    >
      <Header spaces={spaces} />

      <Content>
        <FunctionAndCapacityEditSection
          spaces={spaces}
          floor={props.floor}
          planDetail={props.planDetail}
        />

        <MissingValuesCallToActionSection spaces={spaces} />

        <LabelSection spaces={spaces} />
      </Content>
    </div>
  );
};

export default React.memo(BulkEditCard);
