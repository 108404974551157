import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar } from '@densityco/ui';
import {
  Impersonate,
  ImpersonateData,
  showOpenArea,
  showPortfolio,
  showAdminMenu,
  showDevTools,
  showOrganizationSettings,
  showSensorList,
  EnvironmentSwitcher,
} from '@densityco/lib-common-auth';

import { useAppSelector } from 'redux/store';

// This component fetches all the stuff about path and whatnot
// that the navbar component will need
export default function NavbarWrapper() {
  const history = useHistory();

  const user = useAppSelector((state) => state.user);
  const tokenCheckResponse = useAppSelector(
    (state) => state.auth.tokenCheckResponse
  );

  const userData = user.data;

  const onChangeImpersonatedUser = useCallback(
    (data: ImpersonateData) => {
      history.push('/');
      history.go(0);
    },
    [history]
  );

  return (
    <Navbar
      path={window.location.toString()}
      environmentSwitcher={
        <EnvironmentSwitcher
          enabled={
            tokenCheckResponse?.permissions.includes('impersonate') || false
          }
        />
      }
      impersonate={<Impersonate onChange={onChangeImpersonatedUser} />}
      onPrem={false}
      showOpenArea={showOpenArea(userData)}
      showPortfolio={showPortfolio(userData)}
      showAdminMenu={showAdminMenu(userData)}
      showDevTools={showDevTools(userData)}
      showOrganizationSettings={showOrganizationSettings(userData)}
      showSensorList={showSensorList(userData)}
    />
  );
}
