import moment from 'moment-timezone';
import { scaleLinear } from '@visx/scale';

export type UtilizationChartDataPoint = {
  time: string;
  value: number;
};
export type UtilizationChartData = Array<UtilizationChartDataPoint>;

type InputDatam = {
  date: string;
  min: number;
  max: number;
  avg: number;
};

type GroupedSpaceData = Array<{ time: string; values: [number] }>;

export function aggregateData(
  data: InputDatam[],
  timeZone: string,
  metric: 'max' | 'avg',
  aggregateBy: 'day' | 'hour'
) {
  const groupedData = data.reduce((acc, next) => {
    const nextMoment = moment.tz(next.date, timeZone);

    // Look for a bucket with the same "aggregation index"
    const existing = acc.find((x) =>
      aggregateBy === 'day'
        ? moment.tz(x.time, timeZone).diff(nextMoment, 'days') % 7 === 0
        : moment.tz(x.time, timeZone).hours() === nextMoment.hours()
    );

    // Add this metric to the existing bar, or make a new one
    if (existing) {
      existing.values.push(next[metric]);
    } else {
      acc.push({ time: next.date, values: [next[metric]] });
    }

    return acc;
  }, [] as GroupedSpaceData);

  // Aggregate the data for each bar, and return
  return groupedData.map((bar) => {
    const aggregatedValue =
      metric === 'avg'
        ? bar.values.reduce((a, n) => a + n, 0) / bar.values.length
        : Math.max(...bar.values);

    return {
      time: bar.time,
      value: aggregatedValue,
    };
  });
}

export function preprocessUtilizationChartValues(
  data: UtilizationChartData,
  chartHeight: number,
  headroom = 0
) {
  let maxValue = -Infinity;
  let maxIndices: Array<number> = [];
  let firstMaxIndex = -1;

  // Find all values that share the maxValue, and the first index of same
  data.forEach((d, i) => {
    if (d.value > maxValue) {
      maxValue = d.value;
      maxIndices = [i];
      firstMaxIndex = i;
    } else if (d.value === maxValue) {
      maxIndices.push(i);
    }
  });

  const yScale = scaleLinear<number>({
    range: [0, chartHeight],
    round: true,
    domain: [0, maxValue + maxValue * headroom],
  });

  return {
    yScale,
    maxIndices,
    firstMaxIndex,
  };
}

export function getUtilizationChartSummaryDay(
  data: UtilizationChartData,
  timeZone: string
) {
  let maxValue = -Infinity;
  let maxIndex = -1;

  data.forEach((d, i) => {
    if (d.value > maxValue) {
      maxValue = d.value;
      maxIndex = i;
    }
  });

  const maxDatum = data[maxIndex];

  const maxTime = moment.tz(maxDatum.time, timeZone).format('dddd');

  return maxTime;
}

export function getUtilizationChartSummaryTime(
  data: UtilizationChartData,
  timeZone: string
) {
  let maxValue = -Infinity;
  let maxIndex = -1;

  data.forEach((d, i) => {
    if (d.value > maxValue) {
      maxValue = d.value;
      maxIndex = i;
    }
  });

  const maxDatum = data[maxIndex];

  const maxTimeStart = moment.tz(maxDatum.time, timeZone).format('ha');
  const maxTimeEnd = moment
    .tz(maxDatum.time, timeZone)
    .add(1, 'h')
    .format('ha');

  return `${maxTimeStart} - ${maxTimeEnd}`;
}

// Duplicate function, but for preprocessing "time used by day"
// In this chart the values are in units of time - maybe significant
// But maybe these could both be abstracted into "preprocessTimeseriesChartValues"
export function preprocessTimeUsedByDayChartValues(
  data: Array<{ time: string; value: number }>,
  chartHeight: number,
  headroom = 0
) {
  let maxValue = -Infinity;
  let maxIndices: Array<number> = [];
  let firstMaxIndex = -1;

  // Find all values that share the maxValue, and the first index of same
  data.forEach((d, i) => {
    if (d.value > maxValue) {
      maxValue = d.value;
      maxIndices = [i];
      firstMaxIndex = i;
    } else if (d.value === maxValue) {
      maxIndices.push(i);
    }
  });

  const yScale = scaleLinear<number>({
    range: [0, chartHeight],
    round: true,
    domain: [0, maxValue + maxValue * headroom],
  });

  return {
    yScale,
    maxIndices,
    firstMaxIndex,
  };
}

type ChartAvailabilityByDayRow = {
  hour: string;
  available: boolean;
};
export type ChartAvailabilityByDayColumn = {
  date: string;
  rows: Array<ChartAvailabilityByDayRow>;
};

// TODO: Should utilizationByHour be a defined type that is also the response type from `aggregateUtilization`?
export function utilizationByHourToChartAvailabilityByDayData(
  utilizationByHour: Array<{
    time: string;
    value: number;
  }>,
  timeZone: string
): Array<ChartAvailabilityByDayColumn> {
  const output: Array<ChartAvailabilityByDayColumn> = [];

  utilizationByHour.forEach((item) => {
    const date = moment.tz(item.time, timeZone).format('YYYY-MM-DD');
    const hour = moment.tz(item.time, timeZone).format('HH:mm');
    const column = output.find((x) => x.date === date);

    if (column) {
      column.rows.push({
        hour: hour,
        available: item.value === 0,
      });
    } else {
      output.push({
        date,
        rows: [
          {
            hour,
            available: item.value === 0,
          },
        ],
      });
    }
  });

  return output;
}
