import { Fragment, useMemo } from 'react';
import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import moment from 'moment-timezone';
import { Blue400, Gray100 } from '@density/dust/dist/tokens/dust.tokens';

import { preprocessUtilizationChartValues } from 'lib/charts';

const LABEL_SPACING = 6;
const LABEL_HEIGHT = 12;
const BAR_SPACING = 4;

export type BarsProps = {
  width: number;
  height: number;
  data: Array<{ time: string; value: number }>;
  timeZone: string;
  events?: boolean;
};

export default function ChartBusiestTime({
  data,
  width,
  height,
  timeZone,
  events = false,
}: BarsProps) {
  const totalSpacing = BAR_SPACING * (data.length - 1);
  const chartHeight = height - LABEL_HEIGHT - LABEL_SPACING;
  const barWidth = (width - totalSpacing) / (data.length + 1);

  const { yScale, maxIndices, firstMaxIndex } = useMemo(
    () => preprocessUtilizationChartValues(data, chartHeight),
    [data, chartHeight]
  );

  return (
    <svg
      width={width}
      height={height}
      style={{
        overflow: 'visible',
      }}
    >
      <Group top={0}>
        {data.map((d, i) => {
          const isMax = maxIndices.includes(i);
          const barHeight = yScale(d.value) ?? 0;
          const barX = i * (barWidth + BAR_SPACING);
          const barY = chartHeight - barHeight;

          return (
            <Fragment key={`bar-${d.time}`}>
              <BarRounded
                top
                radius={2}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill={isMax ? Blue400 : Gray100}
                onClick={() => {
                  if (events) {
                    alert(`clicked: ${JSON.stringify(Object.values(d))}`);
                  }
                }}
              />
              {i === firstMaxIndex ? (
                <Group
                  top={height - LABEL_SPACING}
                  left={barX + barWidth / 2}
                  textAnchor="middle"
                >
                  <text fontSize={10} fontWeight={600} fill={Blue400}>
                    {moment.tz(d.time, timeZone).format('ha').slice(0, -1)}
                  </text>
                </Group>
              ) : null}
            </Fragment>
          );
        })}
      </Group>
    </svg>
  );
}
