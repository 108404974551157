import { useCallback } from 'react';
import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { Action } from './actions';
import { PhotoGroup, State } from './state';

import { FloorplanCoordinates } from 'lib/geometry';

const DIAMETER_PX = 28;
const BADGE_OFFSET_X_PX = (DIAMETER_PX / 2) * Math.cos(-Math.PI / 4);
const BADGE_OFFSET_Y_PX = (DIAMETER_PX / 2) * Math.sin(-Math.PI / 4);

const FOCUSED_DIAMETER_PX = 32;
const FOCUSED_BADGE_OFFSET_X_PX =
  (FOCUSED_DIAMETER_PX / 2) * Math.cos(-Math.PI / 4);
const FOCUSED_BADGE_OFFSET_Y_PX = (DIAMETER_PX / 2) * Math.sin(-Math.PI / 4);

const FOCUSED_SHADOW_WIDTH_PX = 5;

const PhotoGroupGraphic: React.FunctionComponent<{
  photoGroup: PhotoGroup;
  state: State;
  cursor: string;
  dispatch: React.Dispatch<Action>;
}> = ({ photoGroup, state, cursor, dispatch }) => {
  const positionViewportCoords = FloorplanCoordinates.toViewportCoordinates(
    photoGroup.position,
    state.floorplan,
    state.viewport
  );
  const isHighlighted = State.isPhotoGroupHighlighted(state, photoGroup.id);
  const isFocused = State.isPhotoGroupFocused(state, photoGroup.id);
  const isAnyPhotoGroupFocused = state.focusedPhotoGroupId !== null;

  const onMouseDown = useCallback(
    (evt: React.MouseEvent<SVGRectElement>) => {
      evt.stopPropagation();

      dispatch({
        type: 'item.graphic.mousedown',
        itemType: 'photogroup',
        itemId: photoGroup.id,
        itemPosition: positionViewportCoords,
        clientX: evt.clientX,
        clientY: evt.clientY,
      });
    },
    [photoGroup, positionViewportCoords, dispatch]
  );

  return (
    <g
      key={photoGroup.id}
      data-cy={`photo-group-graphic-${photoGroup.id}`}
      transform={`translate(${positionViewportCoords.x},${positionViewportCoords.y})`}
      onMouseDown={onMouseDown}
      onMouseEnter={() => {
        dispatch({
          type: 'item.graphic.mouseenter',
          itemType: 'photogroup',
          itemId: photoGroup.id,
        });
      }}
      onMouseLeave={() => {
        dispatch({
          type: 'item.graphic.mouseleave',
          itemType: 'photogroup',
          itemId: photoGroup.id,
        });
      }}
      style={{ cursor }}
    >
      {/* Secondary "shadow" circle */}
      {isFocused || isHighlighted ? (
        <circle
          r={
            (isFocused ? FOCUSED_DIAMETER_PX : DIAMETER_PX) / 2 +
            FOCUSED_SHADOW_WIDTH_PX
          }
          cx={0}
          cy={0}
          fill={colors.yellow400}
          opacity={0.4}
        />
      ) : null}

      {/* Main yellow circle */}
      <circle
        r={(isFocused ? FOCUSED_DIAMETER_PX : DIAMETER_PX) / 2}
        cx={0}
        cy={0}
        fill={
          isAnyPhotoGroupFocused
            ? isFocused
              ? colors.yellow
              : colors.gray400
            : colors.yellow
        }
        style={{ transition: 'fill 150ms ease-in-out' }}
        stroke="#fff"
        strokeWidth={2}
      />

      {/* Image Group Icon */}
      <g
        transform={
          isFocused ? 'scale(1.3) translate(-8,-8)' : 'translate(-8,-8)'
        }
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7 2l1.333 1.333h6.334V14H1.333V2H7zm6.333 2.667v8H2.667v-8h10.666z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M3.78 10.018l1.672-2.512 1.876 1.409L9.916 6l2.323 3.925.428.742H3.333l.446-.65z"
        ></path>
      </g>

      {/* Badge */}
      <g
        transform={
          isFocused
            ? `translate(${FOCUSED_BADGE_OFFSET_X_PX},${FOCUSED_BADGE_OFFSET_Y_PX})`
            : `translate(${BADGE_OFFSET_X_PX},${BADGE_OFFSET_Y_PX})`
        }
      >
        <rect
          r={8}
          x={-8}
          y={-8}
          width={16}
          height={16}
          rx={8}
          ry={8}
          fill={photoGroup.photos.length > 0 ? colors.gray900 : colors.red}
        />
        <text
          fill="#fff"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontWeight="bold"
          fontSize={12}
          style={{ userSelect: 'none' }}
        >
          {photoGroup.photos.length}
        </text>
      </g>
    </g>
  );
};

export default PhotoGroupGraphic;
