import { useEffect, useState } from 'react';

// "Size" types are just a width and a height, for convenience
type Size = {
  width: number;
  height: number;
  top: number;
  left: number;
};

type WindowSize = {
  windowWidth: number;
  windowHeight: number;
};

// Some helpers to get sizes, otherwise we duplicate these
function getRefSize(ref: React.RefObject<HTMLElement>): Size {
  const rect = ref.current?.getBoundingClientRect();
  return rect
    ? { width: rect.width, height: rect.height, top: rect.top, left: rect.left }
    : { width: 0, height: 0, top: 0, left: 0 };
}
function getWindowSize(): WindowSize {
  return { windowWidth: window.innerWidth, windowHeight: window.innerHeight };
}

// Auto-size listener hook gives the current size of an element
// NOTE: This update only fires when the *window* is resized, not on any change.
// See the "triggerResize" helper for a manual way to fire the "resize" event.
export function useAutoSize(
  ref: React.RefObject<HTMLElement>,
  debounce: number = 0,
  initialSize: Size = { width: 0, height: 0, top: 0, left: 0 }
) {
  const [size, setSize] = useState<Size>(initialSize);
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const onResize = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (!debounce) {
        return setSize(getRefSize(ref));
      }
      timeout = setTimeout(() => setSize(getRefSize(ref)), debounce);
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, [ref, debounce]);
  return size;
}

// Auto-window-size listener hook gives the current size of the browser window
export function useAutoWindowSize(
  debounce: number = 0,
  initialSize: WindowSize = { windowWidth: 0, windowHeight: 0 }
) {
  const [windowSize, setWindowSize] = useState<WindowSize>(initialSize);
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const onResize = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (!debounce) {
        return setWindowSize(getWindowSize());
      }
      timeout = setTimeout(() => setWindowSize(getWindowSize()), debounce);
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, [debounce]);
  return windowSize;
}
