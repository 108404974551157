export type PlanViewId = 'planning' | 'analysis' | 'availability';

export interface PlanViewDescriptor {
  id: PlanViewId;
  label: string;
  path: string;
}

export const PLAN_VIEW_PLANNING: PlanViewDescriptor = {
  id: 'planning',
  label: 'Planning',
  path: 'planning',
};
export const PLAN_VIEW_AVAILABILITY: PlanViewDescriptor = {
  id: 'availability',
  label: 'Availability',
  path: 'availability',
};
export const PLAN_VIEW_ANALYSIS: PlanViewDescriptor = {
  id: 'analysis',
  label: 'Analysis',
  path: 'analysis',
};

export const planViewListOrder = [
  PLAN_VIEW_PLANNING,
  PLAN_VIEW_AVAILABILITY,
  PLAN_VIEW_ANALYSIS,
];
