import { ImageCoordinates } from 'lib/geometry';
import { PlanDetail } from './api';

export type Floorplan = {
  width: number;
  height: number;
  origin: ImageCoordinates;
  scale: number;
};

export function getFloorplanFromPlan(plan: PlanDetail) {
  const floorplan: Floorplan = {
    width: plan.image_width_pixels,
    height: plan.image_height_pixels,
    scale: plan.image_pixels_per_meter,
    origin: ImageCoordinates.create(plan.origin_x_pixels, plan.origin_y_pixels),
  };

  return floorplan;
}
