import { useEffect, useRef, useMemo } from 'react';
import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import { FloorplanCoordinates } from 'lib/geometry';
import { degreesToRadians } from 'lib/math';
import { displayLength } from 'lib/units';
import {
  computeCoverageRadiusOA,
  computeCoverageMajorMinorAxisOA,
  computeCoverageRadiusEntry,
} from 'lib/sensor';

import { State, Sensor, getSensorStatusColor } from 'components/editor/state';

import {
  ObjectLayer,
  MetricLabel,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  toRawHex,
} from 'components/floorplan';

import {
  Blue400,
  Purple400,
  Green400,
  Green700,
  Gray500,
  Gray900,
  White,
} from '@density/dust/dist/tokens/dust.tokens';

import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';

const SENSOR_FOCUSED_OUTLINE_WIDTH_PX = 4;
const SENSOR_STATUS_INDICATOR_SIZE_PX = 10;
const SENSOR_STATUS_INDICATOR_OUTLINE_PX = 3;
const getSensorColor = (sensor: Sensor) =>
  sensor.type === 'oa' ? toRawHex(Blue400) : toRawHex(Purple400);

const SENSOR_COVERAGE_INTERSECTION_POINT_RADIUS_PX = 5;

// The sensors layer renders a list of sensors, both oa and entry, to the floorplan.
const SensorsLayer: React.FunctionComponent<{
  sensors: Array<Sensor>;
  highlightedObject: {
    type: 'sensor' | 'space' | 'photogroup' | 'reference';
    id: string;
  } | null;
  focusedObject: null | {
    type: 'sensor' | 'space' | 'layer';
    id: string;
  };
  heightMap: State['heightMap'];
  coverageIntersectionEnabled: boolean;
  coverageIntersectionVectors: State['sensorCoverageIntersectionVectors'];
  hideOpenAreaCoverage?: boolean;
  hideSensorLabels?: boolean;
  onMouseEnter: (sensor: Sensor, event: FederatedPointerEvent) => void;
  onMouseLeave: (sensor: Sensor, event: FederatedPointerEvent) => void;
  onMouseDown: (sensor: Sensor, event: FederatedPointerEvent) => void;
  onDragMove: (sensor: Sensor, newCoordinates: FloorplanCoordinates) => void;
  onDragEnd: (sensor: Sensor) => void;
}> = ({
  sensors,
  highlightedObject,
  focusedObject,
  heightMap,
  coverageIntersectionEnabled,
  coverageIntersectionVectors,
  hideOpenAreaCoverage = false,
  hideSensorLabels = false,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onDragMove,
  onDragEnd,
}) => {
  const context = useFloorplanLayerContext();

  const ellipticalOACoverageEnabled = useTreatment(
    SPLITS.ELLIPTICAL_OA_COVERAGE
  );

  const sensorMajorMinorInMeters = useMemo(() => {
    const sensorMajorMinorInMeters: { [sensorId: string]: [number, number] } =
      {};

    for (const sensor of sensors) {
      if (sensor.type === 'oa' && ellipticalOACoverageEnabled) {
        sensorMajorMinorInMeters[sensor.id] = computeCoverageMajorMinorAxisOA(
          sensor.height
        );
      } else if (sensor.type === 'oa' && !ellipticalOACoverageEnabled) {
        // NOTE: This is the old radius-based oa sensor coverage rendering, which is deprecated
        const radiusMeters = computeCoverageRadiusOA(sensor.height);
        sensorMajorMinorInMeters[sensor.id] = [radiusMeters, radiusMeters];
      } else {
        const radiusMeters = computeCoverageRadiusEntry(sensor.height);
        sensorMajorMinorInMeters[sensor.id] = [radiusMeters, radiusMeters];
      }
    }

    return sensorMajorMinorInMeters;
  }, [sensors, ellipticalOACoverageEnabled]);

  // When a sensor is focused, add serial number and sensor labels.
  const focusedSensor =
    focusedObject && focusedObject.type === 'sensor'
      ? sensors.find((sensor) => sensor.id === focusedObject.id)
      : null;
  useEffect(() => {
    if (!focusedSensor) {
      return;
    }

    const sensorLabelGroup = new PIXI.Container();
    sensorLabelGroup.name = 'sensor-label-group';

    let radiusLabel: MetricLabel | null = null;
    if (focusedSensor.type === 'oa' && !ellipticalOACoverageEnabled) {
      // NOTE: This is only shown for the old radius-based oa sensor coverage rendering, which is deprecated
      const displayRadius = displayLength(
        computeCoverageRadiusOA(focusedSensor.height),
        context.lengthUnit
      );
      radiusLabel = new MetricLabel(displayRadius);
      radiusLabel.name = 'coverage-radius-label';
      radiusLabel.renderable = false;
      sensorLabelGroup.addChild(radiusLabel);
    }

    let serialNumberLabel: MetricLabel | null = null;
    if (focusedSensor.serialNumber) {
      serialNumberLabel = new MetricLabel(focusedSensor.serialNumber, {
        backgroundColor: getSensorColor(focusedSensor),
      });
      serialNumberLabel.name = 'serial-number-label';
      serialNumberLabel.renderable = false;
      sensorLabelGroup.addChild(serialNumberLabel);
    }

    context.app.stage.addChild(sensorLabelGroup);

    return () => {
      context.app.stage.removeChild(sensorLabelGroup);
    };
  }, [context, focusedSensor, ellipticalOACoverageEnabled]);

  // Convert each sensor's set of coverage intersection vectors into cartesian coordinates
  const coverageIntersectionPoints = useMemo(() => {
    const result = new Map<
      Sensor['id'],
      {
        perimeter: Array<[number, number]>;
        obstructions: Array<[number, number]>;
      }
    >();

    if (!coverageIntersectionEnabled) {
      return result;
    }

    for (const [sensorId, coverageIntersectionsForSensor] of Array.from(
      coverageIntersectionVectors
    )) {
      if (
        coverageIntersectionsForSensor === 'loading' ||
        coverageIntersectionsForSensor === 'empty'
      ) {
        continue;
      }

      const sensor = sensors.find((sensor) => sensor.id === sensorId);
      if (!sensor) {
        continue;
      }

      const perimeter: Array<[number, number]> = [];
      const obstructions: Array<[number, number]> = [];
      for (const [
        theta,
        magnitudesInMeters,
      ] of coverageIntersectionsForSensor) {
        for (let index = 0; index < magnitudesInMeters.length; index += 1) {
          const magnitudeInMeters = magnitudesInMeters[index];

          if (index === magnitudesInMeters.length - 1) {
            // The final intersection forms the perimeter of the coverage area!
            // NOTE: take into account the sensor rotation because the perimeter shape will be
            // rendered on the `coverage-area` graphics entity which is rotated. So "invert that
            // rotation".
            const x =
              magnitudeInMeters *
              Math.cos(degreesToRadians(theta - sensor.rotation));
            const y =
              magnitudeInMeters *
              Math.sin(degreesToRadians(theta - sensor.rotation));
            perimeter.push([x, y]);
          } else {
            const x = magnitudeInMeters * Math.cos(degreesToRadians(theta));
            const y = magnitudeInMeters * Math.sin(degreesToRadians(theta));
            obstructions.push([x, y]);
          }
        }
      }

      result.set(sensorId, { perimeter, obstructions });
    }

    return result;
  }, [coverageIntersectionEnabled, coverageIntersectionVectors, sensors]);

  const coverageIntersectionPointTexture = useMemo(() => {
    const gr = new PIXI.Graphics();
    gr.lineStyle({
      width: 1,
      color: toRawHex(Gray900),
      join: PIXI.LINE_JOIN.ROUND,
      alpha: 1,
    });

    // Draw white circle
    gr.beginFill(toRawHex(White), 1);
    gr.drawCircle(0, 0, SENSOR_COVERAGE_INTERSECTION_POINT_RADIUS_PX);
    gr.endFill();

    return context.app.renderer.generateTexture(gr);
  }, [context.app.renderer]);

  // When a focused sensor has coverage vectors, render a sprite at each coverage itnersection point
  // to indicate what blocked the sensor coverage
  const coverageIntersectionPointsForFocusedSensor = focusedSensor
    ? coverageIntersectionPoints.get(focusedSensor.id)
    : null;
  useEffect(() => {
    if (!focusedSensor) {
      return;
    }
    if (!coverageIntersectionPointsForFocusedSensor) {
      return;
    }

    const sensorCoverageIntersectionsGroup = new PIXI.Container();
    sensorCoverageIntersectionsGroup.name =
      'sensor-coverage-intersections-group';

    for (
      let i = 0;
      i < coverageIntersectionPointsForFocusedSensor.obstructions.length;
      i += 1
    ) {
      const sprite = new PIXI.Sprite(coverageIntersectionPointTexture);
      sprite.anchor.set(0.5, 0.5);
      sensorCoverageIntersectionsGroup.addChild(sprite);
    }

    context.app.stage.addChild(sensorCoverageIntersectionsGroup);

    return () => {
      context.app.stage.removeChild(sensorCoverageIntersectionsGroup);
    };
  }, [
    context,
    focusedSensor,
    coverageIntersectionEnabled,
    coverageIntersectionPointsForFocusedSensor,
    coverageIntersectionPointTexture,
  ]);

  const focusedSensorCoordinates = useRef<FloorplanCoordinates | null>(null);

  return (
    <ObjectLayer
      objects={sensors}
      extractId={(sensor) => sensor.id}
      onCreate={(getSensor) => {
        const sensorGraphic = new PIXI.Container();

        const coverageArea = new PIXI.Graphics();
        coverageArea.name = 'coverage-area';
        coverageArea.interactive = true;
        coverageArea.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }
          onMouseDown(getSensor(), evt);

          if (getSensor().locked) {
            return;
          }

          addDragHandler(
            context,
            getSensor().position,
            evt,
            (newPosition) => {
              focusedSensorCoordinates.current = newPosition;
            },
            () => {
              if (!focusedSensorCoordinates.current) {
                return;
              }
              onDragMove(getSensor(), focusedSensorCoordinates.current);
              onDragEnd(getSensor());
              focusedSensorCoordinates.current = null;
            }
          );
        });
        coverageArea.on('mouseover', (evt) => onMouseEnter(getSensor(), evt));
        coverageArea.on('mouseout', (evt) => onMouseLeave(getSensor(), evt));
        sensorGraphic.addChild(coverageArea);

        const focusedLabels = new PIXI.Graphics();
        focusedLabels.name = 'focused-labels';
        sensorGraphic.addChild(focusedLabels);

        return sensorGraphic;
      }}
      onUpdate={(sensor: Sensor, sensorGraphic) => {
        if (!context.viewport.current) {
          return;
        }

        const majorMinorMeters = sensorMajorMinorInMeters[sensor.id];
        if (!majorMinorMeters) {
          return;
        }
        const [majorMeters, minorMeters] = majorMinorMeters;
        const majorPixels =
          majorMeters * context.floorplan.scale * context.viewport.current.zoom;
        const minorPixels =
          minorMeters * context.floorplan.scale * context.viewport.current.zoom;

        const isFocused =
          focusedObject &&
          focusedObject.type === 'sensor' &&
          focusedObject.id === sensor.id;
        const isHighlighted =
          highlightedObject &&
          highlightedObject.type === 'sensor' &&
          highlightedObject.id === sensor.id;

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          isFocused && focusedSensorCoordinates.current
            ? focusedSensorCoordinates.current
            : sensor.position,
          context.floorplan,
          context.viewport.current
        );

        // Hide sensors that are not on the screen
        sensorGraphic.renderable = isWithinViewport(
          context,
          viewportCoords,
          -1 * majorPixels
        );
        if (!sensorGraphic.renderable) {
          return;
        }

        sensorGraphic.x = viewportCoords.x;
        sensorGraphic.y = viewportCoords.y;

        // Draw main sensor coverage area
        const coverageArea = sensorGraphic.getChildByName(
          'coverage-area'
        ) as PIXI.Graphics;
        coverageArea.cursor = sensor.locked ? 'pointer' : 'grab';
        coverageArea.angle = sensor.rotation;
        coverageArea.clear();
        coverageArea.beginFill(getSensorColor(sensor), 0.2);
        if (isFocused || isHighlighted) {
          coverageArea.lineStyle({
            width: 1,
            color: getSensorColor(sensor),
            join: PIXI.LINE_JOIN.ROUND,
          });
        }
        switch (sensor.type) {
          case 'oa':
            if (hideOpenAreaCoverage) {
              break;
            }
            // OA coverage area is a circle
            coverageArea.drawEllipse(0, 0, minorPixels, majorPixels);
            break;
          case 'entry':
            // Entry coverage area is a half-circle
            coverageArea.moveTo(0, 0);
            const startAngle = degreesToRadians(0);
            const endAngle = startAngle + Math.PI;
            coverageArea.arc(0, 0, majorPixels, startAngle, endAngle, true);
            coverageArea.lineTo(0, 0);
            break;
        }
        coverageArea.endFill();

        // Draw inset shadow used to indicate that the sensor is selected
        if (isFocused) {
          coverageArea.lineStyle({
            width: SENSOR_FOCUSED_OUTLINE_WIDTH_PX,
            color: getSensorColor(sensor),
            alpha: 0.2,
            join: PIXI.LINE_JOIN.ROUND,
            alignment: sensor.type === 'oa' ? 0 : 1,
          });
          if (sensor.type === 'oa') {
            coverageArea.drawEllipse(0, 0, minorPixels, majorPixels);
          } else {
            coverageArea.moveTo(0, 0);
            const startAngle = degreesToRadians(0);
            const endAngle = startAngle + Math.PI;
            coverageArea.arc(0, 0, majorPixels, startAngle, endAngle, true);
            coverageArea.lineTo(0, 0);
          }
        }

        // Draw center status indicator
        // FIXME: this should probably just be a sprite
        function drawStatus(graphics: PIXI.Graphics) {
          graphics.beginFill(toRawHex(getSensorStatusColor(sensor.status)));
          graphics.lineStyle({
            width: SENSOR_STATUS_INDICATOR_OUTLINE_PX,
            color: 0xffffff,
            join: PIXI.LINE_JOIN.ROUND,
          });
          if (sensor.type === 'oa') {
            // OA has a square status indicator
            graphics.drawRoundedRect(
              -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX / 2),
              -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX / 2),
              SENSOR_STATUS_INDICATOR_SIZE_PX,
              SENSOR_STATUS_INDICATOR_SIZE_PX,
              2
            );
          } else {
            // OA has a triangular status indicator
            graphics.moveTo(
              -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX * 0.75),
              SENSOR_STATUS_INDICATOR_SIZE_PX * 0.5
            );
            graphics.lineTo(0, -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX * 0.75));
            graphics.lineTo(
              SENSOR_STATUS_INDICATOR_SIZE_PX * 0.75,
              SENSOR_STATUS_INDICATOR_SIZE_PX * 0.5
            );
            graphics.lineTo(
              -1 * (SENSOR_STATUS_INDICATOR_SIZE_PX * 0.75),
              SENSOR_STATUS_INDICATOR_SIZE_PX * 0.5
            );
          }
          graphics.endFill();
        }

        const coverageIntersectionsForSensor = coverageIntersectionPoints.get(
          sensor.id
        );
        if (
          coverageIntersectionsForSensor &&
          coverageIntersectionsForSensor.perimeter.length > 0
        ) {
          const isGreen =
            isFocused || !(focusedObject && focusedObject.type === 'sensor');

          // Draw the polygon shape
          coverageArea.lineStyle({
            width: 1,
            color:
              (isFocused || isHighlighted) && hideOpenAreaCoverage
                ? toRawHex(isGreen ? Green700 : Gray900)
                : toRawHex(isGreen ? Green400 : Gray500),
            join: PIXI.LINE_JOIN.ROUND,
            alpha: 1,
          });
          coverageArea.beginFill(
            toRawHex(isGreen ? Green400 : Gray500),
            isGreen ? 0.5 : 0.2
          );

          let first: [number, number] | null = null;
          for (
            let index = 0;
            index < coverageIntersectionsForSensor.perimeter.length;
            index += 1
          ) {
            const [x, y] = coverageIntersectionsForSensor.perimeter[index];
            const xPixels =
              x * context.floorplan.scale * context.viewport.current.zoom;
            const yPixels =
              y * context.floorplan.scale * context.viewport.current.zoom;
            if (index === 0) {
              coverageArea.moveTo(xPixels, yPixels);
              first = [xPixels, yPixels];
            } else {
              coverageArea.lineTo(xPixels, yPixels);
            }
          }
          if (first) {
            coverageArea.lineTo(first[0], first[1]);
          }
          coverageArea.endFill();
        }

        // Draw extra bits and bobs that are visible when sensor is focused
        const focusedLabels = sensorGraphic.getChildByName(
          'focused-labels'
        ) as PIXI.Graphics;
        if (!isFocused) {
          focusedLabels.renderable = false;
          drawStatus(coverageArea);
          return;
        }

        focusedLabels.angle = sensor.rotation;
        focusedLabels.renderable = true;
        focusedLabels.clear();

        // A vertical line indicates 0 degrees
        //
        // Because the whole focusedLabels container is being rotated, rotate this line a
        // correlating negative amount so that it appears vertical
        focusedLabels.lineStyle({
          width: 1,
          color: getSensorColor(sensor),
          join: PIXI.LINE_JOIN.ROUND,
        });
        focusedLabels.moveTo(
          Math.cos(degreesToRadians(-1 * sensor.rotation - 90)) * majorPixels,
          Math.sin(degreesToRadians(-1 * sensor.rotation - 90)) * majorPixels
        );
        focusedLabels.lineTo(
          Math.cos(degreesToRadians(-1 * sensor.rotation + 90)) * majorPixels,
          Math.sin(degreesToRadians(-1 * sensor.rotation + 90)) * majorPixels
        );

        const arrowRadiusPx = majorPixels - SENSOR_FOCUSED_OUTLINE_WIDTH_PX;

        focusedLabels.moveTo(0, arrowRadiusPx);
        focusedLabels.lineTo(0, -1 * arrowRadiusPx);
        // Left side of the arrow
        focusedLabels.lineTo(
          Math.cos(degreesToRadians(135)) * 10,
          Math.sin(degreesToRadians(135)) * 10 - arrowRadiusPx
        );
        focusedLabels.moveTo(0, -1 * arrowRadiusPx);
        // Right side of the arrow
        focusedLabels.lineTo(
          Math.cos(degreesToRadians(45)) * 10,
          Math.sin(degreesToRadians(45)) * 10 - arrowRadiusPx
        );

        drawStatus(focusedLabels);

        // Update positions of focused labels
        // - The sensor label group contains all labels, and is itself rotatied.
        // - This allows all labels to be positioned assuming that the sensor is not rotated.
        // - Then, all sensor labels themselves (ie, the MetricLabel) are rotated the opposite
        //   direction so they appear inthe correct orientation
        //
        // To make what's going on more clear, try togging the rotation of each level and watching
        // what that results in within the layer.
        const sensorLabelGroup = context.app.stage.getChildByName(
          'sensor-label-group'
        ) as PIXI.Container | null;
        if (sensorLabelGroup) {
          sensorLabelGroup.x = viewportCoords.x;
          sensorLabelGroup.y = viewportCoords.y;
          sensorLabelGroup.angle = sensor.rotation;

          const serialNumberLabel = sensorLabelGroup.getChildByName(
            'serial-number-label'
          );
          if (serialNumberLabel) {
            if (hideSensorLabels) {
              serialNumberLabel.renderable = false;
            } else {
              serialNumberLabel.x =
                Math.cos(degreesToRadians(315 - sensor.rotation)) * minorPixels;
              serialNumberLabel.y =
                Math.sin(degreesToRadians(315 - sensor.rotation)) * majorPixels;
              serialNumberLabel.angle = -1 * sensor.rotation;
              serialNumberLabel.renderable = true;
            }
          }

          // NOTE: This is the old radius-based oa sensor coverage rendering, which is deprecated
          if (ellipticalOACoverageEnabled) {
            const coverageRadiusLabel = sensorLabelGroup.getChildByName(
              'coverage-radius-label'
            );
            if (coverageRadiusLabel) {
              if (hideSensorLabels) {
                coverageRadiusLabel.renderable = false;
              } else {
                coverageRadiusLabel.x =
                  Math.cos(degreesToRadians(225 - sensor.rotation)) *
                  minorPixels;
                coverageRadiusLabel.y =
                  Math.sin(degreesToRadians(225 - sensor.rotation)) *
                  majorPixels;
                coverageRadiusLabel.angle = -1 * sensor.rotation;
                coverageRadiusLabel.renderable = true;
              }
            }
          }
        }

        const sensorCoverageIntersectionsGroup =
          context.app.stage.getChildByName(
            'sensor-coverage-intersections-group'
          ) as PIXI.Container | null;
        if (
          coverageIntersectionsForSensor &&
          sensorCoverageIntersectionsGroup
        ) {
          for (
            let index = 0;
            index < coverageIntersectionsForSensor.obstructions.length;
            index += 1
          ) {
            const [x, y] = coverageIntersectionsForSensor.obstructions[index];
            const position = FloorplanCoordinates.toViewportCoordinates(
              FloorplanCoordinates.create(
                (focusedSensorCoordinates.current || sensor.position).x + x,
                (focusedSensorCoordinates.current || sensor.position).y + y
              ),
              context.floorplan,
              context.viewport.current
            );
            sensorCoverageIntersectionsGroup.children[index].x = position.x;
            sensorCoverageIntersectionsGroup.children[index].y = position.y;
          }
        }
      }}
      onRemove={(sensor: Sensor, coverageArea) => {
        coverageArea.destroy(true);
      }}
    />
  );
};

export default SensorsLayer;
