import * as React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { css } from '@emotion/react';

import AuthWrapper from 'components/auth-wrapper/auth-wrapper';
import LogoutFinish from 'components/logout-finish';
import NavbarWrapper from 'components/navbar-wrapper';
import FloorAvailability from 'components/floor-availability/floor-availability';
import FloorPlanning from 'components/floor-planning/floor-planning';
import FloorAnalysis from 'components/floor-analysis/floor-analysis';
import EmptyStateBuildingsIndex from 'components/empty-state-buildings-index';
import BuildingSingle from 'components/building-single/building-single';
import TestRouteLoaderComponent from 'components/test-route-loader-component';
import { autoSelectBuilding, isBuilding } from 'lib/buildings';
import { PlanViewId } from 'lib/plan-views';
import PlanCreator from 'components/plan-creator';
import { TreatmentsProvider } from 'contexts/treatments';
import { ErrorReporting } from 'lib/error-reporting';
import { Engagement } from 'lib/engagement';
import { useAppDispatch, useAppSelector } from 'redux/store';
import ToastContainer from 'components/toast-container/toast-container';
import { Dialogger } from '@densityco/ui';
import { spacesSelectors } from 'redux/features/spaces/spaces-slice';
import {
  getInitialAsyncTaskState,
  isAsyncTaskFailure,
  isAsyncTaskUninitializedOrFetching,
} from 'lib/redux';
import { asyncFetchPlanDetailThunk } from 'redux/features/plans/async-fetch-plan-detail-thunk';
import { planSummariesSelectors } from 'redux/features/plans/plans-slice';
import { asyncFetchSpacesThunk } from 'redux/features/spaces/async-fetch-spaces-thunk';
import { asyncFetchPlanSummariesThunk } from 'redux/features/plans/async-fetch-plan-summaries-thunk';
import { selectPlanDetailByFloor } from 'redux/features/plans/select-plan-detail-by-floor';
import { selectFloor } from 'redux/features/spaces/select-floor';
import { useImageLoader } from 'hooks/use-image-loader';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import FillCenter from 'components/fill-center/fill-center';
import { Floor } from 'lib/floors';
import { PlanDetail } from 'lib/api';
import { selectBuilding } from 'redux/features/spaces/select-building';
import ErrorMessage from 'components/error-message/error-message';
import { asyncFetchUserThunk } from 'redux/features/user/async-fetch-user-thunk';

function App() {
  const user = useAppSelector((state) => state.user);

  // this is where we identify our user to services like sentry or intercom
  React.useEffect(
    function initialize() {
      if (!user.data) {
        return;
      }

      ErrorReporting.identify({
        id: user.data.id,
        name: user.data.full_name,
        email: user.data.email,
        organizationId: user.data.organization.id,
      });

      // ref: https://github.com/DensityCo/dashboard/blob/trunk/src/components/intercom/index.tsx
      Engagement.identify({
        id: user.data.id,
        name: user.data.full_name,
        email: user.data.email,
        organizationId: user.data.organization.id,
        organizationName: user.data.organization.name,
        coreConsent: user.data.core_consent,
        roe: user.data.role,
      });
    },
    [user]
  );

  return (
    <BrowserRouter>
      <TreatmentsProvider splitKey={user.data?.id}>
        <div
          css={css`
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
          `}
        >
          <ToastContainer />
          <Dialogger />
          <Switch>
            <Route path="/logout-finish" component={LogoutFinish} />
            <Route path="/" component={AuthedRoutes} />
            <Redirect to="/" />
          </Switch>
        </div>
      </TreatmentsProvider>
    </BrowserRouter>
  );
}

export default React.memo(App);

function UserFetcher() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(asyncFetchUserThunk());
  }, [dispatch]);

  return null;
}

function AuthedRoutes() {
  return (
    <AuthWrapper>
      {/* Top-level nav isn't behind any route, it exists on all pages */}
      <NavbarWrapper />

      <UserFetcher />

      <Switch>
        {/* TODO: leaving this here for debugging for now, remove before release */}
        <Route path="/test" component={TestRouteLoaderComponent} />

        {/* Main landing page once user logged in */}
        <Route path="/buildings" component={BuildingsRoutes} />

        <Redirect to="/buildings" />
      </Switch>
    </AuthWrapper>
  );
}

/**
 * Path /buildings/*
 */
function BuildingsRoutes() {
  const { path } = useRouteMatch();

  const dispatch = useAppDispatch();

  const spacesQuery = useAppSelector((state) => state.spaces.spacesQuery);
  const planSumariesQuery = useAppSelector(
    (state) => state.plans.planSumariesQuery
  );

  React.useEffect(() => {
    dispatch(asyncFetchSpacesThunk());
    dispatch(asyncFetchPlanSummariesThunk());
  }, [dispatch]);

  if (
    isAsyncTaskFailure(spacesQuery) ||
    isAsyncTaskFailure(planSumariesQuery)
  ) {
    return (
      <FillCenter>
        <ErrorMessage>
          Whoops, there was an error loading your spaces.
        </ErrorMessage>
      </FillCenter>
    );
  }

  if (
    isAsyncTaskUninitializedOrFetching(spacesQuery) ||
    isAsyncTaskUninitializedOrFetching(planSumariesQuery)
  ) {
    return <LoadingOverlay text="Loading spaces..." />;
  }

  return (
    <Switch>
      <Route exact path={path} component={BuildingsIndexRoute} />
      <Route path={`${path}/:buildingId`} component={BuildingSingleRoutes} />
      <Redirect to={path} />
    </Switch>
  );
}

/**
 * Path /buildings
 */
function BuildingsIndexRoute() {
  const { path } = useRouteMatch();

  const spaces = useAppSelector(spacesSelectors.selectAll);

  const autoSelectedBuilding = React.useMemo(() => {
    const buildings = spaces.filter(isBuilding);

    return autoSelectBuilding(buildings);
  }, [spaces]);

  if (autoSelectedBuilding) {
    return <Redirect to={`${path}/${autoSelectedBuilding.id}`} />;
  }

  return <EmptyStateBuildingsIndex />;
}

/**
 * Path /buildings/:buildingId
 */
function BuildingSingleRoutes() {
  const { path, params } = useRouteMatch<{ buildingId: string }>();

  const building = useAppSelector((state) =>
    selectBuilding(state, { buildingId: params.buildingId })
  );

  // NOTE: we don't fetch the building here as theres no way for us to render its floors
  // if it is missing from toplevel spaces fetch. We'd need some apis specificly optimized for these views

  if (!building) {
    return (
      <FillCenter>
        <ErrorMessage>
          Whoops, there was an error loading your building.
        </ErrorMessage>
      </FillCenter>
    );
  }

  return (
    <Switch>
      <Route exact path={`${path}/floors`}>
        <BuildingSingle building={building} />
      </Route>

      <Route path={`${path}/floors/:floorId`} component={FloorSingleRoutes} />

      <Redirect to={`${path}/floors`} />
    </Switch>
  );
}

/**
 * Path /buildings/:buildingId/floors/:floorId/*
 */
const FloorSingleRoutes: React.FunctionComponent = () => {
  const { path, params } =
    useRouteMatch<{ buildingId: string; floorId: string }>();

  const dispatch = useAppDispatch();

  const planDetailQueries = useAppSelector(
    (state) => state.plans.planDetailQueries
  );

  const planSummaries = useAppSelector(planSummariesSelectors.selectAll);

  const targetPlan = React.useMemo(() => {
    return planSummaries.find((p) => p.floor.id === params.floorId);
  }, [params.floorId, planSummaries]);

  const planDetail = useAppSelector((state) =>
    selectPlanDetailByFloor(state, { floorId: params.floorId })
  );

  const floor = useAppSelector((state) =>
    selectFloor(state, { floorId: params.floorId })
  );

  const [planImage, isLoadingPlanImage, planImageError] = useImageLoader(
    targetPlan?.image_url || ''
  );

  const planDetailQuery = React.useMemo(() => {
    if (!targetPlan) {
      return getInitialAsyncTaskState<void>();
    }

    return planDetailQueries[targetPlan.id] || getInitialAsyncTaskState();
  }, [planDetailQueries, targetPlan]);

  // NOTE: we don't fetch the floor here as it should have been fetched in the toplevel spaces query

  React.useEffect(() => {
    if (!targetPlan) {
      return;
    }

    dispatch(asyncFetchPlanDetailThunk(targetPlan.id));
  }, [dispatch, targetPlan]);

  const onPlanCreated = React.useCallback(() => {
    // TODO(wuweiweiwu): we shouldn't have to rely on reload for client state updates
    window.location.reload();
  }, []);

  if (!targetPlan) {
    return <PlanCreator onPlanCreated={onPlanCreated} />;
  }

  if (isAsyncTaskFailure(planDetailQuery)) {
    return (
      <FillCenter>
        <ErrorMessage>
          Whoops, there was an error loading this Plan.
        </ErrorMessage>
      </FillCenter>
    );
  }

  if (isAsyncTaskUninitializedOrFetching(planDetailQuery)) {
    return <LoadingOverlay text="Loading plan..." />;
  }

  if (planImageError) {
    return (
      <FillCenter>
        <ErrorMessage>
          Whoops, there was an error loading this plan image.
        </ErrorMessage>
      </FillCenter>
    );
  }

  if (isLoadingPlanImage) {
    return <LoadingOverlay text="Loading plan image..." />;
  }

  if (planDetail && floor) {
    return (
      <Switch>
        <Route path={`${path}/:planViewId`}>
          <PlanRoutes
            planDetail={planDetail}
            floor={floor}
            planImage={planImage || null}
          />
        </Route>

        <Redirect
          to={`${path}/${
            floor.status === 'live' && planImage ? 'analysis' : 'planning'
          }`}
        />
      </Switch>
    );
  }

  return null;
};

const PlanRoutes: React.FunctionComponent<{
  floor: Floor;
  planDetail: PlanDetail;
  planImage: HTMLImageElement | null;
}> = ({ floor, planDetail, planImage }) => {
  const { params } = useRouteMatch<{
    buildingId: string;
    floorId: string;
    planViewId: PlanViewId;
  }>();

  const { buildingId, floorId, planViewId } = params;

  const view = React.useMemo(() => {
    switch (planViewId) {
      case 'planning': {
        return <FloorPlanning planDetail={planDetail} planImage={planImage} />;
      }

      case 'analysis': {
        if (!planImage) {
          return <LoadingOverlay text="Loading plan image..." />;
        }
        return (
          <FloorAnalysis
            planDetail={planDetail}
            floor={floor}
            planImage={planImage}
          />
        );
      }

      case 'availability': {
        if (!planImage) {
          return <LoadingOverlay text="Loading plan image..." />;
        }
        return (
          <FloorAvailability
            planDetail={planDetail}
            floor={floor}
            planImage={planImage}
          />
        );
      }

      default: {
        return (
          <Redirect
            to={`/buildings/${buildingId}/floors/${floorId}/planning`}
          />
        );
      }
    }
  }, [buildingId, floor, floorId, planDetail, planImage, planViewId]);

  return view;
};
