import styled from '@emotion/styled';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

// clear button in dust
// https://www.figma.com/file/1fBpJ87vzhU969NH0xzmDr/library-dust?node-id=282%3A16689
const ClearButton = styled.button`
  // css button reset
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;

  border-radius: 4px;
  padding: 2px 4px;
  font-weight: ${dust.FontWeightBold};
  font-size: ${dust.TextScale2};
  color: ${dust.Gray400};
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  &:hover {
    background-color: ${dust.Gray100};
  }

  &:focus {
    color: ${dust.Blue400};
    background-color: ${dust.Blue100};
  }

  &:disabled {
    color: ${dust.Gray300};
  }
`;

export default ClearButton;
