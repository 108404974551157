import { createSelector } from '@reduxjs/toolkit';
import invariant from 'invariant';

import { selectSubspaces } from '../spaces/select-subspaces';
import { AnalysisDatapoint } from './analysis-slice';

import { aggregateData } from 'lib/charts';
import { RootState } from 'redux/store';
import { ascending } from 'lib/sort';
import { spaceFunctionToNewSpaceFunction } from 'lib/space-functions';
import { planDetailsSelectors } from '../plans/plans-slice';
import { selectTimeZone } from '../spaces/select-timezone';
import { isSingleOccupancy } from 'lib/space';

// for every area/space get analytics query datum and get average utilization

export const selectRankInfo = createSelector(
  [
    selectSubspaces,

    (state: RootState) => state.analysis.analyticsQuery.data,
    (state: RootState) => state.analysis.utilizationAnalyticsByHour,

    (state: RootState, params: { planId: string }) =>
      planDetailsSelectors.selectById(state, params.planId),

    selectTimeZone,

    (_: RootState, params: { selfSpaceId: string }) => params.selfSpaceId,
  ],
  (
    subspaces,
    analyticsQueryData,
    utilizationAnalyticsByHour,
    plan,
    timeZone,
    selfSpaceId
  ) => {
    if (!analyticsQueryData || !plan) {
      return;
    }

    const space = subspaces.find((space) => space.id === selfSpaceId);
    if (!space?.function) {
      return;
    }

    const sameFunctionSubspaces = subspaces.filter((s) => {
      // enforce that capacity exists
      if (!s.capacity) {
        return false;
      }

      // enforce functions exists
      if (!s.function || !space.function) {
        return false;
      }

      return (
        spaceFunctionToNewSpaceFunction(s.function) ===
        spaceFunctionToNewSpaceFunction(space.function)
      );
    });

    // construct some easy lookup tables
    const areaIdBySpaceId: { [spaceId: string]: string } = {};
    plan.areas.forEach((area) => {
      const spaceId = area.space_id;
      if (!spaceId) {
        return;
      }

      areaIdBySpaceId[spaceId] = area.id;
    });

    const analyticsDatumByAreaId: { [areaId: string]: AnalysisDatapoint } = {};
    analyticsQueryData.forEach((datum) => {
      analyticsDatumByAreaId[datum.area_id] = datum;
    });

    const averageUtilizationBySpaceId: {
      [spaceId: string]: number;
    } = {};
    sameFunctionSubspaces.forEach((space) => {
      invariant(space.capacity, `space capacity must be set. id: ${space.id}`);

      const data = utilizationAnalyticsByHour.entities[space.id]?.data || [];

      const aggregation = aggregateData(data, timeZone, 'avg', 'hour');

      const average =
        aggregation.reduce((acc, curr) => acc + curr.value, 0) /
        aggregation.length;

      averageUtilizationBySpaceId[space.id] = average / space.capacity;
    });

    // rankInfo is calculated as average utilzation x  * time used
    // single occupancy has average utilization as 1
    const rankedSubspaces = sameFunctionSubspaces.sort((a, b) => {
      const aAverageUtilization = isSingleOccupancy(a)
        ? 1
        : averageUtilizationBySpaceId[a.id];
      const bAverageUtilization = isSingleOccupancy(b)
        ? 1
        : averageUtilizationBySpaceId[b.id];

      const aRank =
        aAverageUtilization *
          analyticsDatumByAreaId[areaIdBySpaceId[a.id]]?.occupied_percent || 0;

      const bRank =
        bAverageUtilization *
          analyticsDatumByAreaId[areaIdBySpaceId[b.id]]?.occupied_percent || 0;

      return ascending(aRank, bRank);
    });

    const selfIndex = rankedSubspaces.findIndex((s) => s.id === selfSpaceId);

    return { rankIndex: selfIndex, total: rankedSubspaces.length };
  }
);
