import { Fragment, useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { AxiosInstance } from 'axios';
import Fuse from 'fuse.js';
import axios from 'axios';
import { Icons } from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';

import { Action } from './actions';
import {
  FloorplanCollection,
  Reference,
  Sensor,
  Space,
  State,
  SensorStatus,
  PhotoGroup,
  PlanDXF,
  LayerId,
} from './state';
import styles from './styles.module.scss';
import { uploadDXFFile } from './cad-import';

import TextField from 'components/text-field';
import HorizontalForm from 'components/horizontal-form';
import Switch from 'components/switch/switch';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import { CADFileUploader } from 'components/image-uploader';

import { SPLITS } from 'lib/treatments';
import { PlanDetail, FloorplanAPI } from 'lib/api';

import { useTreatment } from 'contexts/treatments';

function applyFuzzySearch<T extends object>(
  collection: Array<T>,
  searchText: string,
  fns: Array<(item: T, index: number) => string>
): Array<[T, number]> {
  if (searchText.length === 0) {
    return collection.map((item, index) => [item, index]);
  }

  const collectionWithIndex = collection.map((item, index) => ({
    item,
    index,
  }));

  // NOTE: I'm really not satisfied with this fuse.js library. `fuzzy`, my preferred library, wasn't
  // working and I couldn't figure out why (was always just returning an empty array).
  const fuse = new Fuse(collectionWithIndex, {
    threshold: 0.1,
    includeScore: true,
    keys: ['bogus string needed here to trigger getFn below'],
    getFn: ({ item, index }) => fns.map((fn) => fn(item, index)),
  });

  return fuse.search(searchText).map((i) => [i.item.item, i.item.index]);
}

const ReferenceListItem: React.FunctionComponent<{
  reference: Reference;
  isEnabled: boolean;
  isHighlighted: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({ reference, isEnabled, isHighlighted, dispatch }) => {
  const [listItemHovered, setListitemHovered] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => {
    setListitemHovered(true);
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'reference',
      itemId: reference.id,
    });
  }, [reference.id, dispatch]);
  const onMouseLeave = useCallback(() => {
    setListitemHovered(false);
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'reference',
      itemId: reference.id,
    });
  }, [reference.id, dispatch]);
  return (
    <li
      key={reference.id}
      className={classNames(styles.referenceListItem, {
        [styles.disabled]: !isEnabled,
        [styles.highlighted]: isHighlighted,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-cy="reference-list-item"
    >
      <div className={styles.objectsPanelListItemIconWrapper}>
        {reference.type === 'point' ? (
          <Icons.AccuracyTarget width={18} height={18} color="currentColor" />
        ) : (
          <Icons.Ruler width={18} height={18} color="currentColor" />
        )}
      </div>
      <span className={styles.referenceListItemTitle}>
        {reference.id.split('-')[0]}
      </span>
      {listItemHovered ? (
        <button
          className={classNames([
            styles.buttonSmall,
            styles.buttonIcon,
            styles.danger,
          ])}
          onClick={() => {
            dispatch({ type: 'reference.remove', id: reference.id });
          }}
        >
          <Icons.Trash width={18} height={18} color={colors.red} />
        </button>
      ) : null}
      <button
        className={classNames([styles.buttonSmall, styles.buttonIcon])}
        onClick={() => {
          dispatch({ type: 'reference.click', id: reference.id });
        }}
      >
        {reference.enabled ? (
          <Icons.VisibilityShow width={18} height={18} color="currentColor" />
        ) : (
          <Icons.VisibilityHide width={18} height={18} color="currentColor" />
        )}
      </button>
    </li>
  );
};

const SensorListItem: React.FunctionComponent<{
  sensor: Sensor;
  sensorTitle: string;
  isHighlighted: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  sensor,
  sensorTitle,
  isHighlighted,
  isFocused,
  isDisabled,
  dispatch,
}) => {
  const onMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'sensor',
      itemId: sensor.id,
    });
  }, [sensor.id, isDisabled, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'sensor',
      itemId: sensor.id,
    });
  }, [sensor.id, isDisabled, dispatch]);

  const onMouseDown = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mousedown',
      itemType: 'sensor',
      itemId: sensor.id,
    });
  }, [sensor.id, isDisabled, dispatch]);

  return (
    <li
      key={sensor.id}
      className={classNames(styles.sensorListItem, {
        [styles.highlighted]: isHighlighted,
        [styles.focused]: isFocused,
        [styles.disabled]: isDisabled,
        [styles.error]:
          sensor.status === SensorStatus.ERROR ||
          sensor.status === SensorStatus.LOW_POWER,
        [styles.online]: sensor.status === SensorStatus.ONLINE,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      data-cy="sensor-list-item"
      data-cy-sensor-type={sensor.type}
      data-cy-disabled={isDisabled}
    >
      <div
        className={classNames([
          styles.objectsPanelListItemIconWrapper,
          {
            [styles.objectsPanelListItemIconWrapperEntry]:
              sensor.type === 'entry',
          },
        ])}
      >
        {sensor.type === 'oa' ? (
          <Icons.DeviceSide width={18} height={18} color="currentColor" />
        ) : (
          <Icons.DeviceTop width={18} height={18} color="currentColor" />
        )}
      </div>
      {sensorTitle}
    </li>
  );
};

const SpaceListItem: React.FunctionComponent<{
  space: Space;
  isHighlighted: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({ space, isHighlighted, isFocused, isDisabled, dispatch }) => {
  const onMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'space',
      itemId: space.id,
    });
  }, [space.id, isDisabled, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'space',
      itemId: space.id,
    });
  }, [space.id, isDisabled, dispatch]);

  const onMouseDown = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mousedown',
      itemType: 'space',
      itemId: space.id,
    });
  }, [space.id, isDisabled, dispatch]);

  return (
    <li
      key={space.id}
      className={classNames(styles.spaceListItem, {
        [styles.highlighted]: isHighlighted,
        [styles.focused]: isFocused,
        [styles.disabled]: isDisabled,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      data-cy="space-list-item"
      data-cy-disabled={isDisabled}
    >
      <div className={styles.objectsPanelListItemIconWrapper}>
        <Icons.Space width={18} height={18} color="currentColor" />
      </div>
      {space.name}
    </li>
  );
};

const PhotoGroupListItem: React.FunctionComponent<{
  photoGroup: PhotoGroup;
  title: string;
  isHighlighted: boolean;
  isFocused: boolean;
  isDisabled: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({
  photoGroup,
  title,
  isFocused,
  isHighlighted,
  isDisabled,
  dispatch,
}) => {
  const onMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseenter',
      itemType: 'photogroup',
      itemId: photoGroup.id,
    });
  }, [photoGroup.id, isDisabled, dispatch]);

  const onMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    dispatch({
      type: 'item.menu.mouseleave',
      itemType: 'photogroup',
      itemId: photoGroup.id,
    });
  }, [photoGroup.id, isDisabled, dispatch]);

  return (
    <li
      key={photoGroup.id}
      data-cy={`photo-group-list-item-${photoGroup.id}`}
      data-cy-highlighted={isHighlighted}
      data-cy-disabled={isDisabled}
      className={classNames(styles.photoGroupListItem, {
        [styles.focused]: isFocused,
        [styles.highlighted]: isHighlighted,
        [styles.disabled]: isDisabled,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={() => {
        if (isDisabled) {
          return;
        }
        dispatch({
          type: 'item.menu.mousedown',
          itemType: 'photogroup',
          itemId: photoGroup.id,
        });
      }}
    >
      <div className={styles.photoGroupListItemIconWrapper}>
        <Icons.FolderImage width={18} height={18} color="currentColor" />
      </div>
      <span className={styles.photoGroupListItemName}>{photoGroup.name}</span>
      {photoGroup.locked ? (
        <div className={styles.photoGroupListItemLockWrapper}>
          <Icons.LockClosed width={10} height={10} color="currentColor" />
        </div>
      ) : null}
      <HorizontalForm size="small">
        <div className={styles.photoGroupListItemTitle}>{title}</div>
        <div
          className={classNames(styles.photoGroupListItemBubble, {
            [styles.filled]: photoGroup.photos.length === 0,
          })}
          data-cy="photo-group-bubble"
          data-cy-isfilled={photoGroup.photos.length === 0}
        />
      </HorizontalForm>
    </li>
  );
};

const LayerListItem: React.FunctionComponent<{
  icon: React.ReactNode;
  label: React.ReactNode;
  isCheckable?: boolean;
  isChecked?: boolean;
  onChange?: (value: boolean) => void;
  isDisabled?: boolean;
  indent?: number;
  actions?: React.ReactNode;
}> = ({
  icon,
  label,
  isCheckable = true,
  isChecked = false,
  onChange,
  isDisabled,
  indent = 0,
  actions,
}) => (
  <li
    className={styles.layerListItem}
    style={{ marginLeft: 8 + indent * 24 }}
    onClick={() => {
      if (isCheckable && onChange && !isDisabled) {
        onChange(!isChecked);
      }
    }}
  >
    <span className={styles.layerListItemIcon}>{icon}</span>
    <span className={styles.layerListItemLabel}>{label}</span>
    <HorizontalForm size="small">
      {actions}
      {isCheckable ? (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            isChecked={isChecked}
            onChange={(event) => {
              if (onChange && !isDisabled) {
                onChange(event.target.checked);
              }
            }}
            isDisabled={isDisabled}
          />
        </div>
      ) : null}
    </HorizontalForm>
  </li>
);

const LayerListItemHeader: React.FunctionComponent = ({ children }) => (
  <li className={styles.layerListItemHeader}>{children}</li>
);

export const LatestDXFStatus: React.FunctionComponent<{
  state: State;
  client: AxiosInstance;
  plan: PlanDetail;
  dispatch: React.Dispatch<Action>;
}> = ({ state, client, plan, dispatch }) => {
  const onEditDXF = useCallback(
    async (dxfId: PlanDXF['id']) => {
      let dxfResponse;
      try {
        dxfResponse = await FloorplanAPI.getDXF(client, plan.id, dxfId);
      } catch (err) {
        toast.error(`Cannot get details for DXF ${dxfId}.`);
        return;
      }

      // Ensure that there is a png floorplan image available
      const fullImageAsset = dxfResponse.data.assets.find(
        (asset) =>
          asset.name === 'full_image' && asset.content_type === 'image/png'
      );
      if (!fullImageAsset) {
        toast.error(
          'Complete DXF does not contain a png-formatted floorplan image!'
        );
        return;
      }

      dispatch({
        type: 'latestDXF.edit',
        planDXF: dxfResponse.data,
        fullImageAsset,
      });
    },
    [client, plan.id, dispatch]
  );

  if (!state.latestDXF) {
    return null;
  }

  if (
    state.latestDXF.status !== 'uploading' &&
    state.latestDXF.status !== 'upload_error' &&
    state.latestDXF.id === state.activeDXFId
  ) {
    return null;
  }
  return (
    <div className={styles.latestDXFStatus}>
      <div className={styles.objectsPanelListItemIconWrapper}>
        <Icons.Apps width={18} height={18} color="currentColor" />
      </div>

      {state.latestDXF.status === 'uploading' ? (
        <div
          className={styles.middle}
          data-cy="latest-dxf-processing"
          data-cy-status="uploading"
        >
          <span className={styles.title}>Uploading DXF:</span>
          <Tooltip
            target={
              <div className={styles.latestDXFPercentWrapper}>
                <div
                  className={styles.latestDXFPercent}
                  style={{
                    width: `${state.latestDXF.fileUploadPercent || 0}%`,
                  }}
                />
              </div>
            }
            contents={`${Math.round(
              state.latestDXF.fileUploadPercent || 0
            )}% uploaded`}
            placement="bottom"
            width="100%"
          />
        </div>
      ) : null}
      {state.latestDXF.status === 'upload_error' ? (
        <div
          className={styles.middle}
          data-cy="latest-dxf-processing"
          data-cy-status="upload_error"
        >
          <span className={styles.title}>Uploading:</span>
          Upload Error!
        </div>
      ) : null}
      {state.latestDXF.status !== 'uploading' &&
      state.latestDXF.status !== 'upload_error' ? (
        <div
          className={styles.middle}
          data-cy="latest-dxf-processing"
          data-cy-status={state.latestDXF.status}
        >
          <span className={styles.title}>New DXF:</span>
          {{
            created: 'Queueing...',
            downloading: 'Downloading...',
            parsing_dxf: 'Reading Geometry...',
            processing_dxfs: 'Writing Geometry...',
            processing_images: 'Generating Images...',
            complete: 'Processed',
            error: 'Error',
          }[state.latestDXF.status] || state.latestDXF.status}
        </div>
      ) : null}

      {state.latestDXF.status === 'complete' ? (
        <Button
          size="medium"
          type="outlined"
          trailingIcon={
            <Icons.ArrowRight width={18} height={18} color="currentColor" />
          }
          onClick={() => {
            if (!state.latestDXF || state.latestDXF.status !== 'complete') {
              return;
            }
            onEditDXF(state.latestDXF.id);
          }}
          data-cy="latest-dxf-next"
        >
          Next
        </Button>
      ) : null}
      {state.latestDXF.status === 'error' ? (
        <Button
          size="medium"
          type="outlined"
          leadingIcon={
            <Icons.Refresh width={18} height={18} color="currentColor" />
          }
          onClick={async () => {
            if (!state.latestDXF || state.latestDXF.status !== 'error') {
              return;
            }

            try {
              await FloorplanAPI.updateAndReprocessDXF(
                client,
                plan.id,
                state.latestDXF.id,
                state.latestDXF.parseOptions
              );
            } catch (err) {
              toast.error('Error reprocessing dxf!');
              return;
            }

            toast.success('Reprocessing dxf...');
          }}
          data-cy="latest-dxf-retry"
        >
          Retry
        </Button>
      ) : null}
    </div>
  );
};

const FloorplanObjectsList: React.FunctionComponent<{
  state: State;
  client: AxiosInstance;
  plan: PlanDetail;
  dispatch: React.Dispatch<Action>;
}> = ({ state, client, plan, dispatch }) => {
  const [isOASensorsListVisible, setIsOASensorsListVisible] =
    useState<boolean>(true);
  const [isEntrySensorsListVisible, setIsEntrySensorsListVisible] =
    useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');

  const heightMapEnabled = useTreatment(SPLITS.HEIGHT_MAP);
  const sensorCoverageEnabled = useTreatment(SPLITS.SENSOR_COVERAGE);

  const [oaSensorTitles, entrySensorTitles] = useMemo(() => {
    const generateTitle = (sensor: Sensor) => {
      if (sensor.serialNumber) {
        return sensor.serialNumber;
      }
      if (sensor.cadId) {
        return sensor.cadId;
      }
      const genericTitlePrefix = sensor.type === 'oa' ? 'OA' : 'E';
      return `${genericTitlePrefix}${sensor.plan_sensor_index}`;
    };

    return [
      Sensor.filterByType('oa', state.sensors).map(generateTitle),
      Sensor.filterByType('entry', state.sensors).map(generateTitle),
    ];
  }, [state.sensors]);

  // To display sensors in the order of their plan index
  const sortSensorsByPlanIndex = (
    [sensorA, sensorAPreSortedIndex]: [Sensor, number],
    [sensorB, sensorBPreSortedIndex]: [Sensor, number]
  ): number => {
    if (!(sensorA && sensorB)) {
      return 0;
    }
    if (!sensorA.plan_sensor_index) {
      return 1;
    }
    if (!sensorB.plan_sensor_index) {
      return -1;
    }
    return sensorA.plan_sensor_index - sensorB.plan_sensor_index;
  };

  const photoGroupTitles = useMemo(() => {
    const generateTitle = (
      photoGroup: PhotoGroup,
      indexInCurrentList: number
    ) => {
      const numberInList = indexInCurrentList + 1;
      return `PG${numberInList}`;
    };

    return FloorplanCollection.list(state.photoGroups).map(generateTitle);
  }, [state.photoGroups]);

  const onUploadCADFile = useCallback(
    (file: File) => {
      uploadDXFFile(
        plan.floor.id,
        plan.id,
        client,
        file,
        () => dispatch({ type: 'latestDXF.uploadBegin' }),
        (percent) =>
          dispatch({
            type: 'latestDXF.uploadProgress',
            fileUploadPercent: percent,
          }),
        () => dispatch({ type: 'latestDXF.uploadError' }),
        (planDXF) =>
          dispatch({
            type: 'latestDXF.uploadComplete',
            planDXF,
          })
      );
    },
    [plan, client, dispatch]
  );

  const onUploadImage = useCallback(
    async (image, dataUrl) => {
      let signedUrlResponse;
      try {
        signedUrlResponse = await FloorplanAPI.imageUpload(client, {
          floor_id: plan.floor.id,
          ext: 'png',
          content_type: 'image/png',
        });
      } catch (err) {
        toast.error('Error creating image upload url!');
        return;
      }

      const [, imageData] = dataUrl.split(',');
      const {
        key: objectKey,
        signed_url: signedUrl,
        get_signed_url: getSignedUrl,
      } = signedUrlResponse.data;

      const imageBytesAsString = atob(imageData);
      const byteArray = new Uint8Array(imageBytesAsString.length);
      for (let i = 0; i < imageBytesAsString.length; i++) {
        byteArray[i] = imageBytesAsString.charCodeAt(i);
      }

      try {
        await axios.put(signedUrl, byteArray.buffer, {
          headers: {
            'Content-Type': 'image/png',
          },
        });
      } catch (err) {
        toast.error('Error uploading image.');
        return;
      }

      // Instead of using the base64 data url version of the image, access it on s3 using the get
      // url that came back during the upload process
      const serverImage = new Image();
      serverImage.src = getSignedUrl;
      await new Promise((resolve) =>
        serverImage.addEventListener('load', resolve)
      );

      dispatch({ type: 'scaleEdit.begin', image: serverImage, objectKey });
    },
    [plan, client, dispatch]
  );

  return (
    <div className={styles.objectsPanel}>
      <div className={styles.objectsPanelHeader}>
        <div className={classNames(styles.navTabs, styles.objectsPanelTabs)}>
          <button
            className={classNames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'sensor',
            })}
            onClick={() => dispatch({ type: 'menu.showSensors' })}
          >
            <Icons.DeviceSide width={18} height={18} color="currentColor" />
            <div className={styles.tooltip}>Sensors</div>
          </button>
          <button
            className={classNames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'space',
            })}
            onClick={() => dispatch({ type: 'menu.showSpaces' })}
            data-cy="spaces-tab"
          >
            <Icons.Space width={18} height={18} color="currentColor" />
            <div className={styles.tooltip}>Spaces</div>
          </button>
          <button
            className={classNames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'reference',
            })}
            onClick={() => dispatch({ type: 'menu.showReferences' })}
            data-cy="references-tab"
          >
            <Icons.Ruler width={18} height={18} color="currentColor" />
            <div className={styles.tooltip}>References</div>
          </button>
          <button
            className={classNames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'photogroup',
            })}
            onClick={() => dispatch({ type: 'menu.showPhotoGroups' })}
            data-cy="photo-groups-tab"
          >
            <Icons.FolderImage width={18} height={18} color="currentColor" />
            <div className={styles.tooltip}>Photos</div>
          </button>
          <button
            className={classNames(styles.tabTarget, {
              [styles.active]: state.objectListType === 'layer',
            })}
            onClick={() => dispatch({ type: 'menu.showLayers' })}
            data-cy="layers-tab"
          >
            <Icons.Floor width={18} height={18} color="currentColor" />
            <div className={styles.tooltip}>Layers</div>
          </button>
        </div>
      </div>

      {state.objectListType === 'sensor' ? (
        <Fragment>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>Add Sensor</h3>
            <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode &&
                  state.placementMode.type === 'sensor' &&
                  state.placementMode.sensorType === 'oa',
              })}
              disabled={!state.planning.showOASensors}
              onClick={() => {
                dispatch({ type: 'menu.addSensor', sensorType: 'oa' });
              }}
              data-cy="add-sensor-oa"
            >
              OA
            </button>
            <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode &&
                  state.placementMode.type === 'sensor' &&
                  state.placementMode.sensorType === 'entry',
              })}
              disabled={!state.planning.showEntrySensors}
              onClick={() => {
                dispatch({ type: 'menu.addSensor', sensorType: 'entry' });
              }}
              data-cy="add-sensor-entry"
            >
              Entry
            </button>
          </div>
          <div className={styles.objectsPanelSearch}>
            <TextField
              leadingIcon={<Icons.Search color="currentColor" />}
              size="medium"
              placeholder="Search sensors..."
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          </div>
          <div className={styles.objectsPanelMainScrollArea}>
            <div
              className={styles.sensorListHeader}
              onClick={() => {
                setIsOASensorsListVisible(!isOASensorsListVisible);
              }}
            >
              <h3 className={styles.sensorListTitle}>Open Area</h3>
              {isOASensorsListVisible ? (
                <Icons.ChevronDown
                  width={18}
                  height={18}
                  color="currentColor"
                />
              ) : (
                <Icons.ChevronUp width={18} height={18} color="currentColor" />
              )}
            </div>
            {isOASensorsListVisible ? (
              <ul className={styles.sensorList}>
                {applyFuzzySearch(
                  Sensor.filterByType('oa', state.sensors),
                  searchText,
                  [
                    (sensor) => sensor.serialNumber || '', // Serial number
                    (_s, index) => oaSensorTitles[index], // Floorplan identifier
                  ]
                )
                  .sort(sortSensorsByPlanIndex)
                  .map(([sensor, preSortedIndex]) => {
                    return (
                      <SensorListItem
                        key={sensor.id}
                        sensor={sensor}
                        sensorTitle={oaSensorTitles[preSortedIndex]}
                        isHighlighted={State.isSensorHighlighted(
                          state,
                          sensor.id
                        )}
                        isFocused={State.isSensorFocused(state, sensor.id)}
                        isDisabled={!state.planning.showOASensors}
                        dispatch={dispatch}
                      />
                    );
                  })}
              </ul>
            ) : null}
            <div
              className={styles.sensorListHeader}
              onClick={() => {
                setIsEntrySensorsListVisible(!isEntrySensorsListVisible);
              }}
            >
              <h3 className={styles.sensorListTitle}>ENTRY</h3>
              {isEntrySensorsListVisible ? (
                <Icons.ChevronDown
                  width={18}
                  height={18}
                  color="currentColor"
                />
              ) : (
                <Icons.ChevronUp width={18} height={18} color="currentColor" />
              )}
            </div>
            {isEntrySensorsListVisible ? (
              <ul className={styles.sensorList}>
                {applyFuzzySearch(
                  Sensor.filterByType('entry', state.sensors),
                  searchText,
                  [
                    (sensor) => sensor.serialNumber || '', // Serial number
                    (_s, index) => entrySensorTitles[index], // Floorplan identifier
                  ]
                )
                  .sort(sortSensorsByPlanIndex)
                  .map(([sensor, preSortedIndex]) => {
                    return (
                      <SensorListItem
                        key={sensor.id}
                        sensor={sensor}
                        sensorTitle={entrySensorTitles[preSortedIndex]}
                        isHighlighted={State.isSensorHighlighted(
                          state,
                          sensor.id
                        )}
                        isFocused={State.isSensorFocused(state, sensor.id)}
                        isDisabled={!state.planning.showEntrySensors}
                        dispatch={dispatch}
                      />
                    );
                  })}
              </ul>
            ) : null}
          </div>
          <div className={styles.objectsPanelFooter}>
            <div className={styles.sensorCount}>
              <div className={styles.sensorCountLabel}>
                <Icons.DeviceSide
                  width={18}
                  height={18}
                  color={colors.gray500}
                />
                OA:
              </div>
              <div className={styles.sensorCountNumber}>
                {Sensor.filterByType('oa', state.sensors).length}
              </div>
            </div>
            <div className={styles.sensorCount}>
              <div className={styles.sensorCountLabel}>
                <Icons.DeviceTop
                  width={18}
                  height={18}
                  color={colors.gray500}
                />
                Entry:
              </div>
              <div className={styles.sensorCountNumber}>
                {Sensor.filterByType('entry', state.sensors).length}
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
      {state.objectListType === 'space' ? (
        <Fragment>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>Add Space</h3>
            <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode &&
                  state.placementMode.type === 'space' &&
                  state.placementMode.shape === 'box',
              })}
              disabled={!state.planning.showSpaces}
              onClick={() => {
                dispatch({ type: 'menu.addSpace', shape: 'box' });
              }}
              data-cy="add-space-box"
            >
              <Icons.EditorSquare width={18} height={18} color="currentColor" />
            </button>
            <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode &&
                  state.placementMode.type === 'space' &&
                  state.placementMode.shape === 'circle',
              })}
              disabled={!state.planning.showSpaces}
              onClick={() => {
                dispatch({ type: 'menu.addSpace', shape: 'circle' });
              }}
              data-cy="add-space-circle"
            >
              <Icons.EditorCircle width={18} height={18} color="currentColor" />
            </button>
            <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode?.type === 'space' &&
                  state.placementMode?.shape === 'polygon',
              })}
              disabled={!state.planning.showSpaces}
              onClick={() => {
                dispatch({ type: 'menu.addSpace', shape: 'polygon' });
              }}
              data-cy="add-space-polygon"
            >
              <Icons.Freehand width={18} height={18} color="currentColor" />
            </button>
          </div>
          <div className={styles.objectsPanelSearch}>
            <TextField
              leadingIcon={<Icons.Search color="currentColor" />}
              size="medium"
              placeholder="Search spaces..."
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          </div>
          <div
            className={styles.objectsPanelMainScrollArea}
            data-cy="space-object-list"
          >
            <ul className={styles.sensorList}>
              {applyFuzzySearch(
                FloorplanCollection.list(state.spaces),
                searchText,
                [(s) => s.name || '']
              ).map(([space]) => {
                return (
                  <SpaceListItem
                    key={space.id}
                    space={space}
                    isHighlighted={State.isSpaceHighlighted(state, space.id)}
                    isFocused={State.isSpaceFocused(state, space.id)}
                    isDisabled={!state.planning.showSpaces}
                    dispatch={dispatch}
                  />
                );
              })}
            </ul>
          </div>
        </Fragment>
      ) : null}
      {state.objectListType === 'reference' ? (
        <Fragment>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>Add Ruler</h3>
            {/* <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode &&
                  state.placementMode.type === 'reference' &&
                  state.placementMode.referenceType === 'point',
              })}
              onClick={() => {
                dispatch({ type: 'menu.addReference', referenceType: 'point' });
              }}
            >
              <img src={iconTarget} width={18} height={18} alt={'Point'} />
            </button> */}
            <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode &&
                  state.placementMode.type === 'reference' &&
                  state.placementMode.referenceType === 'ruler',
              })}
              disabled={!state.planning.showRulers}
              onClick={() => {
                dispatch({ type: 'menu.addReference', referenceType: 'ruler' });
              }}
              data-cy="add-ruler"
            >
              <Icons.Ruler width={18} height={18} color="currentColor" />
            </button>
          </div>
          <div className={styles.objectsPanelMainScrollArea}>
            <ul className={styles.sensorList}>
              {FloorplanCollection.list(state.references).map((reference) => {
                return (
                  <ReferenceListItem
                    key={reference.id}
                    reference={reference}
                    isEnabled={State.isReferenceEnabled(state, reference.id)}
                    isHighlighted={State.isReferenceHighlighted(
                      state,
                      reference.id
                    )}
                    dispatch={dispatch}
                  />
                );
              })}
            </ul>
          </div>
        </Fragment>
      ) : null}
      {state.objectListType === 'photogroup' ? (
        <Fragment>
          <div className={styles.objectsPanelActions}>
            <h3 className={styles.objectsPanelActionsTitle}>Add Photo Group</h3>
            <button
              className={classNames(styles.objectsPanelActionsButton, {
                [styles.active]:
                  state.placementMode &&
                  state.placementMode.type === 'photogroup',
              })}
              disabled={!state.planning.showPhotoGroups}
              onClick={() => {
                dispatch({ type: 'menu.addPhotoGroup' });
              }}
              data-cy="photo-group-add"
            >
              <Icons.FolderImageAdd
                width={18}
                height={18}
                color="currentColor"
              />
            </button>
          </div>
          <div className={styles.objectsPanelSearch}>
            <TextField
              leadingIcon={<Icons.Search color="currentColor" />}
              size="medium"
              placeholder="Search photo groups..."
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          </div>
          {FloorplanCollection.isEmpty(state.photoGroups) ? (
            <div className={styles.objectsPanelEmptyState}>
              Add photo groups to this floor
            </div>
          ) : null}
          <div className={styles.objectsPanelMainScrollArea}>
            <ul className={styles.sensorList}>
              {applyFuzzySearch(
                FloorplanCollection.list(state.photoGroups),
                searchText,
                [(photoGroup) => photoGroup.name]
              ).map(([photoGroup, preSortedIndex]) => {
                return (
                  <PhotoGroupListItem
                    key={photoGroup.id}
                    title={photoGroupTitles[preSortedIndex]}
                    photoGroup={photoGroup}
                    isFocused={State.isPhotoGroupFocused(state, photoGroup.id)}
                    isHighlighted={State.isPhotoGroupHighlighted(
                      state,
                      photoGroup.id
                    )}
                    isDisabled={!state.planning.showPhotoGroups}
                    dispatch={dispatch}
                  />
                );
              })}
            </ul>
          </div>
        </Fragment>
      ) : null}
      {state.objectListType === 'layer' ? (
        <Fragment>
          <div className={styles.objectsPanelMainScrollArea}>
            <ul className={styles.layerList}>
              <LayerListItemHeader>Objects</LayerListItemHeader>
              <LayerListItem
                icon={
                  <Icons.DeviceSide
                    width={18}
                    height={18}
                    color="currentColor"
                  />
                }
                label="Sensors"
                isChecked={state.planning.showSensors}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleSensors' })
                }
              />
              {state.planning.showSensors ? (
                <Fragment>
                  <LayerListItem
                    icon={
                      <Icons.DeviceTop
                        width={18}
                        height={18}
                        color="currentColor"
                      />
                    }
                    label="Entry"
                    isChecked={state.planning.showEntrySensors}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleEntrySensors' })
                    }
                    indent={1}
                  />
                  <LayerListItem
                    icon={
                      <Icons.DeviceSide
                        width={18}
                        height={18}
                        color="currentColor"
                      />
                    }
                    label="Open Area"
                    isChecked={state.planning.showOASensors}
                    onChange={() =>
                      dispatch({ type: 'menu.planning.toggleOASensors' })
                    }
                    indent={1}
                  />
                  {state.planning.showOASensors ? (
                    <Fragment>
                      <LayerListItem
                        icon={
                          <Icons.Tag
                            width={18}
                            height={18}
                            color="currentColor"
                          />
                        }
                        label="Labels"
                        isChecked={state.planning.showSensorLabels}
                        onChange={() =>
                          dispatch({ type: 'menu.planning.toggleSensorLabels' })
                        }
                        indent={2}
                      />
                      <LayerListItem
                        icon={
                          <Icons.Integrations2
                            width={18}
                            height={18}
                            color="currentColor"
                          />
                        }
                        label="Coverage"
                        isChecked={state.planning.showSensorCoverage}
                        onChange={() =>
                          dispatch({
                            type: 'menu.planning.toggleSensorCoverage',
                          })
                        }
                        indent={2}
                      />
                      {sensorCoverageEnabled ? (
                        <LayerListItem
                          icon={
                            <Icons.Router
                              width={18}
                              height={18}
                              color="currentColor"
                            />
                          }
                          label="Extents"
                          isChecked={state.planning.showSensorCoverageExtents}
                          isDisabled={!state.heightMap.enabled}
                          onChange={() =>
                            dispatch({
                              type: 'menu.planning.toggleSensorCoverageExtents',
                            })
                          }
                          indent={2}
                        />
                      ) : null}
                    </Fragment>
                  ) : null}
                </Fragment>
              ) : null}
              <LayerListItem
                icon={
                  <Icons.Space width={18} height={18} color="currentColor" />
                }
                label="Spaces"
                isChecked={state.planning.showSpaces}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleSpaces' })
                }
              />
              <LayerListItem
                icon={
                  <Icons.Ruler width={18} height={18} color="currentColor" />
                }
                label="Reference Rulers"
                isChecked={state.planning.showRulers}
                onChange={() =>
                  dispatch({ type: 'menu.planning.toggleRulers' })
                }
              />
              <LayerListItem
                icon={
                  <Icons.RulerVertical
                    width={18}
                    height={18}
                    color="currentColor"
                  />
                }
                label="Floorplan Scale"
                isChecked={state.planning.showScale}
                onChange={() => dispatch({ type: 'menu.planning.toggleScale' })}
              />
              <LayerListItem
                icon={
                  <Icons.FolderImage
                    width={18}
                    height={18}
                    color="currentColor"
                  />
                }
                label="Photo Groups"
                isChecked={state.planning.showPhotoGroups}
                onChange={() =>
                  dispatch({ type: 'menu.planning.togglePhotoGroups' })
                }
              />

              <LayerListItemHeader>Map Layers</LayerListItemHeader>

              {heightMapEnabled ? (
                <li
                  className={classNames(styles.layerListItem, {
                    [styles.focused]: State.isLayerFocused(
                      state,
                      LayerId.HEIGHTMAP
                    ),
                  })}
                  onClick={() => dispatch({ type: 'layers.heightMap.focus' })}
                  data-cy="height-map-layer-item"
                >
                  <span className={styles.layerListItemIcon}>
                    <Icons.FolderImage
                      width={18}
                      height={18}
                      color="currentColor"
                    />
                  </span>
                  <span className={styles.layerListItemLabel}>Height Map</span>
                  <HorizontalForm size="small">
                    <Switch
                      isChecked={state.planning.showCeilingHeightMap}
                      isDisabled={!state.heightMap.enabled}
                      onChange={(event) =>
                        dispatch({
                          type: 'menu.planning.toggleCeilingHeightMap',
                        })
                      }
                    />
                  </HorizontalForm>
                </li>
              ) : null}

              <LayerListItem
                icon={
                  <Icons.ImageAlt width={18} height={18} color="currentColor" />
                }
                label="Floorplan"
                actions={
                  <div onClick={(e) => e.stopPropagation()}>
                    <HorizontalForm size="small">
                      <Tooltip
                        target={
                          <Button
                            onClick={() => {
                              dispatch({
                                type: 'scaleEdit.begin',
                              });
                            }}
                            trailingIcon={
                              <Icons.RulerVertical
                                height={18}
                                width={18}
                                color="currentColor"
                              />
                            }
                            size="small"
                            type="cleared"
                            data-cy="edit-scale-button"
                          />
                        }
                        contents="Edit Scale"
                      />
                      <Tooltip
                        target={
                          <CADFileUploader
                            onUploadImage={onUploadImage}
                            onUploadCADFile={onUploadCADFile}
                          >
                            {(trigger) => (
                              <Button
                                onClick={trigger}
                                trailingIcon={
                                  <Icons.Switch
                                    height={18}
                                    width={18}
                                    color="currentColor"
                                  />
                                }
                                size="small"
                                type="outlined"
                                data-cy="swap-button"
                              />
                            )}
                          </CADFileUploader>
                        }
                        contents="Swap Floorplan"
                      />
                    </HorizontalForm>
                  </div>
                }
                isCheckable={false}
              />
              <LatestDXFStatus
                state={state}
                client={client}
                plan={plan}
                dispatch={dispatch}
              />
            </ul>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default FloorplanObjectsList;
