import { INTERCOM_APP_ID } from '../config';

export class Engagement {
  static validate() {
    if (!window.Intercom) {
      throw new Error('Intercom not installed on window');
    }
  }

  public static identify(properties: object) {
    Engagement.validate();

    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      hide_default_launcher: true,
      ...properties,
    });
  }

  public static show() {
    Engagement.validate();

    window.Intercom('show');
  }
}
