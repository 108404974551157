import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';
import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Icons } from '@densityco/ui';
import Fuse from 'fuse.js';

import { ReactComponent as ChevronDownIcon } from 'img/icon-chevron-down.svg';
import {
  NewSpaceFunction,
  SPACE_FUNCTION_DISPLAY_NAMES,
  SPACE_FUNCTION_ICONS,
} from 'lib/space-functions';

import GhostButton from 'components/ghost-button/ghost-button';
import {
  DropdownMenuContent,
  DropdownMenuItem,
} from 'components/dropdown/dropdown';

const SmallDropdownMenuItem = styled(DropdownMenuItem)`
  height: 24px;
`;

const SpaceFilterSearch: FC<{
  searchText: string;
  onSearchTextChange: (value: string) => void;
}> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${dust.Space1};
        margin: 0 -4px 4px -4px;
        padding: 0 4px 4px 4px;
        border-bottom: 1px solid ${dust.Gray200};
      `}
    >
      <Icons.Search width={20} height={20} color={dust.Gray400} />

      <input
        ref={inputRef}
        value={props.searchText}
        onKeyDown={(e) => {
          // radix implements dropdown typeahead.
          // https://github.com/radix-ui/primitives/blob/58486382a2afa83e9727d49f2fae857335be6d0f/packages/react/menu/src/Menu.tsx#L615
          // this is a special dropdown since the items change depending on
          // so we stop the propagation of the keydown event so the menu doesn't get it
          e.stopPropagation();
        }}
        onChange={(e) => {
          props.onSearchTextChange(e.target.value);
        }}
        css={css`
          font-size: ${dust.TextScale4};
          border: none;
          flex-grow: 1;
        `}
      />
    </div>
  );
};

type SortOption = 'Name' | 'Most Used';

const SpaceFilterSort: React.FC<{
  sort: SortOption;
  onSortChange: (value: SortOption) => void;
}> = (props) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        font-size: ${dust.TextScale3};
        font-weight: ${dust.TextWeight3};
        margin-bottom: ${dust.Space2};
      `}
    >
      Sort by:&nbsp;
      <div
        onClick={() =>
          props.onSortChange(props.sort === 'Name' ? 'Most Used' : 'Name')
        }
        css={css`
          display: flex;
          align-items: center;
          background: ${dust.Blue100};
          padding: 0 ${dust.Space1};
          border-radius: ${dust.Radius100};
          font-weight: ${dust.FontWeightBold};
          cursor: pointer;
        `}
      >
        {props.sort}&nbsp;
        <Icons.SwapVertical width={12} height={12} color="currentColor" />
      </div>
    </div>
  );
};

const SpaceFilterFunctionDropdown: React.FC<{
  options: Array<{ spaceFunction: NewSpaceFunction; quantity: number }>;
  onSelect: (value: NewSpaceFunction) => void;
}> = (props) => {
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState<SortOption>('Name');

  const fuse = useMemo(() => {
    const options = {
      keys: ['spaceFunction'],
    };

    return new Fuse(props.options, options);
  }, [props.options]);

  const searchResults = searchText
    ? fuse.search(searchText).map((res) => res.item)
    : props.options;

  const displayedOptions = searchResults.sort((a, b) =>
    sort === 'Most Used'
      ? b.quantity - a.quantity
      : a.spaceFunction > b.spaceFunction
      ? 1
      : a.spaceFunction < b.spaceFunction
      ? -1
      : 0
  );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <GhostButton
          aria-label="Filter Spaces by Function"
          css={css`
            width: 42px;
            height: 32px;
            color: ${dust.Gray700};
            background: ${dust.Gray000};
          `}
        >
          <Icons.Space width={20} height={20} color="currentColor" />
          <ChevronDownIcon width={16} height={16} />
        </GhostButton>
      </DropdownMenu.Trigger>

      <DropdownMenuContent align="start">
        <SpaceFilterSearch
          searchText={searchText}
          onSearchTextChange={setSearchText}
        />

        <SpaceFilterSort sort={sort} onSortChange={setSort} />

        {displayedOptions.map((option) => {
          const Icon = SPACE_FUNCTION_ICONS[option.spaceFunction];

          return (
            <SmallDropdownMenuItem
              key={option.spaceFunction}
              css={css`
                gap: 8px;
                justify-content: space-between;
              `}
              onSelect={(e) => props.onSelect(option.spaceFunction)}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                `}
              >
                <Icon height={16} width={16} color={dust.Blue400} />
                <span
                  css={css`
                    font-size: ${dust.TextScale4};
                    font-weight: ${dust.FontWeightMedium};
                  `}
                >
                  {SPACE_FUNCTION_DISPLAY_NAMES[option.spaceFunction]}
                </span>
              </div>
              <span
                css={css`
                  background: ${dust.Gray000};
                  border-radius: ${dust.Radius200};
                  padding: ${dust.Space1} ${dust.Space2};
                  font-size: ${dust.TextScale2};
                  font-weight: ${dust.TextWeight2};
                  color: ${dust.Gray400};
                `}
              >
                {option.quantity}
              </span>
            </SmallDropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu.Root>
  );
};

const SpaceFilterLabelDropdown: React.FC<{
  options: Array<{ label: string; id: string; quantity: number }>;
  onSelect: (id: string) => void;
}> = (props) => {
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState<SortOption>('Name');

  const fuse = useMemo(() => {
    const options = {
      keys: ['label'],
    };

    return new Fuse(props.options, options);
  }, [props.options]);

  const searchResults = searchText
    ? fuse.search(searchText).map((res) => res.item)
    : props.options;

  const displayedOptions = searchResults.sort((a, b) =>
    sort === 'Most Used'
      ? b.quantity - a.quantity
      : a.label > b.label
      ? 1
      : a.label < b.label
      ? -1
      : 0
  );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <GhostButton
          aria-label="Filter Spaces by Label"
          css={css`
            width: 42px;
            height: 32px;
            color: ${dust.Gray700};
            background: ${dust.Gray000};
          `}
        >
          <Icons.Tag width={20} height={20} color="currentColor" />
          <ChevronDownIcon width={16} height={16} />
        </GhostButton>
      </DropdownMenu.Trigger>

      <DropdownMenuContent align="start">
        <SpaceFilterSearch
          searchText={searchText}
          onSearchTextChange={setSearchText}
        />

        <SpaceFilterSort sort={sort} onSortChange={setSort} />

        {displayedOptions.map((option) => {
          return (
            <SmallDropdownMenuItem
              key={option.label}
              css={css`
                gap: 8px;
                justify-content: space-between;
              `}
              onSelect={(e) => props.onSelect(option.id)}
            >
              <span
                css={css`
                  font-size: ${dust.TextScale4};
                  font-weight: ${dust.FontWeightMedium};
                `}
              >
                {option.label}
              </span>
              <span
                css={css`
                  background: ${dust.Gray000};
                  border-radius: ${dust.Radius200};
                  padding: ${dust.Space1} ${dust.Space2};
                  font-size: ${dust.TextScale2};
                  font-weight: ${dust.TextWeight2};
                  color: ${dust.Gray400};
                `}
              >
                {option.quantity}
              </span>
            </SmallDropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu.Root>
  );
};

const SpaceFilterToolbar: React.FC<{
  isActive: boolean;

  functionOptions: Array<{ spaceFunction: NewSpaceFunction; quantity: number }>;
  labelOptions: Array<{ label: string; id: string; quantity: number }>;

  onSelectFunction: (value: NewSpaceFunction) => void;
  onSelectLabel: (id: string) => void;
}> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      css={css`
        width: ${isExpanded ? `${32 + 4 + 42 + 4 + 42}px` : '32px'};
        transition: width 0.2s ease-in-out;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          width: fit-content;
          display: flex;
          gap: ${dust.Space2};
          background: ${dust.Gray000};
          border-radius: ${dust.Radius100};
        `}
      >
        <GhostButton
          aria-label="Show Filters"
          css={css`
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            color: ${props.isActive ? dust.Blue400 : dust.Gray700};
            background-color: ${props.isActive
              ? dust.Blue100
              : isExpanded
              ? dust.Gray100
              : dust.White};
            border-radius: ${dust.Radius100};
          `}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Icons.Filter width={20} height={20} color="currentColor" />
        </GhostButton>

        <SpaceFilterFunctionDropdown
          options={props.functionOptions}
          onSelect={props.onSelectFunction}
        />

        <SpaceFilterLabelDropdown
          options={props.labelOptions}
          onSelect={props.onSelectLabel}
        />
      </div>
    </div>
  );
};

export default SpaceFilterToolbar;
