import { useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { Icons } from '@densityco/ui';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';

import Switch from 'components/switch/switch';
import InternalTool from 'components/internal-tool';
import Button from 'components/button';
import Tooltip from 'components/tooltip';
import { LengthUnit } from 'lib/units';
import BetaLabel from 'components/beta-label/beta-label';
import { TokenCheckResponse } from '@densityco/lib-common-auth';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';

type UnitChoice = { id: LengthUnit; label: string };
const UNIT_CHOICES: Array<UnitChoice> = [
  { id: 'feet_and_inches', label: 'ft & in' },
  { id: 'inches', label: 'in' },
  { id: 'meters', label: 'm' },
  { id: 'centimeters', label: 'cm' },
  { id: 'millimeters', label: 'mm' },
];

const SettingsPanel: React.FunctionComponent<{
  state: State;
  dispatch: React.Dispatch<Action>;
  tokenCheckResponse?: TokenCheckResponse;
}> = ({ state, dispatch, tokenCheckResponse }) => {
  const isShowTracksOn = useTreatment(SPLITS.SHOW_TRACKS);

  const [panelOpen, setPanelOpen] = useState<boolean>(false);

  return (
    <div className={styles.settingsPanel}>
      <Tooltip
        contents="Plan settings"
        placement="bottom"
        target={
          <Button
            onClick={() => setPanelOpen(!panelOpen)}
            trailingIcon={
              <Icons.Cog height={18} width={18} color="currentColor" />
            }
            size="medium"
            type="outlined"
            data-cy="settings-panel-button"
          />
        }
      />
      <div
        className={classNames(styles.settingsMenu, {
          [styles.open]: panelOpen,
        })}
      >
        <div className={styles.settingsMenuSection}>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelIcon}>
                <Icons.RulerVertical
                  height={16}
                  width={16}
                  color="currentColor"
                />
              </div>
              <div>Units</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              {/* NOTE: The below will work, but I don't feel like fighting the styles/contexts */}
              {/* <InputBoxContext.Provider value={'LIST_VIEW'}>
                <InputBox
                  type="select"
                  choices={UNIT_CHOICES}
                  value={state.displayUnit}
                  onChange={(choice: UnitChoice) => {
                    dispatch({
                      type: 'referenceList.setDisplayUnit',
                      displayUnit: choice.id,
                    });
                  }}
                  width={60}
                />
              </InputBoxContext.Provider> */}
              <select
                className={styles.settingsMenuUnitSelect}
                onChange={(evt) => {
                  dispatch({
                    type: 'referenceList.setDisplayUnit',
                    displayUnit: evt.currentTarget.value as LengthUnit,
                  });
                }}
                data-cy="settings-panel-unit-select"
              >
                {UNIT_CHOICES.map((choice) => {
                  return (
                    <option key={choice.id} value={choice.id}>
                      {choice.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {isShowTracksOn ? (
            <div
              className={classNames(
                styles.settingsMenuRow,
                styles.settingsMenuRowInteractive
              )}
              // NOTE: the entire row is a clickable toggle target
              onClick={() => {
                dispatch({ type: 'menu.streaming.toggleTracks' });
              }}
            >
              <div className={styles.settingsMenuRowLabel}>
                <div className={styles.settingsMenuRowLabelIcon}>
                  <Icons.PersonFull
                    height={16}
                    width={16}
                    color="currentColor"
                  />
                </div>
                <div>
                  Activity
                  <BetaLabel />
                </div>
              </div>
              <div className={styles.settingsMenuRowAction}>
                <Switch isChecked={state.streaming.showTracks} />
              </div>
            </div>
          ) : null}
          <div
            className={classNames(
              styles.settingsMenuRow,
              styles.settingsMenuRowInteractive
            )}
            // NOTE: the entire row is a clickable toggle target
            onClick={() => {
              dispatch({ type: 'menu.streaming.togglePoints' });
            }}
          >
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelIcon}>
                <Icons.CleanMagicSparkle
                  height={16}
                  width={16}
                  color="currentColor"
                />
              </div>
              <div>Points</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <Switch isChecked={state.streaming.showPoints} />
            </div>
          </div>
          <InternalTool>
            <div className={styles.settingsMenuHeader}>Internal</div>
            <div
              className={classNames(
                styles.settingsMenuRow,
                styles.settingsMenuRowInteractive
              )}
              // NOTE: the entire row is a clickable toggle target
              onClick={() => {
                if (state.simulation.enabled) {
                  dispatch({ type: 'simulation.menu.disableSimulation' });
                } else {
                  dispatch({ type: 'simulation.menu.enableSimulation' });
                }
              }}
            >
              <div className={styles.settingsMenuRowLabel}>
                <div className={styles.settingsMenuRowLabelIcon}>
                  <Icons.PersonHuddle
                    height={16}
                    width={16}
                    color="currentColor"
                  />
                </div>
                <div>Sims</div>
              </div>
              <div className={styles.settingsMenuRowAction}>
                <Switch isChecked={state.simulation.enabled} />
              </div>
            </div>
          </InternalTool>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;
