import Select, { Props } from 'react-select';
import {
  ActionMeta,
  ControlProps,
  SingleValue,
  PlaceholderProps,
} from 'react-select/dist/declarations/src';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import * as React from 'react';
import { CSSObject } from '@emotion/react';

import {
  NewSpaceFunction,
  SPACE_FUNCTION_DISPLAY_NAMES,
  SPACE_FUNCTION_ICONS,
} from 'lib/space-functions';

export type Value = { value: NewSpaceFunction; label: string };
export type Options = Array<Value>;

export function valueToOption(value: NewSpaceFunction) {
  return {
    value,
    label: SPACE_FUNCTION_DISPLAY_NAMES[value],
  };
}

export function FunctionIcon({ value }: { value: NewSpaceFunction }) {
  const IconComponent = SPACE_FUNCTION_ICONS[value];
  return <IconComponent />;
}

function DropdownIndicator() {
  return (
    <svg
      height="15"
      width="15"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      fill="currentColor"
    >
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
}

const getCustomStyles = (params: {
  controlStyles?: (base: CSSObject, props: ControlProps<Value>) => CSSObject;
  placeholderStyles?: (
    base: CSSObject,
    props: PlaceholderProps<Value>
  ) => CSSObject;
}): Props<Value, false>['styles'] => {
  return {
    control: (provided, state) => ({
      ...provided,

      border: 0,
      padding: 0,
      transform: 'translate(-6px, -0.5px)',
      boxShadow: 'none',
      background: state.menuIsOpen ? dust.Blue100 : 'none',
      fontSize: 12,
      justifyContent: 'flex-start',
      color: state.hasValue ? dust.Blue400 : dust.Gray400,
      minHeight: 20,
      height: 20,
      lineHeight: '12px',
      fontWeight: 600,
      width: 'fit-content',

      ...params.controlStyles?.(provided, state),
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 20,
      paddingRight: 3,
      paddingLeft: 4,
      paddingTop: 1,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 2px 2px 6px',
      height: 20,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
    }),
    singleValue: () => ({
      height: 12,
      lineHeight: '12px',
      transform: 'translateY(-0px)', // Because when it was searchable, the input/cursor was messing up the height
      color: dust.Blue400,
    }),
    option: (provided, state) => ({
      background: state.isFocused ? dust.Gray100 : dust.White,
      padding: 4,
      fontSize: 12,
      fontWeight: 500,
      whiteSpace: 'nowrap',
    }),
    menu: (provided) => ({
      ...provided,
      width: 'fit-content',
      padding: '0 4px',
      cursor: 'pointer',
      marginTop: 5,
    }),
    placeholder: (provided, state) => ({
      ...provided,

      fontSize: dust.TextScale2,
      fontWeight: dust.FontWeightBold,
      color: dust.Gray400,

      ...params.placeholderStyles?.(provided, state),
    }),
  };
};

type FunctionDropdownProps = {
  value?: Value;
  options: Options;
  placeholder?: string;

  // style overrides
  placeholderStyles?: (
    base: CSSObject,
    props: PlaceholderProps<Value>
  ) => CSSObject;
  controlStyles?: (base: CSSObject, props: ControlProps<Value>) => CSSObject;

  onChange?: (
    newValue: SingleValue<Value>,
    actionMeta: ActionMeta<Value>
  ) => void;
};

const FunctionDropdown: React.FC<FunctionDropdownProps> = (props) => {
  const customStyles = React.useMemo(() => {
    return getCustomStyles({
      placeholderStyles: props.placeholderStyles,
      controlStyles: props.controlStyles,
    });
  }, [props.controlStyles, props.placeholderStyles]);

  return (
    <Select
      value={props.value}
      options={props.options}
      isSearchable={false}
      components={{
        DropdownIndicator,
      }}
      formatOptionLabel={(option, meta) => {
        // Don't render an icon for the selected value at the top of the dropdown
        if (meta.context === 'value') {
          return SPACE_FUNCTION_DISPLAY_NAMES[option.value];
        }

        // Get the icon component for this space function
        const Icon = SPACE_FUNCTION_ICONS[option.value];

        // Render the Icon and label together
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon height={16} color={dust.Blue400} />
            &nbsp;
            {SPACE_FUNCTION_DISPLAY_NAMES[option.value]}
          </div>
        );
      }}
      placeholder={props.placeholder || 'Select function'}
      styles={customStyles}
      onChange={props.onChange}
      menuPosition="fixed"
    />
  );
};

export default FunctionDropdown;
