import * as PIXI from 'pixi.js';
import { FloorplanLayerContextData } from './types';
import { ViewportCoordinates, FloorplanCoordinates } from 'lib/geometry';
import { FixMe } from 'types/fixme';

// Check to see if a given set of coordinates are inside of the viewport.
// Also takes a "padding" value, which is a number of pixels inset the coordinates must be from the
// edge of the viewport.
export function isWithinViewport(
  context: FloorplanLayerContextData,
  coords: ViewportCoordinates,
  padding = 0
) {
  return (
    coords.x > padding &&
    coords.y > padding &&
    coords.x < context.app.screen.width - padding &&
    coords.y < context.app.screen.height - padding
  );
}

// Use this function to register a function that will be called with new floorplan coordinates when
// a new objet moves

const EDGE_SCROLL_TRIGGER_WIDTH = 16; // pixels
const EDGE_SCROLL_VELOCITY = 300; // pixels per second

export function addDragHandler(
  context: FloorplanLayerContextData,
  originalPosition: FloorplanCoordinates,
  mouseDownEvent: FixMe, // FIXME: can't figure out the type of the pixi js mouse down event
  onDrag: (
    position: FloorplanCoordinates,
    rawMousePosition: ViewportCoordinates,
    evt: MouseEvent
  ) => void,
  onRelease?: () => void
): () => void {
  if (!context.viewport.current) {
    throw new Error(
      'Viewport (in floorplan context) is null, this shouldnt happen!'
    );
  }

  const canvasOffsetX =
    mouseDownEvent.data.originalEvent.clientX - mouseDownEvent.data.global.x;
  const canvasOffsetY =
    mouseDownEvent.data.originalEvent.clientY - mouseDownEvent.data.global.y;

  const originalPositionViewport = FloorplanCoordinates.toViewportCoordinates(
    originalPosition,
    context.floorplan,
    context.viewport.current
  );
  const offsetFromSensorX =
    mouseDownEvent.data.global.x - originalPositionViewport.x;
  const offsetFromSensorY =
    mouseDownEvent.data.global.y - originalPositionViewport.y;

  // Apply edge scrolling when at the boundary of the viewport
  let edgeScrollingInterval: ReturnType<typeof setInterval> | null = null;
  function applyEdgeScrolling(
    viewportPosition: ViewportCoordinates,
    axis: 'x' | 'y'
  ): boolean {
    const viewportKey = axis === 'x' ? 'left' : 'top';
    if (viewportPosition[axis] < EDGE_SCROLL_TRIGGER_WIDTH) {
      if (!edgeScrollingInterval) {
        edgeScrollingInterval = setInterval(() => {
          if (!context.viewport.current) {
            return;
          }
          context.viewport.current[viewportKey] -=
            EDGE_SCROLL_VELOCITY / 100 / context.viewport.current.zoom;
        }, 10);
      }
      return true;
    } else if (
      viewportPosition[axis] >
      context.app.screen[axis === 'y' ? 'height' : 'width'] -
        EDGE_SCROLL_TRIGGER_WIDTH
    ) {
      if (!edgeScrollingInterval) {
        edgeScrollingInterval = setInterval(() => {
          if (!context.viewport.current) {
            return;
          }
          context.viewport.current[viewportKey] +=
            EDGE_SCROLL_VELOCITY / 100 / context.viewport.current.zoom;
        }, 10);
      }
      return true;
    }
    return false;
  }

  function onMouseMove(evt: MouseEvent) {
    if (!context.viewport.current) {
      return;
    }

    // Ensure when dragging an object, the user doesn't inadvertantly select unrelated
    // interface elements
    document.body.style.userSelect = 'none';

    const x = evt.clientX - canvasOffsetX;
    const y = evt.clientY - canvasOffsetY;

    const rawMousePosition = ViewportCoordinates.create(x, y);

    const viewportPosition = ViewportCoordinates.create(
      x - offsetFromSensorX,
      y - offsetFromSensorY
    );

    const appliedEdgeScrollingX = applyEdgeScrolling(viewportPosition, 'x');
    const appliedEdgeScrollingY = applyEdgeScrolling(viewportPosition, 'y');
    if (
      edgeScrollingInterval &&
      !appliedEdgeScrollingX &&
      !appliedEdgeScrollingY
    ) {
      // Reset edge scrolling interval if no longer in the edge scrolling region
      clearInterval(edgeScrollingInterval);
      edgeScrollingInterval = null;
    }

    const floorplanPosition = ViewportCoordinates.toFloorplanCoordinates(
      viewportPosition,
      context.viewport.current,
      context.floorplan
    );

    onDrag(floorplanPosition, rawMousePosition, evt);
  }

  function unregister() {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);

    if (edgeScrollingInterval) {
      clearInterval(edgeScrollingInterval);
      edgeScrollingInterval = null;
    }

    if (onRelease) {
      onRelease();
    }
  }

  function onMouseUp() {
    document.body.style.userSelect = '';
    unregister();
  }

  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', onMouseUp);

  return unregister;
}

export function drawPolygon(
  graphics: PIXI.Graphics,
  vertices: Array<ViewportCoordinates>
) {
  graphics.moveTo(vertices[0].x, vertices[0].y);
  for (let index = 1; index < vertices.length; index += 1) {
    graphics.lineTo(vertices[index].x, vertices[index].y);
  }
  graphics.lineTo(vertices[0].x, vertices[0].y);
}

// Given a hex color, convert it into the format that pixi.js needs to function
export function toRawHex(stringHexColor: string): number {
  return Number('0x' + stringHexColor.replace('#', ''));
}
