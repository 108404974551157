import * as React from 'react';
import {
  Icons,
  PercentageBar,
  PercentageBarContext,
  Tooltip,
} from '@densityco/ui';
import { useRifm } from 'rifm';
import moment from 'moment-timezone';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ValueLabel from 'components/value-label/value-label';
import FunctionDropdown, {
  valueToOption,
} from 'components/function-dropdown/function-dropdown';
import ChartBusiestDay from 'components/chart-busiest-day/chart-busiest-day';
import LabelEditor, {
  diffLabels,
  ReadonlyLabels,
} from 'components/label-editor/label-editor';
import InlineInput from 'components/inline-input/inline-input';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import {
  NewSpaceFunction,
  spaceFunctionToNewSpaceFunction,
  SPACE_FUNCTION_DISPLAY_NAMES,
  SPACE_FUNCTION_ICONS,
} from 'lib/space-functions';
import { computeHoursPerDay } from 'lib/date-time';
import ChartBusiestTime from 'components/chart-busiest-time/chart-busiest-time';
import { ReactComponent as HelpQuestionMark } from 'img/help-question-mark.svg';
import {
  getUtilizationChartSummaryDay,
  getUtilizationChartSummaryTime,
  utilizationByHourToChartAvailabilityByDayData,
} from 'lib/charts';
import ChartOccupancyDistribution from 'components/chart-occupancy-distribution/chart-occupancy-distribution';
import { asyncSaveSpaceLabelsThunk } from 'redux/features/spaces/async-save-space-labels-thunk';
import { asyncUpdateSpaceThunk } from 'redux/features/spaces/async-update-space-thunk';
import { selectAvgUtilizationForSpace } from 'redux/features/analysis/select-avg-utilization-for-space';
import { selectDistributionChartDataForSpace } from 'redux/features/analysis/select-distribution-analytics-for-space';
import { selectLabelOptions } from 'redux/features/spaces/select-label-options';
import { selectMaxUtilizationForSpace } from 'redux/features/analysis/select-max-utilization-for-space';
import { selectUtilizationChartDataByHourForSpace } from 'redux/features/analysis/select-utilization-chart-data-by-hour-for-space';
import { selectUtilizationChartDataByDayForSpace } from 'redux/features/analysis/select-utilization-chart-data-by-day-for-space';
import ChartDisplayToggle from 'components/chart-display-toggle/chart-display-toggle';
import { useUpdate } from 'hooks/use-update';
import { isSingleOccupancy } from 'lib/space';
import {
  NO_VALUE_PLACEHOLDER,
  toHumanReadableTime,
  toHumanReadableNumber,
  toHumanReadablePercent,
} from 'lib/text';
import { createSelector } from '@reduxjs/toolkit';
import ChartAvailabilityByDay from 'components/chart-availability-by-day/chart-availability-by-day';
import { selectRankInfo } from 'redux/features/analysis/select-rank-info';
import Badge from 'components/badge/badge';
import { PlanDetail } from 'lib/api';
import { spacesSelectors } from 'redux/features/spaces/spaces-slice';
import { selectTimeZone } from 'redux/features/spaces/select-timezone';
import { Floor } from 'lib/floors';
import { CoreSpace } from '@densityco/lib-api-types';
import { utilizationAnalyticsByHourSelectors } from 'redux/features/analysis/analysis-slice';
import { asyncFetchDistributionAnalyticsThunk } from 'redux/features/analysis/async-fetch-distribution-analytics-thunk';
import { asyncFetchUtilizationAnalyticsByHourThunk } from 'redux/features/analysis/async-fetch-utilization-analytics-by-hour-thunk';
import { asyncFetchDwellAnalyticsThunk } from 'redux/features/analysis/async-fetch-dwell-analytics-thunk';
import { selectAvgDwellForSpace } from 'redux/features/analysis/select-avg-dwell-for-space';
import { selectMaxDwellForSpace } from 'redux/features/analysis/select-max-dwell-for-space';
import { asyncFetchTimeUsedAnalyticsThunk } from 'redux/features/analysis/async-fetch-time-used-analytics-thunk';
import ChartTimeUsedByDay from 'components/chart-time-used-by-day/chart-time-used-by-day';
import { selectTimeUsedChartDataForSpace } from 'redux/features/analysis/select-time-used-chart-data-for-space';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import { selectTimeUsedMetricDataForSpace } from 'redux/features/analysis/select-time-used-metric-data-for-space';

// tooltip contents https://www.notion.so/densityinc/OA-Historical-Utilization-MLP-Tooltips-abcb944778914d86b9cdf2f27d2388d9

enum ChartDisplay {
  Hours = 'hrs',
  Percentage = '%',
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const Section = styled.section`
  padding: ${dust.Space4};
  border-bottom: 1px solid ${dust.Gray200};
`;

const TimeUsedByDaySection: React.FC<{ spaceId: string; floor: Floor }> = (
  props
) => {
  const timeZone = useAppSelector((state) =>
    selectTimeZone(state, { floorId: props.floor.id })
  );

  const timeUsedChartDataForSpace = useAppSelector((state) =>
    selectTimeUsedChartDataForSpace(state, {
      floorId: props.floor.id,
      spaceId: props.spaceId,
    })
  );

  if (!timeUsedChartDataForSpace) {
    return null;
  }

  if (timeUsedChartDataForSpace.length <= 1) {
    return null;
  }

  return (
    <Section>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <span
            css={css`
              font-size: ${dust.TextScale4};
              color: ${dust.Gray400};
            `}
          >
            Time Used by Day
          </span>

          <Tooltip
            contents="The amount of time that this space was used per day."
            target={
              <div
                css={css`
                  margin-left: 4px;
                `}
              >
                <HelpQuestionMark height={12} width={12} color={dust.Gray400} />
              </div>
            }
          />
        </div>
      </div>
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <ChartTimeUsedByDay
          width={235}
          height={160}
          data={timeUsedChartDataForSpace}
          timeZone={timeZone}
        />
      </div>
    </Section>
  );
};

const AvailabilityByDaySection: React.FC<{ spaceId: string; floor: Floor }> = (
  props
) => {
  const timeZone = useAppSelector((state) =>
    selectTimeZone(state, { floorId: props.floor.id })
  );

  const utilizationAnalyticsForSpace = useAppSelector((state) =>
    state.analysis.utilizationAnalyticsByHour.entities[
      props.spaceId
    ]?.data?.map((x) => ({
      time: x.date,
      value: x.max,
    }))
  );

  const chartData = React.useMemo(() => {
    if (!utilizationAnalyticsForSpace) {
      return;
    }

    return utilizationByHourToChartAvailabilityByDayData(
      utilizationAnalyticsForSpace,
      timeZone
    );
  }, [utilizationAnalyticsForSpace, timeZone]);

  if (!chartData) {
    return null;
  }

  if (chartData.length <= 1 || chartData[0].rows.length <= 1) {
    return null;
  }

  return (
    <Section>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <span
            css={css`
              font-size: ${dust.TextScale4};
              color: ${dust.Gray400};
            `}
          >
            Availability by Day
          </span>

          <Tooltip
            contents="The hours that this space was available vs occupied."
            target={
              <div
                css={css`
                  margin-left: 4px;
                `}
              >
                <HelpQuestionMark height={12} width={12} color={dust.Gray400} />
              </div>
            }
          />
        </div>
      </div>
      <div
        css={css`
          margin-top: ${dust.Space3};
        `}
      >
        <ChartAvailabilityByDay columns={chartData} timeZone={timeZone} />
      </div>
    </Section>
  );
};

// https://www.notion.so/densityinc/Space-Performance-Rank-against-same-function-0afbf1f6ff40488eac0c279556c6c936#7eb82520df584e6db2730ca1c56bc33f
const PerformanceSection: React.FC<{
  areaId: string;
  space: CoreSpace;
  floor: Floor;
  planDetail: PlanDetail;
}> = (props) => {
  const rankInfo = useAppSelector((state) =>
    selectRankInfo(state, {
      spaceId: props.floor.id,
      floorId: props.floor.id,
      selfSpaceId: props.space.id,
      planId: props.planDetail.id,
    })
  );

  const timeUsedDatum = useAppSelector((state) =>
    selectTimeUsedMetricDataForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  const avgUtilizationForSpace = useAppSelector((state) =>
    selectAvgUtilizationForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  if (
    !rankInfo ||
    !props.space?.function ||
    avgUtilizationForSpace === undefined ||
    !timeUsedDatum
  ) {
    return null;
  }

  const isTooLarge = avgUtilizationForSpace < 0.5;
  const isTooSmall = avgUtilizationForSpace > 1;
  const isRightSize = !isTooLarge && !isTooSmall;

  const isTooVacant = timeUsedDatum.percent < 0.5;
  const isRarelyAvailable = timeUsedDatum.percent > 0.8;
  const isWellUtilized = !isTooVacant && !isRarelyAvailable;

  const isDescriptionConflicting =
    ((isTooLarge || isTooSmall) && isWellUtilized) ||
    ((isTooVacant || isRarelyAvailable) && isRightSize);

  return (
    <Section>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <span
            css={css`
              font-size: ${dust.TextScale4};
              color: ${dust.Gray400};
            `}
          >
            Performance
          </span>

          <Tooltip
            contents="The performance of a space relative to other spaces of the same function. Ranking is based on average utilization and % time used."
            target={
              <HelpQuestionMark height={12} width={12} color={dust.Gray400} />
            }
          />
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <span
            css={css`
              color: ${dust.Gray400};
              font-size: ${dust.TextScale2};
              font-weight: ${dust.FontWeightMedium};
            `}
          >
            {rankInfo.total - rankInfo.rankIndex} / {rankInfo.total}
          </span>

          {rankInfo.total > 2 ? (
            <>
              {rankInfo.rankIndex === 0 ? (
                <Badge intent="failure">Lowest</Badge>
              ) : rankInfo.rankIndex === rankInfo.total - 1 ? (
                <Badge intent="success">Highest</Badge>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      <div
        css={css`
          margin-top: 12px;
        `}
      >
        <span
          css={css`
            font-size: ${dust.TextScale3};
            font-weight: ${dust.FontWeightMedium};
          `}
        >
          <span>This space is </span>
          <span
            css={css`
              color: ${isWellUtilized ? dust.Green400 : dust.Red400};
            `}
          >
            {isWellUtilized
              ? 'well utilized'
              : isTooVacant
              ? 'too vacant'
              : 'rarely available'}
          </span>
          <span> {isDescriptionConflicting ? 'but' : 'and'} </span>
          <span
            css={css`
              color: ${isRightSize ? dust.Green400 : dust.Red400};
            `}
          >
            {isRightSize
              ? 'right-sized'
              : isTooLarge
              ? 'too large'
              : 'too small'}
          </span>

          {rankInfo.total > 1 ? (
            <>
              <span> relative to other </span>
              <span
                css={css`
                  color: ${dust.Blue400};
                `}
              >
                {
                  SPACE_FUNCTION_DISPLAY_NAMES[
                    spaceFunctionToNewSpaceFunction(props.space.function)
                  ]
                }
                s
              </span>
              <span> on this floor.</span>
            </>
          ) : (
            '.'
          )}
        </span>
      </div>
    </Section>
  );
};

const DistributionSection: React.FC<{ spaceId: string }> = (props) => {
  const [distributionDisplay, setDistributionDisplay] =
    React.useState<ChartDisplay>(ChartDisplay.Hours);

  const distributionChartDataForSpace = useAppSelector((state) =>
    selectDistributionChartDataForSpace(state, {
      spaceId: props.spaceId,
    })
  );

  if (!distributionChartDataForSpace?.length) {
    return null;
  }

  return (
    <Section>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <span
            css={css`
              font-size: ${dust.TextScale4};
              color: ${dust.Gray400};
            `}
          >
            Group Sizes
          </span>

          <Tooltip
            contents="The amount of time a given number of people used the space together."
            target={
              <div
                css={css`
                  margin-left: 4px;
                `}
              >
                <HelpQuestionMark height={12} width={12} color={dust.Gray400} />
              </div>
            }
          />
        </div>

        <ChartDisplayToggle
          value={distributionDisplay}
          options={[ChartDisplay.Hours, ChartDisplay.Percentage]}
          onChange={setDistributionDisplay}
        />
      </div>

      <div
        css={css`
          margin-top: ${dust.Space3};
        `}
      >
        <ChartOccupancyDistribution
          data={distributionChartDataForSpace}
          // TODO(gus): what are the criteria for this chart to be boolean?
          // Perhaps use a helper to toggle all behavior based on function and/or capacity
          isBoolean={false}
          getValue={(datum) => {
            if (distributionDisplay === ChartDisplay.Hours) {
              return datum.percentage;
            }

            return datum.raw;
          }}
          getLegendLabel={(datum) => {
            if (distributionDisplay === ChartDisplay.Percentage) {
              return toHumanReadablePercent(datum.percentage);
            }

            const hours = moment.duration(datum.raw, 'milliseconds').asHours();

            return hours === 0
              ? '0hr'
              : hours < 1
              ? '< 1hr'
              : `${hours.toFixed(0)}hr`;
          }}
        />
      </div>
    </Section>
  );
};

const BusiestDayAndTimeSection: React.FC<{ space: CoreSpace; floor: Floor }> = (
  props
) => {
  const space = props.space;

  const utilizationChartDataByHourForSpace = useAppSelector((state) =>
    selectUtilizationChartDataByHourForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  const utilizationAnalyticsByHourForSpace = useAppSelector((state) =>
    utilizationAnalyticsByHourSelectors.selectById(state, props.space.id)
  );

  const utilizationChartDataByDayForSpace = useAppSelector((state) =>
    selectUtilizationChartDataByDayForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  const timeZone = useAppSelector((state) =>
    selectTimeZone(state, { floorId: props.floor.id })
  );

  const peakOccupancyDatum = React.useMemo(() => {
    if (!utilizationAnalyticsByHourForSpace) {
      return;
    }

    let maxValue = -Infinity;
    let maxIndex = -1;

    utilizationAnalyticsByHourForSpace.data.forEach((datum, i) => {
      if (datum.max > maxValue) {
        maxValue = datum.max;
        maxIndex = i;
      }
    });

    return utilizationAnalyticsByHourForSpace.data[maxIndex];
  }, [utilizationAnalyticsByHourForSpace]);

  return (
    <Section>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <span
          css={css`
            font-size: ${dust.TextScale4};
            color: ${dust.Gray400};
          `}
        >
          Insights
        </span>

        <Tooltip
          contents="The typical day of the week and typical time of day the space had the highest occupancy during the time selected."
          target={
            <div
              css={css`
                margin-left: 4px;
              `}
            >
              <HelpQuestionMark height={12} width={12} color={dust.Gray400} />
            </div>
          }
        />
      </div>

      <div
        css={css`
          margin-top: ${dust.Space3};
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <span
            css={css`
              font-size: ${dust.TextScale2};
              font-weight: ${dust.FontWeightBold};
              color: ${dust.Gray400};
            `}
          >
            Busiest Day
          </span>

          {utilizationChartDataByDayForSpace &&
          utilizationChartDataByDayForSpace.length > 1 ? (
            <>
              <span
                css={css`
                  font-size: ${dust.TextScale5};
                  font-weight: ${dust.FontWeightMedium};
                `}
              >
                {getUtilizationChartSummaryDay(
                  utilizationChartDataByDayForSpace,
                  timeZone
                )}
              </span>

              <div
                css={css`
                  height: 36px;
                  width: 100px;
                  margin-top: 12px;
                `}
              >
                <ChartBusiestDay
                  width={100}
                  height={36}
                  timeZone={timeZone}
                  data={utilizationChartDataByDayForSpace}
                />
              </div>
            </>
          ) : (
            <span
              css={css`
                font-size: ${dust.TextScale5};
                font-weight: ${dust.FontWeightMedium};
              `}
            >
              {NO_VALUE_PLACEHOLDER}
            </span>
          )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <span
            css={css`
              font-size: ${dust.TextScale2};
              font-weight: ${dust.FontWeightBold};
              color: ${dust.Gray400};
            `}
          >
            Busiest Time
          </span>

          {utilizationChartDataByHourForSpace &&
          utilizationChartDataByHourForSpace.length > 1 ? (
            <>
              <span
                css={css`
                  font-size: ${dust.TextScale5};
                  font-weight: ${dust.FontWeightMedium};
                `}
              >
                {getUtilizationChartSummaryTime(
                  utilizationChartDataByHourForSpace,
                  timeZone
                )}
              </span>
              <div
                css={css`
                  height: 36px;
                  width: 100px;
                  margin-top: 12px;
                `}
              >
                <ChartBusiestTime
                  width={100}
                  height={36}
                  timeZone={timeZone}
                  data={utilizationChartDataByHourForSpace}
                />
              </div>
            </>
          ) : (
            <span
              css={css`
                font-size: ${dust.TextScale5};
                font-weight: ${dust.FontWeightMedium};
              `}
            >
              {NO_VALUE_PLACEHOLDER}
            </span>
          )}
        </div>
      </div>

      {utilizationChartDataByHourForSpace &&
      utilizationChartDataByHourForSpace.length > 1 &&
      peakOccupancyDatum &&
      space.capacity ? (
        // TODO(wuweiweiwu): need to find some sort of templating library
        <div
          css={css`
            margin-top: ${dust.Space3};
          `}
        >
          <span
            css={css`
              font-size: ${dust.TextScale3};
              font-weight: ${dust.FontWeightMedium};
            `}
          >
            <span>Peak occupancy of </span>
            <span
              css={css`
                color: ${dust.Blue400};
              `}
            >
              {toHumanReadableNumber(peakOccupancyDatum.max)} people
            </span>
            <span> occurred on </span>
            <span
              css={css`
                color: ${dust.Blue400};
              `}
            >
              {moment
                .tz(peakOccupancyDatum.date, timeZone)
                .format('ddd, MMM Do')}
            </span>
            <span> at </span>
            <span
              css={css`
                color: ${dust.Blue400};
              `}
            >
              {moment.tz(peakOccupancyDatum.date, timeZone).format('ha')}
            </span>
          </span>
        </div>
      ) : null}
    </Section>
  );
};

const LabelSection: React.FC<{ space: CoreSpace }> = (props) => {
  const [key, update] = useUpdate();

  const dispatch = useAppDispatch();

  const labelOptions = useAppSelector(selectLabelOptions);

  const [labelValues, setLabelValues] = React.useState<ReadonlyLabels>([]);
  const [initialLabelValues, setInitialLabelValues] =
    React.useState<ReadonlyLabels>([]);

  React.useEffect(() => {
    const labelValues =
      props.space?.labels.map((l) => ({ value: l.id, label: l.name })) || [];
    setLabelValues(labelValues);
    setInitialLabelValues(labelValues);
  }, [key, props.space?.labels]);

  return (
    <Section>
      <span
        css={css`
          font-size: ${dust.TextScale4};
          color: ${dust.Gray400};
        `}
      >
        Labels
      </span>

      <div
        css={css`
          margin-top: ${dust.Space3};
        `}
      >
        <LabelEditor
          value={labelValues}
          options={labelOptions}
          onChange={(value) => {
            setLabelValues(value);
          }}
          onBlur={() => {
            const diff = diffLabels(initialLabelValues, labelValues);

            const addedLabelIds = diff.creates
              .filter((l) => !l.__isNew__)
              .map((l) => l.value);

            const removedLabelIds = diff.deletes.map((l) => l.value);

            const createdLabelNames = diff.creates
              .filter((l) => l.__isNew__)
              .map((l) => l.label);

            dispatch(
              asyncSaveSpaceLabelsThunk({
                spaceId: props.space.id,

                addedLabelIds,
                removedLabelIds,
                createdLabelNames,
              })
            ).then(() => {
              update();
            });
          }}
        />
      </div>
    </Section>
  );
};

const selectOccupiedHours = createSelector(
  [
    selectTimeUsedMetricDataForSpace,
    selectTimeZone,
    (state: RootState) => state.analysis.dates,
    (state: RootState) => state.analysis.filterDays,
  ],
  (timeUsedDatum, timeZone, dates, filterDays) => {
    if (!timeUsedDatum) {
      return;
    }

    const hours = moment
      .duration(timeUsedDatum.timeUsed, 'milliseconds')
      .asHours();

    const computedHoursPerDay = computeHoursPerDay(
      hours,
      dates || [moment.tz(timeZone), moment.tz(timeZone)],
      filterDays,
      timeZone
    );

    return computedHoursPerDay;
  }
);

const MetricsSection: React.FC<{
  areaId: string;
  space: CoreSpace;
  floor: Floor;
}> = (props) => {
  const isDwellOn = useTreatment(SPLITS.DWELL);

  const timeUsedDatum = useAppSelector((state) =>
    selectTimeUsedMetricDataForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  console.log({ timeUsedDatum });

  const occupiedHours = useAppSelector((state) =>
    selectOccupiedHours(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  const avgUtilizationForSpace = useAppSelector((state) =>
    selectAvgUtilizationForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  const maxUtilizationForSpace = useAppSelector((state) =>
    selectMaxUtilizationForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  const avgDwellForSpace = useAppSelector((state) =>
    selectAvgDwellForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  const maxDwellForSpace = useAppSelector((state) =>
    selectMaxDwellForSpace(state, {
      spaceId: props.space.id,
      floorId: props.floor.id,
    })
  );

  return (
    <Section>
      <div
        css={css`
          display: grid;
          row-gap: 12px;
          column-gap: 40px;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 12px;
        `}
      >
        {!isSingleOccupancy(props.space) ? (
          <>
            <ValueLabel
              label="Peak Utilization"
              value={
                maxUtilizationForSpace === undefined
                  ? undefined
                  : toHumanReadablePercent(maxUtilizationForSpace)
              }
            />
            <ValueLabel
              label="Avg Utilization"
              value={
                avgUtilizationForSpace === undefined
                  ? undefined
                  : toHumanReadablePercent(avgUtilizationForSpace)
              }
            />
          </>
        ) : null}

        {isDwellOn ? (
          <>
            <ValueLabel
              label="Peak Dwell"
              value={
                maxDwellForSpace === undefined
                  ? undefined
                  : toHumanReadableTime(maxDwellForSpace)
              }
            />

            <ValueLabel
              label="Avg Dwell"
              value={
                avgDwellForSpace === undefined
                  ? undefined
                  : toHumanReadableTime(avgDwellForSpace)
              }
            />
          </>
        ) : null}
      </div>

      <span
        css={css`
          font-size: ${dust.TextScale2};
          font-weight: ${dust.FontWeightBold};
          color: ${dust.Gray400};
        `}
      >
        Time Used
      </span>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${dust.Space3};

          div {
            span {
              width: auto !important;
            }
          }
        `}
      >
        <PercentageBarContext.Provider value="LIST_VIEW">
          <PercentageBar percentage={timeUsedDatum?.percent || 0} />
        </PercentageBarContext.Provider>

        <span
          css={css`
            font-size: ${dust.TextScale4};
            color: ${dust.Gray400};
            white-space: nowrap;
          `}
        >
          {toHumanReadableNumber(occupiedHours || 0)} hrs
        </span>
      </div>
    </Section>
  );
};

const FunctionAndCapacityEditSection: React.FC<{
  space: CoreSpace;
  floor: Floor;
  planDetail: PlanDetail;

  onCapacityUpdated?: VoidFunction;
}> = (props) => {
  const [key, update] = useUpdate();

  const dispatch = useAppDispatch();

  const [capacity, setCapacity] = React.useState(`${props.space?.capacity}`);

  const rifm = useRifm({
    value: capacity,
    mask: true,
    onChange: setCapacity,
    format: (str: string) => {
      const r = parseInt(str.replace(/[^\d]+/gi, ''), 10);

      return r ? r.toString() : '';
    },
  });

  React.useEffect(() => {
    setCapacity(`${props.space?.capacity}`);
  }, [key, props.space?.capacity]);

  return (
    <section
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid ${dust.Gray200};
        height: 32px;
        padding: 0 ${dust.Space4};
        flex-shrink: 0;
      `}
    >
      <FunctionDropdown
        key={`${props.space.id}-${key}`}
        value={
          props.space.function
            ? valueToOption(
                spaceFunctionToNewSpaceFunction(props.space.function)
              )
            : undefined
        }
        options={Object.values(NewSpaceFunction).map((f) =>
          valueToOption(f as NewSpaceFunction)
        )}
        onChange={(value) => {
          if (!value) {
            return;
          }

          dispatch(
            asyncUpdateSpaceThunk({
              spaceId: props.space.id,
              body: {
                function: value.value,
              },
              planId: props.planDetail.id,
            })
          ).then(() => {
            update();
          });
        }}
      />

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <span
          css={css`
            font-size: ${dust.TextScale2};
            color: ${dust.Gray400};
            font-weight: ${dust.FontWeightBold};
            margin-right: ${dust.Space3};
            transform: translateY(1px);
          `}
        >
          Capacity
        </span>

        <InlineInput
          inputStyles={css`
            height: 20px;
            width: 28px;
            font-size: ${dust.TextScale3};
            font-weight: ${dust.FontWeightMedium};
            margin-right: -10px;
          `}
          labelStyles={css`
            font-size: ${dust.TextScale3};
            font-weight: ${dust.FontWeightMedium};
            margin-top: 1px;
          `}
          value={rifm.value}
          onChange={rifm.onChange}
          placeholder={NO_VALUE_PLACEHOLDER}
          onBlur={() => {
            dispatch(
              asyncUpdateSpaceThunk({
                spaceId: props.space.id,
                body: {
                  capacity: parseInt(capacity),
                },
                planId: props.planDetail.id,
              })
            ).then(() => {
              props.onCapacityUpdated?.();

              update();
            });
          }}
        />
      </div>
    </section>
  );
};

const SpaceHeader: React.FC<{
  space: CoreSpace;
  floor: Floor;
  planDetail: PlanDetail;
}> = (props) => {
  const [key, update] = useUpdate();

  const dispatch = useAppDispatch();

  const [name, setName] = React.useState(props.space?.name);

  React.useEffect(() => {
    setName(`${props.space?.name}`);
  }, [key, props.space?.name]);

  const Icon = props.space?.function
    ? SPACE_FUNCTION_ICONS[
        // TODO(guscost): remove this migration/cast when space functions are updated
        spaceFunctionToNewSpaceFunction(props.space.function)
      ]
    : Icons.Floor;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 8px 8px 8px 12px;
        gap: ${dust.Space3};
        height: 2.5rem;
        border-bottom: 1px solid ${dust.Gray200};
        flex-shrink: 0px;
      `}
    >
      <div
        css={css`
          margin-left: -${dust.Space2};
          color: ${dust.Blue400};
          transform: translateY(2px);
        `}
      >
        <Icon width={24} height={24} color="currentColor" />
      </div>

      <InlineInput
        value={name}
        inputStyles={css`
          height: 28px;
          width: 100%;
          font-size: ${dust.TextScale4};
          font-weight: ${dust.FontWeightBold};
          margin-left: -3px;
        `}
        labelStyles={css`
          width: 100%;
          font-size: ${dust.TextScale4};
          font-weight: ${dust.FontWeightBold};
          margin-top: 2px;
          // https://css-tricks.com/almanac/properties/l/line-clamp/
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `}
        onChange={(e) => {
          setName(e.target.value);
        }}
        onBlur={() => {
          dispatch(
            asyncUpdateSpaceThunk({
              spaceId: props.space.id,
              body: {
                name,
              },
              planId: props.planDetail.id,
            })
          ).then(() => {
            update();
          });
        }}
      />
    </div>
  );
};

type SpaceCardProps = {
  areaId: string;

  floor: Floor;
  planDetail: PlanDetail;
};

const SpaceCard: React.FC<SpaceCardProps> = (props) => {
  const isDwellOn = useTreatment(SPLITS.DWELL);

  const [distributionQueryKey, updateDistributionQueryKey] = useUpdate();

  const dispatch = useAppDispatch();

  const area = React.useMemo(() => {
    return props.planDetail.areas.find((a) => a.id === props.areaId);
  }, [props.areaId, props.planDetail.areas]);

  const dates = useAppSelector((state) => state.analysis.dates);
  const filterDays = useAppSelector((state) => state.analysis.filterDays);
  const filterStart = useAppSelector((state) => state.analysis.filterStart);
  const filterEnd = useAppSelector((state) => state.analysis.filterEnd);

  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );

  const spacesDict = useAppSelector(spacesSelectors.selectEntities);

  const space = area?.space_id ? spacesDict[area.space_id] : undefined;

  React.useEffect(() => {
    if (!dates) {
      return;
    }

    if (!space) {
      return;
    }

    const promise = dispatch(
      asyncFetchDistributionAnalyticsThunk({
        spaceId: space.id,
        timeZone: props.floor.time_zone,
        startDate: dates[0],
        endDate: dates[1],
        filterStart: filterStart,
        filterEnd: filterEnd,
        filterDays: filterDays,
      })
    );

    return () => {
      promise.abort();
    };
  }, [
    distributionQueryKey,
    dates,
    dispatch,
    filterDays,
    filterEnd,
    filterStart,
    props.floor.time_zone,
    space,
  ]);

  React.useEffect(() => {
    if (!dates) {
      return;
    }

    if (!space) {
      return;
    }

    const promise = dispatch(
      asyncFetchUtilizationAnalyticsByHourThunk({
        spaceId: space.id,
        timeZone: props.floor.time_zone,
        startDate: dates[0],
        endDate: dates[1],
        filterStart: filterStart,
        filterEnd: filterEnd,
        filterDays: filterDays,
      })
    );

    return () => {
      promise.abort();
    };
  }, [
    dates,
    dispatch,
    filterDays,
    filterEnd,
    filterStart,
    props.floor.time_zone,
    space,
  ]);

  React.useEffect(() => {
    if (!isDwellOn) {
      return;
    }

    if (!dates) {
      return;
    }

    if (!space) {
      return;
    }

    const promise = dispatch(
      asyncFetchDwellAnalyticsThunk({
        spaceId: space.id,
        timeZone: props.floor.time_zone,
        startDate: dates[0],
        endDate: dates[1],
        filterStart: filterStart,
        filterEnd: filterEnd,
        filterDays: filterDays,
      })
    );

    return () => {
      promise.abort();
    };
  }, [
    dates,
    dispatch,
    filterDays,
    filterEnd,
    filterStart,
    isDwellOn,
    props.floor.time_zone,
    space,
  ]);

  React.useEffect(() => {
    if (!dates) {
      return;
    }

    if (!space) {
      return;
    }

    const promise = dispatch(
      asyncFetchTimeUsedAnalyticsThunk({
        spaceId: space.id,
        timeZone: props.floor.time_zone,
        startDate: dates[0],
        endDate: dates[1],
        filterStart: filterStart,
        filterEnd: filterEnd,
        filterDays: filterDays,
      })
    );

    return () => {
      promise.abort();
    };
  }, [
    dates,
    dispatch,
    filterDays,
    filterEnd,
    filterStart,
    props.floor.time_zone,
    space,
  ]);

  if (!space) {
    // TODO(wuweiweiwu): error state here
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 17.5rem;
        min-width: 17.5rem;
        max-height: ${heatmapEnabled ? 'calc(100% - 80px)' : '100%'};
        padding: 0;
        background-color: ${dust.White};
        border: 1px solid ${dust.Gray200};
        border-radius: ${dust.Radius300};
        box-shadow: ${dust.Elevation300};
        pointer-events: all;
      `}
    >
      <SpaceHeader
        space={space}
        floor={props.floor}
        planDetail={props.planDetail}
      />

      <Content>
        <FunctionAndCapacityEditSection
          space={space}
          floor={props.floor}
          planDetail={props.planDetail}
          onCapacityUpdated={updateDistributionQueryKey}
        />

        <MetricsSection
          space={space}
          areaId={props.areaId}
          floor={props.floor}
        />

        <LabelSection space={space} />

        <PerformanceSection
          areaId={props.areaId}
          space={space}
          floor={props.floor}
          planDetail={props.planDetail}
        />

        <BusiestDayAndTimeSection space={space} floor={props.floor} />

        {isSingleOccupancy(space) ? null : (
          <DistributionSection spaceId={space.id} />
        )}

        <AvailabilityByDaySection spaceId={space.id} floor={props.floor} />

        <TimeUsedByDaySection spaceId={space.id} floor={props.floor} />
      </Content>
    </div>
  );
};

export default React.memo(SpaceCard);
