import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';
import { DayOfWeek } from '@densityco/lib-common-types';
import { CompatibleDateValue } from '@densityco/ui/dist/cjs/src/date-picker';

import { AnalysisDatapoint } from './analysis-slice';

import { AppThunkApiConfig } from 'redux/store';
import { getTimeFilterString, TimeOfDay } from 'lib/date-time';
import { defaultAppThunkOptions } from 'lib/redux';

// FIXME: this endpoint is not accurate for time-used metrics, we should replace with asyncFetchTimeUsedAnalyticsThunk which should occur when we transition fully to OA MLP
export const asyncFetchAnalyticsThunk = createAsyncThunk<
  // return value
  AnalysisDatapoint[],
  // parameters
  {
    planId: string;
    areaIds: string[];
    timeZone: string;

    startTime: CompatibleDateValue;
    endTime: CompatibleDateValue;
    filterStart: TimeOfDay;
    filterEnd: TimeOfDay;
    filterDays: DayOfWeek[];
  },
  // types for thunkApi
  AppThunkApiConfig
>(
  'analysis/asyncFetchAnalytics',
  async (args, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    const source = axios.CancelToken.source();
    thunkApi.signal.addEventListener('abort', () => {
      source.cancel();
    });

    const timeFilterString = getTimeFilterString(
      args.filterStart,
      args.filterEnd,
      args.filterDays
    );

    const params = {
      start_time: args.startTime,
      end_time: args.endTime,
      time_zone: args.timeZone,
      area_ids: args.areaIds.join(','),
      time_filters: timeFilterString,
    };

    const results = await auth.densityAPIClient.get(
      `/v1/plans/${args.planId}/events/analytics`,
      {
        params,
        cancelToken: source.token,
      }
    );

    return results.data.data;
  },
  {
    ...defaultAppThunkOptions,
    condition: (args, thunkApi) => {
      const { auth } = thunkApi.getState();

      if (!auth.densityAPIClient) {
        return false;
      }

      if (!args.areaIds.length) {
        return false;
      }
    },
  }
);
