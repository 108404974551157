import { useCallback } from 'react';
import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { Space } from 'components/editor/state';
import { FloorplanCoordinates, ViewportCoordinates } from 'lib/geometry';
import { Floorplan } from 'lib/floorplan';
import { Viewport } from 'lib/viewport';
import { Area } from 'lib/api';
import { useAppDispatch } from 'redux/store';
import {
  unhoverArea,
  hoverArea,
} from 'redux/features/availability/availability-slice';

const COLOR_AVAILABLE = colors.green;
const COLOR_OCCUPIED = colors.red;

// shared styles between space graphic types
const LINE_WIDTH = 1;
const LINE_WIDTH_HIGHLIGHTED = 1;
const FILL_OPACITY = 0.4;
const FILL_OPACITY_HIGHLIGHTED = 0.6;

const SpaceCircleGraphic: React.FunctionComponent<{
  position: ViewportCoordinates;
  radiusPixels: number;
  isAvailable: boolean;
  isHighlighted: boolean;
  onMouseEnter: React.MouseEventHandler<SVGElement>;
  onMouseLeave: React.MouseEventHandler<SVGElement>;
  // onMouseDown: React.MouseEventHandler<SVGElement>;
}> = ({
  position,
  radiusPixels,
  isAvailable,
  isHighlighted,
  onMouseEnter,
  onMouseLeave,
  // onMouseDown,
}) => {
  const color = isAvailable ? COLOR_AVAILABLE : COLOR_OCCUPIED;

  return (
    <circle
      cx={position.x}
      cy={position.y}
      r={radiusPixels}
      fill={color}
      stroke={color}
      strokeWidth={isHighlighted ? LINE_WIDTH_HIGHLIGHTED : LINE_WIDTH}
      fillOpacity={isHighlighted ? FILL_OPACITY_HIGHLIGHTED : FILL_OPACITY}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      // onMouseDown={onMouseDown}
    />
  );
};

const SpaceBoxGraphic: React.FunctionComponent<{
  position: ViewportCoordinates;
  widthPixels: number;
  heightPixels: number;
  isAvailable: boolean;
  isHighlighted: boolean;
  onMouseEnter: React.MouseEventHandler<SVGElement>;
  onMouseLeave: React.MouseEventHandler<SVGElement>;
  // onMouseDown: React.MouseEventHandler<SVGElement>;
}> = ({
  position,
  widthPixels,
  heightPixels,
  isAvailable,
  isHighlighted,
  onMouseEnter,
  onMouseLeave,
  // onMouseDown,
}) => {
  const leftX = position.x - widthPixels / 2;
  // const rightX = position.x + widthPixels / 2;
  const topY = position.y - heightPixels / 2;
  // const bottomY = position.y + heightPixels / 2;

  // corners
  const topLeft = ViewportCoordinates.create(leftX, topY);
  // const topRight = ViewportCoordinates.create(rightX, topY);
  // const bottomRight = ViewportCoordinates.create(rightX, bottomY);
  // const bottomLeft = ViewportCoordinates.create(leftX, bottomY);

  const color = isAvailable ? COLOR_AVAILABLE : COLOR_OCCUPIED;

  return (
    <rect
      x={topLeft.x}
      y={topLeft.y}
      width={widthPixels}
      height={heightPixels}
      rx={2}
      fill={color}
      stroke={color}
      strokeWidth={isHighlighted ? LINE_WIDTH_HIGHLIGHTED : LINE_WIDTH}
      fillOpacity={isHighlighted ? FILL_OPACITY_HIGHLIGHTED : FILL_OPACITY}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      // onMouseDown={onMouseDown}
    />
  );
};

const SpacePolygonGraphic: React.FunctionComponent<{
  vertices: Array<ViewportCoordinates>;
  isAvailable: boolean;
  isHighlighted: boolean;
  onMouseEnter: React.MouseEventHandler<SVGElement>;
  onMouseLeave: React.MouseEventHandler<SVGElement>;
  // onMouseDown: React.MouseEventHandler<SVGElement>;
}> = ({
  vertices,
  isAvailable,
  isHighlighted,
  onMouseEnter,
  onMouseLeave,
  // onMouseDown,
}) => {
  const color = isAvailable ? COLOR_AVAILABLE : COLOR_OCCUPIED;

  return (
    <path
      d={`
        M${vertices[0].x},${vertices[0].y}
        ${vertices.slice(1).map((v) => `L${v.x},${v.y}`)}
        L${vertices[0].x},${vertices[0].y}
      `}
      fill={color}
      stroke={color}
      strokeWidth={isHighlighted ? LINE_WIDTH_HIGHLIGHTED : LINE_WIDTH}
      fillOpacity={isHighlighted ? FILL_OPACITY_HIGHLIGHTED : FILL_OPACITY}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      // onMouseDown={onMouseDown}
    />
  );
};

const SpaceGraphic: React.FunctionComponent<{
  area: Area;
  space: Space;
  floorplan: Floorplan;
  viewport: Viewport;
  isAvailable: boolean;
  isHighlighted: boolean;
}> = ({ area, space, floorplan, viewport, isAvailable, isHighlighted }) => {
  const dispatch = useAppDispatch();

  const position = FloorplanCoordinates.toViewportCoordinates(
    space.position,
    floorplan,
    viewport
  );

  const onMouseEnter = useCallback(() => {
    dispatch(hoverArea(area.id));
  }, [area.id, dispatch]);

  const onMouseLeave = useCallback(() => {
    dispatch(unhoverArea());
  }, [dispatch]);

  // TODO: This happens sometimes, unsure why
  if (Number.isNaN(position.x) || Number.isNaN(position.y)) {
    return null;
  }

  return (
    <g key={space.id}>
      {space.shape.type === 'circle' ? (
        <SpaceCircleGraphic
          position={position}
          radiusPixels={space.shape.radius * floorplan.scale * viewport.zoom}
          isAvailable={isAvailable}
          isHighlighted={isHighlighted}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          // onMouseDown={onMouseDown}
        />
      ) : null}
      {space.shape.type === 'box' ? (
        <SpaceBoxGraphic
          position={position}
          widthPixels={space.shape.width * floorplan.scale * viewport.zoom}
          heightPixels={space.shape.height * floorplan.scale * viewport.zoom}
          isAvailable={isAvailable}
          isHighlighted={isHighlighted}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          // onMouseDown={onMouseDown}
        />
      ) : null}
      {space.shape.type === 'polygon' ? (
        <SpacePolygonGraphic
          vertices={space.shape.vertices.map((vertex) =>
            FloorplanCoordinates.toViewportCoordinates(
              vertex,
              floorplan,
              viewport
            )
          )}
          isAvailable={isAvailable}
          isHighlighted={isHighlighted}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          // onMouseDown={onMouseDown}
        />
      ) : null}
    </g>
  );
};

export default SpaceGraphic;
