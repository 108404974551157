import invariant from 'invariant';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { connect, disconnect } from '@giantmachines/redux-websocket';

import { AppThunkApiConfig } from 'redux/store';
import { ErrorReporting } from 'lib/error-reporting';
import { OA_LIVE_SOCKET_PREFIX } from 'lib/redux';
import { defaultAppThunkOptions } from 'lib/redux';

export const asyncSetUpOALiveWebSocketThunk = createAsyncThunk<
  // return value
  void,
  // parameters
  { planId: string },
  // types for thunkAPI
  AppThunkApiConfig
>(
  'availability/asyncSetUpOALiveWebSocket',
  async (args, thunkAPI) => {
    const { auth } = thunkAPI.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener('abort', () => {
      source.cancel();
      thunkAPI.dispatch(disconnect(OA_LIVE_SOCKET_PREFIX));
    });

    const authURL = `/v2/presence/status/floorplan/${args.planId}/auth`;

    try {
      const response = await auth.densityAPIClient.post(authURL, undefined, {
        cancelToken: source.token,
      });

      const socketURL = `${response.data.url}?targets=true`;

      thunkAPI.dispatch(connect(socketURL, OA_LIVE_SOCKET_PREFIX));
    } catch (err) {
      // use instanceof to narrow the type to Error
      if (!axios.isCancel(err) && err instanceof Error) {
        ErrorReporting.report(err, 'error');
      }

      // throw the error so the thunk will dispatch the rejected action
      throw err;
    }
  },
  {
    ...defaultAppThunkOptions,
    condition: (_, thunkAPI) => {
      const { auth } = thunkAPI.getState();

      if (!auth.densityAPIClient) {
        return false;
      }
    },
  }
);
