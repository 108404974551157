import {
  FloorplanCollection,
  Reference,
  ReferenceRuler,
  Sensor,
  State,
  PhotoGroup,
  PhotoGroupPhoto,
  HeightMap,
} from './state';

import {
  scale,
  translate,
  compose,
  rotateDEG,
  decomposeTSR,
} from 'transformation-matrix';

import { Measurement } from 'components/floorplan-measure/floorplan-measure';
import {
  Area,
  isAreaId,
  isPlanSensorId,
  isReferencePointId,
  isReferenceRulerId,
  PlanDetail,
  PlanSensor,
  PlanUpdate,
  Unsaved,
} from 'lib/api';
import { FloorplanCoordinates, ImageCoordinates } from 'lib/geometry';
import { Floorplan } from 'lib/floorplan';
import { Viewport } from 'lib/viewport';
import { areaToSpace } from 'lib/area';

const DEFAULT_VIEWPORT = {
  width: 1,
  height: 1,
  top: 0,
  left: 0,
  zoom: 1,
};

export function preparePlanUpdate(state: State): PlanUpdate {
  // PlanSensor

  const planSensors: PlanUpdate['plan_sensors'] = FloorplanCollection.list(
    state.sensors
  ).map((sensor) => {
    // TODO: validate serial number format

    /**
     * We check if the id is a valid PlanSensorId (ie. has the psr_ prefix)
     * in order to determine if it has already been saved with the Plan.
     *
     * Otherwise, if the id is not prefixed, then we set the id to undefined
     * here so that when this object is serialized to JSON, the id key will be
     * removed.
     *
     * The PATCH endpoint for the Plan will assume that any PlanSensor without
     * an id is a new sensor that should be created and assigned to the Plan.
     */
    const planSensor: PlanSensor | Unsaved<PlanSensor> = {
      id: isPlanSensorId(sensor.id) ? sensor.id : undefined,
      // only include the serial number if it has a truthy value
      sensor_serial_number: sensor.serialNumber || undefined,
      sensor_type: sensor.type,
      height_meters: sensor.height,
      centroid_from_origin_x_meters: sensor.position.x,
      centroid_from_origin_y_meters: sensor.position.y,
      rotation: sensor.rotation,
      locked: sensor.locked,
      last_heartbeat: sensor.last_heartbeat,
      status: sensor.status,
      diagnostic_info: {
        ipv4: sensor.ipv4,
        ipv6: sensor.ipv6,
        mac: sensor.mac,
        os: sensor.os,
      },
      notes: sensor.notes,
      // this will send the plan_sensor_index field for saving
      // but it's readonly, so it should be fine
      plan_sensor_index: sensor.plan_sensor_index,
      cad_id: sensor.cadId,
      bounding_box_filter: sensor.boundingBoxFilter,
    };

    return planSensor;
  });

  // Area
  const areas: PlanUpdate['areas'] = FloorplanCollection.list(state.spaces).map(
    (space) => {
      const cx = space.position.x;
      const cy = space.position.y;

      // NOTE: Only include the id if it is an area id from the database (already saved area)
      // NOTE: Using `undefined` here means that when JSON.stringify is called on it, the key is not included
      const id = isAreaId(space.id) ? space.id : undefined;
      const name = space.name;
      const locked = space.locked;

      if (space.shape.type === 'box') {
        const halfWidth = space.shape.width / 2;
        const halfHeight = space.shape.height / 2;

        const left = cx - halfWidth;
        const right = cx + halfWidth;
        const top = cy - halfHeight;
        const bottom = cy + halfHeight;

        const area: Area | Unsaved<Area> = {
          id,
          name,
          locked,
          shape: 'polygon',
          polygon_verticies: [
            {
              x_from_origin_meters: left,
              y_from_origin_meters: top,
            },
            {
              x_from_origin_meters: right,
              y_from_origin_meters: top,
            },
            {
              x_from_origin_meters: right,
              y_from_origin_meters: bottom,
            },
            {
              x_from_origin_meters: left,
              y_from_origin_meters: bottom,
            },
          ],
          circle_centroid_x_meters: null,
          circle_centroid_y_meters: null,
          circle_radius_meters: null,
        };
        return area;
      } else if (space.shape.type === 'circle') {
        const area: Area | Unsaved<Area> = {
          id,
          name,
          locked,
          shape: 'circle',
          polygon_verticies: null,
          circle_centroid_x_meters: cx,
          circle_centroid_y_meters: cy,
          circle_radius_meters: space.shape.radius,
        };
        return area;
      } else if (space.shape.type === 'polygon') {
        const area: Area | Unsaved<Area> = {
          id,
          name,
          locked,
          shape: 'polygon',
          polygon_verticies: space.shape.vertices.map((v) => ({
            x_from_origin_meters: v.x,
            y_from_origin_meters: v.y,
          })),
          circle_centroid_x_meters: null,
          circle_centroid_y_meters: null,
          circle_radius_meters: null,
        };
        return area;
      } else {
        throw new Error(
          'Valid space shape types are "box", "circle", and "polygon"'
        );
      }
    }
  );

  // ReferencePoint and ReferenceRuler
  const referencePoints: PlanUpdate['reference_points'] = [];
  const referenceRulers: PlanUpdate['reference_rulers'] = [];
  for (const reference of FloorplanCollection.list(state.references)) {
    switch (reference.type) {
      case 'point': {
        referencePoints.push({
          id: isReferencePointId(reference.id) ? reference.id : undefined,
          position_x_meters: reference.position.x,
          position_y_meters: reference.position.y,
          enabled: reference.enabled,
        });
        break;
      }
      case 'ruler': {
        referenceRulers.push({
          id: isReferenceRulerId(reference.id) ? reference.id : undefined,
          position_a_x_meters: reference.positionA.x,
          position_a_y_meters: reference.positionA.y,
          position_b_x_meters: reference.positionB.x,
          position_b_y_meters: reference.positionB.y,
          position_label_x_meters: reference.distanceLabelPosition.x,
          position_label_y_meters: reference.distanceLabelPosition.y,
          enabled: reference.enabled,
        });
        break;
      }
    }
  }

  const { floorplan, measurement } = state;

  let matrix = undefined;
  if (
    state.heightMap.enabled &&
    state.heightMap.geotiffTransformationData.enabled
  ) {
    matrix = compose(
      rotateDEG(-state.heightMap.rotation),
      scale(
        1 / state.heightMap.geotiffTransformationData.scale,
        1 / state.heightMap.geotiffTransformationData.scale
      ),
      translate(-state.heightMap.position.x, -state.heightMap.position.y),
      translate(
        -state.heightMap.geotiffTransformationData.tiePoint[0].x,
        state.heightMap.geotiffTransformationData.tiePoint[0].y
      )
    );
  }

  return {
    // collections
    areas: areas,
    plan_sensors: planSensors,
    reference_points: referencePoints,
    reference_rulers: referenceRulers,
    // image dimensions and scale
    image_width_pixels: floorplan.width,
    image_height_pixels: floorplan.height,
    image_pixels_per_meter: floorplan.scale,
    // floorplan origin
    origin_x_pixels: floorplan.origin.x,
    origin_y_pixels: floorplan.origin.y,
    // measurement
    measurement_point_a_x_pixels: measurement?.pointA.x,
    measurement_point_a_y_pixels: measurement?.pointA.y,
    measurement_point_b_x_pixels: measurement?.pointB.x,
    measurement_point_b_y_pixels: measurement?.pointB.y,
    measurement_computed_length_meters: measurement?.computedLength,
    measurement_user_entered_length_feet:
      measurement?.userEnteredLength.feetText,
    measurement_user_entered_length_inches:
      measurement?.userEnteredLength.inchesText,
    floorplan_cad_origin_x: state.floorplanCADOrigin.x,
    floorplan_cad_origin_y: state.floorplanCADOrigin.y,
    ceiling_raster_key: state.heightMap.enabled
      ? state.heightMap.objectKey
      : null,
    ceiling_raster_floorplan_origin_x: state.heightMap.enabled
      ? state.heightMap.position.x
      : 0,
    ceiling_raster_floorplan_origin_y: state.heightMap.enabled
      ? state.heightMap.position.y
      : 0,
    ceiling_raster_floorplan_angle_degrees: state.heightMap.enabled
      ? state.heightMap.rotation
      : 0,
    ceiling_raster_opacity_percent: state.heightMap.enabled
      ? state.heightMap.opacity
      : 0,
    ceiling_raster_notes: state.heightMap.enabled ? state.heightMap.notes : '',
    ceiling_raster_min_height_limit_meters:
      state.heightMap.enabled && state.heightMap.limits.enabled
        ? state.heightMap.limits.minMeters
        : 0,
    ceiling_raster_max_height_limit_meters:
      state.heightMap.enabled && state.heightMap.limits.enabled
        ? state.heightMap.limits.maxMeters
        : 0,
    point_cloud_transformation_matrix:
      matrix && state.heightMap.enabled
        ? JSON.parse(JSON.stringify(matrix))
        : undefined,
    point_cloud_transformation_steps:
      state.heightMap.enabled && matrix
        ? JSON.stringify(decomposeTSR(matrix))
        : undefined,
  };
}

export function initializeStateFromPlan(
  plan: PlanDetail,
  floorplanImage: HTMLImageElement | null,
  viewport: Viewport = DEFAULT_VIEWPORT
): State {
  const floorplan: Floorplan = {
    width: plan.image_width_pixels,
    height: plan.image_height_pixels,
    scale: plan.image_pixels_per_meter,
    origin: {
      type: 'image-coordinates',
      x: plan.origin_x_pixels,
      y: plan.origin_y_pixels,
    },
  };

  // TODO: avoid needing to initialize viewport for state

  const measurement: Measurement = {
    pointA: ImageCoordinates.create(
      plan.measurement_point_a_x_pixels,
      plan.measurement_point_a_y_pixels
    ),
    pointB: ImageCoordinates.create(
      plan.measurement_point_b_x_pixels,
      plan.measurement_point_b_y_pixels
    ),
    userEnteredLength: {
      feetText: plan.measurement_user_entered_length_feet,
      inchesText: plan.measurement_user_entered_length_inches,
    },
    computedLength: plan.measurement_computed_length_meters,
    computedScale: floorplan.scale,
  };

  const lastSensorIndices = {
    lastOASensorIndex: plan.last_oa_sensor_index,
    lastEntrySensorIndex: plan.last_entry_sensor_index,
  };

  let state = State.getInitialState(
    floorplan,
    viewport,
    floorplanImage,
    measurement,
    lastSensorIndices
  );

  for (const planSensor of plan.plan_sensors) {
    const sensor = Sensor.create(
      planSensor.sensor_type,
      FloorplanCoordinates.create(
        planSensor.centroid_from_origin_x_meters,
        planSensor.centroid_from_origin_y_meters
      ),
      planSensor.height_meters,
      planSensor.rotation,
      // TODO: Sensor name
      '',
      planSensor.notes
    );
    // FIXME: pass through id and serialNumber in create() instead
    sensor.id = planSensor.id;
    sensor.serialNumber = planSensor.sensor_serial_number || null;
    sensor.locked = planSensor.locked;
    sensor.last_heartbeat = planSensor.last_heartbeat;
    sensor.status = planSensor.status;
    sensor.ipv4 = planSensor.diagnostic_info?.ipv4;
    sensor.ipv6 = planSensor.diagnostic_info?.ipv6;
    sensor.mac = planSensor.diagnostic_info?.mac;
    sensor.os = planSensor.diagnostic_info?.os;
    sensor.plan_sensor_index = planSensor.plan_sensor_index; // this is the name of json coming from backend
    sensor.cadId = planSensor.cad_id || '';
    sensor.boundingBoxFilter = planSensor.bounding_box_filter || 'none';
    state = State.addSensor(state, sensor, false);
  }

  for (const area of plan.areas) {
    const space = areaToSpace(area);
    state = State.addSpace(state, space, false);
  }

  for (const referencePoint of plan.reference_points) {
    const position = FloorplanCoordinates.create(
      referencePoint.position_x_meters,
      referencePoint.position_y_meters
    );
    const reference = Reference.createPoint(position);
    // FIXME: pass through id in createPoint() instead
    reference.id = referencePoint.id;
    state = State.addReference(state, reference, false);
  }

  for (const referenceRuler of plan.reference_rulers) {
    const positionA = FloorplanCoordinates.create(
      referenceRuler.position_a_x_meters,
      referenceRuler.position_a_y_meters
    );
    const positionB = FloorplanCoordinates.create(
      referenceRuler.position_b_x_meters,
      referenceRuler.position_b_y_meters
    );
    // If the position_label_x_meters / position_label_y_meters fields aren't set, then default to the
    // center position
    const distanceLabelPosition = referenceRuler.position_label_x_meters
      ? FloorplanCoordinates.create(
          referenceRuler.position_label_x_meters,
          referenceRuler.position_label_y_meters
        )
      : ReferenceRuler.calculateCenterPoint({ positionA, positionB });
    const reference = Reference.createRuler(
      positionA,
      positionB,
      distanceLabelPosition
    );
    // FIXME: pass through id in createRuler() instead
    reference.id = referenceRuler.id;
    state = State.addReference(state, reference, false);
  }

  for (const planPhotoGroup of plan.photo_groups) {
    const photoGroup = PhotoGroup.create(
      planPhotoGroup.name,
      FloorplanCoordinates.create(
        planPhotoGroup.origin_x_pixels,
        planPhotoGroup.origin_y_pixels
      )
    );
    photoGroup.id = planPhotoGroup.id;
    photoGroup.locked = planPhotoGroup.locked;
    photoGroup.notes = planPhotoGroup.notes;
    photoGroup.operationToPerform = null;

    for (const planPhotoGroupPhoto of planPhotoGroup.photos) {
      const photo = PhotoGroupPhoto.createFromImageUrl(
        planPhotoGroupPhoto.id,
        planPhotoGroupPhoto.name,
        planPhotoGroupPhoto.url
      );
      photoGroup.photos.push(photo);
    }

    state = State.addPhotoGroup(state, photoGroup, false);
  }

  if (
    typeof plan.floorplan_cad_origin_x !== 'undefined' &&
    typeof plan.floorplan_cad_origin_y !== 'undefined'
  ) {
    state.floorplanCADOrigin = FloorplanCoordinates.create(
      plan.floorplan_cad_origin_x,
      plan.floorplan_cad_origin_y
    );
  }

  if (plan.latest_dxf) {
    state.latestDXF = {
      id: plan.latest_dxf.id,
      status: plan.latest_dxf.status,
      createdAt: plan.latest_dxf.created_at,
      parseOptions: plan.latest_dxf.options,
    };
  }
  if (plan.active_dxf_id) {
    state.activeDXFId = plan.active_dxf_id;
  }
  if (plan.active_dxf_full_raster_url) {
    state.activeDXFFullRasterUrl = plan.active_dxf_full_raster_url;
  }

  if (plan.ceiling_raster_url && plan.ceiling_raster_key) {
    let limits: HeightMap['limits'] = { enabled: false };
    if (
      plan.ceiling_raster_min_height_limit_meters > 0 &&
      plan.ceiling_raster_max_height_limit_meters > 0
    ) {
      limits = {
        enabled: true,
        minMeters: plan.ceiling_raster_min_height_limit_meters,
        maxMeters: plan.ceiling_raster_max_height_limit_meters,
      };
    }

    state.heightMap = {
      enabled: true,
      url: plan.ceiling_raster_url,
      objectKey: plan.ceiling_raster_key,
      rotation: plan.ceiling_raster_floorplan_angle_degrees || 0,
      position: FloorplanCoordinates.create(
        plan.ceiling_raster_floorplan_origin_x || 0,
        plan.ceiling_raster_floorplan_origin_y || 0
      ),
      opacity: plan.ceiling_raster_opacity_percent || 100,
      notes: plan.ceiling_raster_notes || '',
      limits,
      geotiffTransformationData: {
        enabled: false,
      },
    };
    state.planning.showCeilingHeightMap = true;
  } else {
    state.heightMap = { enabled: false };
    state.planning.showCeilingHeightMap = false;
  }

  return state;
}
