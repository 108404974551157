import { wrap, releaseProxy } from 'comlink';

export const getHeatmapWorkerApiAndCleanup = () => {
  const worker = new Worker(new URL('./heatmap-worker', import.meta.url), {
    name: 'heatmap-worker',
  });

  const workerApi = wrap<import('lib/heatmap-worker').HeatmapWorker>(worker);

  // A cleanup function that releases the comlink proxy and terminates the worker
  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  return { workerApi, cleanup };
};
