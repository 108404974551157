import { useEffect, useState } from 'react';

/**
 * `key` should be a valid value for `KeyboardEvent.key`
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 */
export function useKeyState(key: string) {
  const [keyState, setKeyState] = useState<boolean>(false);
  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      if (evt.key === key) {
        setKeyState(true);
      }
    };
    const onKeyUp = (evt: KeyboardEvent) => {
      if (evt.key === key) {
        setKeyState(false);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [key]);
  return keyState;
}
