import { useCallback, useEffect, useReducer, useRef } from 'react';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { ViewportCoordinates } from 'lib/geometry';
import { useKeyState } from 'lib/keyboard';
import { addDragListener } from 'lib/drag';
import { Viewport } from 'lib/viewport';
import { withPayloadType } from 'lib/redux';

const initialState: Viewport = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  zoom: 0,
};

const resize = createAction(
  'viewport/resize',
  withPayloadType<{ width: number; height: number }>()
);

const dragMove = createAction(
  'viewport/dragMove',
  withPayloadType<{ dx: number; dy: number }>()
);

const dragStart = createAction('viewport/dragStart', withPayloadType<void>());

const dragEnd = createAction('viewport/dragEnd', withPayloadType<void>());

const zoomToFit = createAction(
  'viewport/zoomToFit',
  withPayloadType<{ width: number; height: number }>()
);

const scrollWheel = createAction(
  'viewport/scrollWheel',
  withPayloadType<{
    dx: number;
    dy: number;
    ctrlKey: boolean;
    metaKey: boolean;
    position: ViewportCoordinates;
  }>()
);

const mouseDown = createAction(
  'viewport/mouseDown',
  withPayloadType<{
    position: ViewportCoordinates;
  }>()
);

const pan = createAction(
  'viewport/pan',
  withPayloadType<{ dx: number; dy: number }>()
);

const viewportReducer = createReducer(initialState, (builder) => {
  builder.addCase(resize, (state, action) => {
    state.width = action.payload.width;
    state.height = action.payload.height;
  });

  builder.addCase(dragMove, (state, action) => {
    state.top = state.top - action.payload.dy / state.zoom;
    state.left = state.left - action.payload.dx / state.zoom;
  });

  builder.addCase(dragStart, (state, action) => {});

  builder.addCase(dragEnd, (state, action) => {});

  builder.addCase(zoomToFit, (state, action) => {
    return Viewport.zoomToFit(state, action.payload);
  });

  builder.addCase(scrollWheel, (state, action) => {
    const { position, dx, dy, ctrlKey, metaKey } = action.payload;

    // ctrlKey hack for zoom
    if (ctrlKey || metaKey) {
      // limit scroll wheel sensitivity for mouse users
      const limit = 8;
      const scrollDelta = Math.max(-limit, Math.min(limit, dy));

      const nextZoomFactor = state.zoom + state.zoom * scrollDelta * -0.01;

      const targetX = state.left + position.x / state.zoom;
      const targetY = state.top + position.y / state.zoom;

      const top = targetY - position.y / nextZoomFactor;
      const left = targetX - position.x / nextZoomFactor;

      state.zoom = nextZoomFactor;
      state.top = top;
      state.left = left;
      return;
    }

    // otherwise pan
    state.top = state.top + dy / state.zoom;
    state.left = state.left + dx / state.zoom;
  });

  builder.addCase(mouseDown, (state, action) => {});

  builder.addCase(pan, (state, action) => {
    state.left += action.payload.dx;
    state.top += action.payload.dy;
  });
});

export function useViewportControls<TElement extends HTMLElement>() {
  const [viewport, dispatch] = useReducer(viewportReducer, initialState);

  const viewportElementRef = useRef<TElement>(null);

  const spaceKeyPressed = useKeyState(' ');

  const onViewportMouseDown = useCallback(
    (evt: React.MouseEvent) => {
      const elem = evt.currentTarget as HTMLElement;
      if (!elem) {
        return;
      }

      const bbox = elem.getBoundingClientRect();
      const position = ViewportCoordinates.create(
        evt.clientX - bbox.left,
        evt.clientY - bbox.top
      );

      dispatch(
        mouseDown({
          position,
        })
      );

      if (spaceKeyPressed) {
        dispatch(dragStart());

        const unregister = addDragListener(
          evt.clientX,
          evt.clientY,
          (dx, dy) => {
            dispatch(dragMove({ dx, dy }));
          },
          () => {
            dispatch(dragEnd());
          }
        );

        return () => {
          unregister();
        };
      }
    },
    [spaceKeyPressed]
  );

  const onWheel = useCallback((evt: WheelEvent) => {
    evt.preventDefault();

    const elem = evt.currentTarget as HTMLElement;
    if (!elem) {
      return;
    }

    const bbox = elem.getBoundingClientRect();
    const dx = evt.deltaX;
    const dy = evt.deltaY;

    const { ctrlKey, metaKey } = evt;

    const position = ViewportCoordinates.create(
      evt.clientX - bbox.left,
      evt.clientY - bbox.top
    );

    dispatch(
      scrollWheel({
        position,
        dx,
        dy,
        ctrlKey,
        metaKey,
      })
    );
  }, []);

  useEffect(() => {
    const element = viewportElementRef.current;
    if (!element) {
      return;
    }

    element.addEventListener('wheel', onWheel, { passive: false });

    return () => {
      element.removeEventListener('wheel', onWheel);
    };
  }, [onWheel, viewportElementRef]);

  const resizeCb = useCallback((width: number, height: number) => {
    dispatch(
      resize({
        height,
        width,
      })
    );
  }, []);

  const zoomToFitCb = useCallback((item: { width: number; height: number }) => {
    dispatch(zoomToFit(item));
  }, []);

  return {
    viewport,
    viewportElementRef,

    resize: resizeCb,
    zoomToFit: zoomToFitCb,
    onViewportMouseDown,
  };
}
