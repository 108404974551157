import { Floorplan } from 'lib/floorplan';
import { Viewport } from 'lib/viewport';
import { degreesToRadians, radiansToDegrees } from 'lib/math';
// TODO: move definition of SensorCoordinates to this file
import { SensorCoordinates, HeightMap } from 'components/editor/state';
import { CONVERT_TO_METERS, CONVERT_FROM_METERS, LengthUnit } from 'lib/units';

export type GridCoordinates = {
  type: 'grid-coordinates';
  x: number;
  y: number;
};

export const GridCoordinates = {
  create(x: number, y: number): GridCoordinates {
    return {
      type: 'grid-coordinates',
      x,
      y,
    };
  },
};

export type FloorplanCoordinates = {
  type: 'floorplan-coordinates';
  x: number;
  y: number;
};
export const FloorplanCoordinates = {
  create(x: number, y: number): FloorplanCoordinates {
    return {
      type: 'floorplan-coordinates',
      x,
      y,
    };
  },
  toImageCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    floorplan: Floorplan
  ) {
    const pixelsFromOriginX = floorplanCoordinates.x * floorplan.scale;
    const pixelsFromOriginY = floorplanCoordinates.y * floorplan.scale;
    const x = floorplan.origin.x + pixelsFromOriginX;
    const y = floorplan.origin.y + pixelsFromOriginY;
    return ImageCoordinates.create(x, y);
  },
  toViewportCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    floorplan: Floorplan,
    viewport: Viewport
  ) {
    return ImageCoordinates.toViewportCoordinates(
      FloorplanCoordinates.toImageCoordinates(floorplanCoordinates, floorplan),
      viewport
    );
  },

  toGridCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    gridStepSize: number
  ) {
    return GridCoordinates.create(
      Math.floor(floorplanCoordinates.x / gridStepSize),
      Math.floor(floorplanCoordinates.y / gridStepSize)
    );
  },

  toCADCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number
  ) {
    const x = floorplanCoordinates.x - floorplanCADOrigin.x;
    const y = floorplanCADOrigin.y - floorplanCoordinates.y;

    return CADCoordinates.create(
      CONVERT_FROM_METERS[cadFileUnit](x) * cadFileScale,
      CONVERT_FROM_METERS[cadFileUnit](y) * cadFileScale
    );
  },

  toHeightMapCoordinates(
    floorplanCoordinates: FloorplanCoordinates,
    heightMap: HeightMap,
    heightMapScale: number
  ) {
    const x = (floorplanCoordinates.x - heightMap.position.x) / heightMapScale;
    const y = (floorplanCoordinates.y - heightMap.position.y) / heightMapScale;

    // Convert the ceiling raster into polar coordinates
    const distance = Math.hypot(x, y);
    let rotation = radiansToDegrees(Math.atan2(y, x));

    rotation -= heightMap.rotation;
    while (rotation < 0) {
      rotation += 360;
    }

    const rotationInRadians = degreesToRadians(rotation);

    const finalX = Math.cos(rotationInRadians) * distance;
    const finalY = Math.sin(rotationInRadians) * distance;

    return HeightMapCoordinates.create(finalX, finalY);
  },
};

export type ImageCoordinates = {
  type: 'image-coordinates';
  x: number;
  y: number;
};
export const ImageCoordinates = {
  create(x: number, y: number): ImageCoordinates {
    return {
      type: 'image-coordinates',
      x,
      y,
    };
  },
  toFloorplanCoordinates(
    imageCoordinates: ImageCoordinates,
    floorplan: Floorplan
  ) {
    const offsetX = imageCoordinates.x - floorplan.origin.x;
    const offsetY = imageCoordinates.y - floorplan.origin.y;
    const x = offsetX / floorplan.scale;
    const y = offsetY / floorplan.scale;
    return FloorplanCoordinates.create(x, y);
  },

  toViewportCoordinates(
    imageCoordinates: ImageCoordinates,
    viewport: Viewport
  ) {
    const x = (imageCoordinates.x - viewport.left) * viewport.zoom;
    const y = (imageCoordinates.y - viewport.top) * viewport.zoom;
    return ViewportCoordinates.create(x, y);
  },

  toCADCoordinates(
    imageCoordinates: ImageCoordinates,
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number
  ) {
    return FloorplanCoordinates.toCADCoordinates(
      ImageCoordinates.toFloorplanCoordinates(imageCoordinates, floorplan),
      floorplan,
      floorplanCADOrigin,
      cadFileUnit,
      cadFileScale
    );
  },
};

export type ViewportCoordinates = {
  type: 'viewport-coordinates';
  x: number;
  y: number;
};
export const ViewportCoordinates = {
  create(x: number, y: number): ViewportCoordinates {
    return {
      type: 'viewport-coordinates',
      x,
      y,
    };
  },
  toImageCoordinates(
    viewportCoordinates: ViewportCoordinates,
    viewport: Viewport
  ) {
    const x = viewportCoordinates.x / viewport.zoom + viewport.left;
    const y = viewportCoordinates.y / viewport.zoom + viewport.top;
    return ImageCoordinates.create(x, y);
  },
  toFloorplanCoordinates(
    viewportCoordinates: ViewportCoordinates,
    viewport: Viewport,
    floorplan: Floorplan
  ) {
    return ImageCoordinates.toFloorplanCoordinates(
      ViewportCoordinates.toImageCoordinates(viewportCoordinates, viewport),
      floorplan
    );
  },
  toHeightMapCoordinates(
    viewportCoordinates: ViewportCoordinates,
    viewport: Viewport,
    floorplan: Floorplan,
    heightMap: HeightMap,
    heightMapScale: number
  ) {
    const floorplanCoordinates = ViewportCoordinates.toFloorplanCoordinates(
      viewportCoordinates,
      viewport,
      floorplan
    );
    return FloorplanCoordinates.toHeightMapCoordinates(
      floorplanCoordinates,
      heightMap,
      heightMapScale
    );
  },
};

export type CADCoordinates = {
  type: 'cad-coordinates';
  x: number;
  y: number;
};
export const CADCoordinates = {
  create(x: number, y: number): CADCoordinates {
    return {
      type: 'cad-coordinates',
      x,
      y,
    };
  },
  toFloorplanCoordinates(
    cadCoordinates: CADCoordinates,
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number
  ) {
    const x = CONVERT_TO_METERS[cadFileUnit](cadCoordinates.x / cadFileScale);
    const y = CONVERT_TO_METERS[cadFileUnit](cadCoordinates.y / cadFileScale);

    return FloorplanCoordinates.create(
      // The CAD origin is in the lower left corner of the cad drawing
      x + floorplanCADOrigin.x,
      -1 * y + floorplanCADOrigin.y
    );
  },
  toImageCoordinates(
    cadCoordinates: CADCoordinates,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number,
    floorplan: Floorplan
  ) {
    return FloorplanCoordinates.toImageCoordinates(
      CADCoordinates.toFloorplanCoordinates(
        cadCoordinates,
        floorplan,
        floorplanCADOrigin,
        cadFileUnit,
        cadFileScale
      ),
      floorplan
    );
  },
  toViewportCoordinates(
    cadCoordinates: CADCoordinates,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnit: LengthUnit,
    cadFileScale: number,
    floorplan: Floorplan,
    viewport: Viewport
  ) {
    return FloorplanCoordinates.toViewportCoordinates(
      CADCoordinates.toFloorplanCoordinates(
        cadCoordinates,
        floorplan,
        floorplanCADOrigin,
        cadFileUnit,
        cadFileScale
      ),
      floorplan,
      viewport
    );
  },
};

// Height map coordinates are pixel coordinates within the ceiling raster image
export type HeightMapCoordinates = {
  type: 'height-map-coordinates';
  x: number;
  y: number;
};
export const HeightMapCoordinates = {
  create(x: number, y: number): HeightMapCoordinates {
    return {
      type: 'height-map-coordinates',
      x,
      y,
    };
  },

  toFloorplanCoordinates(
    heightMapCoordinates: HeightMapCoordinates,
    heightMap: HeightMap,
    heightMapScale: number
  ) {
    // Convert the ceiling raster into polar coordinates
    const distance = Math.hypot(heightMapCoordinates.x, heightMapCoordinates.y);
    let rotation = radiansToDegrees(
      Math.atan2(heightMapCoordinates.y, heightMapCoordinates.x)
    );
    rotation += heightMap.rotation;
    while (rotation >= 360) {
      rotation -= 360;
    }

    const rotationInRadians = degreesToRadians(rotation);
    const x = Math.cos(rotationInRadians) * distance;
    const y = Math.sin(rotationInRadians) * distance;

    return FloorplanCoordinates.create(
      x * heightMapScale + heightMap.position.x,
      y * heightMapScale + heightMap.position.y
    );
  },
  toViewportCoordinates(
    heightMapCoordinates: HeightMapCoordinates,
    viewport: Viewport,
    floorplan: Floorplan,
    heightMap: HeightMap,
    heightMapScale: number
  ) {
    return FloorplanCoordinates.toViewportCoordinates(
      HeightMapCoordinates.toFloorplanCoordinates(
        heightMapCoordinates,
        heightMap,
        heightMapScale
      ),
      floorplan,
      viewport
    );
  },
};

export class ViewportVector {
  constructor(public readonly x: number, public readonly y: number) {}

  toImageVector(viewport: Viewport) {
    return new ImageVector(this.x / viewport.zoom, this.y / viewport.zoom);
  }
}

export class ImageVector {
  constructor(public readonly x: number, public readonly y: number) {}

  toFloorplanVector(floorplan: Floorplan) {
    return new FloorplanVector(
      this.x / floorplan.scale,
      this.y / floorplan.scale
    );
  }

  toViewportVector(viewport: Viewport) {
    return new ViewportVector(this.x * viewport.zoom, this.y * viewport.zoom);
  }
}

export class FloorplanVector {
  constructor(public readonly x: number, public readonly y: number) {}
}

export function sensorToFloorplanCoordinates(
  sensorCoordinates: SensorCoordinates,
  sensorPosition: FloorplanCoordinates,
  sensorRotation: number
) {
  const rotationRadians = degreesToRadians(sensorRotation + 180);
  const x =
    -sensorCoordinates.x * Math.cos(rotationRadians) -
    sensorCoordinates.z * Math.sin(rotationRadians);
  const y =
    -sensorCoordinates.x * Math.sin(rotationRadians) +
    sensorCoordinates.z * Math.cos(rotationRadians);
  return FloorplanCoordinates.create(
    sensorPosition.x + x,
    sensorPosition.y + y
  );
}

// Adapted from https://stackoverflow.com/a/3368118/1525769
export function drawRoundedRectangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
}

// Given any kind of coordinate, snap the coordinate to an angle that is a multiple of the
// `snapAngle` about a center point `center`
export function snapToAngle<T extends string>(
  center: { type: T; x: number; y: number },
  outer: { type: T; x: number; y: number },
  snapAngle = 45,
  snapAngleStartPoint = 0
): { type: T; x: number; y: number } {
  // Compute the distance between the two points
  const magnitude = Math.hypot(outer.x - center.x, outer.y - center.y);

  // Run through each possible point `magnitude` away from `center`, and see which is closest to the
  // `outer` point passed in. The closest point represents the snap location!
  let distance = Infinity;
  let point = { type: center.type, x: 0, y: 0 };
  for (let angle = snapAngleStartPoint; angle < 360; angle += snapAngle) {
    const testPoint = {
      type: center.type,
      x: center.x + magnitude * Math.cos(degreesToRadians(angle)),
      y: center.y + magnitude * Math.sin(degreesToRadians(angle)),
    };
    const distanceFromOuterPointToTestPoint = Math.hypot(
      testPoint.x - outer.x,
      testPoint.y - outer.y
    );

    if (distance > distanceFromOuterPointToTestPoint) {
      distance = distanceFromOuterPointToTestPoint;
      point = testPoint;
    }
  }
  return point;
}

// Given a list of vertices of a polygon, compute the centroid
// More info: https://math.stackexchange.com/a/700059
export function calculatePolygonCentroid(
  vertices: Array<FloorplanCoordinates>
): FloorplanCoordinates {
  if (vertices.length === 0) {
    throw new Error('Cannot compute centroid of an empty vertex list!');
  }
  if (vertices.length === 1) {
    return vertices[0];
  }
  if (vertices.length === 2 || vertices.length === 3) {
    const totalX = vertices.reduce((acc, i) => acc + i.x, 0);
    const totalY = vertices.reduce((acc, i) => acc + i.y, 0);
    return FloorplanCoordinates.create(
      totalX / vertices.length,
      totalY / vertices.length
    );
  }

  // For more complex polygons, split up into triples of points
  const results: Array<FloorplanCoordinates> = [];
  for (let i = 0, j = 1, k = 2; k < vertices.length; i += 1, j += 1, k += 1) {
    const triple = [vertices[i], vertices[j], vertices[k]];
    // Compute the centroid of each triangle
    results.push(calculatePolygonCentroid(triple));
  }

  // And then compute the centroid of all the centroids
  return calculatePolygonCentroid(results);
}
