import { protocol } from '@densityco/lib-oa-protocol';

import { Seconds } from 'lib/units';

export namespace Tracks {
  export type SensorTrack = {
    timestamp: number;
    position: { x: number; y: number };
  };

  export function getTargetsFromFrame(frame: protocol.Frame) {
    const result: Array<SensorTrack> = [];
    const targets = frame.classifier?.targets || [];
    const timestamp = Seconds.fromMicroseconds(Number(frame.timestamp));
    for (const target of targets) {
      const { x, y } = target;
      if (typeof x !== 'number' || typeof y !== 'number') continue;

      result.push({
        timestamp,
        position: { x, y },
      });
    }
    return result;
  }
}
