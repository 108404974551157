import { Fragment, useEffect, useState, useRef } from 'react';
import { wrap, releaseProxy, proxy, Remote } from 'comlink';
import * as React from 'react';
import * as PIXI from 'pixi.js';

import { Action } from './actions';
import { Sensor, State } from './state';

import HorizontalForm from 'components/horizontal-form';
import Button from 'components/button';
import TextField from 'components/text-field';
import Switch from 'components/switch/switch';
import Panel, { PanelHeader, PanelBody, PanelActions } from 'components/panel';
import { SensorHeightInput } from './focused-sensor-panel';

import {
  FloorplanCoordinates,
  ViewportCoordinates,
  snapToAngle,
} from 'lib/geometry';
import { computeCoverageMajorMinorAxisOA } from 'lib/sensor';
import {
  Layer,
  useFloorplanLayerContext,
  toRawHex,
  ResizeHandle,
} from 'components/floorplan';

import {
  Blue400,
  Blue700,
  Green400,
  Teal400,
  Gray400,
} from '@density/dust/dist/tokens/dust.tokens';

const AutoLayoutPanel: React.FunctionComponent<{
  state: State;
  dispatch: React.Dispatch<Action>;
}> = ({ state, dispatch }) => {
  const [minimumExclusiveAreaText, setMinimumExclusiveAreaText] = useState('');
  const [minimumExclusiveAreaTextValid, setMinimumExclusiveAreaTextValid] =
    useState(true);

  const minimumExclusiveAreaOrNull = state.autoLayout.active
    ? state.autoLayout.minimumExclusiveArea
    : null;
  useEffect(() => {
    if (!state.autoLayout.active || minimumExclusiveAreaOrNull === null) {
      return;
    }
    setMinimumExclusiveAreaText(`${minimumExclusiveAreaOrNull}`);
    setMinimumExclusiveAreaTextValid(true);
  }, [state.autoLayout.active, minimumExclusiveAreaOrNull]);

  const [cadIdPrefixText, setCadIdPrefixText] = useState('');
  const cadIdPrefixOrNull = state.autoLayout.active
    ? state.autoLayout.cadIdPrefix
    : null;
  useEffect(() => {
    if (!state.autoLayout.active || cadIdPrefixOrNull === null) {
      return;
    }
    setCadIdPrefixText(cadIdPrefixOrNull);
  }, [state.autoLayout.active, cadIdPrefixOrNull]);

  const autoLayoutProcessingWorker = useRef<Worker | null>(null);
  const autoLayoutProcessingWorkerWrapped = useRef<Remote<
    import('lib/auto-layout-worker').AutoLayoutWorker
  > | null>(null);
  useEffect(() => {
    autoLayoutProcessingWorker.current = new Worker(
      new URL('lib/auto-layout-worker', import.meta.url),
      {
        name: 'auto-layout-worker',
      }
    );

    autoLayoutProcessingWorkerWrapped.current = wrap(
      autoLayoutProcessingWorker.current
    );

    return () => {
      if (autoLayoutProcessingWorkerWrapped.current) {
        autoLayoutProcessingWorkerWrapped.current[releaseProxy]();
        autoLayoutProcessingWorkerWrapped.current = null;
      }
      if (autoLayoutProcessingWorker.current) {
        autoLayoutProcessingWorker.current.terminate();
        autoLayoutProcessingWorker.current = null;
      }
    };
  }, []);

  useEffect(() => {
    dispatch({ type: 'autoLayout.clearSensorPositions' });

    if (!state.autoLayout.active) {
      return;
    }
    if (state.autoLayout.boundingRegionPlacementEnabled) {
      return;
    }
    if (!state.autoLayout.originPosition) {
      return;
    }
    if (!autoLayoutProcessingWorkerWrapped.current) {
      return;
    }

    autoLayoutProcessingWorkerWrapped.current.generateSensorPositions(
      state.autoLayout,
      state.heightMap.enabled ? state.heightMap : null,
      proxy((sensorPositions, done) => {
        dispatch({
          type: 'autoLayout.setSensorPositions',
          sensorPositions,
          done,
        });
      })
    );
  }, [state.autoLayout, state.heightMap, dispatch]);

  if (!state.autoLayout.active) {
    return null;
  }

  return (
    <Panel position="top-right">
      <PanelHeader>Autolayout</PanelHeader>

      <PanelBody>
        {state.autoLayout.boundingRegionPlacementEnabled ? (
          <Fragment>
            Placing bounding region
            <br />
          </Fragment>
        ) : (
          <HorizontalForm size="small">
            <span>
              Bounding region: {state.autoLayout.boundingRegionVertices.length}{' '}
              vertices
            </span>
            <Button
              type="outlined"
              size="small"
              onClick={() =>
                dispatch({ type: 'autoLayout.resetBoundingRegion' })
              }
            >
              Clear
            </Button>
          </HorizontalForm>
        )}
        Sensor Height:{' '}
        <SensorHeightInput
          value={state.autoLayout.sensorHeight}
          sensorHeightBounds={{
            min: Sensor.min_height('oa'),
            max: Sensor.max_height('oa'),
          }}
          disabled={false}
          displayUnits={state.displayUnit}
          onChange={(height: number) => {
            dispatch({
              type: 'autoLayout.changeSensorHeight',
              height,
            });
          }}
        />
        Minimum Exclusive Area:{' '}
        <TextField
          value={minimumExclusiveAreaText}
          onChange={(e) => setMinimumExclusiveAreaText(e.currentTarget.value)}
          error={!minimumExclusiveAreaTextValid}
          placeholder="eg: 1"
          onBlur={() => {
            const minimumExclusiveArea = parseFloat(minimumExclusiveAreaText);
            if (
              isNaN(minimumExclusiveArea) ||
              `${minimumExclusiveArea}` !== minimumExclusiveAreaText
            ) {
              setMinimumExclusiveAreaTextValid(false);
              return;
            }
            setMinimumExclusiveAreaTextValid(true);

            dispatch({
              type: 'autoLayout.changeMinimumExclusiveArea',
              value: minimumExclusiveArea,
            });
          }}
          trailingSuffix="m^2"
        />
        Height Map:{' '}
        <Switch
          isChecked={state.autoLayout.heightMapEnabled}
          isDisabled={!state.heightMap.enabled}
          onChange={(event) =>
            dispatch({
              type: 'autoLayout.changeHeightMapEnabled',
              enabled: event.currentTarget.checked,
            })
          }
        />
        CAD ID Prefix:{' '}
        <TextField
          value={cadIdPrefixText}
          placeholder="ex: CUST-LOC-FLR-"
          trailingSuffix={
            state.autoLayout.cadIdPrefix.length > 0 ? '-OAS-###' : 'OAS-###'
          }
          onChange={(e) => setCadIdPrefixText(e.currentTarget.value)}
          onBlur={() => {
            dispatch({
              type: 'autoLayout.changeCadIdPrefix',
              prefix: cadIdPrefixText,
            });
          }}
        />
      </PanelBody>

      <PanelActions
        left={
          <Button
            type="cleared"
            size="medium"
            onClick={() => dispatch({ type: 'autoLayout.cancel' })}
          >
            Cancel
          </Button>
        }
        right={
          <HorizontalForm size="small">
            <Button
              type="filled"
              size="medium"
              disabled={
                !state.autoLayoutSensorPositionsDone ||
                state.autoLayoutSensorPositions === null
              }
              onClick={() => dispatch({ type: 'autoLayout.submit' })}
            >
              {state.autoLayoutSensorPositionsDone &&
              state.autoLayoutSensorPositions
                ? `Create ${
                    state.autoLayoutSensorPositions.length === 1
                      ? '1 Sensor'
                      : `${state.autoLayoutSensorPositions.length} Sensors`
                  }`
                : 'Create'}
            </Button>
          </HorizontalForm>
        }
      />
    </Panel>
  );
};

export const AutoLayoutLayer: React.FunctionComponent<{
  autoLayout: State['autoLayout'];
  autoLayoutSensorPositions: State['autoLayoutSensorPositions'];
  heightMap: State['heightMap'];

  onPlaceVertex: (coords: FloorplanCoordinates) => void;
  onChangeVertexPosition: (index: number, coords: FloorplanCoordinates) => void;
  onCompleteBoundingRegion: () => void;
  onChangeOriginPosition: (origin: FloorplanCoordinates) => void;
}> = ({
  autoLayout,
  autoLayoutSensorPositions,
  heightMap,
  onPlaceVertex,
  onChangeVertexPosition,
  onCompleteBoundingRegion,
  onChangeOriginPosition,
}) => {
  const context = useFloorplanLayerContext();

  const nextVertexPosition = useRef<FloorplanCoordinates | null>(null);
  const mouseOverFirstVertex = useRef<boolean>(false);

  const selectedResizeHandle = useRef<{
    index: number;
    position: FloorplanCoordinates;
  } | null>(null);

  const cachedOriginPosition = useRef<FloorplanCoordinates | null>(null);

  useEffect(() => {
    const container = new PIXI.Container();
    container.name = 'auto-layout-container';

    const background = new PIXI.Sprite();
    background.name = 'background';
    background.x = 0;
    background.y = 0;
    background.interactive = true;
    background.on('mousemove', (event) => {
      if (!context.viewport.current) {
        return;
      }
      if (!autoLayout.active) {
        return;
      }
      if (!autoLayout.boundingRegionPlacementEnabled) {
        return;
      }

      const position = ViewportCoordinates.create(
        event.data.global.x,
        event.data.global.y
      );
      let positionFloorplan = ViewportCoordinates.toFloorplanCoordinates(
        position,
        context.viewport.current,
        context.floorplan
      );

      // Check to see if the mouse is overtop of the first vertex - if so, then set a flag so
      // clicking will complete the polygon
      const firstVertex = autoLayout.boundingRegionVertices[0];
      if (firstVertex) {
        const firstVertexViewport = FloorplanCoordinates.toViewportCoordinates(
          firstVertex,
          context.floorplan,
          context.viewport.current
        );
        const distanceToFirstVertexPixels = Math.hypot(
          firstVertexViewport.x - position.x,
          firstVertexViewport.y - position.y
        );
        if (distanceToFirstVertexPixels < 8) {
          nextVertexPosition.current = null;
          mouseOverFirstVertex.current = true;
          return;
        }
      }

      // Otherwise, clicking will make a new point
      const lastVertex =
        autoLayout.boundingRegionVertices[
          autoLayout.boundingRegionVertices.length - 1
        ];
      if (event.data.originalEvent.shiftKey) {
        positionFloorplan = snapToAngle(lastVertex, positionFloorplan);
      }

      nextVertexPosition.current = positionFloorplan;
      mouseOverFirstVertex.current = false;
    });
    background.on('mouseout', () => {
      nextVertexPosition.current = null;
    });
    background.on('mousedown', () => {
      if (!context.viewport.current) {
        return;
      }
      if (!autoLayout.active) {
        return;
      }
      if (!autoLayout.boundingRegionPlacementEnabled) {
        return;
      }
      if (!nextVertexPosition.current) {
        return;
      }

      onPlaceVertex(nextVertexPosition.current);
    });
    container.addChild(background);

    const shape = new PIXI.Graphics();
    shape.name = 'shape';
    container.addChild(shape);

    const resizeHandles = new PIXI.Container();
    resizeHandles.name = 'resize-handles';
    container.addChild(resizeHandles);

    const origin = new ResizeHandle(
      context,
      (newPosition) => {
        cachedOriginPosition.current = newPosition;
      },
      {
        color: toRawHex(Green400),
        onRelease: () => {
          if (!cachedOriginPosition.current) {
            return;
          }
          onChangeOriginPosition(cachedOriginPosition.current);
        },
      }
    );
    origin.name = 'origin';
    container.addChild(origin);

    context.app.stage.addChild(container);
    return () => {
      context.app.stage.removeChild(container);
    };
  }, [context, autoLayout, onPlaceVertex, onChangeOriginPosition]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }
        const viewport = context.viewport.current;
        if (!autoLayout.active) {
          return;
        }

        // Disable the ability to move points around if the sensor placements are currently loading
        const disabled =
          !autoLayout.boundingRegionPlacementEnabled &&
          autoLayoutSensorPositions === null;

        const container = context.app.stage.getChildByName(
          'auto-layout-container'
        ) as PIXI.Container | null;
        if (!container) {
          return;
        }

        const background = container.getChildByName(
          'background'
        ) as PIXI.Sprite;
        background.width = viewport.width;
        background.height = viewport.height;

        const shape = container.getChildByName('shape') as PIXI.Graphics;
        shape.clear();
        shape.lineStyle({
          width: 1,
          color: toRawHex(Teal400),
          join: PIXI.LINE_JOIN.ROUND,
        });
        if (!autoLayout.boundingRegionPlacementEnabled) {
          // Fill the shape if it's not currently being created
          shape.beginFill(toRawHex(Gray400), 0.1);
        }

        const boundingRegionVerticesViewport =
          autoLayout.boundingRegionVertices.map((vertex, index) => {
            const position =
              selectedResizeHandle.current &&
              selectedResizeHandle.current.index === index
                ? selectedResizeHandle.current.position
                : vertex;
            return FloorplanCoordinates.toViewportCoordinates(
              position,
              context.floorplan,
              viewport
            );
          });

        // Draw the polygon region
        if (boundingRegionVerticesViewport.length > 0) {
          shape.moveTo(
            boundingRegionVerticesViewport[0].x,
            boundingRegionVerticesViewport[0].y
          );
          for (
            let index = 1;
            index < boundingRegionVerticesViewport.length;
            index += 1
          ) {
            shape.lineTo(
              boundingRegionVerticesViewport[index].x,
              boundingRegionVerticesViewport[index].y
            );
          }
          if (
            !autoLayout.boundingRegionPlacementEnabled ||
            mouseOverFirstVertex.current
          ) {
            // Render the polygon with a closed outline if not in placement mode
            shape.lineTo(
              boundingRegionVerticesViewport[0].x,
              boundingRegionVerticesViewport[0].y
            );
          }

          // Draw a leader line going to the mouse position
          if (nextVertexPosition.current) {
            const finalVertex =
              boundingRegionVerticesViewport[
                boundingRegionVerticesViewport.length - 1
              ];
            shape.moveTo(finalVertex.x, finalVertex.y);

            const nextVertexPositionViewport =
              FloorplanCoordinates.toViewportCoordinates(
                nextVertexPosition.current,
                context.floorplan,
                context.viewport.current
              );
            shape.lineTo(
              nextVertexPositionViewport.x,
              nextVertexPositionViewport.y
            );

            // Draw fake resize handle at the end
            shape.beginFill(0xffffff);
            shape.drawRect(
              nextVertexPositionViewport.x - 4,
              nextVertexPositionViewport.y - 4,
              8,
              8
            );
          }
        }

        // Add / remove resize handles so that the number of resize handles equals the number of
        // bounding region vertices
        const resizeHandles = container.getChildByName(
          'resize-handles'
        ) as PIXI.Container;
        if (
          resizeHandles.children.length < boundingRegionVerticesViewport.length
        ) {
          // Add new resize handles
          for (
            let index = resizeHandles.children.length;
            index < boundingRegionVerticesViewport.length;
            index += 1
          ) {
            const resizeHandle = new ResizeHandle(
              context,
              (newPosition) => {
                selectedResizeHandle.current = {
                  index,
                  position: newPosition,
                };
              },
              {
                color: toRawHex(Teal400),
                onPress: () => {
                  // If the user is hovering over the first vertex, then clicking should complete
                  // the polygon
                  if (index === 0 && mouseOverFirstVertex.current) {
                    onCompleteBoundingRegion();
                  }
                },
                onRelease: () => {
                  if (!selectedResizeHandle.current) {
                    return;
                  }
                  onChangeVertexPosition(
                    selectedResizeHandle.current.index,
                    selectedResizeHandle.current.position
                  );
                },
              }
            );
            resizeHandles.addChild(resizeHandle);
          }
        } else if (
          resizeHandles.children.length > boundingRegionVerticesViewport.length
        ) {
          // Remove extra resize handles
          for (
            let index = boundingRegionVerticesViewport.length - 1;
            index < resizeHandles.children.length;
            index += 1
          ) {
            resizeHandles.removeChild(resizeHandles.children[index]);
          }
        }

        // Adjust the position of each resize handle
        for (
          let index = 0;
          index < boundingRegionVerticesViewport.length;
          index += 1
        ) {
          const resizeHandle = resizeHandles.children[index];
          resizeHandle.cursor = 'move';
          resizeHandle.x = boundingRegionVerticesViewport[index].x;
          resizeHandle.y = boundingRegionVerticesViewport[index].y;
          resizeHandle.renderable = !disabled;
        }

        // Draw the position of each sensor
        if (autoLayoutSensorPositions) {
          for (const [
            position,
            heightMeters,
            coveragePolygon,
          ] of autoLayoutSensorPositions) {
            const positionViewport = FloorplanCoordinates.toViewportCoordinates(
              position,
              context.floorplan,
              context.viewport.current
            );

            if (autoLayout.heightMapEnabled && coveragePolygon.length > 0) {
              shape.lineStyle({
                width: 1,
                color: toRawHex(Green400),
                join: PIXI.LINE_JOIN.ROUND,
              });
              shape.beginFill(toRawHex(Green400), 0.5);
              let first: ViewportCoordinates | null = null;
              for (const [x, y] of coveragePolygon) {
                const pointViewport =
                  FloorplanCoordinates.toViewportCoordinates(
                    FloorplanCoordinates.create(x, y),
                    context.floorplan,
                    context.viewport.current
                  );

                if (!first) {
                  shape.moveTo(pointViewport.x, pointViewport.y);
                  first = pointViewport;
                } else {
                  shape.lineTo(pointViewport.x, pointViewport.y);
                }
              }
              if (first) {
                shape.lineTo(first.x, first.y);
              }
              shape.endFill();
            } else {
              // No coverage shape? Render an ellipse.
              const [majorMeters, minorMeters] =
                computeCoverageMajorMinorAxisOA(heightMeters);
              const major =
                majorMeters * context.floorplan.scale * viewport.zoom;
              const minor =
                minorMeters * context.floorplan.scale * viewport.zoom;

              shape.lineStyle({
                width: 1,
                color: toRawHex(Blue400),
                join: PIXI.LINE_JOIN.ROUND,
              });
              shape.beginFill(toRawHex(Blue400), 0.5);
              shape.drawEllipse(
                positionViewport.x,
                positionViewport.y,
                minor,
                major
              );
              shape.endFill();
            }

            shape.beginFill(toRawHex(Blue700), 1);
            shape.drawRoundedRect(
              positionViewport.x - 4,
              positionViewport.y - 4,
              8,
              8,
              2
            );
            shape.endFill();
          }
        }

        // Render origin marker
        const origin = container.getChildByName('origin') as ResizeHandle;
        if (autoLayout.originPosition) {
          const originPositionViewport =
            FloorplanCoordinates.toViewportCoordinates(
              cachedOriginPosition.current
                ? cachedOriginPosition.current
                : autoLayout.originPosition,
              context.floorplan,
              context.viewport.current
            );

          origin.renderable = !disabled;
          origin.x = originPositionViewport.x;
          origin.y = originPositionViewport.y;
        } else {
          origin.renderable = false;
        }
      }}
    />
  );
};

export default AutoLayoutPanel;
