import { createAsyncThunk } from '@reduxjs/toolkit';
import invariant from 'invariant';

import { AppThunkApiConfig } from 'redux/store';
import { defaultAppThunkOptions } from 'lib/redux';
import { getPlan, PlanDetail } from 'lib/api';
import { planDetailsSelectors } from './plans-slice';

export const asyncFetchPlanDetailThunk = createAsyncThunk<
  // return value
  PlanDetail,
  // parameters
  string,
  // types for thunkApi
  AppThunkApiConfig
>(
  'plans/asyncFetchPlanDetail',
  async (arg, thunkApi) => {
    const { auth } = thunkApi.getState();

    invariant(auth.densityAPIClient, 'missing densityAPIClient');

    const response = await getPlan(auth.densityAPIClient, arg);

    return response.data;
  },
  {
    ...defaultAppThunkOptions,
    condition: (arg, thunkApi) => {
      const { auth } = thunkApi.getState();
      if (!auth.densityAPIClient) {
        return false;
      }

      // don't start a fetch if a plan detail is in cache
      const planDetail = planDetailsSelectors.selectById(
        thunkApi.getState(),
        arg
      );
      if (planDetail) {
        return false;
      }
    },
  }
);
