import { Fragment } from 'react';
import * as React from 'react';
import colors from '@densityco/ui/variables/colors.json';

import { ViewportCoordinates } from 'lib/geometry';
import EndpointGraphic from 'components/endpoint-graphic';
import { MetricLabel } from 'components/metric-label';

export const FloorplanRulerGraphic: React.FunctionComponent<{
  positionA: ViewportCoordinates;
  positionB: ViewportCoordinates;
  distanceText: string;
  showDistanceLabel: boolean;
  distanceLabelPosition?: ViewportCoordinates;
  showDistanceLeaderLine: boolean;
  endpointRadius?: number;
  color?: string;
  opacity?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseDownLine?: (evt: any) => void;
  onMouseDownEndPoint?: (
    position: 'positionA' | 'positionB',
    evt: React.MouseEvent
  ) => void;
  onMouseDownDimension?: (evt: any) => void;
}> = ({
  positionA,
  positionB,
  distanceText,
  distanceLabelPosition,
  showDistanceLabel,
  endpointRadius = 8,
  showDistanceLeaderLine,
  onMouseEnter,
  onMouseLeave,
  onMouseDownLine,
  onMouseDownEndPoint,
  onMouseDownDimension,
  color = colors.blue,
  opacity = 1,
}) => {
  const centerPoint = {
    ...positionA,
    x: (positionA.x + positionB.x) / 2,
    y: (positionA.y + positionB.y) / 2,
  };

  return (
    <Fragment>
      <g
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        opacity={opacity}
      >
        <line
          x1={positionA.x}
          y1={positionA.y}
          x2={positionB.x}
          y2={positionB.y}
          stroke={color}
          strokeWidth={2}
        />
        <line
          x1={positionA.x}
          y1={positionA.y}
          x2={positionB.x}
          y2={positionB.y}
          stroke={'transparent'}
          strokeWidth={14}
          onMouseDown={onMouseDownLine}
        />
        <EndpointGraphic
          position={positionA}
          radius={endpointRadius}
          color={color}
          onMouseDown={(evt) => {
            if (!onMouseDownEndPoint) {
              return;
            }
            onMouseDownEndPoint('positionA', evt);
          }}
        />
        <EndpointGraphic
          position={positionB}
          radius={endpointRadius}
          color={color}
          onMouseDown={(evt) => {
            if (!onMouseDownEndPoint) {
              return;
            }
            onMouseDownEndPoint('positionB', evt);
          }}
        />
        {showDistanceLabel ? (
          <MetricLabel
            onMouseDown={onMouseDownDimension}
            labelText={distanceText}
            color={color}
            centerPoint={distanceLabelPosition || centerPoint}
            pointAt={centerPoint}
            showDistanceLeaderLine={showDistanceLeaderLine}
          />
        ) : null}
      </g>
    </Fragment>
  );
};
