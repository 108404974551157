import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { planDetailsSelectors } from './plans-slice';

export const selectPlanDetailByFloor = createSelector(
  [
    planDetailsSelectors.selectAll,

    (_: RootState, params: { floorId: string }) => params.floorId,
  ],
  (plans, floorId) => {
    return plans.find((plan) => plan.floor.id === floorId);
  }
);
