import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import { RootState } from 'redux/store';
import { selectSpace } from '../spaces/select-space';
import { selectTimeZone } from '../spaces/select-timezone';

export const selectTimeUsedMetricDataForSpace = createSelector(
  [
    (state: RootState) => state.analysis.timeUsedAnalytics,

    selectTimeZone,
    selectSpace,

    // parameters
    (_: RootState, params: { spaceId?: string }) => params.spaceId,
  ],
  (timeUsedAnalytics, timeZone, space, spaceId) => {
    if (!spaceId || !space) {
      return;
    }

    const data = timeUsedAnalytics.entities[spaceId]?.data;
    if (!data?.length) {
      return;
    }

    const aggregated: {
      time: string;
      value: number;
      count: number;
      percentage: number;
    }[] = [];

    data.forEach((datum) => {
      const existing = aggregated.find(
        (a) => moment.tz(a.time, timeZone).diff(datum.date, 'days') === 0
      );

      if (existing) {
        existing.value += datum.raw;
        existing.count++;
        // compute rolling average for percent
        existing.percentage =
          (existing.percentage * (existing.count - 1) + datum.percentage) /
          existing.count;
      } else {
        aggregated.push({
          time: datum.date,
          value: datum.raw,
          percentage: datum.percentage,
          count: 1,
        });
      }
    });

    const computed = aggregated.reduce(
      (acc, val) => {
        acc.timeUsed += val.value;
        acc.days++;
        // compute rolling average for percent
        acc.percentage =
          (acc.percentage * (acc.days - 1) + val.percentage) / acc.days;
        return acc;
      },
      { timeUsed: 0, total: 0, percentage: 0, days: 0 }
    );

    return {
      percent: computed.percentage,
      timeUsed: computed.timeUsed,
    };
  }
);
