import { CoreSpace, CoreSpaceType } from '@densityco/lib-api-types';

export interface Floor extends CoreSpace {
  space_type: CoreSpaceType.FLOOR;
}

export function isFloor(space: CoreSpace): space is Floor {
  return space.space_type === CoreSpaceType.FLOOR;
}

export function findFloorsInBuilding(
  spaces: Array<CoreSpace>,
  buildingId: string
) {
  return spaces.filter<Floor>((space): space is Floor => {
    return isFloor(space) && space.parent_id === buildingId;
  });
}
