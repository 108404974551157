import EventEmitter from 'events';
import { AxiosInstance, AxiosResponse } from 'axios';

import { PlanSensor, Area } from 'lib/api';
import {
  PhotoGroup,
  PhotoGroupPhoto,
  PlanDXF,
  PlanExport,
} from 'components/editor/state';

type SocketsResponseBody = { url: string; ttl: number };

type FloorplanEventStreamStatus =
  | 'disconnected'
  | 'connecting'
  | 'connected'
  | 'error';

type FloorplanEventStreamOptions = {
  reconnectAutomatically: boolean;
};

// "PlanEventRawMessage" and associated types represent the data that enters FloorplanEventStream
// via the websocket connection with the backend.
export type PlanEventRawMessageBase = {
  type: 'plan_event';
  plan_id: string;
  user_email: string;
  user_id: string;
  published_at: string;
};
export type PlanEventRawMessagePlan = PlanEventRawMessageBase & {
  event: 'plan.created' | 'plan.updated' | 'plan.deleted';
};
export type PlanEventRawMessageSensor = PlanEventRawMessageBase & {
  event: 'plan.sensor.created' | 'plan.sensor.updated' | 'plan.sensor.deleted';
  plan_sensor_id: string;
};
export type PlanEventRawMessageArea = PlanEventRawMessageBase & {
  event: 'plan.area.created' | 'plan.area.updated' | 'plan.area.deleted';
  plan_area_id: string;
};
export type PlanEventRawMessagePhoto = PlanEventRawMessageBase & {
  event: 'plan.photo.created' | 'plan.photo.updated' | 'plan.photo.deleted';
  plan_photo_id: string;
  plan_photo_group_id: string;
};
export type PlanEventRawMessagePhotoGroup = PlanEventRawMessageBase & {
  event:
    | 'plan.photo_group.created'
    | 'plan.photo_group.updated'
    | 'plan.photo_group.deleted';
  plan_photo_group_id: string;
};
export type PlanEventRawMessageDXF = PlanEventRawMessageBase & {
  event: 'plan.dxf.created' | 'plan.dxf.updated' | 'plan.dxf.deleted';
  plan_dxf: PlanDXF;
  plan_dxf_id: string;
};
export type PlanEventRawMessageExport = PlanEventRawMessageBase & {
  event: 'plan.export.created' | 'plan.export.updated' | 'plan.export.deleted';
  plan_export: PlanExport;
  plan_export_id: string;
};
export type PlanEventRawMessage =
  | PlanEventRawMessagePlan
  | PlanEventRawMessageSensor
  | PlanEventRawMessageArea
  | PlanEventRawMessagePhoto
  | PlanEventRawMessagePhotoGroup
  | PlanEventRawMessageDXF
  | PlanEventRawMessageExport;

// "PlanEventMessage" and associated types represent the data that leaves FloorplanEventStream via
// its event emitter interface
export type PlanEventMessagePlan = PlanEventRawMessagePlan;
export type PlanEventMessageSensor = PlanEventRawMessageSensor & {
  plan_sensor: PlanSensor | null;
};
export type PlanEventMessageArea = PlanEventRawMessageArea & {
  area: Area | null;
};
export type PlanEventMessagePhoto = PlanEventRawMessagePhoto & {
  photo: PhotoGroupPhoto | null;
};
export type PlanEventMessagePhotoGroup = PlanEventRawMessagePhotoGroup & {
  photo_group: PhotoGroup | null;
};
export type PlanEventMessageDXF = PlanEventRawMessageDXF;
export type PlanEventMessageExport = PlanEventRawMessageExport;

export type PlanEventMessage =
  | PlanEventMessagePlan
  | PlanEventMessageSensor
  | PlanEventMessageArea
  | PlanEventMessagePhoto
  | PlanEventMessagePhotoGroup
  | PlanEventMessageDXF
  | PlanEventMessageExport;

export default class FloorplanEventStream extends EventEmitter {
  status: FloorplanEventStreamStatus;
  client: AxiosInstance;
  planId: string;
  websocket: WebSocket | null;
  connectionCount: number;
  options: FloorplanEventStreamOptions;

  onMessage: (event: MessageEvent) => void;

  localEvents: Array<{
    eventName: PlanEventRawMessage['event'];
    matcher: (event: PlanEventRawMessage) => boolean;
    registeredAt: Date;
  }>;
  localEventTimeoutInMilliseconds = 30 * 1000; // 30 seconds

  constructor(
    client: AxiosInstance,
    planId: string,
    options: Partial<FloorplanEventStreamOptions> = {}
  ) {
    super();
    this.status = 'disconnected';

    this.client = client;
    this.planId = planId;
    this.websocket = null;
    this.connectionCount = 0;

    options.reconnectAutomatically = options.reconnectAutomatically || false;
    this.options = options as FloorplanEventStreamOptions;

    this.onMessage = async (event: MessageEvent) => {
      let rawMessage;
      try {
        rawMessage = JSON.parse(event.data);
      } catch (err) {
        console.warn('Error parsing websocket event data:', event.data);
        return;
      }

      if (rawMessage.type !== 'plan_event') {
        console.warn(
          `Received websocket event with type "${rawMessage.type}" - expected "plan_event". Skipping...`
        );
        return;
      }

      const message = rawMessage as PlanEventRawMessage;

      if (message.plan_id !== this.planId) {
        // This message is for another plan, so disregard
        return;
      }

      // Check to see if this event was registered because it was initially created in this browser
      // session.
      // If it was, ignore the event - it's already been processed elsewhere.
      //
      const now = new Date();
      for (let i = 0; i < this.localEvents.length; i += 1) {
        const event = this.localEvents[i];

        // If the event is too old, get rid of it
        const millisecondsSinceEventWasRegistered =
          now.getTime() - event.registeredAt.getTime();
        if (
          millisecondsSinceEventWasRegistered >
          this.localEventTimeoutInMilliseconds
        ) {
          this.localEvents.splice(i, 1);
          i -= 1;
          continue;
        }

        if (event.eventName !== message.event) {
          continue;
        }
        if (!event.matcher(message)) {
          continue;
        }

        // The message seems to have matched, so ignore it
        this.localEvents.splice(i, 1);
        return;
      }

      switch (message.event) {
        case 'plan.sensor.created':
        case 'plan.sensor.updated':
        case 'plan.sensor.deleted': {
          let planSensor: PlanSensor | null = null;

          if (!message.event.endsWith('deleted')) {
            let response: AxiosResponse<PlanSensor>;
            try {
              response = await this.client.get(
                `/v2/floorplans/${this.planId}/sensors/${message.plan_sensor_id}`
              );
            } catch (err) {
              console.error(
                `Error fetching plan sensor ${message.plan_sensor_id} on plan ${this.planId}: ${err}`
              );
              return;
            }
            planSensor = response.data;
          }

          const publicMessage: PlanEventMessageSensor = {
            ...message,
            plan_sensor: planSensor,
          };
          this.emit(message.event, publicMessage);
          return;
        }

        case 'plan.area.created':
        case 'plan.area.updated':
        case 'plan.area.deleted': {
          let area: Area | null = null;

          if (!message.event.endsWith('deleted')) {
            let response: AxiosResponse<Area>;
            try {
              response = await this.client.get(
                `/v2/floorplans/${this.planId}/areas/${message.plan_area_id}`
              );
            } catch (err) {
              console.error(
                `Error fetching area ${message.plan_area_id} on plan ${this.planId}: ${err}`
              );
              return;
            }
            area = response.data;
          }

          const publicMessage: PlanEventMessageArea = { ...message, area };
          this.emit(message.event, publicMessage);
          return;
        }

        case 'plan.photo_group.created':
        case 'plan.photo_group.updated':
        case 'plan.photo_group.deleted': {
          let photoGroup: PhotoGroup | null = null;

          if (!message.event.endsWith('deleted')) {
            let response: AxiosResponse<PhotoGroup>;
            try {
              response = await this.client.get(
                `/v2/floorplans/${this.planId}/photo-groups/${message.plan_photo_group_id}`
              );
            } catch (err) {
              console.error(
                `Error fetching photo group ${message.plan_photo_group_id} on plan ${this.planId}: ${err}`
              );
              return;
            }
            photoGroup = response.data;
          }

          const publicMessage: PlanEventMessagePhotoGroup = {
            ...message,
            photo_group: photoGroup,
          };
          this.emit(message.event, publicMessage);
          return;
        }

        case 'plan.photo.created':
        case 'plan.photo.updated':
        case 'plan.photo.deleted': {
          let photo: PhotoGroupPhoto | null = null;

          if (!message.event.endsWith('deleted')) {
            let response: AxiosResponse<PhotoGroupPhoto>;
            try {
              response = await this.client.get(
                `/v2/floorplans/${this.planId}/photo-groups/${message.plan_photo_group_id}/photos/${message.plan_photo_id}`
              );
            } catch (err) {
              console.error(
                `Error fetching photo ${message.plan_photo_id} (part of photo group ${message.plan_photo_group_id}) on plan ${this.planId}: ${err}`
              );
              return;
            }
            photo = response.data;
          }
          const publicMessage: PlanEventMessagePhoto = { ...message, photo };
          this.emit(message.event, publicMessage);
          return;
        }

        case 'plan.dxf.created':
        case 'plan.dxf.updated':
        case 'plan.dxf.deleted': {
          const publicMessage: PlanEventMessageDXF = message;
          this.emit(message.event, publicMessage);
          return;
        }

        case 'plan.export.created':
        case 'plan.export.updated':
        case 'plan.export.deleted': {
          const publicMessage: PlanEventMessageExport = message;
          this.emit(message.event, publicMessage);
          return;
        }
      }
    };

    this.localEvents = [];
  }

  async connect() {
    const isNotDisconnected = this.status !== 'disconnected';
    if (isNotDisconnected) {
      return;
    }

    await this.connectOnce();

    if (this.status === 'connected') {
      this.connectionCount = 0;
      return;
    }

    setTimeout(() => {
      this.connectionCount += 1;
      this.connect(); //
    }, Math.pow(this.connectionCount, 2));
  }

  disconnect() {
    if (this.websocket) {
      this.websocket.removeEventListener('message', this.onMessage);
      this.websocket.close();
    }
    this.status = 'disconnected';
  }

  // Call this function to inform the FloorplanEventStream about an event that was initiated by this
  // browser client. With this information, the FloorplanEventStream will filter these events so
  // that they are not emitted to higher level consumers.
  //
  // this.registerLocalEvent('plan.area.updated', event => event.plan_area_id === 'are_123');
  registerLocalEvent(
    eventName: PlanEventRawMessage['event'],
    matcher: (event: PlanEventRawMessage) => boolean
  ) {
    this.localEvents.push({ eventName, matcher, registeredAt: new Date() });
  }

  async connectOnce() {
    this.status = 'connecting';

    // Connection step one: request a "websocket url" from the core api
    let response: AxiosResponse<SocketsResponseBody>;
    try {
      response = await this.client.post('/v2/sockets?type=plan_event');
    } catch (err) {
      this.status = 'error';
      return;
    }

    // NOTE: typescript thinks it's impossible for this.status to be anything other than
    // "connecting", but it's possible that when exeution is deferred in the await that
    // ".disconnect()" might be called. This guards against that.
    if ((this.status as any) === 'disconnected') {
      return;
    }

    // Connection step two: connect to the wesocket url that was fetched
    this.websocket = new WebSocket(response.data.url);
    await new Promise((resolve) => {
      (this.websocket as WebSocket).addEventListener('open', resolve);
    });

    // NOTE: typescript thinks it's impossible for this.status to be anything other than
    // "connecting", but it's possible that when exeution is deferred in the await that
    // ".disconnect()" might be called. This guards against that.
    if ((this.status as any) === 'disconnected') {
      this.websocket.close();
      return;
    }

    this.status = 'connected';

    this.websocket.addEventListener('close', (e) => {
      // Before reconnecting, check to see if the connection was aleady disconnected.
      // If it was - the disconnect was purposeful and already handled. Skip the code for when an
      // unexpected disconnection occurs.
      const wasAlreadyDisconnected = this.status === 'disconnected';
      if (wasAlreadyDisconnected) {
        return;
      }

      this.status = 'disconnected';
      (this.websocket as WebSocket).removeEventListener(
        'message',
        this.onMessage
      );

      if (this.options.reconnectAutomatically) {
        this.connect();
      }
    });

    this.websocket.addEventListener('message', this.onMessage);
  }
}
