import * as React from 'react';
import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { List as ReactMovableList } from 'react-movable';
import { Icons } from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';

import { Action } from './actions';
import { PhotoGroup, PhotoGroupPhoto } from './state';
import styles from './styles.module.scss';

import { getUploadImageUrl } from 'lib/api';
import Button from 'components/button';
import TextField from 'components/text-field';
import HorizontalForm from 'components/horizontal-form';
import DarkTheme from 'components/dark-theme';
import NotesBox from 'components/notes-box';
import ImageUploader from 'components/image-uploader';
import Panel, {
  PanelHeader,
  PanelBody,
  PanelBodySection,
  PanelActions,
} from 'components/panel';

const Photo: React.FunctionComponent<{
  photoGroup: PhotoGroup;
  photo: PhotoGroupPhoto;
  canBeDragged: boolean;
  dragging: boolean;
  dispatch: React.Dispatch<Action>;
}> = ({ photoGroup, photo, canBeDragged, dragging, dispatch }) => (
  <div
    className={classnames(styles.photo, {
      [styles.draggable]: canBeDragged,
      [styles.dragging]: dragging,
    })}
    style={{
      backgroundImage: `url(${
        photo.image.dirty ? photo.image.dataUrl : photo.image.url
      })`,
    }}
    data-cy={`photo-${photo.id}`}
  >
    <div className={styles.photoImageShadow} />
    <DarkTheme>
      <div className={styles.photoControls}>
        <div className={styles.photoControlsName}>
          <TextField
            value={photo.name}
            onChange={(e) =>
              dispatch({
                type: 'photoGroup.photos.changeName',
                id: photoGroup.id,
                photoId: photo.id,
                name: e.currentTarget.value,
              })
            }
            width="100%"
            size="small"
            data-cy="photo-name"
          />
        </div>
        <div className={styles.photoControlsActions}>
          <HorizontalForm size="small">
            <Button
              size="small"
              type="cleared"
              trailingIcon={
                <Icons.Export width={14} height={14} color="currentColor" />
              }
              onClick={() => {
                if (photo.image.dirty) {
                  // Chrome and safari at least seem to have problems opening a very long data url
                  // with `window.open`. So instead, open an empty window and append an image tag
                  // containing the data url.
                  const popup = window.open();
                  if (!popup) {
                    return;
                  }
                  const imageOnPopup = popup.document.createElement('img');
                  imageOnPopup.src = photo.image.dataUrl;
                  popup.document.body.appendChild(imageOnPopup);
                  popup.document.title = 'Unsaved Photo';
                } else {
                  // If there's a plain URL available, then just open it!
                  window.open(photo.image.url, '_blank');
                }
              }}
            />
            <Button
              type="cleared"
              size="small"
              trailingIcon={
                <Icons.Trash width={18} height={18} color="currentColor" />
              }
              onClick={() => {
                dispatch({
                  type: 'photoGroup.photos.remove',
                  id: photoGroup.id,
                  photoId: photo.id,
                });
              }}
              data-cy="delete-photo"
            />
          </HorizontalForm>
        </div>
      </div>
    </DarkTheme>
  </div>
);

const FocusedPhotoGroupPanel: React.FunctionComponent<{
  photoGroup: PhotoGroup;
  planId: string;
  dispatch: React.Dispatch<Action>;
  client: AxiosInstance;
}> = ({ photoGroup, planId, dispatch, client }) => {
  const onUploadPhoto = async (
    image: HTMLImageElement,
    dataUrl: string,
    file: File
  ) => {
    const [, imageData] = dataUrl.split(',');
    const imageBytesAsString = atob(imageData);
    const byteArray = new Uint8Array(imageBytesAsString.length);
    for (let i = 0; i < imageBytesAsString.length; i++) {
      byteArray[i] = imageBytesAsString.charCodeAt(i);
    }

    let photoId: string, uploadUrl: string;
    try {
      const imageUploadResponse = await getUploadImageUrl(
        client,
        planId,
        file.name,
        file.type
      );
      photoId = imageUploadResponse.data.photo_id;
      uploadUrl = imageUploadResponse.data.upload_url;
    } catch {
      toast.error('Error: Failed to initialize image upload image!');
      return;
    }

    const fileNameWithoutExtension = file.name.replace(/\.[^.]+$/, '');

    dispatch({
      type: 'photoGroup.photos.append',
      id: photoGroup.id,
      photoDataUrl: dataUrl,
      fileName: fileNameWithoutExtension,
      uploadedPhotoId: photoId,
    });

    try {
      await axios.put(uploadUrl, byteArray.buffer, {
        headers: {
          'Content-Type': file.type,
        },
      });
    } catch {
      toast.error('Error: Failed to upload image!');
      dispatch({ type: 'photoGroup.remove', id: photoGroup.id });
      return;
    }
  };

  return (
    <div data-cy="focused-photo-group-panel" data-cy-locked={photoGroup.locked}>
      <Panel position="top-right">
        <PanelHeader>
          <div
            className={classnames([
              styles.inputInline,
              styles.panelHeaderTextboxActionWrapper,
            ])}
          >
            <TextField
              type="text"
              size="medium"
              value={photoGroup.name}
              placeholder={photoGroup.name}
              disabled={photoGroup.locked}
              onChange={(evt) => {
                const name = evt.currentTarget.value;
                dispatch({
                  type: 'photoGroup.changeName',
                  id: photoGroup.id,
                  name,
                });
              }}
              leadingIcon={
                <Icons.FolderImage height={18} width={18} color={colors.blue} />
              }
              width="100%"
              data-cy="name"
            />
          </div>
        </PanelHeader>

        <PanelBody>
          <PanelBodySection>
            <NotesBox
              notes={photoGroup.notes}
              disabled={photoGroup.locked}
              onNotesEdited={(notes) =>
                dispatch({
                  type: 'photoGroup.saveNotes',
                  id: photoGroup.id,
                  notes,
                })
              }
              data-cy="notes"
            />
          </PanelBodySection>

          <PanelBodySection>
            <ImageUploader onUploadImage={onUploadPhoto}>
              {(trigger) =>
                photoGroup.photos.length === 0 ? (
                  <div onClick={trigger} className={styles.photoEmptyButton}>
                    <Icons.ImageAddAlt color="currentColor" />
                    &nbsp;Take / Upload a photo
                  </div>
                ) : (
                  <Button
                    size="medium"
                    type="outlined"
                    leadingIcon={
                      <Icons.ReportAdd
                        width={18}
                        height={18}
                        color={colors.blue}
                      />
                    }
                    onClick={trigger}
                    width="100%"
                  >
                    Take / Upload a photo
                  </Button>
                )
              }
            </ImageUploader>
          </PanelBodySection>

          <PanelBodySection>
            {/* TODO: uncomment the below once the dragging interface is supported by the backend */}
            {/* {photoGroup.locked ? ( */}
            {true || photoGroup.locked ? (
              photoGroup.photos.map((photo) => (
                <div style={{ marginBottom: 8 }} key={photo.id}>
                  <Photo
                    photo={photo}
                    photoGroup={photoGroup}
                    dispatch={dispatch}
                    canBeDragged={false}
                    dragging={false}
                  />
                </div>
              ))
            ) : (
              <ReactMovableList
                values={photoGroup.photos}
                onChange={({ oldIndex, newIndex }) => {
                  dispatch({
                    type: 'photoGroup.photos.reorder',
                    id: photoGroup.id,
                    oldIndex,
                    newIndex,
                  });
                }}
                renderList={({ children, props }) => (
                  <div {...props}>{children}</div>
                )}
                renderItem={({ value, props, isDragged }) => (
                  <div {...props} style={{ ...props.style, marginBottom: 8 }}>
                    <Photo
                      photo={value}
                      photoGroup={photoGroup}
                      dispatch={dispatch}
                      canBeDragged={true}
                      dragging={isDragged}
                    />
                  </div>
                )}
              />
            )}
          </PanelBodySection>

          <PanelBodySection>
            <div className={styles.photoUploadHint}>
              Not sure what kind of photos to take?{' '}
              <a
                href="https://support.density.io/hc/en-us/articles/4405398293275"
                rel="noreferrer"
                target="_blank"
              >
                Read the guidelines.
              </a>
            </div>
          </PanelBodySection>
        </PanelBody>

        <PanelActions
          left={
            <HorizontalForm size="medium">
              <Button
                type="cleared"
                size="medium"
                trailingIcon={
                  <Icons.Trash width={18} height={18} color="currentColor" />
                }
                danger
                disabled={photoGroup.locked}
                onClick={() => {
                  dispatch({ type: 'photoGroup.remove', id: photoGroup.id });
                }}
                data-cy="delete-photo-group"
              />
              <Button
                type="cleared"
                size="medium"
                trailingIcon={
                  photoGroup.locked ? (
                    <Icons.LockClosed
                      width={18}
                      height={18}
                      color={colors.yellow}
                    />
                  ) : (
                    <Icons.LockOpen
                      width={18}
                      height={18}
                      color="currentColor"
                    />
                  )
                }
                onClick={() => {
                  const lockToggleAction = photoGroup.locked
                    ? 'photoGroup.unlock'
                    : 'photoGroup.lock';
                  dispatch({ type: lockToggleAction, id: photoGroup.id });
                }}
                data-cy="lock-button"
              />
              {/*
              <Button
                type="cleared"
                size="medium"
                trailingIcon={<Icons.CopyAlt width={18} height={18} color="currentColor" />}
                onClick={() =>
                  dispatch({ type: 'menu.duplicatePhotoGroup', id: photoGroup.id })
                }
              />
              */}
            </HorizontalForm>
          }
          right={
            <Button
              type="cleared"
              size="medium"
              onClick={() =>
                dispatch({ type: 'photoGroup.dismiss', id: photoGroup.id })
              }
            >
              Done
            </Button>
          }
        />
      </Panel>
    </div>
  );
};

export default FocusedPhotoGroupPanel;
