import * as React from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ValueLabel from 'components/value-label/value-label';
import { useAppSelector } from 'redux/store';
import GhostButton from 'components/ghost-button/ghost-button';
import { ReactComponent as ChevronToggleCollapse } from 'img/chevron-toggle-collapse.svg';
import { ReactComponent as ChevronToggleExpand } from 'img/chevron-toggle-expand.svg';
import { selectSubspaces } from 'redux/features/spaces/select-subspaces';
import { ReactComponent as CopyIcon } from 'img/icon-copy.svg';
import { satisfyFilters } from 'lib/filter';
import { CoreSpace } from '@densityco/lib-api-types';
import { Floor } from 'lib/floors';

// TODO(wuweiweiwu): can def share some stuff between here and floor card as they are both collapsable

const Content: React.FC<{ isExpanded?: boolean }> = ({
  children,
  isExpanded,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  // some large initial value so when the content mounts, its already expanded
  const [height, setHeight] = React.useState(10000);

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let height = 0;

    Array.from(ref.current.children).forEach((child) => {
      height += (child as HTMLElement).offsetHeight;
    });

    setHeight(height);
  }, []);

  return (
    <div
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        transition: max-height 0.5s ease;
        overflow: ${isExpanded ? 'auto' : 'hidden'};
        // solution from https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css?rq=1
        // kinda hacky since we have to set an arbitrary max height. We don't know the height of the card (unless we do getBoundingClientRect) since
        // the height grows dependant of the children. it is alleviated by calculating the height from all of the child nodes offsetHeight
        // but if the content of the card is significantly higher, the transition time takes longer
        max-height: ${isExpanded ? height : 0}px;
      `}
    >
      {children}
    </div>
  );
};

const Section = styled.section`
  padding: ${dust.Space4};
  border-bottom: 1px solid ${dust.Gray200};
`;

const MetricsSection: React.FC<{ spaces: CoreSpace[] }> = (props) => {
  const count = props.spaces.length;
  const capacity = props.spaces.reduce((acc, curr) => {
    return acc + (curr.capacity || 0);
  }, 0);

  return (
    <Section>
      <div
        css={css`
          display: grid;
          row-gap: 12px;
          column-gap: 40px;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 12px;
        `}
      >
        <ValueLabel label="Capacity" value={capacity} />
        <ValueLabel label="Spaces" value={count} />
      </div>
    </Section>
  );
};

const Header: React.FC<{
  isExpanded: boolean;
  toggleExpanded: VoidFunction;
}> = (props) => {
  const ToggleIcon = props.isExpanded
    ? ChevronToggleCollapse
    : ChevronToggleExpand;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 0 8px 0 12px;
        gap: ${dust.Space3};
        height: 2.5rem;
        border-bottom: 1px solid ${dust.Gray200};
        flex-shrink: 0;
      `}
    >
      <div
        css={css`
          margin-left: -${dust.Space2};
          color: ${dust.Gray400};
          transform: translateY(2px);
        `}
      >
        <CopyIcon width={24} height={24} color="currentColor" />
      </div>

      <span
        css={css`
          width: 100%;
          font-size: ${dust.TextScale4};
          font-weight: ${dust.FontWeightBold};
          margin-top: 2px;
          // https://css-tricks.com/almanac/properties/l/line-clamp/
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `}
      >
        Filtered Spaces
      </span>

      <div
        css={css`
          display: flex;
          align-items: center;
          margin-left: auto;
          color: ${dust.Gray400};
          gap: ${dust.Space2};
          flex-shrink: 0;
        `}
      >
        <GhostButton onClick={props.toggleExpanded}>
          <ToggleIcon width={24} height={24} color="currentColor" />
        </GhostButton>
      </div>
    </div>
  );
};

type FilteredCardProps = { floor: Floor };

const FilteredCard: React.FC<FilteredCardProps> = (props) => {
  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );

  const spaceFunctionFilters = useAppSelector(
    (state) => state.analysis.spaceFunctionFilters
  );
  const labelFilters = useAppSelector((state) => state.analysis.labelFilters);

  const subspaces = useAppSelector((state) =>
    selectSubspaces(state, { spaceId: props.floor.id })
  );

  const [isExpanded, setIsExpanded] = React.useState(true);

  const subspacesThatSatisfyFilter = React.useMemo(() => {
    return subspaces.filter((space) => {
      return satisfyFilters(spaceFunctionFilters, labelFilters, space);
    });
  }, [labelFilters, spaceFunctionFilters, subspaces]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 17.5rem;
        min-width: 17.5rem;
        max-height: ${heatmapEnabled ? 'calc(100% - 80px)' : '100%'};
        padding: 0;
        background-color: ${dust.White};
        border: 1px solid ${dust.Gray200};
        border-radius: ${dust.Radius300};
        box-shadow: ${dust.Elevation300};
        pointer-events: all;
      `}
    >
      <Header
        isExpanded={isExpanded}
        toggleExpanded={() => setIsExpanded((prev) => !prev)}
      />

      <Content isExpanded={isExpanded}>
        <MetricsSection spaces={subspacesThatSatisfyFilter} />
      </Content>
    </div>
  );
};

export default React.memo(FilteredCard);
