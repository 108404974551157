import mixpanel, { Dict } from 'mixpanel-browser';
import Perfume from 'perfume.js';

import { MIXPANEL_TOKEN, NODE_ENV } from '../config';

if (NODE_ENV === 'production' && MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN);
}

export class Analytics {
  static identify(id: string) {
    if (NODE_ENV === 'development') {
      return;
    }

    if (!MIXPANEL_TOKEN) {
      return;
    }

    mixpanel.identify(id);
  }

  static track(name: string, properties?: Dict) {
    if (NODE_ENV === 'development') {
      return;
    }

    if (!MIXPANEL_TOKEN) {
      return;
    }

    mixpanel.track(`OA ${name}`, properties);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const perfume = new Perfume({
  analyticsTracker: (options) => {
    const { metricName, data } = options;
    switch (metricName) {
      case 'navigationTiming':
        // @ts-ignore
        if (data && data.timeToFirstByte && typeof data !== 'number') {
          Analytics.track('navigationTiming', data);
        }
        break;
      case 'networkInformation':
        // @ts-ignore
        if (data && data.effectiveType && typeof data !== 'number') {
          Analytics.track('networkInformation', data);
        }
        break;
      case 'fp':
        Analytics.track('firstPaint', { duration: data });
        break;
      case 'fcp':
        Analytics.track('firstContentfulPaint', { duration: data });
        break;
      case 'fid':
        Analytics.track('firstInputDelay', { duration: data });
        break;
      case 'lcp':
        Analytics.track('largestContentfulPaint', { duration: data });
        break;
      case 'lcpFinal':
        Analytics.track('largestContentfulPaintFinal', { duration: data });
        break;
      case 'cls':
        Analytics.track('cumulativeLayoutShift', { duration: data });
        break;
      case 'clsFinal':
        Analytics.track('cumulativeLayoutShiftFinal', { duration: data });
        break;
      case 'tbt':
        Analytics.track('totalBlockingTime', { duration: data });
        break;
      case 'tbt5S':
        Analytics.track('totalBlockingTime5S', { duration: data });
        break;
      case 'tbt10S':
        Analytics.track('totalBlockingTime10S', { duration: data });
        break;
      case 'tbtFinal':
        Analytics.track('totalBlockingTimeFinal', { duration: data });
        break;
      default:
        Analytics.track(metricName, { duration: data });
        break;
    }
  },
});
