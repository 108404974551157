import { useCallback, useState } from 'react';
import * as React from 'react';
import axios from 'axios';
import { Icons } from '@densityco/ui';
import { toast } from 'react-toastify';
import { useTreatment } from 'contexts/treatments';

import styles from './styles.module.scss';

import { CADFileUploader } from 'components/image-uploader';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import FloorplanMeasure, {
  Measurement,
} from 'components/floorplan-measure/floorplan-measure';
import AppBarFloorplan from 'components/app-bar-floorplan';
import FloorSingleView from 'components/floor-single-view/floor-single-view';
import { uploadDXFFile } from 'components/editor/cad-import';
import { PlanDXF } from 'components/editor/state';
import { PlanDetail, FloorplanAPI } from 'lib/api';
import { SPLITS } from 'lib/treatments';
import { useAppSelector } from 'redux/store';
import { useRouteMatch } from 'react-router-dom';

type PlanCreatorState =
  | { status: 'upload' }
  | { status: 'uploading_image'; fileUploadPercent?: number }
  | {
      status: 'measure';
      image: HTMLImageElement;
    }
  | { status: 'uploading_dxf'; fileUploadPercent?: number }
  | { status: 'upload_dxf_error' }
  | PlanDXF
  | { status: 'creating_plan' };

const PlanCreator: React.FunctionComponent<{
  onPlanCreated: (plan: PlanDetail) => void;
}> = ({ onPlanCreated }) => {
  const { params } = useRouteMatch<{ floorId: string }>();

  const importDXFEnabled = useTreatment(SPLITS.IMPORT_DXF);

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const [state, setState] = useState<PlanCreatorState>({
    status: 'upload' as const,
  });

  const onUploadFloorplanImage = useCallback((image: HTMLImageElement) => {
    setState({
      status: 'measure',
      image,
    });
  }, []);

  const onUploadFloorplanCADFile = useCallback(
    async (sourceFile: File, dataUrl: string) => {
      if (!densityAPIClient) {
        return;
      }

      if (!importDXFEnabled) {
        toast.error('DXF import not enabled.');
        return;
      }

      // Create new plan
      setState({ status: 'creating_plan' });
      let response;
      try {
        response = await FloorplanAPI.createFloorplan(densityAPIClient, {
          floor_id: params.floorId,
          image_key: '',
          image_width_pixels: 0,
          image_height_pixels: 0,
          image_pixels_per_meter: 0,
          origin_x_pixels: 0,
          origin_y_pixels: 0,
          measurement_point_a_x_pixels: 0,
          measurement_point_a_y_pixels: 0,
          measurement_point_b_x_pixels: 0,
          measurement_point_b_y_pixels: 0,
          measurement_computed_length_meters: 0,
          measurement_user_entered_length_feet: '',
          measurement_user_entered_length_inches: '',
        });
      } catch (err) {
        toast.error('Error creating plan.');
        return;
      }
      const plan = response.data;

      // Upload DXF to plan
      uploadDXFFile(
        params.floorId,
        plan.id,
        densityAPIClient,
        sourceFile,
        () => setState({ status: 'uploading_dxf' }),
        (percent) =>
          setState({ status: 'uploading_dxf', fileUploadPercent: percent }),
        () => setState({ status: 'upload_dxf_error' }),
        (planDXF) => {
          setState(planDXF);

          // Redirect to planning view
          window.location.href = `/buildings/${plan.floor.parent_id}/floors/${plan.floor.id}/planning`;
        }
      );
    },
    [importDXFEnabled, densityAPIClient, params.floorId]
  );

  const onMeasurementSubmit = useCallback(
    async (measurement: Measurement, image: HTMLImageElement) => {
      if (!densityAPIClient) {
        return;
      }

      const imageData = image.src.split(',')[1];

      setState({ status: 'uploading_image' });

      // Upload floorplan image
      const signedUrlResponse = (
        await FloorplanAPI.imageUpload(densityAPIClient, {
          floor_id: params.floorId,
          ext: 'png',
          content_type: 'image/png',
        })
      ).data;

      const { key: objectKey, signed_url: signedUrl } = signedUrlResponse;

      const imageBytesAsString = atob(imageData);
      const byteArray = new Uint8Array(imageBytesAsString.length);
      for (let i = 0; i < imageBytesAsString.length; i++) {
        byteArray[i] = imageBytesAsString.charCodeAt(i);
      }

      const putImageResponse = await axios.put(signedUrl, byteArray.buffer, {
        headers: {
          'Content-Type': 'image/png',
        },
        onUploadProgress: (event) => {
          const percent = (event.loaded / event.total) * 100;
          setState({ status: 'uploading_image', fileUploadPercent: percent });
        },
      });

      if (putImageResponse.status !== 200) {
        toast.error('Error uploading image.');
        return;
      }

      setState({ status: 'creating_plan' });

      let response;
      try {
        response = await FloorplanAPI.createFloorplan(densityAPIClient, {
          floor_id: params.floorId,
          image_key: objectKey,
          image_width_pixels: image.width,
          image_height_pixels: image.height,
          image_pixels_per_meter: measurement.computedScale,
          origin_x_pixels: 0,
          origin_y_pixels: 0,
          measurement_point_a_x_pixels: measurement.pointA.x,
          measurement_point_a_y_pixels: measurement.pointA.y,
          measurement_point_b_x_pixels: measurement.pointB.x,
          measurement_point_b_y_pixels: measurement.pointB.y,
          measurement_computed_length_meters: measurement.computedLength,
          measurement_user_entered_length_feet:
            measurement.userEnteredLength.feetText,
          measurement_user_entered_length_inches:
            measurement.userEnteredLength.inchesText,
        });
      } catch (err) {
        toast.error('Error creating plan.');
        return;
      }

      toast.success('Plan created.');
      onPlanCreated(response.data);
    },
    [densityAPIClient, params.floorId, onPlanCreated]
  );

  if (state.status === 'measure') {
    return (
      <FloorSingleView>
        <FloorplanMeasure
          image={state.image}
          renderAsModal={false}
          onSubmit={(measurement) =>
            onMeasurementSubmit(measurement, state.image)
          }
          onCancel={() => setState({ status: 'upload' })}
        />
      </FloorSingleView>
    );
  } else if (state.status === 'upload_dxf_error') {
    return (
      <FloorSingleView>
        <LoadingOverlay text="Error uploading!" />
      </FloorSingleView>
    );
  } else {
    let title = 'Processing DXF...';
    if (state.status === 'upload') {
      title = 'Upload a floorplan';
    }
    if (state.status === 'uploading_dxf') {
      title = 'Uploading DXF...';
    }
    if (state.status === 'uploading_image') {
      title = 'Uploading Image...';
    }
    return (
      <FloorSingleView>
        <AppBarFloorplan />
        <div className={styles.PlanCreator}>
          <div className={styles.floorUploadPanel}>
            <div className={styles.floorUploadPanelHeader}>
              <div className={styles.floorUploadPanelHeaderTitle}>{title}</div>
              {state.status === 'upload' ? (
                <div className={styles.floorUploadPanelHeaderMessage}>
                  This floor doesn’t have a floorplan attached. Upload a
                  floorplan to get started.
                </div>
              ) : null}
            </div>

            {state.status === 'upload' ? (
              <div className={styles.floorUploadPanelBody}>
                <CADFileUploader
                  onUploadImage={onUploadFloorplanImage}
                  onUploadCADFile={onUploadFloorplanCADFile}
                >
                  {(trigger) => {
                    return (
                      <div className={styles.uploadArea} onClick={trigger}>
                        <div className={styles.uploadAreaInstructions}>
                          <div className={styles.uploadAreaAction}>
                            <Icons.Upload color="currentColor" />
                          </div>
                          <span className={styles.uploadAreaAction}>
                            Upload
                          </span>{' '}
                          a floorplan
                        </div>
                      </div>
                    );
                  }}
                </CADFileUploader>
              </div>
            ) : null}
            {state.status === 'uploading_dxf' ||
            state.status === 'uploading_image' ? (
              <div className={styles.floorUploadingPanelBody}>
                <div className={styles.progressBarWrapper}>
                  <div
                    className={styles.progressBarInner}
                    style={{
                      width: `${state.fileUploadPercent || 0}%`,
                    }}
                  />
                </div>
                <div className={styles.progressBarPercent}>
                  {Math.round(state.fileUploadPercent || 0)}%
                </div>
              </div>
            ) : null}
            {state.status === 'creating_plan' ? (
              <div className={styles.floorUploadingPanelBody}>
                Creating plan...
              </div>
            ) : null}
            {state.status !== 'upload' &&
            state.status !== 'uploading_dxf' &&
            state.status !== 'uploading_image' &&
            state.status !== 'creating_plan' ? (
              // TODO: add some proper UI here!
              <div className={styles.floorUploadingPanelBody}>
                Currently in the <strong>{state.status}</strong> step.
              </div>
            ) : null}
          </div>
        </div>
      </FloorSingleView>
    );
  }
};

export default PlanCreator;
