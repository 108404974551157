import { Fragment, useEffect, useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Icons } from '@densityco/ui';
import colors from '@densityco/ui/variables/colors.json';
import { convertISOTimestampToFormattedComponentsInLocalTime } from '@densityco/lib-time-helpers';

import { Action } from './actions';
import { State, Sensor, getSensorStatusColor } from './state';
import styles from './styles.module.scss';
import { css } from '@emotion/react';
import { ReactComponent as HelpQuestionMark } from 'img/help-question-mark.svg';

import {
  computeCoverageRadiusOA,
  computeCoverageMajorMinorAxisOA,
  supportsLocate,
} from 'lib/sensor';
import { locateSensor, getSensorDiagnostics } from 'lib/api';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import {
  displayCircularArea,
  displayEllipticalArea,
  displayLength,
  LengthUnit,
  Meters,
} from 'lib/units';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import Switch from 'components/switch/switch';
import Tooltip from 'components/tooltip';
import TextField from 'components/text-field';
import RotationField from 'components/rotation-field';
import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import NotesBox from 'components/notes-box';
import Panel, { PanelHeader, PanelBody, PanelActions } from 'components/panel';
import { AxiosInstance } from 'axios';

const SensorRotationInput: React.FunctionComponent<{
  value: number;
  disabled: boolean;
  onChange: (rotation: number) => void;
  onRotateRight90: () => void;
}> = ({ value, disabled, onChange, onRotateRight90 }) => {
  return (
    <RotationField
      value={value}
      disabled={disabled}
      onChange={onChange}
      onRotateRight90={onRotateRight90}
      rotationSVGGlyph={
        <Fragment>
          <path
            d="M18.1113 32.2227H21.8891V34.556H18.1113V32.2227Z"
            fill="transparent"
            stroke={dust.Blue400}
            strokeWidth="2"
          />
          <path
            d="M20 35.5557V39.889"
            fill="transparent"
            stroke={dust.Blue400}
            strokeWidth="2"
          />
          <path
            d="M26.8077 31.6375L26.8072 31.6375L13.2474 31.667L13.247 31.667C10.3731 31.6744 8.03712 29.349 8.02948 26.4742C8.02948 26.4741 8.02948 26.4739 8.02948 26.4738L8.00002 12.9144L8.00002 12.914C7.99261 10.0395 10.3156 7.70411 13.1899 7.69648C13.19 7.69647 13.1901 7.69647 13.1903 7.69647L26.7526 7.66701L26.753 7.66701C29.6269 7.6596 31.9629 9.98494 31.9705 12.8598C31.9705 12.8599 31.9705 12.8601 31.9705 12.8602L32 26.4196L32 26.42C32.0074 29.2974 29.6815 31.6326 26.8077 31.6375Z"
            stroke={dust.Blue400}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
          />
          <path
            d="M18.6875 14.1498C18.6875 13.8129 18.9572 13.5278 19.3045 13.5278H20.6958C21.0431 13.5278 21.3128 13.8128 21.3128 14.1498V16.7848C21.3128 18.5391 22.263 20.064 23.6634 20.8573L23.6686 20.8602L25.8331 22.1342C26.1281 22.3078 26.2241 22.6862 26.0578 22.9797L25.3621 24.208C25.1937 24.5055 24.8144 24.6128 24.5153 24.4368L22.3294 23.1502C21.6475 22.7356 20.8515 22.4973 20.0002 22.4973C19.1489 22.4973 18.353 22.7355 17.6713 23.15L17.6668 23.1527L15.4851 24.4368C15.1859 24.6128 14.8066 24.5054 14.6381 24.208L13.9425 22.9797C13.7763 22.6863 13.8722 22.3078 14.1672 22.1342L16.3366 20.8574C17.7371 20.0641 18.6875 18.5392 18.6875 16.7848V14.1498Z"
            fill={dust.Blue400}
            stroke="transparent"
          />
          <path
            d="M15.6665 4.50022L19.9998 1.61133L24.3332 4.50022"
            stroke={dust.Blue400}
            strokeWidth="2"
            fill="transparent"
          />
        </Fragment>
      }
      data-cy="sensors-focused-panel-rotation-text-field"
    />
  );
};

const reduceDecimalPlaces = (value: number): string => {
  const numDigits = value.toString().length;
  if (numDigits <= 5) {
    return value.toString();
  } else if (value > 10000) {
    return value.toFixed(0);
  } else if (value > 1000) {
    return value.toFixed(1);
  } else if (value > 100) {
    return value.toFixed(2);
  } else if (value > 10) {
    return value.toFixed(3);
  } else if (value > 1) {
    return value.toFixed(4);
  } else {
    return value.toFixed(5);
  }
};

const SensorHeightField: React.FunctionComponent<{
  width: number;
  min: number;
  max: number;
  value: number;
  disabled: boolean;
  displayUnits: LengthUnit; // only here so we can use it as a useEffect dependency
  handleSendToState: (height: number) => void;
}> = ({
  width,
  value,
  min,
  max,
  disabled,
  displayUnits,
  handleSendToState,
}) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());
  const [focusing, setFocusing] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean>(true);

  useEffect(() => {
    const newValue = Number(inputValue);
    if (isNaN(newValue)) {
      setIsInputValid(false);
    } else if (newValue >= min && newValue <= max) {
      setIsInputValid(true);
    } else {
      setIsInputValid(false);
    }
  }, [inputValue, min, max]);

  useEffect(() => {
    // Reset the input value if the units change
    setInputValue(value.toString());
  }, [displayUnits]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Only update the input value to be the value from state if:
    // 1) the input is not currently being updated (not focused)
    // 2) it's a valid input (we want to show the invalid input to make it clear it needs to be updated)

    if (!focusing && isInputValid) {
      setInputValue(value.toString());
    }
  }, [value, inputValue, focusing, isInputValid]);

  return (
    <TextField
      type="number"
      width={width}
      size="medium"
      disabled={disabled}
      min={min}
      max={max}
      step={1}
      value={inputValue}
      onChange={(evt) => setInputValue(evt.currentTarget.value)}
      onBlur={(evt) => {
        setFocusing(false);
        const newValue = Number(evt.currentTarget.value);
        handleSendToState(newValue);
      }}
      onFocus={() => setFocusing(true)}
      error={!focusing && !isInputValid}
      data-cy="sensor-height-field"
    />
  );
};

const parseMeasurement = (m: string): number =>
  Math.round(window.parseFloat(m));

// Need a completely separate component for feet + inches to handle inches overflow
const SensorFeetInchesField: React.FunctionComponent<{
  valueMeters: number;
  sensorHeightBounds: { min: number; max: number };
  disabled: boolean;
  onChange: (height: number) => void;
}> = ({ valueMeters, sensorHeightBounds, disabled, onChange }) => {
  const [feet, inches] = Meters.toFeetAndInches(valueMeters);
  const [feetInput, setFeetInput] = useState<string>(feet.toString());
  const [inchesInput, setInchesInput] = useState<string>(inches.toString());
  const [isValueValid, setIsValueValid] = useState<boolean>(true);
  const [focusingFeet, setFocusingFeet] = useState<boolean>(false);
  const [focusingInches, setFocusingInches] = useState<boolean>(false);

  useEffect(() => {
    const newValue = Meters.fromFeetAndInches(
      parseMeasurement(feetInput),
      parseMeasurement(inchesInput)
    );
    if (isNaN(newValue)) {
      setIsValueValid(false);
    } else if (
      newValue >= sensorHeightBounds.min &&
      newValue <= sensorHeightBounds.max
    ) {
      setIsValueValid(true);
    } else {
      setIsValueValid(false);
    }
  }, [feetInput, inchesInput, sensorHeightBounds.max, sensorHeightBounds.min]);

  const handleSubmit = () => {
    const feet = parseMeasurement(feetInput);
    const inches = parseMeasurement(inchesInput);
    setFeetInput(`${feet}`);
    setInchesInput(`${inches}`);

    const newMeters = Meters.fromFeetAndInches(feet, inches);
    if (
      newMeters > sensorHeightBounds.max ||
      newMeters < sensorHeightBounds.min
    ) {
      setIsValueValid(false);
    } else {
      setIsValueValid(true);
    }
    onChange(newMeters);
  };

  return (
    <div className={styles.sensorHeightFormGroup}>
      <div className={styles.sensorHeightFormFields}>
        <div className={styles.inputInlineIcon}>
          <Icons.RulerVertical height={18} width={18} color="currentColor" />
        </div>
        <TextField
          type="number"
          width={40}
          size="medium"
          disabled={disabled}
          min={Meters.toFeet(sensorHeightBounds.min)}
          max={Meters.toFeet(sensorHeightBounds.max)}
          step={1}
          value={feetInput}
          onChange={(evt) => {
            setFeetInput(evt.currentTarget.value);
          }}
          onBlur={() => {
            setFocusingFeet(false);
            handleSubmit();
          }}
          onFocus={() => setFocusingFeet(true)}
          error={!focusingFeet && !focusingInches && !isValueValid}
          data-cy="sensor-height-feet"
        />
        <label className={styles.sensorHeightInputLabel}>ft</label>
        <TextField
          type="number"
          width={40}
          size="medium"
          disabled={disabled}
          min={-1}
          max={12}
          step={1}
          value={inchesInput}
          onChange={(evt) => {
            const value = parseMeasurement(evt.currentTarget.value);
            if (value === -1) {
              const nextFeet = feet - 1;
              const nextInches = 11;
              setFeetInput(nextFeet.toString());
              setInchesInput(nextInches.toString());
            } else if (value === 12) {
              const nextFeet = feet + 1;
              const nextInches = 0;
              setFeetInput(nextFeet.toString());
              setInchesInput(nextInches.toString());
              onChange(Meters.fromFeetAndInches(nextFeet, nextInches));
            } else {
              setInchesInput(value.toString());
            }
          }}
          onBlur={() => {
            setFocusingInches(false);
            handleSubmit();
          }}
          onFocus={() => setFocusingInches(true)}
          error={!focusingFeet && !focusingInches && !isValueValid}
          data-cy="sensor-height-inches"
        />
        <label className={styles.sensorHeightInputLabel}>in</label>
      </div>
      {/* .sensorHeightInputFieldss */}
      <div className={styles.sensorHeightValue}>
        {feet}' {inches}"
      </div>
    </div>
  );
};

export const SensorHeightInput: React.FunctionComponent<{
  value: number;
  sensorHeightBounds: { min: number; max: number };
  disabled: boolean;
  displayUnits: LengthUnit;
  onChange: (height: number) => void;
}> = ({ value, sensorHeightBounds, disabled, displayUnits, onChange }) => {
  switch (displayUnits) {
    case 'inches': {
      const inches = Math.round(Meters.toInches(value));
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical
                height={18}
                width={18}
                color="currentColor"
              />
            </div>
            <SensorHeightField
              width={80}
              min={Meters.toInches(sensorHeightBounds.min)}
              max={Meters.toInches(sensorHeightBounds.max)}
              value={inches}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromInches(Math.round(val)));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>in</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(inches)}"
          </div>
        </div>
      );
    }
    case 'centimeters': {
      const centimeters = Meters.toCentimeters(value);
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical
                height={18}
                width={18}
                color="currentColor"
              />
            </div>
            <SensorHeightField
              width={80}
              min={Meters.toCentimeters(sensorHeightBounds.min)}
              max={Meters.toCentimeters(sensorHeightBounds.max)}
              value={centimeters}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromCentimeters(val));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>cm</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(centimeters)}cm
          </div>
        </div>
      );
    }
    case 'millimeters': {
      const millimeters = Meters.toMillimeters(value);
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical
                height={18}
                width={18}
                color="currentColor"
              />
            </div>
            <SensorHeightField
              width={80}
              min={Meters.toMillimeters(sensorHeightBounds.min)}
              max={Meters.toMillimeters(sensorHeightBounds.max)}
              value={millimeters}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(Meters.fromMillimeters(val));
              }}
            />
            <label className={styles.sensorHeightInputLabel}>mm</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(millimeters)}mm
          </div>
        </div>
      );
    }
    case 'meters': {
      const meters = value;
      return (
        <div className={styles.sensorHeightFormGroup}>
          <div className={styles.sensorHeightFormFields}>
            <div className={styles.inputInlineIcon}>
              <Icons.RulerVertical
                height={18}
                width={18}
                color="currentColor"
              />
            </div>
            <SensorHeightField
              width={80}
              min={sensorHeightBounds.min}
              max={sensorHeightBounds.max}
              value={meters}
              disabled={disabled}
              displayUnits={displayUnits}
              handleSendToState={(val: number) => {
                onChange(val);
              }}
            />
            <label className={styles.sensorHeightInputLabel}>m</label>
          </div>
          {/* .sensorHeightInputFieldss */}
          <div className={styles.sensorHeightValue}>
            {reduceDecimalPlaces(meters)}m
          </div>
        </div>
      );
    }
    case 'feet_and_inches': {
      return (
        <SensorFeetInchesField
          valueMeters={value}
          onChange={onChange}
          disabled={disabled}
          sensorHeightBounds={sensorHeightBounds}
        />
      );
    }
  }
};

const FocusedSensorPanel: React.FunctionComponent<{
  state: State;
  sensor: Sensor;
  dispatch: React.Dispatch<Action>;
  client: AxiosInstance;
}> = ({ state, sensor, dispatch, client }) => {
  const ellipticalOACoverageEnabled = useTreatment(
    SPLITS.ELLIPTICAL_OA_COVERAGE
  );

  const [serialNumberText, setSerialNumberText] = useState<string>(
    sensor.serialNumber || ''
  );

  const sensorConnection = state.sensorConnections.get(sensor.id) || null;
  const sensorStreamingStatus = sensorConnection
    ? sensorConnection.status
    : 'Off';
  const sensorIsLinked = sensor.serialNumber !== null;

  const radiusMeters = computeCoverageRadiusOA(sensor.height);
  const radius = displayLength(radiusMeters, state.displayUnit);
  const [majorMeters, minorMeters] = computeCoverageMajorMinorAxisOA(
    sensor.height
  );
  const major = displayLength(majorMeters, state.displayUnit);
  const minor = displayLength(minorMeters, state.displayUnit);
  const coverageArea = ellipticalOACoverageEnabled
    ? displayCircularArea(radiusMeters, state.displayUnit)
    : displayEllipticalArea(majorMeters, minorMeters, state.displayUnit);

  const lastHeartbeat = sensor.last_heartbeat
    ? convertISOTimestampToFormattedComponentsInLocalTime(sensor.last_heartbeat)
    : null;
  const heartbeatTime = lastHeartbeat
    ? `${lastHeartbeat.date} ${lastHeartbeat.time} ${lastHeartbeat.timezone}`
    : '--';

  const [locateButtonDisabled, setLocateButtonDisabled] = useState(false);

  const unlinkSensor = () => {
    setSerialNumberText('');
    dispatch({
      type: 'sensor.changeSerialNumber',
      id: sensor.id,
      serialNumber: null,
    });
    dispatch({
      type: 'sensor.menu.disconnect',
      id: sensor.id,
    });
    // Clear config metadata when unlinking
    dispatch({
      type: 'sensor.setDiagnosticMetadata',
      id: sensor.id,
      status: undefined,
      ipv4: undefined,
      ipv6: undefined,
      mac: undefined,
      last_heartbeat: undefined,
      os: undefined,
    });
  };

  return (
    <Panel position="top-left">
      <PanelHeader>
        <div
          className={classNames([
            styles.inputInline,
            styles.panelHeaderTextboxActionWrapper,
          ])}
        >
          <TextField
            size="medium"
            leadingIcon={
              sensor.type === 'oa' ? (
                <Icons.DeviceSide height={18} width={18} color={colors.blue} />
              ) : (
                <Icons.DeviceTop height={18} width={18} color={colors.purple} />
              )
            }
            placeholder={sensor.type === 'oa' ? 'ex: B2EJK019' : sensor.name}
            type="text"
            value={serialNumberText}
            disabled={sensorIsLinked}
            onChange={(evt) => {
              setSerialNumberText(evt.currentTarget.value.toUpperCase());
            }}
            width="100%"
            data-cy="sensors-focused-panel-serial-number"
          />

          <div className={styles.panelHeaderTextboxAction}>
            {sensorIsLinked ? (
              <Button
                size="medium"
                type="outlined"
                disabled={sensor.locked}
                onClick={unlinkSensor}
                trailingIcon={
                  <Icons.LinkBroken
                    width={18}
                    height={18}
                    color="currentColor"
                  />
                }
                danger
                data-cy="sensors-focused-panel-serial-number-unlink"
              />
            ) : (
              <Button
                disabled={serialNumberText === ''}
                size="medium"
                onClick={() => {
                  dispatch({
                    type: 'sensor.changeSerialNumber',
                    id: sensor.id,
                    serialNumber: serialNumberText,
                  });

                  const sensorDiagnosticsRequest = getSensorDiagnostics(
                    client,
                    serialNumberText
                  );

                  sensorDiagnosticsRequest.then((response) => {
                    const sensorDiagnostics = response.data;
                    const networkAddresses =
                      sensorDiagnostics.network_addresses || [];

                    const mac =
                      networkAddresses.length > 0
                        ? networkAddresses[0].mac
                        : null;
                    const ipv4 =
                      networkAddresses.find((n) => n.family === 'ipv4')
                        ?.address || null;
                    const ipv6 =
                      networkAddresses.find((n) => n.family === 'ipv6')
                        ?.address || null;
                    dispatch({
                      type: 'sensor.setDiagnosticMetadata',
                      id: sensor.id,
                      status: sensorDiagnostics.current_status,
                      ipv4,
                      ipv6,
                      mac,
                      last_heartbeat: sensorDiagnostics.last_heartbeat,
                      os: sensorDiagnostics.os?.VERSION_ID || null,
                    });
                  });

                  sensorDiagnosticsRequest.catch((err) => {
                    if (err.response.status === 404) {
                      toast.error(
                        'Error: unable to find a sensor with this serial number!'
                      );
                      return;
                    }
                  });
                }}
                trailingIcon={
                  <Icons.Link width={18} height={18} color="currentColor" />
                }
                data-cy="sensors-focused-panel-serial-number-link"
              />
            )}
          </div>
        </div>
      </PanelHeader>

      <PanelBody>
        <div className={classNames([styles.formGroup])}>
          <TextField
            type="text"
            size="medium"
            placeholder={
              sensor.type === 'oa'
                ? 'ex: CUST-LOC-FLR-OAS001'
                : 'ex: CUST-LOC-FLR-ES001'
            }
            width="100%"
            leadingIcon={
              <Icons.Apps height={16} width={16} color="currentColor" />
            }
            value={sensor.cadId}
            onChange={(evt) => {
              dispatch({
                type: 'sensor.changeCadId',
                id: sensor.id,
                cadId: evt.currentTarget.value,
              });
            }}
          />
        </div>

        <div className={styles.formGroup}>
          <SensorHeightInput
            value={sensor.height}
            sensorHeightBounds={{
              min: Sensor.min_height(sensor.type),
              max: Sensor.max_height(sensor.type),
            }}
            disabled={sensor.locked}
            displayUnits={state.displayUnit}
            onChange={(height: number) => {
              dispatch({
                type: 'sensor.changeHeight',
                id: sensor.id,
                sensorType: sensor.type,
                height,
              });
            }}
          />
        </div>
        {/* .sensorHeightInput */}

        <div
          className={classNames([
            styles.formGroup,
            styles.sensorRotationFormGroup,
          ])}
        >
          <label className={classNames([styles.large, styles.muted])}>
            Orientation:
          </label>
          <div className={styles.sensorHeightFormFields}>
            <SensorRotationInput
              value={sensor.rotation}
              disabled={sensor.locked}
              onChange={(rotation) => {
                dispatch({
                  type: 'sensor.changeRotation',
                  id: sensor.id,
                  rotation,
                });
              }}
              onRotateRight90={() =>
                dispatch({ type: 'sensor.rotateRight90', id: sensor.id })
              }
            />
          </div>
          {/* .inputInline */}
        </div>
        {/* .sensorRotationFormGroup */}
        {sensor.type === 'oa' && (
          <div
            className={styles.boundingBoxRow}
            data-cy="sensors-focused-panel-bounding-box-filter"
          >
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelIcon}>
                <Icons.Filter height={16} width={16} color="currentColor" />
              </div>
              <div>Bounding Box Filter</div>
              <Tooltip
                contents="Ignore data outside the bounds of the space this sensor is within."
                target={
                  <div
                    css={css`
                      margin-left: 5px;
                      padding-top: 4px;
                    `}
                  >
                    <HelpQuestionMark
                      height={12}
                      width={12}
                      color={dust.Gray400}
                    />
                  </div>
                }
              />
            </div>
            <div className={styles.settingsMenuRowBoundingBox}>
              <Switch
                onChange={(event) =>
                  dispatch({
                    type: 'sensor.boundingBoxFilter',
                    id: sensor.id,
                    boundingBoxFilter: event.target.checked ? 'cloud' : 'none',
                  })
                }
                isChecked={sensor.boundingBoxFilter === 'cloud'}
              />
            </div>
          </div>
        )}
        <div className={styles.sensorConfigMeta}>
          <div
            className={styles.sensorConfigMetaStatus}
            style={{ color: getSensorStatusColor(sensor.status) }}
          >
            &bull;{' '}
            <span className={styles.sensorConfigMetaStatusText}>
              {sensor.serialNumber
                ? sensor.status
                  ? sensor.status
                  : 'Unconfigured'
                : 'Unassigned'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>
              <Icons.Heartbeat height={12} width={12} />:
            </span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {heartbeatTime}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>MAC:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.mac || '--'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>IPv4:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.ipv4 || '--'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>IPv6:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.ipv6 || '--'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>OS:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {sensor.os || '--'}
            </span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span className={styles.metaLabel}>Height:</span>
            <span className={`${styles.muted} ${styles.metaDetail}`}>
              {displayLength(sensor.height, state.displayUnit)}
            </span>
          </div>
          {sensor.type === 'oa' && ellipticalOACoverageEnabled ? (
            <Fragment>
              {ellipticalOACoverageEnabled ? (
                <Fragment>
                  <div className={styles.sensorConfigMetaRow}>
                    <span className={styles.metaLabel}>Major:</span>
                    <span
                      className={classNames(styles.muted, styles.metaDetail)}
                    >
                      {major}
                    </span>
                  </div>
                  <div className={styles.sensorConfigMetaRow}>
                    <span className={styles.metaLabel}>Minor:</span>
                    <span
                      className={classNames(styles.muted, styles.metaDetail)}
                    >
                      {minor}
                    </span>
                  </div>
                </Fragment>
              ) : (
                <div className={styles.sensorConfigMetaRow}>
                  <span className={styles.metaLabel}>Radius:</span>
                  <span className={`${styles.muted} ${styles.metaDetail}`}>
                    {radius}
                  </span>
                </div>
              )}

              <div className={styles.sensorConfigMetaRow}>
                <span className={styles.metaLabel}>Area:</span>
                <span className={`${styles.muted} ${styles.metaDetail}`}>
                  {coverageArea}
                </span>
              </div>
            </Fragment>
          ) : null}
          {/* <div className={styles.sensorConfigMetaRow}>
            <span>MAC:</span>
            <span className={styles.muted}>---</span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span>IPv4:</span>
            <span className={styles.muted}>---</span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span>IPv6:</span>
            <span className={styles.muted}>---</span>
          </div>
          <div className={styles.sensorConfigMetaRow}>
            <span>OS:</span>
            <span className={styles.muted}>---</span>
          </div> */}
        </div>
        {/* .sensorConfigMeta */}

        <NotesBox
          notes={sensor.notes}
          disabled={sensor.locked}
          onNotesEdited={(notes) => {
            dispatch({ type: 'sensor.saveNotes', id: sensor.id, notes });
            toast.success('To persist notes, click "Save"', {
              position: 'top-right',
            });
          }}
        />

        {sensorIsLinked && sensor.type === 'oa' ? (
          <Fragment>
            <div
              className={styles.sensorStreamingControl}
              data-cy="sensors-focused-panel-streaming-controls"
            >
              <label className={classNames([styles.large])}>
                Streaming:
                <span
                  className={classNames([
                    styles.sensorStreamingStatus,
                    styles[sensorStreamingStatus],
                  ])}
                >
                  {sensorStreamingStatus}
                </span>
              </label>

              {sensorStreamingStatus !== 'connected' ? (
                <button
                  className={classNames([
                    styles.buttonSmall,
                    styles.buttonIcon,
                    styles.link,
                    styles.dark,
                    styles.sensorConnectionButton,
                  ])}
                  onClick={() => {
                    dispatch({
                      type: 'sensor.menu.connect',
                      id: sensor.id,
                      serialNumber: serialNumberText,
                    });
                  }}
                  data-cy="sensors-focused-panel-streaming-connect"
                >
                  <Icons.LightningOutline
                    width={18}
                    height={18}
                    color="currentColor"
                  />
                </button>
              ) : (
                <button
                  className={classNames([
                    styles.buttonSmall,
                    styles.buttonIcon,
                    styles.link,
                    styles.sensorConnectionButton,
                  ])}
                  onClick={() => {
                    dispatch({
                      type: 'sensor.menu.disconnect',
                      id: sensor.id,
                    });
                  }}
                  data-cy="sensors-focused-panel-streaming-disconnect"
                >
                  <Icons.LightningFill
                    width={18}
                    height={18}
                    color={colors.yellow}
                  />
                </button>
              )}
            </div>

            {supportsLocate(sensor.os || '') ? (
              <button
                className={classNames([styles.buttonSmall, styles.secondary])}
                style={{ width: '100%' }}
                disabled={locateButtonDisabled}
                onClick={() => {
                  // This should never happen (this case is already guarded against above), it's just
                  // here to make typescript happy
                  if (!sensor.serialNumber) {
                    return;
                  }

                  // Disable the button so that someone can't click the button over and over rapidly
                  setLocateButtonDisabled(true);

                  locateSensor(client, sensor.serialNumber)
                    .then(() => {
                      toast.success('Sensor LED blinking...');

                      // Re-enable the button after 3 seconds
                      setTimeout(() => {
                        setLocateButtonDisabled(false);
                      }, 3000);
                    })
                    .catch((err) => {
                      toast.error('Error locating sensor!');
                      // Re-enable the button instantly if an error occurs
                      setLocateButtonDisabled(false);
                    });
                }}
              >
                <Icons.AccuracyTarget
                  color="currentColor"
                  width={18}
                  height={18}
                />
                &nbsp; Locate
              </button>
            ) : (
              <Tooltip
                width="100%"
                target={
                  <button
                    className={classNames([
                      styles.buttonSmall,
                      styles.disabled,
                      styles.secondary,
                    ])}
                    style={{ width: '100%' }}
                  >
                    <Icons.AccuracyTarget
                      color="currentColor"
                      width={18}
                      height={18}
                    />
                    &nbsp; Locate
                  </button>
                }
                contents="This sensor isn't running a new enough software version."
              />
            )}

            {/* .sensorStreamingControl */}
          </Fragment>
        ) : null}
        {/* .sensorStreamingControl */}
      </PanelBody>

      <PanelActions
        left={
          <HorizontalForm size="medium">
            <Button
              type="cleared"
              size="medium"
              onClick={() => dispatch({ type: 'sensor.remove', id: sensor.id })}
              disabled={sensor.locked}
              trailingIcon={
                <Icons.Trash width={18} height={18} color="currentColor" />
              }
              danger
              data-cy="sensors-focused-panel-delete"
            />
            <Button
              type="cleared"
              size="medium"
              onClick={() => {
                const lockToggleAction = sensor.locked
                  ? 'sensor.unlock'
                  : 'sensor.lock';
                dispatch({ type: lockToggleAction, id: sensor.id });
              }}
              data-cy={
                sensor.locked
                  ? 'sensors-focused-panel-unlock'
                  : 'sensors-focused-panel-lock'
              }
              trailingIcon={
                sensor.locked ? (
                  <Icons.LockClosed
                    width={18}
                    height={18}
                    color={colors.yellow}
                  />
                ) : (
                  <Icons.LockOpen width={18} height={18} color="currentColor" />
                )
              }
            />
            <Button
              type="cleared"
              size="medium"
              onClick={() =>
                dispatch({ type: 'menu.duplicateSensor', id: sensor.id })
              }
              trailingIcon={
                <Icons.CopyAlt width={18} height={18} color="currentColor" />
              }
              data-cy="sensors-focused-panel-duplicate"
            />
          </HorizontalForm>
        }
        right={
          <div>
            <Button
              type="cleared"
              size="medium"
              onClick={() =>
                dispatch({ type: 'sensor.dismiss', id: sensor.id })
              }
              data-cy="sensors-dismiss"
            >
              Done
            </Button>
          </div>
        }
      />
    </Panel>
  );
};

export default FocusedSensorPanel;
