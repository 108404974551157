import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { spacesSelectors } from './spaces-slice';

export const selectSpace = createSelector(
  [
    (state: RootState, params: { spaceId: string }) =>
      spacesSelectors.selectById(state, params.spaceId),
  ],
  (space) => {
    return space;
  }
);
