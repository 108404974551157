export const GRID_STEP_SIZE = 0.1524;

export const HEATMAP_COLORS = [
  'rgba(255, 255, 255, 0',
  'rgba(25, 130, 188, 0.1)',
  'rgba(0, 162, 203, 0.1)',
  'rgba(0, 199, 194, 0.2)',
  'rgba(182, 246, 142, 0.5)',
  'rgba(253, 255, 131, 1)',
  'rgba(248, 196, 61, 1)',
  'rgba(235, 115, 0, 1)',
  'rgba(216, 19, 10, 1)',
];
