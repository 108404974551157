import * as React from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import classNames from 'classnames';

import './styles.scss';

type ThumbRenderProps = {
  key: string;
  className: string;
  style: React.CSSProperties;
};
type ThumbRenderContext = {
  index: number;
  value: number;
  valueNow: number;
};

const Thumb = (props: ThumbRenderProps, context: ThumbRenderContext) => {
  const { className, ...rest } = props;

  return <div className={classNames('SliderThumb', className)} {...rest}></div>;
};

type TrackRenderProps = {
  key: string;
  className: string;
  style: React.CSSProperties;
};
type TrackRenderContext = {
  index: number;
  value: number | Array<number>;
};

const Track = (props: TrackRenderProps, context: TrackRenderContext) => {
  const { className, ...rest } = props;
  return <div className={classNames('SliderTrack', className)} {...rest}></div>;
};

const TrackMultiple = (
  props: TrackRenderProps,
  context: TrackRenderContext
) => {
  const { className, ...rest } = props;
  return (
    <div
      className={classNames('SliderTrackMultiple', className)}
      {...rest}
    ></div>
  );
};

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

type ValueSliderProps = Override<
  ReactSliderProps,
  {
    value: number;
    onChange: (value: number) => void;
  }
>;
export const ValueSlider: React.FunctionComponent<ValueSliderProps> = (
  props
) => {
  return (
    <ReactSlider
      {...(props as any)}
      className={'Slider'}
      renderThumb={Thumb as any}
      renderTrack={Track as any}
    />
  );
};

type RangeSliderProps = Override<
  ReactSliderProps,
  {
    value: [number, number];
    onChange: (value: [number, number]) => void;
  }
>;

export const RangeSlider: React.FunctionComponent<RangeSliderProps> = (
  props
) => {
  return (
    <ReactSlider
      {...(props as any)}
      className={'Slider'}
      renderThumb={Thumb as any}
      renderTrack={TrackMultiple as any}
    />
  );
};
