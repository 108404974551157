import * as React from 'react';
import moment from 'moment-timezone';
import { css } from '@emotion/react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { v4 as uuidv4 } from 'uuid';

import styles from './heatmap-toolbar.module.scss';

import BetaLabel from 'components/beta-label/beta-label';
import { useHeatmap } from './heatmap-context';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { toggleHeatmapEnabled } from 'redux/features/analysis/analysis-slice';
import { HEATMAP_COLORS } from './heatmap-constants';

// DO NOT USE: this is a custom switch just for the heatmap toggle
// it is deprecated and will be removed soon

const Switch: React.FC<{
  isChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = (props) => {
  const uniqueId = uuidv4();

  return (
    <div>
      <input
        id={uniqueId}
        css={css`
          display: none;
        `}
        type="checkbox"
        onChange={props.onChange}
        checked={props.isChecked}
      />

      <label
        htmlFor={uniqueId}
        css={css`
          outline: 0;
          margin-bottom: 0;
          display: inline-block;
          width: 1.25rem;
          height: 0.75rem;
          position: relative;
          cursor: pointer;
          user-select: none;
          background-color: ${props.isChecked ? dust.Blue400 : dust.Gray300};
          border-radius: 0.75rem;
          transition: all 200ms ease;

          &:after {
            position: relative;
            display: block;
            content: '';
            width: 0.5rem;
            height: 0.5rem;
            box-sizing: border-box;
            left: ${props.isChecked ? 0.625 : 0.125}rem;
            top: 0.125rem;
            border-radius: 50%;
            background-color: ${dust.White};
            border: 0 solid ${dust.Gray300};
            transition: all 200ms ease;
          }
        `}
      />
    </div>
  );
};

const HeatmapToolbar: React.FC = () => {
  const heatmapEnabled = useAppSelector(
    (state) => state.analysis.heatmapEnabled
  );

  const dispatch = useAppDispatch();

  const { minDisplayed, maxDisplayed, frameContours } = useHeatmap();

  const showKey = frameContours.length > 0;

  return (
    <nav className={styles.toolbar}>
      <div className={styles.heatmapToggle}>
        <p className={styles.heatmapToggle__text}>
          Heatmap
          <BetaLabel />
        </p>

        <Switch
          isChecked={heatmapEnabled}
          onChange={() => {
            dispatch(toggleHeatmapEnabled());
          }}
        />
      </div>

      {showKey && (
        <div className={styles.key}>
          <p className={styles.key__label}>
            {frameContours.length
              ? moment
                  .duration(minDisplayed)
                  .humanize()
                  .replace('a few seconds', '< 1s')
              : null}
          </p>

          <div className={styles.scale}>
            {HEATMAP_COLORS.map((x, i) =>
              2 < i && i < HEATMAP_COLORS.length ? (
                <div
                  key={i}
                  className={styles.swatch}
                  style={{
                    backgroundColor: HEATMAP_COLORS[i],
                  }}
                />
              ) : null
            )}
          </div>
          <p className={styles.key__label}>
            {maxDisplayed && frameContours.length > 1
              ? `> ${moment
                  .duration(maxDisplayed)
                  .humanize()
                  .replace('a few seconds', '1s')
                  .replace('a minute', '1m')
                  .replace('an hour', '1h')
                  .replace(/ seconds?/, 's')
                  .replace(/ minutes?/, 'm')
                  .replace(/ hours?/, 'h')
                  .replace(/ days?/, 'd')}`
              : null}
          </p>
        </div>
      )}
    </nav>
  );
};

export default React.memo(HeatmapToolbar);
