import * as React from 'react';
import Select from 'react-select/creatable';
import { Props, components, MultiValueProps } from 'react-select';
import { ActionMeta, MultiValue } from 'react-select/dist/declarations/src';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { FixMe } from 'types/fixme';

export type SelectOptionType = {
  value: string;
  label: string;
  clickToAdd?: boolean;
  // this is internal react select typings
  __isNew__?: boolean;
};
export type Labels = Array<SelectOptionType>;
export type ReadonlyLabels = MultiValue<SelectOptionType>;

const customStyles: Props<SelectOptionType, true>['styles'] = {
  control: (provided, state) => ({
    border: 'none',
    marginTop: state.isFocused || state.hasValue ? 0 : -22,
  }),
  valueContainer: () => ({
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (provided, state) => ({
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginRight: 2,
    marginBottom: 2,
    padding: '4px 0px 4px 8px',
    background: dust.Gray100,
    height: 24,
    borderRadius: 12,
    opacity: state.data.clickToAdd ? 0.4 : 1,
    cursor: state.data.clickToAdd ? 'pointer' : undefined,
  }),
  multiValueLabel: () => ({
    fontSize: 12,
    lineHeight: '24px',
    fontWeight: 500,
    color: dust.Gray700,
    overflow: 'hidden',
  }),
  multiValueRemove: () => ({
    height: 14,
    paddingRight: 4,
    paddingLeft: 2,
    color: dust.Gray300,
    cursor: 'pointer',
  }),
  input: (provided) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    margin: '2px 0',
  }),
  menu: (provided) => ({
    ...provided,
    padding: '0 4px',
  }),
  option: (provided, state) => ({
    background: state.isFocused ? dust.Gray100 : dust.White,
    padding: 4,
    fontSize: 12,
    fontWeight: 500,
  }),
};

// Helper function to merge two lists of labels
export function mergeLabels(
  serverLabels: ReadonlyLabels,
  value: ReadonlyLabels
) {
  const mergedLabels: Labels = Array.from(serverLabels);
  value.forEach((label) => {
    if (
      !mergedLabels.some((mergedLabel) => mergedLabel.value === label.value)
    ) {
      mergedLabels.push(label);
    }
  });
  return mergedLabels as ReadonlyLabels;
}

// Helper function for diffing options and turning into a changeset
export function diffLabels(
  oldLabels: ReadonlyLabels,
  newLabels: ReadonlyLabels
) {
  const changeset: {
    creates: Labels;
    deletes: Labels;
  } = {
    creates: [],
    deletes: [],
  };

  newLabels.forEach((newLabel) => {
    if (!oldLabels.some((label) => label.value === newLabel.value)) {
      changeset.creates.push(newLabel);
    }
  });

  oldLabels.forEach((oldLabel) => {
    if (!newLabels.some((label) => label.value === oldLabel.value)) {
      changeset.deletes.push(oldLabel);
    }
  });

  const readonlyChangeset: {
    creates: ReadonlyLabels;
    deletes: ReadonlyLabels;
  } = changeset;

  return readonlyChangeset;
}

// Render an "+ Add" prompt instead of a carat
const DropdownIndicator = () => {
  return (
    <span
      style={{
        padding: '4px 8px 4px 4px',
        color: dust.Gray300,
        fontSize: 12,
        fontWeight: 500,
      }}
    >
      + Add label
    </span>
  );
};

const CustomMultiValue = (
  props: MultiValueProps<SelectOptionType, true> & { focus: VoidFunction }
) => {
  return (
    <components.MultiValue
      {...props}
      components={{
        ...props.components,

        Label: (labelProps) => (
          <components.MultiValueLabel
            {...labelProps}
            innerProps={{
              ...labelProps.innerProps,

              // @ts-ignore multi value label doesn't have event listener types on it
              onClick: (e) => {
                const newValue = props
                  .getValue()
                  .map((val) =>
                    val.value === props.data.value
                      ? { ...props.data, clickToAdd: false }
                      : val
                  );

                props.setValue(newValue, 'select-option', {
                  ...props.data,
                  clickToAdd: false,
                });

                props.focus();
              },

              // @ts-ignore
              onMouseDown: (e) => {
                // prevent the menu open like the remove
                // https://github.com/jedwatson/react-select/blob/master/packages/react-select/src/Select.tsx#L1674
                if (props.data.clickToAdd) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              },
            }}
          />
        ),
      }}
    />
  );
};

type LabelEditorProps = {
  value: ReadonlyLabels;
  options: ReadonlyLabels;
  onChange?: (
    newValue: ReadonlyLabels,
    actionMeta: ActionMeta<SelectOptionType>
  ) => void;
  onBlur?: () => void;
};

const LabelEditor: React.FC<LabelEditorProps> = ({
  value,
  options,
  onChange,
  onBlur,
}) => {
  const selectRef = React.useRef<FixMe>(null);

  const focus = React.useCallback(() => selectRef.current?.focus(), []);

  return (
    <Select
      ref={selectRef}
      components={{
        DropdownIndicator,
        MultiValue: (props) => <CustomMultiValue {...props} focus={focus} />,
      }}
      styles={customStyles}
      isMulti={true}
      isClearable={false}
      allowCreateWhileLoading={false}
      placeholder={null}
      value={value}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      menuPosition="fixed"
    />
  );
};

export default LabelEditor;
