import * as React from 'react';
import {
  loadCookieData,
  TokenProvider,
  TokenProviderData,
} from '@densityco/lib-common-auth';

import { createDensityAPIClient, DensityAuthHeaders } from 'lib/client';
import { StorageKeys } from 'lib/storage';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { setAuthState } from 'redux/features/auth/auth-slice';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import FillCenter from 'components/fill-center/fill-center';
import ErrorMessage from 'components/error-message/error-message';

// Just create the headers ourselves instead of using the unstable headers within
// the TokenProviderData (which are recomputed each render and so unstable)
export const getAuthHeaders = (
  token: string,
  xImpersonateUserHeader?: string
) => {
  const authHeaders: DensityAuthHeaders = {
    Authorization: `Bearer ${token}`,
    ...(xImpersonateUserHeader
      ? { 'X-Impersonate-User': xImpersonateUserHeader }
      : undefined),
  };

  return authHeaders;
};

// This is pretty gross, but working around the mutable state of TokenProviderData
// by memoizing based on the inner values (which are stable values)
const SessionWrapper: React.FC<{
  tokenProviderData: TokenProviderData;
}> = ({ tokenProviderData, children }) => {
  // These values are reliable
  const { token, tokenCheckResponse, xImpersonateUserHeader } =
    tokenProviderData;

  // Here is the memoization...
  const authState = React.useMemo(() => {
    if (tokenCheckResponse === null)
      throw new Error('TokenCheckResponse may not be null');

    const densityAPIClient = createDensityAPIClient(
      getAuthHeaders(token, xImpersonateUserHeader)
    );

    return {
      densityAPIClient,
      tokenCheckResponse,
    };
  }, [token, xImpersonateUserHeader, tokenCheckResponse]); // <-- if these change, the value is re-computed

  const dispatch = useAppDispatch();

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  // NOTE: sync context value to redux auth slice
  React.useEffect(() => {
    dispatch(setAuthState(authState));
  }, [dispatch, authState]);

  // make sure density api client exists before rendering any children
  if (!densityAPIClient) {
    return null;
  }

  return <>{children}</>;
};

const AuthWrapper: React.FunctionComponent = ({ children }) => {
  return (
    <TokenProvider
      fallback={<LoadingOverlay text="Verifying user info..." />}
      error={
        <FillCenter>
          <ErrorMessage>Failed to verify user info.</ErrorMessage>
        </FillCenter>
      }
      tokenLocalStorageKey={StorageKeys.SESSION_TOKEN}
      loginHost={loadCookieData().environment.coreHost}
    >
      {(tokenProviderData) => {
        return (
          <SessionWrapper tokenProviderData={tokenProviderData}>
            {children}
          </SessionWrapper>
        );
      }}
    </TokenProvider>
  );
};

export default AuthWrapper;
