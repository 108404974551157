import * as React from 'react';
import moment from 'moment-timezone';
import * as d3 from 'd3';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { GRID_STEP_SIZE } from './heatmap-constants';
import { isPositionInsideSpace } from 'lib/algorithm';
import { areaToSpace } from 'lib/area';
import { FloorplanCoordinates } from 'lib/geometry';
import {
  clickTimeline,
  hoverTimeline,
} from 'redux/features/analysis/analysis-slice';
import { PlanDetail } from 'lib/api';
import { Floor } from 'lib/floors';

// NOTE: this context is for storing heatmap derivations like timeline data or contours

export const TIMELINE_OFFSET = 4;

type HeatmapContextType = {
  timelinePoints: number[];
  frameStartX: number;
  frameStep: number;
  windowSize: number;
  stepSize: number;

  startMoment: moment.Moment;

  frameContours: d3.ContourMultiPolygon[];
  gridSize: number;
  maxX: number;
  maxY: number;
  frame: number;

  minDisplayed: number;
  maxDisplayed: number;
  isCumulative: boolean;

  onTimelineHover: (x: number | null) => void;
  onTimelineClick: (x: number) => void;
  onViewportWidthChange: (w: number) => void;
};

const HeatmapContext = React.createContext<HeatmapContextType | undefined>(
  undefined
);

export const useHeatmap = () => {
  const context = React.useContext(HeatmapContext);
  if (!context) {
    throw new Error('useHeatmap must be used within a HeatmapProvider');
  }
  return context;
};

export const HeatmapProvider: React.FC<{ plan: PlanDetail; floor: Floor }> = ({
  plan,
  floor,
  children,
}) => {
  const selectedAreas = useAppSelector((state) => state.analysis.selectedAreas);
  const processedHeatmapData = useAppSelector(
    (state) => state.analysis.processHeatmap.data
  );
  const hoveredTimelineX = useAppSelector(
    (state) => state.analysis.hoveredTimelineX
  );
  const clickedTimelineX = useAppSelector(
    (state) => state.analysis.clickedTimelineX
  );

  const timeZone = floor.time_zone;

  const dispatch = useAppDispatch();

  const [viewportWidth, setViewportWidth] = React.useState(0);

  // 4px margin on both sides
  const availableWidth = viewportWidth - TIMELINE_OFFSET * 2;

  const {
    heatmapFrameStarts,
    nestedHeatmapFrames,
    unrolledHeatmapFrames,
    individualMaxValue,
    cumulativeMaxValue,
    maxX,
    maxY,
    windowSize,
    stepSize,
    rawStepSize,
    gridSize,
  } = processedHeatmapData || {
    heatmapFrameStarts: [],
    nestedHeatmapFrames: [],
    unrolledHeatmapFrames: [],
    individualMaxValue: 0,
    cumulativeMaxValue: 0,
    maxX: 0,
    maxY: 0,
    windowSize: 0,
    stepSize: 0,
    rawStepSize: 0,
    gridSize: 0,
  };

  const timelinePoints = React.useMemo(() => {
    const points: number[] = new Array(nestedHeatmapFrames.length).fill(0);

    const areas = plan.areas.filter((area) => selectedAreas.includes(area.id));

    const areasAsSpaces = areas.map(areaToSpace);

    nestedHeatmapFrames.forEach(
      (nestedHeatmapFrame, nestedHeatmapFrameIndex) => {
        nestedHeatmapFrame.forEach((row, y) => {
          const yCoord = y * GRID_STEP_SIZE + GRID_STEP_SIZE / 2;

          row.forEach((value, x) => {
            const xCoord = x * GRID_STEP_SIZE + GRID_STEP_SIZE / 2;

            // if there are no selected areas, accumulate all values
            if (!areasAsSpaces.length) {
              points[nestedHeatmapFrameIndex] += value;
              return;
            }

            const coord = FloorplanCoordinates.create(xCoord, yCoord);

            // calculate the heatmap data that is within any of the selected areas
            if (
              areasAsSpaces.some((areaAsSpace) =>
                isPositionInsideSpace(coord, areaAsSpace)
              )
            ) {
              points[nestedHeatmapFrameIndex] += value;
            }
          });
        });
      }
    );

    // the mininum opacity is 5% and the max is 80%
    const min = d3.min(points) || 0;
    const max = d3.max(points) || 0;
    const scale = d3.scaleLinear([min, max], [0.05, 0.8]);

    return points.map(scale);
  }, [nestedHeatmapFrames, plan.areas, selectedAreas]);

  const currX = hoveredTimelineX || clickedTimelineX || null;

  const frameStep = availableWidth / (unrolledHeatmapFrames.length - 1);
  const frame =
    currX === null
      ? unrolledHeatmapFrames.length - 1
      : Math.max(Math.floor((currX - 16) * Math.pow(frameStep, -1)), 0);
  const frameStartX = frame * frameStep;

  const frameContours = React.useMemo(() => {
    const logScale = d3
      .scaleSymlog()
      .domain([
        0,
        Math.max(
          frame === unrolledHeatmapFrames.length - 1
            ? // TODO: eyeball factor of two
              cumulativeMaxValue * 2
            : individualMaxValue,
          windowSize / 5
        ),
      ])
      .range([0, 9])
      .constant(50);
    const thresholds = Array(9)
      .fill(0)
      .map((_, i) => logScale.invert(i));
    const contours = d3
      .contours()
      .size([maxX, maxY])
      .smooth(true)
      .thresholds(thresholds);
    return unrolledHeatmapFrames[frame]
      ? contours(unrolledHeatmapFrames[frame])
      : [];
  }, [
    unrolledHeatmapFrames,
    frame,
    maxX,
    maxY,
    individualMaxValue,
    cumulativeMaxValue,
    windowSize,
  ]);

  const startMoment = React.useMemo(
    () => moment.tz(heatmapFrameStarts[frame], timeZone),
    [frame, heatmapFrameStarts, timeZone]
  );

  const isCumulative = currX === null;

  const maxValue = isCumulative ? cumulativeMaxValue : individualMaxValue;
  const lastThreshold = frameContours.length
    ? frameContours[frameContours.length - 1].value
    : 0;
  const lastBandGeometricMean = Math.pow(lastThreshold * maxValue, 1 / 2);

  const minDisplayed = frameContours.length ? frameContours[0].value : 0;

  const maxDisplayed = Math.min(
    lastBandGeometricMean,
    windowSize *
      0.8 *
      (frame === unrolledHeatmapFrames.length - 1
        ? (heatmapFrameStarts.length * rawStepSize) / windowSize
        : 1)
  );

  const memoedContextValue = React.useMemo(() => {
    const value: HeatmapContextType = {
      timelinePoints,
      frameStartX,
      frameStep,
      windowSize,
      stepSize,
      startMoment,
      frameContours,
      gridSize,
      maxX,
      maxY,
      frame,
      minDisplayed,
      maxDisplayed,
      isCumulative,
      onTimelineClick: (v) => dispatch(clickTimeline(v)),
      onTimelineHover: (v) => dispatch(hoverTimeline(v)),
      onViewportWidthChange: setViewportWidth,
    };

    return value;
  }, [
    dispatch,
    frame,
    frameContours,
    frameStartX,
    frameStep,
    gridSize,
    isCumulative,
    maxDisplayed,
    maxX,
    maxY,
    minDisplayed,
    startMoment,
    stepSize,
    timelinePoints,
    windowSize,
  ]);

  return (
    <HeatmapContext.Provider value={memoedContextValue}>
      {children}
    </HeatmapContext.Provider>
  );
};
