import { useEffect, useState, useMemo, FunctionComponent } from 'react';

import { useFloorplanLayerContext, toRawHex } from 'components/floorplan';
import ReferenceRulersLayer from './reference-rulers-layer';

import {
  ReferenceRuler,
  FloorplanCollection,
  Sensor,
} from 'components/editor/state';
import {
  FloorplanCoordinates,
  ViewportCoordinates,
  snapToAngle,
} from 'lib/geometry';

import { Gray900 } from '@density/dust/dist/tokens/dust.tokens';

const ObjectMeasureLayer: FunctionComponent<{
  focusedObject: { type: 'sensor' | 'space' | 'layer'; id: string } | null;
  sensors: FloorplanCollection<Sensor>;
}> = ({ focusedObject, sensors }) => {
  const context = useFloorplanLayerContext();

  const [enabled, setEnabled] = useState<boolean>(false);
  const [mousePosition, setMousePosition] =
    useState<FloorplanCoordinates | null>(null);

  const focusedObjectPosition = useMemo(() => {
    if (!focusedObject) {
      return null;
    }

    switch (focusedObject.type) {
      case 'sensor': {
        const focusedSensor = sensors.items.get(focusedObject.id);
        if (!focusedSensor) {
          return null;
        }
        return focusedSensor.position;
      }
      case 'space': {
        return null;
      }
    }
  }, [focusedObject, sensors]);

  useEffect(() => {
    const canvasElement = context.app.view;
    const canvasBBox = canvasElement.getBoundingClientRect();

    const onMouseMoveCanvas = (evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }
      if (!focusedObjectPosition) {
        return;
      }

      const enabled = Boolean(evt.altKey);
      if (!enabled) {
        setEnabled(false);
        return;
      }

      setEnabled(true);

      const coords = ViewportCoordinates.create(
        evt.clientX - canvasBBox.x,
        evt.clientY - canvasBBox.y
      );

      let floorplanCoords = ViewportCoordinates.toFloorplanCoordinates(
        coords,
        context.viewport.current,
        context.floorplan
      );

      if (evt.shiftKey && focusedObjectPosition) {
        floorplanCoords = snapToAngle(focusedObjectPosition, floorplanCoords);
      }

      setMousePosition(floorplanCoords);
    };
    canvasElement.addEventListener('mousemove', onMouseMoveCanvas);

    const onKeyUpWindow = (evt: KeyboardEvent) => {
      if (!evt.altKey) {
        setEnabled(false);
      }
    };
    window.addEventListener('keyup', onKeyUpWindow);

    return () => {
      canvasElement.removeEventListener('mousemove', onMouseMoveCanvas);
      window.removeEventListener('keyup', onKeyUpWindow);
    };
  }, [
    focusedObjectPosition,
    context.app.view,
    context.floorplan,
    context.viewport,
  ]);

  const referenceRulers = useMemo(() => {
    if (!mousePosition) {
      return null;
    }

    if (!focusedObjectPosition) {
      return null;
    }

    return [
      {
        id: 'measure' as const,
        type: 'ruler' as const,
        positionA: focusedObjectPosition,
        positionB: mousePosition,
        distanceLabelPosition: ReferenceRuler.calculateCenterPoint({
          positionA: focusedObjectPosition,
          positionB: mousePosition,
        }),
        enabled: true,
      },
    ];
  }, [focusedObjectPosition, mousePosition]);

  if (!enabled) {
    return null;
  }

  if (!referenceRulers) {
    return null;
  }

  return (
    <ReferenceRulersLayer
      referenceRulers={referenceRulers}
      rulerColor={toRawHex(Gray900)}
      // Always show custom "measurement" label, no matter how far in or out the user zooms
      labelVisibilityZoomThreshold={0}
    />
  );
};
export default ObjectMeasureLayer;
