import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { css } from '@emotion/react';

import GhostButton from 'components/ghost-button/ghost-button';

type ChartDisplayToggleProps<T extends string> = {
  options: T[];
  value: T;
  onChange: (v: T) => void;
};

const ChartDisplayToggle = <T extends string>(
  props: ChartDisplayToggleProps<T>
) => {
  return (
    <div
      css={css`
        display: flex;
        gap: ${dust.Space2};
      `}
    >
      {props.options.map((option) => {
        return (
          <GhostButton
            key={option}
            isActive={props.value === option}
            onClick={() => props.onChange(option)}
          >
            <span
              css={css`
                font-size: ${dust.TextScale2};
                font-weight: ${dust.FontWeightBold};
              `}
            >
              {option}
            </span>
          </GhostButton>
        );
      })}
    </div>
  );
};

export default ChartDisplayToggle;
